import React, { useState } from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { Tertiary } from 'components/link/Link';
import { BannerFields } from 'components/banner/Banner';
import { NextImageWithNullFallback } from 'components/shared/nextImg/NextImageWithNullFallback';
import { SectionHeaderWaveSecondary } from 'components/logo-lines/LogoLines';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import LazyLoadComponent from 'components/shared/lazy-load/LazyLoadComponent';

type SectionHeaderProps = {
  params: { [key: string]: string; styles: string };
  fields?: {
    data: {
      datasource?: BannerFields;
    };
  };
};

export const SectionHeader2 = (props: SectionHeaderProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const [editingMode] = useState(isEditorActive());
  const titleTag = props.params['TitleTag'] || 'h2';
  return (
    <LazyLoadComponent componentId="sectionHeaderSecondary">
      <div
        className={`section-header-secondary component--with-paddings ${sitecoreStyles(
          props.params?.styles
        )}`}
        id={id ? id : undefined}
      >
        <div className="section-header-secondary__content">
          <div className="container">
            <div className="row section-header-secondary__main-row">
              <div className="col-12 col-md-8 col-lg-5">
                <div className="section-header-secondary__text-container">
                  <Text
                    tag={titleTag}
                    className="section-header-secondary__title"
                    field={props.fields?.data.datasource?.title?.jsonValue}
                  />
                  {(props.fields?.data.datasource?.link?.jsonValue.value?.href || editingMode) && (
                    <div className="section-header-secondary__link">
                      <Tertiary
                        fields={{ Link: props.fields?.data.datasource?.link?.jsonValue }}
                        params={{}}
                      ></Tertiary>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-8 col-lg-6">
                <div className="section-header-secondary__image-box">
                  <div className="section-header-secondary__image">
                    <NextImageWithNullFallback
                      field={props.fields?.data.datasource?.image?.jsonValue}
                      // width={649}
                      // height={364}
                      fill
                      sizes="(max-width: 1200px) 100vw, (min-width: 1201px) 25vw, 30vw"
                      params={{
                        styles: 'next-image--16-9',
                      }}
                    />
                  </div>
                  {(props.fields?.data.datasource?.subTitle?.jsonValue.value ||
                    props.fields?.data.datasource?.description?.jsonValue.value ||
                    editingMode) && (
                    <div className="section-header-secondary__image-text-container">
                      <Text
                        tag="h4"
                        className="section-header-secondary__subtitle"
                        field={props.fields?.data.datasource?.subTitle?.jsonValue}
                      />
                      <RichText
                        tag="div"
                        className="body2 section-header-secondary__text"
                        field={props.fields?.data.datasource?.description?.jsonValue}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <SectionHeaderWaveSecondary />
        </div>
      </div>
    </LazyLoadComponent>
  );
};
