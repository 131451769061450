import { createContext } from 'react';

interface totalContextType {
  totalCount: number;
  setNewCount?: (x: number) => void;
}

const totalContext = createContext<totalContextType | undefined>({
  totalCount: 0,
});

export default totalContext;
