/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react';
import { NavigationContext } from './NavigationContext';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { IconName } from 'src/types/Enum';
import { NavigationProps } from './Navigation';
import dynamic from 'next/dynamic';
import config from 'temp/config';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

export const MobileNavigation = (props: NavigationProps) => {
  const router = useRouter();
  const { navigationState, setNavigationState } = useContext(NavigationContext);
  const [currentItem, setCurrentItem] = useState(-1);
  const [currentItemL2, setCurrentItemL2] = useState(-1);

  useEffect(() => {
    if (navigationState.showLanguage) {
      setCurrentItem(-100);
    } else {
      setCurrentItem(-1);
    }
  }, [navigationState]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const hamburger = document.querySelector('.header__navigation-hamburger') as HTMLElement;
      hamburger.addEventListener('click', showMobileNavigation);

      return () => {
        hamburger.removeEventListener('click', showMobileNavigation);
      };
    }

    return undefined;
  }, []);

  const hideNav = () => {
    document.querySelector('.header__container')?.classList.remove('header__container--open');
    document.getElementsByTagName('html')[0].removeAttribute('class');
    document
      .querySelector('.header__navigation-hamburger')
      ?.classList.remove('header__navigation-hamburger--open');
    document.querySelector('.search-box')?.classList.remove('search-box--hidden');
  };

  function showMobileNavigation() {
    if (
      !document.querySelector('.header__container')?.classList.contains('header__container--open')
    ) {
      document.querySelector('.header__container')?.classList.add('header__container--open');
      document
        .querySelector('.navigation__mobile-content')
        ?.classList.add('navigation__mobile-content--open');

      document.getElementsByTagName('html')[0].setAttribute('class', 'no-scroll');
      document
        .querySelector('.header__navigation-hamburger')
        ?.classList.add('header__navigation-hamburger--open');
      document.querySelector('.search-box')?.classList.add('search-box--hidden');
    } else {
      hideNav();
    }
  }

  const getFirstLvlData = () => {
    const firstLvl = [];
    for (const value of Object.values(props.fields)) {
      firstLvl.push(value);
    }
    return firstLvl;
  };

  const menuItems = getFirstLvlData();

  function toggleItem(index: number, level: string) {
    if (level === 'level2') {
      setCurrentItemL2(index !== currentItemL2 ? index : -1);
    } else {
      setCurrentItem(index !== currentItem ? index : -1);
      setCurrentItemL2(-1);
    }
  }

  function toggleItemsToDefault() {
    setCurrentItem(-1);
    setCurrentItemL2(-1);
    setNavigationState({ ...navigationState, showLanguage: false });
  }

  useEffect(() => {
    const openHamburger = document.querySelector('.header__navigation-hamburger--open');
    openHamburger?.addEventListener('click', toggleItemsToDefault);

    if (currentItem > -1) {
      document.querySelector("[data-text='Contact Us']")?.classList.add('button-hidden');
      document.querySelector('.site-selector')?.classList.add('button-hidden');
    } else {
      document.querySelector("[data-text='Contact Us']")?.classList.remove('button-hidden');
      document.querySelector('.site-selector')?.classList.remove('button-hidden');
    }

    return () => {
      openHamburger?.removeEventListener('click', toggleItemsToDefault);
    };
  }, [currentItem]);

  let DynamicIcon;
  const editingmode = isEditorActive();
  if (editingmode) {
    // Static import when in editing mode
    DynamicIcon =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/shared/icon/Icon').Icon;
  } else {
    // Dynamic import when not in editing mode
    DynamicIcon = dynamic(() => import('components/shared/icon/Icon').then((mod) => mod.Icon), {
      ssr: !!config.dynamicImportSsr,
    });
  }

  return (
    <>
      {currentItem !== -1 && (
        <div
          onClick={() => toggleItemsToDefault()}
          className="subheading1 mobile-navigation__main-menu"
        >
          <DynamicIcon icon={IconName.ChevronLeft} iconLabel={'Back to menu'} />
          Main Menu
        </div>
      )}
      <ul className="mobile-navigation">
        {menuItems.map((item, index) => (
          <li className="mobile-navigation__level1" key={index}>
            {(currentItem === -1 || currentItem === index) && (
              <div
                className={`mobile-navigation__level1-container ${
                  currentItem === index && currentItemL2 !== -1
                    ? 'mobile-navigation__level1-container--open'
                    : ''
                }`}
              >
                <NextLink
                  onClick={(e) => {
                    if (item?.Children?.length > 0) {
                      e.preventDefault();
                      toggleItem(index, 'level1');
                    }

                    if (currentItem === index && currentItemL2 === -1) {
                      e.preventDefault();
                      hideNav();
                      router.push(item.Href);
                    }
                  }}
                  href={item.Href}
                  className="h6 mobile-navigation__level1-item"
                >
                  {currentItem === index && currentItemL2 !== -1 && (
                    <DynamicIcon icon={IconName.ChevronLeft} iconLabel={'Back'} />
                  )}
                  {item?.NavigationTitle?.value}
                  <div className="mobile-navigation__level1-icon">
                    {item?.Children?.length > 0 && currentItem !== index && (
                      <DynamicIcon icon={IconName.ChevronRight} iconLabel={'Open'} />
                    )}
                  </div>
                </NextLink>
              </div>
            )}

            {currentItem !== -1 && currentItem === index && (
              <ul className="mobile-navigation__level2">
                {item?.Children?.map(
                  (child: any, indexL2: number) =>
                    child?.NavigationTitle?.value !== '$name' && (
                      <li key={indexL2}>
                        {(currentItemL2 === -1 || currentItemL2 === indexL2) && (
                          <div
                            className="mobile-navigation__level2-container"
                            onClick={() => {
                              if (child?.Children?.length > 0) toggleItem(indexL2, 'level2');
                            }}
                          >
                            <NextLink
                              className={`mobile-navigation__level2-item ${
                                currentItemL2 === indexL2 ? 'subheading1' : 'subheading2'
                              }`}
                              href={child.Href}
                              onClick={(e) => {
                                if (child?.Children?.length > 0 && currentItemL2 !== indexL2) {
                                  e.preventDefault();
                                } else {
                                  hideNav();
                                }
                              }}
                            >
                              {child?.NavigationTitle?.value}
                            </NextLink>
                            {child?.Children?.length > 0 && currentItemL2 !== indexL2 && (
                              <DynamicIcon icon={IconName.ChevronRight} iconLabel={'Open'} />
                            )}
                          </div>
                        )}
                        {currentItemL2 !== -1 && currentItemL2 === indexL2 && (
                          <ul className="mobile-navigation__level3">
                            {child?.Children?.map(
                              (child: any, indexL3: number) =>
                                child?.NavigationTitle?.value !== '$name' && (
                                  <li
                                    className="mobile-navigation__level3-item subheading2"
                                    key={indexL3}
                                  >
                                    <NextLink
                                      onClick={() => {
                                        hideNav();
                                      }}
                                      href={child.Href}
                                    >
                                      {child?.NavigationTitle?.value}
                                    </NextLink>
                                  </li>
                                )
                            )}
                          </ul>
                        )}
                      </li>
                    )
                )}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </>
  );
};
