import {
  ImageField,
  LinkField,
  RichText,
  RichTextField,
  Text,
  TextField,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import React, { useState } from 'react';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import dynamic from 'next/dynamic';
import config from 'temp/config';

interface Fields {
  img?: ImageField;
  title?: TextField;
  body?: RichTextField;
  link: LinkField;
}

type LargeContentCardProps = {
  params: { [key: string]: string };
  fields: Fields;
};

const LargeContentCardDefaultComponent = (props: LargeContentCardProps): JSX.Element => (
  <div className={`large-content-card ${sitecoreStyles(props.params?.styles)}`}>
    <div>Empty</div>
  </div>
);

export const Default = (props: LargeContentCardProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const [editingMode] = useState(isEditorActive());
  const handleClick = () => {
    if (props.fields.link) window.open(props.fields?.link.value.href, '_self');
  };
  const editingmode = isEditorActive();
  let DynamicTertiary;

  if (editingmode) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicTertiary = require('components/link/Link').Tertiary;
  } else {
    // Dynamic import when not in editing mode
    DynamicTertiary = dynamic(() => import('components/link/Link').then((mod) => mod.Tertiary), {
      ssr: !!config.dynamicImportSsr,
    });
  }
  let DynamicNextImageWithNullFallback;

  if (editingmode) {
    // Static import when in editing mode

    DynamicNextImageWithNullFallback =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/shared/nextImg/NextImageWithNullFallback').NextImageWithNullFallback;
  } else {
    // Dynamic import when not in editing mode
    DynamicNextImageWithNullFallback = dynamic(
      () =>
        import('components/shared/nextImg/NextImageWithNullFallback').then(
          (mod) => mod.NextImageWithNullFallback
        ),
      { ssr: !!config.dynamicImportSsr } // Adjust server-side rendering (ssr: true) based on your config
    );
  }
  if (props.fields) {
    return (
      <div
        className={`large-content-card large-content-card__default-container ${sitecoreStyles(
          props.params?.styles
        )}`}
        id={id ? id : undefined}
        onClick={!editingMode ? handleClick : () => null}
      >
        <div
          className={`large-content-card ${sitecoreStyles(props.params?.styles)}`}
          id={id ? id : undefined}
        >
          {props?.fields?.img && (
            <div className="large-content-card__element-margin">
              <DynamicNextImageWithNullFallback
                field={props?.fields?.img}
                width={456}
                height={456}
                params={{
                  styles: 'large-content-card__logo next-image--square',
                }}
              ></DynamicNextImageWithNullFallback>
            </div>
          )}
          <div className="text-container">
            <RichText
              tag="div"
              className="body1 large-content-card__element-margin large-content-card__body"
              field={props.fields.body}
            />
            <div className="h5 large-content-card__element-margin large-content-card__title">
              <Text field={props.fields.title} />
            </div>

            <span className="read-me-button">
              {props.fields.link && (
                <DynamicTertiary fields={{ Link: props.fields.link }} params={{}}></DynamicTertiary>
              )}
            </span>
          </div>
        </div>
      </div>
    );
  }
  return <LargeContentCardDefaultComponent {...props} />;
};
