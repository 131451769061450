/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/*  eslint-disable @typescript-eslint/no-var-requires */

import React, { useState, useEffect, useContext } from 'react';
import { useI18n } from 'next-localization';
import { MultiSelect } from 'react-multi-select-component';
import { CoveoSearchApiCareersResult } from 'lib/coveo/CoveoSearchApi';
import { GetJobList, GetSavedJobList } from 'components/card/variants/career-job/CareerJob';
import totalContext from 'src/utils/totalValueContext';
import { useRouter } from 'next/router';
import { useSearchParams } from 'next/navigation';
import Select from 'react-select';

let jobLangCode = 1;

export const GetMasterAreaOfExpertise = async (jobLocale: number) => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const coveoModule = require('lib/coveo/CoveoSearchApi');
  const CoveoSearchResultsRequest = coveoModule.CoveoSearchResultsRequest;
  const results = await CoveoSearchResultsRequest({
    q: `@job_item_type == talentcommunity AND @job_expertise_language_id == ${jobLocale}`, //,
    sortCriteria: '@job_area_of_expertise ascending',
    numberOfResults: 24,
  });
  return results;
};
//GetMasterAreaOfExpertise(jobLangCode);

export const GetMasterCountryCity = async () => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const coveoModule = require('lib/coveo/CoveoSearchApi');
  const CoveoSearchResultsRequest = coveoModule.CoveoSearchResultsRequest;
  const results = await CoveoSearchResultsRequest({
    q: `@job_item_type == countrycity`, //,
    sortCriteria: '@priority ascending, @job_location_name ascending',
    numberOfResults: 5000,
  });
  return results;
};
//GetMasterCountryCity();

type LocationPropType = {
  label: string;
  value: string;
  id: string;
};

export const Default = ({
  loadValue,
  selectedRadio,
  reExp,
  recSearchData,
  resetLoadMoreValue,
  sendData,
}: any): JSX.Element => {
  const { t } = useI18n();
  {
    const params = useSearchParams();

    const [keyword, setKeyword] = useState<string>('');
    const [valuesForMulti, setValuesForMulti] = useState<LocationPropType[]>([]);
    const [selectedLocation, setSelectedLoaction] = useState<any>([]);
    const [expertiseValue, setExpertiseValue] = useState<any>('');
    const [expertiseMasterList, setExpertiseMasterList] = useState<
      { value: number; label: string }[]
    >([]);
    const [keywordFilter, setKeywordFilter] = useState<string[]>([]);
    const [locFilter, setLocFilter] = useState<any[]>([]);
    const [expertFilter, setExpertFilter] = useState<any[]>([]);
    const [_searchData, setSearchData] = useState<CoveoSearchApiCareersResult[]>([]);
    const [currentPageUrl, setCurrentPageUrl] = useState<string>('');
    const [disableArrow, setDisableArrow] = useState<boolean>(false);
    const [consentVal, setConsentVal] = useState<boolean>(true);
    const [clearFilterFlag, setClearFilterFlag] = useState<boolean>(false);
    const [searchClickFlag, setSearchClickFlag] = useState<boolean>(false);

    const searchEnable =
      (expertiseValue !== undefined && expertiseValue !== '') ||
      keyword !== '' ||
      selectedLocation[0] !== undefined;

    const { setNewCount } = useContext<any>(totalContext);

    reExp && reExp(expertiseMasterList);

    const urlName = useRouter();

    const jobLanguage = urlName.locale && urlName.locale.toLowerCase();
    if (jobLanguage) {
      if (jobLanguage == 'fr-ca') {
        jobLangCode = 11;
      }
      if (jobLanguage == 'pt-br') {
        jobLangCode = 13;
      }
    }
    const loadAOEData = async () => {
      const updatedAOEData = await GetMasterAreaOfExpertise(jobLangCode);
      const empArr: { id: number; name: string }[] = [];
      updatedAOEData.map((aoe: any) => {
        empArr.push({ id: aoe.raw.job_expertise_id, name: aoe.raw.job_area_of_expertise });
      });
      const newEmp: { value: number; label: string }[] = [];
      updatedAOEData.map((aoe: any) => {
        newEmp.push({ value: aoe.raw.job_expertise_id, label: aoe.raw.job_area_of_expertise });
      });
      setExpertiseMasterList(newEmp);
    };
    const loadCountryData = async () => {
      const updatedCountryData = await GetMasterCountryCity();
      const empArr: any = [];
      updatedCountryData.map((coData: any) => {
        empArr.push({
          label: coData.raw.job_location_name,
          value: coData.raw.job_location_id,
          id: coData.raw.job_location_id,
          joblocationcountrycode: coData.raw.joblocationcountrycode,
        });
        setValuesForMulti(empArr);
      });
    };

    const clearIndividualFilter = (data: Record<string, any>) => {
      const keydata = Object.keys(data);
      let [urlkeyword, selectedLocationvalue, expertiseValuedata] = getURLKeyword(
        window.location.hash
      );
      if (String(keydata) == 'keyword') {
        urlkeyword = '';
      }
      if (String(keydata) == 'expert') {
        expertiseValuedata = '';
      }
      const remainingLocation = [];
      if (String(keydata) == 'location') {
        const selectedValue = selectedLocationvalue.split(',');
        for (const i of selectedValue) {
          if (i != String(Object.values(data))) {
            remainingLocation.push(i);
          }
        }
        selectedLocationvalue = remainingLocation.join(',');
      }
      const locationList = selectedLocationvalue;
      let keywordURLData = '';
      let selectedLocationURLData = '';
      let expertiseValueURLData = '';
      if (urlkeyword) {
        keywordURLData += 'keyword=' + urlkeyword;
      }
      if (locationList) {
        selectedLocationURLData += 'selectedLocation=' + locationList;
      }
      if (expertiseValuedata) {
        expertiseValueURLData += 'expertiseValue=' + expertiseValuedata;
      }
      let result = '';
      const keywordAndLocationResult =
        (urlkeyword != '' ? keywordURLData : '') +
        (urlkeyword == '' && locationList.length != 0
          ? locationList.length != 0
            ? selectedLocationURLData
            : ''
          : locationList.length != 0
          ? '&' + selectedLocationURLData
          : '');
      if (expertiseValuedata) {
        if (keywordAndLocationResult) {
          result += keywordAndLocationResult + '&' + expertiseValueURLData;
        } else {
          result += expertiseValueURLData;
        }
      } else {
        result += keywordAndLocationResult;
      }
      window.location.hash = window.location.hash.replace(/\#.*/, result);
    };

    const getURLKeyword = (url: string) => {
      let keywordData = '';
      let selectedLocationData = '';
      let expertiseValueData = '';
      const result = url?.split('#')[1]?.split('&');
      for (const i in result) {
        if (result[i].includes('keyword')) {
          keywordData = result[i]?.split('=')[1];
        }
        if (result[i].includes('selectedLocation')) {
          selectedLocationData = result[i]?.split('=')[1];
        }
        if (result[i].includes('expertiseValue')) {
          expertiseValueData = result[i]?.split('=')[1];
        }
      }
      return [keywordData, selectedLocationData, expertiseValueData];
    };

    const getAOEData = async (name: string) => {
      const updatedAOEData = await GetMasterAreaOfExpertise(jobLangCode);
      const aoeDataResult: { value: number; label: string }[] = [];
      updatedAOEData.map((aoe: any) => {
        if (
          aoe.raw.job_area_of_expertise.toLowerCase() == name.replaceAll('%20', ' ').toLowerCase()
        )
          aoeDataResult.push({
            value: aoe.raw.job_expertise_id,
            label: aoe.raw.job_area_of_expertise,
          });
      });
      return aoeDataResult[0];
    };

    const getCountryData = async (location: string) => {
      const updatedCountryData = await GetMasterCountryCity();
      const locationlist = location?.split(',');
      const countryDataResult: any = [];
      for (const location of locationlist) {
        updatedCountryData.map((coData: any) => {
          if (location == coData.raw.job_location_id) {
            countryDataResult.push({
              label: coData.raw.job_location_name,
              value: coData.raw.job_location_id,
              id: coData.raw.job_location_id,
              joblocationcountrycode: coData.raw.joblocationcountrycode,
            });
          }
        });
      }
      return countryDataResult;
    };

    useEffect(() => {
      // console.log('consolee filters fetchData condition checking', {
      //   keyword,
      //   selectedLocation,
      //   expertiseValue,
      //   keywordFilter,
      //   locFilter,
      //   expertFilter,
      //   clearFilterFlag,
      //   searchClickFlag,
      // });

      console.log('USE_EFFECT --> 1');

      const Arr = [keyword, selectedLocation, expertiseValue];
      recSearchData && recSearchData(Arr);

      // Case 1: Saved Jobs Page with Search Click Flag
      if (urlName.asPath.includes('/career/saved-jobs') && searchClickFlag) {
        const savedData = JSON.parse(localStorage?.getItem('cardApi') || '[]');
        setSearchData(savedData);
        setSearchClickFlag(false);
        console.log(
          'consolee filters useEffect if block: Loaded data from localStorage',
          savedData
        );

        // Case 2: Filters Applied or Clear Filter Flag is Set
      } else if (
        keywordFilter.length !== 0 ||
        locFilter.length !== 0 ||
        expertFilter.length !== 0 ||
        clearFilterFlag
      ) {
        fetchData()
          .then(() => {
            setClearFilterFlag(false); // Reset flag after fetching
            console.log('consolee if else condition: Data fetched with filters');
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });

        // Case 3: No Filters Applied
      } else {
        fetchData()
          .then(() => {
            console.log('consolee else condition: Data fetched without filters');
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      }
    }, [keywordFilter, locFilter, expertFilter]);

    useEffect(() => {
      const [urlkeyword, selectedLocationvalue, expertiseValuedata] = getURLKeyword(urlName.asPath);
      const keywordvalue = urlkeyword.replaceAll('%20', ' ');
      const location = selectedLocationvalue;
      const expertise = expertiseValuedata;
      keywordvalue && setKeywordFilter([keywordvalue]);
      setKeyword(keywordvalue);
      if (location) {
        const countryData = getCountryData(location);
        countryData.then((data) => {
          setLocFilter(data);
          setSelectedLoaction(data);
        });
      }
      if (expertise) {
        const expertiseData = getAOEData(expertise);
        expertiseData.then((data) => {
          setExpertFilter([data]);
          setExpertiseValue(data);
        });
      }
    }, [urlName.asPath]);

    useEffect(() => {
      console.log('USE_EFFECT --> 2');

      const disKeyWord = params.get('keyword')?.toString();
      disKeyWord != undefined && setKeyword(disKeyWord);
      disKeyWord != undefined && setKeywordFilter([disKeyWord]);
    }, [params]);

    // useEffect(() => {
    //   console.log('USE_EFFECT --> 3');

    //   if (!localStorage?.getItem('keyword')) {
    //     localStorage?.setItem('keyword', JSON.stringify(keyword));
    //     window.dispatchEvent(new Event('searchKey'));
    //   }
    //   if (!localStorage?.getItem('expertiseValue')) {
    //     localStorage?.setItem('expertiseValue', JSON.stringify(expertiseValue));
    //     window.dispatchEvent(new Event('searchKey'));
    //   }
    //   if (!localStorage?.getItem('selectedLocation')) {
    //     localStorage?.setItem('selectedLocation', JSON.stringify(selectedLocation));
    //     window.dispatchEvent(new Event('searchKey'));
    //   }
    // }, [keyword, expertiseValue, selectedLocation]);

    useEffect(() => {
      console.log('USE_EFFECT --> 4');

      const consent = JSON.parse(localStorage?.getItem('CookieState') || '[]')
        ? JSON.parse(localStorage?.getItem('CookieState') || '[]')
        : false;
      setConsentVal(consent);

      const reqItem = !consent && JSON.parse(localStorage?.getItem('keyword') || '[]');
      let reqExp =
        !consent &&
        localStorage?.getItem('expertiseValue') &&
        JSON.parse(localStorage?.getItem('expertiseValue') || '');
      let reqLoc: any[] = [];
      if (!consent == true) {
        reqLoc = JSON.parse(localStorage?.getItem('selectedLocation') || '[]');
      } else {
        reqLoc = selectedLocation ? selectedLocation : [];
      }

      window.addEventListener('localEvent', () => {
        setKeyword('');
        setKeywordFilter([]);

        setExpertFilter([]);
        setExpertiseValue('');

        setLocFilter([]);
        setSelectedLoaction([]);
      });

      const disKeyWord = params.get('keyword')?.toString();

      if (disKeyWord != undefined) {
        setKeyword(disKeyWord);
        setKeywordFilter([disKeyWord]);
        reqExp = '';
        reqLoc = [];
        setLocFilter([]);
        setSelectedLoaction([]);
        setExpertiseValue('');
        setExpertFilter([]);
      } else {
        reqItem != '' && setKeyword(reqItem);
        reqItem != '' && setKeywordFilter([reqItem]);
        reqExp && setExpertFilter(reqExp);
        reqExp && setExpertiseValue(reqExp[0]);
        reqLoc && setLocFilter(reqLoc);
        reqLoc && setSelectedLoaction(reqLoc);
      }

      setCurrentPageUrl(urlName.asPath);
      // to load dropdown data ⬇️
      loadCountryData();
      loadAOEData();
    }, [urlName.asPath]);

    // fetch Data ⬇️

    const fetchData = async () => {
      try {
        const sortCriteria = selectedRadio === 'relevant' ? 1 : 0;
        const selectedCountry = selectedLocation.length > 0 ? selectedLocation : null;
        const initialLoadValue = localStorage?.getItem('loadValue') && 1;

        const mtCity: any = [];

        const selectedCity = selectedCountry?.filter((item: any) => {
          return item.id != item.joblocationcountrycode;
        });

        selectedCity?.map((item: any) => {
          mtCity.push(item.joblocationcountrycode);
        });

        const filCountry = selectedCountry?.filter((item: any) => {
          return !mtCity.includes(item.id);
        });

        const reqListCount = filCountry?.map((item: any) => item.id)?.join(',');

        if (urlName.asPath.includes('/career/search-jobs')) {
          const datareq = await GetJobList({
            keyword: keyword,
            country: reqListCount,
            areaOfExpertise: expertiseValue,
            LoadMoreValue: localStorage?.getItem('loadValue') || loadValue,
            sortCriteria: sortCriteria,
            initailLoad: initialLoadValue,
            jobLocale: jobLangCode,
            siteCountry: jobLanguage,
          });
          const getcards = await datareq.results;
          setNewCount(datareq.totalCount);
          setSearchData(getcards);
          sendData && sendData(getcards);
          if (!consentVal) {
            localStorage?.setItem('cardApi', JSON.stringify(getcards));
          }
        } else {
          localStorage?.removeItem('searchData');
          const datareq = await GetSavedJobList({
            jobList: JSON.parse(localStorage?.getItem('bookmarkedCards') || '[]')
              ?.map((item: any) => item)
              ?.join(','),
            sortCriteria: sortCriteria,
            jobLocale: jobLangCode,
          });
          console.log('data req', datareq);
          {
            currentPageUrl;
          }
          setTimeout(() => {
            const getcards = datareq.results;
            setNewCount(datareq.totalCount);
            setSearchData(getcards);
            sendData && sendData(getcards);
          }, 300);

          // Sending Data to Parent Component ⬇️
        }
      } catch (error) {
        console.error('Error fetching job card data:', error);
      }
    };

    // fetch Data ⬆️

    const keywordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setKeyword(e.target.value);
    };

    // Search Functionality 🔍
    const searchClick = (e?: React.MouseEvent<HTMLSpanElement>) => {
      setSearchClickFlag(true);
      resetLoadMoreValue(12);
      e?.preventDefault();
      if (urlName.asPath == '/career/saved-jobs') {
        urlName.push('/career/search-jobs');
      }
      const Arr: any = [];
      const compFilter = keyword == '' ? [] : [keyword];

      // if (!consentVal) {
      //   localStorage?.setItem('keyword', JSON.stringify(keyword));
      //   localStorage?.setItem('expertiseValue', JSON.stringify([expertiseValue]));
      //   localStorage?.setItem('selectedLocation', JSON.stringify(selectedLocation));
      //   window.dispatchEvent(new Event('searchUpdate'));
      // }

      setKeywordFilter(compFilter);
      setExpertFilter([expertiseValue]);
      setLocFilter(selectedLocation);

      // sendData && sendData(searchData);

      recSearchData && recSearchData(Arr);

      Arr.push(keyword, selectedLocation, expertiseValue);
      fetchData();
      const locationList = selectedLocation.map((item: any) => item.id);
      let keywordURLData = '';
      let selectedLocationURLData = '';
      let expertiseValueURLData = '';
      if (keyword) {
        keywordURLData += 'keyword=' + keyword;
      }
      if (locationList) {
        selectedLocationURLData += 'selectedLocation=' + locationList.join(',');
      }
      if (expertiseValue) {
        expertiseValueURLData += 'expertiseValue=' + expertiseValue['label'];
      }
      let result = '';
      const keywordAndLocationResult =
        (keyword != '' ? keywordURLData : '') +
        (keyword == '' && locationList.length != 0
          ? locationList.length != 0
            ? selectedLocationURLData
            : ''
          : locationList.length != 0
          ? '&' + selectedLocationURLData
          : '');
      if (expertiseValue) {
        if (keywordAndLocationResult) {
          result += keywordAndLocationResult + '&' + expertiseValueURLData;
        } else {
          result += expertiseValueURLData;
        }
      } else {
        result += keywordAndLocationResult;
      }
      urlName.push('/career/search-jobs#' + result);
    };

    const clearFilter = () => {
      setClearFilterFlag(true);
      console.log('consolee clearFilter flag set to true');
      setKeywordFilter([]);
      setExpertFilter([]);
      setLocFilter([]);
      setSelectedLoaction([]);
      setKeyword('');
      setExpertiseValue('');
      resetLoadMoreValue(12);
      // localStorage?.setItem('keyword', '');
      // localStorage?.setItem('expertiseValue', JSON.stringify([]));
      // localStorage?.setItem('selectedLocation', JSON.stringify([]));
      window.location.hash = window.location.hash.replace(/\#.*/, '');
    };

    const locationFilterFun = (loc: string) => {
      resetLoadMoreValue(12);
      const reqItems = locFilter.filter((selLoc: LocationPropType) => {
        return selLoc.value != loc;
      });
      setLocFilter(reqItems);
      setSelectedLoaction(reqItems);
    };

    // multiselect related code ⬇️:

    const customValueRenderer = (selected: any) => {
      selected.length > 0 ? setDisableArrow(true) : setDisableArrow(false);
      return selected.length
        ? `${t(`career-job-country-or-city`)}: ${selected.length}`
        : `${t(`career-job-country-or-city`)}`;
    };

    const DefaultItemRenderer = ({ checked, option, onClick, disabled }: any) => (
      <div className={`itemRenderer ${checked == true ? 'isChecked' : 'notChecked'}`}>
        <span>{option.label}</span>
        <input
          className={`${checked == true ? 'isChecked' : 'notChecked'}`}
          type="checkbox"
          onChange={onClick}
          checked={checked}
          tabIndex={-1}
          disabled={disabled}
        />
      </div>
    );

    const noArrow = () => {
      return (
        <svg
          width="2"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path />
        </svg>
      );
    };

    const customArrow = ({ expanded }: any) => {
      return (
        <span className={`customArrowPosition ${expanded ? `customArrowTransition` : ``}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M4.21967 8.46967C4.51256 8.17678 4.98744 8.17678 5.28033 8.46967L12 15.1893L18.7197 8.46967C19.0126 8.17678 19.4874 8.17678 19.7803 8.46967C20.0732 8.76256 20.0732 9.23744 19.7803 9.53033L12.5303 16.7803C12.2374 17.0732 11.7626 17.0732 11.4697 16.7803L4.21967 9.53033C3.92678 9.23744 3.92678 8.76256 4.21967 8.46967Z"
              fill="#333"
            />
          </svg>
        </span>
      );
    };
    // multiselect related code ends ⬆️:

    const customClassNames = {
      menuList: () => {
        return 'custom-menuList';
      },
      contol: () => {
        return 'custom-control';
      },
    };

    return (
      <>
        <div>
          {/* Title Text 🔽*/}
          <div className="searchjob-text-container">
            <h2 className="searchjob-text title-banner__title">
              {t(`career-job-dream-job-header`)}
            </h2>
          </div>
          <div className="header-search-container__expanded__content career_search_page">
            <div className="header-search-container__expanded__search-bar">
              {/* Keyword input 🔽 */}
              <span
                className={`input-border ${keyword == '' ? '' : 'gradiantBorder'} gradientRight`}
              >
                <input
                  value={keyword}
                  onChange={keywordChange}
                  className={`searchIconSearchJobs background-icon-style ${
                    keyword == '' ? '' : 'gradiantBorder'
                  }`}
                  placeholder={t(`career-job-search-for-keyword`)}
                />
              </span>
              {/* React Multi Select Component 🔽 */}
              <span
                className={`input-border lang-multi-select ${
                  selectedLocation.length == 0 || selectedLocation == false ? '' : 'gradiantBorder'
                }`}
              >
                <MultiSelect
                  hasSelectAll={false}
                  valueRenderer={customValueRenderer}
                  options={valuesForMulti}
                  value={selectedLocation}
                  onChange={setSelectedLoaction}
                  labelledBy="location Select"
                  overrideStrings={{ selectSomeItems: t(`career-job-country-or-city`) }}
                  ArrowRenderer={disableArrow ? noArrow : customArrow}
                  ItemRenderer={DefaultItemRenderer}
                />
              </span>
              {/* Area Of Expertise Dropdown 🔽 */}
              <span
                className={`input-border ${
                  expertiseValue == undefined || expertiseValue == ''
                    ? ''
                    : 'gradiantBorder gradientRight'
                }`}
              >
                <Select
                  placeholder={t(`career-job-area-of-expertise`)}
                  options={expertiseMasterList}
                  value={expertiseValue}
                  onChange={(val: any) => setExpertiseValue(val)}
                  classNamePrefix="custom-select"
                  classNames={customClassNames}
                  isSearchable={false}
                  styles={{
                    control: (base: any) => ({
                      ...base,
                      border: 0,
                      boxShadow: 'none',
                    }),
                    option: (base: any) => ({
                      ...base,
                      color: '#4c4c4c',
                      fontWeight: 400,
                      height: '100%',
                      overflow: 'hidden',
                    }),
                    dropdownIndicator: (base: any, state: any) => ({
                      ...base,
                      transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                      transition: '250ms',
                    }),
                  }}
                  theme={(theme: any) => ({
                    ...theme,
                    borderRadius: 20,
                    colors: {
                      ...theme.colors,
                      primary: '#f5f7fa',
                      primary25: '#f5f7fa',
                      primary50: '#fff',
                      primary75: '#000',
                      neutral20: '#000',
                    },
                  })}
                />
              </span>
              {/* Search Button 🔍 */}
              <span
                className={`search-button ${!searchEnable ? 'disableButton' : 'enableButton'}`}
                onClick={(e) => searchClick(e)}
                data-analytics-link-name="Search Button"
                data-analytics-link-type="Button"
                data-analytics-content-class="Job Search button"
                data-analytics-template-zone="Body"
                data-analytics-component-name="Job Search button"
                data-analytics-component-variation="Hero Job search button"
                data-analytics-target={keyword}
                data-analytics-component-section="Job search"
                data-analytics-slide-number="NAN"
                data-analytics-ispersonalized="False"
                data-analytics-iscarousal="False"
                data-analytics-job-title={keyword}
                data-analytics-job-region={selectedLocation
                  .map((item: any) => item.label)
                  .toString()}
                data-analytics-job-sub-title={expertiseValue ? expertiseValue.label : ''}
              >
                {t(`career-job-search-now`)}
              </span>
            </div>

            {keywordFilter.length > 0 ? (
              keywordFilter.map((keyword) => {
                return (
                  <span
                    className="filterItem"
                    key={keyword}
                    onClick={() => {
                      setKeywordFilter([]);
                      setKeyword('');
                      clearIndividualFilter({ keyword: keyword });
                      // localStorage.setItem('keyword', '');
                    }}
                  >
                    {keyword}
                    <span className="filterIcon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M2.39705 2.55379L2.46967 2.46967C2.73594 2.2034 3.1526 2.1792 3.44621 2.39705L3.53033 2.46967L8 6.939L12.4697 2.46967C12.7626 2.17678 13.2374 2.17678 13.5303 2.46967C13.8232 2.76256 13.8232 3.23744 13.5303 3.53033L9.061 8L13.5303 12.4697C13.7966 12.7359 13.8208 13.1526 13.6029 13.4462L13.5303 13.5303C13.2641 13.7966 12.8474 13.8208 12.5538 13.6029L12.4697 13.5303L8 9.061L3.53033 13.5303C3.23744 13.8232 2.76256 13.8232 2.46967 13.5303C2.17678 13.2374 2.17678 12.7626 2.46967 12.4697L6.939 8L2.46967 3.53033C2.2034 3.26406 2.1792 2.8474 2.39705 2.55379L2.46967 2.46967L2.39705 2.55379Z"
                          fill="#212121"
                        />
                      </svg>
                    </span>
                  </span>
                );
              })
            ) : (
              <></>
            )}

            {locFilter ? (
              locFilter.map((loc: LocationPropType) => {
                return (
                  <span
                    className="filterItem"
                    key={loc.value}
                    onClick={() => {
                      resetLoadMoreValue(12);
                      const reqId = loc.value;
                      locationFilterFun(reqId);
                      clearIndividualFilter({ location: loc.value });
                      // const finalLoc = locFilter.filter((locf) => loc.value !== locf['value']);
                      // localStorage.setItem('selectedLocation', JSON.stringify(finalLoc));
                    }}
                  >
                    {loc.label}
                    <span className="filterIcon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M2.39705 2.55379L2.46967 2.46967C2.73594 2.2034 3.1526 2.1792 3.44621 2.39705L3.53033 2.46967L8 6.939L12.4697 2.46967C12.7626 2.17678 13.2374 2.17678 13.5303 2.46967C13.8232 2.76256 13.8232 3.23744 13.5303 3.53033L9.061 8L13.5303 12.4697C13.7966 12.7359 13.8208 13.1526 13.6029 13.4462L13.5303 13.5303C13.2641 13.7966 12.8474 13.8208 12.5538 13.6029L12.4697 13.5303L8 9.061L3.53033 13.5303C3.23744 13.8232 2.76256 13.8232 2.46967 13.5303C2.17678 13.2374 2.17678 12.7626 2.46967 12.4697L6.939 8L2.46967 3.53033C2.2034 3.26406 2.1792 2.8474 2.39705 2.55379L2.46967 2.46967L2.39705 2.55379Z"
                          fill="#212121"
                        />
                      </svg>
                    </span>
                  </span>
                );
              })
            ) : (
              <></>
            )}
            {expertFilter[0] != null && expertFilter[0] != '' && expertFilter.length != 0 ? (
              expertFilter &&
              expertFilter.map((exFil) => {
                return (
                  <span
                    className="filterItem"
                    key={exFil.value}
                    onClick={() => {
                      resetLoadMoreValue(12);
                      setExpertFilter([]);
                      setExpertiseValue('');
                      clearIndividualFilter({ expert: exFil.label });
                      // localStorage.setItem('expertiseValue', JSON.stringify([]));
                    }}
                  >
                    {expertFilter[0].label}
                    <span className="filterIcon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M2.39705 2.55379L2.46967 2.46967C2.73594 2.2034 3.1526 2.1792 3.44621 2.39705L3.53033 2.46967L8 6.939L12.4697 2.46967C12.7626 2.17678 13.2374 2.17678 13.5303 2.46967C13.8232 2.76256 13.8232 3.23744 13.5303 3.53033L9.061 8L13.5303 12.4697C13.7966 12.7359 13.8208 13.1526 13.6029 13.4462L13.5303 13.5303C13.2641 13.7966 12.8474 13.8208 12.5538 13.6029L12.4697 13.5303L8 9.061L3.53033 13.5303C3.23744 13.8232 2.76256 13.8232 2.46967 13.5303C2.17678 13.2374 2.17678 12.7626 2.46967 12.4697L6.939 8L2.46967 3.53033C2.2034 3.26406 2.1792 2.8474 2.39705 2.55379L2.46967 2.46967L2.39705 2.55379Z"
                          fill="#212121"
                        />
                      </svg>
                    </span>
                  </span>
                );
              })
            ) : (
              <></>
            )}
            {keywordFilter.length > 0 || expertFilter[0] || locFilter.length > 0 ? (
              <span className="clearFilter" onClick={clearFilter}>
                {t(`career-job-clear-applied-filters`)}
              </span>
            ) : (
              <></>
            )}
          </div>
          <hr className="line-separator"></hr>
        </div>
      </>
    );
  }
};
