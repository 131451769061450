import React, { useEffect, useState } from 'react';
import {
  TextField,
  Text,
  ComponentParams,
  ComponentRendering,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import config from 'temp/config';
import {
  DoubleAccordionContext,
  DoubleAccordionState,
} from './partials/double-acc-context/DoubleAccordionContext';
import { Icon } from 'components/shared/icon/Icon';
import { IconName, IconSize } from 'src/types/Enum';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import dynamic from 'next/dynamic';
import LazyLoadComponent from 'components/shared/lazy-load/LazyLoadComponent';

interface DoubleAccordionItemFields {
  id: string;
  heading?: {
    jsonValue?: TextField;
  };
}

export interface DoubleAccordionDatasource {
  id: string;
  children?: {
    results?: DoubleAccordionItemFields[];
  };
}

type DoubleAccordionPocProps = {
  rendering: ComponentRendering & { params: ComponentParams };
  children?: React.ReactNode[];
  params: { [key: string]: string };
  fields?: {
    data: {
      datasource?: DoubleAccordionDatasource;
    };
  };
};

export const parseKeyValuePairs = (input?: string): { type: string; link: string }[] => {
  const result = [];
  if (input) {
    const pairs = input.split('&');
    console.log(pairs);
    for (const pair of pairs) {
      const [key, value] = pair.split('=');
      // Only add to the result if both key and value are present
      if (key && value) {
        result.push({ type: key, link: value });
      }
    }
  }
  return result;
};
export const Default = (props: DoubleAccordionPocProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const datasource = props.fields?.data?.datasource;
  console.log('---->[DoubleAccordionPoc] Component', datasource);

  const [isDoubleAccordionComponentOpen, setIsDoubleAccordionComponentOpen] = useState([0]);
  const [doubleAccordionState, setDoubleAccordionState] = useState<DoubleAccordionState>({
    currentlyOpenAccordion: [],
  });
  const [editingMode] = useState(isEditorActive());

  const toggleDoubleAccordionComponent = (index: number) => {
    setDoubleAccordionState({ currentlyOpenAccordion: [] });

    if (!isDoubleAccordionComponentOpen.includes(index)) {
      setIsDoubleAccordionComponentOpen([...isDoubleAccordionComponentOpen, index]);
    } else {
      setIsDoubleAccordionComponentOpen(isDoubleAccordionComponentOpen.filter((i) => i != index));
    }
  };

  const sitecoreContext = useSitecoreContext();

  useEffect(() => {
    if (typeof window !== 'undefined' && !editingMode) {
      const firstToOpen =
        window.document.getElementsByClassName('inside-accordion__full-name')[0] &&
        window.document.getElementsByClassName('inside-accordion__full-name')[0].innerHTML;
      setDoubleAccordionState({ currentlyOpenAccordion: [firstToOpen] || [''] });
    }
  }, [editingMode]);

  const editingmode = isEditorActive();

  let DynamicChildrenOrPlaceholder;
  if (editingmode) {
    // Static import when in editing mode

    DynamicChildrenOrPlaceholder =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/shared/children-or-placeholder/ChildrenOrPlaceholder').ChildrenOrPlaceholder;
  } else {
    // Dynamic import when not in editing mode
    DynamicChildrenOrPlaceholder = dynamic(
      () =>
        import('components/shared/children-or-placeholder/ChildrenOrPlaceholder').then(
          (mod) => mod.ChildrenOrPlaceholder
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }

  if (datasource) {
    return (
      <LazyLoadComponent componentId="doubleAccordion">
        <div
          className={`double-accordion component--with-paddings ${sitecoreStyles(
            props.params.styles
          )}`}
          id={id ? id : undefined}
        >
          <div className={`double-accordion-component`}>
            <DoubleAccordionContext.Provider
              value={{ doubleAccordionState, setDoubleAccordionState }}
            >
              <div className="double-accordion-component__root">
                {datasource?.children?.results?.map((item, index) => (
                  <div
                    key={`item-${index}`}
                    className={`double-accordion-component__item ${
                      isDoubleAccordionComponentOpen.includes(index)
                        ? 'double-accordion-component__item--open'
                        : ''
                    }`}
                  >
                    <div className="double-accordion-component__trigger">
                      <Text
                        tabIndex={0}
                        tag="h3"
                        className={`double-accordion-component__item-title`}
                        field={item?.heading?.jsonValue}
                      />
                      <button
                        aria-label="toggle-accordion"
                        onClick={() => toggleDoubleAccordionComponent(index)}
                      >
                        <div className="double-accordion-component__icon">
                          {!isDoubleAccordionComponentOpen.includes(index) ? (
                            <Icon
                              icon={IconName.ChevronDownWhite}
                              iconSize={IconSize.Size48}
                              iconLabel={'Open'}
                            />
                          ) : (
                            <Icon
                              icon={IconName.ChevronDown}
                              iconSize={IconSize.Size48}
                              iconLabel={'Close'}
                            />
                          )}
                        </div>
                      </button>
                    </div>
                    <div className="double-accordion-component__accordion-content">
                      <div className={`inside-accordion`}>
                        <div className="inside-accordion__root">
                          <DynamicChildrenOrPlaceholder
                            name={`double-accordion-content-${index + 1}`}
                            rendering={props.rendering}
                            sitecoreContext={sitecoreContext}
                          >
                            {props.children ? props.children : null}
                          </DynamicChildrenOrPlaceholder>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </DoubleAccordionContext.Provider>
          </div>
        </div>
      </LazyLoadComponent>
    );
  }

  return <DoubleAccordionPoc />;
};

export const DoubleAccordionPoc = (): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  return (
    <React.Fragment>
      {editingMode && (
        <div className={`double-accordion component--with-paddings`}>
          <span className="is-empty-hint">Double Accordion</span>
        </div>
      )}
    </React.Fragment>
  );
};
