import { useI18n } from 'next-localization';
import React, { useState, useEffect } from 'react';

interface propTypeShareLinkUrls {
  shareLinkUrls: string;
  shareLinkTitle?: string;
}

export const SocialBlockModal = (props: propTypeShareLinkUrls) => {
  // Share functionality - Start--------------------------
  const [visibility, setvisibility] = useState(false);
  const [hostName, sethostName] = useState('');
  useEffect(() => {
    if (typeof window != 'undefined') {
      const getHostName = 'https://' + window.location.hostname;
      sethostName(getHostName);
    }
  }, []);
  const openshare = () => {
    setvisibility(true);
  };
  const closeshare = () => {
    setvisibility(false);
  };

  const encodedTitle = encodeURIComponent(props.shareLinkTitle || 'Default Title');
  const encodedUrl = encodeURIComponent(props.shareLinkUrls);
  const mailtoLink = `mailto:?subject=${encodedTitle} via Avanade&body='${encodedTitle}' at ${hostName}${encodedUrl}`;

  return (
    <>
      <div
        className="shareIcon"
        onClick={(e) => {
          openshare();
          e.stopPropagation();
        }}
      ></div>
      <div
        className={'shareOptions ' + (visibility ? 'visible' : '')}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <a
          href={mailtoLink}
          className="ga-track social-sharing-analytics"
          target="_blank"
          rel="noopener noreferrer"
          data-analytics-link-name="Social Share"
          data-analytics-link-type="Email"
          data-analytics-content-class="Content"
          data-analytics-template-zone="Body"
          data-analytics-component-name="Image Molecule"
          data-analytics-component-variation="NAN"
          data-analytics-target={props.shareLinkUrls}
          data-analytics-component-section="NAN"
          data-analytics-slide-number="NAN"
          data-analytics-ispersonalized="False"
          data-analytics-iscarousal="False"
          data-analytics-social-media-type="Email"
        >
          <i className="icon icon--email icon--28" aria-label="email"></i>
        </a>
        <a
          href="javascript:void(0);"
          className="ga-track social-sharing-analytics"
          target="_blank"
          rel="noopener noreferrer"
          data-analytics-link-name="Social Share"
          data-analytics-link-type="Facebook"
          data-analytics-content-class="Content"
          data-analytics-template-zone="Body"
          data-analytics-component-name="Image Molecule"
          data-analytics-component-variation="NAN"
          data-analytics-target={props.shareLinkUrls}
          data-analytics-component-section="NAN"
          data-analytics-slide-number="NAN"
          data-analytics-ispersonalized="False"
          data-analytics-iscarousal="False"
          data-analytics-social-media-type="Facebook"
          onClick={() => {
            window.open(
              'https://www.facebook.com/sharer/sharer.php?u=' + hostName + props.shareLinkUrls,
              'popupwindow',
              'scrollbars=yes,width=600,height=600'
            );
          }}
        >
          <i className="icon icon--facebook icon--24" aria-label="Facebook"></i>
        </a>
        {/* <a
          href="https://www.instagram.com/avanadeinc/"
          className="ga-track social-sharing-analytics"
          target="_blank"
          rel="noopener noreferrer"
          data-analytics-link-name="Social Share"
          data-analytics-link-type="Instagram"
          data-analytics-content-class="Content"
          data-analytics-template-zone="Body"
          data-analytics-component-name="Image Molecule"
          data-analytics-component-variation="NAN"
          data-analytics-target={props.shareLinkUrls}
          data-analytics-component-section="NAN"
          data-analytics-slide-number="NAN"
          data-analytics-ispersonalized="False"
          data-analytics-iscarousal="False"
          data-analytics-social-media-type="Instagram"
          onClick={() => {
            window.open(
              'https://www.instagram.com/avanadeinc/',
              'popupwindow',
              'scrollbars=yes,width=600,height=600'
            );
          }}
        >
          <i className="icon icon--instagram icon--24" aria-label="Instagram"></i>
        </a> */}
        <a
          href="javascript:void(0);"
          className="ga-track social-sharing-analytics"
          target="_blank"
          rel="noopener noreferrer"
          data-analytics-link-name="Social Share"
          data-analytics-link-type="LinkedIn"
          data-analytics-content-class="Content"
          data-analytics-template-zone="Body"
          data-analytics-component-name="Image Molecule"
          data-analytics-component-variation="NAN"
          data-analytics-target={props.shareLinkUrls}
          data-analytics-component-section="NAN"
          data-analytics-slide-number="NAN"
          data-analytics-ispersonalized="False"
          data-analytics-iscarousal="False"
          data-analytics-social-media-type="LinkedIn"
          onClick={() => {
            window.open(
              'https://www.linkedin.com/shareArticle?url=' + hostName + props.shareLinkUrls,
              'popupwindow',
              'scrollbars=yes,width=600,height=600'
            );
          }}
        >
          <i className="icon icon--linkedin icon--24" aria-label="Linkedin"></i>
        </a>
        {/* <a
          href="javascript:void(0);"
          className="ga-track social-sharing-analytics"
          target="_blank"
          rel="noopener noreferrer"
          data-analytics-link-name="Social Share"
          data-analytics-link-type="Pinterest"
          data-analytics-content-class="Content"
          data-analytics-template-zone="Body"
          data-analytics-component-name="Image Molecule"
          data-analytics-component-variation="NAN"
          data-analytics-target={props.shareLinkUrls}
          data-analytics-component-section="NAN"
          data-analytics-slide-number="NAN"
          data-analytics-ispersonalized="False"
          data-analytics-iscarousal="False"
          data-analytics-social-media-type="Pinterest"
          onClick={() => {
            window.open(
              'https://www.pinterest.com/company/avanade',
              'popupwindow',
              'scrollbars=yes,width=600,height=600'
            );
          }}
        >
          <i className="icon icon--pinterest icon--24" aria-label="Pinterest"></i>
        </a> */}
        {/* <a
          href="javascript:void(0);"
          className="ga-track social-sharing-analytics"
          target="_blank"
          rel="noopener noreferrer"
          data-analytics-link-name="Social Share"
          data-analytics-link-type="Youtube"
          data-analytics-content-class="Content"
          data-analytics-template-zone="Body"
          data-analytics-component-name="Image Molecule"
          data-analytics-component-variation="NAN"
          data-analytics-target="https://www.youtube.com/avanade"
          data-analytics-component-section="NAN"
          data-analytics-slide-number="NAN"
          data-analytics-ispersonalized="False"
          data-analytics-iscarousal="False"
          data-analytics-social-media-type="Youtube"
          onClick={() => {
            window.open(
              'https://www.youtube.com/avanade',
              'popupwindow',
              'scrollbars=yes,width=600,height=600'
            );
          }}
        >
          <i className="icon icon--youtube icon--24" aria-label="Youtube"></i>
        </a> */}
        <a>
          <i
            className="icon icon--dismiss icon--24"
            aria-label="Close"
            onClick={(e) => {
              closeshare();
              e.stopPropagation();
            }}
          ></i>
        </a>
      </div>
    </>
  );
};

export const SocialBlock = (props: propTypeShareLinkUrls) => {
  const [hostName, sethostName] = useState('');
  useEffect(() => {
    if (typeof window != 'undefined') {
      const getHostName = 'https://' + window.location.hostname;
      sethostName(getHostName);
    }
  }, []);

  const { t } = useI18n(); /*Dictionary*/

  const encodedTitle = encodeURIComponent(props.shareLinkTitle || 'Default Title');
  const encodedUrl = encodeURIComponent(props.shareLinkUrls);
  const mailtoLink = `mailto:?subject=${encodedTitle} via Avanade&body='${encodedTitle}' at ${hostName}${encodedUrl}`;

  return (
    <div className="social-share">
      <h5>{t(`career-job-share-with-friend`)}</h5>
      <div className="iconStyles__container">
        <a
          href={mailtoLink}
          className="ga-track social-sharing-analytics"
          target="_blank"
          rel="noopener noreferrer"
          data-analytics-link-name="Social Share"
          data-analytics-link-type="Email"
          data-analytics-content-class="Content"
          data-analytics-template-zone="Body"
          data-analytics-component-name="Image Molecule"
          data-analytics-component-variation="NAN"
          data-analytics-target={props.shareLinkUrls}
          data-analytics-component-section="NAN"
          data-analytics-slide-number="NAN"
          data-analytics-ispersonalized="False"
          data-analytics-iscarousal="False"
          data-analytics-social-media-type="Email"
        >
          <i className="icon icon--email icon--28" aria-label="email"></i>
        </a>
        <a
          href="javascript:void(0);"
          className="ga-track social-sharing-analytics"
          target="_blank"
          rel="noopener noreferrer"
          data-analytics-link-name="Social Share"
          data-analytics-link-type="Facebook"
          data-analytics-content-class="Share"
          data-analytics-template-zone="Body"
          data-analytics-component-name="Image Molecule"
          data-analytics-component-variation="NAN"
          data-analytics-target={props.shareLinkUrls}
          data-analytics-component-section="NAN"
          data-analytics-slide-number="NAN"
          data-analytics-ispersonalized="False"
          data-analytics-iscarousal="False"
          data-analytics-social-media-type="Facebook"
          onClick={() => {
            window.open(
              'https://www.facebook.com/sharer/sharer.php?u=' + hostName + props.shareLinkUrls,
              'popupwindow',
              'scrollbars=yes,width=600,height=600'
            );
          }}
        >
          <i className="icon icon--facebook icon--28" aria-label="Facebook"></i>
        </a>
        {/* <a
          href="javascript:void(0);"
          className="ga-track social-sharing-analytics"
          target="_blank"
          rel="noopener noreferrer"
          data-analytics-link-name="undefined"
          data-analytics-link-type="Share"
          data-analytics-content-class="Share"
          data-analytics-template-zone="Body"
          data-analytics-component-name="Image Molecule"
          data-analytics-component-variation="NAN"
          data-analytics-target="https://www.instagram.com/avanadeinc/"
          data-analytics-component-section="NAN"
          data-analytics-slide-number="NAN"
          data-analytics-ispersonalized="False"
          data-analytics-iscarousal="False"
          data-analytics-social-media-type="Instagram"
          onClick={() => {
            window.open(
              'https://www.instagram.com/avanadeinc/',
              'popupwindow',
              'scrollbars=yes,width=600,height=600'
            );
          }}
        >
          <i className="icon icon--instagram icon--28" aria-label="Instagram"></i>
        </a> */}
        <a
          href="javascript:void(0);"
          className="ga-track social-sharing-analytics"
          target="_blank"
          rel="noopener noreferrer"
          data-analytics-link-name="Social Share"
          data-analytics-link-type="LinkedIn"
          data-analytics-content-class="Share"
          data-analytics-template-zone="Body"
          data-analytics-component-name="Image Molecule"
          data-analytics-component-variation="NAN"
          data-analytics-target={props.shareLinkUrls}
          data-analytics-component-section="NAN"
          data-analytics-slide-number="NAN"
          data-analytics-ispersonalized="False"
          data-analytics-iscarousal="False"
          data-analytics-social-media-type="LinkedIn"
          onClick={() => {
            window.open(
              'https://www.linkedin.com/shareArticle?url=' + hostName + props.shareLinkUrls,
              'popupwindow',
              'scrollbars=yes,width=600,height=600'
            );
          }}
        >
          <i className="icon icon--linkedin icon--28" aria-label="Linkedin"></i>
        </a>
        {/* <a
          href="javascript:void(0);"
          className="ga-track social-sharing-analytics"
          target="_blank"
          rel="noopener noreferrer"
          data-analytics-link-name="undefined"
          data-analytics-link-type="Link"
          data-analytics-content-class="Content"
          data-analytics-template-zone="Body"
          data-analytics-component-name="Image Molecule"
          data-analytics-component-variation="NAN"
          data-analytics-target="https://www.pinterest.com/company/avanade"
          data-analytics-component-section="NAN"
          data-analytics-slide-number="NAN"
          data-analytics-ispersonalized="False"
          data-analytics-iscarousal="False"
          data-analytics-social-media-type="Pinterest"
          onClick={() => {
            window.open(
              'https://www.pinterest.com/company/avanade',
              'popupwindow',
              'scrollbars=yes,width=600,height=600'
            );
          }}
        >
          <i className="icon icon--pinterest icon--28" aria-label="Pinterest"></i>
        </a>
        <a
          href="javascript:void(0);"
          className="ga-track social-sharing-analytics"
          target="_blank"
          rel="noopener noreferrer"
          data-analytics-link-name="undefined"
          data-analytics-link-type="Share"
          data-analytics-content-class="Share"
          data-analytics-template-zone="Body"
          data-analytics-component-name="Image Molecule"
          data-analytics-component-variation="NAN"
          data-analytics-target="https://www.youtube.com/avanade"
          data-analytics-component-section="NAN"
          data-analytics-slide-number="NAN"
          data-analytics-ispersonalized="False"
          data-analytics-iscarousal="False"
          data-analytics-social-media-type="Youtube"
          onClick={() => {
            window.open(
              'https://www.youtube.com/avanade',
              'popupwindow',
              'scrollbars=yes,width=600,height=600'
            );
          }}
        >
          <i className="icon icon--youtube icon--28" aria-label="Youtube"></i>
        </a> */}
      </div>
    </div>
  );
};
