import React, { useState } from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { TimageFields } from 'components/timage/Timage';
import { NextImageWithNullFallback } from 'components/shared/nextImg/NextImageWithNullFallback';
import sitecoreStyles from 'src/helpers/sitecoreStyles';

type TimageProps = {
  params: { [key: string]: string; styles: string };
  fields: TimageFields;
};
export const TextImageTopTitleTimage = (props: TimageProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const [editingMode] = useState(isEditorActive());
  //const tag = props.params['TitleTag'] || 'h2';

  return (
    <div
      className={`timage-top-title component--with-paddings ${sitecoreStyles(
        props.params?.styles
      )}`}
      id={id ? id : undefined}
    >
      {editingMode && (
        <div className="row">
          <div className="col-12">
            <div className="s-editing-mode-hint">
              <strong>HINT:</strong> Requirement for the Title in this component is to
              <strong> NOT USE H1 AND H2 TAGS</strong> due to translations issue.
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-12">
          <Text
            tag={props.params['TitleTag'] || 'h3'}
            field={props.fields.Title}
            className="timage-top-title__header"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <NextImageWithNullFallback
            field={props.fields.Image}
            width={552}
            height={552}
            params={{
              styles: 'timage-top-title__image-container next-image--square',
            }}
          ></NextImageWithNullFallback>
        </div>
        <div className="col-12 col-md-6 timage-top-title__text-container">
          <Text tag="h5" className="timage-top-title__subtitle" field={props.fields.SubTitle} />
          <RichText className="body2" field={props.fields.Text} />
        </div>
      </div>
    </div>
  );
};
