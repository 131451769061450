import { Field, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { useState } from 'react';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import { IconName, IconSize } from 'src/types/Enum';
import dynamic from 'next/dynamic';
import config from 'temp/config';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

export interface JumpLinkFields {
  JumpLinkTitle?: Field<string>;
  JumpLinkIdentifier?: Field<string>;
}

type JumpLinkProps = {
  params: { [key: string]: string };
  fields?: JumpLinkFields;
};

export const Default = (props: JumpLinkProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const [editingMode] = useState(sitecoreContext && sitecoreContext.pageState === 'edit');
  const id = props.fields?.JumpLinkIdentifier?.value || props.params.RenderingIdentifier;
  const editingmode = isEditorActive();
  let DynamicIcon;
  if (editingmode) {
    // Static import when in editing mode
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicIcon = require('components/shared/icon/Icon').Icon;
  } else {
    // Dynamic import when not in editing mode
    DynamicIcon = dynamic(() => import('components/shared/icon/Icon').then((mod) => mod.Icon), {
      ssr: !!config.dynamicImportSsr,
    });
  }
  return (
    <>
      {editingMode ? (
        <div className={`jump-link-anchor row ${sitecoreStyles(props.params?.styles)}`}>
          <div className="col-md-2">
            <DynamicIcon
              icon={IconName.ArrowCircleRight}
              iconSize={IconSize.Size28}
              iconLabel={'Arrow circle right'}
            />
          </div>
          <div className="col-md-5">
            <strong>Jump link title:</strong>{' '}
            <Text tag="span" field={props.fields?.JumpLinkTitle} />
          </div>
          <div className="col-md-5">
            <strong>Jump link identifier:</strong>{' '}
            <Text tag="span" field={props.fields?.JumpLinkIdentifier} />
          </div>
        </div>
      ) : (
        <>
          {props.fields?.JumpLinkTitle?.value && props.fields?.JumpLinkIdentifier?.value && (
            <div className="jump-link-anchor" id={id ? id : undefined}></div>
          )}
        </>
      )}
    </>
  );
};
