export interface AnswerLevels {
  [key: string]: number;
}

export interface AnswerChoices {
  ansTitle: string;
  answerLevels: AnswerLevels[];
}

export type SurveyInput = {
  pages: (SurveyQuestionHeader | SurveyQuestionPage)[];
  showQuestionNumbers: string;
  pageNextText: string;
  pagePrevText: string;
  completeText: string;
  showPrevButton: boolean;
  firstPageIsStarted: boolean;
  startSurveyText: string;
};

export type SurveyQuestion = {
  name: string;
  title: string;
  type: string;
  choices: SurveyAnswer[];
  isRequired: boolean;
  requiredErrorText: string;
};

export type SurveyQuestionHeader = {
  type: string;
  html: string;
};

export type SurveyQuestionPage = {
  elements: (SurveyQuestionHeader | SurveyQuestion)[];
};

export type SurveyAnswer = {
  value: string;
  text: string;
};
