import React from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { Tertiary } from 'components/link/Link';
import { BannerFields } from 'components/banner/Banner';
import { NextImageWithNullFallback } from 'components/shared/nextImg/NextImageWithNullFallback';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import LazyLoadComponent from 'components/shared/lazy-load/LazyLoadComponent';

type BannerProps = {
  params: { [key: string]: string; styles: string };
  fields?: {
    data: {
      datasource?: BannerFields;
    };
  };
};

export const TextImageRight = (props: BannerProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const titleTag = props.params['TitleTag'] || 'h2';
  const isTag = titleTag == 'h1' || titleTag == 'h2';

  return (
    <LazyLoadComponent componentId="TextImageRight">
      <div
        className={`text-image-right component--with-paddings ${sitecoreStyles(
          props.params?.styles
        )}`}
        id={id ? id : undefined}
      >
        <div className="row">
          <div className="col-12 col-lg-5 text-image-right__text-column">
            <Text
              tag={isTag ? titleTag : 'div'}
              field={props.fields?.data.datasource?.title?.jsonValue}
              className={`text-image-right__header ${!isTag ? titleTag : ''}`}
            />
            <RichText
              tag="div"
              className="text-image-right__description body2"
              field={props.fields?.data.datasource?.description?.jsonValue}
            />
            {props.fields?.data.datasource?.link?.jsonValue && (
              <Tertiary
                fields={{ Link: props.fields?.data.datasource?.link?.jsonValue }}
                params={{}}
              ></Tertiary>
            )}
          </div>
          <div className="col-12 col-lg-7 text-image-right__image-column">
            <NextImageWithNullFallback
              field={props.fields?.data.datasource?.image?.jsonValue}
              width={649}
              height={365}
              params={{
                styles: 'text-image-right__image-container next-image--16-9',
              }}
            ></NextImageWithNullFallback>
          </div>
        </div>
      </div>
    </LazyLoadComponent>
  );
};
