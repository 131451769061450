/* eslint-disable @typescript-eslint/no-explicit-any */

import config from 'temp/config';
import { CoveoSearchResult } from '../../types/Coveo';
import { getOrganizationEndpoints } from '@coveo/atomic-react';
import NodeCache from 'node-cache';

// Create a cache with a TTL of 3600 seconds (1 hour)
const duration = Number(config.apiCacheDuration);
const cache = new NodeCache({ stdTTL: duration });
const revalidateInterval = Number(process.env.COVEO_CACHE_DURATION) || 600;

export const fetchJobs = async (
  query: CoveoSearchApiQuery
): Promise<CoveoSearchApiCareersResult[]> => {
  let allResults: CoveoSearchApiCareersResult[] = [];
  const pageSize = 256;
  let currentPage = 0;
  let totalAvailableResults = Infinity;
  const q = { ...query, numberOfResults: pageSize, firstResult: currentPage * pageSize };
  while (allResults.length < totalAvailableResults) {
    const response = await CoveoSearchRequest(q);
    totalAvailableResults = response.totalCountFiltered;
    currentPage++;
    q.firstResult = currentPage * pageSize;
    allResults = [...allResults, ...response.results];
  }
  return allResults;
};

export const fetchPages = async (query: CoveoSearchApiQuery): Promise<CoveoSearchResult[]> => {
  let allResults: CoveoSearchResult[] = [];
  const pageSize = 256;
  let currentPage = 0;
  let totalAvailableResults = Infinity;
  const q = { ...query, numberOfResults: pageSize, firstResult: currentPage * pageSize };
  while (allResults.length < totalAvailableResults) {
    const response = await CoveoSearchPagesRequest(q);
    totalAvailableResults = response.totalCountFiltered;
    currentPage++;
    q.firstResult = currentPage * pageSize;
    allResults = [...allResults, ...response.results];
  }
  return allResults;
};

export const fetchJobRecommendations2 = async (
  jobId?: string,
  langid?: string,
  country?: string
): Promise<CoveoSearchApiCareersResult[]> => {
  if (!jobId) return [];
  let query = `NOT @jobid==(${jobId}) AND @joblanguageid==${langid || '1'}`;
  if (country) {
    query = `${query} AND @sitecorecountry=${country}`; // the query should contain all the saved job ids and the urlId
  }
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const results = await CoveoSearchResultsRequest({
    cq: '@job_item_type==ActiveJobs AND @isignoredjob==0',
    q: query,
    sortCriteria: '@jobposteddate descending, @jobname ascending',
    numberOfResults: 4,
  });
  return results;
};

export const fetchJobDetails2 = async (
  id: string | undefined,
  langId: string | undefined
): Promise<CoveoSearchApiCareersResult | undefined> => {
  if (!id) return undefined;
  const response = await CoveoSearchRequestWithRetry({
    cq: `@job_item_type==ActiveJobs AND @isignoredjob==0`,
    q: `@jobid==${id} AND @joblanguageid==${langId || 1}`,
  });
  if (response?.results?.length > 0) return response.results[0];
  return undefined;
};

export const fetchJobDetails3 = async (
  id: string | undefined,
  langId: string | undefined
): Promise<CoveoSearchApiCareersResult | undefined> => {
  if (!id) return undefined;
  const response = await CoveoSearchRequestWithRetry({
    cq: `@isignoredjob == 0`,
    q: `@jobid==${id} AND @joblanguageid==${langId || 1}`,
  });
  if (response?.results?.length > 0) return response.results[0];
  return undefined;
};

export const fetchJobsByLang = async (
  langId: string | undefined
): Promise<CoveoSearchApiCareersResult[]> => {
  return await fetchJobs({
    cq: `@job_item_type==ActiveJobs AND @isignoredjob == 0`,
    q: `@joblanguageid==${langId || 1}`,
    sortCriteria: '@jobposteddate descending, @jobname ascending',
  });
};

const CoveoSearchRequestWithRetry = (
  //jobdetails
  query: CoveoSearchApiQuery,
  retries = Number(config.coveoRetryAttempt)
): Promise<CoveoSearchApiResponse> => {
  return new Promise((resolve, reject) => {
    const coveoApiUrl = getCoveoApiUrl();
    query.searchHub = config.coveoCareersSearchHub;

    const retryRequest = (remainingRetries: number) => {
      fetch(coveoApiUrl, {
        headers: {
          Authorization: `Bearer ${config.coveoCareersAccessToken}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(query),
        next: {
          revalidate: revalidateInterval,
        },
      })
        .then((response) => {
          const coveoResponse = response.json();
          resolve(coveoResponse);
        })
        .catch((error) => {
          if (remainingRetries > 0) {
            // Retry the request with decremented retry count
            console.log(`[Error] Coveo retry attempts - ${remainingRetries}.`);
            setTimeout(() => {
              retryRequest(remainingRetries - 1);
            }, Number(config.coveoRetryDelay));
          } else {
            // No more retries left, reject with error
            console.log(`[Error] Coveo retry attempts exceeded.`);
            reject(error);
          }
        });
    };

    // Start the initial request
    retryRequest(retries);
  });
};
//jobs
export const CoveoSearchRequest = (query: CoveoSearchApiQuery): Promise<CoveoSearchApiResponse> => {
  return new Promise((resolve, reject) => {
    const coveoApiUrl = getCoveoApiUrl();
    query.searchHub = config.coveoCareersSearchHub;
    fetch(coveoApiUrl, {
      headers: {
        Authorization: `Bearer ${config.coveoCareersAccessToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(query),
      next: {
        revalidate: revalidateInterval,
      },
    })
      .then(async (response) => {
        const coveoResponse: CoveoSearchApiResponse = await response.json();
        resolve(coveoResponse);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const CoveoSearchPagesRequest = (
  //related client stories
  query: CoveoSearchApiQuery
): Promise<CoveoSearchPagesApiResponse> => {
  return new Promise((resolve, reject) => {
    //const coveoApiUrl = getCoveoApiUrl();
    const coveoUrl = `${
      getOrganizationEndpoints(config.coveoOrganizationId).search
    }?organizationId=${config.coveoOrganizationId}`;
    query.searchHub = config.coveoSearchHub;
    fetch(coveoUrl, {
      headers: {
        Authorization: `Bearer ${config.coveoAccessToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(query),
      next: {
        revalidate: revalidateInterval,
      },
    })
      .then((response) => {
        //const coveoResponse: CoveoSearchResult = response.data;
        resolve(response.json());
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const CoveoSearchAllPagesRequest = (
  query: CoveoSearchApiQuery
): Promise<CoveoSearchResult[]> => {
  return new Promise((resolve, reject) => {
    //const coveoApiUrl = getCoveoApiUrl();
    const coveoUrl = `${
      getOrganizationEndpoints(config.coveoOrganizationId).search
    }?organizationId=${config.coveoOrganizationId}`;
    query.searchHub = config.coveoSearchHub;
    fetch(coveoUrl, {
      headers: {
        Authorization: `Bearer ${config.coveoAccessToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(query),
      next: {
        revalidate: revalidateInterval,
      },
    })
      .then(async (response) => {
        const coveoResponse: CoveoSearchApiResponse = await response.json();
        // resolve(response.data.results);
        resolve(coveoResponse.results);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const CoveoSearchResultsRequest = (
  //recommended jobs
  query: CoveoSearchApiQuery
): Promise<Array<CoveoSearchApiCareersResult>> => {
  return new Promise<CoveoSearchApiCareersResult[]>((resolve, reject) => {
    const coveoApiUrl = getCoveoApiUrl();
    query.searchHub = config.coveoCareersSearchHub;

    // Check if the cache has the data
    if (cache.has(coveoApiUrl + query.searchHub)) {
      const cachedData: CoveoSearchApiCareersResult[] | undefined = cache.get(
        coveoApiUrl + query.searchHub
      );
      if (cachedData) {
        resolve(cachedData);
      }
    }
    // If not in cache, fetch the data
    try {
      fetch(coveoApiUrl, {
        headers: {
          Authorization: `Bearer ${config.coveoCareersAccessToken}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(query),
        next: {
          revalidate: revalidateInterval,
        },
      })
        .then(async (response) => {
          const coveoResponse: CoveoSearchApiResponse = await response.json();
          // Store the data in cache
          // cache.set(coveoApiUrl + query.searchHub, coveoResponse.results);
          resolve(coveoResponse.results);
        })
        .catch((error) => {
          reject(error);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const CoveoSeachValuesRequest = (fieldName: string): Promise<Array<string>> => {
  return new Promise((resolve, reject) => {
    const coveoApiUrl = getCoveoValuesApiUrl(fieldName);
    //Check if the cache has the data
    const cachedData: CoveoValuesReposponse | undefined = cache.get(coveoApiUrl + fieldName);
    if (cachedData) {
      const values = cachedData.values.map((item) => {
        return item.value;
      });
      resolve(values);
    }
    // If not in cache, fetch the data
    try {
      const query = { searchHub: config.coveoCareersSearchHub };
      fetch(coveoApiUrl, {
        headers: {
          Authorization: `Bearer ${config.coveoCareersAccessToken}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        method: 'GET',
        body: JSON.stringify(query),
        next: {
          revalidate: revalidateInterval,
        },
      })
        .then(async (response) => {
          const coveoResponse: CoveoValuesReposponse = await response.json();
          const values = coveoResponse.values.map((item) => {
            return item.value;
          });
          // Store the data in cache
          // cache.set(coveoApiUrl + fieldName, coveoResponse);
          resolve(values);
        })
        .catch((error) => {
          reject(error);
        });
    } catch (error) {
      reject(error);
    }
  });
};

const getCoveoValuesApiUrl = (fieldName: string, maximumNumberOfValues = 65536): string => {
  const coveoOrgId = config.coveoOrganizationId;
  // return `https://${coveoOrgId}.org.coveo.com/rest/search/v2/values?OrganizationId=${coveoOrgId}&field=${fieldName}&maximumNumberOfValues=${maximumNumberOfValues}`;
  return `https://${coveoOrgId}.org.coveo.com/rest/search/v2/values?organizationId=${coveoOrgId}&field=${fieldName}&maximumNumberOfValues=${maximumNumberOfValues}`;
};

const getCoveoApiUrl = (): string => {
  const coveoOrgId = config.coveoOrganizationId;
  // return `https://${coveoOrgId}.org.coveo.com/rest/search/v2?OrganizationId=${coveoOrgId}`;
  return `https://${coveoOrgId}.org.coveo.com/rest/search/v2?organizationId=${coveoOrgId}`;
};

export interface CoveoSearchApiQuery {
  locale?: string;
  searchHub?: string;
  tab?: string;
  cq?: string;
  aq?: string;
  q?: string;
  groupBy?: CoveoSearchApiQueryGroupBy[];
  sortCriteria?: string;
  numberOfResults?: number;
}

export interface CoveoSearchApiQueryGroupBy {
  field: string;
  sortCriteria: string;
}

export interface CoveoSearchApiResponse {
  totalCount: number;
  totalCountFiltered: number;
  duration: number;
  indexDuration: number;
  requestDuration: number;
  searchUid: string;
  pipeline: string;
  apiVersion: number;
  basicExpression: string;
  advancedExpression: null | string;
  largeExpression: null | string;
  constantExpression: string;
  disjunctionExpression: null | string;
  mandatoryExpression: null | string;
  userIdentities: CoveoSearchApiUserIdentity[];
  rankingExpressions: any[];
  topResults: any[];
  index: string;
  indexRegion: string;
  indexToken: string;
  refinedKeywords: any[];
  triggers: any[];
  termsToHighlight: Record<string, any>;
  phrasesToHighlight: Record<string, any>;
  queryCorrections: any[];
  groupByResults: any[];
  facets: any[];
  suggestedFacets: any[];
  categoryFacets: any[];
  results: CoveoSearchApiCareersResult[];
  extendedResults: Record<string, any>;
}

export interface CoveoSearchPagesApiResponse {
  totalCount: number;
  totalCountFiltered: number;
  duration: number;
  indexDuration: number;
  requestDuration: number;
  searchUid: string;
  pipeline: string;
  apiVersion: number;
  basicExpression: string;
  advancedExpression: null | string;
  largeExpression: null | string;
  constantExpression: string;
  disjunctionExpression: null | string;
  mandatoryExpression: null | string;
  userIdentities: CoveoSearchApiUserIdentity[];
  rankingExpressions: any[];
  topResults: any[];
  index: string;
  indexRegion: string;
  indexToken: string;
  refinedKeywords: any[];
  triggers: any[];
  termsToHighlight: Record<string, any>;
  phrasesToHighlight: Record<string, any>;
  queryCorrections: any[];
  groupByResults: any[];
  facets: any[];
  suggestedFacets: any[];
  categoryFacets: any[];
  results: CoveoSearchResult[];
  extendedResults: Record<string, any>;
}

export interface CoveoSearchApiCareersResult {
  title: string;
  uri: string;
  printableUri: string;
  clickUri: string;
  uniqueId: string;
  excerpt: string;
  firstSentences: null | string;
  summary: null | string;
  flags: string;
  hasHtmlVersion: boolean;
  hasMobileHtmlVersion: boolean;
  score: number;
  percentScore: number;
  rankingInfo: null | string;
  rating: number;
  isTopResult: boolean;
  isRecommendation: boolean;
  isUserActionView: boolean;
  titleHighlights: any[];
  firstSentencesHighlights: any[];
  excerptHighlights: any[];
  printableUriHighlights: any[];
  summaryHighlights: any[];
  parentResult: null | string;
  childResults: any[];
  totalNumberOfChildResults: number;
  absentTerms: any[];
  //raw: CoveoSearchApiCareersFields | CoveoSearchApiMasterDataFields;
  raw: any;
}
export interface CoveoSearchApiCareersFields {
  systitle: string;
  sysurihash: string;
  urihash: string;
  sysuri: string;
  systransactionid: number;
  sysconcepts: string;
  concepts: string;
  sysindexeddate: number;
  permanentid: string;
  syslanguage: string[];
  transactionid: number;
  title: string;
  date: number;
  jobareaofexpertise: string;
  jobdescription: string;
  jobname: string;
  sysconnectortype: string;
  rowid: number;
  size: number;
  jobposteddate: number;
  jobcountry: string;
  sitecorecountry: string;
  syssource: string;
  orderingid: number;
  syssize: number;
  sysdate: number;
  jobid: number;
  jobidstring: string;
  isignoredjob: number;
  wordcount: number;
  source: string;
  collection: string;
  indexeddate: number;
  connectortype: string;
  filetype: string;
  jobcityname: string;
  sysfiletype: string;
  language: string[];
  sysrowid: number;
  uri: string;
  syscollection: string;
  jobgreatplacetowork: string;
  jobwhatyouwilldo: string;
  joblearnmore: string;
  jobenjoyyourcareer: string;
  jobcomejoinus: string;
  jobskillsandexperiences: string;
  jobaboutyou: string;
}

export interface CoveoSearchApiMasterDataFields {
  systitle: string;
  job_expertise_language_id: number;
  sysurihash: string;
  urihash: string;
  sysuri: string;
  systransactionid: number;
  sysindexeddate: number;
  job_area_of_expertise: string;
  permanentid: string;
  transactionid: number;
  title: string;
  date: number;
  jobareaofexpertise: string;
  sysconnectortype: string;
  rowid: number;
  size: number;
  job_expertise_id: number;
  job_location_type: string;
  job_location_id: number;
  job_location_name: string;
  syssource: string;
  orderingid: number;
  syssize: number;
  sysdate: number;
  wordcount: number;
  source: string;
  collection: string;
  job_item_type: string;
  indexeddate: number;
  connectortype: string;
  filetype: string;
  sysfiletype: string;
  sysrowid: number;
  uri: string;
  syscollection: string;
}
export interface CoveoSearchApiUserIdentity {
  name: string;
  provider: string;
  type: string;
}

export interface CoveoValuesReposponse {
  values: CoveoValueResponse[];
}

export interface CoveoValueResponse {
  value: string;
  lookupValue: string;
  numberOfResults: number;
}
