import React from 'react';
import Dropdown, { Option } from 'react-dropdown';
import 'react-dropdown/style.css';
import sitecoreStyles from 'src/helpers/sitecoreStyles';

interface Fields {
  options: string[];
  activeItem: string;
}

type DropdownProps = {
  parentCallback(value: string): unknown;
  params: { [key: string]: string };
  fields: Fields;
};

const DropdownDefaultComponent = (props: DropdownProps): JSX.Element => (
  <div className={`dropdown ${sitecoreStyles(props.params?.styles)}`}>
    <div className="dropdown__content">Empty</div>
  </div>
);

export const Default = (props: DropdownProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;

  const options = props.fields.options;
  const defaultOption = props.fields.activeItem;

  const handleClick = (event: Option) => {
    props.parentCallback(event.value);
  };

  if (props.fields) {
    return (
      <div className={`dropdown ${sitecoreStyles(props.params?.styles)}`} id={id ? id : undefined}>
        <Dropdown
          className="dropdown__container"
          options={options}
          onChange={($event) => handleClick($event)}
          value={defaultOption}
          placeholder="Default"
        ></Dropdown>
      </div>
    );
  }
  return <DropdownDefaultComponent {...props} />;
};

export default Default;
