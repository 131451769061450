import React, { useEffect, useState } from 'react';
import {
  ComponentParams,
  ComponentRendering,
  Field,
  ImageField,
  LinkField,
  Text,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import config from 'temp/config';
import 'animate.css';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import dynamic from 'next/dynamic';
import LazyLoadComponent from 'components/shared/lazy-load/LazyLoadComponent';
import { InView } from 'react-intersection-observer';
import useMediaQuery from 'components/hooks/media-query-hook';

export interface ScrollingAccordionCardFields {
  Title: Field<string>;
  Content: Field<string>;
  Link: LinkField;
  Image: ImageField;
}

export interface ScrollingAccordionStepFields {
  id: string;
  title: {
    jsonValue: Field<string>;
  };
  link: {
    jsonValue: LinkField;
  };
  children: {
    results: ScrollingAccordionCardFields[];
    total: number;
  };
}

export interface ScrollingAccordionDatasource {
  id: string;
  title?: {
    jsonValue: Field<string>;
  };
  children: {
    results: ScrollingAccordionStepFields[];
    total: number;
  };
}

interface Fields {
  data: {
    datasource: ScrollingAccordionDatasource;
  };
}

export type ScrollingAccordionProps = {
  children?: React.ReactNode[];
  rendering: ComponentRendering & { params: ComponentParams };
  params: { [key: string]: string };
  fields: Fields;
};

let headerElement: Element | null;
let jumpLinksNavBox: Element | null;
let leftPanelTopOffset: number;

const ScrollingAccordionDefaultComponent = (props: ScrollingAccordionProps): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  return (
    <React.Fragment>
      {editingMode && (
        <div
          className={`scrolling-accordion component--with-paddings ${sitecoreStyles(
            props?.params?.styles
          )}`}
        >
          <div className="component-content">Scrolling accordion</div>
        </div>
      )}
    </React.Fragment>
  );
};

export const Default = (props: ScrollingAccordionProps): JSX.Element => {
  const datasource = props.fields?.data?.datasource;
  const sections = datasource?.children.results;
  const id = props.params?.RenderingIdentifier;
  const sectionIndex = datasource?.children.results;
  const [currentIndex, setCurrentIndex] = useState(0);
  const styles = props?.params?.styles || '';
  const [editingMode] = useState(isEditorActive());
  const sitecoreContext = useSitecoreContext();
  const isDesktop = useMediaQuery('(min-width: 1025px)');

  const setLeftPanelTopOffset = () => {
    requestAnimationFrame(() => {
      const isJumpLinksSticky = document.querySelector('.jl__sticky');

      if (isJumpLinksSticky) {
        headerElement = document.querySelector('#header');
        const headerElementHeight = headerElement?.getBoundingClientRect().height;
        jumpLinksNavBox = document.querySelector('.jump-links--sticky');
        const jumpLinksNavBoxHeight = jumpLinksNavBox
          ? jumpLinksNavBox.getBoundingClientRect().height
          : 0;
        if (headerElementHeight && jumpLinksNavBoxHeight) {
          leftPanelTopOffset = headerElementHeight + jumpLinksNavBoxHeight + 40;
          setTimeout(() => {
            const leftPanel = document.querySelector('.scrolling-accordion__left-container');
            leftPanel?.setAttribute('style', 'top: ' + leftPanelTopOffset + 'px');

            const slider = document.querySelector('.scrolling-accordion__slider');
            slider?.setAttribute('style', 'top: ' + leftPanelTopOffset + 'px');
          }, 500);
        }
      }
    });
  };
  useEffect(() => {
    window.addEventListener('scroll', setLeftPanelTopOffset);
    return () => {
      window.removeEventListener('scroll', setLeftPanelTopOffset);
    };
  }, []);
  useEffect(() => {
    window.addEventListener('scroll', setLeftPanelTopOffset);
  });

  let DynamicScrollingAccordionEditView;
  const editingmode = isEditorActive();
  if (editingmode) {
    // Static import when in editing mode
    DynamicScrollingAccordionEditView =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('./partials/scrolling-accordion-edit-view/ScrollingAccordionEditView').ScrollingAccordionEditView;
  } else {
    // Dynamic import when not in editing mode
    DynamicScrollingAccordionEditView = dynamic(
      () =>
        import('./partials/scrolling-accordion-edit-view/ScrollingAccordionEditView').then(
          (mod) => mod.ScrollingAccordionEditView
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }

  const setRightPanelTopOffset = () => {
    requestAnimationFrame(() => {
      const isJumpLinksSticky = document.querySelector('header');

      if (isJumpLinksSticky) {
        headerElement = document.querySelector('#header');
        const headerElementHeight = headerElement?.getBoundingClientRect().height;
        jumpLinksNavBox = document.querySelector('.jump-links');
        const jumpLinksNavBoxHeight = jumpLinksNavBox?.getBoundingClientRect().height;
        const jumpLinksList = document.querySelector('.jump-links__list');

        // Check if jump-links__list is empty
        if (jumpLinksList && jumpLinksList.children.length === 0) {
          if (headerElement) {
            headerElement.classList.remove('jl__sticky'); // Remove class if list is empty
            headerElement?.setAttribute('style', 'box-shadow: 0 10px 20px 5px rgba(0, 0, 0, 0.05)');
          }
          if (jumpLinksNavBox) {
            jumpLinksNavBox?.setAttribute('style', 'display: none'); // Hide the sticky jump links
          }
        }

        if (headerElementHeight && jumpLinksNavBoxHeight) {
          leftPanelTopOffset = headerElementHeight + jumpLinksNavBoxHeight + 40;
          setTimeout(() => {
            const leftPanel = document.querySelector('.scrolling-accordion__left-container');
            leftPanel?.setAttribute('style', 'top: ' + leftPanelTopOffset + 'px');

            const slider = document.querySelector('.scrolling-accordion__slider');
            slider?.setAttribute('style', 'top: ' + leftPanelTopOffset + 'px');

            const inviewPanel = document.querySelector('.inView-container');
            inviewPanel?.setAttribute('style', 'top: ' + leftPanelTopOffset + 'px');
          }, 500);
        }
      }
    });
  };

  useEffect(() => {
    // Set the left panel offset on initial render
    setRightPanelTopOffset();

    // Add the scroll event listener
    window.addEventListener('scroll', setRightPanelTopOffset);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', setRightPanelTopOffset);
    };
  }, []); // Empty dependency array to run only on mount

  //code for scroll-based navigation, highlighting active sections, or updating UI accordingly.
  useEffect(() => {
    const handleScroll = () => {
      sections.forEach((section, index) => {
        const sectionElement = document.getElementById(section.id);
        if (sectionElement) {
          const rect = sectionElement.getBoundingClientRect();
          if (rect.top >= 0 && rect.top < window.innerHeight / 2) {
            setCurrentIndex(index);
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sections]);

  console.log('---->[ScrollingAccordion] Component', datasource);
  if (editingMode) {
    return <DynamicScrollingAccordionEditView {...props}></DynamicScrollingAccordionEditView>;
  }

  let DynamicSlider;
  if (editingmode) {
    // Static import when in editing mode
    DynamicSlider =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/scrolling-accordion/partials/slider/Slider').Slider;
  } else {
    // Dynamic import when not in editing mode
    DynamicSlider = dynamic(
      () =>
        import('components/scrolling-accordion/partials/slider/Slider').then((mod) => mod.Slider),
      { ssr: !!config.dynamicImportSsr }
    );
  }

  let DynamicTertiary;
  if (editingmode) {
    // Static import when in editing mode
    DynamicTertiary =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/link/Link').Tertiary;
  } else {
    // Dynamic import when not in editing mode
    DynamicTertiary = dynamic(() => import('components/link/Link').then((mod) => mod.Tertiary), {
      ssr: !!config.dynamicImportSsr,
    });
  }

  let DynamicChildrenOrPlaceholder;
  if (editingmode) {
    // Static import when in editing mode
    DynamicChildrenOrPlaceholder =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/shared/children-or-placeholder/ChildrenOrPlaceholder').ChildrenOrPlaceholder;
  } else {
    // Dynamic import when not in editing mode
    DynamicChildrenOrPlaceholder = dynamic(
      () =>
        import('components/shared/children-or-placeholder/ChildrenOrPlaceholder').then(
          (mod) => mod.ChildrenOrPlaceholder
        ),
      {
        ssr: !!config.dynamicImportSsr,
      }
    );
  }
  let DynamicScrollingAccordionMobileView;
  if (editingmode) {
    // Static import when in editing mode
    DynamicScrollingAccordionMobileView =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/scrolling-accordion/partials/scrolling-accordion-mobile-view/ScrollingAccordionMobileView').ScrollingAccordionMobileView;
  } else {
    // Dynamic import when not in editing mode
    DynamicScrollingAccordionMobileView = dynamic(
      () =>
        import(
          'components/scrolling-accordion/partials/scrolling-accordion-mobile-view/ScrollingAccordionMobileView'
        ).then((mod) => mod.ScrollingAccordionMobileView),
      { ssr: !!config.dynamicImportSsr }
    );
  }

  if (datasource) {
    return (
      <LazyLoadComponent componentId="scrollingAccordion">
        {isDesktop ? (
          <div
            className={`scrolling-accordion component--with-paddings ${styles}`.trim()}
            id={id ? id : undefined}
          >
            <div className="scrolling-accordion__left-container">
              <Text
                tag="h2"
                className="scrolling-accordion__header"
                field={datasource?.title?.jsonValue}
              />

              <div className="scrolling-accordion__sectionIndex">
                {sectionIndex.map((section, index) => (
                  <a
                    className={`${
                      index === currentIndex
                        ? 'h3 scrolling-accordion__sectionIndex__option--current'
                        : 'body2'
                    }`}
                    key={section.id}
                    href={`#${section.id}`}
                    aria-label={section.title.jsonValue.value}
                    data-analytics-link-name={section.title.jsonValue.value}
                    data-analytics-link-type="Engagement"
                    data-analytics-content-class="Content"
                    data-analytics-template-zone="Body"
                    data-analytics-component-name="Accordion Module"
                    data-analytics-component-variation="Scrolling Accordion"
                    data-analytics-target={`#${section.id}`}
                    data-analytics-component-section="Body"
                    data-analytics-slide-number="NAN"
                    data-analytics-ispersonalized="False"
                    data-analytics-iscarousal="False"
                  >
                    {section.title.jsonValue.value}
                  </a>
                ))}
              </div>
            </div>
            <div className="scrolling-accordion__slider">
              <DynamicSlider
                incompleteCount={sections.length - 1 - currentIndex}
                doneCount={currentIndex}
              ></DynamicSlider>
            </div>

            <div className="inView-container">
              {sections.map((section, index) => (
                <InView
                  id={section.id}
                  key={index}
                  as="div"
                  threshold={0.4}
                  onChange={(inView) => {
                    inView && setCurrentIndex(index);
                  }}
                  className={`scrolling-accordion__inView  ${
                    index === currentIndex
                      ? 'animate__animated animate__fadeIn'
                      : 'scrolling-accordion__inView--hidden'
                  }`}
                >
                  <Text
                    tag="h6"
                    className="scrolling-accordion__inView__title"
                    field={section.title.jsonValue}
                  ></Text>{' '}
                  <DynamicChildrenOrPlaceholder
                    name={`accordion-card-${index + 1}`}
                    rendering={props.rendering}
                    params={{ scrolling: 'true' }}
                    sitecoreContext={sitecoreContext}
                  >
                    {props.children ? props.children : null}
                  </DynamicChildrenOrPlaceholder>
                  <DynamicTertiary
                    fields={{
                      Link: section.link.jsonValue,
                    }}
                    params={{}}
                  />
                </InView>
              ))}
            </div>
          </div>
        ) : (
          <div className={'scrolling-accordion-mobile-view'}>
            <DynamicScrollingAccordionMobileView {...props}></DynamicScrollingAccordionMobileView>
          </div>
        )}
      </LazyLoadComponent>
    );
  }
  return <ScrollingAccordionDefaultComponent {...props} />;
};
