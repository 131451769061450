import React from 'react';
import { EmblaOptionsType } from 'embla-carousel';
import { EvidenceCard } from 'components/shared/evidence-card/EvidenceCard';
import { EmblaCarousel } from 'components/shared/embla-carousel/EmblaCarousel';
import { CarouselCard } from 'components/carousel/partials/carousel-content/CarouselContent';
import sitecoreStyles from 'src/helpers/sitecoreStyles';

//component properties

type EvidenceCarouselProps = {
  id?: string;
  options?: EmblaOptionsType;
  slides?: CarouselCard[];
  params: { styles: string };
};

export const EvidenceCarousel = ({
  id,
  slides,
  options,
  params,
}: EvidenceCarouselProps): JSX.Element => {
  if (!slides) {
    return <></>;
  }

  return (
    <div className={`evidence component--with-paddings ${sitecoreStyles(params?.styles)}`}>
      <EmblaCarousel
        className="evidence__carousel"
        options={{ loop: true, watchDrag: slides.length > 1 ? true : false, ...options }}
        type="centerPagination"
        key={id}
      >
        {slides?.map((value, index) => {
          if (value?.link?.value?.href?.startsWith('http://#videoid')) {
            value.link.value.href = value.link.value.href?.replace('http://#videoid', '#videoid');
          }
          return (
            <EvidenceCard
              key={index}
              quote={value?.body}
              author={value?.area}
              authorLink={value?.link}
            ></EvidenceCard>
          );
        })}
      </EmblaCarousel>
    </div>
  );
};
