/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, useState } from 'react';
import {
  ComponentRendering,
  GetStaticComponentProps,
  useComponentProps,
} from '@sitecore-jss/sitecore-jss-nextjs';
//import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { useI18n } from 'next-localization';
import config from 'temp/config';
import { CoveoSearchResult } from '../../types/Coveo';
import { CoveoSearchApiQuery, CoveoSearchPagesRequest, fetchPages } from 'lib/coveo/CoveoSearchApi';
import { cacheProvider } from 'lib/cache/cacheProvider';
import { timestampDateToISO } from '../../utils/formatUtils';
import { formatGuid } from '../../utils/formatUtils';
import dynamic from 'next/dynamic';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
const fetchClientStories = async (
  locale: string,
  editingMode: boolean
): Promise<CoveoSearchResult[]> => {
  let cacheKey = `${process.env.PUBLIC_URL}_client_stories_${locale}`;
  let query: CoveoSearchApiQuery = {
    aq: `@pagetype=='ClientStoryPage'`,
    locale: locale,
  };
  if (editingMode) {
    // take only 16 random results just for showing dummy content in editing mode
    cacheKey = `${cacheKey}_editing`;
    query = {
      ...query,
      numberOfResults: 16,
    };
    const response = await CoveoSearchPagesRequest(query);
    return response.results;
  }
  let clientStories = await cacheProvider.get<CoveoSearchResult[]>(cacheKey);
  if (!clientStories) {
    if (editingMode) {
      const response = await CoveoSearchPagesRequest(query);
      clientStories = response.results;
    } else {
      clientStories = await fetchPages(query);
    }
    if (clientStories && clientStories.length > 0) {
      const ttl = parseInt(process.env.RELATED_CLIENT_STORIES_CACHE_DURATION || '21600'); // 6 hours
      await cacheProvider.set(cacheKey, clientStories, ttl); // 6 hours ttl
    }
  }
  return clientStories;
};

export const getStaticProps: GetStaticComponentProps = async (props, _layoutData, context) => {
  const relatedStories = (props.fields?.relatedStories as unknown as {
    [key: string]: RelatedClientStoryPageField[];
  }) || { Industry: [], Geography: [], Services: [], Technology: [] };

  const contextTags = (props?.fields?.contextTags as unknown as { [key: string]: string[] }) || {
    Industry: [],
    Geography: [],
    Services: [],
    Technology: [],
  };

  let clientStories: CoveoSearchResult[] = [];
  //if (!_layoutData.sitecore.context.pageEditing) {
  clientStories = await fetchClientStories(
    context.locale || 'en',
    _layoutData.sitecore.context.pageState !== 'normal'
  );
  //}

  // remove current page
  clientStories = clientStories.filter(
    (item) => formatGuid(item.raw?.pageid) !== formatGuid(_layoutData.sitecore.route?.itemId)
  );

  const processExtras = (category: string, storiesToExclude: RelatedClientStoryPageField[]) => {
    const fieldKey = `taxonomy_${category.toLowerCase()}`;
    const tagKey = `avanade_${fieldKey}`;
    const alreadySelected = [...relatedStories[category], ...storiesToExclude];
    const alreadySelectedUrls = alreadySelected.map((item) => item.url);

    const extras = clientStories
      .map((item) => {
        const tags = (item.raw as Record<string, string[]>)?.[fieldKey];
        const matches = tags
          ? tags.filter((tag) => contextTags?.[tagKey]?.includes(tag)).length
          : 0;
        return {
          ...item,
          matches,
        };
      })
      .filter((item) => item.Uri && !alreadySelectedUrls.includes(item.Uri))
      .sort((a, b) => b.matches - a.matches);

    const matchedExtras = extras.filter((item) => item.matches > 0);
    const uniqueExtras = [...matchedExtras, ...extras.filter((item) => item.matches === 0)].slice(
      0,
      4
    );
    return uniqueExtras.map((item) => {
      const result: RelatedClientStoryPageField = {
        url: item.Uri!,
        referenceTitle: item.raw?.pagetitle || null,
        referenceText: item.raw?.pagereftext || null,
        referenceImage: item.raw?.pagerefimage || null,
        postedDate: timestampDateToISO(item.raw?.date) || null,
      };
      return result;
    });
  };

  const industryExtras = processExtras('Industry', []);
  const geographyExtras = processExtras('Geography', [...industryExtras]);
  const servicesExtras = processExtras('Services', [...industryExtras, ...geographyExtras]);
  const technologyExtras = processExtras('Technology', [
    ...industryExtras,
    ...geographyExtras,
    ...servicesExtras,
  ]);

  return {
    Industry: industryExtras,
    Geography: geographyExtras,
    Services: servicesExtras,
    Technology: technologyExtras,
  };
};

interface RelatedClientStoryPageField {
  postedDate?: string | null;
  referenceCategory?: string | null;
  referenceImage?: string | null;
  referenceText?: string | null;
  referenceTitle?: string | null;
  url: string;
}

type RelatedClientStoryProps = {
  params: { [key: string]: string; styles: string };
  fields: {
    title?: string;
    linkUrl?: string;
    linkText?: string;
    linkTarget?: string;
    relatedStories: { [key: string]: RelatedClientStoryPageField[] };
    contextTags: { [key: string]: string[] };
  };
  rendering: ComponentRendering;
};

export interface ContentTab {
  tabName: string | undefined;
  active: boolean;
  content: RelatedClientStoryPageField[] | undefined;
}
// eslint-disable-next-line prettier/prettier, @typescript-eslint/no-unused-vars

export const Default = (props: RelatedClientStoryProps): JSX.Element => {
  console.log('---->[RelatedClientStory] Component', props);
  //const [editingMode] = useState(isEditorActive());
  const { sitecoreContext } = useSitecoreContext();
  const [editingMode] = useState(sitecoreContext && sitecoreContext.pageState === 'edit');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const externalData = useComponentProps<{ [key: string]: RelatedClientStoryPageField[] }>(
    props.rendering.uid
  );
  const { t } = useI18n();

  const datasource = props.fields;
  const id = props.params.RenderingIdentifier;
  const contentTabs: ContentTab[] = [];

  const categories = Object.keys(datasource.relatedStories || []);

  const tabsData = useMemo(() => {
    categories.forEach((category, index) => {
      contentTabs.push({
        tabName: t(category.toLowerCase()) || category,
        active: index === 0,
        content: [
          ...datasource.relatedStories[category],
          ...(externalData?.[category] || []),
        ].slice(0, 4),
      });
    });

    if (editingMode) {
      return contentTabs;
    }
    const filterContent = contentTabs.filter((tab) => tab.content?.length);
    if (filterContent.length > 0) {
      filterContent[0].active = true;
    }
    return filterContent;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [tabs, setTabs] = useState(tabsData);
  const [activeCategory, setActiveCategory] = useState(tabsData[0]);

  const handleClick = (obj: string) => {
    let activeTab: ContentTab;
    const tmpTabs = tabs.map((tmp) => {
      tmp.active = false;
      if (tmp.tabName == obj) {
        tmp.active = true;
        activeTab = tmp;
        setActiveCategory(activeTab);
      }
      return tmp;
    });
    setTabs([...tmpTabs]);
  };

  const editingmode = isEditorActive();

  let DynamicDropdown;
  if (editingmode) {
    // Static import when in editing mode
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicDropdown = require('components/shared/dropdown/Dropdown').Default;
  } else {
    // Dynamic import when not in editing mode
    DynamicDropdown = dynamic(
      () => import('components/shared/dropdown/Dropdown').then((mod) => mod.Default),
      { ssr: !!config.dynamicImportSsr }
    );
  }

  let DynamicTabs;
  if (editingmode) {
    // Static import when in editing mode
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicTabs = require('components/shared/tabs/Tabs').Tabs;
  } else {
    // Dynamic import when not in editing mode
    DynamicTabs = dynamic(() => import('components/shared/tabs/Tabs').then((mod) => mod.Tabs), {
      ssr: !!config.dynamicImportSsr,
    });
  }

  let DynamicContentGalleryCard;
  if (editingmode) {
    // Static import when in editing mode
    DynamicContentGalleryCard =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/content-gallery/partials/content-gallery-card/ContentGalleryCard').ContentGalleryCard;
  } else {
    // Dynamic import when not in editing mode
    DynamicContentGalleryCard = dynamic(
      () =>
        import('components/content-gallery/partials/content-gallery-card/ContentGalleryCard').then(
          (mod) => mod.ContentGalleryCard
        ),
      {
        ssr: !!config.dynamicImportSsr,
      }
    );
  }

  let DynamicTertiary;

  if (editingmode) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicTertiary = require('components/link/Link').Tertiary;
  } else {
    // Dynamic import when not in editing mode
    DynamicTertiary = dynamic(() => import('components/link/Link').then((mod) => mod.Tertiary), {
      ssr: !!config.dynamicImportSsr,
    });
  }
  if (datasource) {
    return (
      <>
        {
          <div className="related-client-stories component--with-paddings" id={id ? id : undefined}>
            {datasource.title && (
              <div className="row related-client-stories__header-container">
                <div className="col-12 col-md-8 col-lg-10">
                  <h2 className="related-client-stories__title">{datasource.title}</h2>
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-12">
                <div className="related-client-stories__main-container">
                  <div className="related-client-stories__category">Category</div>
                  <div className="related-client-stories__dropdown">
                    <DynamicDropdown
                      parentCallback={handleClick}
                      params={{}}
                      fields={{
                        options: tabs.map((el) => el.tabName ?? ''),
                        activeItem: activeCategory?.tabName ?? '',
                      }}
                    ></DynamicDropdown>
                  </div>
                  <div className="related-client-stories__mobile-cards-container">
                    {activeCategory?.content?.map((relatedStory, index) => {
                      return (
                        <div className="related-client-stories__card-container" key={index}>
                          <DynamicContentGalleryCard
                            id={index.toString()}
                            referenceImage={
                              relatedStory.referenceImage
                                ? {
                                    jsonValue: {
                                      value: {
                                        src: relatedStory.referenceImage,
                                        width: '264',
                                        height: '264',
                                      },
                                    },
                                  }
                                : undefined
                            }
                            referenceTitle={{
                              jsonValue: { value: relatedStory.referenceTitle || '' },
                            }}
                            referenceText={{
                              jsonValue: { value: relatedStory.referenceText || '' },
                            }}
                            referenceCategory={{
                              targetItems: [
                                {
                                  title: {
                                    jsonValue: { value: relatedStory?.referenceCategory || '' },
                                  },
                                },
                              ],
                            }}
                            url={{
                              url: relatedStory.url,
                            }}
                          ></DynamicContentGalleryCard>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="related-client-stories__all-cards-container">
                  <DynamicTabs parentCallback={handleClick} fields={{ tabs: tabs }} params={{}}>
                    <div className="related-client-stories__card-big-container">
                      {activeCategory?.content?.map((relatedStory, index) => {
                        return (
                          <div
                            className="related-client-stories__card-container"
                            key={`${index}-dropdown`}
                          >
                            <DynamicContentGalleryCard
                              id={index.toString()}
                              referenceImage={
                                relatedStory.referenceImage
                                  ? {
                                      jsonValue: {
                                        value: {
                                          src: relatedStory.referenceImage,
                                          width: '264',
                                          height: '264',
                                        },
                                      },
                                    }
                                  : undefined
                              }
                              referenceText={{
                                jsonValue: { value: relatedStory.referenceText || '' },
                              }}
                              referenceTitle={{
                                jsonValue: { value: relatedStory.referenceTitle || '' },
                              }}
                              referenceCategory={{
                                targetItems: [
                                  {
                                    title: {
                                      jsonValue: { value: relatedStory?.referenceCategory || '' },
                                    },
                                  },
                                ],
                              }}
                              url={{
                                url: relatedStory.url || '',
                              }}
                            ></DynamicContentGalleryCard>{' '}
                          </div>
                        );
                      })}
                    </div>
                  </DynamicTabs>
                </div>
              </div>
            </div>
            {(props.fields?.linkUrl || editingMode) && (
              <div className="row">
                <div className="col-12">
                  <div className="related-client-stories__link">
                    <DynamicTertiary
                      fields={{
                        Link: {
                          value: {
                            href: props.fields?.linkUrl,
                            title: props.fields?.linkText,
                            text: props.fields?.linkText,
                            target: props.fields?.linkTarget,
                          },
                        },
                      }}
                      params={{}}
                    ></DynamicTertiary>
                  </div>
                </div>
              </div>
            )}
          </div>
        }
      </>
    );
  }

  return <RelatedClientStoryDefaultComponent {...props} />;
};

export const RelatedClientStoryDefaultComponent = (props: RelatedClientStoryProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const [editingMode] = useState(sitecoreContext && sitecoreContext.pageState === 'edit');
  // const [editingMode] = useState(isEditorActive());
  return (
    <React.Fragment>
      {editingMode && (
        <div className={`related-client-stories component--with-paddings ${props.params.styles}`}>
          <span className="is-empty-hint">Related Client Stories</span>
        </div>
      )}
    </React.Fragment>
  );
};
