import React from 'react';
import { Field, Text, RichText, RichTextField } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import { ContactCountryFields } from '../contact-country/ContactCountry';
import { useState } from 'react';
import { IconName } from 'src/types/Enum';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useI18n } from 'next-localization';
import config from 'temp/config';
export interface OfficeDirectoryFields {
  id: string;
  officeName?: {
    jsonValue?: Field<string>;
  };
  country?: {
    targetItem?: ContactCountryFields;
  };
  contactName?: {
    jsonValue: Field<string>;
  };
  address?: {
    jsonValue: RichTextField;
  };
  phoneOne?: {
    jsonValue: Field<string>;
  };
  phoneTwo?: {
    jsonValue: Field<string>;
  };
  fax?: {
    jsonValue: Field<string>;
  };
}

export interface OfficeDirectoryDatasource {
  data: {
    datasource: {
      children: {
        results: OfficeDirectoryFields[];
      };
    };
  };
}
type OfficeDirectoryProps = {
  params: { [key: string]: string };
  fields?: OfficeDirectoryDatasource;
};

const editingmode = isEditorActive();

let DynamicIcon;

if (editingmode) {
  // Static import when in editing mode
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  DynamicIcon = require('components/shared/icon/Icon').Icon;
} else {
  // Dynamic import when not in editing mode
  DynamicIcon = dynamic(() => import('components/shared/icon/Icon').then((mod) => mod.Icon), {
    ssr: !!config.dynamicImportSsr,
  });
}

export const Default = (props: OfficeDirectoryProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const datasource = props?.fields?.data?.datasource;
  console.log('---->[OfficeDirectory] Component', props);
  const { t, locale } = useI18n();

  const getCountryCode = () => {
    const counryCode = locale().split('-').slice(-1);
    return counryCode[0];
  };

  const getCountryNameByCode = () => {
    const code = getCountryCode();
    const country = datasource?.children?.results?.find((item) => {
      return (
        item.country?.targetItem?.countryISO2Name?.value.trim().toUpperCase() == code.toUpperCase()
      );
    });
    return country?.country?.targetItem?.countryName?.jsonValue.value || 'United States';
  };

  getCountryNameByCode();
  const [isAccordionOpen, setIsAccordionOpen] = useState(-1);
  const [filteredCountry, setFilteredCountry] = useState(getCountryNameByCode());
  const [editingMode] = useState(isEditorActive());

  const toggleAccordion = (index: number) => {
    if (index !== isAccordionOpen) {
      setIsAccordionOpen(index);
    } else {
      setIsAccordionOpen(-1);
    }
  };

  const countryArray: string[] = [];

  const prepareCountryArray = () => {
    if (datasource) {
      datasource.children.results.forEach((item) => {
        const tmpCountryElement = countryArray.find(
          (element) => element == item.country?.targetItem?.countryName?.jsonValue.value
        );

        if (!tmpCountryElement) {
          item.country?.targetItem?.countryName?.jsonValue?.value
            ? countryArray.push(item.country?.targetItem?.countryName?.jsonValue?.value)
            : '';
        }
      });
    }
  };

  const formatCountryArray = () => {
    for (let i = 0; i < countryArray.length; i++) {
      countryArray[i] = countryArray[i].charAt(0).toUpperCase() + countryArray[i].slice(1);
    }
    countryArray.sort();
  };
  prepareCountryArray();
  formatCountryArray();

  const handleClick = (item: string) => {
    setFilteredCountry(item);
  };
  const editingmode = isEditorActive();

  let DynamicDropdown;

  if (editingmode) {
    // Static import when in editing mode
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicDropdown = require('components/shared/dropdown/Dropdown').Default;
  } else {
    // Dynamic import when not in editing mode
    DynamicDropdown = dynamic(
      () => import('components/shared/dropdown/Dropdown').then((mod) => mod.Default),
      { ssr: !!config.dynamicImportSsr }
    );
  }
  if (datasource) {
    return (
      <div
        className={`office-directory component--with-paddings ${sitecoreStyles(
          props.params.styles
        )}`}
        id={id ? id : undefined}
      >
        {!editingMode && (
          <div className="row office-directory__dropdown-row">
            <div className=" col-12 col-md-8 col-lg-6">
              <div className="office-directory__dropdown-title">Country</div>
              <DynamicDropdown
                parentCallback={handleClick}
                params={{}}
                fields={{
                  options: countryArray,
                  activeItem: filteredCountry,
                }}
              ></DynamicDropdown>
            </div>
          </div>
        )}

        <div className="office-directory__container">
          {datasource?.children?.results
            .filter(
              (item) =>
                item.country?.targetItem?.countryName?.jsonValue.value.toLowerCase() ==
                  filteredCountry?.toLowerCase() || filteredCountry == ''
            )
            .map((item, index) => (
              <div key={`item-${index}`} className="office-directory__item">
                <button
                  type="button"
                  title="accordion button"
                  onClick={() => toggleAccordion(index)}
                  className={`office-directory__item-header ${
                    isAccordionOpen === index ? 'office-directory__item-header--open' : ''
                  }`}
                >
                  <Text
                    tag="div"
                    className="office-directory__country-name h6"
                    field={item?.officeName?.jsonValue}
                  />

                  <div
                    className={`office-directory__icon ${
                      isAccordionOpen === index ? 'office-directory__icon--rotate' : ''
                    }`}
                  >
                    <DynamicIcon icon={IconName.ChevronDown} iconLabel={'Open / close'} />
                  </div>
                </button>
                <div
                  className={`office-directory__item-content ${
                    isAccordionOpen === index ? 'office-directory__item-content--open' : ''
                  }`}
                >
                  <div className="office-directory__inside-content">
                    <RichText
                      tag="div"
                      className={`body2 office-directory__address`}
                      field={item?.address?.jsonValue}
                    />
                    <div>
                      {editingMode ? (
                        <>
                          {item?.phoneOne?.jsonValue.value && (
                            <Text
                              tag="div"
                              className={`office-directory__item-phone-one`}
                              field={item?.phoneOne?.jsonValue}
                            />
                          )}

                          {item?.phoneTwo?.jsonValue.value && (
                            <>
                              <Text
                                tag="div"
                                className={`office-directory__item-phone-two`}
                                field={item?.phoneTwo?.jsonValue}
                              />
                              {t('component-contacts-tollfree')}
                            </>
                          )}

                          {item?.fax?.jsonValue.value && (
                            <Text
                              tag="div"
                              className={`office-directory__item-fax`}
                              field={item?.fax?.jsonValue}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          {item?.phoneOne?.jsonValue.value && (
                            <Link
                              href={`fax:${item?.phoneOne?.jsonValue.value}`}
                              className={`body1 office-directory__contacts-align`}
                            >
                              {item?.phoneOne?.jsonValue.value}
                            </Link>
                          )}
                          {item?.phoneTwo?.jsonValue.value && (
                            <>
                              <Link
                                href={`fax:${item?.phoneTwo?.jsonValue.value}`}
                                className={`body1 office-directory__contacts-align`}
                              >
                                {item?.phoneTwo?.jsonValue.value}
                              </Link>
                              {t('component-contacts-tollfree')}
                            </>
                          )}

                          {item?.fax?.jsonValue.value && (
                            <Link
                              href={`fax:${item?.fax?.jsonValue.value}`}
                              className={`body1 office-directory__contacts-align`}
                            >
                              {item?.fax?.jsonValue.value}
                            </Link>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }

  return <OfficeDirectoryDefaultComponent />;
};

export const DirectoryWithoutFilter = (props: OfficeDirectoryProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const datasource = props?.fields?.data?.datasource;
  console.log('---->[OfficeDirectory] Component', props);
  const [isAccordionOpen, setIsAccordionOpen] = useState(-1);

  const toggleAccordion = (index: number) => {
    if (index !== isAccordionOpen) {
      setIsAccordionOpen(index);
    } else {
      setIsAccordionOpen(-1);
    }
  };

  const [editingMode] = useState(isEditorActive());
  if (datasource) {
    return (
      <div
        className={`office-directory component--with-paddings ${sitecoreStyles(
          props.params.styles
        )}`}
        id={id ? id : undefined}
      >
        <div className="office-directory__container">
          {datasource?.children?.results.map((item, index) => (
            <div key={`item-${index}`} className="office-directory__item">
              <button
                type="button"
                title="accordion button"
                onClick={() => toggleAccordion(index)}
                className={`office-directory__item-header ${
                  isAccordionOpen === index ? 'office-directory__item-header--open' : ''
                }`}
              >
                <Text
                  tag="div"
                  className="office-directory__country-name h6"
                  field={item?.country?.targetItem?.countryName?.jsonValue}
                />

                <div
                  className={`office-directory__icon ${
                    isAccordionOpen === index ? 'office-directory__icon--rotate' : ''
                  }`}
                >
                  <DynamicIcon icon={IconName.ChevronDown} iconLabel={'Open / close'} />
                </div>
              </button>
              <Text
                tag="div"
                className={`body2 office-directory__office-name ${
                  isAccordionOpen == index ? 'office-directory__office-name--subheading1' : ''
                }`}
                field={item?.officeName?.jsonValue}
              />
              <div
                className={`office-directory__item-content ${
                  isAccordionOpen === index ? 'office-directory__item-content--open' : ''
                }`}
              >
                <div className="office-directory__inside-content">
                  <RichText
                    tag="div"
                    className={`body2 office-directory__address`}
                    field={item?.address?.jsonValue}
                  />
                </div>
                {editingMode ? (
                  <>
                    {item?.phoneOne?.jsonValue.value && (
                      <Text
                        tag="div"
                        className={`office-directory__item-phone-one`}
                        field={item?.phoneOne?.jsonValue}
                      />
                    )}
                    {item?.phoneTwo?.jsonValue.value && (
                      <Text
                        tag="div"
                        className={`office-directory__item-phone-two`}
                        field={item?.phoneTwo?.jsonValue}
                      />
                    )}
                    {item?.fax?.jsonValue.value && (
                      <Text
                        tag="div"
                        className={`office-directory__item-fax`}
                        field={item?.fax?.jsonValue}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {item?.phoneOne?.jsonValue.value && (
                      <Link
                        href={`fax:${item?.phoneOne?.jsonValue.value}`}
                        className={`body1 office-directory__contacts-align`}
                      >
                        {item?.phoneOne?.jsonValue.value}
                      </Link>
                    )}
                    {item?.phoneTwo?.jsonValue.value && (
                      <Link
                        href={`fax:${item?.phoneTwo?.jsonValue.value}`}
                        className={`body1 office-directory__contacts-align`}
                      >
                        {item?.phoneTwo?.jsonValue.value}
                      </Link>
                    )}
                    {item?.fax?.jsonValue.value && (
                      <Link
                        href={`fax:${item?.fax?.jsonValue.value}`}
                        className={`body1 office-directory__contacts-align`}
                      >
                        {item?.fax?.jsonValue.value}
                      </Link>
                    )}
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return <OfficeDirectoryDefaultComponent />;
};

export const OfficeDirectoryDefaultComponent = (): JSX.Element => (
  <div className="office-directory component--with-paddings">
    <span className="is-empty-hint">Office Directory</span>
  </div>
);
