import {
  buildSearchEngine,
  getOrganizationEndpoints,
  buildContext,
  AtomicSearchInterface,
  AtomicSearchBox,
  // AtomicResultList,
  // AtomicResultLink,
  // AtomicResultText,
  AtomicLoadMoreResults,
} from '@coveo/atomic-react';
import { useMemo, useEffect } from 'react';
import { Default as CustomBreadcrumbs } from './custom-breadcrumbs/CustomBreadcrumbs3';
import { CustomFacet } from './custom-facet/CustomFacet';
import { CustomQuerySummary } from './custom-query-summary/CustomQuerySummary';
import { CustomResultList } from './custom-results-list/CustomResultsList';

export const CoveoPocPage = (): JSX.Element => {
  const engine = useMemo(
    () =>
      buildSearchEngine({
        configuration: {
          organizationId: 'barcagroupproductionkwvdy6lp',
          accessToken: 'xx5a7943ef-ea52-42e5-8742-51198cc651f7',
          organizationEndpoints: getOrganizationEndpoints('barcagroupproductionkwvdy6lp'),
        },
      }),
    []
  );

  // buildContext(headlessEngine).add('website', 'engineering');

  useEffect(() => {
    buildContext(engine).add('website', 'engineering');
  }, [engine]);

  return (
    <div className="poc-page">
      <AtomicSearchInterface iconAssetsPath="" engine={engine}>
        <>
          <div className="poc-page__search-input">
            <AtomicSearchBox></AtomicSearchBox>
          </div>
          <div className="poc-page__filters-container">
            <p className="poc-page__filters-title body2">Filter by</p>
            <div className="row">
              <div className="col-12 col-md-6 col-lg-3 poc-page__custom-facet-container">
                <CustomFacet title="Industry" engine={engine} facetName="ec_brand"></CustomFacet>
              </div>
              <div className="col-12 col-md-6 col-lg-3 poc-page__custom-facet-container">
                <CustomFacet
                  title="Service"
                  engine={engine}
                  facetName="eng_processor"
                ></CustomFacet>
              </div>
              <div className="col-12 col-md-6 col-lg-3 poc-page__custom-facet-container">
                <CustomFacet
                  title="Technology"
                  engine={engine}
                  facetName="eng_processor"
                ></CustomFacet>
              </div>
              <div className="col-12 col-md-6 col-lg-3 poc-page__custom-facet-container">
                <CustomFacet title="Geography" engine={engine} facetName="store_name"></CustomFacet>
              </div>
            </div>
          </div>
        </>
        <CustomBreadcrumbs engine={engine}></CustomBreadcrumbs>
        <CustomQuerySummary engine={engine}></CustomQuerySummary>
        <CustomResultList engine={engine}></CustomResultList>
        {/* <AtomicResultList display="grid" template={searchResultItemTemplate}></AtomicResultList> */}
        <AtomicLoadMoreResults />
      </AtomicSearchInterface>
    </div>
  );
};
