import {
  WithSitecoreContextProps,
  withSitecoreContext,
  Field,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import Head from 'next/head';

import { useState } from 'react';

export interface LinkHrefFields {
  rel?: Field<string>;
  href?: Field<string>;
}

type LinkHrefProps = {
  params: { [key: string]: string };
  fields?: LinkHrefFields;
} & WithSitecoreContextProps;

const Default = (props: LinkHrefProps): JSX.Element => {
  console.log('---->[LinkHref] Component', props.fields);
  const [editingMode] = useState(isEditorActive());

  return (
    <>
      {editingMode && props.sitecoreContext.route?.templateName == 'Partial Design' ? (
        <div className="is-metadata-hint">
          <strong>Metadata:</strong> Link href
        </div>
      ) : (
        <Head>
          <>
            <link rel={props?.fields?.rel?.value} href={props?.fields?.href?.value} />
          </>
        </Head>
      )}
    </>
  );
};

export default withSitecoreContext()(Default);
