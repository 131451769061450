import React, { useState } from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { HeroProps } from 'components/hero/Hero';
import { Default as PrimaryButton } from 'components/link/Link';
import NextImageWithNullFallback from 'components/shared/nextImg/NextImageWithNullFallback';
import { HomepageWaves } from 'components/logo-lines/LogoLines';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { ImageLoadingStrategy } from '../../../../types/ImageLoadingStrategy';

export const DefaultHomepageHero = (): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  return (
    <React.Fragment>
      {editingMode && (
        <div className={`homepage-hero-square-image component--with-paddings`}>
          <span className="is-empty-hint">Homepage Hero Square Image</span>
        </div>
      )}
    </React.Fragment>
  );
};

export const HomepageHeroSquareImage = (props: HeroProps): JSX.Element => {
  const imageLoadingStrategy: ImageLoadingStrategy =
    (props.params && (props.params['ImageLoadingStrategy'] as ImageLoadingStrategy)) || undefined;
  const id = props.params.RenderingIdentifier;
  console.log('hero-->', props.fields);

  if (props.fields) {
    return (
      <div className="homepage-hero-square-image component--with-paddings" id={id ? id : undefined}>
        <div>
          <div className="homepage-hero-square-image__blurBG"> </div>

          <div className="container homepage-hero-square-image__content">
            <div className="row">
              <div className="col-12 col-md-8 col-lg-5 homepage-hero-square-image__text-container">
                <Text
                  tag="h1"
                  className="homepage-hero-square-image__title"
                  field={props.fields?.data.datasource?.title?.jsonValue}
                />
                <RichText
                  tag="p"
                  className="homepage-hero-square-image__paragraph subheading2"
                  field={props.fields?.data.datasource?.body?.jsonValue}
                />
                {props.fields?.data.datasource?.link?.jsonValue && (
                  <PrimaryButton
                    params={{ styles: 'homepage-hero-square-image__link link__button-primary' }}
                    fields={{ Link: props.fields?.data.datasource?.link?.jsonValue }}
                  ></PrimaryButton>
                )}
              </div>
              <div className="col-12 col-md-8 col-lg-7 homepage-hero-square-image__image-box">
                <NextImageWithNullFallback
                  field={props.fields?.data.datasource?.image?.jsonValue}
                  // width={658}
                  // height={658}
                  fill
                  sizes="(max-width: 1200px) 100vw, (min-width: 1201px) 39vw, 33vw"
                  loading={imageLoadingStrategy}
                  priority={imageLoadingStrategy == 'eager' ? true : false}
                  params={{
                    styles: 'next-image--square',
                  }}
                />
              </div>
            </div>
          </div>
          <HomepageWaves />
        </div>
      </div>
    );
  }
  return <DefaultHomepageHero />;
};
