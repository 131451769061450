import React, { useState } from 'react';
import { RichText, RichTextField, Text, Field } from '@sitecore-jss/sitecore-jss-nextjs';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

export interface FeatureMetricFields {
  metric?: Field<string>;
  prefix?: Field<string>;
  postfix?: Field<string>;
  title?: Field<string>;
  description: RichTextField;
}

export type FeatureMetricProps = {
  params: { [key: string]: string };
  fields?: FeatureMetricFields;
};

export const Default = (props: FeatureMetricProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  if (props.fields) {
    return (
      <div
        className={`feature-metric ${sitecoreStyles(props.params.styles)}`}
        id={id ? id : undefined}
      >
        <div className="feature-metric__container">
          <Text tag="div" field={props.fields.prefix} />
          <div className="feature-metric__number">
            <Text field={props.fields.metric} />
          </div>
          <div className="feature-metric__character">
            <Text tag="div" field={props.fields.postfix} />
          </div>
          <div className="feature-metric__content">
            <Text tag="div" field={props.fields.title} />
            <RichText tag="div" field={props.fields.description} />
          </div>
        </div>
      </div>
    );
  }
  return <FeatureMetricDefaultComponent {...props} />;
};
export const FeatureMetricDefaultComponent = (props: FeatureMetricProps): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  return (
    <React.Fragment>
      {editingMode && (
        <div
          className={`feature-metric component--with-paddings ${sitecoreStyles(
            props.params?.styles
          )}`}
        >
          <span className="is-empty-hint">Feature Metric</span>
        </div>
      )}
    </React.Fragment>
  );
};
