import { ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useState } from 'react';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import dynamic from 'next/dynamic';
import config from 'temp/config';
export interface ClientStoryLogoFields {
  logoImage?: {
    jsonValue: ImageField;
  };
}

type ClientStoryLogoProps = {
  params: { [key: string]: string };
  fields?: {
    data: {
      contextItem?: ClientStoryLogoFields;
    };
  };
};

export const Default = (props: ClientStoryLogoProps) => {
  const [editingMode] = useState(isEditorActive());
  console.log(
    '---->[ClientStoryLogoProps] Component',
    props.fields?.data?.contextItem?.logoImage?.jsonValue?.value
  );
  const editingmode = isEditorActive();

  let DynamicNextImageWithNullFallback;

  if (editingmode) {
    // Static import when in editing mode

    DynamicNextImageWithNullFallback =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/shared/nextImg/NextImageWithNullFallback').NextImageWithNullFallback;
  } else {
    // Dynamic import when not in editing mode
    DynamicNextImageWithNullFallback = dynamic(
      () =>
        import('components/shared/nextImg/NextImageWithNullFallback').then(
          (mod) => mod.NextImageWithNullFallback
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }
  return (
    <>
      {(props.fields?.data?.contextItem?.logoImage?.jsonValue?.value?.src || editingMode) && (
        <div className={`client-story-logo ${sitecoreStyles(props.params?.styles)}`}>
          {props.fields && props.fields?.data?.contextItem?.logoImage?.jsonValue && (
            <DynamicNextImageWithNullFallback
              field={props.fields?.data?.contextItem?.logoImage?.jsonValue}
              width={200}
              height={100}
              params={{
                styles: 'client-story-logo__image next-image--contain next-image--left-align',
              }}
            />
          )}
        </div>
      )}
    </>
  );
};
