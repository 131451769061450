import React, { useState } from 'react';
import { IconName, IconSize } from 'src/types/Enum';
import { WithSitecoreContextProps, withSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { removeColFromStylesProps } from 'src/helpers/sitecoreStyles';
import dynamic from 'next/dynamic';
import config from 'temp/config';
export interface BreadcrumbsComponentFields {
  title: string;
  url?: string;
  active: boolean;
}

type BreadcrumbsProps = {
  params: { [key: string]: string; styles: string };
  fields?: { [key: string]: BreadcrumbsComponentFields };
} & WithSitecoreContextProps;

export const Default = (props: BreadcrumbsProps) => {
  console.log('---->[breadcrumbs] Component', props.fields);

  const [editingMode] = useState(isEditorActive());
  const editingmode = isEditorActive();

  let DynamicIcon;

  // Static import when in editing mode
  if (editingmode) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicIcon = require('components/shared/icon/Icon').Icon;
  } else {
    // Dynamic import when not in editing mode
    DynamicIcon = dynamic(() => import('components/shared/icon/Icon').then((mod) => mod.Icon), {
      ssr: !!config.dynamicImportSsr,
    });
  }

  return (
    <>
      {editingMode && props?.sitecoreContext?.route?.templateName == 'Partial Design' ? (
        <div className={`is-metadata-hint ${removeColFromStylesProps(props.params?.styles)}`}>
          <strong>Breadcrumb</strong>
        </div>
      ) : (
        <div className={`breadcrumbs ${removeColFromStylesProps(props.params?.styles)}`}>
          <div className="breadcrumbs__container">
            {props.fields &&
              Object.values(props.fields).map((item, index) => (
                <div key={index} className="breadcrumbs__item">
                  <a
                    href={item.url}
                    className={`breadcrumbs__link ${item.active ? 'body1' : 'body2'}`}
                  >
                    {item.title}
                  </a>
                  <DynamicIcon
                    icon={IconName.ChevronRight}
                    iconLabel={'Chevron right'}
                    iconSize={IconSize.Size16}
                    additionalClass={'breadcrumbs__arrow'}
                  />
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
};

export default withSitecoreContext()(Default);
