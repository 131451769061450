/* eslint-disable @typescript-eslint/no-unused-vars */
import { buildFacet, loadPaginationActions } from '@coveo/atomic-react';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { Icon } from 'components/shared/icon/Icon';
import { useState, useEffect, useRef } from 'react';
import { IconName } from 'src/types/Enum';
import { SearchEngineContext } from '../search-context/SearchContext';
import React from 'react';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import { useSearchFacet } from 'lib/contexts/SearchFacetContext';
import { useRouter } from 'next/router';

interface SearchFacetFields {
  Field?: Field<string>;
  Label?: Field<string>;
}

type SortCriteria =
  | 'alphanumeric'
  | 'alphanumericDescending'
  | 'automatic'
  | 'occurrences'
  | 'score';
type ResultsMustMatch = 'allValues' | 'atLeastOneValue';

type SearchFacetProps = {
  params: { [key: string]: string };
  fields: SearchFacetFields;
};

// Locale-to-country mapping
const countryMap: Record<string, string> = {
  en: 'Global',
  'en-au': 'Australia',
  'de-at': 'Austria',
  'en-be': 'Belgium',
  'pt-br': 'Brazil',
  'en-ca': 'Canada',
  'fr-ca': 'Canada',
  'en-dk': 'Denmark',
  'de-de': 'Germany',
  'en-fi': 'Finland',
  'fr-fr': 'France',
  'en-ie': 'Ireland',
  'it-it': 'Italy',
  'ja-jp': 'Japan',
  'en-my': 'Malaysia',
  'en-nl': 'Netherlands',
  'en-no': 'Norway',
  'en-pl': 'Poland',
  'pt-pt': 'Portugal',
  'en-sg': 'Singapore',
  'es-es': 'Spain',
  'en-se': 'Sweden',
  'en-ch': 'Switzerland',
  'en-ae': 'United Arab Emirates',
  'en-gb': 'United Kingdom',
  'en-us': 'United States',
};
export const Default = (props: SearchFacetProps): JSX.Element => {
  console.log('---->[SearchFacet] Component', props);
  const urlName = useRouter();
  const { locale } = urlName;
  const country = countryMap[locale?.toLowerCase() || ''] || null;

  const numberOfValues = parseInt(props.params['NumberOfValues'] || '10');
  const sortCriteria = (props.params['SortCriteria'] || 'automatic') as SortCriteria;
  const resultsMustMatch = (props.params['ResultsMustMatch'] ||
    'atLeastOneValue') as ResultsMustMatch;

  const searchScope = props.params.SearchScope;
  const { getSearchEngine } = React.useContext(SearchEngineContext);
  const searchEngine = getSearchEngine(searchScope);

  const [headlessFacet] = useState(
    buildFacet(searchEngine, {
      options: {
        facetId: props.fields?.Field?.value || '',
        numberOfValues,
        sortCriteria,
        resultsMustMatch,
        field: props.fields?.Field?.value || '',
      },
    })
  );
  const paginationCreator = loadPaginationActions(searchEngine);

  const [open, setOpen] = useState(false);
  const [facetState, setFacetState] = useState(headlessFacet.state);
  const { toggleSearchFacet } = useSearchFacet();

  useEffect(() => {
    headlessFacet.subscribe(() => {
      setFacetState(headlessFacet.state);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchEngine]);

  const isInitialRender = useRef(true);
  const router = useRouter();
  const { query } = router;

  // Extract job location filter from URL
  const urlJobLocation = query['f-joblocation'] as string | undefined;

  useEffect(() => {
    if (!country) return;

    if (!router.asPath.includes('search-jobs')) return;
    if (facetState.values.length === 0) return;
    //  Ensure this logic only applies to 'joblocation' facet
    if (props.fields.Field?.value !== 'joblocation') return;

    // Prevent reapplying filter after manual clearing

    if (!isInitialRender.current) return;
    isInitialRender.current = false; // Mark that the initial load is complete
    const selectedValues = facetState.values.filter((selected) => selected.state === 'selected');

    // Find the previously selected country filter (if any)
    const previousCountry = selectedValues.find((selected) =>
      Object.values(countryMap).includes(selected.value)
    );

    // Remove previous country filter (if any) while keeping other selections
    if (previousCountry) {
      headlessFacet.toggleSelect(previousCountry);
    }

    setTimeout(() => {
      const matchingFacets = facetState.values.filter(
        (f) =>
          f.value.toLowerCase() === country.toLowerCase() || // Match mapped country
          (urlJobLocation && f.value.toLowerCase() === urlJobLocation.toLowerCase()) // Match URL filter
      );

      if (matchingFacets.length > 0) {
        matchingFacets.forEach((facet) => headlessFacet.toggleSelect(facet));

        setTimeout(() => {
          searchEngine.executeFirstSearch();
        }, 300);
      }
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, urlJobLocation, facetState.values.length, router.asPath]);

  const selectedFacets = facetState.values.filter((facet) => facet.state == 'selected').length;

  const id = props.params.RenderingIdentifier;

  function checkFacetClick() {
    // if(window.location.hash.includes("saved-job")){
    toggleSearchFacet(true);
    // }
  }
  const isLocationIconPresent = props.fields.Field?.value === 'joblocation';
  const isLocationOrExpertise =
    props.fields.Field?.value === 'joblocation' ||
    props.fields.Field?.value === 'jobareaofexpertise';
  return (
    <div
      id={id ? id : undefined}
      className={`search-facet ${
        facetState.values.length === 0 ? 'search-facet--empty' : ''
      } ${sitecoreStyles(props.params.styles)}
      ${selectedFacets > 0 ? 'search-facet--selected' : ''}
      `}
      onBlur={() => {
        setTimeout(() => {
          document.activeElement?.className != 'search-facet__list search-facet__list--selected'
            ? setOpen(false)
            : setOpen(true);
        });
      }}
      onClick={() => setOpen(!open)}
      tabIndex={0}
    >
      <div
        className={`search-facet__container ${isLocationIconPresent ? 'full-width' : ''} ${
          isLocationOrExpertise ? 'AOE-margin' : ''
        }`}
        style={{ margin: isLocationOrExpertise ? '0' : undefined }}
      >
        <div className="search-facet__title">
          {isLocationIconPresent && props.fields.Field?.value === 'joblocation' && (
            <Icon icon={IconName.LocationIcon} iconLabel={'Location'} />
          )}
          {props.fields.Field?.value === 'taxonomy_geography' && (
            <Icon icon={IconName.mapIcon} iconLabel={'Map'} />
          )}

          <span className={`${selectedFacets > 0 ? 'body1' : 'body2'}`}>
            {props.fields.Label?.value}
            {selectedFacets ? `: ${selectedFacets}` : ''}
          </span>

          <div
            className={`custom-facet__icon ${
              open && facetState.values.length > 0 ? 'custom-facet__icon--rotate' : ''
            }`}
          >
            <Icon icon={IconName.ChevronDown} iconLabel={'Open'} />
          </div>
        </div>
        {open && facetState.values.length > 0 && (
          <div
            className={`search-facet__list ${
              facetState.values.length > 0 ? 'search-facet__list--selected' : ''
            }`}
          >
            {[...facetState.values]
              .sort((a, b) => {
                return a.value.localeCompare(b.value);
              })
              .map((value, index) => {
                return (
                  <div
                    className="search-facet__list-item body2"
                    key={index}
                    onClick={(event) => {
                      event.stopPropagation();
                      if (searchScope == 'Jobs') {
                        searchEngine.dispatch(paginationCreator.registerNumberOfResults(12));
                      } else if (
                        searchScope == 'Insights' ||
                        searchScope == 'ClientStories' ||
                        searchScope == 'Events'
                      ) {
                        // when applied filter search will get 16 cards
                        searchEngine.dispatch(paginationCreator.registerNumberOfResults(16));
                      } else {
                        searchEngine.dispatch(paginationCreator.registerNumberOfResults(17));
                      }
                      checkFacetClick();
                      headlessFacet.toggleSelect(value);
                    }}
                  >
                    <span>{value.value}</span>
                    <span>
                      {headlessFacet.isValueSelected(value) ? (
                        <Icon icon={IconName.Checkmark} iconLabel={'Close'} />
                      ) : (
                        ''
                      )}
                    </span>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};
