import React from 'react';
import { IconName, IconSize } from 'src/types/Enum';

interface IconProps {
  icon: IconName;
  iconLabel: string;
  iconSize?: IconSize;
  additionalClass?: string;
}

export const Icon = (props: IconProps): JSX.Element => {
  return (
    <i
      className={`icon icon--${props.icon} icon--${props.iconSize ?? '24'} ${
        props.additionalClass
      }`}
      aria-label={props.iconLabel}
    ></i>
  );
};
