import React, { useState } from 'react';
import { Text, Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
export interface LocationFields {
  id: string;
  venueLocation?: {
    jsonValue: Field<string>;
  };
}

export type LocationProps = {
  params: { [key: string]: string };
  fields?: {
    data: {
      contextItem?: LocationFields;
    };
  };
};

export const Default = (props: LocationProps): JSX.Element => {
  console.log('---->[Location] Component', props.fields?.data?.contextItem);
  const contextItem = props.fields?.data?.contextItem;
  const [editingMode] = useState(isEditorActive());
  const id = props.params.RenderingIdentifier;

  if (contextItem) {
    const isEmptyBlock = !contextItem?.venueLocation?.jsonValue?.value ? 'hide-block' : '';
    return (
      <div
        className={`location ${sitecoreStyles(props.params?.styles)} ${isEmptyBlock}`}
        id={id ? id : undefined}
      >
        {(contextItem?.venueLocation?.jsonValue?.value || editingMode) && (
          <div className="location-section">
            <div className=" subheading1 location-section__title">
              <Text field={contextItem?.venueLocation?.jsonValue} />
            </div>
          </div>
        )}
      </div>
    );
  }
  return <LocationDefaultComponent />;
};

export const LocationDefaultComponent = (): JSX.Element => (
  <div className="location component--with-paddings">
    <span className="is-empty-hint">location</span>
  </div>
);
