import React, { useEffect, useState } from 'react';
import { CarouselCard as AnimatedCarouselCards } from 'components/carousel/partials/carousel-content/CarouselContent';
import { ImageField, RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import NextImageWithNullFallback from 'components/shared/nextImg/NextImageWithNullFallback';
import { Icon } from 'components/shared/icon/Icon';
import { IconName, IconSize } from 'src/types/Enum';
import { Tertiary } from 'components/link/Link';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import dynamic from 'next/dynamic';
import config from 'temp/config';
interface Fields {
  cardsData?: AnimatedCarouselCards[];
  backgroundImg: ImageField;
  multiImages?: boolean;
  displayNextCard?: boolean;
}

//component properties
type AnimatedCarouselProps = {
  params: { [key: string]: string; styles: string };
  fields: Fields;
};

//empty variant?
const AnimatedCarouselDefaultComponent = (props: AnimatedCarouselProps): JSX.Element => (
  <div className={`component ${sitecoreStyles(props?.params?.styles)}`}>
    <div className="component-content">Empty</div>
  </div>
);

export const AnimatedCarousel = (props: AnimatedCarouselProps): JSX.Element => {
  const cards = props?.fields?.cardsData;

  const lastCard = (cards?.length || 1) - 1;
  const dotsArray = Array.from(Array(cards?.length || 0).keys());
  const [editingMode] = useState(isEditorActive());
  const [currentSlide, setSlide] = useState(0);

  const smallCardTitle = 'Up Next';

  if (!cards) {
    return <></>;
  }

  const getNextSlideIndex = () => {
    return currentSlide + 1 <= lastCard ? currentSlide + 1 : 0;
  };

  const getNextSlideData = () => {
    return {
      title: smallCardTitle,
      body: cards[getNextSlideIndex()]?.title?.value,
    };
  };

  const changeSlide = (leap: number) => {
    setSlide((current) => {
      const newCurrent = current + leap;
      if (newCurrent > lastCard) {
        return 0;
      }

      if (newCurrent < 0) {
        return lastCard;
      }

      return newCurrent;
    });
  };

  const editingmode = isEditorActive();

  let DynamicNextImageWithNullFallback;

  if (editingmode) {
    // Static import when in editing mode

    DynamicNextImageWithNullFallback =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/shared/nextImg/NextImageWithNullFallback').NextImageWithNullFallback;
  } else {
    // Dynamic import when not in editing mode
    DynamicNextImageWithNullFallback = dynamic(
      () =>
        import('components/shared/nextImg/NextImageWithNullFallback').then(
          (mod) => mod.NextImageWithNullFallback
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }
  if (props?.fields) {
    if (editingMode) {
      return (
        <div className={`animated-carousel-edit-container ${sitecoreStyles(props.params?.styles)}`}>
          {cards.map((card, index) => {
            return (
              <div
                className={`animated-carousel--edit ${
                  index === currentSlide
                    ? 'animated-carousel--edit-active'
                    : 'animated-carousel--edit-inactive'
                }`}
                key={index}
              >
                <AnimatedCarouselCard
                  key={index}
                  cardData={card}
                  dotsArray={dotsArray}
                  id={index}
                  changeSlide={changeSlide}
                  multiImages={props?.fields?.multiImages}
                  smallCard={getNextSlideData()}
                  backgroundImg={props?.fields?.backgroundImg}
                  displayNextCard={props?.fields?.displayNextCard}
                ></AnimatedCarouselCard>
              </div>
            );
          })}
        </div>
      );
    }
    const nextSlideIndex = getNextSlideIndex();
    const backgroundImageUrl =
      nextSlideIndex !== -1 ? cards[nextSlideIndex]?.backgroundSlideImg : null;
    return (
      <>
        <AnimatedCarouselCard
          cardData={cards[currentSlide]}
          dotsArray={dotsArray}
          id={currentSlide}
          changeSlide={changeSlide}
          setSlide={setSlide}
          smallCard={getNextSlideData()}
          multiImages={props?.fields?.multiImages}
          backgroundImg={props?.fields?.backgroundImg}
          displayNextCard={props?.fields?.displayNextCard}
        ></AnimatedCarouselCard>
        {
          //Adding NextImage component which src points to the next slide image.
          //This image is hidden but it will force preloading the next carousel image and enhence experiance for users with slower internet connection
          props?.fields?.multiImages && backgroundImageUrl != null && (
            <DynamicNextImageWithNullFallback
              loading="eager"
              // width={1728}
              // height={740}
              fill
              sizes="(max-width: 1200px) 100vw, (min-width: 1201px) 60vw"
              field={cards[getNextSlideIndex()]?.backgroundSlideImg}
              params={{
                styles: 'animated-carousel__background-image-preload',
              }}
            />
          )
        }
      </>
    );
  }
  return <AnimatedCarouselDefaultComponent {...props} />;
};

interface AnimatedCarouselCardProps {
  id: number;
  cardData: AnimatedCarouselCards;
  dotsArray: number[];
  changeSlide?: (slideNumber: number) => void;
  setSlide?: (slideNumber: number) => void;
  smallCard?: {
    title?: string | number;
    body?: string | number;
  };
  multiImages?: boolean;
  backgroundImg?: ImageField;
  displayNextCard?: boolean;
}

const AnimatedCarouselCard = ({
  id,
  cardData,
  changeSlide,
  dotsArray,
  setSlide,
  smallCard,
  multiImages,
  backgroundImg,
  displayNextCard,
}: AnimatedCarouselCardProps): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  const [animateBackground, setAnimateBackground] = useState(true);
  useEffect(() => {
    if (!multiImages) {
      setAnimateBackground(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editingmode = isEditorActive();

  let DynamicNextImageWithNullFallback;

  if (editingmode) {
    // Static import when in editing mode

    DynamicNextImageWithNullFallback =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/shared/nextImg/NextImageWithNullFallback').NextImageWithNullFallback;
  } else {
    // Dynamic import when not in editing mode
    DynamicNextImageWithNullFallback = dynamic(
      () =>
        import('components/shared/nextImg/NextImageWithNullFallback').then(
          (mod) => mod.NextImageWithNullFallback
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }
  return (
    <>
      <div
        key={id}
        className={`animated-carousel slide-${id} ${editingMode ? 'animated-carousel--edit' : ''}`}
      >
        <div
          className={`animated-carousel__background ${
            editingMode ? 'animated-carousel__background--edit' : ''
          }`}
        >
          <div
            className={`animated-carousel__background-image-container  ${
              editingMode ? 'animated-carousel__background--edit' : ''
            } ${animateBackground ? 'animation--fade-zoom' : ''} `}
          >
            <DynamicNextImageWithNullFallback
              loading="eager"
              // width={1728}
              // height={740}
              fill
              priority
              sizes="(max-width: 1200px) 100vw, (min-width: 1201px) 60vw"
              field={multiImages ? cardData?.backgroundSlideImg : backgroundImg}
              params={{
                styles: 'animated-carousel__background-image',
              }}
            />
          </div>
        </div>
        <div className="animated-carousel__big-card">
          <div className={`animated-carousel__big-card-viewport`}>
            <div className="animated-carousel__big-card-container">
              <div className="animated-carousel__big-card-main-box">
                {!cardData?.hideContentLabel?.jsonValue?.value &&
                  cardData?.link?.value.href &&
                  (isEditorActive() ? (
                    <div className="animated-carousel__big-card-tag animation--fade-in-up button-tags">
                      {cardData?.contentLabel?.targetItem?.title?.jsonValue.value}
                    </div>
                  ) : (
                    <Text
                      tag="div"
                      className="animated-carousel__big-card-tag animation--fade-in-up button-tags"
                      field={cardData?.contentLabel?.targetItem?.title?.jsonValue || { value: '' }}
                    />
                  ))}
                {(cardData?.img?.value?.src || editingMode) && (
                  <NextImageWithNullFallback
                    field={cardData?.img}
                    width={201}
                    height={100.65}
                    params={{
                      styles:
                        'animated-carousel__big-card-logo-container next-image--contain animation--fade-in-up',
                    }}
                  />
                )}

                <Text
                  tag="div"
                  className="animated-carousel__big-card-title animation--fade-in-up h3"
                  field={cardData?.title}
                />
                {(cardData?.body?.value || editingMode) && (
                  <RichText
                    tag="div"
                    className="animated-carousel__big-card-text body2 animation--fade-in-up"
                    field={cardData?.body}
                  />
                )}
              </div>
              <div className="animated-carousel__big-card-tertiary-btn animation--fade-in-up">
                <Tertiary fields={{ Link: cardData?.link }} params={{}}></Tertiary>
              </div>
            </div>
          </div>
          <div className="animated-carousel__pagination">
            <button
              data-testid="previous"
              aria-label="previous"
              className="animated-carousel__pagination-button animated-carousel__pagination-button--prev"
              onClick={() => changeSlide?.(-1)}
            >
              <Icon icon={IconName.ChevronLeft} iconSize={IconSize.Size28} iconLabel={'Previous'} />
            </button>
            {dotsArray.map((item, index) => {
              return (
                <button
                  key={item}
                  className={`animated-carousel__pagination-dot ${
                    item == id ? 'animated-carousel__pagination-dot--selected' : ''
                  } `}
                  type="button"
                  aria-label="dot"
                  onClick={() => {
                    setSlide?.(index);
                  }}
                />
              );
            })}

            <button
              data-testid="next"
              aria-label="next"
              className="animated-carousel__pagination-button animated-carousel__pagination-button--next"
              onClick={() => changeSlide?.(1)}
            >
              <Icon icon={IconName.ChevronRight} iconSize={IconSize.Size28} iconLabel={'Next'} />
            </button>
          </div>
        </div>

        {smallCard && displayNextCard && (
          <div className="animated-carousel__small-card">
            <div className="animated-carousel__small-card-container ">
              <div className=" animated-carousel__small-card-title animation--fade-in-up">
                {smallCard?.title}
              </div>
              <div className="animated-carousel__small-card-text animation--fade-in-up h5">
                {smallCard?.body}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
