import { WithSitecoreContextProps, withSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import Head from 'next/head';
import { useState } from 'react';
import { JssFieldValue } from 'src/types/JssFieldValue';

export interface SeoComponentFields {
  metaTitle: JssFieldValue<string>;
  metaDescription?: JssFieldValue<string>;
  metaKeywords?: JssFieldValue<string>;
}

type SeoProps = {
  params: { [key: string]: string };
  fields?: SeoComponentFields;
} & WithSitecoreContextProps;

const Default = (props: SeoProps): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  console.log('---->[Seo] Component', props.fields);
  return (
    <>
      <Head>
        <title>{props.fields?.metaTitle?.value}</title>
        {props.fields?.metaDescription?.value && (
          <meta name="description" content={props.fields?.metaDescription?.value} />
        )}
        {props.fields?.metaKeywords?.value && (
          <meta name="keywords" content={props.fields?.metaKeywords?.value} />
        )}
      </Head>
      {editingMode && props.sitecoreContext.route?.templateName == 'Partial Design' && (
        <div className="is-metadata-hint">
          <strong>Metadata:</strong> Seo Meta Tags
        </div>
      )}
    </>
  );
};

export default withSitecoreContext()(Default);
