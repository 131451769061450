import React, { useEffect, useState } from 'react';

export const DividerLogoLine = () => {
  const [isInViewport, setIsInViewport] = useState(false);

  function onScroll() {
    setIsInViewport(true);
    window.removeEventListener('scroll', onScroll);
  }
  function loadDivider() {
    if (!isInViewport) {
      window.scrollY > 10 // If page is already scrolled
        ? setIsInViewport(true)
        : window.addEventListener('scroll', onScroll); //On scroll load the divider
    }
  }
  useEffect(() => {
    loadDivider();
  }, [isInViewport]);
  return isInViewport ? <div className="logoline logoline--divider"></div> : null;
};
