/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import Script from 'next/script';
import { Field, LinkField, RichText, RichTextField, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { RenderNextPlaceholder } from 'components/next-placeholder/RenderNextPlaceholder';
import { IconName } from 'src/types/Enum';
import { useI18n } from 'next-localization';
import { removeColFromStylesProps } from 'src/helpers/sitecoreStyles';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { AssessmentResultFields } from '../survey-assessment/Assessment';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import config from 'temp/config';
import LazyLoadComponent from 'components/shared/lazy-load/LazyLoadComponent';

declare global {
  interface Window {
    MktoForms2: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [x: string]: any;
      loadForm: (
        arg0: string,
        arg1: string,
        arg2: string,
        arg3: (arg0: { onSuccess: (arg0: () => boolean) => void }) => void
      ) => void;
    };
    dataLayer: {
      push: (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        arg0: any
      ) => void;
    };
    _mfq: {
      push: (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        arg0: any
      ) => void;
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    MarketoFormAdobeSubmits: (params: any) => void;
  }
}

export interface FormFields {
  FormId: Field<string>;
  FormSuccessTitle: Field<string>;
  FormSuccessMessage: RichTextField;
  FormSuccessLink: LinkField;
}

export type FormProps = {
  params: { [key: string]: string };
  fields?: FormFields;
  //parameters for survey assessment result
  surveyResultFields?: AssessmentResultFields;
  surveyResultFieldName?: string;
  surveyResponse?: string;
  surveyResponseFieldName?: string;
  retake?: () => void;
  ctalink?: LinkField;
};

// storybook type statement
type StorybookProps = {
  FormId: Field<string>;
  FormSuccessMessage: Field<string>;
};

const removeFormBeforeLoad = (formId: string) => {
  const form = document.querySelector(`#mktoForm_${formId}`);
  if (form) {
    while (form.firstChild) {
      form.removeChild(form.firstChild);
    }
  }
};

const applyAccessibilityFixes = (
  formId: string | undefined,
  surveyResult?: AssessmentResultFields | undefined,
  surveyResultFieldName?: string | undefined,
  surveyResponse?: string,
  surveyResponseFieldName?: string | undefined
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const form = window.MktoForms2.allForms().findLast((f: any) => f.getId() == formId);
  const formElement = form?.getFormElem();

  if (formElement && formElement.length > 0) {
    formElement[0].querySelectorAll('[id]').forEach((el: Element) => {
      if (el.id.indexOf(formElement[0].id) == -1) {
        const label = formElement[0].querySelector(`label[for="${el.id}"]`);
        if (label) {
          label.setAttribute('for', `${formElement[0].id}_${el.id}`);
        }
        el.id = `${formElement[0].id}_${el.id}`;
      }
    });
  }
  document.querySelectorAll('#mktoStyleLoaded').forEach((e, index) => (e.id += index));
  form.addHiddenFields({
    lastFormURL: document.location.href,
    lastReferralURL: document.referrer || localStorage.getItem('prevUrl') || '',
    avanadeUTMCampaign: sessionStorage.getItem('utm_campaign') || '',
    avanadeUTMSource: sessionStorage.getItem('utm_source') || '',
    avanadeUTMMedium: sessionStorage.getItem('utm_medium') || '',
    avanadeUTMContent: sessionStorage.getItem('utm_content') || '',
    avanadeUTMTerm: sessionStorage.getItem('utm_term') || '',
  });
  if (surveyResult && surveyResponse && surveyResponseFieldName && surveyResultFieldName) {
    form.addHiddenFields({
      [surveyResponseFieldName]: surveyResponse,
      [surveyResultFieldName]: surveyResult.resultTitle?.jsonValue?.value,
    });
  }
};

function contactUsCloseClick() {
  const modal = document.querySelector('.contact-us');
  if (modal) {
    modal.classList.remove('show');
    document.getElementsByTagName('html')[0].removeAttribute('class');
  }
}

function useMarketo({
  baseUrl,
  munchkinId,
  formId,
  callback,
}: {
  baseUrl: string;
  munchkinId: string;
  formId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (f: any) => void;
}) {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [reload, setReload] = useState(0);
  const [hasScrolled, setHasScrolled] = useState(false);
  const handleScroll = () => {
    if (!hasScrolled) {
      setHasScrolled(true);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);
  const reloadForm = () => {
    setReload(reload + 1);
  };
  useEffect(() => {
    if (isScriptLoaded) {
      removeFormBeforeLoad(formId); //just in case
      window.MktoForms2.loadForm(baseUrl, munchkinId, formId, callback); //load fresh form
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScriptLoaded, reload, hasScrolled]); //execute ONLY when script is loaded or reload is requested via reloadForm()
  return { setIsScriptLoaded, reloadForm, hasScrolled };
}
export const Default = (props: FormProps): JSX.Element => {
  const handleRetakeAssessment = () => {
    if (props.retake) {
      props.retake();
    } // Invoke the provided callback to handle retaking assessment
  };
  console.log('---->[Form] Component', props?.fields);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [isPopUpValue, setIsPopUpValue] = useState<Element | null>();
  const router = useRouter();
  const [isRouterChange, setIsRouterChange] = useState(false);
  function showContactUsOverlay(event: Event) {
    event.preventDefault();
    const formModal = document.getElementsByClassName('is-popover')[0] as HTMLElement;
    const hasFormChild = formModal.getElementsByClassName('mktoForm');
    setTimeout(() => {
      if (hasFormChild.length == 0) {
        // setIsScriptLoaded(true);
        reloadForm();
      }
    }, 1500);
    document.getElementById('contact-us-popover')?.classList.add('show');
    document.getElementsByTagName('html')[0].setAttribute('class', 'no-scroll');
  }

  useEffect(() => {
    router.events.on('routeChangeComplete', () => {
      setIsRouterChange(true);
      setShowSuccessMsg(false);
      reloadForm();
      // setIsScriptLoaded(true);
      if (document.querySelector('.is-popover.show')) {
        contactUsCloseClick();
      }
    });
  }, [router]);
  useEffect(() => {
    const contactExpert = document.getElementsByClassName('contact-expert')[0] as HTMLElement;
    if (contactExpert && !contactExpert.getAttribute('id')) {
      contactExpert.setAttribute('id', props?.fields?.FormId.value || '');
    }
  }, [isRouterChange]);

  const formRef = useRef<HTMLFormElement>(null);

  const onFormSuccess = (form: { onSuccess: (arg0: () => boolean) => void }) => {
    form.onSuccess(() => {
      setShowSuccessMsg(true);
      if (typeof window !== undefined) {
        const MarketoSubmit = window.MarketoFormAdobeSubmits;
        MarketoSubmit(props?.fields?.FormId.value);
      }
      return false;
    });
  };

  const { setIsScriptLoaded, reloadForm, hasScrolled } = useMarketo({
    baseUrl: 'https://pages.avanade.com',
    munchkinId: '857-NHG-455',
    formId: props?.fields?.FormId.value || 'unknown',
    callback: onFormSuccess,
  });

  useEffect(() => {
    const mutationObserver = new MutationObserver(() => {
      if (props.params?.isSurveyForm === 'true') {
        applyAccessibilityFixes(
          props?.fields?.FormId.value,
          props.surveyResultFields,
          props.surveyResultFieldName,
          props.surveyResponse,
          props.surveyResponseFieldName
        );
      } else {
        applyAccessibilityFixes(props?.fields?.FormId.value);
      }
    });
    if (formRef.current) {
      mutationObserver.observe(formRef.current, {
        childList: true,
        subtree: false,
      });
    }

    return () => {
      mutationObserver.disconnect();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, reloadForm]);

  useEffect(() => {
    document
      .querySelector('.header-contact-us-button')
      ?.addEventListener('click', showContactUsOverlay);
    const modalContactUs = document.querySelector('.is-popover.show');
    setIsPopUpValue(modalContactUs);
    if (showSuccessMsg && modalContactUs) {
      modalContactUs.classList.remove('show');
      reloadForm();
    } else if (showSuccessMsg && !modalContactUs) {
      document.querySelector('.contact-expert')?.classList.add('hide-contact-expert');
      document.querySelector('.contact-us-page')?.classList.add('hide-contact-expert');
      document.querySelector('.survey-marketo form')?.classList.add('hide-survey-marketo');
      document.querySelector('.survey-form-container')?.classList.add('hide-onresult-form'); // adding this class to apply css to handle distotion for last slide
      document.querySelector('.survey-form-container h3')?.classList.add('survey-form-title');
    } else if (!showSuccessMsg) {
      document.querySelector('.contact-expert')?.classList.remove('hide-contact-expert');
      document.querySelector('.contact-us-page')?.classList.remove('hide-contact-expert');
      document.querySelector('.form-submit')?.classList.remove('form-submit');
    }

    return () => {
      document.querySelector('html')?.classList.remove('no-scroll');
      // document
      //   .querySelector('.header-contact-us-button')
      //   ?.removeEventListener('click', showContactUsOverlay);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSuccessMsg]);

  const { t } = useI18n();
  const scriptRoot = useRef<HTMLFormElement>(null); // gets assigned to a root node
  const script = `<script>
                     function MarketoFormAdobeSubmits(formID) {
                         disableperformanceActivites = false;
                         CheckCookieConsent();
                         window.dataLayer = window.dataLayer || [];
                         window.dataLayer.push({
                             'event': 'FormSubmission',
                             'Marketo Form ID': formID
                         });
                     
                         window._mfq = window._mfq || [];
                         window._mfq.push(['formSubmitSuccess', formID]);
                         var y = new Object();
                             const formTitleElement = document.getElementById("form" + formID)?.closest("div")?.parentElement?.previousElementSibling?.querySelector("h1, h2, h3, h4, h5, h6")?.innerText;
                            if (formTitleElement) {
                             s.eVar58 = formTitleElement; 
                           }
                         if (typeof _satellite != "undefined" & !disableperformanceActivites) {
                             y.eVar3 = _satellite.getVar('Datalayer - Page Url');
                             y.eVar12 = "Marketo Form ID : " + formID;
                              y.eVar58 = s.eVar58;
                             y.linkTrackVars = "eVar12,eVar3,eVar58";
                             s.events = "Event14";
                             s.linkTrackEvents = "Event14";
                             s.tl(true, "o", "Marketo Form Submitted", y);
                         }
                     }
     </script>`;
  useEffect(() => {
    const range = document.createRange(); // creates a mini-document (light weight version), in our range with our script in it
    const documentFragment = range.createContextualFragment(script); // appends it to our script root - so it renders in the correct location
    if (scriptRoot.current && scriptRoot.current.childElementCount < 1) {
      scriptRoot.current.appendChild(documentFragment);
    }
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      scriptRoot.current?.firstChild?.remove;
    };
  }, [isRouterChange, script, hasScrolled, reloadForm]);

  const expertScriptRoot = useRef<HTMLFormElement>(null); // gets assigned to a root node
  const expertScript = `<script>
            try {
              document.addEventListener('focusin', function (event) {
                  const target = event.target;
                  const form = target.form;
                  if (form) {
                      const hiddenInput = Array.from(form.elements).find(element =>
                              element.type === 'hidden' && element.value === '857-NHG-455' && element.name === 'munchkinId');
                      const formIdInput = Array.from(form.elements).find(element =>
                              element.type === 'hidden' && element.name === 'formid');
                      const formID = formIdInput ? formIdInput.value : "";
                          const formTitleElement = document.getElementById("form" + formID)?.closest("div")?.parentElement?.previousElementSibling?.querySelector("h1, h2, h3, h4, h5, h6")?.innerText;
                      if (hiddenInput && formID && target.type !== 'hidden') {
                          let trackFormStartFlag = "trackMarketoFormStarted" + formID;
                          if (_satellite && !window[trackFormStartFlag]) {
                            // console.log("formID:",formID);
                              window[trackFormStartFlag] = true;
                              _satellite.track("Marketo Form Started", {
                                  "formID": "Marketo Form ID : " + formID,
                                  "formTitleElement": formTitleElement

                              });
                                if (formTitleElement) {
                            s.eVar58 = formTitleElement; 
                        }
                          }
                      }
                  }
              });
            } catch (e) {
              _satellite.logger.error(e)
            } 
        </script>`;

  useEffect(() => {
    const range = document.createRange(); // creates a mini-document (light weight version), in our range with our script in it
    const documentFragment = range.createContextualFragment(expertScript); // appends it to our script root - so it renders in the correct location
    if (expertScriptRoot.current && expertScriptRoot.current.childElementCount < 1) {
      expertScriptRoot.current.appendChild(documentFragment);
    }
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      expertScriptRoot.current?.firstChild?.removeChild;
    };
  }, [isRouterChange, expertScript, hasScrolled, reloadForm]);

  const editingmode = isEditorActive();
  let DynamicFormSuccess;
  if (editingmode) {
    // Static import when in editing mode
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicFormSuccess = require('./partials/FormSuccess').FormSuccess;
  } else {
    // Dynamic import when not in editing mode
    DynamicFormSuccess = dynamic(
      () => import('./partials/FormSuccess').then((mod) => mod.FormSuccess),
      { ssr: !!config.dynamicImportSsr }
    );
  }

  let DynamicIcon;
  if (editingmode) {
    // Static import when in editing mode
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicIcon = require('components/shared/icon/Icon').Icon;
  } else {
    // Dynamic import when not in editing mode
    DynamicIcon = dynamic(() => import('components/shared/icon/Icon').then((mod) => mod.Icon), {
      ssr: !!config.dynamicImportSsr,
    });
  }
  if (props?.fields) {
    return (
      <LazyLoadComponent componentId={`form${props?.fields.FormId.value}`}>
        <div
          className={`component form ${removeColFromStylesProps(props.params.styles)}`}
          id={`form${props?.fields.FormId.value}`}
        >
          <button onClick={() => contactUsCloseClick()} className="close-button">
            {t('component-form-close')}
            <DynamicIcon icon={IconName.Dismiss} iconLabel={'Close'} />
          </button>
          <>
            <Script
              src="https://pages.avanade.com/js/forms2/js/forms2.min.js"
              strategy="afterInteractive"
              onLoad={() => setIsScriptLoaded(true)}
              onReady={() => setIsScriptLoaded(true)}
            />
            <form
              ref={formRef}
              id={`mktoForm_${props?.fields.FormId.value}`}
              style={{
                display: 'block',
              }}
            ></form>
          </>
        </div>
        {<form ref={scriptRoot} />}
        {<form ref={expertScriptRoot} />}
        <Script id="FormSubmitScript">
          {`
            ///delecare global variables
            disableBrowserStorage = false;
            disableperformanceActivites = false;
            disableTargetActivies = false;
            const performanceCode = ",C0002,";
            const functionalityCode = ",C0003,";
            const targetCode = ",C0004,";

            /*Disabling BrowserStorage*/
            //  function DisableBrowserStorage() {
            //      localStorage.clear();
            //      sessionStorage.clear();
            //  }
            function CheckCookieConsent() {
                setInterval(function () {
                    if (typeof OptanonActiveGroups !== "undefined") {
                        //Check for Performance cookies
                        if (OptanonActiveGroups.indexOf(performanceCode) < 0) {
                            disableperformanceActivites = true;
                            if (document.querySelector("#brightcoveOptOut") != null) {        //(jQuery("#brightcoveOptOut").length > 0) {
                                var optouthtml = document.querySelector("#brightcoveOptOut").innerHTML;        //jQuery("#brightcoveOptOut").html();
                                if (optouthtml.indexOf('img') < 0) {
                                    document.querySelector("#brightcoveOptOut").innerHTML = "<img src='https://map.brightcove.com/lead/clear' alt='cookie consent' style= 'display: block; width: 0px' />";    //jQuery("#brightcoveOptOut").html("<img src='https://map.brightcove.com/lead/clear' style= 'display: block; width: 0px' />");
                                }
                            }
                        }
                        else {
                            disableperformanceActivites = false;
                        }
                        // ApplyCookieConsentCeros(disableperformanceActivites); commenting this line as Sanjay recommended
                        //Check for Functionality cookies
                        if (OptanonActiveGroups.indexOf(functionalityCode) < 0) {
                            disableBrowserStorage = true;
                            //  DisableBrowserStorage();
                            if (typeof MktoForms2 != "undefined") {
                                if (document.querySelector('[name="avanadeUTMCampaign"]') != null) {                //(jQuery('[name="avanadeUTMCampaign"]').length > 0) {
                                    document.querySelector('[name="avanadeUTMCampaign"]').value = "";          //jQuery('[name="avanadeUTMCampaign"]').val('');
                                }
                                if (document.querySelector('[name="avanadeUTMSource"]') != null) {                  //(jQuery('[name="avanadeUTMSource"]').length > 0) {
                                    document.querySelector('[name="avanadeUTMSource"]').value = "";            //jQuery('[name="avanadeUTMSource"]').val('');
                                }
                                if (document.querySelector('[name="avanadeUTMMedium"]') != null) {                  //(jQuery('[name="avanadeUTMMedium"]').length > 0) {
                                    document.querySelector('[name="avanadeUTMMedium"]').value = "";            //jQuery('[name="avanadeUTMMedium"]').val('');
                                }
                                if (document.querySelector('[name="avanadeUTMContent"]') != null) {                 //(jQuery('[name="avanadeUTMContent"]').length > 0) {
                                    document.querySelector('[name="avanadeUTMContent"]').value = "";           //jQuery('[name="avanadeUTMContent"]').val('');
                                }
                                if (document.querySelector('[name="avanadeUTMTerm"]') != null) {                  //(jQuery('[name="avanadeUTMTerm"]').length > 0) {
                                    document.querySelector('[name="avanadeUTMTerm"]').value = "";             //jQuery('[name="avanadeUTMTerm"]').val('');
                                }
                            }
                        }
                        else {
                            disableBrowserStorage = false;
                        }
                        //Check for Target cookies
                        if (OptanonActiveGroups.indexOf(targetCode) < 0) {
                            disableTargetActivies = true;
                        }
                        else {
                            disableTargetActivies = false;
                        }
                    }
                    localStorage.setItem('CookieState', disableBrowserStorage);
                    window.dispatchEvent(new Event('CookieState'));

                }, 1000);
            }
            CheckCookieConsent();
            function getParameterByName(name, url) {
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                const product = urlParams.get(name)
                return decodeURIComponent(product);
            }
            let winHref = new URL(window.location.href).href;
                   
            if (winHref.indexOf("utm_campaign") != -1) {
                var queryStringkey2 = getParameterByName('utm_campaign');
                sessionStorage.setItem('utm_campaign', queryStringkey2);
            }

            if (winHref.indexOf("utm_source") != -1) {
                var queryStringkey3 = getParameterByName('utm_source');
                sessionStorage.setItem('utm_source', queryStringkey3);
            }

            if (winHref.indexOf("utm_medium") != -1) {
                var queryStringkey4 = getParameterByName('utm_medium');
                sessionStorage.setItem('utm_medium', queryStringkey4);
            }

            if (winHref.indexOf("utm_content") != -1) {
                var queryStringkey5 = getParameterByName('utm_content');
                sessionStorage.setItem('utm_content', queryStringkey5);
            }

            if (winHref.indexOf("utm_term") != -1) {
                var queryStringkey6 = getParameterByName('utm_term');
                sessionStorage.setItem('utm_term', queryStringkey6);
            }
                 
          `}
        </Script>
        {/* This is a way to render your success message component outside of the form component but still maintaining the control.
        You can show/hide it from the form component. */}
        {props.params?.isSurveyForm === 'true' ? (
          <DynamicFormSuccess
            {...props?.fields}
            show={showSuccessMsg}
            setShow={setShowSuccessMsg}
            isPopUp={isPopUpValue}
            isSurveyForm={true}
            surveyResult={props.surveyResultFields}
            retake={handleRetakeAssessment}
            ctalink={props?.ctalink}
          />
        ) : (
          <RenderNextPlaceholder name="before-footer">
            <DynamicFormSuccess
              {...props?.fields}
              show={showSuccessMsg}
              setShow={setShowSuccessMsg}
              isPopUp={isPopUpValue}
            />
          </RenderNextPlaceholder>
        )}
      </LazyLoadComponent>
    );
  }

  return <FormDefaultComponent {...props} />;
};

export const WithText = (props: FormProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  if (props?.fields) {
    return (
      <div className={`component form ${props.params.styles}`} id={id ? id : undefined}>
        <div className="component-content">
          <div className="form-text">
            <div className="field-formid">
              <Text field={props?.fields.FormId} />
              click save to load the form.
            </div>
            <RichText
              tag="div"
              className="field-formsuccessmessage"
              field={props?.fields.FormSuccessMessage}
            />
          </div>
        </div>
      </div>
    );
  }

  return <FormDefaultComponent {...props} />;
};

export const FormDefaultComponent = (props: FormProps): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  return (
    <React.Fragment>
      {editingMode && (
        <div className={`component form component--with-paddings ${props.params.styles}`}>
          <span className="is-empty-hint">Form</span>
        </div>
      )}
    </React.Fragment>
  );
};

// storybook markup
export const FormStorybookComponent = (props: StorybookProps) => (
  <div className={`component form`}>
    <div className="component-content">
      <div className="form-text">
        <div className="field-formid">
          <Text field={props.FormId} />
        </div>
        <RichText tag="div" className="field-formsuccessmessage" field={props.FormSuccessMessage} />
      </div>
    </div>
  </div>
);
