import React, { useState } from 'react';
import { LinkField, RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { useI18n } from 'next-localization';
import { Tertiary } from 'components/link/Link';
import { BannerFields } from 'components/banner/Banner';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import LazyLoadComponent from 'components/shared/lazy-load/LazyLoadComponent';

type BannerProps = {
  params: { [key: string]: string; styles: string };
  fields?: {
    data: {
      datasource?: BannerFields;
    };
  };
};

const TitleBannerDefaultComponent = (props: BannerProps): JSX.Element => (
  <div className={`component ${sitecoreStyles(props.params?.styles)}`}>
    <div className="component-content">Empty</div>
  </div>
);

export const TitleBanner = (props: BannerProps): JSX.Element => {
  const { t } = useI18n();
  const [editingMode] = useState(isEditorActive());

  if (props.fields) {
    const id = props.params.RenderingIdentifier;
    const titleTag = props.params['TitleTag'] || 'h2';
    if (
      props.fields.data.datasource?.link?.jsonValue.value &&
      !props.fields.data.datasource?.link?.jsonValue.value.text
    ) {
      props.fields.data.datasource.link.jsonValue.value.text = t('component-banner-findoutmore');
    }
    return (
      <LazyLoadComponent componentId="TitleBanner">
        <div
          id={id ? id : undefined}
          className={`${sitecoreStyles(
            props.params?.styles
          )} title-banner component--with-paddings`}
        >
          {(props.fields.data.datasource?.title?.jsonValue.value || editingMode) && (
            <div className="row">
              <div className="col-12">
                <Text
                  tag={titleTag}
                  field={props.fields.data.datasource?.title?.jsonValue}
                  className="title-banner__title"
                />
              </div>
            </div>
          )}

          {(props.fields.data.datasource?.description?.jsonValue.value || editingMode) && (
            <div className="row">
              <div className="col-12">
                <RichText
                  tag="div"
                  className="body2 title-banner__description"
                  field={props.fields.data.datasource?.description?.jsonValue}
                />
              </div>
            </div>
          )}

          {(props.fields.data.datasource?.link?.jsonValue.value.href ||
            (!props.fields.data.datasource?.link?.jsonValue.value.href && editingMode)) && (
            <div className="row">
              <div className="col-12">
                <div className="title-banner__button">
                  <Tertiary
                    fields={{ Link: props.fields.data.datasource?.link?.jsonValue as LinkField }}
                    params={{}}
                  ></Tertiary>
                </div>
              </div>
            </div>
          )}
        </div>
      </LazyLoadComponent>
    );
  }
  return <TitleBannerDefaultComponent {...props} />;
};
