import React from 'react';
import {
  ComponentParams,
  ComponentRendering,
  Placeholder,
} from '@sitecore-jss/sitecore-jss-nextjs';
import sitecoreStyles from 'src/helpers/sitecoreStyles';

interface ComponentProps {
  rendering: ComponentRendering & { params: ComponentParams };
  params: ComponentParams;
  children?: React.ReactNode[];
}

export const Default = (props: ComponentProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const styles = `${props.params.Styles ?? ''}`.replace('col-12', '').trimEnd();
  const phKey = `pagerow-${props.params.DynamicPlaceholderId}`;

  return (
    <div
      id={id ? id : undefined}
      className={`row ${styles} ${sitecoreStyles(props.params?.styles)}`}
    >
      <div className={`col-12`}>
        <Placeholder name={phKey} rendering={props.rendering} />
      </div>
    </div>
  );
};
