import React from 'react';
import { EmployeeProfileFields } from 'components/employee-profile/EmployeeProfile';
import { useI18n } from 'next-localization';

export interface ArticleByFields {
  authors: {
    targetItems: EmployeeProfileFields[];
  };
}

export type ArticleByProps = {
  params: { [key: string]: string; styles: string };
  fields?: {
    data: {
      datasource: ArticleByFields;
    };
  };
};

export const Default = (props: ArticleByProps) => {
  const datasource = props?.fields?.data?.datasource;
  console.log('---->[ArticleAuthors] Component', props);
  const { t } = useI18n();

  return (
    <div className="article-by">
      <span className="article-by__title">{t('article-by')}</span>
      {datasource && datasource.authors && (
        <span>
          {datasource.authors && datasource.authors.targetItems ? (
            <>
              {datasource.authors.targetItems.slice(0, 5).map((item, index, array) => (
                <React.Fragment key={index}>
                  <span className="article-by__name"> {item.FullName.value}</span>
                  {index < array.length - 1 && (
                    <>
                      {index === array.length - 2 ? (
                        <span> {t('component-articleauthors-and')} </span>
                      ) : (
                        <span>, </span>
                      )}
                    </>
                  )}
                </React.Fragment>
              ))}
            </>
          ) : null}
        </span>
      )}
    </div>
  );
};
