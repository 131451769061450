import React, { useEffect, useRef } from 'react';
import { useComponentVisibility } from '../../../lib/contexts/ComponentVisibilityContext';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

interface LazyLoadComponentProps {
  componentId: string;
  children: React.ReactNode;
}

const LazyLoadComponent: React.FC<LazyLoadComponentProps> = ({ componentId, children }) => {
  const componentRef = useRef<HTMLDivElement | null>(null);
  const { visibleComponents, markComponentVisible, hasScrolled, handleScroll } =
    useComponentVisibility();

  // Set up IntersectionObserver to detect when the component comes into view
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !visibleComponents.includes(componentId)) {
            markComponentVisible(componentId);
          }
        });
      },
      {
        rootMargin: '0px',
        threshold: 0.5, // Trigger when 50% of the component is in view
      }
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      if (componentRef.current) {
        observer.disconnect();
      }
    };
  }, [visibleComponents, componentId, markComponentVisible]);

  // Listen for the scroll event to load components after first scroll
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const editingmode = isEditorActive();

  return (
    <>
      {editingmode ? (
        <> {children} </>
      ) : (
        <div ref={componentRef}>
          {visibleComponents.includes(componentId) || hasScrolled ? children : null}
        </div>
      )}
    </>
  );
};

export default LazyLoadComponent;
