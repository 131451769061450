import React, { useState } from 'react';

import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';

import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

import { Tertiary } from 'components/link/Link';

import { TimageFields } from 'components/timage/Timage';

import { NextImageWithNullFallback } from 'components/shared/nextImg/NextImageWithNullFallback';

import sitecoreStyles from 'src/helpers/sitecoreStyles';

export type TimageProps = {
  params: { [key: string]: string; styles: string };
  fields: TimageFields;
  variant: 'default' | 'square';
};

export const TextImageRightComponent = ({
  props,
  variant,
}: {
  props: TimageProps;
  variant: string;
}): JSX.Element => {
  const id = props.params.RenderingIdentifier;

  const [editingMode] = useState(isEditorActive());

  const imageStyles =
    variant === 'square'
      ? 'timage-right-square__image-container next-image--square'
      : 'timage-right__image-container next-image--16-9';

  const containerClass = variant === 'square' ? 'timage-right-square' : 'timage-right';
  const tag = props.params['TitleTag'] || 'h3';
  const isTag = tag == 'h1' || tag == 'h2';
  return (
    <div
      className={`component--with-paddings ${containerClass} ${sitecoreStyles(
        props.params?.styles
      )}`}
      id={id ? id : undefined}
    >
      {editingMode && (
        <div className="row">
          <div className="col-12">
            <div className="s-editing-mode-hint">
              <strong>HINT:</strong> Requirement for the Title in this component is to
              <strong> NOT USE H1 AND H2 TAGS</strong> due to translations issue.
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <div
          className={`col-12 col-${
            variant === 'square' ? 'md-6' : 'lg-5'
          } ${containerClass}__text-column`}
        >
          <Text
            tag={isTag ? tag : 'div'}
            field={props.fields.Title}
            className={`${containerClass}__header ${!isTag ? tag : ''}`}
          />
          <RichText
            tag="div"
            className={`${containerClass}__description body2`}
            field={props.fields.Text}
          />

          {(props.fields.Link?.value?.href || editingMode) && (
            <div className={`${containerClass}__link`}>
              <Tertiary fields={{ Link: props.fields.Link }} params={{}} />
            </div>
          )}
        </div>
        <div
          className={`col-12 col-${
            variant === 'square' ? 'md-6' : 'lg-7'
          } ${containerClass}__image-column`}
        >
          <NextImageWithNullFallback
            field={props.fields.Image}
            width={552}
            height={552}
            params={{
              styles: imageStyles,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const TextImageRightTimage = (props: TimageProps): JSX.Element => {
  return <TextImageRightComponent props={props} variant="default" />;
};
