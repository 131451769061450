import {
  NextPlaceholderName,
  useNextPlaceholderContext,
} from 'lib/contexts/NextPlaceholderContext';
import { useEffect } from 'react';

type Props = {
  name: NextPlaceholderName;
  children: React.ReactNode;
};

export const RenderNextPlaceholder = ({ name, children }: Props) => {
  const { setContentFor } = useNextPlaceholderContext();

  useEffect(() => {
    setContentFor(name, children);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, children]);

  return null;
};
