import React from 'react';
import {
  ComponentParams,
  ComponentRendering,
  Field,
  Text,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import config from 'temp/config';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import dynamic from 'next/dynamic';
import LazyLoadComponent from 'components/shared/lazy-load/LazyLoadComponent';

export interface MetricsBlockFields {
  Title: Field<string>;
}

export interface MetricBlocksProps {
  rendering: ComponentRendering & { params: ComponentParams };
  params: ComponentParams;
  children?: React.ReactNode[];
  fields?: MetricsBlockFields;
  useGradient?: boolean;
}

export const Default = (props: MetricBlocksProps) => {
  console.log('---->[MetricsBlock] Component None-Gradient Numbers', props);
  return <MetricBlocks useGradient={false} {...props}></MetricBlocks>;
};

export const Featured = (props: MetricBlocksProps) => {
  console.log('---->[MetricsBlock] Component Gradient Numbers', props);
  return <MetricBlocks useGradient={true} {...props}></MetricBlocks>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const findNoneEmptyPlaceholders = (renderings: any[]) => {
  const indexesOfNoneEmptyPl: number[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderings.forEach((element: any[], index) => {
    if (element.length || isEditorActive()) {
      indexesOfNoneEmptyPl.push(index + 1);
    }
  });
  return indexesOfNoneEmptyPl;
};

export const MetricBlocks = (props: MetricBlocksProps) => {
  console.log('---->[MetricsBlockGeneric] Component MSI', props);
  const id = props.params.RenderingIdentifier;
  const placeholders = props.rendering.placeholders;
  const useGradient = props.useGradient;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let placeholdersValues: any[] = [];
  let placeholdersIndex: number[] = [];
  if (placeholders) {
    placeholdersValues = Object.values(placeholders);
    placeholdersIndex = findNoneEmptyPlaceholders(placeholdersValues);
  }
  const sitecoreContext = useSitecoreContext();

  const tag = props.params['TitleTag'] || 'h2';
  const editingmode = isEditorActive();
  let DynamicChildrenOrPlaceholder;

  if (editingmode) {
    DynamicChildrenOrPlaceholder =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/shared/children-or-placeholder/ChildrenOrPlaceholder').ChildrenOrPlaceholder;
  } else {
    // Dynamic import when not in editing mode
    DynamicChildrenOrPlaceholder = dynamic(
      () =>
        import('components/shared/children-or-placeholder/ChildrenOrPlaceholder').then(
          (mod) => mod.ChildrenOrPlaceholder
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }
  return (
    <LazyLoadComponent componentId="MetricsBlock">
      <div
        className={`metric-blocks ${
          useGradient ? 'metric-blocks-featured' : ''
        } component--with-paddings row`}
        id={id || undefined}
      >
        <Text
          tag={tag}
          field={props.fields?.Title}
          className={`col-12 metric-blocks__title ${sitecoreStyles(props.params?.styles)}`}
        />
        <div className="metric-blocks__main-content row">
          {placeholdersIndex.map((placeholderIndex, index) => {
            return (
              <div
                key={index}
                className={`metric-blocks__metric col-12 col-md-6 col-lg-3 ${
                  placeholdersIndex.length === 3 ? 'metric-blocks__metric--border-bottom' : ''
                } ${placeholdersIndex.length === 1 ? 'metric-blocks__metric--no-border' : ''}`}
              >
                <DynamicChildrenOrPlaceholder
                  name={`metrics-block-${placeholderIndex}`}
                  rendering={props.rendering}
                  sitecoreContext={sitecoreContext}
                >
                  {props.children ? props.children[index] : null}
                </DynamicChildrenOrPlaceholder>
              </div>
            );
          })}
        </div>
      </div>
    </LazyLoadComponent>
  );
};
