import React, { useState } from 'react';
import { Text, ImageField, Field, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import dynamic from 'next/dynamic';
import config from 'temp/config';

export interface FollowUsFields {
  title?: {
    jsonValue: Field<string>;
  };
  text?: {
    jsonValue: Field<string>;
  };
  facebookIcon?: {
    jsonValue: ImageField;
  };
  facebookUrl?: {
    jsonValue: LinkField;
  };
  instagramIcon?: {
    jsonValue: ImageField;
  };
  instagramUrl?: {
    jsonValue: LinkField;
  };
  linkedinIcon?: {
    jsonValue: ImageField;
  };
  linkedinUrl?: {
    jsonValue: LinkField;
  };
  youtubeIcon?: {
    jsonValue: ImageField;
  };
  youtubeUrl?: {
    jsonValue: LinkField;
  };
  pinterestIcon?: {
    jsonValue: ImageField;
  };
  pinterestUrl?: {
    jsonValue: LinkField;
  };
}

export type FollowUsProps = {
  params: { [key: string]: string };
  fields?: {
    data: {
      datasource: FollowUsFields;
    };
  };
};

export const Share = (props: FollowUsProps): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  const onClickActionHandler = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (editingMode) {
      e.preventDefault();
    }
  };
  const datasource = props.fields?.data?.datasource;
  const titleTag = props.params['TitleTag'] || 'h2';
  const contentTag = props.params['TitleTag'] || 'div';

  return (
    <div className="follow-us component--with-paddings">
      <Text tag={titleTag} className="follow-us__title" field={datasource?.title?.jsonValue} />
      <Text
        tag={contentTag}
        className="follow-us__description"
        field={datasource?.text?.jsonValue}
      />
      <div className="follow-us__icon">
        {renderSocialIcon(
          datasource?.linkedinUrl?.jsonValue.value.href,
          datasource?.linkedinIcon?.jsonValue,
          onClickActionHandler
        )}
        {renderSocialIcon(
          datasource?.instagramUrl?.jsonValue.value.href,
          datasource?.instagramIcon?.jsonValue,
          onClickActionHandler
        )}
        {renderSocialIcon(
          datasource?.youtubeUrl?.jsonValue.value.href,
          datasource?.youtubeIcon?.jsonValue,
          onClickActionHandler
        )}
        {renderSocialIcon(
          datasource?.facebookUrl?.jsonValue.value.href,
          datasource?.facebookIcon?.jsonValue,
          onClickActionHandler
        )}
        {renderSocialIcon(
          datasource?.pinterestUrl?.jsonValue.value.href,
          datasource?.pinterestIcon?.jsonValue,
          onClickActionHandler
        )}
      </div>
    </div>
  );
};

const renderSocialIcon = (
  url: string | undefined,
  iconField: ImageField | undefined,
  onClickAction: React.MouseEventHandler
) => {
  const editingmode = isEditorActive();

  let DynamicNextImageWithNullFallback;
  if (editingmode) {
    // Static import when in editing mode

    DynamicNextImageWithNullFallback =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/shared/nextImg/NextImageWithNullFallback').NextImageWithNullFallback;
  } else {
    // Dynamic import when not in editing mode
    DynamicNextImageWithNullFallback = dynamic(
      () =>
        import('components/shared/nextImg/NextImageWithNullFallback').then(
          (mod) => mod.NextImageWithNullFallback
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }
  if (url && iconField?.value?.src) {
    return (
      <div>
        <a
          href={url || ''}
          onClick={onClickAction}
          target="_blank"
          aria-label={(iconField?.value?.alt as string) || 'Image'}
        >
          <DynamicNextImageWithNullFallback
            field={iconField}
            params={{
              styles: 'followUs__image',
            }}
          ></DynamicNextImageWithNullFallback>
        </a>
      </div>
    );
  }
  return null;
};

export const Default = (props: FollowUsProps): JSX.Element => {
  console.log('---->[FollowUs] Component', props.fields);

  if (props.fields) return <Share {...props} />;
  return <></>;
};
