import React, { useState } from 'react';
import { TagFields } from 'src/types/Tag';
import {
  Field,
  RichText,
  RichTextField,
  Text,
  TextField,
  LinkField,
} from '@sitecore-jss/sitecore-jss-nextjs';
import config from 'temp/config';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import dynamic from 'next/dynamic';

export interface TextBlockFields {
  id: string;
  title: {
    jsonValue: Field<string>;
  };
  text: {
    jsonValue: RichTextField;
  };
  tags?: {
    targetItems: TagFields[];
  };
  link?: {
    jsonValue: LinkField;
  };
}

export type TextBlockProps = {
  params: { [key: string]: string };
  fields?: {
    data: {
      datasource: TextBlockFields;
    };
  };
};

export const Default = (props: TextBlockProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const datasources = props.fields?.data?.datasource;
  const [editingMode] = useState(isEditorActive());
  const editingmode = isEditorActive();
  let DynamicTag;

  if (editingmode) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicTag = require('components/tag/Tag').Default;
  } else {
    // Dynamic import when not in editing mode
    DynamicTag = dynamic(() => import('components/tag/Tag').then((mod) => mod.Default), {
      ssr: !!config.dynamicImportSsr,
    });
  }
  let DynamicTertiary;

  if (editingmode) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicTertiary = require('components/link/Link').Tertiary;
  } else {
    // Dynamic import when not in editing mode
    DynamicTertiary = dynamic(() => import('components/link/Link').then((mod) => mod.Tertiary), {
      ssr: !!config.dynamicImportSsr,
    });
  }
  if (datasources) {
    return (
      <div
        className={`text-block ${sitecoreStyles(props.params?.styles)}`}
        id={id ? id : undefined}
      >
        <Text tag="div" className="text-block__title h5" field={datasources.title?.jsonValue} />
        <RichText
          tag="div"
          className="body2 text-block__body"
          field={datasources.text?.jsonValue}
        />

        {((datasources?.tags && datasources?.tags?.targetItems.length > 0) || editingMode) && (
          <div className="text-block__tags-container">
            {datasources?.tags?.targetItems.map((tag, index) => {
              const tagTmp: TextField = {
                value: tag.title?.jsonValue.value,
              };
              return (
                <div key={index} className="text-block__tag">
                  <DynamicTag params={{}} fields={{ Title: tagTmp }} />
                </div>
              );
            })}
          </div>
        )}
        {(datasources?.link?.jsonValue.value?.href || editingMode) && (
          <div className="text-block__link">
            <DynamicTertiary
              fields={{ Link: datasources?.link?.jsonValue }}
              params={{}}
            ></DynamicTertiary>
          </div>
        )}
      </div>
    );
  }

  return <TextBlockDefaultComponent {...props} />;
};

export const TextBlockDefaultComponent = (props: TextBlockProps): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  return (
    <React.Fragment>
      {editingMode && (
        <div className={`component textblock ${sitecoreStyles(props.params?.styles)}`}>
          <div className="component-content">
            <span className="is-empty-hint">Text Block</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
