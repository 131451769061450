import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { ScrollingAccordionCardFields } from 'components/scrolling-accordion/ScrollingAccordion';
import React, { useState } from 'react';
import { Tertiary } from 'components/link/Link';
import NextImageWithNullFallback from 'components/shared/nextImg/NextImageWithNullFallback';
import sitecoreStyles from 'src/helpers/sitecoreStyles';

interface ScrollingAccordionCardProps {
  params: { [key: string]: string };
  fields: ScrollingAccordionCardFields;
}

export const Default = (props: ScrollingAccordionCardProps): JSX.Element => {
  console.log('---->[Scrolling Accordion Card] Component', props.fields);
  const [editingMode] = useState(isEditorActive());
  if (props.fields) {
    return (
      <div
        className={`ga-track row scrolling-accordion__card
        ${sitecoreStyles(props.params?.styles)}
        ${
          !props.fields.Link?.value?.href && !editingMode
            ? 'scrolling-accordion__card--disabled'
            : ''
        }`}
        data-analytics-link-name={`${props.fields.Title?.value}`}
        data-analytics-link-type="Link"
        data-analytics-content-class="Content"
        data-analytics-template-zone="Body"
        data-analytics-component-name="Scrolling Accordian Card"
        data-analytics-component-variation="NAN"
        data-analytics-target={`${props.fields.Link?.value?.href}`}
        data-analytics-component-section="NAN"
        data-analytics-slide-number="NAN"
        data-analytics-ispersonalized="False"
        data-analytics-iscarousal="False"
      >
        <div className="col-6 scrolling-accordion__card-image-container">
          <NextImageWithNullFallback
            field={props.fields.Image}
            width={264}
            height={264}
            params={{
              styles: 'scrolling-accordion__card-image next-image--square',
            }}
          ></NextImageWithNullFallback>
        </div>

        <div className="col-12 col-lg-6 scrolling-accordion__card-details-container">
          <Text
            tag="h5"
            className={`scrolling-accordion__card-header`}
            field={props.fields.Title}
          />
          <RichText
            tag="div"
            className="body2 scrolling-accordion__card-body"
            field={props.fields.Content}
          />
          <div className="scrolling-accordion__card-link">
            <Tertiary params={{}} fields={{ Link: props.fields.Link }}></Tertiary>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default Default;
