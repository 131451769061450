import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { useCallback, useEffect, useState } from 'react';
import sitecoreStyles from 'src/helpers/sitecoreStyles';

export interface JumpLinkComponentFields {
  title: string;
  identifier: string;
  isActive?: boolean;
}

export interface JumpLinkFields {
  JumpLinkTitle?: Field<string>;
  JumpLinkIdentifier?: Field<string>;
}

type JumpLinksProps = {
  params: { [key: string]: string };
  fields: {
    jumpLinks: JumpLinkComponentFields[];
  };
};

let renderCount = 0;
let prevItem: DOMRect;
let currentItem: DOMRect;
let headerElement: Element | null;
let headerElementHeight: number;
let jumpLinksNavBox: Element | null;
let jumpLinksBoxTopInit: number;
let jumpLinksWrapper: Element | null;
const globalTopBottomPadding = 40;

export const Default = (props: JumpLinksProps): JSX.Element => {
  const trackRenders = () => {
    if (renderCount < 2) {
      renderCount++;
    }
  };

  const jumpBoxInit = () => {
    if (!jumpLinksBoxTopInit) {
      jumpLinksWrapper = document.querySelector('.jump-links-wrapper');
      headerElement = document.querySelector('#header');
      const pageTop = document.querySelector('body')?.getBoundingClientRect().top;
      if (jumpLinksWrapper && headerElement && pageTop) {
        headerElementHeight = headerElement?.getBoundingClientRect().height;
        jumpLinksBoxTopInit =
          jumpLinksWrapper.getBoundingClientRect().top -
          pageTop -
          headerElementHeight -
          globalTopBottomPadding / 2;
      }
    }
  };

  const jumpBoxInitEditingMode = () => {
    jumpLinksWrapper = document.querySelector('.jump-links-wrapper');
    const jumpLinksParent = jumpLinksWrapper?.parentElement;
    jumpLinksParent?.setAttribute('style', 'margin-bottom: ' + globalTopBottomPadding + 'px');
  };

  const setJumpLinkOffset = () => {
    jumpLinksNavBox = document.querySelector('.jump-links');
    if (jumpLinksNavBox) {
      const jumpLinkAnchorOffset = globalTopBottomPadding; // Adjust as needed
      const jumpLinkAnchors = document.querySelectorAll('.jump-link-anchor');
      jumpLinkAnchors.forEach((jumpLinkAnchor) => {
        const topValue = -jumpLinkAnchorOffset; // Ensure this is a valid number
        jumpLinkAnchor.setAttribute('style', `top: ${topValue}px`);
      });
    }
  };
  useEffect(() => {
    jumpBoxInit();
    setJumpLinkOffset();
  }, [props.fields.jumpLinks]);
  const setJumpLinksWrapperHeight = () => {
    if (jumpLinksNavBox && jumpLinksWrapper) {
      const jumpLinksInnerHeight = window
        .getComputedStyle(jumpLinksNavBox, null)
        .getPropertyValue('height');
      (jumpLinksWrapper as HTMLElement).style.height = jumpLinksInnerHeight;
    }
  };

  const [editingMode] = useState(isEditorActive());

  const isEditingMode = useCallback(() => {
    if (!editingMode) {
      jumpBoxInit();
      setJumpLinkOffset();
      setJumpLinksWrapperHeight();
    } else {
      jumpBoxInitEditingMode();
    }
  }, [editingMode]);

  const prepareData = () => {
    return (
      props.fields?.jumpLinks?.map((jumpLink) => {
        return {
          title: jumpLink.title,
          identifier: jumpLink.identifier,
          isActive: false,
        };
      }) || []
    );
  };

  const jumpLinksInit = prepareData();
  const [jumpLinks, setJumpLinks] = useState(jumpLinksInit);

  const handleSticky = () => {
    if (jumpLinksNavBox) {
      const scrollTop = window.scrollY;
      if (scrollTop > jumpLinksBoxTopInit) {
        jumpLinksNavBox.classList.add('jump-links--sticky');
        headerElement?.classList.add('jl__sticky');
      } else {
        jumpLinksNavBox.classList.remove('jump-links--sticky');
        headerElement?.classList.remove('jl__sticky');
      }
    }
  };

  const handleDividersDuringResising = () => {
    const items = document.getElementsByClassName('jump-links__list-item');

    for (let i = 0; i < items.length; i++) {
      currentItem = items[i].getBoundingClientRect();
      if (prevItem && prevItem.top < currentItem.top) {
        items[i - 1]?.classList.remove('jump-links__dividers');
      }

      items[i].classList.add('jump-links__dividers');
      prevItem = currentItem;
    }
  };

  const handleScroll = useCallback(() => {
    let hasActiveLinkChanged = false;

    jumpLinks.forEach((jumpEl) => {
      const element = document.getElementById(jumpEl.identifier);
      const rect = element?.getBoundingClientRect();

      if (rect && rect.top >= 0 && rect.top <= window.innerHeight && !hasActiveLinkChanged) {
        hasActiveLinkChanged = true;

        const updatedLinks = jumpLinks.map((jumpLink) => ({
          ...jumpLink,
          isActive: jumpLink.identifier === jumpEl.identifier,
        }));

        setJumpLinks(updatedLinks);
      }
    });

    handleSticky();
  }, [jumpLinks]);

  const handleResize = () => {
    setJumpLinksWrapperHeight();
  };

  useEffect(() => {
    trackRenders();
    isEditingMode();
    window.addEventListener('resize', handleDividersDuringResising);
    window.addEventListener('scroll', handleScroll);

    setJumpLinksWrapperHeight();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('resize', handleDividersDuringResising);
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleScroll, isEditingMode]);

  useEffect(() => {
    setJumpLinks(prepareData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.fields.jumpLinks]);

  return (
    <div className={`jump-links-wrapper ${sitecoreStyles(props.params?.styles)}`}>
      <div className="jump-links">
        <ul className="jump-links__list">
          {jumpLinks.map((jumpLink) => {
            return (
              <li
                className={`jump-links__list-item ${
                  jumpLink.isActive ? 'jump-links__list-item--active' : ''
                }`}
                key={jumpLink.identifier}
              >
                <a
                  onClick={() => handleScroll()}
                  className="body1 jump-links__link"
                  href={`#${jumpLink.identifier}`}
                  aria-label={jumpLink.title}
                  data-analytics-link-name={jumpLink.title}
                  data-analytics-link-type="Jump Link"
                  data-analytics-content-class="Navigation"
                  data-analytics-template-zone="Body"
                  data-analytics-component-name="Jump Link"
                  data-analytics-component-variation="NAN"
                  data-analytics-target={`${jumpLink.identifier}`}
                  data-analytics-component-section="Page Jump Link"
                  data-analytics-slide-number="NAN"
                  data-analytics-ispersonalized="False"
                  data-analytics-iscarousal="False"
                >
                  {jumpLink.title}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
