import { WithSitecoreContextProps, withSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import Head from 'next/head';
import React, { useState } from 'react';

export interface CustomMetadataItemFields {
  attributes?: {
    value: string;
  };
}

type CustomMetadataProps = {
  params: { [key: string]: string };
  fields?: {
    data: {
      contextItem?: CustomMetadataItemFields;
    };
  };
} & WithSitecoreContextProps;

const Default = (props: CustomMetadataProps): JSX.Element => {
  console.log('---->[CustomMetadata] Component', props.fields);

  const [editingMode] = useState(isEditorActive());

  const parseKeyValuePairs = (input?: string): { [key: string]: string } => {
    const result: { [key: string]: string } = {};
    if (input) {
      const pairs = input.split('&');
      for (const pair of pairs) {
        const [key, value] = pair.split('=');

        // Only add to the result if both key and value are present
        if (key && value) {
          result[key] = value;
        }
      }
    }
    return result;
  };

  const kvp = parseKeyValuePairs(props?.fields?.data?.contextItem?.attributes?.value);

  return (
    <>
      {editingMode && props.sitecoreContext.route?.templateName == 'Partial Design' ? (
        <div className="is-metadata-hint">
          <strong>Metadata:</strong> Custom Meta Tags
        </div>
      ) : (
        <Head>
          <>
            {kvp &&
              Object.entries(kvp).map(([key, value], index) => {
                return <meta key={index} name={key} content={value} />;
              })}
          </>
        </Head>
      )}
    </>
  );
};

export default withSitecoreContext()(Default);
