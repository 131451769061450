import React from 'react';
import { Field, RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { ReferencableItemFields } from 'src/types/Referencable';
import { TaggableFields } from 'src/types/Taggable';
import { TagFields } from '../../types/Tag';
import dynamic from 'next/dynamic';
import config from 'temp/config';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

export const TopFiveTagsCard = (props: ReferencableItemFields & TaggableFields): JSX.Element => {
  console.log('---->[TopFiveTagsCard] Component', props);
  const editingmode = isEditorActive();
  let DynamicTag;

  if (editingmode) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicTag = require('components/tag/Tag').Default;
  } else {
    DynamicTag = dynamic(() => import('components/tag/Tag').then((mod) => mod.Default), {
      ssr: !!config.dynamicImportSsr,
    });
  }

  let DynamicNextImageWithNullFallback;

  if (editingmode) {
    // Static import when in editing mode

    DynamicNextImageWithNullFallback =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/shared/nextImg/NextImageWithNullFallback').NextImageWithNullFallback;
  } else {
    // Dynamic import when not in editing mode
    DynamicNextImageWithNullFallback = dynamic(
      () =>
        import('components/shared/nextImg/NextImageWithNullFallback').then(
          (mod) => mod.NextImageWithNullFallback
        ),
      { ssr: !!config.dynamicImportSsr } // Adjust server-side rendering (ssr: true) based on your config
    );
  }

  const selectedContentTypes: (Field<string> | undefined)[] = [];
  const newTags: (TagFields | undefined)[] = [];

  const tags = props?.sxaTags?.targetItems;

  if (tags && tags.length && tags.length > 0) {
    tags.forEach((element) => {
      element.parent?.name == 'ContentType'
        ? selectedContentTypes.push(element.title?.jsonValue)
        : element.parent?.name != 'EventType' && newTags.push(element);
    });
  }
  const contentType: Field<string> | undefined =
    (selectedContentTypes.length > 0 && selectedContentTypes[0]) || undefined;

  return (
    <div className="top-five-card">
      {props.isFirstItem && (
        <div className="top-five-card__img">
          <DynamicNextImageWithNullFallback
            field={props.referenceImage?.jsonValue}
            params={{
              styles: 'next-image--16-9',
            }}
          />
        </div>
      )}
      <div className="top-five-card__container">
        <div className="topfivetagscards-container">
          {newTags && (
            <div className="top-five-card__tag body2">
              <DynamicTag
                params={{}}
                fields={{
                  Title: { value: newTags[0]?.title?.jsonValue?.value },
                }}
              />
            </div>
          )}
        </div>

        <a href={props?.url?.url} className="top-five-card__link">
          <Text
            tag="div"
            className={`top-five-card__title ${
              props.isFirstItem ? 'top-five-card__title--big' : 'top-five-card__title--small'
            }`}
            field={props.referenceTitle?.jsonValue}
            data-analytics-link-name={props.referenceTitle?.jsonValue.value}
            data-analytics-link-type="Link"
            data-analytics-content-class="Content"
            data-analytics-template-zone="Body"
            data-analytics-component-name="Top Five Client Stories"
            data-analytics-component-variation={
              props.isFirstItem ? 'top-five-card__title--big' : 'top-five-card__title--small'
            }
            data-analytics-target={props?.url?.url}
            data-analytics-component-section="NAN"
            data-analytics-slide-number="NAN"
            data-analytics-ispersonalized="False"
            data-analytics-iscarousal="False"
            data-analytics-category={
              props.referenceCategory?.targetItems[0]?.title?.jsonValue?.value
            }
          />
        </a>
        {props.isFirstItem && (
          <RichText
            tag="div"
            className="subheading1 top-five-card__text"
            field={props.referenceText?.jsonValue}
          />
        )}

        <Text tag="div" className="body2 top-five-card__content-type" field={contentType} />
      </div>
    </div>
  );
};
