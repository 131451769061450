import React, { useState } from 'react';
import { LinkField, RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { Default as PrimaryButton } from 'components/link/Link';
import { BannerFields } from 'components/banner/Banner';
import NextImageWithNullFallback from 'components/shared/nextImg/NextImageWithNullFallback';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import LazyLoadComponent from 'components/shared/lazy-load/LazyLoadComponent';

export type BannerProps = {
  params: { [key: string]: string };
  fields?: {
    data: {
      datasource?: BannerFields;
    };
  };
};

const FullBleedBannerDefaultComponent = (props: BannerProps): JSX.Element => (
  <div className={`component ${sitecoreStyles(props.params?.styles)}`}>
    <div className="component-content">Empty</div>
  </div>
);

export const FullBleedBanner = (props: BannerProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const [editingMode] = useState(isEditorActive());
  const titleTag = props.params['TitleTag'] || 'h3';
  if (props.fields) {
    return (
      <LazyLoadComponent componentId="FullBleedBanner">
        <div
          className={`full-bleed-banner component--with-paddings ${sitecoreStyles(
            props.params?.styles
          )}`}
          id={id ? id : undefined}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-5 full-bleed-banner__text-container">
                {!props.fields?.data.datasource?.hideContentLabel?.jsonValue?.value &&
                  props.fields?.data.datasource?.link?.jsonValue.value?.href &&
                  (isEditorActive() ? (
                    <div className="button-tags full-bleed-banner__tag">
                      {
                        props.fields?.data.datasource?.contentLabel?.targetItem?.title?.jsonValue
                          .value
                      }
                    </div>
                  ) : (
                    <Text
                      tag="div"
                      className="button-tags full-bleed-banner__tag"
                      field={
                        props.fields?.data.datasource?.contentLabel?.targetItem?.title
                          ?.jsonValue || {
                          value: '',
                        }
                      }
                    />
                  ))}
                <Text
                  tag={titleTag}
                  className="full-bleed-banner__title"
                  field={props.fields?.data.datasource?.title?.jsonValue}
                />
                <RichText
                  tag="div"
                  className="body2 full-bleed-banner__text"
                  field={props.fields?.data.datasource?.description?.jsonValue}
                />
                {(props.fields?.data.datasource?.link?.jsonValue.value?.href || editingMode) && (
                  <div className=" full-bleed-banner__link">
                    <PrimaryButton
                      fields={{ Link: props.fields?.data.datasource?.link?.jsonValue as LinkField }}
                      params={{ styles: 'link__button-primary' }}
                    />
                  </div>
                )}
              </div>
              <div className="col-12 col-lg-7 full-bleed-banner__image-container">
                <NextImageWithNullFallback
                  field={props.fields?.data.datasource?.image?.jsonValue}
                  width={658}
                  height={370}
                  params={{
                    styles: 'next-image--16-9',
                  }}
                ></NextImageWithNullFallback>
              </div>
            </div>
          </div>
        </div>
      </LazyLoadComponent>
    );
  }
  return <FullBleedBannerDefaultComponent {...props} />;
};
