import { TimageFields } from 'components/timage/Timage';
import { TextImageComponent } from './TimageLeft';

export type TimageProps = {
  params: { [key: string]: string; styles: string };
  fields: TimageFields;
  variant: 'default' | 'square';
};

export const TextImageLeftSquareTimage = (props: TimageProps): JSX.Element => {
  return <TextImageComponent props={props} variant="square" />;
};
