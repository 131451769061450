import {
  Field,
  withSitecoreContext,
  WithSitecoreContextProps,
} from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useState } from 'react';
import { SearchEngineContext } from '../search-context/SearchContext';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { loadSortCriteriaActions, SortBy, SortOrder } from '@coveo/headless';
import sitecoreStyles from 'src/helpers/sitecoreStyles';

interface SearchSortFields {
  FieldName?: Field<string>;
  OrderBy: Field<string>;
}

type SearchSortProps = {
  params: { [key: string]: string };
  fields: SearchSortFields;
} & WithSitecoreContextProps;

const Default = (props: SearchSortProps): JSX.Element => {
  console.log('---->[SearchSort] Component', props.fields);

  const searchScope = props.params.SearchScope;
  const { getSearchEngine } = React.useContext(SearchEngineContext);
  const searchEngine = getSearchEngine(searchScope);

  const [editingMode] = useState(isEditorActive());

  const sortCreators = loadSortCriteriaActions(searchEngine);
  if (props.fields?.FieldName?.value) {
    let orderBy: SortOrder = SortOrder.Ascending;
    if (props.fields?.OrderBy?.value === 'Descending') {
      orderBy = SortOrder.Descending;
    }
    const sortCriteria = sortCreators.registerSortCriterion({
      field: props.fields.FieldName.value,
      order: orderBy,
      by: SortBy.Field,
    });
    searchEngine.dispatch(sortCriteria);
  }

  return (
    <>
      {editingMode && (
        <div className={`is-metadata-hint ${sitecoreStyles(props.params?.styles)}`}>
          <strong>Search sort criteria</strong>
          <div>
            {props.fields?.FieldName?.value && (
              <pre>
                <strong>Field Name: </strong>
                {props.fields?.FieldName?.value}
              </pre>
            )}
            {props.fields?.OrderBy?.value && (
              <pre>
                <strong>Order By: </strong>
                {props.fields?.OrderBy?.value}
              </pre>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default withSitecoreContext()(Default);
