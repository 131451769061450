import { CoveoSearchApiCareersResult } from 'lib/coveo/CoveoSearchApi';
import { createContext, ReactNode, useContext } from 'react';

export type CareerJobDetailsContextProps = {
  jobDetails?: CoveoSearchApiCareersResult; // Consider specifying a more precise type instead of any
  jobRecommendations: CoveoSearchApiCareersResult[];
};

const CareerJobDetailsContext = createContext<CareerJobDetailsContextProps>({
  jobDetails: undefined,
  jobRecommendations: [],
});

export const CareerJobDetailsProvider = ({
  children,
  jobDetails,
  jobRecommendations,
}: {
  children: ReactNode;
  jobDetails: CoveoSearchApiCareersResult | undefined;
  jobRecommendations: CoveoSearchApiCareersResult[] | undefined;
}) => {
  return (
    <CareerJobDetailsContext.Provider
      value={{
        jobDetails,
        jobRecommendations: jobRecommendations || [],
      }}
    >
      {children}
    </CareerJobDetailsContext.Provider>
  );
};

export const useCareerJobDetailsContext = () => {
  const context = useContext(CareerJobDetailsContext);
  if (!context) {
    throw new Error('useCareerJobDetailsContext must be used within a CareerJobDetailsProvider');
  }
  return context;
};
