import { WithSitecoreContextProps, withSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import Head from 'next/head';
import { useState } from 'react';
import { OgpItemFields } from 'src/types/Ogp';
import { PageUrl } from 'src/types/PageUrl';
import { ReferencableItemFields } from 'src/types/Referencable';
import { SeoItemFields } from 'src/types/SeoSeo';

type OgpComponentDatasource = PageUrl & OgpItemFields & SeoItemFields & ReferencableItemFields;

type OgpProps = {
  params: { [key: string]: string };
  fields?: {
    data: {
      datasource: OgpComponentDatasource;
    };
  };
} & WithSitecoreContextProps;

const Default = (props: OgpProps): JSX.Element => {
  console.log('---->[Ogp] Component', props.fields);
  const [editingMode] = useState(isEditorActive());

  const page = props.fields?.data?.datasource;

  if (!page) {
    console.log('---->[Ogp] Component failed to load.', props.fields);
    return <></>;
  }

  const title =
    page?.openGraphTitle?.value || page.metaTitle?.value || page.referenceTitle?.jsonValue?.value;
  const description =
    page.openGraphDescription?.value ||
    page.metaDescription?.value ||
    page.referenceText?.jsonValue?.value;
  const image = page.openGraphImageUrl?.jsonValue || page.referenceImage?.jsonValue;
  const type = page.openGraphType?.value || 'website';

  return (
    <>
      {editingMode && props.sitecoreContext.route?.templateName == 'Partial Design' ? (
        <div className="is-metadata-hint">
          <strong>Metadata:</strong> Open Graph Protocol
        </div>
      ) : (
        <Head>
          {title && <meta property="og:title" content={title} />}
          {description && <meta property="og:description" content={description} />}
          {image && image.value?.src && <meta property="og:image" content={image.value?.src} />}
          {type && <meta property="og:type" content={type} />}
          <meta property="og:url" content={page.url.url} />
          {page.openGraphAppId?.value && (
            <meta property="fb:app_id" content={page.openGraphAppId?.value} />
          )}
          {page.openGraphAdmins?.value && (
            <meta property="fb:admins" content={page.openGraphAdmins?.value} />
          )}
        </Head>
      )}
    </>
  );
};

export default withSitecoreContext()(Default);
