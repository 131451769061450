import React, { useState } from 'react';
import { Text, TextField, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import dynamic from 'next/dynamic';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import config from 'temp/config';
interface Fields {
  Title: TextField;
  Icon?: ImageField;
}

type TagProps = {
  params: { [key: string]: string };
  fields?: Fields;
};

const TagDefaultComponent = (props: TagProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const [editingMode] = useState(sitecoreContext && sitecoreContext.pageState === 'edit');
  return (
    <React.Fragment>
      {editingMode && (
        <div className={`tag ${sitecoreStyles(props.params.styles)}`}>
          <div className="tag">Tag</div>
        </div>
      )}
    </React.Fragment>
  );
};

export const Default = (props: TagProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  if (props.fields) {
    return (
      <div
        className={`tag ${sitecoreStyles(props.params.styles)}`}
        id={id ? id : undefined}
        role="note"
        aria-label={`tag ${props.fields.Title.value}`}
      >
        <div className="tag__content">
          <Text field={props.fields.Title}></Text>
        </div>
      </div>
    );
  }
  return <TagDefaultComponent {...props} />;
};
const editingmode = isEditorActive();
let DynamicNextImageWithNullFallback;

if (editingmode) {
  // Static import when in editing mode

  DynamicNextImageWithNullFallback =
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    require('components/shared/nextImg/NextImageWithNullFallback').NextImageWithNullFallback;
} else {
  // Dynamic import when not in editing mode
  DynamicNextImageWithNullFallback = dynamic(
    () =>
      import('components/shared/nextImg/NextImageWithNullFallback').then(
        (mod) => mod.NextImageWithNullFallback
      ),
    { ssr: !!config.dynamicImportSsr } // Adjust server-side rendering (ssr: true) based on your config
  );
}

export const TagWithIconLeft = (props: TagProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const [editingMode] = useState(sitecoreContext && sitecoreContext.pageState === 'edit');

  const id = props.params.RenderingIdentifier;
  if (props.fields) {
    return (
      <div
        className={`tag tag--with-icon ${editingMode ? 'tag-edit' : ''} ${sitecoreStyles(
          props.params.styles
        )}`}
        id={id ? id : undefined}
        role="note"
        aria-label={`tag ${props.fields.Title.value}`}
      >
        <DynamicNextImageWithNullFallback
          field={props.fields.Icon}
          alt={`${props.fields.Title.value}`}
          width={24}
          height={24}
          params={{
            styles: 'tag__icon',
          }}
        ></DynamicNextImageWithNullFallback>

        <div className="body2">
          <Text field={props.fields.Title}></Text>
        </div>
      </div>
    );
  }
  return <TagDefaultComponent {...props} />;
};

export const TagWithIconRight = (props: TagProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const [editingMode] = useState(sitecoreContext && sitecoreContext.pageState === 'edit');
  const id = props.params.RenderingIdentifier;
  if (props.fields) {
    return (
      <div
        className={`tag tag--with-icon ${editingMode ? 'tag-edit' : ''} ${sitecoreStyles(
          props.params.styles
        )}`}
        id={id ? id : undefined}
        role="note"
        aria-label={`tag ${props.fields.Title.value}`}
      >
        <div className="body1">
          <Text field={props.fields.Title}></Text>
        </div>

        <DynamicNextImageWithNullFallback
          field={props.fields.Icon}
          alt={`${props.fields.Title.value}`}
          width={24}
          height={24}
          params={{
            styles: 'tag__icon',
          }}
        ></DynamicNextImageWithNullFallback>
      </div>
    );
  }
  return <TagDefaultComponent {...props} />;
};
