/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { useI18n } from 'next-localization';
import {
  buildSmartSnippet,
  AtomicResultList,
  AtomicQueryError,
  AtomicNoResults,
  AtomicResultLink,
  AtomicResultText,
  AtomicLayoutSection,
  buildResultList,
  loadFieldActions,
  AtomicSmartSnippet,
  AtomicSmartSnippetSuggestions,
  AtomicQuerySummary,
} from '@coveo/atomic-react';
import { AtomicPageWrapper } from '../atomic/AtomicPageWrapper';
import { SearchEngineContext } from '../search-context/SearchContext';
import {
  ClientStoryCard,
  InsightStoryCard,
  EventWebinarCard,
  NewsRoomCard,
} from 'components/client-story-card/ClientStoryCard';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { Loader } from 'components/shared/loader/Loader';
import { Icon } from 'components/shared/icon/Icon';
import { IconName } from 'src/types/Enum';
import { JobsSearchResults } from 'components/search/search-results/variants/jobs/JobsSearchResults';

export interface Fields {
  ResultsNotFoundText: Field<string>;
}

export type SearchResultsProps = {
  params: { [key: string]: string };
  fields: Fields;
};

export type Density = 'comfortable' | 'normal' | 'compact';
export type Display = 'list' | 'grid' | 'table';
export type ImageSize = 'large' | 'small' | 'icon' | 'none';

export const Default = (props: SearchResultsProps): JSX.Element => {
  console.log('---->[SearchResults] Component', props.fields);

  const display = (props.params['Display'] || undefined) as Display | undefined;
  const density = (props.params['Density'] || undefined) as Density | undefined;
  const imageSize = (props.params['ImageSize'] || undefined) as ImageSize | undefined;

  const searchScope = props.params.SearchScope;
  const { getSearchEngine } = React.useContext(SearchEngineContext);
  const searchEngine = getSearchEngine(searchScope);

  const [editingMode] = useState<boolean>(isEditorActive());
  const id = props.params.RenderingIdentifier;
  const [isClient, setIsClient] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { t, locale } = useI18n();

  // Atomic Smart Snippet state
  const [smartSnippet] = useState(buildSmartSnippet(searchEngine));
  const [smartSnippetState, setsmartSnippetState] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    smartSnippet.subscribe(() => {
      setsmartSnippetState(smartSnippet.state?.answerFound); //set boolean value according to availability of smart snippet answer
    });
  }, [searchEngine]);

  useEffect(() => {
    // Subscribe to search engine state changes to track loading state
    const unsubscribe = searchEngine.subscribe(() => {
      setIsLoading(searchEngine.state.search.isLoading);
    });
    return () => {
      unsubscribe();
    };
  }, [searchEngine]);

  const getLinkStyle = () => {
    const width = window.innerWidth;

    if (width >= 1024) {
      // Desktop and up
      return {
        fontSize: '24px',
        lineHeight: '32px',
        fontWeight: '700',
        marginBottom: '16px',
      };
    } else if (width >= 768) {
      // Tablet and up
      return {
        fontSize: '20px',
        fontWeight: '700',
        marginBottom: '16px',
        lineHeight: 'normal',
      };
    } else {
      // Mobile and below
      return {
        fontSize: '18px',
        lineHeight: 'normal',
        fontWeight: '700',
        marginBottom: '16px',
      };
    }
  };

  const searchResultItemTemplate = () => {
    setIsLoading(false);
    return (
      <div
        className="search-results__result-box"
        style={{
          padding: '24px',
          backgroundColor: '#F5F7FA',
          width: '100%',
          borderRadius: '30px',
          display: 'flex',
          flexDirection: 'column',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '24px',
        }}
      >
        <AtomicResultLink className="search-results__result-link" style={getLinkStyle()} />
        <AtomicResultText field={'excerpt'} />
      </div>
    );
  };

  return (
    <div className={`search-results ${sitecoreStyles(props.params?.styles)}`}>
      {isClient &&
        // adding code to facilitate content loading in editing mode
        (editingMode ? (
          <div className="is-metadata-hint">
            <strong>Search:</strong> results
          </div>
        ) : (
          <AtomicPageWrapper engine={searchEngine}>
            <AtomicLayoutSection id={id ? id : undefined} section="results">
              {isLoading && <Loader />}
              {/* check if smart snippet is available */}
              {!isLoading && locale()?.toLowerCase().startsWith('en') && smartSnippetState && (
                <>
                  <h5>{t(`coveo-recommended-result`)}</h5>
                  <div className="event__featured body1">
                    <Icon icon={IconName.Sparkle} iconLabel={'Sparkle'} />
                    <span className="sparkle_title">{t(`coveo-ai-curated`)}</span>
                  </div>
                  {/* adding atomic smart snippets */}
                  {/* https://docs.coveo.com/en/atomic/latest/reference/components/atomic-smart-snippet/ */}
                  <AtomicSmartSnippet />
                </>
              )}

              {locale()?.toLowerCase().startsWith('en') && ( //adding atomic smart snippet suggesions for en languages
                //https://docs.coveo.com/en/atomic/latest/reference/components/atomic-smart-snippet-suggestions/
                <AtomicSmartSnippetSuggestions />
              )}

              {/* adding total results statement */}
              <AtomicQuerySummary />

              <AtomicResultList
                display={display}
                density={density}
                imageSize={imageSize}
                template={searchResultItemTemplate}
              ></AtomicResultList>
              {searchEngine.state.search.isLoading && <Loader></Loader>}
              <AtomicQueryError />
              <AtomicNoResults />
            </AtomicLayoutSection>
          </AtomicPageWrapper>
        ))}
    </div>
  );
};

export const ClientStories = (props: SearchResultsProps): JSX.Element => {
  console.log('---->[SearchResults] Component', props.fields);

  const searchScope = props.params.SearchScope;
  const { getSearchEngine } = React.useContext(SearchEngineContext);
  const searchEngine = getSearchEngine(searchScope);

  const fieldActions = loadFieldActions(searchEngine); //need a SC param
  const includeFields = fieldActions.registerFieldsToInclude([
    'pagereftitle',
    'pagereftext',
    'pagerefimage',
    'pagetag',
    'taxonomy_content',
  ]);
  searchEngine.dispatch(includeFields);
  const [headlessResultList] = useState(buildResultList(searchEngine));
  const [headlessResultListState, setheadlessResultList] = useState(headlessResultList.state);
  const [firstSearch, setFirstSearch] = useState(true);
  const [editingMode] = useState(isEditorActive());

  const id = props.params.RenderingIdentifier;

  useEffect(() => {
    headlessResultList.subscribe(() => {
      setheadlessResultList(headlessResultList.state);
    });
  }, [searchEngine]);

  useEffect(() => {
    if (window.location.hash) {
      setFirstSearch(false);
    } else {
      setFirstSearch(true);
    }
  }, [headlessResultListState]);

  useEffect(() => {
    if (!firstSearch) {
      document.querySelector('.top-five')?.classList.add('top-five--hide');
      document
        .querySelector('.container-grey-background')
        ?.classList.add('container-grey-background--hide');
      document
        .querySelector('.section-header-tertiary')
        ?.classList.add('section-header-tertiary--hide');
    } else {
      document.querySelector('.top-five')?.classList.remove('top-five--hide');
      document
        .querySelector('.container-grey-background')
        ?.classList.remove('container-grey-background--hide');
      document
        .querySelector('.section-header-tertiary')
        ?.classList.remove('section-header-tertiary--hide');
    }
  }, [firstSearch]);

  if (
    !editingMode &&
    headlessResultListState.results.length == 0 &&
    !firstSearch &&
    headlessResultListState.firstSearchExecuted
  ) {
    return <NoResults></NoResults>;
  }
  return (
    <div
      className={`custom-results-list row ${sitecoreStyles(props.params.styles)}`}
      id={id ? id : undefined}
    >
      {searchEngine.state.search.isLoading && <Loader></Loader>}

      {editingMode ? (
        <div className="is-metadata-hint">
          <strong>Search:</strong> results
        </div>
      ) : (
        <React.Fragment>
          {headlessResultListState.results.map((result, index) => {
            const contenttype = result.raw.taxonomy_content as Array<string>;
            return (
              <div
                className={` ${
                  // eslint-disable-next-line prettier/prettier
                                    index >= 3 || !firstSearch ? 'col-12 col-md-6 col-lg-3' : 'col-12 col-lg-6'
                }`}
                key={index}
              >
                <ClientStoryCard
                  key={index}
                  params={{ name: 'client-story-card' }}
                  fields={{
                    Image: {
                      value: {
                        src: (result.raw.pagerefimage || result.raw.ec_images) as string,
                        width: 1,
                        height: 1,
                        sizes: '100vw',
                      },
                    },
                    Industry: { value: result.raw.ec_brand as string },
                    Title: { value: result.raw.pagereftitle as string },
                    Description: { value: result.raw.pagereftext as string },
                    Url: result.uri,
                    ContentType: { value: contenttype && contenttype[0] },
                    Tag: { value: result.raw.pagetag as string },
                  }}
                ></ClientStoryCard>
              </div>
            );
          })}
        </React.Fragment>
      )}
    </div>
  );
};

export const InsightStories = (props: SearchResultsProps): JSX.Element => {
  console.log('---->[SearchResults] Component', props.fields);

  const searchScope = props.params.SearchScope;
  const { getSearchEngine } = React.useContext(SearchEngineContext);
  const searchEngine = getSearchEngine(searchScope);

  const fieldActions = loadFieldActions(searchEngine); //need a SC param
  const includeFields = fieldActions.registerFieldsToInclude([
    'pagereftitle',
    'pagereftext',
    'pagerefimage',
    'pagetag',
    'taxonomy_industry',
    'taxonomy_services',
    'taxonomy_content',
  ]);
  searchEngine.dispatch(includeFields);
  const [headlessResultList] = useState(buildResultList(searchEngine));
  const [headlessResultListState, setheadlessResultList] = useState(headlessResultList.state);
  const [firstSearch, setFirstSearch] = useState(true);
  const [editingMode] = useState(isEditorActive());

  const id = props.params.RenderingIdentifier;

  useEffect(() => {
    headlessResultList.subscribe(() => {
      setheadlessResultList(headlessResultList.state);
    });
  }, [searchEngine]);

  useEffect(() => {
    if (window.location.hash) {
      setFirstSearch(false);
    } else {
      setFirstSearch(true);
    }
  }, [headlessResultListState]);

  useEffect(() => {
    if (!firstSearch) {
      document.querySelector('.top-five')?.classList.add('top-five--hide');
      document
        .querySelector('.container-grey-background')
        ?.classList.add('container-grey-background--hide');
      document
        .querySelector('.section-header-tertiary')
        ?.classList.add('section-header-tertiary--hide');
    } else {
      document.querySelector('.top-five')?.classList.remove('top-five--hide');
      document
        .querySelector('.container-grey-background')
        ?.classList.remove('container-grey-background--hide');
      document
        .querySelector('.section-header-tertiary')
        ?.classList.remove('section-header-tertiary--hide');
    }
  }, [firstSearch]);

  if (
    !editingMode &&
    headlessResultListState.results.length == 0 &&
    !firstSearch &&
    headlessResultListState.firstSearchExecuted
  ) {
    return <NoResults></NoResults>;
  }
  return (
    <div
      className={`custom-results-list row ${sitecoreStyles(props.params.styles)}`}
      id={id ? id : undefined}
    >
      {searchEngine.state.search.isLoading && <Loader></Loader>}

      {editingMode ? (
        <div className="is-metadata-hint">
          <strong>Search:</strong> results
        </div>
      ) : (
        <React.Fragment>
          {headlessResultListState.results.map((result, index) => {
            let taxonomyTag =
              result.raw.taxonomy_industry || result.raw.taxonomy_services || result.raw.pagetag;

            if (Array.isArray(taxonomyTag)) {
              taxonomyTag = taxonomyTag.join(';');
            }

            const contenttype = result.raw.taxonomy_content as Array<string>;
            return (
              <div
                className={` ${
                  // eslint-disable-next-line prettier/prettier
                  index >= 3 || !firstSearch ? 'col-12 col-md-6 col-lg-3' : 'col-12 col-lg-6'
                }`}
                key={index}
              >
                <InsightStoryCard
                  key={index}
                  params={{ name: 'insight-story-card' }}
                  fields={{
                    Image: {
                      value: {
                        src: (result.raw.pagerefimage || result.raw.ec_images) as string,
                        width: 1,
                        height: 1,
                        sizes: '100vw',
                      },
                    },
                    Industry: { value: result.raw.ec_brand as string },
                    Title: { value: result.raw.pagereftitle as string },
                    Description: { value: result.raw.pagereftext as string },
                    Url: result.uri,
                    ContentType: { value: contenttype && contenttype[0] },
                    Tag: { value: taxonomyTag as string },
                  }}
                ></InsightStoryCard>
              </div>
            );
          })}
        </React.Fragment>
      )}
    </div>
  );
};

export const Events = (props: SearchResultsProps): JSX.Element => {
  console.log('---->[SearchResults] Component', props.fields);

  const searchScope = props.params.SearchScope;
  const { getSearchEngine } = React.useContext(SearchEngineContext);
  const searchEngine = getSearchEngine(searchScope);

  const fieldActions = loadFieldActions(searchEngine); //need a SC param
  const includeFields = fieldActions.registerFieldsToInclude([
    'pagereftitle',
    'pagereftext',
    'pagerefimage',
    'pageeventstartdate',
    'pageeventenddate',
    'taxonomy_event',
  ]);
  searchEngine.dispatch(includeFields);
  const [headlessResultList] = useState(buildResultList(searchEngine));
  const [headlessResultListState, setheadlessResultList] = useState(headlessResultList.state);
  const [firstSearch, setFirstSearch] = useState(true);
  const [editingMode] = useState(isEditorActive());

  const id = props.params.RenderingIdentifier;

  useEffect(() => {
    headlessResultList.subscribe(() => {
      setheadlessResultList(headlessResultList.state);
    });
  }, [searchEngine]);

  useEffect(() => {
    if (!window.location.hash || window.location.hash.includes('sortCriteria')) {
      setFirstSearch(true);
      if (window.location.hash.includes('taxonomy') || window.location.hash.includes('q=')) {
        setFirstSearch(false);
      }
    } else {
      setFirstSearch(false);
    }
  }, [headlessResultListState]);

  useEffect(() => {
    if (!firstSearch) {
      document.querySelector('.top-five')?.classList.add('top-five--hide');
    } else {
      document.querySelector('.top-five')?.classList.remove('top-five--hide');
    }
  }, [firstSearch]);

  if (
    !editingMode &&
    headlessResultListState.results.length == 0 &&
    !firstSearch &&
    headlessResultListState.firstSearchExecuted
  ) {
    return <NoResults></NoResults>;
  }
  return (
    <div
      className={`custom-results-list row ${sitecoreStyles(props.params.styles)}`}
      id={id ? id : undefined}
    >
      {searchEngine.state.search.isLoading && <Loader></Loader>}

      {editingMode ? (
        <div className="is-metadata-hint">
          <strong>Search:</strong> results
        </div>
      ) : (
        <React.Fragment>
          {headlessResultListState.results.map((result, index) => {
            const eventtype = result.raw.taxonomy_event as Array<string>;
            if (firstSearch) {
              document
                .querySelector('.custom-query-summary')
                ?.classList.add('custom-query-summary--hide');
            } else {
              document
                .querySelector('.custom-query-summary')
                ?.classList.remove('custom-query-summary--hide');
            }
            return (
              <div
                className={` ${
                  // eslint-disable-next-line prettier/prettier
                  index >= 3 || !firstSearch ? 'col-12 col-md-6 col-lg-3' : 'col-12 col-lg-6'
                }`}
                key={index}
              >
                <EventWebinarCard
                  key={index}
                  params={{ name: 'events-webinar-card' }}
                  fields={{
                    Image: {
                      value: {
                        src: (result.raw.pagerefimage || result.raw.ec_images) as string,
                        width: 1,
                        height: 1,
                        sizes: '100vw',
                      },
                    },
                    Title: { value: result.raw.pagereftitle as string },
                    Description: { value: result.raw.pagereftext as string },
                    Url: result.uri,
                    startDateTime: {
                      jsonValue: { value: result.raw.pageeventstartdate as string },
                    },
                    endDateTime: { jsonValue: { value: result.raw.pageeventenddate as string } },
                    EventType: { value: eventtype && eventtype[0] },
                  }}
                ></EventWebinarCard>
              </div>
            );
          })}
        </React.Fragment>
      )}
    </div>
  );
};

export const NewsRoom = (props: SearchResultsProps): JSX.Element => {
  console.log('---->[SearchResults] Component', props.fields);

  const searchScope = props.params.SearchScope;
  const { getSearchEngine } = React.useContext(SearchEngineContext);
  const searchEngine = getSearchEngine(searchScope);

  const fieldActions = loadFieldActions(searchEngine); //need a SC param
  const includeFields = fieldActions.registerFieldsToInclude([
    'pagereftitle',
    'pagereftext',
    'pageposteddate',
  ]);
  searchEngine.dispatch(includeFields);
  const [headlessResultList] = useState(buildResultList(searchEngine));
  const [headlessResultListState, setheadlessResultList] = useState(headlessResultList.state);
  const [firstSearch, setFirstSearch] = useState(true);
  const [editingMode] = useState(isEditorActive());

  const id = props.params.RenderingIdentifier;

  useEffect(() => {
    headlessResultList.subscribe(() => {
      setheadlessResultList(headlessResultList.state);
    });
  }, [searchEngine]);

  useEffect(() => {
    if (!window.location.hash || window.location.hash.includes('sortCriteria')) {
      setFirstSearch(true);
      if (window.location.hash.includes('taxonomy') || window.location.hash.includes('q=')) {
        setFirstSearch(false);
      }
    } else {
      setFirstSearch(false);
    }
  }, [headlessResultListState]);

  useEffect(() => {
    if (!firstSearch) {
      document.querySelector('.top-five')?.classList.add('top-five--hide');
    } else {
      document.querySelector('.top-five')?.classList.remove('top-five--hide');
    }
  }, [firstSearch]);

  if (
    !editingMode &&
    headlessResultListState.results.length == 0 &&
    !firstSearch &&
    headlessResultListState.firstSearchExecuted
  ) {
    return <NoResults></NoResults>;
  }
  return (
    <div
      className={`custom-results-list newsroom-results-list ${sitecoreStyles(props.params.styles)}`}
      id={id ? id : undefined}
    >
      {searchEngine.state.search.isLoading && <Loader></Loader>}

      {editingMode ? (
        <div className="is-metadata-hint">
          <strong>Search:</strong> results
        </div>
      ) : (
        <React.Fragment>
          {headlessResultListState.results.map((result, index) => {
            if (firstSearch) {
              document
                .querySelector('.custom-query-summary')
                ?.classList.add('custom-query-summary--hide');
            } else {
              document
                .querySelector('.custom-query-summary')
                ?.classList.remove('custom-query-summary--hide');
            }
            return (
              <NewsRoomCard
                key={index}
                params={{ name: 'events-webinar-card' }}
                fields={{
                  Title: { value: result.raw.pagereftitle as string },
                  Url: result.uri,
                  postedDate: { jsonValue: { value: result.raw.pageposteddate as string } },
                }}
              ></NewsRoomCard>
            );
          })}
        </React.Fragment>
      )}
    </div>
  );
};

export const Jobs = (props: SearchResultsProps): JSX.Element => {
  console.log('---->[SearchResults:Jobs] Component', props.fields);
  return <JobsSearchResults {...props} />;
};

export const NoResults = ({
  isCareerJobs,
}: {
  isCareerJobs?: boolean | undefined;
}): JSX.Element => {
  const { t } = useI18n();
  const noResults = isCareerJobs ? t('career-job-no-results-message') : t('coveo-no-search-text');
  const tryAgain = isCareerJobs ? t('career-job-no-results') : t('coveo-try-again');
  return (
    <div className="custom-results-list__no-result row">
      <div className="col-12 col-md-8 col-lg-6">
        <div className="row">
          <div className="col-12">
            <h3>{tryAgain}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p className="body2 custom-results-list__no-result-description">{noResults}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <button
              className="link__button-primary custom-results-list__no-result-btn"
              onClick={() => {
                window.location.hash.includes('sortCriteria') ||
                window.location.hash.includes('taxonomy')
                  ? (window.location.hash = window.location.hash.replace(/#q=([^&]+)/, ''))
                  : (window.location.hash = window.location.hash.replace(/#q=.*/, ''));
              }}
            >
              {t('coveo-clear-all-filters')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
