import { SearchEngine, buildFacet } from '@coveo/atomic-react';
import { Icon } from 'components/shared/icon/Icon';
import { useState, useEffect } from 'react';
import { IconName } from 'src/types/Enum';

interface CustomFacetProps {
  engine: SearchEngine;
  facetName: string;
  title: string;
}

export const CustomFacet = ({ engine, facetName, title }: CustomFacetProps): JSX.Element => {
  const [headlessFacet] = useState(
    buildFacet(engine, {
      options: {
        numberOfValues: 25,
        field: facetName,
      },
    })
  );

  const [open, setOpen] = useState(false);

  const [facetState, setFacetState] = useState(headlessFacet.state);

  useEffect(() => {
    headlessFacet.subscribe(() => {
      setFacetState(headlessFacet.state);
    });
  }, [headlessFacet]);

  console.log(headlessFacet, 'engine');
  const selectedFacets = facetState.values.filter((facet) => facet.state == 'selected').length;

  return (
    <div
      className={`custom-facet ${
        facetState.values.length === 0 ? 'custom-facet--empty' : 'custom-facet--selected'
      }`}
      id="pet-select"
      onBlur={() => setOpen(false)}
      onClick={() => setOpen(!open)}
      tabIndex={0}
    >
      <div className="custom-facet__title">
        <span className={`${selectedFacets > 0 ? 'body1' : 'body2'}`}>
          {title}
          {selectedFacets ? `: ${selectedFacets}` : ''}
        </span>

        <div
          className={`custom-facet__icon ${
            open && facetState.values.length > 0 ? 'custom-facet__icon--rotate' : ''
          }`}
        >
          <Icon icon={IconName.ChevronDown} iconLabel={'Open'} />
        </div>
      </div>
      {open && facetState.values.length > 0 && (
        <div
          className={`custom-facet__list ${
            facetState.values.length > 0 ? 'custom-facet__list--selected' : ''
          }`}
        >
          {facetState.values.map((value, index) => {
            return (
              <div
                className="custom-facet__list-item body2"
                key={index}
                onClick={(event) => {
                  event.stopPropagation();
                  headlessFacet.toggleSelect(value);
                }}
              >
                <span>{value.value}</span>
                <span>
                  {headlessFacet.isValueSelected(value) ? (
                    <Icon icon={IconName.Checkmark} iconLabel={'Close'} />
                  ) : (
                    ''
                  )}
                </span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
