import React, { useEffect, useRef, useState } from 'react';
import {
  NextImage,
  Image,
  ImageField,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ImageProps } from '@sitecore-jss/sitecore-jss-react';
import { ImageProps as NextImageProperties } from 'next/image';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { useComponentVisibility } from 'lib/contexts/ComponentVisibilityContext';

type NextImageProps = Omit<ImageProps, 'media'> & Partial<NextImageProperties>;

type PictureProps = {
  params?: { [key: string]: string };
};

const NoImage = (props: NextImageProps & PictureProps): JSX.Element => (
  <div className={`next-image__container ${props.params?.styles}`}>
    <div className="next-image next-image--empty" {...props}></div>
  </div>
);

export const NextImageWithNullFallback = (props: NextImageProps & PictureProps) => {
  const field = props.field as ImageField;
  const [editingMode] = useState(isEditorActive());
  const { sitecoreContext } = useSitecoreContext();

  // Function to determine if the image is from Sitecore Content Hub
  const isContentHubImage =
    !!field?.value?.src?.toLowerCase()?.includes('avanade.com') ||
    field?.value?.src?.toLowerCase()?.includes('sitecorecontenthub.cloud');

  // Function to adjust URL for Content Hub images and other scenarios
  const adjustImageUrl = (url: string) => {
    if (isContentHubImage) {
      // No adjustment needed for Content Hub images
      return url;
    }

    // HOTFIX: Sitecore issue with gql query media links
    let adjustedUrl = url.replace('-/jssmedia/', '-/media/');
    if (adjustedUrl.startsWith('-')) {
      adjustedUrl = `/${adjustedUrl}`;
    }
    if (adjustedUrl.startsWith('/media')) {
      adjustedUrl = `/-${adjustedUrl}`;
    }

    return adjustedUrl;
  };
  const ref = useRef<HTMLDivElement>(null);
  const [showComponent, setShowComponent] = useState(false);
  const { hasScrolled, handleScroll } = useComponentVisibility();
  const editingmode = isEditorActive();

  // Listen for the scroll event to load components after first scroll
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (!editingmode) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (ref.current) {
              observer.unobserve(ref.current);
              setShowComponent(true);
            }
          }
        });
      });
      if (ref.current) {
        observer.observe(ref.current);
      }
      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    } else {
      setShowComponent(true);
      return () => {
        setShowComponent(false);
      };
    }
  }, [ref]);
  if (
    sitecoreContext &&
    (sitecoreContext.pageState === 'edit' || sitecoreContext.pageState === 'preview')
  ) {
    if (field && field.value && (field.value.src || editingMode)) {
      let src = field.value?.src;
      if (src) {
        src = adjustImageUrl(src);

        // Apply width and height defaults if not provided
        if (!field.value.width) {
          field.value.width = 100;
        }
        if (!field.value.height) {
          field.value.height = 100;
        }
        // Update the source URL
        field.value.src = src;
      }

      return (
        <div ref={ref} className={`next-image__container ${props.params?.styles}`}>
          {showComponent || hasScrolled ? <Image className="next-image" {...props} /> : null}
        </div>
      );
    }
  }

  if (field && field.value && (field.value.src || editingMode)) {
    let src = field.value?.src;
    if (src) {
      src = adjustImageUrl(src);

      // Apply width and height defaults if not provided
      if (!field.value.width) {
        field.value.width = 100;
      }
      if (!field.value.height) {
        field.value.height = 100;
      }
      // Update the source URL
      field.value.src = src;
    }

    return (
      <div ref={ref} className={`next-image__container ${props.params?.styles}`}>
        {showComponent || hasScrolled ? <NextImage className="next-image" {...props} /> : null}
      </div>
    );
  }

  return <NoImage {...props} />;
};

export default NextImageWithNullFallback;
