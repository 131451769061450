import { RichText, Text, LinkField, RichTextField } from '@sitecore-jss/sitecore-jss-nextjs';
import { Field, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import { useI18n } from 'next-localization';
import { SearchField } from 'components/search-field/SearchField';
import dynamic from 'next/dynamic';
import config from 'temp/config';
import LazyLoadComponent from 'components/shared/lazy-load/LazyLoadComponent';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

export interface FooterFields {
  Title: Field<string>;
  Subtitle: Field<string>;
  Description?: Field<string>;
  Link?: LinkField;
  Image?: ImageField;
  SearchLink: LinkField;
  Disclaimer?: RichTextField;
}

export type CareerFooterProps = {
  params: { [key: string]: string };
  fields: FooterFields;
};

export const Default = (props: CareerFooterProps): JSX.Element => {
  const { t } = useI18n(); /*Dictionary*/
  const editingmode = isEditorActive();

  // DynamicNextImageWithNullFallback
  let DynamicNextImageWithNullFallback;
  if (editingmode) {
    // Static import when in editing mode

    DynamicNextImageWithNullFallback =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/shared/nextImg/NextImageWithNullFallback').NextImageWithNullFallback;
  } else {
    // Dynamic import when not in editing mode
    DynamicNextImageWithNullFallback = dynamic(
      () =>
        import('components/shared/nextImg/NextImageWithNullFallback').then(
          (mod) => mod.NextImageWithNullFallback
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }

  //DynamicCareerFooterLink
  let DynamicCareerFooterLink;
  if (editingmode) {
    // Static import when in editing mode
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicCareerFooterLink = require('../link/Link').Default;
  } else {
    // Dynamic import when not in editing mode
    DynamicCareerFooterLink = dynamic(() => import('../link/Link').then((mod) => mod.Default), {
      ssr: !!config.dynamicImportSsr,
    });
  }

  return (
    <LazyLoadComponent componentId="CareerFooter">
      <div className="career-footer">
        <div className="career-footer__container">
          <DynamicNextImageWithNullFallback
            loading="eager"
            field={props.fields?.Image}
            params={{
              styles: 'career-footer__Image image__square',
            }}
            fill
            sizes="(max-width: 1200px) 100vw, (min-width: 1201px) 30vw, 33vw"
          />

          <div className="career-footer__footerContent">
            <div className="career-footer__footerContent__top">
              <Text
                tag="h2"
                field={{
                  value: t(`career-job-apply-now`),
                  editable: t(`career-job-apply-now`),
                }}
              />
              <RichText tag="div" field={props?.fields?.Subtitle} />
              <SearchField fields={{ Link: props.fields.SearchLink }} />{' '}
            </div>

            <div className="career-footer__footerContent__bottom">
              <RichText tag="div" field={props?.fields?.Description} />
              <DynamicCareerFooterLink
                fields={{
                  Link: {
                    value: {
                      href: props?.fields?.Link?.value?.href,
                      text: t(`career-job-stay-in-touch`),
                    },
                  },
                }}
                params={{ styles: 'link__button-primary' }}
              />
            </div>
          </div>
        </div>
        <RichText
          tag="div"
          className="career-footer__disclaimer body2"
          field={props?.fields?.Disclaimer}
        />
      </div>
    </LazyLoadComponent>
  );
};
