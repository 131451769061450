import {
  AtomicLayoutSection,
  AtomicSearchBox,
  AtomicSearchBoxQuerySuggestions,
  AtomicSearchBoxRecentQueries,
  AtomicSearchBoxInstantResults,
  AtomicResultSectionTitle,
  AtomicResultLink,
  loadPaginationActions,
} from '@coveo/atomic-react';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useContext, useEffect, useState } from 'react';
import { AtomicPageWrapper } from '../atomic/AtomicPageWrapper';
import { NavigationContext } from 'components/navigation/NavigationContext';
import { IconName } from 'src/types/Enum';
import { Icon } from 'components/shared/icon/Icon';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import { SearchEngineContext } from '../search-context/SearchContext';
import { useSearchFacet } from 'lib/contexts/SearchFacetContext';
import { useRouter } from 'next/router';
import { useSortFacet } from 'lib/contexts/SortFacetContext';
import { useLink } from 'lib/contexts/AllLinksContext';

interface Fields {
  SearchButtonText: Field<string>;
  TextBoxText: Field<string>;
  SearchTextBoxLabel: Field<string>;
}

type SearchBoxProps = {
  params: { [key: string]: string };
  fields: Fields;
};

export const Default = (props: SearchBoxProps): JSX.Element => {
  console.log('---->[SearchBox] Component', props.fields);

  const minimumQueryLength = Number(props.params['MinimumQueryLength'] || '0');
  const numberOfQueries = Number(props.params['NumberOfQueries'] || '8');
  const redirectionUrl: string | undefined = props.params['RedirectionUrl'] || undefined;
  const suggestionTimeout = Number(props.params['SuggestionTimeout'] || '400');
  const showRecentQueries = Boolean(false);
  const showInstantResults = Boolean(false);
  const maxWithQuery = Number(props.params['MaxWithQuery'] || '5');
  const maxWithoutQuery = Number(props.params['MaxWithoutQuery'] || '5');
  const numberOfResults = Number(props.params['ResultsPerPage'] || '17');

  const id = props.params.RenderingIdentifier;

  const searchScope = props.params.SearchScope;
  const { getSearchEngine } = React.useContext(SearchEngineContext);
  const searchEngine = getSearchEngine(searchScope);
  const paginationCreator = loadPaginationActions(searchEngine);
  searchEngine.dispatch(paginationCreator.registerNumberOfResults(numberOfResults));

  const instantResultsTemplate = () => {
    return (
      <>
        <AtomicResultSectionTitle>
          <AtomicResultLink />
        </AtomicResultSectionTitle>
      </>
    );
  };
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <>
      {isClient && (
        <>
          <AtomicPageWrapper engine={searchEngine}>
            <AtomicLayoutSection id={id ? id : undefined} section="search">
              <div className={`search-box-container ${sitecoreStyles(props.params?.styles)}`}>
                <div className="search-box-container__search-bar">
                  <AtomicSearchBox
                    minimumQueryLength={minimumQueryLength}
                    numberOfQueries={numberOfQueries}
                    redirectionUrl={redirectionUrl}
                    suggestionTimeout={suggestionTimeout}
                  >
                    <AtomicSearchBoxQuerySuggestions
                      icon="https://www.avanadedotcom.localhost/search.svg"
                      maxWithQuery={maxWithQuery}
                      maxWithoutQuery={maxWithoutQuery}
                    >
                      {showRecentQueries && <AtomicSearchBoxRecentQueries />}
                      {showInstantResults && (
                        <AtomicSearchBoxInstantResults
                          template={instantResultsTemplate}
                          imageSize="none"
                        />
                      )}
                    </AtomicSearchBoxQuerySuggestions>
                  </AtomicSearchBox>
                </div>
              </div>
            </AtomicLayoutSection>
          </AtomicPageWrapper>
        </>
      )}
    </>
  );
};

export const HeaderIcon = (props: SearchBoxProps): JSX.Element => {
  console.log('---->[SearchBox] Component', props.fields);

  const minimumQueryLength = Number(props.params['MinimumQueryLength'] || '0');
  const numberOfQueries = Number(props.params['NumberOfQueries'] || '8');
  const redirectionUrl: string | undefined = props.params['RedirectionUrl'] || undefined;
  const suggestionTimeout = Number(props.params['SuggestionTimeout'] || '400');
  const showRecentQueries = Boolean(props.params['ShowRecentQueries'] || false);
  const showInstantResults = Boolean(props.params['ShowInstantResults'] || false);
  const maxWithQuery = Number(props.params['MaxWithQuery'] || '5');
  const maxWithoutQuery = Number(props.params['MaxWithoutQuery'] || '5');
  const numberOfResults = Number(props.params['ResultsPerPage'] || '17');
  const { navigationState, setNavigationState } = useContext(NavigationContext);
  const [searchExpanded, setSearchExpanded] = useState(navigationState.searchExpanded);

  const id = props.params.RenderingIdentifier;

  const [isClient, setIsClient] = useState(false);
  const searchScope = props.params.SearchScope;
  const { getSearchEngine } = React.useContext(SearchEngineContext);
  const searchEngine = getSearchEngine(searchScope);
  const paginationCreator = loadPaginationActions(searchEngine);
  searchEngine.dispatch(paginationCreator.registerNumberOfResults(numberOfResults));

  useEffect(() => {
    setSearchExpanded(navigationState.searchExpanded);
    setIsClient(true);
  }, [navigationState]);

  const toggleSearch = (toggle: boolean) => {
    setSearchExpanded(!toggle);
    setNavigationState({ ...navigationState, showLanguage: false, searchExpanded: !toggle });
  };

  const instantResultsTemplate = () => {
    return (
      <>
        <AtomicResultSectionTitle>
          <AtomicResultLink />
        </AtomicResultSectionTitle>
      </>
    );
  };

  return (
    <div className={`search-box ${sitecoreStyles(props.params?.styles)}`}>
      <button type="button" title="Search icon" onClick={() => toggleSearch(searchExpanded)}>
        <Icon
          icon={
            searchExpanded && !navigationState.showLanguage
              ? IconName.SearchActive
              : IconName.Search
          }
          iconLabel={'Search'}
        />
      </button>
      {searchExpanded && !navigationState.showLanguage && isClient && (
        <>
          <div className="search-box__expanded">
            <div className="search-box__background"></div>
            <div className="search-box__expanded-box">
              <div className="search-box__expanded-content">
                <div className="search-box__expanded__search-bar">
                  <AtomicPageWrapper engine={searchEngine}>
                    <AtomicLayoutSection id={id ? id : undefined} section="search">
                      <AtomicSearchBox
                        minimumQueryLength={minimumQueryLength}
                        numberOfQueries={numberOfQueries}
                        redirectionUrl={redirectionUrl}
                        suggestionTimeout={suggestionTimeout}
                      >
                        <AtomicSearchBoxQuerySuggestions
                          maxWithQuery={maxWithQuery}
                          maxWithoutQuery={maxWithoutQuery}
                        >
                          {showRecentQueries && <AtomicSearchBoxRecentQueries />}
                          {showInstantResults && (
                            <AtomicSearchBoxInstantResults
                              template={instantResultsTemplate}
                              //  imageSize="none"
                            />
                          )}
                        </AtomicSearchBoxQuerySuggestions>
                      </AtomicSearchBox>
                    </AtomicLayoutSection>
                  </AtomicPageWrapper>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

// Client stories and other similar pages
export const PageSearch = (props: SearchBoxProps): JSX.Element => {
  console.log('---->[SearchBox] Component', props);
  const searchScope = props.params.SearchScope;
  const minimumQueryLength = Number(props.params['MinimumQueryLength'] || '0');
  const numberOfQueries = Number(props.params['NumberOfQueries'] || '8');
  // const redirectionUrl: string | undefined = '/dev/l2-client-stories';
  const suggestionTimeout = Number(props.params['SuggestionTimeout'] || '400');
  const showRecentQueries = Boolean(false);
  const maxWithQuery = Number(props.params['MaxWithQuery'] || '5');
  const maxWithoutQuery = Number(props.params['MaxWithoutQuery'] || '5');
  let numberOfResults = Number(props.params['ResultsPerPage'] || '17');
  if (searchScope == 'Jobs') {
    numberOfResults = 12;
  }
  if (searchScope == 'Insights' || searchScope == 'ClientStories' || searchScope == 'Events') {
    numberOfResults = 16;
  }
  const { getSearchEngine } = React.useContext(SearchEngineContext);
  const searchEngine = getSearchEngine(searchScope);
  const paginationCreator = loadPaginationActions(searchEngine);
  searchEngine.dispatch(paginationCreator.registerNumberOfResults(numberOfResults));
  const { toggleSearchFacet } = useSearchFacet();
  const { facetValue } = useSortFacet();
  const { data } = useLink();
  const id = props.params.RenderingIdentifier;

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const urlName = useRouter();
  useEffect(() => {
    if (urlName.asPath.includes('saved-jobs')) {
      const mainroot = document.querySelector('atomic-search-box')?.shadowRoot;
      const shadowroot = mainroot?.querySelector('input');
      if (shadowroot) {
        shadowroot.value = '';
      }
    }
  }, [urlName.asPath]);

  function checkFacetClick() {
    toggleSearchFacet(true);
  }

  useEffect(() => {
    const updateSearchBoxFromHash = () => {
      const hash = window.location.hash;
      const value = new URLSearchParams(hash.slice(1)).get('q');

      if (value) {
        const searchInput = document
          .querySelector('atomic-search-box')
          ?.shadowRoot?.querySelector('input');
        if (searchInput && searchInput instanceof HTMLInputElement) {
          console.log('search query', value);
          searchInput.value = value;
          const event = new Event('input', {
            bubbles: true,
          });
          searchInput.dispatchEvent(event);
        }
      }
    };
    updateSearchBoxFromHash();
  }, []);

  useEffect(() => {
    const updateSearchResults = () => {
      if (window.location.hash) {
        if (searchScope == 'Events') {
          searchEngine.dispatch(paginationCreator.registerNumberOfResults(17));
        } else if (searchScope == 'Jobs') {
          searchEngine.dispatch(paginationCreator.registerNumberOfResults(12));
        } else {
          searchEngine.dispatch(paginationCreator.registerNumberOfResults(16));
        }
      } else {
        searchEngine.dispatch(paginationCreator.registerNumberOfResults(17));
      }
    };

    window.addEventListener('hashchange', updateSearchResults);
    updateSearchResults(); // Initial check on page load

    return () => {
      window.removeEventListener('hashchange', updateSearchResults);
    };
  }, []);

  return (
    <>
      {isClient && (
        <>
          <AtomicPageWrapper engine={searchEngine}>
            <div
              id={id ? id : undefined}
              className={`client-search__search-input ${sitecoreStyles(props.params.styles)}`}
            >
              <AtomicSearchBox
                minimumQueryLength={minimumQueryLength}
                numberOfQueries={numberOfQueries}
                suggestionTimeout={suggestionTimeout}
                results={5}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    checkFacetClick();
                    if (urlName.asPath.includes('search-jobs')) {
                      if (facetValue === 'recent') {
                        urlName.push(data.searchJobs.value.href + window.location.hash);
                      }
                    }
                  }
                }}
                onClick={(e) => {
                  console.log(
                    'search button shadow element name',
                    e.currentTarget.shadowRoot?.activeElement?.tagName
                  );
                  if (e.currentTarget.shadowRoot?.activeElement?.tagName == 'BUTTON') {
                    checkFacetClick();
                  }
                  if (searchScope == 'Jobs') {
                    searchEngine.dispatch(paginationCreator.registerNumberOfResults(12));
                  } else {
                    searchEngine.dispatch(paginationCreator.registerNumberOfResults(16));
                  }
                  if (urlName.asPath.includes('search-jobs')) {
                    if (facetValue === 'recent') {
                      urlName.push(data.searchJobs.value.href + window.location.hash);
                    }
                  }
                  setTimeout(() => {
                    if (
                      document
                        .querySelector('atomic-search-box')
                        ?.shadowRoot?.querySelector('input')?.value == ''
                    ) {
                      window.location.hash.includes('sortCriteria') ||
                      window.location.hash.includes('taxonomy')
                        ? (window.location.hash = window.location.hash.replace(/#q=([^&]+)/, ''))
                        : (window.location.hash = window.location.hash.replace(/#q=.*/, ''));
                    }
                  }, 100);
                }}
              >
                <AtomicSearchBoxQuerySuggestions
                  icon="https://www.avanadedotcom.localhost/search.svg"
                  maxWithQuery={maxWithQuery}
                  maxWithoutQuery={maxWithoutQuery}
                >
                  {showRecentQueries && <AtomicSearchBoxRecentQueries />}
                </AtomicSearchBoxQuerySuggestions>
              </AtomicSearchBox>{' '}
            </div>
          </AtomicPageWrapper>
        </>
      )}
    </>
  );
};
