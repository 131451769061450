import { CardProps } from 'components/card/Card';
import { useI18n } from 'next-localization';
import React from 'react';
import { VideoModal } from 'components/shared/video-modal/VideoModal';
import { removeColFromStylesProps } from 'src/helpers/sitecoreStyles';
import { InsightCard } from 'components/card/variants/insight/Insight';

export const CardVideo = (props: CardProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;

  const { t } = useI18n();
  const datasource = props.fields?.data?.datasource;

  if (datasource?.link && datasource?.link.jsonValue) {
    datasource.link.jsonValue.value.text = t('watch-video');
  }

  if (!datasource) {
    return <></>;
  }

  return (
    <div
      className={`card-video ${removeColFromStylesProps(props.params?.styles)} `}
      id={id ? id : undefined}
    >
      <InsightCard {...props}>
        <VideoModal
          buttonText={t('watch-video')}
          video={datasource?.video?.targetItem}
        ></VideoModal>
      </InsightCard>
    </div>
  );
};
