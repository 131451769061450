import {
  TextField,
  Text,
  LinkField,
  Link,
  RichText,
  RichTextField,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { useState } from 'react';

export interface EvidenceCard {
  quote?: RichTextField;
  author?: TextField;
  authorLink: LinkField;
}

export const EvidenceCard = (card: EvidenceCard): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  return (
    <div className="evidence-card">
      <div className={'evidence-card__quote'}>
        <div className={'evidence-card__signs'}>“</div>
        <div className={'evidence-card__text-container'}>
          {(card.quote?.value || editingMode) && (
            <RichText className={'evidence-card__text h4'} tag="div" field={card.quote} />
          )}
          <div className={'evidence-card__author'}>
            <div className={'evidence-card__line'}> </div>
            {editingMode ? (
              <>
                <Text
                  tag="span"
                  field={card.author}
                  className={`ga-track evidence-card__author--link`}
                />{' '}
                or
                <Link field={card.authorLink} className={`ga-track evidence-card__author--link`} />
              </>
            ) : (
              <>
                {card.authorLink.value.href ? (
                  <>
                    <Link
                      className={`ga-track evidence-card__author--link`}
                      field={card.authorLink}
                      aria-label={`${card.authorLink.value.text}`}
                      tabIndex={card.authorLink.value.href ? 0 : 1}
                      data-analytics-link-name={`${card.authorLink.value?.title}`}
                      data-analytics-link-type="Link"
                      data-analytics-content-class="Content"
                      data-analytics-template-zone="Body"
                      data-analytics-component-name="Evidence Card Module"
                      data-analytics-component-variation="NAN"
                      data-analytics-target={`${card.authorLink.value?.href}`}
                      data-analytics-component-section="NAN"
                      data-analytics-slide-number="NAN"
                      data-analytics-ispersonalized="False"
                      data-analytics-iscarousal="False"
                    ></Link>
                  </>
                ) : (
                  <Text
                    tag="span"
                    field={card.author}
                    className={`ga-track evidence-card__author--no-link`}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
