import React, { useState } from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { Default as PrimaryButton } from 'components/link/Link';
import { TimageFields } from 'components/timage/Timage';
import { NextImageWithNullFallback } from 'components/shared/nextImg/NextImageWithNullFallback';
import sitecoreStyles from 'src/helpers/sitecoreStyles';

type TimageProps = {
  params: { [key: string]: string; styles: string };
  fields: TimageFields;
};
export const TextImageRightWithQuoteTimage = (props: TimageProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const [editingMode] = useState(isEditorActive());
  // const tag = props.params['TitleTag'] || 'h4';

  return (
    <div
      className={`timage-right-with-quote component--with-paddings ${sitecoreStyles(
        props.params?.styles
      )}`}
      id={id ? id : undefined}
    >
      {editingMode && (
        <div className="row">
          <div className="col-12">
            <div className="s-editing-mode-hint">
              <strong>HINT:</strong> Requirement for the Title in this component is to
              <strong> NOT USE H1 AND H2 TAGS</strong> due to translations issue.
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-12 col-md-6 timage-right-with-quote__text-column">
          <span className="timage-right-with-quote__sign">
            <i className="icon icon--72 icon--quote"></i>
          </span>
          <Text
            tag={props.params['TitleTag'] || 'h4'}
            field={props.fields.Title}
            className="timage-right-with-quote__header"
          />
          <div className="timage-right-with-quote__author-container">
            <span className="timage-right-with-quote__line"></span>
            <RichText
              tag="div"
              className="timage-right-with-quote__author h6"
              field={props.fields.Text}
            />
          </div>
          {props.fields.Link && (
            <div className="timage-right-with-quote__button">
              <PrimaryButton
                fields={{ Link: props.fields.Link }}
                params={{ styles: 'link__button-primary' }}
              />
            </div>
          )}
        </div>
        <div className="col-12 col-md-6 timage-right-with-quote__image-column">
          <NextImageWithNullFallback
            field={props.fields.Image}
            width={552}
            height={552}
            params={{
              styles: 'timage-right-with-quote__image-container next-image--square',
            }}
          ></NextImageWithNullFallback>
        </div>
      </div>
    </div>
  );
};
