import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { CardProps } from 'components/card/Card';
import React, { useState } from 'react';
import { Tertiary } from 'components/link/Link';
import dynamic from 'next/dynamic';
import { removeColFromStylesProps } from 'src/helpers/sitecoreStyles';
import config from 'temp/config';

export const ContentHorizontalCard = (props: CardProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const [editingMode] = useState(isEditorActive());
  const datasource = props.fields?.data?.datasource;

  const handleClick = () => {
    if (datasource?.link?.jsonValue) window.open(datasource?.link.jsonValue?.value?.href, '_self');
  };

  if (!datasource) {
    return <></>;
  }

  const editingmode = isEditorActive();

  let DynamicNextImageWithNullFallback;

  // Static import when in editing mode
  if (editingmode) {
    DynamicNextImageWithNullFallback =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/shared/nextImg/NextImageWithNullFallback').NextImageWithNullFallback;
  } else {
    // Dynamic import when not in editing mode
    DynamicNextImageWithNullFallback = dynamic(
      () =>
        import('components/shared/nextImg/NextImageWithNullFallback').then(
          (mod) => mod.NextImageWithNullFallback
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }

  return (
    <div
      className={`horizontal-card content-card__horizontal ${removeColFromStylesProps(
        props.params.styles
      )}`}
      id={id ? id : undefined}
      onClick={!editingMode ? handleClick : () => null}
    >
      <div className="horizontal-card__content">
        <DynamicNextImageWithNullFallback
          field={datasource?.image?.jsonValue}
          width={264}
          height={264}
          params={{
            styles: 'horizontal-card__image next-image--quare',
          }}
        />

        <div className="horizontal-card__card-info">
          <div className="horizontal-card__content__text">
            <Text
              tag="h3"
              className="body1 horizontal-card__title"
              field={datasource?.title?.jsonValue}
            />
            <RichText tag="div" className="body2" field={datasource?.body?.jsonValue} />
          </div>
          <div className="horizontal-card__link">
            <Tertiary fields={{ Link: datasource?.link?.jsonValue }} params={{}}></Tertiary>
          </div>
        </div>
      </div>
    </div>
  );
};
