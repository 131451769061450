import { WithSitecoreContextProps, withSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import Head from 'next/head';
import React from 'react';

import { useState } from 'react';

// single canonical url  according to context language

//type CanonicalUrl = { [key: string]: string };

export interface CanonicalUrlComponentFields {
  canonicalUrl: { editable: boolean; value: string };
}

type CanonicalUrlProps = {
  params: { [key: string]: string };
  fields?: CanonicalUrlComponentFields;
} & WithSitecoreContextProps;

const Default = (props: CanonicalUrlProps): JSX.Element => {
  console.log('---->[CanonicalUrl] Component', props.fields);
  const [editingMode] = useState(isEditorActive());
  const origin =
    typeof window !== 'undefined' && window.location.origin ? window.location.origin : '';
  console.log(origin);
  return (
    <>
      {editingMode && props.sitecoreContext.route?.templateName == 'Partial Design' ? (
        <div className="is-metadata-hint">
          <strong>Metadata:</strong> Canonical Url
        </div>
      ) : (
        <>
          {props.fields?.canonicalUrl?.value && (
            <>
              <Head>
                {
                  <React.Fragment>
                    <link rel="canonical" href={origin + props.fields.canonicalUrl.value} />
                    {/*https://developers.google.com/search/blog/2011/12/new-markup-for-multilingual-content*/}
                    {/*<link rel="alternate" hrefLang={key} href={origin + value} />*/}
                  </React.Fragment>
                }
              </Head>
            </>
          )}
        </>
      )}
    </>
  );
};

export default withSitecoreContext()(Default);
