import {
  ComponentRendering,
  ComponentParams,
  Placeholder,
} from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';

interface ComponentProps {
  rendering: ComponentRendering & { params: ComponentParams };
  params: ComponentParams;
}

export const Default = (props: ComponentProps): JSX.Element => {
  const phKey = `placeholder-${props.params.DynamicPlaceholderId}`;
  return <Placeholder name={phKey} rendering={props.rendering} />;
};
