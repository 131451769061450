import { WithSitecoreContextProps, withSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import Head from 'next/head';
import React from 'react';
import { useState } from 'react';
import { JssFieldValue } from 'src/types/JssFieldValue';

export interface FaviconComponentFields {
  image?: JssFieldValue<string>;
  applicationName?: JssFieldValue<string>;
  tileColor?: JssFieldValue<string>;
}

type FaviconProps = {
  params: { [key: string]: string };
  fields?: FaviconComponentFields;
} & WithSitecoreContextProps;

const Default = (props: FaviconProps): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  console.log('---->[Favicon] Component', props.fields);
  return (
    <>
      <Head>
        {props.fields?.image?.value && (
          <>
            <link rel="apple-touch-icon-precomposed" href={`${props.fields.image.value}`} />
            <link rel="icon" type="image/png" href={`${props.fields.image.value}`} />
            <meta
              name="msapplication-TileImage"
              content={`${props.fields.image.value}?mw=144&mh=144`}
            />
            <meta
              name="msapplication-square70x70logo"
              content={`${props.fields.image.value}?mw=70&mh=70`}
            />
            <meta
              name="msapplication-square150x150logo"
              content={`${props.fields.image.value}?mw=150&mh=150`}
            />
            <meta
              name="msapplication-square310x310logo"
              content={`${props.fields.image.value}?mw=310&mh=310`}
            />
          </>
        )}
        {props.fields?.applicationName?.value && (
          <meta name="application-name" content={props.fields.applicationName.value} />
        )}
        {props.fields?.tileColor?.value && (
          <meta name="msapplication-TileColor" content={props.fields.tileColor.value} />
        )}
      </Head>
      {editingMode && props.sitecoreContext.route?.templateName == 'Partial Design' && (
        <div className="is-metadata-hint">
          <strong>Metadata:</strong> Favicon Meta Tags
        </div>
      )}
    </>
  );
};

export default withSitecoreContext()(Default);
