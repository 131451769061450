import { AtomicLayoutSection, AtomicLoadMoreResults } from '@coveo/atomic-react';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useEffect, useState } from 'react';
import { AtomicPageWrapper } from '../atomic/AtomicPageWrapper';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import { SearchEngineContext } from 'components/search/search-context/SearchContext';

interface Fields {
  LoadMoreLabel: Field<string>;
}

type LoadMoreProps = {
  params: { [key: string]: string };
  fields: Fields;
};

export const Default = (props: LoadMoreProps): JSX.Element => {
  console.log('---->[LoadMore] Component', props.fields);
  const id = props.params.RenderingIdentifier;
  const [isClient, setIsClient] = useState(false);

  const searchScope = props.params.SearchScope;
  const { getSearchEngine } = React.useContext(SearchEngineContext);
  const searchEngine = getSearchEngine(searchScope);

  const [editingMode] = useState(isEditorActive());
  //need to pass the engine instance name - for now basing on URL
  useEffect(() => {
    setIsClient(true);
  }, []);
  return (
    <React.Fragment>
      {editingMode ? (
        <div className={`is-metadata-hint ${sitecoreStyles(props.params?.styles)}`}>
          <strong>Search:</strong> load more
        </div>
      ) : (
        <React.Fragment>
          {isClient && (
            <AtomicPageWrapper engine={searchEngine}>
              <AtomicLayoutSection
                id={id ? id : undefined}
                section="pagination"
                data-analytics-link-name="Load More"
                data-analytics-link-type="Navigation"
                data-analytics-content-class="Load More"
                data-analytics-template-zone="Body"
                data-analytics-component-name="Load More"
                data-analytics-component-variation="NAN"
                data-analytics-target="NAN"
                data-analytics-component-section="Body"
                data-analytics-slide-number="NAN"
                data-analytics-ispersonalized="False"
                data-analytics-iscarousal="False"
              >
                <AtomicLoadMoreResults />
              </AtomicLayoutSection>
            </AtomicPageWrapper>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Default;
