import React, { createContext, useState } from 'react';

type Props = {
  children?: React.ReactNode;
};

type NavigationState = {
  showLanguage: boolean;
  searchExpanded: boolean;
};

type NavigationContext = {
  navigationState: NavigationState;
  setNavigationState: (navigationState: NavigationState) => void;
};

const NavigationContext = createContext<NavigationContext>({
  navigationState: {
    showLanguage: false,
    searchExpanded: false,
  },
  setNavigationState: function (): void {
    throw new Error('Function not implemented.');
  },
});

function NavigationContextProvider({ children }: Props): JSX.Element {
  const [navigationState, setNavigationState] = useState<NavigationState>({
    showLanguage: false,
    searchExpanded: false,
  });

  return (
    <NavigationContext.Provider value={{ navigationState, setNavigationState }}>
      {children}
    </NavigationContext.Provider>
  );
}

export { NavigationContextProvider, NavigationContext };
