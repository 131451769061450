export interface SxaEnum {
  value: {
    value: string;
  };
}

export enum IconName {
  Globe = 'globe',
  GlobeActive = 'globe-active',
  Search = 'search',
  SearchActive = 'search-active',
  Hamburger = 'hamburger',
  ChevronRight = 'chevron-right',
  ChevronLeft = 'chevron-left',
  ChevronDown = 'chevron-down',
  ChevronUp = 'chevron-up',
  Checkmark = 'checkmark',
  DoubleChevronDown = 'double-chevron-down',
  ChevronDownWhite = 'chevron-down-white',
  Add = 'add',
  Substract = 'subtract',
  ArrowRight = 'arrow-right',
  ArrowRightActive = 'arrow-right-active',
  ArrowDown = 'arrow-down',
  ArrowDownActive = 'arrow-down-active',
  ArrowUp = 'arrow-up',
  ArrowUpActive = 'arrow-up-active',
  ArrowCircleRight = 'arrow-circle-right',
  Phone = 'phone',
  Fax = 'fax',
  FullScreen = 'full-screen',
  Sparkle = 'sparkle',
  Dismiss = 'dismiss',
  DismissCircle = 'dismiss-circle',
  Link = 'link',
  Facebook = 'facebook',
  Youtube = 'youtube',
  Pinterest = 'pinterest',
  Linkedin = 'linkedin',
  Instagram = 'instagram',
  Email = 'email',
  MinimizeScreen = 'minimize-screen',
  Mute = 'mute',
  Pause = 'pause',
  PictureInPictureExit = 'picture-in-picture-exit',
  PictureInPicture = 'picture-in-picture',
  Play = 'play',
  PlayBrightcove = 'play-brightcove',
  PlayButton = 'play-button',
  Quote = 'quote',
  Share = 'share',
  Subtract = 'subtract',
  Volume1 = 'volume-1',
  Volume2 = 'volume-2',
  Volume3 = 'volume-3',
  Calendar = 'calendar',
  LocationIcon = 'Location',
  mapIcon = 'mapicon',
  chevronCircle = 'chevron-circle',
}

export enum IconSize {
  Size16 = '16',
  Size24 = '24',
  Size28 = '28',
  Size32 = '32',
  Size48 = '48',
}
