import React, { useContext, useEffect, useState } from 'react';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { NavigationContext } from 'components/navigation/NavigationContext';
import { IconName } from 'src/types/Enum';
import { useI18n } from 'next-localization';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import dynamic from 'next/dynamic';
import config from 'temp/config';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
interface SiteSelectorModel {
  id: string;
  title: {
    jsonValue: Field<string>;
  };
  countryCode: {
    jsonValue: Field<string>;
  };
  siteUrl: {
    jsonValue: Field<string>;
  };
}
interface Fields {
  data: {
    datasource: {
      children: {
        results: SiteSelectorModel[];
      };
    };
  };
}

type SiteSelectorProps = {
  params: { [key: string]: string };
  fields?: Fields;
};

export const Default = (props: SiteSelectorProps): JSX.Element => {
  console.log('---->[Site Selector] Component', props.fields);
  const { locale } = useI18n();

  const id = props.params.RenderingIdentifier;
  const datasource = props.fields?.data.datasource?.children.results;
  const defaultId = '8AC86A155F2949B1825AF6ACC697415A';
  const { navigationState, setNavigationState } = useContext(NavigationContext);
  const [siteSelectorExpanded, setSiteSelectorExpanded] = useState(navigationState.showLanguage);
  const [currentLanguage, setCurrentLang] = useState(locale());
  const toggleSiteSelector = (toggle: boolean) => {
    console.log(currentLanguage, 'MSI');
    setSiteSelectorExpanded(!toggle);
    setNavigationState({ ...navigationState, showLanguage: !toggle, searchExpanded: false });
  };

  useEffect(() => {
    document.cookie = `xmcountry=${locale().toLowerCase()}; max-age=31556926; path=/`; //1 year in seconds
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSiteSelectorExpanded(navigationState.showLanguage);
  }, [navigationState]);

  const setCountryCode = (cc: string | undefined) => {
    if (cc) {
      setCurrentLang(cc);
      document.cookie = `xmcountry=${cc}`;
    }
  };

  const getCountryFromCookie = () => {
    const cookieValue = document.cookie
      .split('; ')
      .find((row) => row.startsWith('xmcountry='))
      ?.split('=')[1];
    return locale() || cookieValue || defaultId;
  };

  const getCountry = () => {
    return datasource?.find((element) => element.countryCode.jsonValue.value == currentLanguage);
  };

  const setCurrentLangSelection = () => {
    const currentCountryCode = getCountryFromCookie().toLowerCase();

    const selectedLang = datasource?.find(
      (x) => x.countryCode.jsonValue.value.toLowerCase() === currentCountryCode
    );

    const notSelectedLang =
      datasource?.filter(
        (x) => x.countryCode.jsonValue.value.toLowerCase() !== currentCountryCode
      ) || [];

    return selectedLang ? [selectedLang, ...notSelectedLang] : notSelectedLang;
  };

  const getFormattedUrl = (url: string) => {
    const newUrl = new URL(url);
    const urlHash = newUrl.hash;
    let returnUrl = '';
    if (urlHash != '' && urlHash != decodeURIComponent(urlHash)) {
      newUrl.hash = '';
      returnUrl = encodeURI(newUrl.toString()).concat(urlHash);
    } else {
      returnUrl = encodeURI(url);
    }
    return returnUrl;
  };

  let DynamicIcon;
  const editingmode = isEditorActive();
  if (editingmode) {
    // Static import when in editing mode
    DynamicIcon =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/shared/icon/Icon').Icon;
  } else {
    // Dynamic import when not in editing mode
    DynamicIcon = dynamic(() => import('components/shared/icon/Icon').then((mod) => mod.Icon), {
      ssr: !!config.dynamicImportSsr,
    });
  }

  return (
    <div className={`site-selector ${sitecoreStyles(props.params?.styles)}`} id={id}>
      <button
        type="button"
        className="site-selector__button"
        title="Site Selector icon"
        onClick={() => toggleSiteSelector(siteSelectorExpanded)}
      >
        <div className="site-selector__button-container">
          <div className="site-selector__icon site-selector__icon--mobile">
            <DynamicIcon icon={IconName.Globe} iconLabel={'Open / close site selector'} />
          </div>

          <div className="site-selector__icon site-selector__icon--desktop">
            <DynamicIcon
              icon={
                siteSelectorExpanded && !navigationState.searchExpanded
                  ? IconName.GlobeActive
                  : IconName.Globe
              }
              iconLabel={'Open / close site selector'}
            />
          </div>

          <h6 className="site-selector__current">{getCountry()?.title.jsonValue.value}</h6>
        </div>
        {!siteSelectorExpanded && (
          <div className="site-selector__button-icon">
            <DynamicIcon icon={IconName.ChevronRight} iconLabel={'Open'} />
          </div>
        )}
      </button>

      {siteSelectorExpanded && !navigationState.searchExpanded && (
        <div className="site-selector__list-container">
          <div className="site-selector__container">
            <ul className="site-selector__list">
              {setCurrentLangSelection().map((x) => {
                const windowUrl = window.location.href;
                const currenturl = getFormattedUrl(windowUrl);
                return (
                  <li
                    onClick={() => {
                      setCountryCode(x.countryCode.jsonValue.value);
                    }}
                    key={x.id}
                    className="site-selector__list-item body2"
                  >
                    <a
                      href={currenturl.replace(
                        getCountryFromCookie().toLowerCase(),
                        x.countryCode.jsonValue.value.toLowerCase()
                      )}
                      className={`ga-track site-selector__link ${
                        x.countryCode.jsonValue.value.toLowerCase() ===
                        getCountryFromCookie().toLowerCase()
                          ? 'site-selector__link--active'
                          : ''
                      }`}
                      data-analytics-link-name={`${x.title?.jsonValue.value}`}
                      data-analytics-link-type="Link"
                      data-analytics-content-class="Content"
                      data-analytics-template-zone="Body"
                      data-analytics-component-name="Site Selector"
                      data-analytics-component-variation="NAN"
                      data-analytics-target={currenturl.replace(
                        getCountryFromCookie().toLowerCase(),
                        x.countryCode.jsonValue.value.toLowerCase()
                      )}
                      data-analytics-component-section="NAN"
                      data-analytics-slide-number="NAN"
                      data-analytics-ispersonalized="False"
                      data-analytics-iscarousal="False"
                    >
                      {x.title?.jsonValue.value}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          <span
            onClick={() => toggleSiteSelector(siteSelectorExpanded)}
            className="site-selector__background"
          ></span>
        </div>
      )}
    </div>
  );
};

export default Default;
