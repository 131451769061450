import { azureBlobCache } from 'lib/cache/providers/blobCacheProvider';
//import { redisCache } from 'lib/cache/providers/redisCacheProvider';

export interface ICacheProvider {
  get<T>(key: string): Promise<T | null>;
  set<T>(key: string, value: T, ttl?: number): Promise<void>;
  delete(key: string): Promise<number | null>;
  exists(key: string): Promise<boolean>;
  disconnect(): void;
}

export type CacheProviderType = 'azureBlob' | 'redis';

export class CacheProviderFactory {
  private static instance: ICacheProvider;

  public static getCacheProvider(name?: CacheProviderType): ICacheProvider {
    if (!CacheProviderFactory.instance) {
      const cacheType = name || process.env.CACHE_PROVIDER || 'azureBlob';
      console.log('CacheProviderFactory.Type', cacheType);
      switch (cacheType.toLowerCase()) {
        case 'redis':
          //        CacheProviderFactory.instance = redisCache;
          break;
        case 'azureBlob':
        default:
          CacheProviderFactory.instance = azureBlobCache;
          break;
      }
    }

    return CacheProviderFactory.instance;
  }
}

export const cacheProvider = CacheProviderFactory.getCacheProvider();
