import { useState, useEffect } from 'react';
import { Icon } from 'components/shared/icon/Icon';
import { IconName, IconSize } from 'src/types/Enum';
import React from 'react';
import {
  BreadcrumbValue,
  FacetValue,
  SearchEngine,
  buildBreadcrumbManager,
} from '@coveo/atomic-react';

export const Default = ({ engine }: { engine: SearchEngine }): JSX.Element => {
  const [headlessBreadcrumbs] = useState(buildBreadcrumbManager(engine));
  const [breadcrumbsState, setBreadcrumbs] = useState(headlessBreadcrumbs.state);

  useEffect(() => {
    headlessBreadcrumbs.subscribe(() => {
      setBreadcrumbs(headlessBreadcrumbs.state);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCrumbs = () => {
    let allCrumbs: Array<BreadcrumbValue<FacetValue>> = [];
    breadcrumbsState.facetBreadcrumbs.forEach((facetCrumbs) =>
      facetCrumbs.values.forEach((crumb) => (allCrumbs = [...allCrumbs, crumb]))
    );

    return allCrumbs;
  };

  const deselectAllCrumbs = () => {
    headlessBreadcrumbs.deselectAll();
  };

  return (
    <div className="custom-breadcrumbs">
      {getCrumbs().map((crump, index) => (
        <button className="custom-breadcrumbs__pill" onClick={() => crump.deselect()} key={index}>
          <span className="custom-breadcrumbs__pill-text">{crump.value.value}</span>
          <Icon icon={IconName.Dismiss} iconSize={IconSize.Size16} iconLabel={'Close'} />
        </button>
      ))}
      {breadcrumbsState.facetBreadcrumbs.length > 0 && (
        <button className="body2-link" onClick={() => deselectAllCrumbs()}>
          Clear all filters
        </button>
      )}
    </div>
  );
};
