import { CardProps } from 'components/card/Card';
import { useI18n } from 'next-localization';
import React, { useState, useRef } from 'react';
import { VideoModal } from 'components/shared/video-modal/VideoModal';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { removeColFromStylesProps } from 'src/helpers/sitecoreStyles';
import { TagWithIconLeft } from 'components/tag/Tag';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

export const CardVideo = (props: CardProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const [editingMode] = useState(isEditorActive());
  const videoModalRef = useRef<{ setIsModalOpen: (open: boolean) => void }>(null);
  const { t } = useI18n();
  const datasource = props.fields?.data?.datasource;

  if (datasource?.link && datasource?.link.jsonValue) {
    datasource.link.jsonValue.value.text = t('watch-video');
  }

  if (!datasource) {
    return <></>;
  }

  const handleCardClick = () => {
    if (videoModalRef.current) {
      videoModalRef.current.setIsModalOpen(true);
    }
  };

  return (
    <div
      className={`card insight-card insight-card__default-container ${removeColFromStylesProps(
        props.params?.styles
      )} `}
      id={id ? id : undefined}
      onClick={!editingMode ? handleCardClick : () => null}
    >
      <div className="insight-card__text-container">
        {((datasource?.subTitle?.jsonValue?.value && datasource?.iconCard?.jsonValue?.value?.src) ||
          editingMode) && (
          <div className="body1 insight-card__tag">
            <TagWithIconLeft
              fields={{
                Title: datasource?.subTitle?.jsonValue || { value: '' },
                Icon: datasource?.iconCard?.jsonValue,
              }}
              params={{}}
            ></TagWithIconLeft>
          </div>
        )}

        <Text
          tag="div"
          className="body1 insight-card__title"
          field={datasource?.title?.jsonValue}
        />
        <RichText
          tag="div"
          className="body2 insight-card__body"
          field={datasource?.body?.jsonValue}
        />
      </div>
      <div>
        <VideoModal
          ref={videoModalRef}
          buttonText={t('watch-video')}
          video={datasource?.video?.targetItem}
        />
      </div>
    </div>
  );
};
