import React from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { HeroProps } from 'components/hero/Hero';
import { NextImageWithNullFallback } from 'components/shared/nextImg/NextImageWithNullFallback';
import { Tertiary } from 'components/link/Link';

export const DefaultJobHero = (props: HeroProps): JSX.Element => (
  <div className={`component promo ${props.params.styles}`}>
    <div className="component-content">
      <span className="is-empty-hint">Hero</span>
    </div>
  </div>
);

export const JobHero = (props: HeroProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  if (props.fields) {
    return (
      <div
        className={`full-bleed-hero ${props.params.styles} full-bleed-hero-ava-styling`}
        id={id ? id : undefined}
      >
        <div className="full-bleed-hero__container">
          <div className="full-bleed-hero__content">
            <div className="full-bleed-hero__box">
              <div className="full-bleed-hero__title-box">
                <div className="h1 full-bleed-hero__title">
                  <Text field={props.fields?.data.datasource?.title?.jsonValue} />
                </div>
                <RichText
                  tag="div"
                  className="body2 full-bleed-hero__text"
                  field={props.fields?.data.datasource?.body?.jsonValue}
                />
                <Tertiary
                  fields={{ Link: props.fields?.data.datasource?.link?.jsonValue }}
                  params={{ styles: 'full-bleed-hero__link' }}
                ></Tertiary>
              </div>
            </div>
          </div>
        </div>
        <div className="picture__container full-bleed-hero__img-container">
          <NextImageWithNullFallback
            loading="eager"
            field={props.fields?.data.datasource?.image?.jsonValue}
            imageParams={{ mw: 1728 }}
            width={100}
            height={100}
            className="picture"
            priority
          ></NextImageWithNullFallback>
        </div>
      </div>
    );
  }
  return <DefaultJobHero {...props} />;
};
