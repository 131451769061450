import React, { useState } from 'react';
import Script from 'next/script';
import {
  Field,
  WithSitecoreContextProps,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

declare global {
  interface Window {
    CookieRemoval: () => void;
  }
}
interface Fields {
  OneTrustScript: Field;
}

type OneTrustProps = {
  params: { [key: string]: string };
  fields?: Fields;
} & WithSitecoreContextProps;

const Default = (props: OneTrustProps): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  function LoadOneTrust() {
    if (typeof window !== undefined) {
      const cookieRemoval = window.CookieRemoval;
      cookieRemoval();
    }
  }
  return (
    <>
      {editingMode && props.sitecoreContext.route?.templateName == 'Partial Design' ? (
        <div className="is-metadata-hint">
          <strong>Metadata:</strong> Onetrust
        </div>
      ) : (
        <>
          {!editingMode && (
            <>
              <Script
                id="OneTrust"
                src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
                data-document-language="true"
                type="text/javascript"
                strategy="afterInteractive"
                data-domain-script={`${props.fields?.OneTrustScript.value}`}
                onLoad={() => LoadOneTrust()}
                onReady={() => LoadOneTrust()}
              />
              <Script id="OneTrustScript" strategy="afterInteractive">
                {`
                    function OptanonWrapper() { }
                    function CookieRemoval() {
                            if (typeof Document !== "undefined"){              //(typeof jQuery !== "undefined") {
                                //cookie handling code  
                                const performanceCode = ",C0002,";
                                const functionalityCode = ",C0003,";
                                const targetCode = ",C0004,"

                                setInterval(function () {
                                    if (typeof OptanonActiveGroups !== "undefined") {
                                        //console.log("optanon");
                                        //Check for Performance cookies
                                        if (OptanonActiveGroups.indexOf(performanceCode) < 0) {
                                            if (document.querySelector("#brightcoveOptOut") != null){        //(jQuery("#brightcoveOptOut").length > 0) {
                                                var optouthtml = document.querySelector("#brightcoveOptOut").innerHTML;     //jQuery("#brightcoveOptOut").html();
                                                if (optouthtml.indexOf('img') < 0) {
                                                  document.querySelector("#brightcoveOptOut").innerHTML = "<img src='https://map.brightcove.com/lead/clear' alt='cookie consent' style= 'display: block; width: 0px' />";    //jQuery("#brightcoveOptOut").html("<img src='https://map.brightcove.com/lead/clear' style= 'display: block; width: 0px' />");
                                                }
                                            }
                                        }
                                    }
                                }, 2000);
                          
                            }
                    }
                `}
              </Script>

              <div id="brightcoveOptOut"></div>

              {/* <Script
                id="jQueryRefrence"
                src="https://code.jquery.com/jquery-3.7.0.min.js"
                integrity="sha512-3gJwYpMe3QewGELv8k/BX9vcqhryRdzRMxVfq6ngyWXwo03GFEzjsUm8Q7RZcHPHksttq7/GFoxjCVUjkjvPdw=="
                crossOrigin="anonymous"
                async
                onLoad={() => LoadOneTrust()}
              /> */}
            </>
          )}
        </>
      )}
    </>
  );
};

export default withSitecoreContext()(Default);
