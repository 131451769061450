import { LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { Icon } from 'components/shared/icon/Icon';
import { useI18n } from 'next-localization';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { IconName, IconSize } from 'src/types/Enum';
import { useScroll } from 'lib/contexts/ScrollContext';

export interface SearchFields {
  Link: LinkField;
}

export type SearchProps = {
  // params: { [key: string]: string };
  fields: SearchFields;
};

export const SearchField = (props: SearchProps): JSX.Element => {
  const [searchKeyword, setSearchKeyword] = useState('');
  const searchParams = useSearchParams();
  const router = useRouter();
  const sectionRef = useScroll();

  const handleScroll = () => {
    sectionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const params = new URLSearchParams(searchParams);
    if (searchKeyword != '') {
      params.set('q', encodeURI(searchKeyword));
    } else {
      params.delete('q');
    }
    const searchUrl = props?.fields?.Link?.value?.href;
    let isSamepage = false;
    if (searchUrl == window.location.pathname) {
      isSamepage = true;
    }
    if (searchUrl) {
      router
        .push(`${searchUrl}#${params.toString()}&sortCriteria=%40job_area_of_expertise%20ascending`)
        .then(() => {
          // window.scrollTo(0, 0);
          handleScroll();
          if (isSamepage || searchUrl.includes('search-jobs')) router.reload();
        });
    }
  };

  const { t } = useI18n(); /*Dictionary*/
  return (
    <form className="search-field__container" onSubmit={handleSubmit}>
      <button className="search-field__icon" onClick={handleSubmit}>
        <Icon icon={IconName.Search} iconLabel={'Search'} iconSize={IconSize.Size24} />
      </button>
      <input
        type="search"
        className="search-field__box"
        id="searchID"
        placeholder={t(`career-job-dream-job`)}
        onChange={(e) => setSearchKeyword(e.target.value)}
      />
    </form>
  );
};
