import React, { useState } from 'react';
import { LinkField, Text, Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { EventFields, EventProps } from 'components/event/Event';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import dynamic from 'next/dynamic';
import config from 'temp/config';
import LazyLoadComponent from 'components/shared/lazy-load/LazyLoadComponent';

export interface EventsListFields {
  id: string;
  title: {
    jsonValue: Field<string>;
  };
  link: {
    jsonValue: LinkField;
  };
  events: {
    targetItems: EventFields[];
  };
}

type EventsListProps = {
  params: { [key: string]: string; styles: string };
  fields?: {
    data: {
      datasource: EventsListFields;
    };
  };
};

export const Default = (props: EventsListProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const datasource = props.fields?.data?.datasource;
  const [editingMode] = useState(isEditorActive());
  const editingmode = isEditorActive();

  let DynamicTertiary;
  if (editingmode) {
    // Static import when in editing mode
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicTertiary = require('components/link/Link').Tertiary;
  } else {
    // Dynamic import when not in editing mode
    DynamicTertiary = dynamic(() => import('components/link/Link').then((mod) => mod.Tertiary), {
      ssr: !!config.dynamicImportSsr,
    });
  }

  let DynamicEvent;
  if (editingmode) {
    // Static import when in editing mode
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicEvent = require('components/event/Event').Default;
  } else {
    // Dynamic import when not in editing mode
    DynamicEvent = dynamic(() => import('components/event/Event').then((mod) => mod.Default), {
      ssr: !!config.dynamicImportSsr,
    });
  }
  if (datasource) {
    return (
      <LazyLoadComponent componentId="EventList">
        <div
          className={`events-list component--with-paddings ${sitecoreStyles(props.params?.styles)}`}
          id={id ? id : undefined}
        >
          <div className="events-list__container">
            <div className="events-list__title-container">
              <Text tag="h3" className="events-list__title" field={datasource.title.jsonValue} />
            </div>
            <div className="events-list__cards-container">
              {datasource.events.targetItems.map((item) => {
                const eventProps: EventProps = {
                  fields: {
                    data: {
                      datasource: item,
                    },
                  },
                  params: {},
                };
                return <DynamicEvent key={item.id} {...eventProps} />;
              })}
            </div>
            {(datasource.link.jsonValue.value.href || editingMode) && (
              <div className="events-list__cta-container ">
                <DynamicTertiary fields={{ Link: datasource.link.jsonValue }} params={{}} />
              </div>
            )}
          </div>
        </div>
      </LazyLoadComponent>
    );
  }
  return <EventsListDefaultComponent {...props} />;
};

export const EventsListDefaultComponent = (props: EventsListProps): JSX.Element => (
  <div className={`events-list component--with-paddings ${sitecoreStyles(props.params?.styles)}`}>
    <span className="is-empty-hint">Events List</span>
  </div>
);
