import React, { useState } from 'react';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { useI18n } from 'next-localization';

export const Loader = (): JSX.Element => {
  const { t } = useI18n();
  return (
    <>
      <div
        className="loader-main-container"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <div className="loader">
          <div className="loader__container">
            <div className="loader__dots">
              <div className="loader__first-dot"></div>
              <div className="loader__middle-dot"></div>
              <div className="loader__last-dot"></div>
            </div>
            <Text
              tag="h5"
              className="loader-text"
              field={{ value: `${t('component-loading')}...` }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const LoaderDefaultComponent = (): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  return (
    <React.Fragment>
      {editingMode && (
        <div className="loader">
          <span className="is-empty-hint">Loader</span>
        </div>
      )}
    </React.Fragment>
  );
};
