import {
  NextPlaceholderName,
  useNextPlaceholderContext,
} from 'lib/contexts/NextPlaceholderContext';
import React from 'react';

export type NextPlaceholderProps = {
  name: NextPlaceholderName;
};

export const NextPlaceholder = (props: NextPlaceholderProps) => {
  const { content } = useNextPlaceholderContext();
  const placeholderConent = content[props.name];

  return <>{placeholderConent && <>{placeholderConent}</>}</>;
};
