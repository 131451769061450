import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import sitecoreStyles from 'src/helpers/sitecoreStyles';

interface Fields {
  tabs: {
    tabName: string | undefined;
    active: boolean;
  }[];
}

type TabsProps = {
  parentCallback(value: string): unknown;
  params: { [key: string]: string };
  fields: Fields;
  children: React.ReactNode;
};

const TabsDefaultComponent = (props: TabsProps): JSX.Element => (
  <div className={`component Tabs ${sitecoreStyles(props.params?.styles)}`}>
    <div className="component-content">Empty</div>
  </div>
);

export const Tabs = (props: TabsProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;

  const InitActiveTabIndex = props.fields.tabs.indexOf(
    props.fields.tabs.find((item) => item.active) ?? props.fields.tabs[0]
  );

  const [tabGroup, setTabGroup] = useState(props.fields.tabs);
  const [activeTabIndex, setActiveTabIndex] = useState(InitActiveTabIndex);

  const tabToggle = useCallback(
    (currentTabIndex: number) => {
      const tempTabs = [...tabGroup];
      tempTabs[currentTabIndex].active = true;
      setActiveTabIndex(currentTabIndex);
      for (let i = 0; i < tabGroup.length; i++) {
        if (i !== currentTabIndex) {
          const tempTabs = tabGroup;
          tempTabs[i].active = false;
        }
      }
      setTabGroup([...tempTabs]);

      props.parentCallback(tabGroup[currentTabIndex].tabName ?? '');
    },
    [props, tabGroup]
  );

  useEffect(() => {
    if (InitActiveTabIndex !== activeTabIndex) {
      setActiveTabIndex(InitActiveTabIndex);
      tabToggle(InitActiveTabIndex);
    }
  }, [InitActiveTabIndex, activeTabIndex, tabToggle]);

  if (props.fields) {
    const validTabs = tabGroup.filter((tab) => tab.tabName).slice(0, 10);
    return (
      <div className={`tabs ${sitecoreStyles(props.params?.styles)}`} id={id ? id : undefined}>
        <div className="tabs__container">
          {validTabs.map((tab, index) => (
            <button
              key={index}
              onClick={() => tabToggle(index)}
              className={
                tabGroup[index].active
                  ? 'tabs__single-tab tabs__single-tab--active'
                  : 'tabs__single-tab' + ' carousel-arrow-link'
              }
              data-text={tab.tabName}
              data-analytics-link-name={tab.tabName}
              data-analytics-link-type="Engagement"
              data-analytics-content-class="Navigation"
              data-analytics-template-zone="Body"
              data-analytics-component-name="Jump Link"
              data-analytics-component-variation="Jump Link"
              data-analytics-target="NAN"
              data-analytics-component-section="Component Jump Link"
              data-analytics-slide-number={`tab ${index + 1}`}
              data-analytics-ispersonalized="False"
              data-analytics-iscarousal="False"
            >
              {tab.tabName}
            </button>
          ))}
        </div>
        {props.children}
      </div>
    );
  }
  return <TabsDefaultComponent {...props} />;
};
