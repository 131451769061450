import React from 'react';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import { JssFieldValue } from '../../types/JssFieldValue';
import dynamic from 'next/dynamic';
import config from 'temp/config';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
export interface TagListItemFields {
  tags: JssFieldValue<string>[];
}

type TagsListProps = {
  params: { [key: string]: string };
  fields?: TagListItemFields;
};

export const Default = (props: TagsListProps) => {
  console.log('---->[Tagslist] Component', props);
  const editingmode = isEditorActive();
  let DynamicTag;

  if (editingmode) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicTag = require('components/tag/Tag').Default;
  } else {
    // Dynamic import when not in editing mode
    DynamicTag = dynamic(() => import('components/tag/Tag').then((mod) => mod.Default), {
      ssr: !!config.dynamicImportSsr,
    });
  }
  return (
    <>
      {props.fields?.tags && props.fields?.tags?.length > 0 && (
        <div className={`tags-list ${sitecoreStyles(props.params?.styles)}`}>
          <div className="tags-list__tags-container">
            {props.fields?.tags.map((tag, index) => {
              return (
                <div key={index} className="tags-list__tag">
                  <DynamicTag
                    params={{}}
                    fields={{
                      Title: {
                        value: tag.value,
                      },
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};
