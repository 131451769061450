import {
  Field,
  ImageField,
  LinkField,
  RichText,
  RichTextField,
} from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useEffect, useState } from 'react';
import { Model } from 'survey-core';
import { FormProps } from '../form/Form';
import config from 'temp/config';
import {
  GetSurveyInput,
  GetSurveyResponse,
  GetSurveyResults,
} from '../survey-assessment/AssessmentUtil';
import dynamic from 'next/dynamic';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

export interface AssessmentFields {
  id: string;
  mainDescription: {
    jsonValue: RichTextField;
  };
  mainImage: {
    jsonValue: ImageField;
  };
  startButtonText: {
    jsonValue: Field<string>;
  };
  children: {
    results: AssessmentQuestionFields[];
  };
  qualifier: {
    targetItem: {
      children: {
        results: AssessmentResultFields[];
      };
    };
  };
  resultCTAButton: {
    jsonValue: LinkField;
  };
  marketoFormID: {
    jsonValue: Field<string>;
  };
  marketoFormTitle: {
    jsonValue: RichTextField;
  };
  marketoFormSurveyResultField: {
    jsonValue: Field<string>;
  };
  marketoFormSurveyResponseField: {
    jsonValue: Field<string>;
  };
}

export type AssessmentProps = {
  params: { [key: string]: string; styles: string };
  fields?: {
    data: {
      datasource?: AssessmentFields;
    };
  };
};

export interface AssessmentQuestionFields {
  id: string;
  qName: {
    jsonValue: Field<string>;
  };
  qContent: {
    jsonValue: Field<string>;
  };
  children: {
    results: AssessmentAnswerFields[];
  };
}

export interface AssessmentAnswerFields {
  id: string;
  ansTitle: {
    jsonValue: Field<string>;
  };
  ansLevel: {
    targetItems: AssessmentLevelFields[];
  };
}

export interface AssessmentLevelFields {
  id: string;
  levelTitle: {
    jsonValue: Field<string>;
  };
  levelValue: {
    jsonValue: Field<string>;
  };
}

export interface AssessmentResultFields {
  id: string;
  resultTitle: {
    jsonValue: Field<string>;
  };
  resultDescription: {
    jsonValue: RichTextField;
  };
  resultImage: {
    jsonValue: ImageField;
  };
  levelResultMapping: {
    jsonValue: Field<string>;
  };
  sortOrder: {
    jsonValue: Field<number>;
  };
}

export const Default = (props: AssessmentProps): JSX.Element => {
  console.log('---->[Survey] Component', props.fields);
  const datasource = props?.fields?.data?.datasource;
  const [showSurvey, setShowSurvey] = useState(true); // State to hold survey completion
  const [marketo, setMarketo] = useState<FormProps | undefined>(undefined); // State to hold survey results to be passed to marketo form
  const [surveyCookie, setSurveyCookie] = useState(false); // State to hold survey cookie
  const [surveyData, setSurveyData] = useState(null);
  const [results, setResults] = useState<AssessmentResultFields | undefined>(undefined); // State to hold survey results
  const [resetKey, setResetKey] = useState(0); // key prop to reset the Survey component

  const handleRetakeAssessment = () => {
    const funcCookieState = localStorage.getItem('CookieState');
    if (funcCookieState && funcCookieState == 'true') {
      setSurveyData(null);
    }
    setResetKey((prevKey) => prevKey + 1);
    setShowSurvey(true);
  };
  useEffect(() => {
    if (document.cookie.includes('surveyformpage')) {
      setSurveyCookie(true);
    }
  }, [resetKey]); //evaluate cookie status again on retake assessment click
  useEffect(() => {
    const funcCookieState = localStorage.getItem('CookieState');
    const currPage = window?.location?.pathname?.replaceAll('/', '-');

    if (funcCookieState && funcCookieState == 'false') {
      //Cookies are allowed
      const storedDataString = localStorage.getItem(`surveyData${currPage}`);

      if (!surveyData && storedDataString !== null) {
        const storedData = JSON.parse(storedDataString);
        setSurveyData(storedData);
      }
    }
  }, [surveyData]);

  if (datasource) {
    const surveyInput = GetSurveyInput(datasource);
    if (surveyInput) {
      const onCompleteSurvey = (surveyresult: Model) => {
        const calculatedResult = GetSurveyResults(
          surveyresult,
          datasource.qualifier?.targetItem?.children?.results
        );
        const rawResults = surveyresult.data;
        setSurveyData(rawResults);
        const funcCookieState = localStorage.getItem('CookieState');
        const currPage = window?.location?.pathname?.replaceAll('/', '-');
        if (funcCookieState && funcCookieState == 'false') {
          //Cookies are allowed
          localStorage.setItem(`surveyData${currPage}`, JSON.stringify(surveyresult.data));
        }

        setShowSurvey(false); // hide the form and show the results
        setResults(calculatedResult); // save results to be passed to result component
        const formProps = {
          params: { isSurveyForm: 'true' },
          fields: {
            FormId: {
              value: datasource.marketoFormID?.jsonValue?.value,
            },
            FormSuccessTitle: {
              value: '',
            },
            FormSuccessMessage: {
              value: '',
            },
            FormSuccessLink: {
              value: {
                href: '',
              },
            },
          },
          surveyResultFields: calculatedResult, //pass calculated survey result to marketo form
          retake: handleRetakeAssessment, // pass retake assessment function
          ctalink: datasource.resultCTAButton.jsonValue, // pass result page cta link
          surveyResponse: GetSurveyResponse(surveyresult), // pass survey response to marketo hidden field
          surveyResultFieldName: datasource.marketoFormSurveyResultField?.jsonValue?.value, // pass hidden field name
          surveyResponseFieldName: datasource.marketoFormSurveyResponseField?.jsonValue?.value, // pass hidden field name
        };

        setMarketo(formProps); // save data to be passed to marketo form
      };
      const editingmode = isEditorActive();
      let DynamicForm;

      if (editingmode) {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        DynamicForm = require('../form/Form').Default;
      } else {
        // Dynamic import when not in editing mode
        DynamicForm = dynamic(() => import('../form/Form').then((mod) => mod.Default), {
          ssr: !!config.dynamicImportSsr,
        });
      }

      let DynamicSurveyResult;

      if (editingmode) {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        DynamicSurveyResult = require('../survey-assessment/SurveyResult').SurveyResult;
      } else {
        DynamicSurveyResult = dynamic(
          () => import('../survey-assessment/SurveyResult').then((mod) => mod.SurveyResult),
          {
            ssr: !!config.dynamicImportSsr,
          }
        );
      }

      let DynamicSurvey;

      if (editingmode) {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        DynamicSurvey = require('survey-react-ui').Survey;
      } else {
        DynamicSurvey = dynamic(() => import('survey-react-ui').then((mod) => mod.Survey), {
          ssr: !!config.dynamicImportSsr,
        });
      }

      return (
        <>
          {showSurvey ? (
            <DynamicSurvey
              json={surveyInput}
              onComplete={onCompleteSurvey}
              key={resetKey} // Key prop to force re-render
              data={surveyData}
            />
          ) : surveyCookie ? ( // check if cookie is set, if yes then display result directly
            results && (
              <DynamicSurveyResult
                props={results}
                retake={handleRetakeAssessment}
                ctalink={datasource.resultCTAButton.jsonValue}
              />
            )
          ) : (
            marketo &&
            datasource.marketoFormTitle?.jsonValue && (
              <div className="survey-form-container">
                <RichText field={datasource.marketoFormTitle.jsonValue} />
                <DynamicForm {...marketo} />
              </div>
            )
          )}
        </>
      );
    }
  }

  let DynamicAssessmentComponent;
  const editingmode = isEditorActive();
  if (editingmode) {
    DynamicAssessmentComponent =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('../survey-assessment/AssessmentComponent').AssessmentComponent;
  } else {
    // Dynamic import when not in editing mode
    DynamicAssessmentComponent = dynamic(
      () =>
        import('../survey-assessment/AssessmentComponent').then((mod) => mod.AssessmentComponent),
      {
        ssr: !!config.dynamicImportSsr,
      }
    );
  }
  return <DynamicAssessmentComponent />;
};
