import React from 'react';

export const StorybookHintHider = () => {
  const hints = document.getElementsByClassName('s-editing-mode-hint');
  const hideHints = () => {
    for (const element of hints) {
      element.setAttribute('style', 'display: none !important');
    }
  };
  const showHints = () => {
    for (const element of hints) {
      element.setAttribute('style', 'display: block !important');
    }
  };
  return (
    <div className="pages-editor-hint-hider">
      <button onClick={hideHints}>Hide hints</button>
      <button onClick={showHints}>Show hints</button>
    </div>
  );
};
