import {
  ComponentParams,
  ComponentRendering,
  Field,
  LinkField,
  Placeholder,
  Text,
  Link,
} from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { useState } from 'react';
import config from 'temp/config';
import { IconName } from 'src/types/Enum';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import dynamic from 'next/dynamic';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

export type ShowHideFields = {
  CheckboxLabel: Field<string>;
  Checked: Field<boolean>;
  Text: Field<string>;
  Button: LinkField;
};

export type ShowHideProps = {
  params: { [key: string]: string };
  fields?: ShowHideFields;
  rendering: ComponentRendering & { params: ComponentParams };
};

export const Default = (props: ShowHideProps): JSX.Element => {
  const [isChecked, setIsChecked] = useState(props.fields?.Checked?.value || false);
  const id = props.params?.RenderingIdentifier;
  const editingmode = isEditorActive();

  let DynamicSubmitButton;
  if (editingmode) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicSubmitButton = require('components/link/Link').Default;
  } else {
    // Dynamic import when not in editing mode
    DynamicSubmitButton = dynamic(() => import('components/link/Link').then((mod) => mod.Default), {
      ssr: !!config.dynamicImportSsr,
    });
  }
  if (props.fields) {
    return (
      <div
        id={id ? id : undefined}
        className={`show-hide show-hide--default ${sitecoreStyles(props.params?.styles)}`}
      >
        <div className="show-hide__check-box-container">
          <input
            className="show-hide__check-box"
            type="checkbox"
            name="show"
            id="show"
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
          />
          <Text
            tag="label"
            className="show-hide__check-box-text"
            field={props.fields.CheckboxLabel}
          />
        </div>
        {isChecked && (
          <div className="do-not-sell-script">
            <Text tag="p" className="do-not-sell-script__paragraph" field={props.fields.Text} />
            <DynamicSubmitButton
              fields={{ Link: props.fields.Button }}
              params={{ styles: 'link__button-primary  link--capitalize' }}
            />
          </div>
        )}
      </div>
    );
  }
  return <ShowHideDefaultComponent />;
};

export const SectionWithPlaceholder = (props: ShowHideProps): JSX.Element => {
  const [visible, setVisible] = useState<boolean>(props.fields?.Checked?.value || false);
  const { sitecoreContext } = useSitecoreContext();
  const [editingMode] = useState(sitecoreContext && sitecoreContext.pageState === 'edit');
  const id = props.params?.RenderingIdentifier;
  const phKey = `showhide-${props.params.DynamicPlaceholderId}`;

  let DynamicIcon;

  if (editingMode) {
    // Static import when in editing mode
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicIcon = require('components/shared/icon/Icon').Icon;
  } else {
    // Dynamic import when not in editing mode
    DynamicIcon = dynamic(() => import('components/shared/icon/Icon').then((mod) => mod.Icon), {
      ssr: !!config.dynamicImportSsr,
    });
  }
  if (props.fields) {
    return (
      <div
        id={id ? id : undefined}
        className={`show-hide show-hide--section-with-placeholder ${sitecoreStyles(
          props.params?.styles
        )}`}
      >
        <button
          type="button"
          title="show-hide button"
          className={`show-hide__button ${visible ? 'show-hide__button--clicked' : ''}`}
          onClick={(e) => {
            e.preventDefault();
            setVisible(!visible);
          }}
        >
          <Link field={props.fields.Button} className="body2"></Link>
          <div className={`show-hide__icon ${visible ? 'show-hide__icon--rotate' : ''}`}>
            <DynamicIcon icon={IconName.ChevronDown} iconLabel={'Open'} />
          </div>
        </button>

        {(visible || editingMode) && (
          <React.Fragment>
            <div>
              <Placeholder name={phKey} rendering={props.rendering} />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
  return <ShowHideDefaultComponent />;
};

export const ShowHideDefaultComponent = (): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const [editingMode] = useState(sitecoreContext && sitecoreContext.pageState === 'edit');
  return (
    <React.Fragment>
      {editingMode && (
        <div className={`component show-hide`}>
          <div className="component-content">
            <span className="is-empty-hint">Show/Hide component</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
