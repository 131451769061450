import { Fields as MenuItem } from '../Navigation';
import { NavigationContext } from '../NavigationContext';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';

type TopLvlMenuProps = {
  setCurrentMenuItem: (index: number) => void;
  menuItems: MenuItem[];
  currentItem: number;
};

export const TopLvlMenu = ({
  menuItems,
  setCurrentMenuItem,
  currentItem,
}: TopLvlMenuProps): JSX.Element => {
  const { navigationState, setNavigationState } = useContext(NavigationContext);
  const router = useRouter();
  const [isLinkActive, setIsLinkActive] = useState(-1); // L1 selected state
  const [isLinkHovered, setisLinkHovered] = useState(-1); //L1 hovered state
  useEffect(() => {
    const handleRouteChange = () => {
      localStorage.getItem('prevUrl') == null
        ? localStorage.setItem('prevUrl', '')
        : localStorage.setItem('prevUrl', window.location.toString());
    };

    router.events.on('beforeHistoryChange', handleRouteChange);
    return () => {
      router.events.off('beforeHistoryChange', handleRouteChange);
    };
  }, [router]);

  useEffect(() => {
    document?.querySelectorAll('.top-lvl-menu__link').forEach((item) =>
      item.addEventListener('touchend', function (e) {
        e.preventDefault();
      })
    );
  }, []);

  const updateLinkActive = useCallback(() => {
    const currentRoute = router.asPath.split('#')[0].split('/').slice(1)[0];
    menuItems.forEach((item, index) => {
      const currentMenuItem = item.Href.split('/').slice(-1)[0];

      if (currentRoute === currentMenuItem) {
        setIsLinkActive(index);
      } else if (currentRoute === '') {
        setIsLinkActive(-1);
      }
    });
  }, [router.asPath, menuItems]);

  useEffect(() => {
    updateLinkActive();
  }, [updateLinkActive, router.asPath]);

  return (
    <ul className="top-lvl-menu">
      {menuItems.map((item, index) => (
        <li
          className="top-lvl-menu__list-item"
          key={`${index}l1`}
          onMouseEnter={() => {
            setCurrentMenuItem(index);
            setNavigationState({
              ...navigationState,
              showLanguage: false,
              searchExpanded: false,
            });
          }}
        >
          {item.NavigationTitle?.value && (
            <Link
              href={item.Href}
              className={`gatrack internal-link top-lvl-menu__link ${
                isLinkActive === index ? 'top-lvl-menu__link--active' : ''
              } ${
                isLinkHovered === index ||
                (currentItem === index && menuItems[currentItem]?.Children)
                  ? 'top-lvl-menu__link--hovered'
                  : '' //Checking L1 is hovered and have lowlvl menu items
              }`}
              data-analytics-link-name={item?.NavigationTitle?.value}
              data-analytics-link-type="Navigation"
              data-analytics-content-class="Content"
              data-analytics-template-zone="Header"
              data-analytics-component-name="Main Navigation"
              data-analytics-component-variation="Main Navigation"
              data-analytics-target={item.Href}
              data-analytics-component-section="Main Navigation Items L1"
              data-analytics-slide-number="NAN"
              data-analytics-ispersonalized="False"
              data-analytics-iscarousal="False"
              aria-label={item?.NavigationTitle?.value?.toString()}
              onTouchStart={() => {
                setIsLinkActive(-1);
                setCurrentMenuItem(index);

                if (currentItem == index || !item.Children) {
                  router.push(item.Href);
                }
              }}
              onMouseOver={() => {
                setisLinkHovered(index);
              }}
              onMouseOut={() => setisLinkHovered(-1)}
              onClick={() => {
                setCurrentMenuItem(-1);
                //careerFix(item.Href, e);
              }}
            >
              {item.NavigationTitle?.value}
            </Link>
          )}
        </li>
      ))}
    </ul>
  );
};
