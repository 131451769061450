import { WithSitecoreContextProps } from '@sitecore-jss/sitecore-jss-nextjs';
import { resolveCareerLangId } from '../../lib/utils';
import { useState, useEffect } from 'react';
import { Tertiary } from 'components/link/Link';
import { useI18n } from 'next-localization';
// import { Loader } from 'components/shared/loader/Loader';
import { useBookmark } from 'lib/contexts/BookmarkContext';
import parse from 'html-react-parser';
import { useRouter } from 'next/router';
import { useSortFacet } from 'lib/contexts/SortFacetContext';
import { useSearchFacet } from 'lib/contexts/SearchFacetContext';
import { CoveoSearchApiCareersResult } from 'lib/coveo/CoveoSearchApi';
import { useLink } from 'lib/contexts/AllLinksContext';
type SavedJobsProps = {
  params: { [key: string]: string };
} & WithSitecoreContextProps;
import { useShowResult } from 'lib/contexts/ShowingResultContext';
import { Loader } from 'components/shared/loader/Loader';
import LazyLoadComponent from 'components/shared/lazy-load/LazyLoadComponent';

export const Default = (props: SavedJobsProps): JSX.Element => {
  const langCode = resolveCareerLangId(props.sitecoreContext?.language?.toLowerCase());
  console.log('current context language ', langCode);
  const { addShowResultData } = useShowResult();
  const [finalResult, setFinalResult] = useState<CoveoSearchApiCareersResult[]>([]);
  const { toggleBookmark, bookmarkedCards } = useBookmark();
  const { facetValue } = useSortFacet();
  const [sortValue, setSortValue] = useState<number | null>(null);
  const { filterValue } = useSearchFacet();
  const [visibleItems, setVisibleItems] = useState(12);
  const { t } = useI18n();
  const jobLangCode = 1;
  const urlName = useRouter();
  const { data } = useLink();
  const [loader, setLoader] = useState(false);
  const [localBookmark, setLocalBookmark] = useState<number[]>([]);

  const loadMore = () => {
    setLoader(true);
    setTimeout(() => {
      setVisibleItems((prev) => prev + 12);
      setLoader(false);
    }, 500);
  };

  useEffect(() => {
    const total = Number(finalResult.length);
    const currentCount = total - visibleItems;
    let finalCount = total - currentCount;

    if (finalCount > total) {
      finalCount = finalResult.length;
    }
    const result = { total: total, finalCount: finalCount };
    addShowResultData(result);
  }, [finalResult, visibleItems]);

  useEffect(() => {
    setVisibleItems(12);
    if (urlName.asPath.includes('saved-jobs')) {
      if (facetValue === 'relevent') {
        setSortValue(1);
        window.location.hash = window.location.hash.replace(
          /\#.*/,
          '#sortCriteria=%40job_area_of_expertise%20ascending'
        );
      } else if (facetValue === 'recent') {
        setSortValue(0);
        window.location.hash = window.location.hash.replace(
          /\#.*/,
          '#sortCriteria=%40jobposteddate%20descending%2C%40jobname%20ascending'
        );
      }
    }
  }, [facetValue]);

  useEffect(() => {
    if (filterValue === true) {
      urlName.push(data.searchJobs.value.href + window.location.hash);
    }
  }, [filterValue]);

  useEffect(() => {
    const bookmarks = localStorage?.getItem('bookmarkedCards');
    if (bookmarks) {
      setLocalBookmark(JSON.parse(bookmarks));
    }
  }, [bookmarkedCards]);

  useEffect(() => {
    const fetchSavedJobs = async () => {
      const updatedSavedRadioData = await GetSavedJobs({
        jobList: JSON.parse(localStorage?.getItem('bookmarkedCards') || '[]')
          .map((item: string) => item)
          .join(','),
        sortCriteria: sortValue,
        jobLocale: jobLangCode,
      });
      if (updatedSavedRadioData.noResults) {
        setFinalResult([]); // Set to empty array if no results
      } else {
        setFinalResult(updatedSavedRadioData.results);
      }
    };

    fetchSavedJobs();
  }, [sortValue]);

  const handleBookmarkClick = (cardId: number) => {
    toggleBookmark(cardId);
  };

  console.log('total count', finalResult.length);
  return (
    <>
      {/* Job Cards Container */}
      {finalResult.length > 0 ? (
        <div className="jobCardContainer">
          {loader && <Loader></Loader>}
          {finalResult.slice(0, visibleItems).map((result) => {
            const readMoreLink =
              window.location.origin + data.jobDetails.value.href + '/' + String(result.raw.job_id);
            return (
              <div
                key={String(result.raw.job_id)}
                className="jobCard"
                id={result.raw.job_id?.toString()}
                onClick={() => {
                  window.open(readMoreLink, '_self');
                }}
              >
                <div className="insight-card__text-container">
                  <div className="jobCardTag">
                    <LazyLoadComponent componentId="SavedJobs">
                      <div>
                        <p className="jobCardSubTitle">
                          {result.raw.jobareaofexpertise?.toString()}
                        </p>

                        <div
                          id={result.raw.jobid?.toString()}
                          className={`bookmarkIcon ${
                            localBookmark.includes(Number(result.raw.job_id))
                              ? 'bookmarkIconSelected'
                              : 'bookmarkIconNotSelected'
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleBookmarkClick(Number(result.raw.jobid));
                          }}
                          data-analytics-link-name="Bookmark Job Card"
                          data-analytics-link-type="Bookmark"
                          data-analytics-content-class="Job Card"
                          data-analytics-template-zone="Body"
                          data-analytics-component-name="Job Card Bookmark"
                          data-analytics-component-variation="Card"
                          data-analytics-target={result.raw.jobid?.toString()}
                          data-analytics-component-section="Job Card"
                          data-analytics-slide-number="NAN"
                          data-analytics-ispersonalized="False"
                          data-analytics-iscarousal="False"
                          data-analytics-job-title={result.raw.title?.toString()}
                          data-analytics-job-region={result.raw.jobcountry?.toString()}
                          data-analytics-job-sub-title={result.raw.jobareaofexpertise?.toString()}
                          data-analytics-job-id={result.raw.jobid?.toString()}
                        >
                          {' '}
                        </div>
                      </div>
                    </LazyLoadComponent>
                  </div>
                  <div className="jobCardTitle">{result.raw.title?.toString()}</div>
                  <p className="jobCardCountry">
                    {result.raw.jobcountry?.toString()?.replaceAll('^', ', ')}
                  </p>
                  <div className={'jobCardBody'}>
                    {String(result.raw.jobdescription) && parse(String(result.raw.jobdescription))}
                  </div>
                </div>
                <div>
                  <div className="tertiaryLink">
                    <Tertiary
                      fields={{
                        Link: {
                          value: {
                            text: t(`component-contentgallery-readmore`),
                            href: readMoreLink,
                          },
                        },
                      }}
                      params={{}}
                    />
                    <div className="shareIconClass">
                      <SocialBlockModal
                        shareLinkUrls={readMoreLink}
                        shareLinkTitle={result.raw.title}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="col-12 no_saved_jobs title-banner">
          <h3 className="no_saved_jobs__title">{t(`career-no-saved-jobs`)}</h3>
        </div>
      )}

      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <button
          className={finalResult.length > visibleItems ? 'loadMoreButton' : ''}
          onClick={loadMore}
        >
          {finalResult.length > visibleItems ? t(`coveo-load-more-results`) : ''}
        </button>
      </div>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const GetSavedJobs = async ({ jobList, sortCriteria, initialLoad = 0, jobLocale }: any) => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const coveoModule = require('lib/coveo/CoveoSearchApi');
  const CoveoSearchRequest = coveoModule.CoveoSearchRequest;
  // Construct your query here, including city and areaOfExpertise if provided
  const constantQuery = `@job_item_type == ActiveJobs AND @joblanguageid == ${jobLocale} AND @isignoredjob == 0`;
  const queryString = `@jobid==(${jobList})`;

  //Radio Btn
  let sortField;
  if (sortCriteria === 1) {
    sortField = '@job_area_of_expertise ascending';
  } else {
    sortField = '@jobposteddate descending, @jobname ascending';
  }
  const bookmarkCardValue = localStorage?.getItem('bookmarkedCards');
  const bookmarkCardArray = bookmarkCardValue ? JSON.parse(bookmarkCardValue) : [];
  const bookmarkCardCount = bookmarkCardArray && bookmarkCardArray.length;
  const results = await CoveoSearchRequest({
    cq: constantQuery,
    q: queryString,
    firstResult: initialLoad,
    numberOfResults: bookmarkCardCount,
    sortCriteria: sortField,
    // sortCriteria: sortField,
    //  sortCriteria: relevant ? '@jobareaofexpertise ascending' : '@jobposteddate descending', //if jobposteddate is same then sort on basis of title
    //locale: lang
    // Add other parameters as needed
  });
  const availableItems: (number | string)[] = [];
  const localStorageBookmarkedCardData: string[] = JSON.parse(
    localStorage?.getItem('bookmarkedCards') || '[]'
  );

  console.log('result qq', results.results);
  results.results.forEach((item: { raw: { job_id: number | string } }) => {
    availableItems.push(item.raw.job_id);
  });

  const diff = localStorageBookmarkedCardData.filter((item) => !availableItems.includes(item));

  if (diff.length > 0) {
    window.dispatchEvent(new Event('localStorageEvent'));
    localStorage?.setItem('bookmarkedCards', JSON.stringify(availableItems));
  }

  // Check if there are no available items
  if (availableItems.length === 0) {
    return { noResults: true }; // Indicate that there are no results
  }

  return results;
};
//coveo api call for getting job card list

interface propTypeShareLinkUrls {
  shareLinkUrls: string;
  shareLinkTitle?: string;
}

const SocialBlockModal = (props: propTypeShareLinkUrls) => {
  // Share functionality - Start--------------------------
  const [visibility, setvisibility] = useState(false);
  // const [hostName, sethostName] = useState('');
  // useEffect(() => {
  //   if (typeof window != 'undefined') {
  //     const getHostName = 'https://' + window.location.hostname;
  //     sethostName(getHostName);
  //   }
  // }, []);
  const openshare = () => {
    setvisibility(true);
  };
  const closeshare = () => {
    setvisibility(false);
  };

  const encodedTitle = encodeURIComponent(props.shareLinkTitle || 'Default Title');
  const encodedUrl = encodeURIComponent(props.shareLinkUrls);
  const mailtoLink = `mailto:?subject=${encodedTitle} via Avanade&body='${encodedTitle}' at ${encodedUrl}`;

  return (
    <>
      <div
        className="shareIcon"
        style={{ height: '24px', width: '24px' }}
        onClick={(e) => {
          openshare();
          e.stopPropagation();
        }}
      ></div>
      <div
        className={'shareOptions ' + (visibility ? 'visible' : '')}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <a
          href={mailtoLink}
          className="ga-track social-sharing-analytics"
          target="_blank"
          rel="noopener noreferrer"
          data-analytics-link-name="Social Share"
          data-analytics-link-type="Email"
          data-analytics-content-class="Content"
          data-analytics-template-zone="Body"
          data-analytics-component-name="Image Molecule"
          data-analytics-component-variation="NAN"
          data-analytics-target={props.shareLinkUrls}
          data-analytics-component-section="NAN"
          data-analytics-slide-number="NAN"
          data-analytics-ispersonalized="False"
          data-analytics-iscarousal="False"
          data-analytics-social-media-type="Email"
        >
          <i className="icon icon--email icon--28" aria-label="email"></i>
        </a>
        <a
          href="javascript:void(0);"
          className="ga-track social-sharing-analytics"
          target="_blank"
          rel="noopener noreferrer"
          data-analytics-link-name="Social Share"
          data-analytics-link-type="Facebook"
          data-analytics-content-class="Content"
          data-analytics-template-zone="Body"
          data-analytics-component-name="Image Molecule"
          data-analytics-component-variation="NAN"
          data-analytics-target={props.shareLinkUrls}
          data-analytics-component-section="NAN"
          data-analytics-slide-number="NAN"
          data-analytics-ispersonalized="False"
          data-analytics-iscarousal="False"
          data-analytics-social-media-type="Facebook"
          onClick={() => {
            window.open(
              'https://www.facebook.com/sharer/sharer.php?u=' + props.shareLinkUrls,
              'popupwindow',
              'scrollbars=yes,width=600,height=600'
            );
          }}
        >
          <i className="icon icon--facebook icon--24" aria-label="Facebook"></i>
        </a>

        <a
          href="javascript:void(0);"
          className="ga-track social-sharing-analytics"
          target="_blank"
          rel="noopener noreferrer"
          data-analytics-link-name="Social Share"
          data-analytics-link-type="LinkedIn"
          data-analytics-content-class="Content"
          data-analytics-template-zone="Body"
          data-analytics-component-name="Image Molecule"
          data-analytics-component-variation="NAN"
          data-analytics-target={props.shareLinkUrls}
          data-analytics-component-section="NAN"
          data-analytics-slide-number="NAN"
          data-analytics-ispersonalized="False"
          data-analytics-iscarousal="False"
          data-analytics-social-media-type="LinkedIn"
          onClick={() => {
            window.open(
              'https://www.linkedin.com/shareArticle?url=' + props.shareLinkUrls,
              'popupwindow',
              'scrollbars=yes,width=600,height=600'
            );
          }}
        >
          <i className="icon icon--linkedin icon--24" aria-label="Linkedin"></i>
        </a>

        <a>
          <i
            className="icon icon--dismiss icon--24"
            aria-label="Close"
            onClick={(e) => {
              closeshare();
              e.stopPropagation();
            }}
          ></i>
        </a>
      </div>
    </>
  );
};
