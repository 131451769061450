import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import React, { useState } from 'react';
import { CardProps } from 'components/card/Card';
import { Tertiary } from 'components/link/Link';
import { removeColFromStylesProps } from 'src/helpers/sitecoreStyles';

export const HighlightedCard = (props: CardProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const [editingMode] = useState(isEditorActive());
  const handleClick = () => {
    if (props.fields?.data?.datasource?.link?.jsonValue)
      window.open(props.fields?.data?.datasource?.link.jsonValue?.value?.href, '_self');
  };
  const datasource = props.fields?.data?.datasource;

  if (!datasource) {
    return <></>;
  }

  return (
    <div
      className={`card highlighted-card highlighted-card__container ${removeColFromStylesProps(
        props.params.styles
      )}`}
      id={id ? id : undefined}
      onClick={!editingMode ? handleClick : () => null}
    >
      <div className="highlighted-card__text-container">
        <Text
          tag="div"
          className="body1 highlighted-card__title"
          field={datasource?.title?.jsonValue}
        />
      </div>
      <div className="highlighted-card__link">
        <Tertiary fields={{ Link: datasource?.link?.jsonValue }} params={{}}></Tertiary>
      </div>
    </div>
  );
};
