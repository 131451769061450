import { BrightcoveItemFields, Default as SingleVideo } from 'components/single-video/SingleVideo';
import { Default as PlaylistVideo } from 'components/PlaylistVideo/PlaylistVideo';
import Modal from 'components/shared/modal/Modal';

type GlobalVideoModalProps = {
  brightcoveIDValue: string;
  showVideoModal: boolean;
  isPlaylist: boolean;
  setShowVideoModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const GlobalVideoModal = (props: GlobalVideoModalProps) => {
  return (
    <>
      {props.showVideoModal && props.brightcoveIDValue ? (
        <Modal
          hasCloseBtn={true}
          isOpen={props.showVideoModal}
          onClose={() => {
            props.setShowVideoModal(false);
            window.history.replaceState(null, '', window.location.pathname);
          }}
        >
          {props.isPlaylist ? (
            <PlaylistVideo
              isModalDisplayed={props.showVideoModal}
              autoplay={true}
              fields={{
                data: {
                  datasource: {
                    id: '41E8216574FC488A8EFBFC97F6D585EC',
                    brightcovePlayer: {
                      targetItem: {
                        id: 'FB7C2BAFB27D42169CE0C126C7DA577D',
                        playerKey: {
                          jsonValue: {
                            value: 'ryfVT8tf',
                          },
                        },
                      },
                    },
                    brightcoveItem: {
                      targetItem: {
                        id: 'C40FED71E8E7456094DD2C595B960FE4',
                        brightcoveID: {
                          jsonValue: {
                            value: props.brightcoveIDValue,
                          },
                        },
                        brightcoveName: {
                          jsonValue: {
                            value: 'brightcoveIDValue',
                          },
                        },
                      },
                    },
                  },
                },
              }}
              params={{}}
            />
          ) : (
            <SingleVideo
              isModalDisplayed={props.showVideoModal}
              autoplay={true}
              fields={{
                data: {
                  datasource: {
                    id: '64E7DC21D2A34753A55AC4CC37FLL2B0',
                    brightcovePlayer: {
                      targetItem: {
                        id: '2D4E125DF82A49C2BEEFBF7747A84A1C',
                        playerKey: {
                          jsonValue: {
                            value: 'SJXTqvOM',
                          },
                        },
                      },
                    },
                    brightcoveItem: {
                      targetItem: {
                        id: '31210D42B5BF4AEC901C054C250F40B9',
                        brightcoveID: {
                          jsonValue: {
                            value: props.brightcoveIDValue,
                          },
                        },
                        brightcoveName: {
                          jsonValue: {
                            value: '',
                          },
                        },
                      },
                    },
                  } as BrightcoveItemFields,
                },
              }}
              params={{}}
              singleVideoBrightcoveIDValue={props.brightcoveIDValue}
            />
          )}
        </Modal>
      ) : null}
    </>
  );
};
