import React from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { useI18n } from 'next-localization';
import { ReferencableItemFields } from 'src/types/Referencable';
import NextImageWithNullFallback from 'components/shared/nextImg/NextImageWithNullFallback';
import { Icon } from 'components/shared/icon/Icon';
import { IconName } from 'src/types/Enum';

export const RelatedLinksCard = (props: ReferencableItemFields): JSX.Element => {
  const { t } = useI18n();

  return (
    <div className="related-links-card">
      <div className="related-links-card__header">
        {props.referenceImage?.jsonValue && (
          <NextImageWithNullFallback
            field={props.referenceImage.jsonValue}
            width={264}
            height={264}
            params={{
              styles: 'related-links-card__header-image next-image--square',
            }}
          ></NextImageWithNullFallback>
        )}
      </div>
      <div className="related-links-card__body">
        <div className="related-links-card__main-box">
          {props.referenceTitle?.jsonValue && (
            <Text
              field={props.referenceTitle.jsonValue}
              tag="div"
              className="body1 related-links-card__title"
            />
          )}
          {props.referenceText?.jsonValue && (
            <RichText
              tag="div"
              className="related-links-card__description body2"
              field={props.referenceText.jsonValue}
            />
          )}
        </div>

        {
          <a
            className="ga-track related-links-card__learn-more body1"
            href={props.url?.url}
            data-analytics-link-name={`${t('component-relatedlinks-learnmore')}`}
            data-analytics-link-type="Link"
            data-analytics-content-class="Content"
            data-analytics-template-zone="Body"
            data-analytics-component-name="Related Links Card"
            data-analytics-component-variation="NAN"
            data-analytics-target={`${props.url?.url}`}
            data-analytics-component-section="NAN"
            data-analytics-slide-number="NAN"
            data-analytics-ispersonalized="False"
            data-analytics-iscarousal="False"
            aria-label={
              props.referenceTitle?.jsonValue?.value || t('component-relatedlinks-learnmore')
            }
          >
            {t('component-relatedlinks-learnmore')}
            <div className="related-links-card__arrow-icon-container">
              <div className="related-links-card__arrow">
                <Icon icon={IconName.ArrowRight} iconLabel={'Open link'} />
              </div>
              <div className="related-links-card__arrow-active">
                <Icon icon={IconName.ArrowRightActive} iconLabel={'Open link'} />
              </div>
            </div>
          </a>
        }
      </div>
    </div>
  );
};
