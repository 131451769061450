import { ReferencableItemFields } from 'src/types/Referencable';
import React, { useState } from 'react';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import { TaggableFields } from 'src/types/Taggable';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import dynamic from 'next/dynamic';
import config from 'temp/config';
export interface TopFiveTagsFields {
  content: {
    targetItems: (ReferencableItemFields | TaggableFields)[];
  };
}

type TopFiveTagsProps = {
  params: { [key: string]: string };
  fields?: {
    data: {
      datasource?: TopFiveTagsFields;
    };
  };
};

export const Default = (props: TopFiveTagsProps): JSX.Element => {
  const datasource = props.fields?.data?.datasource;

  console.log('---->[TopFive] Component', datasource);

  const [editingMode] = useState(isEditorActive());

  const targetItems = datasource?.content?.targetItems || [];
  const hasTargetItems = targetItems.length > 0;

  const firstItem = hasTargetItems ? targetItems[0] : null;
  const otherItems = hasTargetItems ? targetItems.slice(1, 5) : [];

  const editingmode = isEditorActive();
  let DynamicTopFiveTagsCard;

  if (editingmode) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicTopFiveTagsCard = require('./TopFiveTagsCard').TopFiveTagsCard;
  } else {
    DynamicTopFiveTagsCard = dynamic(
      () => import('./TopFiveTagsCard').then((mod) => mod.TopFiveTagsCard),
      { ssr: !!config.dynamicImportSsr }
    );
  }
  return (
    <div className={`top-five component--with-paddings ${sitecoreStyles(props.params?.styles)}`}>
      <div className="row">
        {editingMode && targetItems.length === 0 && <h2>Component: Top Five Tags</h2>}
        {firstItem && (
          <div key={firstItem.id} className="col-12 col-md-6 col-lg-8 left-container">
            <DynamicTopFiveTagsCard {...firstItem} isFirstItem={true} />
          </div>
        )}
        <div className="col-12 col-md-6 col-lg-4 right-container">
          {otherItems.map((item) => (
            <DynamicTopFiveTagsCard key={item.id} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export const TopFiveDefaultComponent = (props: TopFiveTagsProps): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  return (
    <React.Fragment>
      {editingMode && (
        <div className={`top-five ${sitecoreStyles(props.params?.styles)}`}>
          <span className="is-empty-hint">Top Five Tags</span>
        </div>
      )}
    </React.Fragment>
  );
};
