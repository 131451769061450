import React from 'react';
import { Field, Text, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import { OfficeDirectoryFields } from '../office-directory/OfficeDirectory';
import { useState } from 'react';
import Link from 'next/link';
import { useI18n } from 'next-localization';
import { IconName } from 'src/types/Enum';
import dynamic from 'next/dynamic';
import LazyLoadComponent from 'components/shared/lazy-load/LazyLoadComponent';
import config from 'temp/config';

export interface HeadquartersFields {
  id: string;
  headquartersName?: {
    jsonValue?: Field<string>;
  };
  headquartersOffice?: {
    targetItem: OfficeDirectoryFields;
  };
}

export interface HeadquartersDatasource {
  data: {
    datasource: {
      children: {
        results: HeadquartersFields[];
      };
    };
  };
}
type HeadquartersProps = {
  params: { [key: string]: string };
  fields?: HeadquartersDatasource;
};

export const Default = (props: HeadquartersProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const datasource = props?.fields?.data?.datasource;
  const { t } = useI18n();
  const [editingMode] = useState(isEditorActive());
  console.log('---->[Headquarters] Component', props);
  const editingmode = isEditorActive();

  let DynamicIcon;

  if (editingmode) {
    // Static import when in editing mode
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicIcon = require('components/shared/icon/Icon').Icon;
  } else {
    // Dynamic import when not in editing mode
    DynamicIcon = dynamic(() => import('components/shared/icon/Icon').then((mod) => mod.Icon), {
      ssr: !!config.dynamicImportSsr,
    });
  }
  if (datasource) {
    return (
      <LazyLoadComponent componentId="Headquarters">
        <div
          className={`headquarters component--with-paddings ${sitecoreStyles(props.params.styles)}`}
          id={id ? id : undefined}
        >
          <div className="row">
            {datasource?.children?.results.map((item, index) => (
              <div key={`item-${index}`} className="headquarters__item col-12 col-lg-4">
                <Text tag="h6" field={item?.headquartersName?.jsonValue} />

                <>
                  <RichText
                    tag="div"
                    className={`body2 headquarters__item-address`}
                    field={item.headquartersOffice?.targetItem?.address?.jsonValue}
                  />
                </>
                {item?.headquartersOffice?.targetItem?.phoneOne?.jsonValue.value && (
                  <div className="headquarters__item-phone">
                    <DynamicIcon icon={IconName.Phone} iconLabel={'Phone'} />
                    {editingMode ? (
                      <Text
                        tag="div"
                        className={`body1`}
                        field={item?.headquartersOffice?.targetItem?.phoneOne?.jsonValue}
                      />
                    ) : (
                      <Link
                        href={`tel:${item?.headquartersOffice?.targetItem?.phoneOne?.jsonValue.value}`}
                        className={`body1`}
                      >
                        {item?.headquartersOffice?.targetItem?.phoneOne?.jsonValue.value}
                      </Link>
                    )}
                  </div>
                )}
                {item?.headquartersOffice?.targetItem?.phoneTwo?.jsonValue.value && (
                  <div className="headquarters__item-phone">
                    <DynamicIcon icon={IconName.Phone} iconLabel={'Phone'} />

                    {editingMode ? (
                      <>
                        <Text
                          tag="div"
                          className={`body1`}
                          field={item?.headquartersOffice?.targetItem?.phoneTwo?.jsonValue}
                        />
                        {t('component-contacts-tollfree')}
                      </>
                    ) : (
                      <>
                        <Link
                          href={`tel:${item?.headquartersOffice?.targetItem?.phoneTwo?.jsonValue.value}`}
                          className={`body1`}
                        >
                          {item?.headquartersOffice?.targetItem?.phoneTwo?.jsonValue.value}
                        </Link>
                        {t('component-contacts-tollfree')}
                      </>
                    )}
                  </div>
                )}
                {item?.headquartersOffice?.targetItem?.fax?.jsonValue.value && (
                  <div className="headquarters__item-fax">
                    <DynamicIcon icon={IconName.Fax} iconLabel={'Fax'} />

                    {editingMode ? (
                      <Text
                        tag="div"
                        className={`body1`}
                        field={item?.headquartersOffice?.targetItem?.fax?.jsonValue}
                      />
                    ) : (
                      <Link
                        href={`fax:${item?.headquartersOffice?.targetItem?.fax?.jsonValue.value}`}
                        className={`body1`}
                      >
                        {item?.headquartersOffice?.targetItem?.fax?.jsonValue.value}
                      </Link>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </LazyLoadComponent>
    );
  }

  return <HeadquartersDefaultComponent />;
};

export const HeadquartersDefaultComponent = (): JSX.Element => (
  <div className="headquarters component--with-paddings">
    <span className="is-empty-hint">Headquarters</span>
  </div>
);
