import React from 'react';
import { Text, TextField } from '@sitecore-jss/sitecore-jss-nextjs';
import sitecoreStyles from 'src/helpers/sitecoreStyles';

interface Fields {
  data: {
    datasource: {
      url: {
        path: string;
        siteName: string;
      };
      field: {
        jsonValue: {
          value: string;
          editable: string;
        };
      };
    };
    contextItem: {
      url: {
        path: string;
        siteName: string;
      };
      field: {
        jsonValue: {
          value: string;
          editable: string;
        };
      };
    };
  };
}

type TitleProps = {
  params: { [key: string]: string };
  fields?: Fields;
};

export const Default = (props: TitleProps): JSX.Element => {
  const datasource = props.fields?.data?.datasource || props.fields?.data?.contextItem;
  const text: TextField = {
    value: datasource?.field?.jsonValue?.value,
    editable: datasource?.field?.jsonValue?.editable,
  };
  const tag = props.params['TitleTag'] || 'h1';
  const charLength = text.value && text.value?.toString().length;

  return (
    <Text
      className={`title ${sitecoreStyles(props.params?.styles)} ${
        charLength && charLength > 80 && tag == 'h1' ? `reducedTypo` : ``
      }`}
      field={text}
      tag={tag}
    />
  );
};
