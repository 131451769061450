import React, { useState } from 'react';
import {
  Field,
  // RichTextField,
  ImageField,
  LinkField,
  ComponentParams,
  ComponentRendering,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { HeroTitle as HeroTitleView } from './variants/title-hero/TitleHero';
import {
  FullBleedHeroLeft,
  FullBleedHeroRight,
  FullBleedHeroLeftWithSearch,
} from './variants/full-bleed-hero/FullBleedHero';
import { HomepageHeroSquareImage } from './variants/homepage-hero/HomepageHeroSquareImage';
import { HomepageHeroVideo } from './variants/homepage-hero/HomepageHeroVideo';
import { HomepageHero169Image } from './variants/homepage-hero/HomepageHero16-9Image';
import { Default as SectionHero } from './variants/section-hero/SectionHero';
import { SubPageHeroWithSearch } from './variants/section-hero/SectionHero';
import { JobHero as JobHero } from './variants/job-hero/JobHero';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import { TagFields } from 'src/types/Tag';

export interface HeroFields {
  title: {
    jsonValue: Field<string>;
  };
  body?: {
    jsonValue: Field<string>;
  };
  link: {
    jsonValue: LinkField;
  };
  image?: {
    jsonValue: ImageField;
  };
  contentLabel?: {
    targetItem: TagFields;
  };
  hideContentLabel?: {
    jsonValue: Field<boolean>;
  };
}

export type HeroProps = {
  rendering: ComponentRendering & { params: ComponentParams };
  params: ComponentParams;
  fields?: {
    data: {
      datasource?: HeroFields;
    };
  };
  children?: React.ReactNode[];
};

export const DefaultHero = (props: HeroProps): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  return (
    <React.Fragment>
      {editingMode && (
        <div className={`hero component--with-paddings ${sitecoreStyles(props.params?.styles)}`}>
          <span className="is-empty-hint">Hero</span>
        </div>
      )}
    </React.Fragment>
  );
};

export const Default = (props: HeroProps): JSX.Element => {
  console.log('hero1', props);
  return <FullBleedHeroLeft {...props} />;
};

export const FullBleedRight = (props: HeroProps): JSX.Element => {
  return <FullBleedHeroRight {...props} />;
};

export const FullBleedLeft = (props: HeroProps): JSX.Element => {
  return <FullBleedHeroLeft {...props} />;
};

export const FullBleedLeftSearch = (props: HeroProps): JSX.Element => {
  return <FullBleedHeroLeftWithSearch {...props} />;
};

export const HomepageHeroImageSquare = (props: HeroProps): JSX.Element => {
  return <HomepageHeroSquareImage {...props} />;
};

export const HomepageHeroWithVideo = (props: HeroProps): JSX.Element => {
  return <HomepageHeroVideo {...props} />;
};

export const HomepageHeroImage169 = (props: HeroProps): JSX.Element => {
  return <HomepageHero169Image {...props} />;
};

export const SubPageHero = (props: HeroProps): JSX.Element => {
  return (
    <div className={`hero__sub-page ${sitecoreStyles(props.params?.styles)}`}>
      <SectionHero {...props} />
    </div>
  );
};

export const SubPageHeroSearch = (props: HeroProps): JSX.Element => {
  return (
    <div className={`hero__sub-page ${sitecoreStyles(props.params?.styles)}`}>
      <SubPageHeroWithSearch {...props} />
    </div>
  );
};

export const HeroTitle = (props: HeroProps): JSX.Element => {
  return <HeroTitleView {...props} />;
};

export const CareerJobHero = (props: HeroProps): JSX.Element => {
  return <JobHero {...props} />;
};
