import React, { useState } from 'react';
import { RichText, RichTextField, Text, Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import LazyLoadComponent from 'components/shared/lazy-load/LazyLoadComponent';

export interface MetricFields {
  Metric?: Field<string>;
  Prefix?: Field<string>;
  Postfix?: Field<string>;
  Title?: Field<string>;
  Description?: RichTextField;
}

export type MetricProps = {
  params: { [key: string]: string };
  fields?: MetricFields;
};

export const Default = (props: MetricProps): JSX.Element => {
  console.log('---->[Metric] Component', props.fields);
  const id = props.params.RenderingIdentifier;
  const [editingMode] = useState(isEditorActive());
  if (props.fields) {
    return (
      <LazyLoadComponent componentId="Metric">
        <div
          className={`metric row ${sitecoreStyles(props.params?.styles)}`}
          id={id ? id : undefined}
        >
          <div className="col-12 metric__content">
            {(props.fields?.Prefix?.value ||
              props.fields?.Metric?.value ||
              props.fields?.Postfix?.value ||
              editingMode) && (
              <div className="metric__number-container">
                <Text tag="h2" className="metric__prefix" field={props.fields.Prefix} />
                <Text tag="h2" className="metric__number" field={props.fields.Metric} />
                <Text tag="div" className="metric__postfix h4" field={props.fields.Postfix} />
              </div>
            )}
            {(props.fields?.Title?.value || editingMode) && (
              <Text tag="div" field={props.fields.Title} className="body1 metric__title" />
            )}
            {(props.fields?.Description?.value || editingMode) && (
              <RichText tag="div" className="body2 metric__text" field={props.fields.Description} />
            )}
          </div>
        </div>
      </LazyLoadComponent>
    );
  }
  return <MetricDefaultComponent {...props} />;
};

export const Featured = (props: MetricProps) => {
  console.log('---->[Metric] Component', props.fields);
  const id = props.params.RenderingIdentifier;
  const [editingMode] = useState(isEditorActive());
  return (
    <div
      className={`featured-metric__main ${sitecoreStyles(props.params?.styles)}`}
      id={id ? id : undefined}
    >
      <div className="single-card">
        {(props.fields?.Prefix?.value ||
          props.fields?.Metric?.value ||
          props.fields?.Postfix?.value ||
          editingMode) && (
          <div className="number-content1">
            <Text
              tag="h6"
              className="featured-metric__main__percentage1"
              field={props.fields?.Prefix}
            />
            <Text tag="h6" className="featured-metric__main__digit1" field={props.fields?.Metric} />
            <Text
              tag="h6"
              className="featured-metric__main__percentage1"
              field={props.fields?.Postfix}
            />
          </div>
        )}
        {(props.fields?.Description?.value || editingMode) && (
          <RichText
            tag="div"
            className="featured-metric__main__paragraph1"
            field={props.fields?.Description}
          />
        )}
        {(props.fields?.Title?.value || editingMode) && (
          <Text tag="h6" className="featured-metric__main__subtitle1" field={props.fields?.Title} />
        )}
      </div>
    </div>
  );
};

export const MetricDefaultComponent = (props: MetricProps): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  return (
    <React.Fragment>
      {editingMode && (
        <div className={`metric ${sitecoreStyles(props.params?.styles)}`}>
          <span className="is-empty-hint">Metric</span>
        </div>
      )}
    </React.Fragment>
  );
};
