import React, { useState } from 'react';
import {
  ComponentParams,
  ComponentRendering,
  Field,
  LinkField,
  Text,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import config from 'temp/config';
import { removeColFromStylesProps } from 'src/helpers/sitecoreStyles';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import dynamic from 'next/dynamic';
import LazyLoadComponent from 'components/shared/lazy-load/LazyLoadComponent';

interface ThreeUpPagesDatasourceFields {
  Title?: Field<string>;
  Link: LinkField;
}

type ThreeUpPagesProps = {
  children?: React.ReactNode[];
  rendering: ComponentRendering & { params: ComponentParams };
  params: { [key: string]: string };
  fields?: ThreeUpPagesDatasourceFields;
};

const ThreeUpPagesBase = (props: ThreeUpPagesProps, alignmentClass: string): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const fields = props.fields;
  console.log(`---->[ThreeUpPagesBase] Component`, fields);
  const sitecoreContext = useSitecoreContext();
  const editingmode = isEditorActive();
  let DynamicTertiary;

  if (editingmode) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicTertiary = require('components/link/Link').Tertiary;
  } else {
    // Dynamic import when not in editing mode
    DynamicTertiary = dynamic(() => import('components/link/Link').then((mod) => mod.Tertiary), {
      ssr: !!config.dynamicImportSsr,
    });
  }

  let DynamicChildrenOrPlaceholder;

  if (editingmode) {
    DynamicChildrenOrPlaceholder =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/shared/children-or-placeholder/ChildrenOrPlaceholder').ChildrenOrPlaceholder;
  } else {
    // Dynamic import when not in editing mode
    DynamicChildrenOrPlaceholder = dynamic(
      () =>
        import('components/shared/children-or-placeholder/ChildrenOrPlaceholder').then(
          (mod) => mod.ChildrenOrPlaceholder
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }

  if (fields) {
    return (
      <LazyLoadComponent componentId="ThreeUpPages">
        <div
          className={`three-up-pages ${alignmentClass} component--with-paddings ${removeColFromStylesProps(
            props.params.styles
          )}`.trim()}
          id={id}
        >
          <div className="three-up-pages__title-container">
            <Text tag="h2" field={fields?.Title} className="three-up-pages__title" />
          </div>
          <div className="three-up-pages__main-container">
            <div className="three-up-pages__primary-page-container">
              <div className="three-up-pages__primary-page">
                <DynamicChildrenOrPlaceholder
                  name={`reference-1`}
                  rendering={props.rendering}
                  sitecoreContext={sitecoreContext}
                  params={{ isThreeUp: 'true' }}
                >
                  {props.children ? props.children[0] : null}
                </DynamicChildrenOrPlaceholder>
              </div>
            </div>
            <div className="three-up-pages__secondary-page-container">
              <DynamicChildrenOrPlaceholder
                params={{ scrolling: 'true', isThreeUp: 'true' }}
                name={`reference-2`}
                sitecoreContext={sitecoreContext}
                rendering={props.rendering}
              >
                {props.children ? props.children[1] : null}
              </DynamicChildrenOrPlaceholder>
            </div>
          </div>
          <div className="three-up-pages__view-all-container">
            <DynamicTertiary
              fields={{ Link: fields.Link }}
              params={{}}
              aria-label={`${fields.Link?.value?.text}`}
            />
          </div>
        </div>
      </LazyLoadComponent>
    );
  }

  return <ThreeUpPagesDefaultComponent />;
};

export const Default = (props: ThreeUpPagesProps): JSX.Element => {
  return ThreeUpPagesBase(props, 'three-up-pages--default');
};

export const AlignRight = (props: ThreeUpPagesProps): JSX.Element => {
  return ThreeUpPagesBase(props, 'three-up-pages--align-right');
};

export const ThreeUpPagesDefaultComponent = (): JSX.Element => {
  const [editingMode] = useState(isEditorActive());

  return (
    <React.Fragment>
      {editingMode && (
        <div className={`three-up-pages component--with-paddings`}>
          <span className="is-empty-hint">Three Up Pages</span>
        </div>
      )}
    </React.Fragment>
  );
};
