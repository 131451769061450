import React, { useState } from 'react';
import { ImageField, LinkField, RichText, RichTextField } from '@sitecore-jss/sitecore-jss-nextjs';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import { IconName } from 'src/types/Enum';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import dynamic from 'next/dynamic';
import config from 'temp/config';
import LazyLoadComponent from 'components/shared/lazy-load/LazyLoadComponent';

interface AccordionItemFields {
  id: string;
  heading?: {
    jsonValue?: RichTextField;
  };
  content?: {
    jsonValue?: RichTextField;
  };
  image?: {
    jsonValue?: ImageField;
  };
  subheading: {
    jsonValue: RichTextField;
  };
  link: {
    jsonValue: LinkField;
  };
}

interface AccordionDatasource {
  id: string;
  children?: {
    results?: AccordionItemFields[];
  };
}

type AccordionProps = {
  params: { [key: string]: string; styles: string };
  fields?: {
    data: {
      datasource?: AccordionDatasource;
    };
  };
};

const editingmode = isEditorActive();

let DynamicIcon;

if (editingmode) {
  // Static import when in editing mode
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  DynamicIcon = require('components/shared/icon/Icon').Icon;
} else {
  // Dynamic import when not in editing mode
  DynamicIcon = dynamic(() => import('components/shared/icon/Icon').then((mod) => mod.Icon), {
    ssr: !!config.dynamicImportSsr,
  });
}

export const Default = (props: AccordionProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const datasource = props.fields?.data?.datasource;
  console.log('---->[Accordion] Component', datasource);

  const [isAccordionOpen, setIsAccordionOpen] = useState(-1);

  const toggleAccordion = (index: number) => {
    if (index !== isAccordionOpen) {
      setIsAccordionOpen(index);
    } else {
      setIsAccordionOpen(-1);
    }
  };

  if (datasource) {
    return (
      <LazyLoadComponent componentId="Accordion">
        <div
          className={`accordion ${sitecoreStyles(props.params?.styles)}`}
          id={id ? id : undefined}
        >
          <div className="accordion__container">
            {datasource?.children?.results &&
              datasource?.children?.results.map((item, index) => (
                <div key={`item-${index}`} className="accordion__item">
                  <div
                    title="accordion"
                    onClick={() => toggleAccordion(index)}
                    className="accordion__item-header"
                  >
                    <RichText tabIndex={0} tag="h5" field={item.heading?.jsonValue} />
                    <div
                      className={`accordion__icon ${
                        isAccordionOpen === index ? 'accordion__icon--rotate' : ''
                      }`}
                    >
                      <DynamicIcon icon={IconName.ChevronDown} iconLabel={'Open / close'} />
                    </div>
                  </div>
                  <div
                    className={`accordion__item-content ${
                      isAccordionOpen === index ? 'accordion__item-content--open' : ''
                    }`}
                  >
                    <div className="accordion__inside-content">
                      <RichText
                        tabIndex={0}
                        tag="div"
                        className={`body2 accordion__item-text`}
                        field={item.content?.jsonValue}
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </LazyLoadComponent>
    );
  }

  return <AccordionComponent />;
};

export const WithImages = (props: AccordionProps): JSX.Element => {
  // const { t } = useI18n();
  const id = props.params.RenderingIdentifier;
  const datasource = props.fields?.data?.datasource;
  const [editingMode] = useState(isEditorActive());

  const stopPropagation = (e: React.KeyboardEvent | React.MouseEvent, index: number) => {
    if (index == isAccordionOpen || editingMode) {
      e.stopPropagation();
    }
  };
  console.log('---->[Accordion With Image] Component', datasource);

  const [isAccordionOpen, setIsAccordionOpen] = useState(-1);

  const toggleAccordion = (index: number) => {
    if (index !== isAccordionOpen) {
      setIsAccordionOpen(index);
    } else {
      setIsAccordionOpen(-1);
    }
  };

  const handleChildElementClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (editingMode) {
      e.preventDefault();
    } else {
      e.stopPropagation();
    }
  };

  const editingmode = isEditorActive();

  let DynamicNextImageWithNullFallback;
  if (editingmode) {
    // Static import when in editing mode
    DynamicNextImageWithNullFallback =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/shared/nextImg/NextImageWithNullFallback').NextImageWithNullFallback;
  } else {
    // Dynamic import when not in editing mode
    DynamicNextImageWithNullFallback = dynamic(
      () =>
        import('components/shared/nextImg/NextImageWithNullFallback').then(
          (mod) => mod.NextImageWithNullFallback
        ),
      { ssr: !!config.dynamicImportSsr } // Adjust server-side rendering (ssr: true) based on your config
    );
  }

  //DynamicTertiary
  let DynamicTertiary;

  if (editingmode) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicTertiary = require('components/link/Link').Tertiary;
  } else {
    // Dynamic import when not in editing mode
    DynamicTertiary = dynamic(() => import('components/link/Link').then((mod) => mod.Tertiary), {
      ssr: !!config.dynamicImportSsr,
    });
  }

  if (datasource) {
    return (
      <>
        <LazyLoadComponent componentId="AccordionWithImages">
          <div
            className={`accordion-with-images component--with-paddings ${sitecoreStyles(
              props.params.styles
            )}`}
            id={id ? id : undefined}
          >
            <div className="accordion-with-images__container">
              {datasource?.children?.results &&
                datasource?.children?.results.map((item, index) => (
                  <div key={`item-${index}`} className="accordion-with-images__item">
                    <div
                      title="accordion"
                      data-analytics-link-name={item.heading?.jsonValue?.value?.replace(
                        /<\/?[^>]+>|&nbsp;/gi,
                        ''
                      )}
                      data-analytics-link-type="Engagement"
                      data-analytics-content-class="Content"
                      data-analytics-template-zone="Body"
                      data-analytics-component-name="Accordion Module"
                      data-analytics-component-variation="Collapsable Accordion"
                      data-analytics-target={item.heading?.jsonValue?.value?.replace(
                        /<\/?[^>]+>|&nbsp;/gi,
                        ''
                      )}
                      data-analytics-component-section="NAN"
                      data-analytics-slide-number={`Accordion item-${index}`}
                      data-analytics-ispersonalized="False"
                      data-analytics-iscarousal="False"
                      onClick={() => {
                        toggleAccordion(index);
                      }}
                      className={`accordion-with-images__item-header ${
                        isAccordionOpen === index ? 'accordion-with-images__item-header--open' : ''
                      }`}
                    >
                      <div
                        className={`accordion-with-images__header-container ${
                          isAccordionOpen === index
                            ? 'accordion-with-images__header-container--open'
                            : ''
                        }`}
                      >
                        <div
                          className="accordion-with-images__image-container"
                          onClick={(e) => {
                            stopPropagation(e, index);
                          }}
                        >
                          <DynamicNextImageWithNullFallback
                            field={item.image?.jsonValue}
                            width={264}
                            height={275}
                            params={{
                              styles: 'accordion-with-images__image next-image--square',
                            }}
                          />
                        </div>
                        <div
                          className="accordion-with-images__header-text-container"
                          onKeyUp={(e) => {
                            if (e.code === 'Space' && editingMode) {
                              e.preventDefault();
                            }
                          }}
                          onClick={(e) => {
                            stopPropagation(e, index);
                          }}
                        >
                          <div className="accordion-with-images__text-container">
                            <RichText
                              tabIndex={0}
                              tag="div"
                              className="accordion-with-images__item-title h4"
                              field={item.heading?.jsonValue}
                            />
                            <RichText
                              tabIndex={0}
                              tag="p"
                              className={`accordion-with-images__description subheading1`}
                              field={item.subheading?.jsonValue}
                            />
                            <RichText
                              tabIndex={0}
                              tag="div"
                              className={`body2 accordion-with-images__item-text`}
                              field={item.content?.jsonValue}
                            />
                            <div
                              onClick={(e) => handleChildElementClick(e)}
                              className="accordion-with-images__read-more-container"
                            >
                              <DynamicTertiary
                                params={{}}
                                fields={{ Link: item.link?.jsonValue }}
                              ></DynamicTertiary>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-with-images__icon-container">
                        {isAccordionOpen === index ? (
                          <DynamicIcon
                            icon={IconName.Substract}
                            iconLabel={'Close'}
                            additionalClass={'accordion-with-images__icon'}
                          />
                        ) : (
                          <DynamicIcon
                            icon={IconName.Add}
                            iconLabel={'Open'}
                            additionalClass={'accordion-with-images__icon'}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </LazyLoadComponent>
      </>
    );
  }

  return <AccordionComponent />;
};

export const AccordionComponent = (): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  return (
    <React.Fragment>
      {editingMode && (
        <div className={`accordion component--with-paddings`}>
          <span className="is-empty-hint">Accordion</span>
        </div>
      )}
    </React.Fragment>
  );
};
