import {
  ImageField,
  WithSitecoreContextProps,
  withSitecoreContext,
  Field,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import Head from 'next/head';
import React, { useState } from 'react';
import sitecoreStyles from 'src/helpers/sitecoreStyles';

interface Fields {
  id: string;
  template: {
    id: string;
    name: string;
  };
  title: {
    value: string;
  };
  language: {
    name: string;
  };
  metaTitle?: {
    value?: string;
  };
  metaDescription?: {
    value?: string;
  };
  clientLogo?: {
    jsonValue: ImageField;
  };
  referenceTitle?: {
    value?: string;
  };
  referenceText?: {
    value?: string;
  };
  referenceImage?: {
    jsonValue: ImageField;
  };
  referenceCategory?: {
    targetItems?: [
      {
        title?: {
          value?: string;
        };
      }
    ];
  };
  excludeFromSearch?: {
    boolValue?: boolean;
  };
  sxaTags?: {
    targetItems?: {
      title?: {
        jsonValue: Field<string>;
      };
    };
  };

  startDateTime?: {
    jsonValue: Field<string>;
  };
  endDateTime?: {
    jsonValue: Field<string>;
  };
  postedDate?: {
    jsonValue: Field<string>;
  };
}

type CoveoMetadataProps = {
  params: { [key: string]: string };
  fields?: {
    data: {
      contextItem: Fields;
    };
  };
} & WithSitecoreContextProps;

// https://docs.coveo.com/en/3158/index-content/sitemap-source-json-modification
// https://docs.coveo.com/en/3158/index-content/sitemap-source-json-modification#indexhtmlmetadata-boolean
const Default = (props: CoveoMetadataProps): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  const page = props.fields?.data?.contextItem;

  if (!page) {
    console.log('---->[CoveoMetadata] Component failed to load.', props.fields);
    return <></>;
  }

  const title = page.title?.value ?? page.metaTitle?.value ?? page.referenceTitle?.value;
  const description = page.metaDescription?.value ?? page?.referenceText?.value;
  const searchable = !(page.excludeFromSearch?.boolValue || false);

  const referenceTitle = page.referenceTitle?.value;
  const referenceText = page.referenceText?.value;
  const referenceImage = page.referenceImage?.jsonValue?.value?.src;
  const referenceCategory = page.referenceCategory?.targetItems;
  const referenceCategoryTitles = referenceCategory?.map((ele) => ele.title?.value);
  const clientLogo = page.clientLogo?.jsonValue?.value?.src;

  const pageeventstartdate = page.startDateTime?.jsonValue?.value;
  const pageeventenddate = page.endDateTime?.jsonValue?.value;
  const pageposteddate = page.postedDate?.jsonValue?.value;
  const pageyear = pageposteddate && new Date(pageposteddate).getFullYear();

  // const Tags = page.sxaTags?.targetItems?.title?.jsonValue.value;

  console.log('---->[CoveoMetadata] Component', page);
  const renderMetaTag = (name: string, value?: string) => {
    if (value) return <meta name={name} content={value} />;
    return <></>;
  };
  const referenceCategoryTags: { name: string; value: string }[] = [];
  referenceCategoryTitles?.forEach((ele) => {
    if (ele) {
      referenceCategoryTags.push({
        name: 'avanade_page_category',
        value: ele,
      });
    }
  });

  return (
    <>
      {editingMode && props.sitecoreContext.route?.templateName == 'Partial Design' ? (
        <div className={`is-metadata-hint ${sitecoreStyles(props.params?.styles)}`}>
          <strong>Metadata:</strong> Coveo meta data
        </div>
      ) : (
        <>
          <Head>
            {renderMetaTag('coveobot', 'all')}
            {renderMetaTag('avanade_src', 'xmcloud')}
            {renderMetaTag('avanade_lang_locale', page.language.name)}
            {renderMetaTag('avanade_page_id', page.id)}
            {renderMetaTag('avanade_page_type_id', page.template.id)}
            {renderMetaTag('avanade_page_type_name', page.template.name)}
            {renderMetaTag('avanade_page_title', title)}
            {renderMetaTag('avanade_page_desc', description)}
            {referenceCategoryTags.length > 0 &&
              referenceCategoryTags.map((tag, index) => {
                return <meta key={index} name={tag.name} content={tag.value} />;
              })}

            {renderMetaTag('avanade_searchable', searchable.toString())}
            {renderMetaTag('avanade_clientpage_logo', clientLogo)}
            {renderMetaTag('avanade_page_reftitle', referenceTitle)}
            {renderMetaTag('avanade_page_reftext', referenceText)}
            {renderMetaTag('avanade_page_refimage', referenceImage)}

            {renderMetaTag('avanade_page_eventstartdate', pageeventstartdate)}
            {renderMetaTag('avanade_page_eventenddate', pageeventenddate)}
            {renderMetaTag('avanade_page_posteddate', pageposteddate)}
            {pageyear && pageyear > 1990 && renderMetaTag('avanade_page_year', pageyear.toString())}
            {/* {renderMetaTag('avanade_page_tags', Tags)} */}
          </Head>
        </>
      )}
    </>
  );
};

export default withSitecoreContext()(Default);
