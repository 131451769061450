import { EmblaOptionsType } from 'embla-carousel';
import {
  Field,
  ImageField,
  LinkField,
  ComponentParams,
  ComponentRendering,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { CarouselCard } from 'components/carousel/partials/carousel-content/CarouselContent';
import { useState } from 'react';
import config from 'temp/config';
import { EmblaCarousel } from 'components/shared/embla-carousel/EmblaCarousel';
import { Default as CarouselCardComponent } from 'components/carousel/partials/carousel-card/CarouselCard';
import NextImageWithNullFallback from 'components/shared/nextImg/NextImageWithNullFallback';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import React from 'react';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import { TagFields } from 'src/types/Tag';
import dynamic from 'next/dynamic';
import LazyLoadComponent from 'components/shared/lazy-load/LazyLoadComponent';

export type CarouselSlideProps = {
  id: string;
  slideTitle?: {
    jsonValue: Field<string>;
  };
  slideSubTitle?: {
    jsonValue: Field<string>;
  };
  slideText?: {
    jsonValue: Field<string>;
  };
  slideImage?: {
    jsonValue: ImageField;
  };
  slideLink: {
    jsonValue: LinkField;
  };
  slideBGImage?: {
    jsonValue: ImageField;
  };
  contentLabel?: {
    targetItem: TagFields;
  };
  hideContentLabel?: {
    jsonValue: Field<boolean>;
  };
};

export interface CarouselDatasource {
  id: string;
  title: {
    jsonValue: Field<string>;
  };
  backgroundImage: {
    jsonValue: ImageField;
  };
  children: {
    results: CarouselSlideProps[];
  };
}

interface Fields {
  data: {
    datasource: CarouselDatasource;
  };
}

type CarouselProps = {
  rendering: ComponentRendering & { params: ComponentParams };
  params: { [key: string]: string; styles: string };
  fields: Fields;
  options?: EmblaOptionsType;
  children?: React.ReactNode[];
};

const mapper = (graphQlData: CarouselSlideProps[] | undefined): CarouselCard[] | undefined => {
  return graphQlData?.map<CarouselCard>((slide) => {
    return {
      area: slide?.slideSubTitle?.jsonValue,
      title: slide?.slideTitle?.jsonValue,
      body: slide?.slideText?.jsonValue,
      link: slide?.slideLink?.jsonValue,
      img: slide?.slideImage?.jsonValue,
      backgroundSlideImg: slide?.slideBGImage?.jsonValue,
      contentLabel: slide?.contentLabel,
      hideContentLabel: slide?.hideContentLabel,
    };
  });
};

const Warning = () => {
  return (
    <div style={{ color: '#fa0000' }}>
      Warning! Cards limit has been exceeded. The Carousel can have from 2 to 7 cards.
    </div>
  );
};

export const Default = (props: CarouselProps): JSX.Element => {
  console.log('---->[Carousel] Component', props.fields?.data?.datasource);
  const datasource = props.fields?.data?.datasource;
  const id = props.params?.RenderingIdentifier;

  const [editingMode] = useState(isEditorActive());

  const editingmode = isEditorActive();

  // Static and Dynamic import for DynamicCarousel
  let DynamicCarousel;
  if (editingmode) {
    // Static import when in editing mode

    DynamicCarousel =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/carousel/partials/carousel-content/CarouselContent').Default;
  } else {
    // Dynamic import when not in editing mode
    DynamicCarousel = dynamic(
      () =>
        import('components/carousel/partials/carousel-content/CarouselContent').then(
          (mod) => mod.Default
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }

  if (datasource) {
    return (
      <>
        {editingMode &&
          (datasource?.children?.results.length < 2 ||
            datasource?.children?.results.length > 7) && <Warning />}
        <DynamicCarousel
          slides={mapper(datasource?.children?.results)}
          options={props.options}
          id={id}
        ></DynamicCarousel>
      </>
    );
  }

  return <CarouselDefaultComponent {...props} />;
};

export const FullBleed = (props: CarouselProps): JSX.Element => {
  console.log('---->[Carousel] Component', props.fields?.data?.datasource);
  const datasource = props.fields?.data?.datasource;
  const [editingMode] = useState(isEditorActive());

  const editingmode = isEditorActive();
  let DynamicFullBleedCarousel;

  if (editingmode) {
    // Static import when in editing mode

    DynamicFullBleedCarousel =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/carousel/variants/full-bleed/FullBleedContent').Default;
  } else {
    // Dynamic import when not in editing mode
    DynamicFullBleedCarousel = dynamic(
      () =>
        import('components/carousel/variants/full-bleed/FullBleedContent').then(
          (mod) => mod.Default
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }

  return (
    <>
      {editingMode &&
        (datasource?.children?.results.length < 2 || datasource?.children?.results.length > 7) && (
          <Warning />
        )}
      <DynamicFullBleedCarousel
        params={props.params}
        fields={{
          cardsData: mapper(datasource?.children?.results),
          backgroundImg: datasource?.backgroundImage?.jsonValue,
        }}
      />
    </>
  );
};

export const FullBlogBleed = (props: CarouselProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const datasource = props.fields?.data?.datasource;
  const [editingMode] = useState(isEditorActive());
  const cards = mapper(datasource?.children?.results);
  return (
    <>
      {editingMode &&
        (datasource?.children?.results.length < 2 || datasource?.children?.results.length > 7) && (
          <Warning />
        )}

      <div
        className={`full-bleed-blog ${sitecoreStyles(props.params?.styles)}`}
        id={id ? id : undefined}
      >
        <div
          className={`full-bleed-blog__background ${
            editingMode ? 'full-bleed-blog__background--edit' : ''
          }`}
        >
          <NextImageWithNullFallback
            field={datasource?.backgroundImage?.jsonValue}
            width={1728}
            height={740}
            params={{
              styles: 'full-bleed-blog__background-image',
            }}
          ></NextImageWithNullFallback>
        </div>
        <div
          className={`full-bleed-blog__carousel ${
            editingMode ? 'full-bleed-blog__carousel--edit' : ''
          }`}
        >
          {cards && (
            <EmblaCarousel className={'blog-carousel'} options={{ loop: true, watchDrag: false }}>
              {cards?.map((value, index) => {
                return (
                  <CarouselCardComponent
                    key={index}
                    params={{
                      name: 'link__button-primary',
                      styles: editingMode
                        ? 'full-bleed-blog__card editing-mode'
                        : 'full-bleed-blog__card',
                    }}
                    fields={value}
                  ></CarouselCardComponent>
                );
              })}
            </EmblaCarousel>
          )}
        </div>
      </div>
    </>
  );
};

export const Evidence = (props: CarouselProps): JSX.Element => {
  console.log('---->[Carousel] Evidence Component', props);
  const datasource = props.fields?.data?.datasource;
  const [editingMode] = useState(isEditorActive());

  const editingmode = isEditorActive();

  let DynamicEvidenceCarousel;

  if (editingmode) {
    // Static import when in editing mode

    DynamicEvidenceCarousel =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/carousel/variants/evidence/Evidence').EvidenceCarousel;
  } else {
    // Dynamic import when not in editing mode
    DynamicEvidenceCarousel = dynamic(
      () =>
        import('components/carousel/variants/evidence/Evidence').then(
          (mod) => mod.EvidenceCarousel
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }
  return (
    <LazyLoadComponent componentId="Evidence">
      {editingMode &&
        (datasource?.children?.results.length < 2 || datasource?.children?.results.length > 7) && (
          <Warning />
        )}
      <DynamicEvidenceCarousel
        id={props?.fields?.data?.datasource?.id}
        slides={mapper(datasource?.children?.results)}
        params={props.params}
      />
    </LazyLoadComponent>
  );
};

export const FullBleedVideo = (props: CarouselProps): JSX.Element => {
  console.log('---->[Carousel] Component', props.fields?.data?.datasource);
  const datasource = props.fields?.data?.datasource;
  const [editingMode] = useState(isEditorActive());

  const editingmode = isEditorActive();

  let DynamicFullBleedVideoCarousel;

  if (editingmode) {
    // Static import when in editing mode

    DynamicFullBleedVideoCarousel =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/carousel/variants/full-bleed-video/FullBleedVideo').Default;
  } else {
    // Dynamic import when not in editing mode
    DynamicFullBleedVideoCarousel = dynamic(
      () =>
        import('components/carousel/variants/full-bleed-video/FullBleedVideo').then(
          (mod) => mod.Default
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }
  return (
    <>
      {editingMode &&
        (datasource?.children?.results.length < 2 || datasource?.children?.results.length > 7) && (
          <Warning />
        )}
      <DynamicFullBleedVideoCarousel
        params={props.params}
        fields={{
          cardsData: mapper(datasource?.children?.results),
          backgroundImg: datasource?.backgroundImage?.jsonValue,
        }}
        rendering={props.rendering}
      />
    </>
  );
};

// //animated carousel variant
export const FullBleedAnimated = (props: CarouselProps): JSX.Element => {
  console.log('---->[Full Bleed Animated Carousel] Component', props.fields?.data?.datasource);
  const datasource = props.fields?.data?.datasource;
  const multiImagesBool = Boolean(props.params['MultiImages'] || false);
  const displayNextCardBool = Boolean(props.params['DisplayNextCard'] || false);
  const [editingMode] = useState(isEditorActive());
  console.log(
    '---->[Full Bleed Animated Carousel] Component',
    props.fields?.data?.datasource + 'params' + multiImagesBool + ' ' + displayNextCardBool
  );

  const editingmode = isEditorActive();

  let DynamicAnimatedCarousel;

  if (editingmode) {
    // Static import when in editing mode

    DynamicAnimatedCarousel =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('./variants/full-bleed-animated/FullBleedAnimated').AnimatedCarousel;
  } else {
    // Dynamic import when not in editing mode
    DynamicAnimatedCarousel = dynamic(
      () =>
        import('./variants/full-bleed-animated/FullBleedAnimated').then(
          (mod) => mod.AnimatedCarousel
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }

  return (
    <LazyLoadComponent componentId="FullBleedAnimated">
      {editingMode &&
        (datasource?.children?.results.length < 2 || datasource?.children?.results.length > 7) && (
          <Warning />
        )}
      <DynamicAnimatedCarousel
        params={props.params}
        fields={{
          cardsData: mapper(datasource?.children?.results),
          backgroundImg: datasource?.backgroundImage?.jsonValue,
          multiImages: multiImagesBool,
          displayNextCard: displayNextCardBool,
        }}
      />
    </LazyLoadComponent>
  );
};

export const CarouselDefaultComponent = (props: CarouselProps): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  return (
    <React.Fragment>
      {editingMode && (
        <div className={`full-bleed-blog-carousel ${sitecoreStyles(props.params?.styles)}`}>
          <span className="is-empty-hint">Carousel</span>
        </div>
      )}
    </React.Fragment>
  );
};
