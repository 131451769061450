import React, { useState } from 'react';
import { Text, Link, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { EmployeeProfileFields, getSocialMediaIcon, parseKeyValuePairs } from '../EmployeeProfile';
import NextImageWithNullFallback from 'components/shared/nextImg/NextImageWithNullFallback';
import { Icon } from 'components/shared/icon/Icon';
import { IconName } from 'src/types/Enum';
import { useI18n } from 'next-localization';

export const EmployeeProfileCard = (props: EmployeeProfileFields): JSX.Element => {
  const { t } = useI18n();
  const [showMore, setShowMore] = useState(false);
  const [editingMode] = useState(isEditorActive());

  const socialNetworks = parseKeyValuePairs(props.SocialNetworks?.value); // Possible key values: facebook, linkedin, youtube
  return (
    <div className={`double-accordion-card`}>
      <div className="double-accordion-card__content">
        <div className={'double-accordion-card__avatar'}>
          <NextImageWithNullFallback
            field={props.Picture}
            width={264}
            height={275}
            params={{
              styles: 'double-accordion-card__avatar-picture next-image--circle',
            }}
          ></NextImageWithNullFallback>
        </div>
        <div className={'double-accordion-card__description-container'}>
          <div className={'double-accordion-card__header'}>
            <Text tag="h4" className={'double-accordion-card__name'} field={props.FullName}></Text>
            <Text
              tag="div"
              className={'double-accordion-card__position subheading1'}
              field={props.Role}
            ></Text>
            {socialNetworks.length > 0 && (
              <div className={'double-accordion-card__social'}>
                {socialNetworks.map((media) => (
                  <Link
                    key={media.type}
                    field={{ value: { href: media.link } }}
                    className="ga-track"
                    data-analytics-link-name={`${media?.link}`}
                    data-analytics-link-type="Link"
                    data-analytics-content-class="Content"
                    data-analytics-template-zone="Body"
                    data-analytics-component-name="Employee Profile Card"
                    data-analytics-component-variation="NAN"
                    data-analytics-target={`${media.link}`}
                    data-analytics-component-section="NAN"
                    data-analytics-slide-number="NAN"
                    data-analytics-ispersonalized="False"
                    data-analytics-iscarousal="False"
                  >
                    {getSocialMediaIcon(media.type)}
                  </Link>
                ))}
              </div>
            )}
          </div>
          <div className="double-accordion-card__bio">
            <RichText
              tag="div"
              className={`double-accordion-card__short-bio ${
                showMore ? 'double-accordion-card__short-bio--hide' : ''
              }  body2`}
              field={props.ShortBio}
            />
            <RichText
              tag="div"
              className={`double-accordion-card__long-bio ${
                showMore ? '' : 'double-accordion-card__long-bio--hide'
              }  body2`}
              field={props.Bio}
            />
          </div>
          {(props.Bio.value || editingMode) && (
            <div className="double-accordion-card__show-more-container">
              <button onClick={() => setShowMore(!showMore)} aria-label="Show more/Show less">
                <div className="double-accordion-card__button-container subheading1">
                  <div>
                    {showMore
                      ? t('component-double-accordion-show-less')
                      : t('component-double-accordion-show-more')}
                  </div>
                  <div className="double-accordion-card__icon-container">
                    <Icon
                      icon={showMore ? IconName.ArrowUp : IconName.ArrowDown}
                      iconLabel={'Show more/Show less'}
                    />
                  </div>
                </div>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
