import React, { useCallback, useEffect, useState } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { EmblaOptionsType } from 'embla-carousel';

//component properties

type CarouselProps = {
  children: React.ReactNode[];
  options?: EmblaOptionsType;
  className?: string;
  type?: string;
};

//default variant

export const EmblaCarousel = ({
  children,
  options,
  className,
  type,
}: CarouselProps): JSX.Element => {
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const scrollPrev = useCallback(() => {
    emblaApi && emblaApi.scrollPrev();
  }, [emblaApi]);
  const scrollNext = useCallback(() => {
    emblaApi && emblaApi.scrollNext();
  }, [emblaApi]);
  const scrollTo = useCallback((index: number) => emblaApi && emblaApi.scrollTo(index), [emblaApi]);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
    setPrevBtnEnabled(emblaApi.canScrollPrev());
    setNextBtnEnabled(emblaApi.canScrollNext());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emblaApi, selectedIndex]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    setScrollSnaps(emblaApi.scrollSnapList());
    emblaApi.on('select', onSelect);
    emblaApi.on('reInit', onSelect);
  }, [emblaApi, setScrollSnaps, onSelect]);

  return (
    <div className={`embla-carousel ${className}`}>
      <div className="embla-carousel__viewport" ref={emblaRef}>
        <div className="embla-carousel__slide-container">
          {children?.map((child, index) => (
            <div
              className={`embla-carousel__slide ${
                selectedIndex === index ? '' : 'embla-carousel__slide--hide'
              }`}
              key={index}
            >
              {child}
            </div>
          ))}
        </div>
      </div>
      {children?.length > 1 && (
        <div
          className={`embla-carousel__dots ${
            type === 'centerPagination' ? 'embla-carousel__dots--center' : ''
          }`}
        >
          <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              selected={index === selectedIndex}
              onClick={() => scrollTo(index)}
            />
          ))}
          <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
        </div>
      )}
    </div>
  );
};

type DotButtonPropType = {
  selected: boolean;
  onClick: () => void;
};

const DotButton: React.FC<DotButtonPropType> = (props) => {
  const { selected, onClick } = props;

  return (
    <button
      className={'embla-carousel__dot'.concat(selected ? ' embla-carousel__dot--selected' : '')}
      type="button"
      onClick={onClick}
      aria-label="dot"
    />
  );
};

type PrevNextButtonPropType = {
  enabled: boolean;
  onClick: () => void;
};

export const PrevButton: React.FC<PrevNextButtonPropType> = (props) => {
  const { enabled, onClick } = props;

  return (
    <button
      data-testid="previous"
      aria-label="previous"
      className="embla-carousel__button-carousel embla-carousel__button-carousel--prev"
      onClick={onClick}
      disabled={!enabled}
    >
      <svg
        width="11"
        height="20"
        viewBox="0 0 11 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.4571 19.2071C10.0666 19.5976 9.43342 19.5976 9.04289 19.2071L0.292893 10.4571C0.105357 10.2696 -1.1593e-08 10.0152 0 9.75C1.1593e-08 9.48478 0.105357 9.23043 0.292893 9.04289L9.04289 0.292893C9.43342 -0.0976311 10.0666 -0.0976311 10.4571 0.292893C10.8476 0.683418 10.8476 1.31658 10.4571 1.70711L2.41421 9.75L10.4571 17.7929C10.8476 18.1834 10.8476 18.8166 10.4571 19.2071Z"
          fill="#333333"
        />
      </svg>
    </button>
  );
};

export const NextButton: React.FC<PrevNextButtonPropType> = (props) => {
  const { enabled, onClick } = props;

  return (
    <button
      data-testid="next"
      aria-label="next"
      className="embla-carousel__button-carousel embla-carousel__button-carousel--next"
      onClick={onClick}
      disabled={!enabled}
    >
      <svg
        width="11"
        height="20"
        viewBox="0 0 11 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.542893 0.292893C0.933418 -0.0976309 1.56658 -0.097631 1.95711 0.292893L10.7071 9.04289C10.8946 9.23043 11 9.48478 11 9.75C11 10.0152 10.8946 10.2696 10.7071 10.4571L1.95711 19.2071C1.56658 19.5976 0.933419 19.5976 0.542894 19.2071C0.15237 18.8166 0.15237 18.1834 0.542894 17.7929L8.58579 9.75L0.542893 1.70711C0.152369 1.31658 0.152369 0.683419 0.542893 0.292893Z"
          fill="#333333"
        />
      </svg>
    </button>
  );
};
