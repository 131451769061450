import React from 'react';
import { Field, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import { useScroll } from 'lib/contexts/ScrollContext';

type Fields = {
  Title: Field<string>;
};

type TitleReusableProps = {
  params: { [key: string]: string };
  fields?: Fields;
};

export const Default = (props: TitleReusableProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const tag = props.params['TitleTag'] || 'h2';
  const sectionRef = useScroll();

  return (
    <div
      className={`title-reusable component--with-paddings ${sitecoreStyles(props.params?.styles)}`}
      ref={sectionRef}
      id={id ? id : undefined}
    >
      <Text tag={tag} field={props.fields?.Title} />
    </div>
  );
};

export const GreyTitle = (props: TitleReusableProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const tag = props.params['TitleTag'] || 'h2';
  return (
    <div
      className={`grey-title-reusable ${sitecoreStyles(props.params?.styles)}`}
      id={id ? id : undefined}
    >
      <Text tag={tag} field={props.fields?.Title} />
    </div>
  );
};
