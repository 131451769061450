import React from 'react';
import {
  TextImageLeftSquareTimage,
  TimageProps,
} from 'components/timage/variants/text-image/TimageLeftSquare';
import sitecoreStyles from 'src/helpers/sitecoreStyles';

export const TextImageLeftSquareBlackH3Timage = (props: TimageProps): JSX.Element => {
  return (
    <div className={`timage-left-square-black-h3 ${sitecoreStyles(props.params?.styles)}`}>
      <TextImageLeftSquareTimage {...props} />
    </div>
  );
};
