import React, { useState } from 'react';
import { ComponentRendering, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { Tertiary } from 'components/link/Link';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import { RelatedEventCard } from './RelatedEventCard';
import { isBefore, startOfToday, subDays } from 'date-fns';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

export interface EventsCardsByTagPageField {
  referenceText?: string;
  referenceTitle?: string;
  url: string;
  contextTags: { [key: string]: string[] };
  startDate?: string;
  endDate?: string;
  hideTime?: string;
  eventType?: string;
  location?: string;
  isFeatured?: boolean;
  timezone?: string;
  twentyFourHour?: boolean;
}

type EventsCardsByTagProps = {
  params: { [key: string]: string; styles: string };
  fields: {
    title?: string;
    linkUrl?: string;
    linkText?: string;
    linkTarget?: string;
    cardsReferenceMap: EventsCardsByTagPageField[];
  };
  rendering: ComponentRendering;
};

// Default empty variant component

const EventsCardsByTagDefaultComponent = (props: EventsCardsByTagProps): JSX.Element => {
  const [editingMode] = useState(isEditorActive());

  return (
    <>
      {editingMode && (
        <div className={`component ${props.params.styles}`}>
          <div className="is-empty-hint">Event Cards By Tag</div>
        </div>
      )}
    </>
  );
};

export const Default = (props: EventsCardsByTagProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  console.log('[EventsCardsByTag]', props);
  const eventCards = props.fields?.cardsReferenceMap;
  if (!eventCards || eventCards.length === 0) {
    return <EventsCardsByTagDefaultComponent {...props} />;
  }
  const today = startOfToday();
  const twoDaysAgo = subDays(today, 2);
  const sortedEventCards = eventCards.sort((eventCardA, eventCardB) => {
    const eventCardAStartDate = eventCardA.startDate ? new Date(eventCardA.startDate) : null;
    const eventCardBStartDate = eventCardB.startDate ? new Date(eventCardB.startDate) : null;
    const eventCardAEndDate = eventCardA.endDate ? new Date(eventCardA.endDate) : null;
    const eventCardBEndDate = eventCardB.endDate ? new Date(eventCardB.endDate) : null;
    const isEventCardAFeatured = eventCardA.isFeatured ? 1 : 0;
    const isEventCardBFeatured = eventCardB.isFeatured ? 1 : 0;
    // Sort featured event cards first
    if (isEventCardAFeatured !== isEventCardBFeatured) {
      return isEventCardBFeatured - isEventCardAFeatured; // Featured event cards come first
    }
    const isEventCardAExpired = eventCardAStartDate && isBefore(eventCardAStartDate, twoDaysAgo);
    const isEventCardBExpired = eventCardBStartDate && isBefore(eventCardBStartDate, twoDaysAgo);
    if (isEventCardAExpired && isEventCardBExpired) {
      return 0; // Both expired, maintain order
    }
    if (isEventCardAExpired) {
      return 1;
    }
    if (isEventCardBExpired) {
      return -1;
    }
    if (!eventCardAStartDate && !eventCardBStartDate) {
      return 0;
    }
    if (!eventCardAStartDate) {
      return 1;
    }
    if (!eventCardBStartDate) {
      return -1;
    }
    if (!eventCardAEndDate && !eventCardBEndDate) {
      return 0;
    }
    if (!eventCardAEndDate) {
      return 1;
    }
    if (!eventCardBEndDate) {
      return -1;
    }
    if (eventCardAStartDate && eventCardBStartDate) {
      return eventCardAStartDate.getTime() - eventCardBStartDate.getTime();
    }
    return 0;
  });

  return (
    <div
      className={`events-cardsbytag component--with-paddings ${sitecoreStyles(
        props.params?.styles
      )}`}
      id={id ? id : undefined}
    >
      <div className="events-cardsbytag__container">
        <div className="events-cardsbytag__title">
          <Text tag="h3" field={{ value: props.fields?.title }} />
        </div>
        <div className="events-cardsbytag__cards-container">
          {sortedEventCards?.map((eventCard, index) => (
            <RelatedEventCard fields={eventCard} key={index} />
          ))}
        </div>
        {props?.fields?.linkUrl && (
          <div className="events-cardsbytag__cta-container">
            <Tertiary
              fields={{
                Link: {
                  value: {
                    href: props?.fields?.linkUrl,
                    text: props?.fields?.linkText,
                    target: props?.fields?.linkTarget,
                  },
                },
              }}
              params={{}}
            />
          </div>
        )}
      </div>
    </div>
  );
};
