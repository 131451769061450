import React, { useState } from 'react';
import {
  ImageField,
  LinkField,
  Field,
  ComponentParams,
  ComponentRendering,
  RichTextField,
} from '@sitecore-jss/sitecore-jss-nextjs';
import dynamic from 'next/dynamic';
import config from 'temp/config';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import { TagFields } from 'src/types/Tag';
import LazyLoadComponent from 'components/shared/lazy-load/LazyLoadComponent';

export interface BannerFields {
  title?: {
    jsonValue: Field<string>;
  };
  subTitle?: {
    jsonValue: Field<string>;
  };
  description?: {
    jsonValue: RichTextField;
  };
  link?: {
    jsonValue: LinkField;
  };
  image?: {
    jsonValue: ImageField;
  };
  backgroundImage: {
    jsonValue: ImageField;
  };
  contentLabel?: {
    targetItem: TagFields;
  };
  hideContentLabel?: {
    jsonValue: Field<boolean>;
  };
}
export type BannerProps = {
  rendering: ComponentRendering & { params: ComponentParams };
  params: { [key: string]: string; styles: string };
  fields?: {
    data: {
      datasource?: BannerFields;
    };
  };
};
//empty variant?
const DefaultBanner = (props: BannerProps): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  return (
    <React.Fragment>
      {editingMode && (
        <div className={`component ${sitecoreStyles(props.params?.styles)}`}>
          <span className="is-empty-hint">Banner</span>
        </div>
      )}
    </React.Fragment>
  );
};

//default variant
export const Default = (props: BannerProps): JSX.Element => {
  const editingmode = isEditorActive();

  let DynamicFullBleedBanner;

  if (editingmode) {
    // Static import when in editing mode

    DynamicFullBleedBanner =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('src/components/banner/variants/full-bleed/FullBleedBanner').FullBleedBanner;
  } else {
    // Dynamic import when not in editing mode
    DynamicFullBleedBanner = dynamic(
      () =>
        import('src/components/banner/variants/full-bleed/FullBleedBanner').then(
          (mod) => mod.FullBleedBanner
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }

  if (props.fields) {
    return (
      <>
        <DynamicFullBleedBanner fields={props.fields} params={props.params} />
      </>
    );
  }
  return <DefaultBanner {...props} />;
};

export const FullBleedBannerWhiteBackgroundWithTertiary = (props: BannerProps): JSX.Element => {
  const editingmode = isEditorActive();

  let DynamicFullBleedBannerTertiary;
  if (editingmode) {
    // Static import when in editing mode

    DynamicFullBleedBannerTertiary =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/banner/variants/full-bleed/FullBleedBannerTertiary').FullBleedBannerTertiary;
  } else {
    // Dynamic import when not in editing mode
    DynamicFullBleedBannerTertiary = dynamic(
      () =>
        import('components/banner/variants/full-bleed/FullBleedBannerTertiary').then(
          (mod) => mod.FullBleedBannerTertiary
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }
  if (props.fields) {
    return (
      <LazyLoadComponent componentId="FullBleedBannerWhiteBackgroundWithTertiary">
        <DynamicFullBleedBannerTertiary fields={props.fields} params={props.params} />
      </LazyLoadComponent>
    );
  }
  return <DefaultBanner {...props} />;
};

export const FullBleedBannerWhiteBackgroundMirrorWithTertiary = (
  props: BannerProps
): JSX.Element => {
  const editingmode = isEditorActive();

  let DynamicFullBleedBannerMirrorsTertiary;

  if (editingmode) {
    // Static import when in editing mode

    DynamicFullBleedBannerMirrorsTertiary =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/banner/variants/full-bleed/FullBleedBannerMirrorTertiary').FullBleedBannerMirrorsTertiary;
  } else {
    // Dynamic import when not in editing mode
    DynamicFullBleedBannerMirrorsTertiary = dynamic(
      () =>
        import('components/banner/variants/full-bleed/FullBleedBannerMirrorTertiary').then(
          (mod) => mod.FullBleedBannerMirrorsTertiary
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }

  if (props.fields) {
    return (
      <LazyLoadComponent componentId="FullBleedBannerWhiteBackgroundMirrorWithTertiary">
        <DynamicFullBleedBannerMirrorsTertiary fields={props.fields} params={props.params} />
      </LazyLoadComponent>
    );
  }
  return <DefaultBanner {...props} />;
};

export const FullBleedBannerVideoTertiaryLink = (props: BannerProps): JSX.Element => {
  const editingmode = isEditorActive();

  let DynamicFullBleedBannerVideoTertiary;

  if (editingmode) {
    // Static import when in editing mode

    DynamicFullBleedBannerVideoTertiary =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/banner/variants/full-bleed/FullBleedBannerVideoTertiary').FullBleedBannerVideoTertiary;
  } else {
    // Dynamic import when not in editing mode
    DynamicFullBleedBannerVideoTertiary = dynamic(
      () =>
        import('components/banner/variants/full-bleed/FullBleedBannerVideoTertiary').then(
          (mod) => mod.FullBleedBannerVideoTertiary
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }

  if (props.fields) {
    return (
      <LazyLoadComponent componentId="FullBleedBannerVideoTertiaryLink">
        <DynamicFullBleedBannerVideoTertiary
          fields={props.fields}
          params={props.params}
          rendering={props.rendering}
        />
      </LazyLoadComponent>
    );
  }
  return <DefaultBanner {...props} />;
};

export const FullBleedGreyBanner = (props: BannerProps): JSX.Element => {
  const editingmode = isEditorActive();

  let DynamicFullBleedGreyBanners;

  if (editingmode) {
    // Static import when in editing mode

    DynamicFullBleedGreyBanners =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('src/components/banner/variants/full-bleed/FullBleedGreyBanner').FullBleedGreyBanners;
  } else {
    // Dynamic import when not in editing mode
    DynamicFullBleedGreyBanners = dynamic(
      () =>
        import('src/components/banner/variants/full-bleed/FullBleedGreyBanner').then(
          (mod) => mod.FullBleedGreyBanners
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }

  if (props.fields) {
    return (
      <LazyLoadComponent componentId="FullBleedGreyBanner">
        <DynamicFullBleedGreyBanners fields={props.fields} params={props.params} />
      </LazyLoadComponent>
    );
  }
  return <DefaultBanner {...props} />;
};

export const FullBleedBannerVideo = (props: BannerProps): JSX.Element => {
  const editingmode = isEditorActive();

  let DynamicFullBleedBannerWithVideo;

  if (editingmode) {
    // Static import when in editing mode

    DynamicFullBleedBannerWithVideo =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/banner/variants/full-bleed/FullBleedBannerWithVideo').FullBleedBannerWithVideo;
  } else {
    // Dynamic import when not in editing mode
    DynamicFullBleedBannerWithVideo = dynamic(
      () =>
        import('components/banner/variants/full-bleed/FullBleedBannerWithVideo').then(
          (mod) => mod.FullBleedBannerWithVideo
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }
  if (props.fields) {
    return (
      <LazyLoadComponent componentId="FullBleedBannerVideo">
        <DynamicFullBleedBannerWithVideo
          fields={props.fields}
          params={props.params}
          rendering={props.rendering}
        />
      </LazyLoadComponent>
    );
  }
  return <DefaultBanner {...props} />;
};

export const FullBleedGrayVideoBannerMirror = (props: BannerProps): JSX.Element => {
  const editingmode = isEditorActive();

  let DynamicFullBleedBannerWithVideoGrey;

  if (editingmode) {
    // Static import when in editing mode

    DynamicFullBleedBannerWithVideoGrey =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/banner/variants/full-bleed/FullBleedGrayVideoBannerMirror').FullBleedBannerWithVideoGrey;
  } else {
    // Dynamic import when not in editing mode
    DynamicFullBleedBannerWithVideoGrey = dynamic(
      () =>
        import('components/banner/variants/full-bleed/FullBleedGrayVideoBannerMirror').then(
          (mod) => mod.FullBleedBannerWithVideoGrey
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }

  if (props.fields) {
    return (
      <LazyLoadComponent componentId="FullBleedGrayVideoBannerMirror">
        <DynamicFullBleedBannerWithVideoGrey
          fields={props.fields}
          params={props.params}
          rendering={props.rendering}
        />
      </LazyLoadComponent>
    );
  }
  return <DefaultBanner {...props} />;
};

export const FullBleedBannerWithVideoGrayTitle = (props: BannerProps): JSX.Element => {
  const editingmode = isEditorActive();

  let DynamicFullBleedBannerWithVideoGrayTitle;

  if (editingmode) {
    // Static import when in editing mode

    DynamicFullBleedBannerWithVideoGrayTitle =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/banner/variants/full-bleed/FullBleedBannerWithVideoGrayTitle').FullBleedBannerWithVideoGrayTitle;
  } else {
    // Dynamic import when not in editing mode
    DynamicFullBleedBannerWithVideoGrayTitle = dynamic(
      () =>
        import('components/banner/variants/full-bleed/FullBleedBannerWithVideoGrayTitle').then(
          (mod) => mod.FullBleedBannerWithVideoGrayTitle
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }

  if (props.fields) {
    return (
      <LazyLoadComponent componentId="FullBleedBannerWithVideoGrayTitle">
        <DynamicFullBleedBannerWithVideoGrayTitle
          fields={props.fields}
          params={props.params}
          rendering={props.rendering}
        />
      </LazyLoadComponent>
    );
  }
  return <DefaultBanner {...props} />;
};

export const FullBleedBannerMirror = (props: BannerProps): JSX.Element => {
  const editingmode = isEditorActive();

  let DynamicFullBleedBannerMirrors;

  if (editingmode) {
    // Static import when in editing mode

    DynamicFullBleedBannerMirrors =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/banner/variants/full-bleed/FullBleedBannerMirror').FullBleedBannerMirrors;
  } else {
    // Dynamic import when not in editing mode
    DynamicFullBleedBannerMirrors = dynamic(
      () =>
        import('components/banner/variants/full-bleed/FullBleedBannerMirror').then(
          (mod) => mod.FullBleedBannerMirrors
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }
  if (props.fields) {
    return (
      <LazyLoadComponent componentId="FullBleedBannerMirror">
        <DynamicFullBleedBannerMirrors
          fields={props.fields}
          params={props.params}
          rendering={props.rendering}
        />
      </LazyLoadComponent>
    );
  }
  return <DefaultBanner {...props} />;
};

export const FullBleedGrayBannerMirror = (props: BannerProps): JSX.Element => {
  const editingmode = isEditorActive();

  let DynamicFullBleedBannerGreyMirror;

  if (editingmode) {
    // Static import when in editing mode

    DynamicFullBleedBannerGreyMirror =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/banner/variants/full-bleed/FullBleedGrayBannerMirror').FullBleedBannerGreyMirror;
  } else {
    // Dynamic import when not in editing mode
    DynamicFullBleedBannerGreyMirror = dynamic(
      () =>
        import('components/banner/variants/full-bleed/FullBleedGrayBannerMirror').then(
          (mod) => mod.FullBleedBannerGreyMirror
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }

  if (props.fields) {
    return (
      <LazyLoadComponent componentId="FullBleedGrayBannerMirror">
        <DynamicFullBleedBannerGreyMirror
          fields={props.fields}
          params={props.params}
          rendering={props.rendering}
        />
      </LazyLoadComponent>
    );
  }
  return <DefaultBanner {...props} />;
};

export const FullBleedStatement = (props: BannerProps): JSX.Element => {
  const editingmode = isEditorActive();

  // For the dynamic import to be used correctly:
  let DynamicStatementBanner;

  if (editingmode) {
    // Static import when in editing mode

    DynamicStatementBanner =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/banner/variants/statement/Statement').StatementBanner;
  } else {
    // Dynamic import when not in editing mode
    DynamicStatementBanner = dynamic(
      () =>
        import('components/banner/variants/statement/Statement').then((mod) => mod.StatementBanner),
      { ssr: !!config.dynamicImportSsr }
    );
  }

  if (props.fields) {
    return (
      <LazyLoadComponent componentId="FullBleedStatement">
        <DynamicStatementBanner fields={props.fields} params={props.params} />
      </LazyLoadComponent>
    );
  }
  return <DefaultBanner {...props} />;
};

export const SectionHeader1 = (props: BannerProps): JSX.Element => {
  const editingmode = isEditorActive();

  let DynamicSectionHeader1;

  if (editingmode) {
    // Static import when in editing mode

    DynamicSectionHeader1 =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/banner/variants/section-header/SectionHeaderPrimary').SectionHeader1;
  } else {
    // Dynamic import when not in editing mode
    DynamicSectionHeader1 = dynamic(
      () =>
        import('components/banner/variants/section-header/SectionHeaderPrimary').then(
          (mod) => mod.SectionHeader1
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }

  if (props.fields) {
    return (
      <>
        <DynamicSectionHeader1 fields={props.fields} params={props.params} />
      </>
    );
  }
  return <DefaultBanner {...props} />;
};

export const SectionHeader2 = (props: BannerProps): JSX.Element => {
  const editingmode = isEditorActive();

  let DynamicSectionHeader2;

  if (editingmode) {
    // Static import when in editing mode

    DynamicSectionHeader2 =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/banner/variants/section-header/SectionHeaderSecondary').SectionHeader2;
  } else {
    // Dynamic import when not in editing mode
    DynamicSectionHeader2 = dynamic(
      () =>
        import('components/banner/variants/section-header/SectionHeaderSecondary').then(
          (mod) => mod.SectionHeader2
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }
  if (props.fields) {
    return (
      <>
        <DynamicSectionHeader2 fields={props.fields} params={props.params} />
      </>
    );
  }
  return <DefaultBanner {...props} />;
};

export const SectionHeader3 = (props: BannerProps): JSX.Element => {
  const editingmode = isEditorActive();

  let DynamicSectionHeader3;

  if (editingmode) {
    // Static import when in editing mode

    DynamicSectionHeader3 =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/banner/variants/section-header/SectionHeaderTertiary').SectionHeader3;
  } else {
    // Dynamic import when not in editing mode
    DynamicSectionHeader3 = dynamic(
      () =>
        import('components/banner/variants/section-header/SectionHeaderTertiary').then(
          (mod) => mod.SectionHeader3
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }
  if (props.fields) {
    return (
      <LazyLoadComponent componentId="SectionHeader3">
        <DynamicSectionHeader3 fields={props.fields} params={props.params} />
      </LazyLoadComponent>
    );
  }
  return <DefaultBanner {...props} />;
};

export const Title = (props: BannerProps): JSX.Element => {
  const editingmode = isEditorActive();

  let DynamicTitleBanner;

  if (editingmode) {
    // Static import when in editing mode
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicTitleBanner = require('components/banner/variants/title-banner/TitleBanner').TitleBanner;
  } else {
    // Dynamic import when not in editing mode
    DynamicTitleBanner = dynamic(
      () =>
        import('components/banner/variants/title-banner/TitleBanner').then(
          (mod) => mod.TitleBanner
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }
  if (props.fields) {
    return (
      <>
        <DynamicTitleBanner fields={props.fields} params={props.params} />
      </>
    );
  }
  return <DefaultBanner {...props} />;
};

export const TextLeftImageRight = (props: BannerProps): JSX.Element => {
  const editingmode = isEditorActive();

  let DynamicTextImageRight;

  if (editingmode) {
    // Static import when in editing mode

    DynamicTextImageRight =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/banner/variants/text-image/TextImageRight').TextImageRight;
  } else {
    // Dynamic import when not in editing mode
    DynamicTextImageRight = dynamic(
      () =>
        import('components/banner/variants/text-image/TextImageRight').then(
          (mod) => mod.TextImageRight
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }
  if (props.fields) {
    return (
      <>
        <DynamicTextImageRight fields={props.fields} params={props.params} />
      </>
    );
  }
  return <DefaultBanner {...props} />;
};

export const TextRightImageLeft = (props: BannerProps): JSX.Element => {
  const editingmode = isEditorActive();

  let DynamicTextImageLeft;

  if (editingmode) {
    // Static import when in editing mode
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicTextImageLeft = require('./variants/text-image/TextImageLeft').TextImageLeft;
  } else {
    // Dynamic import when not in editing mode
    DynamicTextImageLeft = dynamic(
      () => import('./variants/text-image/TextImageLeft').then((mod) => mod.TextImageLeft),
      { ssr: !!config.dynamicImportSsr }
    );
  }
  if (props.fields) {
    return (
      <>
        <DynamicTextImageLeft fields={props.fields} params={props.params} />
      </>
    );
  }
  return <DefaultBanner {...props} />;
};
