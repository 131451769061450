import React from 'react';
import { ImageField, LinkField, RichText, Text, Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { Tertiary } from 'components/link/Link';
import { NextImageWithNullFallback } from 'components/shared/nextImg/NextImageWithNullFallback';
import { SectionHeroLogoLines } from 'components/logo-lines/LogoLines';
import { SearchField } from 'components/search-field/SearchField';
import { TagFields } from 'src/types/Tag';
interface Fields {
  title: {
    jsonValue: Field<string>;
  };
  body?: {
    jsonValue: Field<string>;
  };
  link: {
    jsonValue: LinkField;
  };
  image?: {
    jsonValue: ImageField;
  };
  contentLabel?: {
    targetItem: TagFields;
  };
  hideContentLabel?: {
    jsonValue: Field<boolean>;
  };
}

type SectionHeaderProps = {
  params: { [key: string]: string };
  fields?: {
    data: {
      datasource?: Fields;
    };
  };
};

export const Default = (props: SectionHeaderProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;

  return (
    <div className={`section-hero ${props.params.styles}`} id={id ? id : undefined}>
      <div className="section-hero__content">
        <div className="section-hero__text-container">
          <Text
            tag="h1"
            className="section-hero__title"
            field={props.fields?.data.datasource?.title?.jsonValue}
          />
          <RichText
            tag="div"
            className="section-hero__paragraph body2"
            field={props.fields?.data.datasource?.body?.jsonValue}
          />
          {props.fields?.data.datasource?.link?.jsonValue && (
            <div className="section-hero__link">
              <Tertiary
                fields={{ Link: props.fields?.data.datasource?.link?.jsonValue }}
                params={{}}
              ></Tertiary>
            </div>
          )}
        </div>

        <NextImageWithNullFallback
          loading="eager"
          field={props.fields?.data.datasource?.image?.jsonValue}
          // width={360}
          // height={720}
          fill
          sizes="(max-width: 1200px) 100vw, (min-width: 1201px) 50vw, 33vw"
          params={{
            styles: 'section-hero__image-container  next-image--1-2',
          }}
          priority
        />
        <SectionHeroLogoLines />
      </div>
    </div>
  );
};

export const SubPageHeroWithSearch = (props: SectionHeaderProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;

  return (
    <div className={`section-hero ${props.params.styles}`} id={id ? id : undefined}>
      <div className="section-hero__content">
        <div className="section-hero__text-container">
          <Text
            tag="h1"
            className="section-hero__title"
            field={props.fields?.data.datasource?.title?.jsonValue}
          />
          <RichText
            tag="div"
            className="section-hero__paragraph body2"
            field={props.fields?.data.datasource?.body?.jsonValue}
          />
          {props.fields?.data.datasource?.link?.jsonValue && (
            <SearchField fields={{ Link: props.fields?.data.datasource?.link?.jsonValue }} />
          )}
        </div>

        <NextImageWithNullFallback
          loading="eager"
          field={props.fields?.data.datasource?.image?.jsonValue}
          fill
          sizes="(max-width: 1200px) 100vw, (min-width: 1201px) 13vw, 33vw"
          params={{
            styles: 'section-hero__image-container  next-image--1-2',
          }}
          priority
        />
        <SectionHeroLogoLines />
      </div>
    </div>
  );
};
