import React, { useState } from 'react';
import { ComponentRendering, Text, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { removeColFromStylesProps } from 'src/helpers/sitecoreStyles';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { useI18n } from 'next-localization';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import config from 'temp/config';
import dynamic from 'next/dynamic';
export interface CardsByTagPageField {
  referenceImage?: string;
  referenceIcon?: string;
  referenceText?: string;
  referenceTitle?: string;
  contentType?: string;
  url: string;
  contextTags: { [key: string]: string[] };
}

type CardsByTagProps = {
  params: { [key: string]: string; styles: string };
  fields: {
    title?: string;
    linkUrl?: string;
    linkText?: string;
    linkTarget?: string;
    cardsReferenceMap: CardsByTagPageField[];
    //Tags: string[];
  };
  rendering: ComponentRendering;
};

export const Default = (props: CardsByTagProps): JSX.Element => {
  const { t } = useI18n();
  const [editingMode] = useState(isEditorActive());
  const id = props.params.RenderingIdentifier;

  console.log('[CardsByTag]', props);

  const cardsReferenceMap = props.fields?.cardsReferenceMap;
  const linkField = {
    value: {
      href: props.fields?.linkUrl,
      text: props.fields?.linkText,
    },
  };

  if (!cardsReferenceMap || cardsReferenceMap.length === 0) {
    return <CardsByTagDefaultComponent {...props} />;
  }

  const editingmode = isEditorActive();

  let DynamicTagWithIconLeft;

  // Static import when in editing mode
  if (editingmode) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicTagWithIconLeft = require('components/tag/Tag').TagWithIconLeft;
  } else {
    // Dynamic import when not in editing mode
    DynamicTagWithIconLeft = dynamic(
      () => import('components/tag/Tag').then((mod) => mod.TagWithIconLeft),
      { ssr: !!config.dynamicImportSsr }
    );
  }

  let DynamicTertiary;

  // Static import when in editing mode
  if (editingmode) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicTertiary = require('components/link/Link').Tertiary;
  } else {
    // Dynamic import when not in editing mode
    DynamicTertiary = dynamic(() => import('components/link/Link').then((mod) => mod.Tertiary), {
      ssr: !!config.dynamicImportSsr,
    });
  }

  return (
    <div
      className={`cards--default component--with-paddings ${sitecoreStyles(props.params?.styles)}`}
      id={id ? id : undefined}
    >
      <div className="cards--default__container">
        <div className="cards--default__title">
          <Text tag="h3" field={{ value: props.fields?.title }} />
        </div>

        <div className="cards--default__cards-container">
          {cardsReferenceMap?.map((card, index) => {
            if (!card) {
              return null;
            }

            const title = card.referenceTitle ?? '';
            const contentType = card.contentType ?? '';
            const icon = card.referenceIcon ?? '';
            const text = card.referenceText ?? '';
            const url = card.url ?? '';

            const handleClick = () => {
              if (url) window.open(url, '_blank');
            };
            const handleChildElementClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              e.stopPropagation();
            };
            return (
              <>
                <div
                  className={`card cardByTag-card cardByTag-card__default-container ${removeColFromStylesProps(
                    props.params.styles
                  )} `}
                  onClick={!editingMode ? handleClick : () => null}
                >
                  <div key={index} className="cardByTag-card__text-container">
                    {((icon && contentType) || editingMode) && (
                      <div className="body2 cardByTag-card__tag">
                        <DynamicTagWithIconLeft
                          fields={{
                            Title: { value: contentType },
                            Icon: { value: { src: icon } },
                          }}
                          params={{}}
                        ></DynamicTagWithIconLeft>
                      </div>
                    )}

                    <Text
                      tag="div"
                      className="body1 cardByTag-card__title"
                      field={{ value: title }}
                    />
                    <RichText
                      tag="div"
                      className="body2 cardByTag-card__body"
                      field={{ value: text }}
                    />
                  </div>
                  <div onClick={(e) => handleChildElementClick(e)}>
                    {url && (
                      <DynamicTertiary
                        fields={{
                          Link: { value: { href: url, text: t('component-cardsbytag-readmore') } },
                        }}
                        params={{}}
                      ></DynamicTertiary>
                    )}
                  </div>
                </div>
              </>
            );
          })}
        </div>

        {props?.fields?.linkUrl && (
          <div className="cards--default__cta-container">
            <DynamicTertiary fields={{ Link: linkField }} params={{}}></DynamicTertiary>
          </div>
        )}
      </div>
    </div>
  );
};

export const CardsByTagDefaultComponent = (props: CardsByTagProps): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  return (
    <>
      {editingMode && (
        <div
          className={`cards--default__container component--with-paddings ${sitecoreStyles(
            props.params?.styles
          )}`}
        >
          <span className="is-empty-hint">Cards By Tag</span>
        </div>
      )}
    </>
  );
};
