import React, { useState } from 'react';
import { WithSitecoreContextProps, withSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import Head from 'next/head';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import sitecoreStyles from 'src/helpers/sitecoreStyles';

export interface TaxonomyMetadataComponentFields {
  tags: { [key: string]: string[] };
}

type TaxonomyMetadataComponentProps = {
  params: { [key: string]: string };
  fields: TaxonomyMetadataComponentFields;
} & WithSitecoreContextProps;

const Default = (props: TaxonomyMetadataComponentProps) => {
  console.log('---->[Taxonomy] Component', props.fields);
  const [editingMode] = useState(isEditorActive());
  const fields = props.fields;
  const taxonomyTags: { name: string; value: string }[] = [];
  if (fields) {
    Object.entries(fields).forEach(([key, value]) => {
      // temporary changes for coveo mapping change
      if (key == 'avanade_taxonomy_contenttype' && value?.length > 0) {
        taxonomyTags.push({
          name: 'avanade_page_contenttype',
          value: value[0],
        });
      }
      if (key == 'avanade_taxonomy_eventtype' && value?.length > 0) {
        taxonomyTags.push({
          name: 'avanade_page_eventtype',
          value: value[0],
        });
      }
      const tags: string[] = value;
      tags.forEach((tag) => {
        taxonomyTags.push({
          name: key.toLowerCase(),
          value: tag,
        });
      });
    });
  }
  return (
    <>
      {editingMode && props.sitecoreContext.route?.templateName == 'Partial Design' ? (
        <div className={`is-metadata-hint ${sitecoreStyles(props.params?.styles)}`}>
          <strong>Metadata:</strong> Taxonomy
        </div>
      ) : (
        <Head>
          <React.Fragment>
            {taxonomyTags.length > 0 &&
              taxonomyTags.map((tag, index) => {
                return <meta key={index} name={tag.name} content={tag.value} />;
              })}
          </React.Fragment>
        </Head>
      )}
    </>
  );
};

export default withSitecoreContext()(Default);
