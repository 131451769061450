import React from 'react';
import { useI18n } from 'next-localization';

//import sitecoreStyles from 'src/helpers/sitecoreStyles';

export interface ReadingTimeFields {
  readingTime: {
    value: string;
  };
}
export type ReadingTimeProps = {
  params: { [key: string]: string };
  fields?: {
    data: {
      contextItem?: ReadingTimeFields;
    };
  };
};

export const Default = (props: ReadingTimeProps) => {
  console.log('---->[ReadingTimeProps] Component', props.fields);
  const { t } = useI18n();
  return (
    <>
      <div className="estimated-time">
        {props.fields && props.fields?.data?.contextItem?.readingTime && (
          <>
            <span className="estimated-read__time"> {t('estimated-read-time')}:</span>
            <span className="estimated-time__value">
              {' '}
              {props.fields?.data?.contextItem?.readingTime?.value}{' '}
              {props.fields?.data?.contextItem?.readingTime?.value == '1'
                ? t('reading-time-minute')
                : t('reading-time-minutes')}
            </span>
          </>
        )}
      </div>
    </>
  );
};
