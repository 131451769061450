import React, { useState } from 'react';
import { ImageField, Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { PageUrl } from 'src/types/PageUrl';
import { SeoItemFields } from 'src/types/SeoSeo';
import dynamic from 'next/dynamic';
import { useI18n } from 'next-localization';
import config from 'temp/config';

type SocialShareDataSource = PageUrl & SeoItemFields;

export interface SocialShareFields {
  facebookIcon?: {
    jsonValue: ImageField;
  };
  linkedinIcon?: {
    jsonValue: ImageField;
  };
  mailIcon?: {
    jsonValue: ImageField;
  };
  text?: {
    jsonValue: Field<string>;
  };
}

export type SocialShareProps = {
  params: { [key: string]: string };
  fields?: {
    data: {
      datasource: SocialShareFields;
      context: SocialShareDataSource;
    };
  };
};

export const Share = (props: SocialShareProps): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  const { t } = useI18n();
  const url = props.fields?.data.context?.url.url;
  const mailUrl = `mailto:?subject=${props.fields?.data.context?.metaTitle.value} &body=${props.fields?.data.context?.metaDescription?.value} - ${url}`;
  const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`;
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
  const emailClickAction = (e: React.MouseEvent) => {
    if (editingMode) {
      e.preventDefault();
    }
  };
  const newWindowClickAction = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    if (!editingMode) {
      window.open(
        e.currentTarget.href,
        '',
        'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600'
      );
    }
  };

  const isEmptyBlock = !props?.fields?.data?.datasource ? 'hide-block' : '';

  return (
    <div className={`socialShare ${isEmptyBlock}`}>
      <span className="Share__title">{t('Share')}</span>
      {renderSocialIcon(
        mailUrl,
        props.fields?.data.datasource?.mailIcon?.jsonValue,
        emailClickAction
      )}
      {renderSocialIcon(
        linkedinUrl,
        props.fields?.data.datasource?.linkedinIcon?.jsonValue,
        newWindowClickAction
      )}
      {renderSocialIcon(
        facebookUrl,
        props.fields?.data.datasource?.facebookIcon?.jsonValue,
        newWindowClickAction
      )}
    </div>
  );
};

const renderSocialIcon = (
  url: string,
  iconField: ImageField | undefined,

  onClickAction: React.MouseEventHandler
) => {
  // const iseditor = isEditorActive();
  // const DynamicNextImageWithNullFallback = iseditor
  //   ? // eslint-disable-next-line @typescript-eslint/no-var-requires
  //     require('components/shared/nextImg/NextImageWithNullFallback').NextImageWithNullFallback
  //   : dynamic(
  //       () =>
  //         import('components/shared/nextImg/NextImageWithNullFallback').then(
  //           (mod) => mod.NextImageWithNullFallback
  //         ),
  //       { ssr: !!config.dynamicImportSsr }
  //     );

  const editingmode = isEditorActive(); // Determines whether it's in editing mode

  // For the dynamic import to be used correctly:
  let DynamicNextImageWithNullFallback;

  if (editingmode) {
    // Static import when in editing mode

    DynamicNextImageWithNullFallback =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/shared/nextImg/NextImageWithNullFallback').NextImageWithNullFallback;
  } else {
    // Dynamic import when not in editing mode
    DynamicNextImageWithNullFallback = dynamic(
      () =>
        import('components/shared/nextImg/NextImageWithNullFallback').then(
          (mod) => mod.NextImageWithNullFallback
        ),
      { ssr: !!config.dynamicImportSsr } // Adjust server-side rendering (ssr: true) based on your config
    );
  }

  if (url && iconField?.value?.src) {
    return (
      <div className="socialShare__share-icon">
        <a
          href={url || ''}
          onClick={onClickAction}
          target="_blank"
          aria-label={(iconField?.value?.alt as string) || 'Image'}
        >
          <DynamicNextImageWithNullFallback
            field={iconField}
            width={32}
            height={32}
            params={{
              styles: 'socialShare__image',
            }}
          ></DynamicNextImageWithNullFallback>
        </a>
      </div>
    );
  }
  return null;
};
export const Default = (props: SocialShareProps): JSX.Element => {
  console.log('---->[SocialShare] Component', props.fields);

  if (props.fields) return <Share {...props} />;
  return <SocialShareDefaultComponent />;
};

export const SocialShareDefaultComponent = (): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  return (
    <>
      {editingMode && (
        <div className="social-share">
          <div className="social-share__icon"></div>
          <div className="social-share__text"></div>
        </div>
      )}
    </>
  );
};
