/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { SearchEngineContext } from 'components/search/search-context/SearchContext';
import { buildResultList, loadFieldActions } from '@coveo/atomic-react';
import parse from 'html-react-parser';
import { SearchResultsProps } from 'components/search/search-results/SearchResults';
import { Tertiary } from 'components/link/Link';
import { useI18n } from 'next-localization';
import { Loader } from 'components/shared/loader/Loader';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { NoResults } from 'components/search/search-results/SearchResults';
import { useBookmark } from 'lib/contexts/BookmarkContext';
import { useSearchFacet } from 'lib/contexts/SearchFacetContext';
import { useRouter } from 'next/router';
import { useLink } from 'lib/contexts/AllLinksContext';
import { useSearchJob } from 'lib/contexts/SearchJobContext';
import LazyLoadComponent from 'components/shared/lazy-load/LazyLoadComponent';

export const JobsSearchResults = (props: SearchResultsProps): JSX.Element => {
  console.log('job search result', props);

  const searchScope = props.params.SearchScope;
  const { getSearchEngine } = React.useContext(SearchEngineContext);
  const searchEngine = getSearchEngine(searchScope);
  // const [isLoading, setIsLoading] = useState(true);
  const fieldActions = loadFieldActions(searchEngine); //need a SC param
  const includeFields = fieldActions.registerFieldsToInclude([
    'job_id',
    'jobareaofexpertise',
    'jobcountry',
    'title',
    'jobdescription',
  ]);
  searchEngine.dispatch(includeFields);
  const [headlessResultList] = useState(buildResultList(searchEngine));
  const [headlessResultListState, setheadlessResultList] = useState(headlessResultList.state);
  const [editingMode] = useState(isEditorActive());
  const { toggleBookmark, bookmarkedCards } = useBookmark();
  const { filterValue, toggleSearchFacet } = useSearchFacet();
  const { addSearchJobData } = useSearchJob();
  const { t } = useI18n();
  const urlName = useRouter();
  const { data } = useLink();
  useEffect(() => {
    headlessResultList.subscribe(() => {
      setheadlessResultList(headlessResultList.state);
      const searchData = { searchResultLength: Number(headlessResultList.state.results.length) };
      addSearchJobData(searchData);
      window.location.hash = window.location.hash.replaceAll('25', '');
    });
  }, [searchEngine, urlName.asPath]);

  useEffect(() => {
    const location = window.location.hash;
    localStorage.setItem('urllink', location);
  }, [window.location.hash]);

  useEffect(() => {
    const searchData = { searchResultLength: Number(headlessResultListState.results.length) };
    addSearchJobData(searchData);
  }, [headlessResultList.state.results]);
  useEffect(() => {
    if (urlName.asPath.includes('search-jobs')) {
      toggleSearchFacet(false);
    }
  }, [filterValue]);

  // useEffect(() => {
  //   // Subscribe to search engine state changes to track loading state
  //   const unsubscribe = searchEngine.subscribe(() => {
  //     setIsLoading(searchEngine.state.search.isLoading);
  //   });
  //   return () => {
  //     unsubscribe();
  //   };
  // }, [searchEngine]);

  const results = headlessResultListState.results;

  const handleBookmarkClick = (cardId: number) => {
    toggleBookmark(cardId);
  };
  if (
    !editingMode &&
    headlessResultListState.results.length == 0 &&
    headlessResultListState.firstSearchExecuted
  ) {
    return <NoResults isCareerJobs={true} />;
  }
  return (
    <>
      {/* <div className={`search-results ${sitecoreStyles(props.params?.styles)}`}> */}

      <div className="jobCardContainer">
        {searchEngine.state.search.isLoading && <Loader></Loader>}

        {results.map((result) => {
          const readMoreLink =
            window.location.origin + data.jobDetails.value.href + '/' + String(result.raw.job_id);
          return (
            <>
              <div
                key={String(result.raw.job_id)}
                // className={`card insight-card job-card insight-card__default-container`}
                className="jobCard"
                id={result.raw.job_id?.toString()}
                onClick={() => {
                  window.open(readMoreLink, '_self');
                }}
              >
                <div className="insight-card__text-container">
                  <div className="jobCardTag">
                    <LazyLoadComponent componentId="JobsSearchResults">
                      <div>
                        <p className="jobCardSubTitle">
                          {result.raw.jobareaofexpertise?.toString()}
                        </p>

                        <div
                          id={result.raw.jobid?.toString()}
                          // style={bookmarkStyle(String(result.raw.job_id))}
                          className={`bookmarkIcon ${
                            bookmarkedCards.includes(Number(result.raw.job_id))
                              ? 'bookmarkIconSelected'
                              : 'bookmarkIconNotSelected'
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleBookmarkClick(Number(result.raw.jobid));
                          }}
                          data-analytics-link-name="Bookmark Job Card"
                          data-analytics-link-type="Bookmark"
                          data-analytics-content-class="Job Card"
                          data-analytics-template-zone="Body"
                          data-analytics-component-name="Job Card Bookmark"
                          data-analytics-component-variation="Card"
                          data-analytics-target={result.raw.jobid?.toString()}
                          data-analytics-component-section="Job Card"
                          data-analytics-slide-number="NAN"
                          data-analytics-ispersonalized="False"
                          data-analytics-iscarousal="False"
                          data-analytics-job-title={result.raw.title?.toString()}
                          data-analytics-job-region={result.raw.jobcountry?.toString()}
                          data-analytics-job-sub-title={result.raw.jobareaofexpertise?.toString()}
                          data-analytics-job-id={result.raw.jobid?.toString()}
                        >
                          {' '}
                        </div>
                      </div>
                    </LazyLoadComponent>
                  </div>
                  <div className="jobCardTitle">{result.raw.title?.toString()}</div>
                  <p className="jobCardCountry">
                    {result.raw.jobcountry?.toString()?.replaceAll('^', ', ')}
                  </p>
                  <div className={'jobCardBody'}>
                    {String(result.raw.jobdescription) && parse(String(result.raw.jobdescription))}
                  </div>
                </div>
                <div>
                  <div className="tertiaryLink">
                    <Tertiary
                      fields={{
                        Link: {
                          value: {
                            text: t(`component-contentgallery-readmore`),
                            href: readMoreLink,
                          },
                        },
                      }}
                      params={{}}
                    ></Tertiary>
                    <LazyLoadComponent componentId="shareIcon">
                      <div className="shareIconClass">
                        <SocialBlockModal
                          shareLinkUrls={readMoreLink}
                          shareLinkTitle={result.title}
                        />
                      </div>
                    </LazyLoadComponent>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

interface propTypeShareLinkUrls {
  shareLinkUrls: string;
  shareLinkTitle?: string;
}

const SocialBlockModal = (props: propTypeShareLinkUrls) => {
  // Share functionality - Start--------------------------
  const [visibility, setvisibility] = useState(false);
  // const [hostName, sethostName] = useState('');
  // useEffect(() => {
  //   if (typeof window != 'undefined') {
  //     const getHostName = 'https://' + window.location.hostname;
  //     sethostName(getHostName);
  //   }
  // }, []);
  const openshare = () => {
    setvisibility(true);
  };
  const closeshare = () => {
    setvisibility(false);
  };
  const encodedTitle = encodeURIComponent(props.shareLinkTitle || 'Default Title');
  const encodedUrl = encodeURIComponent(props.shareLinkUrls);

  const mailtoLink = `mailto:?subject=${encodedTitle} via Avanade&body='${encodedTitle}' at ${encodedUrl}`;
  return (
    <>
      <div
        className="shareIcon"
        style={{ height: '24px', width: '24px' }}
        onClick={(e) => {
          openshare();
          e.stopPropagation();
        }}
      ></div>
      <div
        className={'shareOptions ' + (visibility ? 'visible' : '')}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <a
          href={mailtoLink}
          className="ga-track social-sharing-analytics"
          target="_blank"
          rel="noopener noreferrer"
          data-analytics-link-name="Social Share"
          data-analytics-link-type="Email"
          data-analytics-content-class="Content"
          data-analytics-template-zone="Body"
          data-analytics-component-name="Image Molecule"
          data-analytics-component-variation="NAN"
          data-analytics-target={props.shareLinkUrls}
          data-analytics-component-section="NAN"
          data-analytics-slide-number="NAN"
          data-analytics-ispersonalized="False"
          data-analytics-iscarousal="False"
          data-analytics-social-media-type="Email"
        >
          <i className="icon icon--email icon--28" aria-label="email"></i>
        </a>
        <a
          href="javascript:void(0);"
          className="ga-track social-sharing-analytics"
          target="_blank"
          rel="noopener noreferrer"
          data-analytics-link-name="Social Share"
          data-analytics-link-type="Facebook"
          data-analytics-content-class="Content"
          data-analytics-template-zone="Body"
          data-analytics-component-name="Image Molecule"
          data-analytics-component-variation="NAN"
          data-analytics-target={props.shareLinkUrls}
          data-analytics-component-section="NAN"
          data-analytics-slide-number="NAN"
          data-analytics-ispersonalized="False"
          data-analytics-iscarousal="False"
          data-analytics-social-media-type="Facebook"
          onClick={() => {
            window.open(
              'https://www.facebook.com/sharer/sharer.php?u=' + props.shareLinkUrls,
              'popupwindow',
              'scrollbars=yes,width=600,height=600'
            );
          }}
        >
          <i className="icon icon--facebook icon--24" aria-label="Facebook"></i>
        </a>

        <a
          href="javascript:void(0);"
          className="ga-track social-sharing-analytics"
          target="_blank"
          rel="noopener noreferrer"
          data-analytics-link-name="Social Share"
          data-analytics-link-type="LinkedIn"
          data-analytics-content-class="Content"
          data-analytics-template-zone="Body"
          data-analytics-component-name="Image Molecule"
          data-analytics-component-variation="NAN"
          data-analytics-target={props.shareLinkUrls}
          data-analytics-component-section="NAN"
          data-analytics-slide-number="NAN"
          data-analytics-ispersonalized="False"
          data-analytics-iscarousal="False"
          data-analytics-social-media-type="LinkedIn"
          onClick={() => {
            window.open(
              'https://www.linkedin.com/shareArticle?url=' + props.shareLinkUrls,
              'popupwindow',
              'scrollbars=yes,width=600,height=600'
            );
          }}
        >
          <i className="icon icon--linkedin icon--24" aria-label="Linkedin"></i>
        </a>

        <a>
          <i
            className="icon icon--dismiss icon--24"
            aria-label="Close"
            onClick={(e) => {
              closeshare();
              e.stopPropagation();
            }}
          ></i>
        </a>
      </div>
    </>
  );
};
