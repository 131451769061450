/**
 * This Layout is needed for Starter Kit.
 */
import React from 'react';
import Head from 'next/head';
import { Placeholder, LayoutServiceData, Field, HTMLLink } from '@sitecore-jss/sitecore-jss-nextjs';
import Scripts from 'src/Scripts';
import { NextPlaceholder } from 'components/next-placeholder/NextPlaceholder';
import { NavigationContextProvider } from 'components/navigation/NavigationContext';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/next';
import config from 'temp/config';
import LazyLoadComponent from 'components/shared/lazy-load/LazyLoadComponent';

interface LayoutProps {
  layoutData: LayoutServiceData;
  headLinks: HTMLLink[];
}

interface RouteFields {
  [key: string]: unknown;
  Title?: Field;
  //pageTitle: Field;
}

const Layout = ({ layoutData, headLinks }: LayoutProps): JSX.Element => {
  const { route } = layoutData.sitecore;
  const fields = route?.fields as RouteFields;
  const isPageEditing = layoutData.sitecore.context.pageEditing;
  const mainClassPageEditing = isPageEditing ? 'editing-mode' : 'prod-mode';
  const uniquePageIdentifier = route?.itemId?.replace('-', '')?.toLocaleLowerCase();
  return (
    <>
      <Scripts />
      <Head>
        <title>{fields?.Title?.value?.toString() || 'Page'}</title>
        {headLinks?.map((headLink) => (
          <link rel={headLink.rel} key={headLink.href} href={headLink.href} />
        ))}
      </Head>

      {/* root placeholder for the app, which we add components to using route data */}
      <div className={`${mainClassPageEditing} page-${uniquePageIdentifier}`}>
        <NextPlaceholder name="root-start" />
        <header>
          <NextPlaceholder name="before-header" />
          <NavigationContextProvider>
            <div id="header" className="header">
              {route && <Placeholder name="headless-header" rendering={route} />}
            </div>
          </NavigationContextProvider>
          <NextPlaceholder name="after-header" />
        </header>
        <main>
          <NextPlaceholder name="before-main" />
          <div id="content">{route && <Placeholder name="headless-main" rendering={route} />}</div>
          <NextPlaceholder name="after-main" />
        </main>
        <footer>
          <NextPlaceholder name="before-footer" />
          <LazyLoadComponent componentId="footer">
            <div id="footer" className="footer">
              {route && <Placeholder name="headless-footer" rendering={route} />}
            </div>
          </LazyLoadComponent>
          <NextPlaceholder name="after-footer" />
        </footer>
        <NextPlaceholder name="root-end" />
      </div>
      {config.vercelAnalyticsEnabled == 'true' && <Analytics />}
      <SpeedInsights />
    </>
  );
};

export default Layout;
