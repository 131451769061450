import React from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { Default as PrimaryButton } from 'components/link/Link';
import { BannerFields } from 'components/banner/Banner';
import sitecoreStyles from 'src/helpers/sitecoreStyles';

type SectionHeaderProps = {
  params: { [key: string]: string; styles: string };
  fields?: {
    data: {
      datasource?: BannerFields;
    };
  };
};

export const SectionHeader3 = (props: SectionHeaderProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const titleTag = props.params['TitleTag'] || 'h2';
  return (
    <div
      className={`section-header-tertiary component--with-paddings ${sitecoreStyles(
        props.params?.styles
      )}`}
      id={id ? id : undefined}
    >
      <div className="section-header-tertiary__main-container">
        <div className="section-header-tertiary__title-container">
          <Text
            tag={titleTag}
            className="section-header-tertiary__subtitle"
            field={props.fields?.data.datasource?.subTitle?.jsonValue}
          />
        </div>
        <div className="section-header-tertiary__description-container">
          <Text
            tag="h4"
            className="section-header-tertiary__title"
            field={props.fields?.data.datasource?.title?.jsonValue}
          />
          <RichText
            tag="div"
            className="section-header-tertiary__description body2"
            field={props.fields?.data.datasource?.description?.jsonValue}
          />
          {props.fields?.data.datasource?.link?.jsonValue && (
            <div className="section-header-default__link">
              <PrimaryButton
                params={{ styles: 'link__button-primary' }}
                fields={{ Link: props.fields?.data.datasource?.link?.jsonValue }}
              ></PrimaryButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
