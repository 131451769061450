import React, { useEffect, useState } from 'react';
import { Text, RichText, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { FormFields } from '../Form';
import { useI18n } from 'next-localization';
import { Tertiary } from 'components/link/Link';
import { useNextPlaceholderContext } from 'lib/contexts/NextPlaceholderContext';
import { useRouter } from 'next/router';
import { Icon } from 'components/shared/icon/Icon';
import { IconName } from 'src/types/Enum';
import { HeartWave } from 'components/logo-lines/LogoLines';
import { AssessmentResultFields } from '../../survey-assessment/Assessment';
import { SurveyResult } from '../../survey-assessment/SurveyResult';

type FormSuccessProps = {
  setShow: (state: boolean) => void;
  show: boolean;
  isPopUp: Element | null | undefined;
  // parameters for survey assessment marketo form
  isSurveyForm?: boolean;
  surveyResult?: AssessmentResultFields;
  retake?: () => void;
  ctalink?: LinkField;
} & FormFields;

export const FormSuccess = (props: FormSuccessProps): JSX.Element => {
  const { t } = useI18n();
  const { setContentFor } = useNextPlaceholderContext();
  const [show, setShow] = useState(false);
  const router = useRouter();

  function handleClick() {
    setContentFor('before-footer', <></>);
    document.getElementsByTagName('html')[0].removeAttribute('class');
    props.setShow(false);
  }

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  useEffect(() => {
    router.events.on('routeChangeStart', () => {
      setShow(false);
      document.querySelector('.form-submit')?.classList.remove('form-submit');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const successBox = document.querySelector(`#form${props.FormId.value}-success`);
    if (successBox)
      document
        .querySelector(`#form${props.FormId.value}`)
        ?.closest('section')
        ?.classList.add('form-submit');
    successBox?.scrollIntoView();
  });

  // drop cookie here with path specific value for survey assessment
  useEffect(() => {
    window.addEventListener('CookieState', () => {
      const funcCookieState = localStorage.getItem('CookieState');
      if (funcCookieState && funcCookieState == 'false') {
        //Cookies are allowed
        if (show && props.isSurveyForm) {
          document.cookie = `surveyformpage=${window.location.pathname}; max-age=31556926; path=${window.location.pathname}`; //1 year in seconds
        }
      }
    });
  }, [show, props.isSurveyForm]);

  return (
    <>
      {show && (
        <div>
          {props.isSurveyForm ? (
            props.surveyResult &&
            props.retake &&
            props.ctalink && (
              <SurveyResult
                props={props.surveyResult}
                retake={props.retake}
                ctalink={props.ctalink}
              />
            )
          ) : (
            <div
              id={`form${props.FormId.value}-success`}
              className={`success ${
                props.isPopUp !== null ? 'success--modal' : 'success--inline'
              } `}
            >
              <div className="success__main-container">
                {props.isPopUp && (
                  <button
                    onClick={() => handleClick()}
                    className="success__close-btn-container"
                    aria-label={t('component-form-close')}
                  >
                    {t('component-form-close')}
                    <Icon icon={IconName.Dismiss} iconLabel={'Close'} />
                  </button>
                )}
                <div className="success__container">
                  <Text tag="h1" className="success__title" field={props.FormSuccessTitle} />
                  <RichText
                    tag="div"
                    className="body2 success__message"
                    field={props.FormSuccessMessage}
                  />
                  {props.FormSuccessLink?.value.href && props.FormSuccessLink?.value.text && (
                    <div className="ga-track success__link">
                      <Tertiary params={{}} fields={{ Link: props.FormSuccessLink }} />
                    </div>
                  )}
                </div>
              </div>
              <div className="success__background-container">
                <HeartWave />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
