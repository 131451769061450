import React, { useState } from 'react';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

export const AssessmentComponent = (): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  return (
    <>
      {editingMode && (
        <div className={`accordion component--with-paddings`}>
          <span className="is-empty-hint">Assessment</span>
        </div>
      )}
    </>
  );
};
