import React, { useState } from 'react';
import {
  Field,
  RichTextField,
  ImageField,
  LinkField,
  ComponentParams,
  ComponentRendering,
} from '@sitecore-jss/sitecore-jss-nextjs';
import config from 'temp/config';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import dynamic from 'next/dynamic';
import LazyLoadComponent from 'components/shared/lazy-load/LazyLoadComponent';

//sharepoint interface (??)
export interface TimageFields {
  Title?: Field<string>;
  SubTitle?: Field<string>;
  Text?: RichTextField;
  Link?: LinkField;
  Image?: ImageField;
}

//component properties
export type TimageProps = {
  rendering: ComponentRendering & { params: ComponentParams };
  params: { [key: string]: string; styles: string };
  fields?: TimageFields;
};
let counter = 0;
const TimageDefaultComponent = (props: TimageProps): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  return (
    <React.Fragment>
      {editingMode && (
        <div className={`component ${sitecoreStyles(props.params?.styles)}`}>
          <div className="component-content">Timage</div>
        </div>
      )}
    </React.Fragment>
  );
};

const editingmode = isEditorActive();
let DynamicTextImageLeftTimage;
if (editingmode) {
  DynamicTextImageLeftTimage =
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    require('components/timage/variants/text-image/TimageLeft').TextImageLeftTimage;
} else {
  // Dynamic import when not in editing mode
  DynamicTextImageLeftTimage = dynamic(
    () =>
      import('components/timage/variants/text-image/TimageLeft').then(
        (mod) => mod.TextImageLeftTimage
      ),
    { ssr: !!config.dynamicImportSsr }
  );
}
//default variant
export const Default = (props: TimageProps): JSX.Element => {
  counter++;
  if (props.fields) {
    return (
      <LazyLoadComponent componentId={`TextImageLeftTimage${counter}`}>
        <DynamicTextImageLeftTimage
          fields={props.fields}
          params={props.params}
          variant={'default'}
        />
      </LazyLoadComponent>
    );
  }
  return <TimageDefaultComponent {...props} />;
};

export const TimageLeft = (props: TimageProps): JSX.Element => {
  counter++;
  if (props.fields) {
    return (
      <LazyLoadComponent componentId={`TimageLeft${counter}`}>
        <DynamicTextImageLeftTimage
          fields={props.fields}
          params={props.params}
          variant={'default'}
        />
      </LazyLoadComponent>
    );
  }
  return <TimageDefaultComponent {...props} />;
};

export const TimageLeftSquare = (props: TimageProps): JSX.Element => {
  let DynamicTextImageLeftSquareTimage;
  if (editingmode) {
    DynamicTextImageLeftSquareTimage =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/timage/variants/text-image/TimageLeftSquare').TextImageLeftSquareTimage;
  } else {
    // Dynamic import when not in editing mode
    DynamicTextImageLeftSquareTimage = dynamic(
      () =>
        import('components/timage/variants/text-image/TimageLeftSquare').then(
          (mod) => mod.TextImageLeftSquareTimage
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }
  counter++;
  if (props.fields) {
    return (
      <LazyLoadComponent componentId={`TimageLeftSquare${counter}`}>
        <DynamicTextImageLeftSquareTimage
          fields={props.fields}
          params={props.params}
          variant={'square'}
        />
      </LazyLoadComponent>
    );
  }
  return <TimageDefaultComponent {...props} />;
};

export const TimageLeftSquareBlackH3 = (props: TimageProps): JSX.Element => {
  let DynamicTextImageLeftSquareBlackH3Timage;
  if (editingmode) {
    DynamicTextImageLeftSquareBlackH3Timage =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('./variants/text-image/TimageLeftSquareBlackH3').TextImageLeftSquareBlackH3Timage;
  } else {
    DynamicTextImageLeftSquareBlackH3Timage = dynamic(
      () =>
        import('./variants/text-image/TimageLeftSquareBlackH3').then(
          (mod) => mod.TextImageLeftSquareBlackH3Timage
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }
  counter++;
  if (props.fields) {
    return (
      <LazyLoadComponent componentId={`TimageLeftSquareBlackH3${counter}`}>
        <DynamicTextImageLeftSquareBlackH3Timage
          fields={props.fields}
          params={props.params}
          variant={'square'}
        />
      </LazyLoadComponent>
    );
  }
  return <TimageDefaultComponent {...props} />;
};

export const TimageRightSquareBlackH3 = (props: TimageProps): JSX.Element => {
  let DynamicTextImageRightSquareBlackH3Timage;
  if (editingmode) {
    DynamicTextImageRightSquareBlackH3Timage =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('./variants/text-image/TimageRightSquareBlackH3').TextImageRightSquareBlackH3Timage;
  } else {
    DynamicTextImageRightSquareBlackH3Timage = dynamic(
      () =>
        import('./variants/text-image/TimageRightSquareBlackH3').then(
          (mod) => mod.TextImageRightSquareBlackH3Timage
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }
  counter++;
  if (props.fields) {
    return (
      <LazyLoadComponent componentId={`TimageRightSquareBlackH3${counter}`}>
        <DynamicTextImageRightSquareBlackH3Timage
          fields={props.fields}
          params={props.params}
          variant={'square'}
        />
      </LazyLoadComponent>
    );
  }
  return <TimageDefaultComponent {...props} />;
};

export const TimageLeftQuote = (props: TimageProps): JSX.Element => {
  let DynamicTextImageLeftWithQuoteTimage;
  if (editingmode) {
    DynamicTextImageLeftWithQuoteTimage =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/timage/variants/text-image/TimageLeftWithQuote').TextImageLeftWithQuoteTimage;
  } else {
    DynamicTextImageLeftWithQuoteTimage = dynamic(
      () =>
        import('components/timage/variants/text-image/TimageLeftWithQuote').then(
          (mod) => mod.TextImageLeftWithQuoteTimage
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }
  counter++;
  if (props.fields) {
    return (
      <LazyLoadComponent componentId={`TimageLeftQuote${counter}`}>
        <DynamicTextImageLeftWithQuoteTimage fields={props.fields} params={props.params} />
      </LazyLoadComponent>
    );
  }
  return <TimageDefaultComponent {...props} />;
};

export const TimageRight = (props: TimageProps): JSX.Element => {
  let DynamicTextImageRightTimage;
  if (editingmode) {
    DynamicTextImageRightTimage =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/timage/variants/text-image/TimageRight').TextImageRightTimage;
  } else {
    DynamicTextImageRightTimage = dynamic(
      () =>
        import('components/timage/variants/text-image/TimageRight').then(
          (mod) => mod.TextImageRightTimage
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }
  counter++;
  if (props.fields) {
    return (
      <LazyLoadComponent componentId={`TimageRight${counter}`}>
        <DynamicTextImageRightTimage
          fields={props.fields}
          params={props.params}
          variant={'default'}
        />
      </LazyLoadComponent>
    );
  }
  return <TimageDefaultComponent {...props} />;
};

export const TimageRightSquare = (props: TimageProps): JSX.Element => {
  let DynamicTextImageRightSquareTimage;
  if (editingmode) {
    DynamicTextImageRightSquareTimage =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/timage/variants/text-image/TimageRightSquare').TextImageRightSquareTimage;
  } else {
    DynamicTextImageRightSquareTimage = dynamic(
      () =>
        import('components/timage/variants/text-image/TimageRightSquare').then(
          (mod) => mod.TextImageRightSquareTimage
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }
  counter++;
  if (props.fields) {
    return (
      <LazyLoadComponent componentId={`TimageRightSquare${counter}`}>
        <DynamicTextImageRightSquareTimage
          fields={props.fields}
          params={props.params}
          variant={'square'}
        />
      </LazyLoadComponent>
    );
  }
  return <TimageDefaultComponent {...props} />;
};

export const TimageRightQuote = (props: TimageProps): JSX.Element => {
  let DynamicTextImageRightWithQuoteTimage;
  if (editingmode) {
    DynamicTextImageRightWithQuoteTimage =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/timage/variants/text-image/TimageRightWithQuote').TextImageRightWithQuoteTimage;
  } else {
    DynamicTextImageRightWithQuoteTimage = dynamic(
      () =>
        import('components/timage/variants/text-image/TimageRightWithQuote').then(
          (mod) => mod.TextImageRightWithQuoteTimage
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }
  counter++;
  if (props.fields) {
    return (
      <LazyLoadComponent componentId={`TimageRightQuote${counter}`}>
        <DynamicTextImageRightWithQuoteTimage fields={props.fields} params={props.params} />
      </LazyLoadComponent>
    );
  }
  return <TimageDefaultComponent {...props} />;
};

export const TimageTopTitle = (props: TimageProps): JSX.Element => {
  let DynamicTextImageTopTitleTimage;
  if (editingmode) {
    DynamicTextImageTopTitleTimage =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/timage/variants/text-image/TimageTopTitle').TextImageTopTitleTimage;
  } else {
    DynamicTextImageTopTitleTimage = dynamic(
      () =>
        import('components/timage/variants/text-image/TimageTopTitle').then(
          (mod) => mod.TextImageTopTitleTimage
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }
  counter++;
  if (props.fields) {
    return (
      <LazyLoadComponent componentId={`TimageTopTitle${counter}`}>
        <DynamicTextImageTopTitleTimage fields={props.fields} params={props.params} />
      </LazyLoadComponent>
    );
  }
  return <TimageDefaultComponent {...props} />;
};

export const TimageLeftCircle = (props: TimageProps): JSX.Element => {
  const editingmode = isEditorActive();
  // For the dynamic import to be used correctly:
  let DynamicTextImageLeftCircleTimager;

  if (editingmode) {
    // Static import when in editing mode
    DynamicTextImageLeftCircleTimager =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('./variants/text-image/TimageLeftCircle').TextImageLeftCircleTimage;
  } else {
    // Dynamic import when not in editing mode
    DynamicTextImageLeftCircleTimager = dynamic(
      () =>
        import('./variants/text-image/TimageLeftCircle').then(
          (mod) => mod.TextImageLeftCircleTimage
        ),
      { ssr: !!config.dynamicImportSsr } // If you want server-side rendering (ssr: true), adjust accordingly
    );
  }

  counter++;
  if (props.fields) {
    return (
      <>
        <LazyLoadComponent componentId={`TimageLeftCircle${counter}`}>
          <DynamicTextImageLeftCircleTimager fields={props.fields} params={props.params} />
        </LazyLoadComponent>
      </>
    );
  }
  return <TimageDefaultComponent {...props} />;
};

export const ContentBox = (props: TimageProps): JSX.Element => {
  let DynamicTextBlockOnly;
  if (editingmode) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicTextBlockOnly = require('./variants/text-image/ContentBox').TextBlockOnly;
  } else {
    DynamicTextBlockOnly = dynamic(
      () => import('./variants/text-image/ContentBox').then((mod) => mod.TextBlockOnly),
      { ssr: !!config.dynamicImportSsr }
    );
  }
  counter++;
  if (props.fields) {
    return (
      <LazyLoadComponent componentId={`ContentBox${counter}`}>
        <DynamicTextBlockOnly fields={props.fields} params={props.params} />
      </LazyLoadComponent>
    );
  }
  return <TimageDefaultComponent {...props} />;
};
