import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import sitecoreStyles from 'src/helpers/sitecoreStyles';

export interface HtmlCodeFields {
  HtmlCode?: Field<string>;
}

type HtmlCodeProps = {
  params: { [key: string]: string };
  fields?: HtmlCodeFields;
};

const HtmlCodeDefaultComponent = (props: HtmlCodeProps): JSX.Element => (
  <div className={`html-code ${sitecoreStyles(props.params.styles)}`}>
    <span className="is-empty-hint">Html Code</span>
  </div>
);

export const Default = (props: HtmlCodeProps): JSX.Element => {
  if (props.fields?.HtmlCode) {
    return <span>{props.fields.HtmlCode.value}</span>;
  }
  return <HtmlCodeDefaultComponent {...props} />;
};

export default Default;
