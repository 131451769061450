/**
 * DO NOT REMOVE THIS COMPONENT!
 * It is still used by some SiteCore components (header, footer, etc.)
 */
import React, { useEffect, useRef, useState } from 'react';
import {
  ImageField,
  Link,
  LinkField,
  Text,
  Field,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import config from 'temp/config';

import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import dynamic from 'next/dynamic';
interface Fields {
  Image?: ImageField;
  ImageCaption?: Field<string>;
  TargetUrl?: LinkField;
}

type ImageProps = {
  params: { [key: string]: string };
  fields?: Fields;
};

const ImageDefault = (props: ImageProps): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  return (
    <React.Fragment>
      {editingMode && (
        <div className={`component ${props.params?.styles}`}>
          <span className="is-empty-hint">Image</span>
        </div>
      )}
    </React.Fragment>
  );
};
export const Default = (props: ImageProps): JSX.Element => {
  console.log('---->[Image] Component', props.fields);
  const { sitecoreContext } = useSitecoreContext();
  const id = props.params.RenderingIdentifier;
  const editingmode = isEditorActive();

  let DynamicNextImageWithNullFallback;

  if (editingmode) {
    // Static import when in editing mode

    DynamicNextImageWithNullFallback =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/shared/nextImg/NextImageWithNullFallback').NextImageWithNullFallback;
  } else {
    // Dynamic import when not in editing mode
    DynamicNextImageWithNullFallback = dynamic(
      () =>
        import('components/shared/nextImg/NextImageWithNullFallback').then(
          (mod) => mod.NextImageWithNullFallback
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }

  const ref = useRef<HTMLDivElement>(null);
  const [showComponent, setShowComponent] = useState(false);
  useEffect(() => {
    if (!editingmode) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (ref.current) {
              observer.unobserve(ref.current);
              setShowComponent(true);
            }
          }
        });
      });
      if (ref.current) {
        observer.observe(ref.current);
      }
      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    } else {
      setShowComponent(true);
      return () => {
        setShowComponent(false);
      };
    }
  }, [ref]);
  if (props.fields) {
    return (
      <div
        ref={ref}
        id={id ? id : undefined}
        className={`next-image__container ${sitecoreStyles(props.params?.styles)}`}
      >
        {showComponent ? (
          <>
            {(sitecoreContext && sitecoreContext.pageState === 'edit') ||
            !props.fields.TargetUrl?.value?.href ? (
              // <Image field={props.fields.Image} className="next-image" />
              <DynamicNextImageWithNullFallback
                field={props.fields.Image}
                // width={1128}
                // height={1128}
                fill
                sizes="(max-width: 1200px) 100vw, (min-width: 1201px) 50vw"
                params={{
                  styles: 'next-image',
                }}
              ></DynamicNextImageWithNullFallback>
            ) : (
              <>
                <Link
                  field={props.fields.TargetUrl}
                  className={`ga-track next-image__container ${sitecoreStyles(
                    props.params?.styles
                  )}`}
                  data-analytics-link-name={`${props.fields.TargetUrl.value?.title}`}
                  data-analytics-link-type="Link"
                  data-analytics-content-class="Content"
                  data-analytics-template-zone="Body"
                  data-analytics-component-name="Image Molecule"
                  data-analytics-component-variation="NAN"
                  data-analytics-target={`${props.fields.TargetUrl.value?.href}`}
                  data-analytics-component-section="NAN"
                  data-analytics-slide-number="NAN"
                  data-analytics-ispersonalized="False"
                  data-analytics-iscarousal="False"
                  aria-label={(props.fields.Image?.value?.alt as string) || 'Image'}
                >
                  {/*<Image field={props.fields.Image} className="next-image" />*/}
                  <DynamicNextImageWithNullFallback
                    field={props.fields.Image}
                    width={1128}
                    height={1128}
                    params={{
                      styles: 'next-image',
                    }}
                  ></DynamicNextImageWithNullFallback>
                </Link>
              </>
            )}
            <Text
              tag="span"
              className="image-caption field-imagecaption"
              field={props.fields.ImageCaption}
            />
          </>
        ) : null}
      </div>
    );
  }
  return <ImageDefault {...props} />;
};

export default Default;
