/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import {
  Link as JssLink,
  LinkField,
  Text,
  TextField,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import dynamic from 'next/dynamic';
import { TopLvlMenu } from './partials/TopLvlMenu';
import { LowLvlMenu } from './partials/LowLvlMenu';
import config from 'temp/config';
import useMediaQuery from './../hooks/media-query-hook';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

export interface Fields {
  Id: string;
  DisplayName: string;
  Title: TextField;
  NavigationTitle: TextField;
  Href: string;
  Querystring: string;
  Children: Array<Fields>;
  Styles: string[];
}

export type NavigationProps = {
  params?: { [key: string]: string };
  fields: Fields;
  handleClick: (event?: React.MouseEvent<HTMLElement>) => void;
  relativeLevel: number;
};

const getNavigationText = function (props: NavigationProps): JSX.Element | string {
  let text;

  if (props.fields?.NavigationTitle) {
    text = <Text field={props.fields.NavigationTitle} />;
  } else if (props.fields?.Title) {
    text = <Text field={props.fields.Title} />;
  } else {
    text = props.fields?.DisplayName;
  }

  return text;
};

const getLinkField = (props: NavigationProps): LinkField => ({
  value: {
    href: props.fields.Href,
    title: getLinkTitle(props),
    querystring: props.fields.Querystring,
    class: 'nav-link',
  },
});

export const HeaderNav = (props: NavigationProps): JSX.Element => {
  console.log('---->[NAVIGATION] Component', props);
  const [currentItem, setCurrentItem] = useState(-1);
  const menuItems = Object.values(props.fields);
  const isDesktop = useMediaQuery('(min-width: 1025px)');
  const styles =
    props.params != null
      ? `${props.params.GridParameters ?? ''} ${props.params.Styles ?? ''}`.trimEnd()
      : '';
  const id = props.params != null ? props.params.RenderingIdentifier : null;

  if (!Object.values(props?.fields)?.length) {
    return (
      <div className={`navigation ${styles}`} id={id ? id : undefined}>
        <div className="component-content">[Navigation]</div>
      </div>
    );
  }

  let DynamicMobileNavigation;
  const editingmode = isEditorActive();
  if (editingmode) {
    // Static import when in editing mode
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicMobileNavigation = require('./MobileNavigation').MobileNavigation;
  } else {
    // Dynamic import when not in editing mode
    DynamicMobileNavigation = dynamic(
      () => import('./MobileNavigation').then((mod) => mod.MobileNavigation),
      { ssr: !!config.dynamicImportSsr }
    );
  }

  return (
    <>
      <div className={`navigation ${styles}`} id={id ? id : undefined}>
        {isDesktop ? (
          <div className="navigation__desktop-content">
            <div className="navigation__container">
              <nav className="navigation__top-lvl-menu-container">
                <TopLvlMenu
                  menuItems={menuItems}
                  setCurrentMenuItem={setCurrentItem}
                  currentItem={currentItem}
                ></TopLvlMenu>
              </nav>
              <LowLvlMenu
                menuItem={menuItems[currentItem]}
                setCurrentMenuItem={setCurrentItem}
              ></LowLvlMenu>
            </div>
          </div>
        ) : (
          <div className="navigation__mobile-content">
            <DynamicMobileNavigation {...props}></DynamicMobileNavigation>
          </div>
        )}
      </div>
    </>
  );
};

export const Default = (props: NavigationProps): JSX.Element => {
  console.log('---->[Navigation] Component', props.fields);

  const [isOpenMenu, openMenu] = useState(false);
  const { sitecoreContext } = useSitecoreContext();
  const styles =
    props.params != null
      ? `${props.params.GridParameters ?? ''} ${props.params.Styles ?? ''}`.trimEnd()
      : '';
  const id = props.params != null ? props.params.RenderingIdentifier : null;

  if (!Object.values(props.fields).length) {
    return (
      <div className={`component navigation ${styles}`} id={id ? id : undefined}>
        <div className="component-content">[Navigation]</div>
      </div>
    );
  }

  const handleToggleMenu = (event?: React.MouseEvent<HTMLElement>, flag?: boolean): void => {
    if (event && sitecoreContext?.pageEditing) {
      event.preventDefault();
    }

    if (flag !== undefined) {
      return openMenu(flag);
    }

    openMenu(!isOpenMenu);
  };

  const list = Object.values(props.fields)
    .filter((element) => element)
    .map((element: Fields, key: number) => (
      <NavigationList
        key={`${key}${element.Id}`}
        fields={element}
        handleClick={(event: React.MouseEvent<HTMLElement>) => handleToggleMenu(event, false)}
        relativeLevel={1}
      />
    ));

  return (
    <div className={`sitemap-navigation ${styles}`} id={id ? id : undefined}>
      <div className="sitemap-navigation__list-container">
        <ul className="sitemap-navigation__list">{list}</ul>
      </div>
    </div>
  );
};

const NavigationList = (props: NavigationProps) => {
  const { sitecoreContext } = useSitecoreContext();
  let children: JSX.Element[] = [];
  if (props.fields.Children && props.fields.Children.length) {
    children = props.fields.Children.map((element: Fields, index: number) => (
      <NavigationList
        key={`${index}${element.Id}`}
        fields={element}
        handleClick={props.handleClick}
        relativeLevel={props.relativeLevel + 1}
      />
    ));
  }
  return (
    <li
      className={props.fields.Styles.concat('rel-level' + props.relativeLevel).join(' ')}
      key={props.fields.Id}
      tabIndex={0}
    >
      <div className="sitemap-navigation__level-title">
        <JssLink
          field={getLinkField(props)}
          editable={sitecoreContext?.pageEditing}
          onClick={props.handleClick}
          className="ga-track internal-link"
          data-analytics-link-name={props.fields.NavigationTitle?.value}
          data-analytics-link-type="Navigation"
          data-analytics-content-class="Content"
          data-analytics-template-zone="Header"
          data-analytics-component-name="Navigation"
          data-analytics-component-variation="Main Navigation"
          data-analytics-target={props.fields.Href}
          data-analytics-component-section="Main Navigation Items L1"
          data-analytics-slide-number="NAN"
          data-analytics-ispersonalized="False"
          data-analytics-iscarousal="False"
        >
          {getNavigationText(props)}
        </JssLink>
      </div>
      {children.length > 0 ? <ul className="clearfix-children">{children}</ul> : null}
    </li>
  );
};

const getLinkTitle = (props: NavigationProps): string | undefined => {
  let title;
  if (props.fields.NavigationTitle?.value) {
    title = props.fields.NavigationTitle.value.toString();
  } else if (props.fields.Title?.value) {
    title = props.fields.Title.value.toString();
  } else {
    title = props.fields.DisplayName;
  }

  return title;
};
