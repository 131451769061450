import React, { useCallback, useEffect, useState } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { EmblaOptionsType } from 'embla-carousel';
import { Default as CarouselCard } from 'components/carousel/partials/carousel-card/CarouselCard';
import {
  Field,
  ImageField,
  LinkField,
  RichTextField,
  TextField,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { Icon } from 'components/shared/icon/Icon';
import { IconName, IconSize } from 'src/types/Enum';
import { TagFields } from 'src/types/Tag';
//component properties

export interface CarouselCard {
  area?: TextField;
  img?: ImageField;
  title?: TextField;
  body?: RichTextField;
  link: LinkField;
  backgroundSlideImg?: ImageField;
  contentLabel?: {
    targetItem: TagFields;
  };
  hideContentLabel?: {
    jsonValue: Field<boolean>;
  };
}

type CarouselContentProps = {
  id?: string;
  options?: EmblaOptionsType;
  slides?: CarouselCard[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateNextSlide?: any;
};

//default variant
export const Default = (props: CarouselContentProps): JSX.Element => {
  const { slides, options } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);
  const [hideDots, setHideDots] = useState(false);

  const scrollPrev = useCallback(() => {
    emblaApi && emblaApi.scrollPrev();
  }, [emblaApi]);
  const scrollNext = useCallback(() => {
    emblaApi && emblaApi.scrollNext();
  }, [emblaApi]);
  const scrollTo = useCallback((index: number) => emblaApi && emblaApi.scrollTo(index), [emblaApi]);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
    setPrevBtnEnabled(emblaApi.canScrollPrev());
    setNextBtnEnabled(emblaApi.canScrollNext());
    props.updateNextSlide && props.updateNextSlide(emblaApi.internalEngine().index.get());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emblaApi, selectedIndex]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    setScrollSnaps(emblaApi.scrollSnapList());
    emblaApi.on('select', onSelect);
    emblaApi.on('reInit', onSelect);
  }, [emblaApi, setScrollSnaps, onSelect]);

  useEffect(() => {
    if (props.slides && props.slides?.length === 1) {
      setHideDots(true);
    } else {
      setHideDots(false);
    }
  }, [props.slides]);

  if (!slides) {
    return <></>;
  }

  return (
    <>
      <div className="carousel-content" id={props.id ? props.id : undefined}>
        <div className="carousel-content__viewport" ref={emblaRef}>
          <div className="carousel-content__container">
            {slides?.map((slide, index) => (
              <div
                key={index}
                className={`carousel-content__slide ${
                  selectedIndex === index ? '' : 'carousel-content__slide--hide'
                }`}
              >
                <CarouselCard
                  params={{
                    name: 'link__button-primary',
                    styles: '',
                  }}
                  fields={{
                    area: slide?.area,
                    img: slide?.img,
                    title: slide?.title,
                    body: slide?.body,
                    link: slide?.link,
                    contentLabel: slide?.contentLabel,
                    hideContentLabel: slide?.hideContentLabel,
                  }}
                />
              </div>
            ))}
          </div>
        </div>
        {!hideDots && (
          <div className="carousel-content__dots">
            <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
            {scrollSnaps.map((_, index) => (
              <DotButton
                key={index}
                selected={index === selectedIndex}
                onClick={() => scrollTo(index)}
              />
            ))}
            <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
          </div>
        )}
      </div>
    </>
  );
};

type DotButtonPropType = {
  selected: boolean;
  onClick: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DotButton: React.FC<DotButtonPropType> = (props) => {
  const { selected, onClick } = props;

  return (
    <button
      className={'carousel-content__dot'.concat(selected ? ' carousel-content__dot--selected' : '')}
      type="button"
      onClick={onClick}
      aria-label="dot"
    />
  );
};

type PrevNextButtonPropType = {
  enabled: boolean;
  onClick: () => void;
};

export const PrevButton: React.FC<PrevNextButtonPropType> = (props) => {
  const { enabled, onClick } = props;

  return (
    <button
      data-testid="previous"
      aria-label="previous"
      className="carousel-content__button carousel-content__button--prev"
      onClick={onClick}
      disabled={!enabled}
    >
      <Icon icon={IconName.ChevronLeft} iconSize={IconSize.Size28} iconLabel={'Previous'} />
    </button>
  );
};

export const NextButton: React.FC<PrevNextButtonPropType> = (props) => {
  const { enabled, onClick } = props;

  return (
    <button
      data-testid="next"
      aria-label="next"
      className="carousel-content__button carousel-content__button--next"
      onClick={onClick}
      disabled={!enabled}
    >
      <Icon icon={IconName.ChevronRight} iconSize={IconSize.Size28} iconLabel={'Next'} />
    </button>
  );
};
