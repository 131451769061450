import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { useImperativeHandle, forwardRef, useState } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { BrightcoveItemFields } from 'components/single-video/SingleVideo';
import config from 'temp/config';
import Script from 'next/script';
import dynamic from 'next/dynamic';

type VideoModalProps = {
  buttonText: string;
  video?: BrightcoveItemFields;
};

export const VideoModal = forwardRef(({ buttonText, video }: VideoModalProps, ref): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const brightcoveIDValue = video?.brightcoveItem?.targetItem?.brightcoveID?.jsonValue?.value || '';

  const { sitecoreContext } = useSitecoreContext();
  let brightcoveplaryerloaderUrl = '/scripts/brightcove-player-loader.min.js';
  if (
    sitecoreContext &&
    (sitecoreContext.pageState === 'edit' || sitecoreContext.pageState === 'preview')
  ) {
    brightcoveplaryerloaderUrl = config.publicUrl + '/scripts/brightcove-player-loader.min.js';
  }

  let DynamicModal;
  const editingmode = isEditorActive();

  if (editingmode) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicModal = require('../modal/Modal').Modal;
  } else {
    // Dynamic import when not in editing mode
    DynamicModal = dynamic(() => import('../modal/Modal').then((mod) => mod.Modal), {
      ssr: !!config.dynamicImportSsr,
    });
  }

  let DynamicSingleVideo;

  if (editingmode) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicSingleVideo = require('components/single-video/SingleVideo').Default;
  } else {
    // Dynamic import when not in editing mode
    DynamicSingleVideo = dynamic(
      () => import('components/single-video/SingleVideo').then((mod) => mod.Default),
      {
        ssr: !!config.dynamicImportSsr,
      }
    );
  }

  useImperativeHandle(ref, () => ({
    setIsModalOpen,
  }));

  const videoLinkButton = (
    <>
      <button
        className={`link ga-track link__button-tertiary subheading1`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          !editingMode && setIsModalOpen(true);
        }}
      >
        <div className="link__button-tertiary-text">
          <span>{buttonText}</span>
          <div className="link__button-tertiary-image-container">
            <div className="link__button-tertiary-arrow">
              <i className="icon icon--arrow-right icon--24"></i>
            </div>

            <div className="link__button-tertiary-arrow-active">
              <i className="icon icon--arrow-right-active icon--24"></i>
            </div>
          </div>
        </div>
      </button>
    </>
  );

  return (
    <>
      <Script id="BrightcovePlayerID" src={brightcoveplaryerloaderUrl} />
      {videoLinkButton}
      <DynamicModal
        hasCloseBtn={true}
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
      >
        {isModalOpen && brightcoveIDValue ? (
          <DynamicSingleVideo
            isModalDisplayed={true}
            autoplay={true}
            fields={{ data: { datasource: video as BrightcoveItemFields } }}
            params={{}}
          />
        ) : (
          <div className={'h1'}>Video not defined</div>
        )}
      </DynamicModal>
    </>
  );
});
VideoModal.displayName = 'VideoModal';
