/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react';
import { LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { usePathname } from 'next/navigation';
import { useI18n } from 'next-localization';
import dynamic from 'next/dynamic';
import { useLink } from 'lib/contexts/AllLinksContext';
import config from 'temp/config';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
export interface WelcomeLink {
  backtosearch?: LinkField;
  savejob?: LinkField;
  showlink: boolean;
}
import { useSortFacet } from 'lib/contexts/SortFacetContext';
export interface WelcomeSaveJobProps {
  welcomelinkprops?: WelcomeLink;
  fields?: {
    SavedJob?: LinkField;
    SearchJobs?: LinkField;
    JobDetails?: LinkField;
  };
}

export const Default = (props: WelcomeSaveJobProps) => {
  console.log('props welcome', props?.fields?.SearchJobs);
  const searchJobs = props?.fields?.SearchJobs;
  const jobDetails = props?.fields?.JobDetails;
  const { t } = useI18n();
  const [saveCount, setSaveCount] = useState(0);
  const { addData } = useLink();
  const { facetValue } = useSortFacet();
  useEffect(() => {
    const storeDataFromBookmark = localStorage.getItem('bookmarkedCards');
    const storeDataArrFromBookmark = storeDataFromBookmark && eval(storeDataFromBookmark);
    const storeDataLenFromBookmark = storeDataArrFromBookmark && storeDataArrFromBookmark.length;
    window.addEventListener('triggerLocalStorage', () => {
      const storeData = localStorage.getItem('bookmarkedCards');
      const storeDataArray = storeData && eval(storeData);
      const storeDataLength = storeDataArray.length;
      setSaveCount(storeDataLength);
    });
    setSaveCount(storeDataLenFromBookmark);
  });

  useEffect(() => {
    addData({ searchJobs, jobDetails });
  }, []);
  //const router = useRouter();
  //console.log('savedjob', router.pathname);

  //const [currentUrl, setCurrentUrl] = useState('');
  //useEffect(() => {
  //  const url = window.location.href;
  //  setCurrentUrl(url);
  //}, []);

  //console.log('currenturl', currentUrl);
  //console.log(window.location.origin);
  const previousLink = localStorage.getItem('urllink');
  const searchjobs: LinkField = {
    value: {
      //href: welcomelinks?.backtosearch?.value.href,
      href:
        previousLink != ''
          ? 'search-jobs/' + String(previousLink)
          : props?.welcomelinkprops?.backtosearch?.value?.href,
      text: t(`career-job-back-to-search-jobs`),
    },
  };

  let sortvalue = '';
  if (facetValue == 'relevent') {
    sortvalue = '#sortCriteria=%40job_area_of_expertise%20ascending';
  } else if (facetValue == 'recent') {
    sortvalue = '#sortCriteria=%40jobposteddate%20descending%2C%40jobname%20ascending';
  }

  const savejobs = props?.fields?.SavedJob
    ? props.fields.SavedJob.value.href + sortvalue
    : props.welcomelinkprops?.savejob?.value.href + sortvalue;

  const currentPath = usePathname();

  useEffect(() => {
    const bookmarkCardValue = localStorage.getItem('bookmarkedCards');
    const bookmarkCardArray = bookmarkCardValue && eval(bookmarkCardValue);
    const bookmarkCardCount = bookmarkCardArray && bookmarkCardArray.length;
    window.addEventListener('localStorageEvent', () => {
      const storeData = localStorage.getItem('bookmarkedCards');
      const storeDataArr = storeData && eval(storeData);
      const storeDataLen = storeDataArr.length;
      setSaveCount(storeDataLen || 0);
    });
    setSaveCount(bookmarkCardCount || 0);
  });

  const SaveJobClick = () => {
    localStorage.setItem('keyword', '');
    localStorage.setItem('expertiseValue', '');
    localStorage.setItem('selectedLocation', '[]');
    window.dispatchEvent(new Event('localEvent'));
  };

  const editingmode = isEditorActive();
  let DynamicTertiaryWithLeftArrow;

  if (editingmode) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicTertiaryWithLeftArrow = require('../../link/Link').TertiaryWithLeftArrow;
  } else {
    // Dynamic import when not in editing mode
    DynamicTertiaryWithLeftArrow = dynamic(
      () => import('../../link/Link').then((mod) => mod.TertiaryWithLeftArrow),
      { ssr: !!config.dynamicImportSsr }
    );
  }

  let DynamicLink;

  if (editingmode) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicLink = require('next/link').default;
  } else {
    // Dynamic import when not in editing mode
    DynamicLink = dynamic(() => import('next/link').then((mod) => mod.default), {
      ssr: !!config.dynamicImportSsr,
    });
  }
  return (
    <div className="welcome-savedjobs-text-container">
      <div className="back-text">
        {/* {welcomelinks.showlink ? (*/}
        {
          /*currentUrl.includes('save-jobs') || */ props.welcomelinkprops?.showlink ? (
            <DynamicTertiaryWithLeftArrow
              params={{}}
              fields={{ Link: searchjobs }}
            ></DynamicTertiaryWithLeftArrow>
          ) : (
            ' '
          )
        }
      </div>
      <div className="welcome-savedjob-border">
        <div>
          <span className="welcometext">{t(`career-job-welcome`)}</span>
        </div>
        <div
          className={`${currentPath.includes('saved-jobs') ? 'highlight-savejob' : ''} ${
            saveCount === 0 ? 'disabled' : 'enabled'
          }`}
        >
          {/*<a href={welcomelinks.savejob?.value.href}>*/}
          <DynamicLink href={savejobs!} onClick={SaveJobClick}>
            <span className="savedjobtext">
              {t(`career-job-saved-jobs`)}
              <label>&nbsp;</label>
              <span className="savedjobcount">({saveCount})</span>
            </span>
          </DynamicLink>
        </div>
      </div>
    </div>
  );
};
