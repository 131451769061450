/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import Script from 'next/script';
import Image from 'next/image';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import config from 'temp/config';
import { useI18n } from 'next-localization';
import dynamic from 'next/dynamic';
import { ComponentRendering, ImageField } from '@sitecore-jss/sitecore-jss-react';
import { ImageLoadingStrategy } from '../../types/ImageLoadingStrategy';
import {
  Field,
  GetStaticComponentProps,
  Text,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';

declare global {
  interface Window {
    VideoPlayerSetup: () => void;
    bc: (video: HTMLElement | null) => void;
    videojs: {
      getPlayer: (id: string) => VideoPlayer;
    };
    InitializeBrightcovePlayerLoader: (arg0: string, arg1: string, arg2: string) => void;
  }
}

interface VideoPlayer {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mediainfo: any;
  play: () => void;
  pause: () => void;
  on: (state: string, callback: () => void) => void;
  one: (state: string, callback: () => void) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  social: (options: any) => void;
  ready: (callback: () => void) => void;
  autoplay: (play: boolean | undefined) => void;
}

export interface BrightcovePlayer {
  id: string;
  playerKey: {
    jsonValue: Field<string>;
  };
}

export interface BrightcoveMediaElement {
  id: string;
  brightcoveID: {
    jsonValue: Field<string>;
  };
  brightcoveName: {
    jsonValue: Field<string>;
  };
}

export interface BrightcoveItemFields {
  id: string;
  brightcovePlayer: {
    targetItem: BrightcovePlayer;
  };
  brightcoveItem: {
    targetItem: BrightcoveMediaElement;
  };
}

type SingleVideoProps = {
  params: { [key: string]: string };
  fields: {
    data: {
      datasource: BrightcoveItemFields;
    };
  };
  autoplay?: boolean;
  isModalDisplayed: boolean;
  singleVideoBrightcoveIDValue?: string;
  isPlayListVideoContainer?: boolean;
  rendering?: ComponentRendering;
  imageSrc?: string;
  height?: number;
  width?: number;
};

export const Default = (props: SingleVideoProps): JSX.Element => {
  console.log(`[Single video] component`, props);
  const id = props.params?.RenderingIdentifier;
  const imageLoadingStrategy: ImageLoadingStrategy =
    (props.params && (props.params['ImageLoadingStrategy'] as ImageLoadingStrategy)) || 'lazy';
  const datasource = props.fields.data.datasource;
  const unformattedVideoPlayerId =
    props?.fields?.data?.datasource?.brightcoveItem?.targetItem?.brightcoveID?.jsonValue?.value;
  let brightcoveIDValue = datasource?.brightcoveItem?.targetItem?.brightcoveID?.jsonValue?.value;

  if (props.isPlayListVideoContainer && props.singleVideoBrightcoveIDValue) {
    brightcoveIDValue = props?.singleVideoBrightcoveIDValue;
  }

  const { sitecoreContext } = useSitecoreContext();

  let brightcoveplaryerloaderUrl = '/scripts/brightcove-player-loader.min.js';
  console.log(sitecoreContext, 'sitecoreContext');

  if (
    sitecoreContext &&
    (sitecoreContext.pageState === 'edit' || sitecoreContext.pageState === 'preview')
  ) {
    brightcoveplaryerloaderUrl = config.publicUrl + '/scripts/brightcove-player-loader.min.js';
  }
  const playerKeyValue = datasource?.brightcovePlayer?.targetItem?.playerKey?.jsonValue?.value;
  let videoPlayerId = `videoId_${unformattedVideoPlayerId}`;
  const [currentUrl, setCurrentUrl] = useState('');
  // const router = useRouter();
  const [jsonValue] = useState<ImageField>({
    value: {
      alt: props.fields.data.datasource?.brightcoveItem?.targetItem?.brightcoveName?.jsonValue
        ?.value,
      src: props.imageSrc,
      width: props.width,
      height: props.height,
    },
  });
  const [playVideo, setPlayVideo] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);

  if (showVideoModal || props.isModalDisplayed) {
    videoPlayerId = videoPlayerId + 'Modal';
  }
  useEffect(() => {
    const url = window.location.href;
    setCurrentUrl(url);
  }, []);

  const closeModal = () => {
    setShowVideoModal(false);
    setPlayVideo(false);
  };

  function LoadPlayer() {
    if (props.fields) {
      const datasource = props.fields.data.datasource;
      if (typeof window !== undefined) {
        window.InitializeBrightcovePlayerLoader(
          '#' + videoPlayerId,
          datasource?.brightcovePlayer?.targetItem?.playerKey?.jsonValue?.value,
          brightcoveIDValue
        );
        setPlayVideo(true);
      }
    }
  }
  const scriptRoot = useRef<HTMLFormElement>(null); // gets assigned to a root node
  const scriptRootModal = useRef<HTMLFormElement>(null); // gets assigned to a root node
  const { t } = useI18n(); /*Dictionary*/
  const script = `<script> 
function InitializeBrightcovePlayerLoader(bVideoId, playerKey, brightcoveIDValue) {
                        if (document.querySelector(bVideoId) != null) 
                        {
                          let videoPlayerContainer = document.querySelector(bVideoId);
                          if(event){
                            videoPlayerContainer = event.target.closest(".video-tab").querySelector(bVideoId);
                          }
                            brightcovePlayerLoader({
                                refNode: videoPlayerContainer,
                                refNodeInsert: 'replace',
                                accountId: '2103045685001',
                                playerId: playerKey,
                                embedId: 'default',
                                videoId: brightcoveIDValue
                            })
                            .then(function(success) {
                                // The player has been created!
                                /*
                                  At this point:
                                  - at least one video player will be in the DOM.
                                  - at least one script created by this library will be in the DOM.
                                  - window.bc will exist.
                                  - window.videojs will exist.
                                 */
                                const videoDomElement = document.getElementsByClassName('vjs-tech')[0];
                                const accountId = encodeURIComponent('2103045685001');
                                const socialUrl = encodeURI(
                                    'https://' + window.location.hostname + window.location.pathname + '?videoid='
                                );
                                videoDomElement?.setAttribute('data-account', accountId);
                                videoDomElement?.setAttribute('data-player', playerKey);
                                videoDomElement?.setAttribute('data-video-id', brightcoveIDValue);
                                if (typeof window.bc != 'undefined') {
                                    window.bc(videoDomElement);
                                }
                                if (typeof window.videojs != 'undefined') {
                                    const player = success.ref;
                                    player.one("loadeddata", function () {
                                    var tracks = player.textTracks();
                                        for(var i = 0; i < (tracks.length); i++){
                                        tracks[i].mode = "disabled";
                                        }
                                    })
                                    player.ready(function () {
                                    player.one('loadstart', function () {
                                        videoDomElement?.setAttribute('data-video-name',player.mediainfo.name);
                                        const options = {
                                        title: player.mediainfo.name,
                                        description: player.mediainfo.description,
                                        url: socialUrl + player.mediainfo.id,
                                        deeplinking: false,
                                        autoplay: ${props?.autoplay} || true,
                                        offset: '00:00:00',
                                        services: {
                                            facebook: true,
                                            twitter: false,
                                            tumblr: false,
                                            pinterest: true,
                                            linkedin: true,
                                        },
                                        };
                                        player.autoplay(${props?.autoplay} || true);

                                        player.social(options);
                                        player.socialOverlay.setDirectLink(socialUrl + player.mediainfo.id);
                                    });
                                    if (${props?.autoplay}) {
                                        player.play();
                                    }
                                    });
                                }
                                 /*brightcovePlayerLoader.reset();*/

                                /*
                                  At this point:
                                  - no video players will be in the DOM.
                                  - no scripts created by this library will be in the DOM.
                                  - window.bc will not exist.
                                  - window.videojs will not exist.
                                 */
                            })
                            .catch(function(error) {
                                // Player creation failed!
                            });
                        }
                     }
</script>`;

  useEffect(() => {
    if (showVideoModal || props.isModalDisplayed) {
      setTimeout(() => {
        const rangeModal = document.createRange(); // creates a mini-document (light weight version), in our range with our script in it
        const documentFragmentModal = rangeModal.createContextualFragment(script); // appends it to our script root - so it renders in the correct location
        if (scriptRootModal.current && scriptRootModal.current.childElementCount < 1) {
          scriptRootModal.current.appendChild(documentFragmentModal);
          if (typeof window !== undefined) {
            LoadPlayer();
          }
        }
      }, 1500);
    } else {
      const range = document.createRange(); // creates a mini-document (light weight version), in our range with our script in it
      const documentFragment = range.createContextualFragment(script); // appends it to our script root - so it renders in the correct location
      if (scriptRoot.current && scriptRoot.current.childElementCount < 1) {
        scriptRoot.current.appendChild(documentFragment);
        if (typeof window !== undefined) {
          if (playVideo) {
            LoadPlayer();
          }
        }
      }
    }
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      scriptRoot.current?.firstChild?.remove;
      scriptRootModal.current?.firstChild?.remove;
    };
  }, [script, showVideoModal, playVideo]);

  const editingmode = isEditorActive();
  let DynamicNextImageWithNullFallback;
  if (editingmode) {
    // Static import when in editing mode

    DynamicNextImageWithNullFallback =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/shared/nextImg/NextImageWithNullFallback').NextImageWithNullFallback;
  } else {
    // Dynamic import when not in editing mode
    DynamicNextImageWithNullFallback = dynamic(
      () =>
        import('components/shared/nextImg/NextImageWithNullFallback').then(
          (mod) => mod.NextImageWithNullFallback
        ),
      { ssr: !!config.dynamicImportSsr } // Adjust server-side rendering (ssr: true) based on your config
    );
  }

  const generateVideoComponent = (
    datasource: BrightcoveItemFields,
    currentUrl: string,
    videoPlayerId: string,
    props: SingleVideoProps,
    id: string | undefined
  ) => {
    return (
      <div
        className={`component single-video ${sitecoreStyles(props.params?.Styles)}`}
        id={id ? id : undefined}
      >
        <div className="component-content">
          <div className="single-video-text">
            <input
              id="hddn_m_DataplaylistId"
              name="hddn_m_DataplaylistId"
              type="hidden"
              value={`${brightcoveIDValue}`}
            />
            <input
              id="hddn_m_PlayerKey"
              name="hddn_m_PlayerKey"
              type="hidden"
              value={`${datasource?.brightcovePlayer.targetItem.playerKey.jsonValue.value}`}
            />
            <input
              id="hddn_m_AccountId"
              name="hddn_m_AccountId"
              type="hidden"
              value="2103045685001"
            />
            <input id="hddn_m_language" name="hddn_m_language" type="hidden" value="en" />
            <input
              id="hddnSharingUrl"
              name="hddnSharingUrl"
              type="hidden"
              value={currentUrl + '?videoid=' + brightcoveIDValue}
            />
            <input id="hddn_disable_map" name="hddn_disable_map" type="hidden" value="0" />
            <input
              id={'hddnVideoId' + brightcoveIDValue}
              name={'hddnVideoId' + brightcoveIDValue}
              type="hidden"
              value={brightcoveIDValue}
            />
            <div className="video-tab">
              <>
                <div>
                  <div
                    className={`before-video-js ${
                      playVideo || showVideoModal || props.isModalDisplayed ? 'hide-block' : ''
                    }`}
                  >
                    <DynamicNextImageWithNullFallback
                      field={jsonValue}
                      params={{
                        styles: 'before-video-js__thumbnail',
                      }}
                      loading={imageLoadingStrategy}
                      priority={imageLoadingStrategy == 'eager' ? true : false}
                      // width={658}
                      // height={370}
                      fill
                      sizes="(max-width: 1200px) 100vw, (min-width: 1201px) 38vw, 33vw"
                    />
                    <button
                      className="before-video-js__play-button"
                      title={t('play-video')}
                      type="button"
                      onClick={() => {
                        LoadPlayer();
                      }}
                    >
                      <span className="before-video-js__play-button__icon" />
                    </button>
                  </div>
                  <div
                    id={videoPlayerId}
                    className={`video-js ${
                      playVideo || showVideoModal || props.isModalDisplayed ? '' : 'hide-block'
                    }`}
                  />

                  <Script id="BrightcovePlayerID" src={brightcoveplaryerloaderUrl} />
                  {showVideoModal || props.isModalDisplayed ? (
                    <form ref={scriptRootModal} />
                  ) : (
                    <form ref={scriptRoot} />
                  )}
                  <Image
                    className="mapbrightcovecom"
                    src="https://map.brightcove.com/lead/clear"
                    width={0}
                    height={0}
                    loading={imageLoadingStrategy}
                    alt="cookie consent"
                  />
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const isEditorActiveVideoComponent = () => {
    return (
      <div
        className={`component single-video ${sitecoreStyles(props.params?.styles)}`}
        id={id ? id : undefined}
      >
        <div className="component-content">
          <div className="single-video-text">
            <div className="field-playerKey">
              <span>Player Key</span>:
              <Text field={datasource?.brightcovePlayer?.targetItem?.playerKey?.jsonValue} />
              <span>click save to load the Single Video Module.</span>
            </div>
            <div className="field-brightcoveID">
              <span>Brightcove video ID </span>:
              <Text field={datasource?.brightcoveItem?.targetItem?.brightcoveID?.jsonValue} />
            </div>
            <div className="field-brightcoveName">
              <span>Brightcove video name </span>:
              <Text field={datasource?.brightcoveItem?.targetItem?.brightcoveName?.jsonValue} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  let DynamicModal;

  if (editingmode) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicModal = require('components/shared/modal/Modal').Modal;
  } else {
    // Dynamic import when not in editing mode
    DynamicModal = dynamic(() => import('components/shared/modal/Modal').then((mod) => mod.Modal), {
      ssr: !!config.dynamicImportSsr,
    });
  }
  if (showVideoModal) {
    return props.isModalDisplayed ? (
      <>{generateVideoComponent(datasource, currentUrl, videoPlayerId, props, id)}</>
    ) : (
      <DynamicModal isOpen={showVideoModal} hasCloseBtn onClose={closeModal}>
        {generateVideoComponent(datasource, currentUrl, videoPlayerId, props, id)}
      </DynamicModal>
    );
  }

  if (brightcoveIDValue && playerKeyValue) {
    return generateVideoComponent(datasource, currentUrl, videoPlayerId, props, id);
  } else if (isEditorActive()) {
    return isEditorActiveVideoComponent();
  }

  return <SingleVideoDefaultComponent {...props} />;
};
const edgebrightcove = new URL(
  'https://edge.api.brightcove.com/playback/v1/accounts/2103045685001/videos/'
).href;

// Define the expected structure of a "source" in the response
interface Source {
  width?: number;
  height?: number;
  // Add other properties here as needed, based on the Brightcove API response
}

interface BrightcoveResponse {
  poster?: string;
  sources: Source[]; // Array of sources
}
export const getStaticProps: GetStaticComponentProps = async (props) => {
  const brightcovesource = props as unknown as SingleVideoProps;
  const videoID =
    brightcovesource.fields.data.datasource?.brightcoveItem?.targetItem?.brightcoveID?.jsonValue
      ?.value; // Assuming videoID comes from params or query
  if (!videoID) {
    return { notFound: true }; // Handle case when videoID is not provided
  }

  try {
    const vidID = encodeURIComponent(videoID); // Directly using videoID from params
    const url = `${edgebrightcove}${vidID}`;
    const api = `application/json;pk=${config.brightcoveImgApikey}`;
    const response = await fetch(url, {
      headers: {
        accept: api,
      },
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch image data: ${response.statusText}`);
    }

    const json: BrightcoveResponse = await response.json(); // Type the JSON response

    // Safely check and extract the sources
    const source = json.sources.find((source: Source) => source.width && source.height);
    if (source) {
      return {
        imageSrc: json.poster || '', // Fallback if 'poster' is not available
        width: source.width || 0, // Fallback if 'width' is not available
        height: source.height || 0, // Fallback if 'height' is not available
      };
    }
  } catch (error) {
    console.error('Error fetching data from Brightcove:', error);
    return {
      imageSrc: '',
      width: 0,
      height: 0,
    };
  }
  return props;
};

export const WithText = (props: SingleVideoProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  if (props.fields) {
    return (
      <div
        className={`component single-video ${sitecoreStyles(props.params?.styles)}`}
        id={id ? id : undefined}
      >
        <div className="component-content">
          <div className="single-video-text">
            <div className="field-playerKey">
              <span>Player Key</span>:
              <Text
                field={
                  props?.fields?.data?.datasource?.brightcovePlayer?.targetItem?.playerKey
                    ?.jsonValue
                }
              />
              <span>click save to load the Single Video Module.</span>
            </div>
            <div className="field-brightcoveID">
              <span>Brightcove Playlist ID</span>:
              <Text
                field={
                  props?.fields?.data?.datasource?.brightcoveItem?.targetItem?.brightcoveID
                    ?.jsonValue
                }
              />
            </div>
            <div className="field-brightcoveName">
              <span>Brightcove Playlist ID</span>:
              <Text
                field={
                  props?.fields?.data?.datasource?.brightcoveItem?.targetItem?.brightcoveName
                    ?.jsonValue
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <SingleVideoDefaultComponent {...props} />;
};

export const SingleVideoDefaultComponent = (props: SingleVideoProps): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  return (
    <React.Fragment>
      {editingMode && (
        <div className={`component single-video ${sitecoreStyles(props.params?.styles)}`}>
          <div className="component-content">
            <span className="is-empty-hint">Single Video</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
