import React from 'react';
import { Field, RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { ReferencableItemFields } from 'src/types/Referencable';
import { DateTimeRangeFields } from 'components/date-time/DateTime';
import { DateRangeWithoutTimezone } from 'components/date-time/DateTime';
import { Default, PostedDateFields } from 'components/posted-date/PostedDate';
import { TaggableFields } from '../../types/Taggable';
import dynamic from 'next/dynamic';
import config from 'temp/config';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

const editingmode = isEditorActive();
let DynamicNextImageWithNullFallback;
if (editingmode) {
  // Static import when in editing mode

  DynamicNextImageWithNullFallback =
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    require('components/shared/nextImg/NextImageWithNullFallback').NextImageWithNullFallback;
} else {
  // Dynamic import when not in editing mode
  DynamicNextImageWithNullFallback = dynamic(
    () =>
      import('components/shared/nextImg/NextImageWithNullFallback').then(
        (mod) => mod.NextImageWithNullFallback
      ),
    { ssr: !!config.dynamicImportSsr } // Adjust server-side rendering (ssr: true) based on your config
  );
}

export const TopFiveCard = (props: ReferencableItemFields): JSX.Element => {
  return (
    <div className="top-five-card">
      {props.isFirstItem && (
        <div className="top-five-card__img">
          <DynamicNextImageWithNullFallback
            field={props.referenceImage?.jsonValue}
            width={752}
            height={423}
            params={{
              styles: 'next-image--16-9',
            }}
          />
        </div>
      )}
      <div className="top-five-card__container">
        <Text
          tag="div"
          className="body2"
          field={props.referenceCategory?.targetItems[0]?.title?.jsonValue}
        />

        <a href={props?.url?.url} className="top-five-card__link">
          <Text
            tag="div"
            className={`top-five-card__title ${
              props.isFirstItem ? 'top-five-card__title--big' : 'top-five-card__title--small'
            }`}
            field={props.referenceTitle?.jsonValue}
            data-analytics-link-name={props.referenceTitle?.jsonValue.value}
            data-analytics-link-type="Link"
            data-analytics-content-class="Content"
            data-analytics-template-zone="Body"
            data-analytics-component-name="Top Five Client Stories"
            data-analytics-component-variation={
              props.isFirstItem ? 'top-five-card__title--big' : 'top-five-card__title--small'
            }
            data-analytics-target={props?.url?.url}
            data-analytics-component-section="NAN"
            data-analytics-slide-number="NAN"
            data-analytics-ispersonalized="False"
            data-analytics-iscarousal="False"
            data-analytics-category={
              props.referenceCategory?.targetItems[0]?.title?.jsonValue?.value
            }
          />
        </a>
        {props.isFirstItem && (
          <RichText
            tag="div"
            className="subheading1 top-five-card__text"
            field={props.referenceText?.jsonValue}
          />
        )}
      </div>
    </div>
  );
};

export const TopFiveDateTimeCard = (
  props: ReferencableItemFields & TaggableFields & DateTimeRangeFields
): JSX.Element => {
  console.log('topfivedatecard', props);

  const startDateTime = props.startDateTime?.jsonValue?.value;
  const endDateTime = props.endDateTime?.jsonValue?.value;
  const defaultDate = '0001-01-01T00:00:00Z';
  const eitherDateIsDefault = startDateTime === defaultDate || endDateTime === defaultDate;
  const shouldRenderDateRange = !eitherDateIsDefault;

  const selectedEventTypeTags = props.sxaTags?.targetItems?.filter(
    (element) => element.parent?.name == 'EventType'
  );

  const eventType: Field<string> | undefined = selectedEventTypeTags?.length
    ? selectedEventTypeTags[0].title?.jsonValue
    : undefined;

  const dateProps: DateTimeRangeFields = {
    id: props.id,
    startDateTime: props.startDateTime,
    endDateTime: props.endDateTime,
  };

  return (
    <div className="top-five-card">
      {props.isFirstItem && (
        <div className="top-five-card__img">
          <DynamicNextImageWithNullFallback
            field={props.referenceImage?.jsonValue}
            width={752}
            height={423}
            params={{
              styles: 'next-image--16-9',
            }}
          />
        </div>
      )}
      <div className="top-five-card__container">
        {shouldRenderDateRange && (
          <DateRangeWithoutTimezone params={{}} fields={{ data: { contextItem: dateProps } }} />
        )}
        <a href={props?.url?.url} className="top-five-card__link">
          <Text
            tag="div"
            className={`top-five-card__title ${
              props.isFirstItem ? 'top-five-card__title--big' : 'top-five-card__title--small'
            }`}
            field={props.referenceTitle?.jsonValue}
            data-analytics-link-name={props.referenceTitle?.jsonValue.value}
            data-analytics-link-type="Link"
            data-analytics-content-class="Content"
            data-analytics-template-zone="Body"
            data-analytics-component-name="Top Five Events/Webinar"
            data-analytics-component-variation={
              props.isFirstItem ? 'top-five-card__title--big' : 'top-five-card__title--small'
            }
            data-analytics-target={props?.url?.url}
            data-analytics-component-section="NAN"
            data-analytics-slide-number="NAN"
            data-analytics-ispersonalized="False"
            data-analytics-iscarousal="False"
            data-analytics-category={
              props.referenceCategory?.targetItems[0]?.title?.jsonValue?.value
            }
          />
        </a>
        {props.isFirstItem && (
          <RichText
            tag="div"
            className="subheading1 top-five-card__text"
            field={props.referenceText?.jsonValue}
          />
        )}
        <Text tag="div" className="body2 top-five-card__content-type" field={eventType} />
      </div>
    </div>
  );
};

export const TopFiveNewsRoomCard = (
  props: ReferencableItemFields & PostedDateFields
): JSX.Element => {
  console.log('topfivenewsroomcard', props);
  const posted: PostedDateFields = {
    id: props.id,
    postedDate: props.postedDate,
  };

  return (
    <div className="top-five-card">
      {props.isFirstItem && (
        <div className="top-five-card__img">
          <DynamicNextImageWithNullFallback
            field={props.referenceImage?.jsonValue}
            width={752}
            height={423}
            params={{
              styles: 'next-image--16-9',
            }}
          />
        </div>
      )}
      <div className="top-five-card__container">
        <Default params={{ postedLabel: 'false' }} fields={{ data: { contextItem: posted } }} />

        <a href={props?.url?.url} className="top-five-card__link">
          <Text
            tag="div"
            className={`top-five-card__title ${
              props.isFirstItem ? 'top-five-card__title--big' : 'top-five-card__title--small'
            }`}
            field={props.referenceTitle?.jsonValue}
            data-analytics-link-name={props.referenceTitle?.jsonValue.value}
            data-analytics-link-type="Link"
            data-analytics-content-class="Content"
            data-analytics-template-zone="Body"
            data-analytics-component-name="Top Five News Room"
            data-analytics-component-variation={
              props.isFirstItem ? 'top-five-card__title--big' : 'top-five-card__title--small'
            }
            data-analytics-target={props?.url?.url}
            data-analytics-component-section="NAN"
            data-analytics-slide-number="NAN"
            data-analytics-ispersonalized="False"
            data-analytics-iscarousal="False"
          />
        </a>
        {props.isFirstItem && (
          <RichText
            tag="div"
            className="subheading1 top-five-card__text"
            field={props.referenceText?.jsonValue}
          />
        )}
      </div>
    </div>
  );
};
