export interface ContactCountryFields {
  id: string;
  countryName?: {
    jsonValue: {
      value: string;
    };
  };
  countryISO2Name?: {
    value: string;
  };
}
