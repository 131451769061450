/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  buildBreadcrumbManager,
  BreadcrumbValue,
  FacetValue,
  loadPaginationActions,
} from '@coveo/atomic-react';
import { useState, useEffect } from 'react';
import { Icon } from 'components/shared/icon/Icon';
import { IconName, IconSize } from 'src/types/Enum';
import { SearchEngineContext } from '../search-context/SearchContext';
import { useI18n } from 'next-localization';
import { WithSitecoreContextProps, withSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import { useRouter } from 'next/router';
import { useSortFacet } from 'lib/contexts/SortFacetContext';
import { useLink } from 'lib/contexts/AllLinksContext';

interface SearchBreadcrumbsFields {
  IHateLint?: string;
}

type SearchBreadcrumbsProps = {
  params: { [key: string]: string };
  fields: SearchBreadcrumbsFields;
} & WithSitecoreContextProps;

const Default = (props: SearchBreadcrumbsProps): JSX.Element => {
  const { t } = useI18n();

  const searchScope = props.params.SearchScope;
  const { getSearchEngine } = React.useContext(SearchEngineContext);
  const searchEngine = getSearchEngine(searchScope);
  const urlName = useRouter();
  // const engine = React.useContext(SearchEngineContext);
  const paginationCreator = loadPaginationActions(searchEngine);

  const [editingMode] = useState(isEditorActive());
  const [headlessBreadcrumbs] = useState(buildBreadcrumbManager(searchEngine));
  const [breadcrumbsState, setBreadcrumbs] = useState(headlessBreadcrumbs.state);
  const { facetValue } = useSortFacet();
  const { data } = useLink();
  useEffect(() => {
    headlessBreadcrumbs.subscribe(() => {
      setBreadcrumbs(headlessBreadcrumbs.state);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (urlName.asPath.includes('search-jobs')) {
      if (data['searchJobs']) {
        if (window.location.hash == '') {
          if (facetValue == 'relevent') {
            urlName.push(
              String(data['searchJobs'].value.href) +
                '#sortCriteria=%40job_area_of_expertise%20ascending'
            );
          } else if (facetValue == 'recent') {
            urlName.push(
              String(data['searchJobs'].value.href) +
                '#sortCriteria=%40jobposteddate%20descending%2C%40jobname%20ascending'
            );
          }
        }
      }
    }
  }, [urlName.asPath]);

  const getCrumbs = () => {
    let allCrumbs: Array<BreadcrumbValue<FacetValue>> = [];
    breadcrumbsState.facetBreadcrumbs.forEach((facetCrumbs) =>
      facetCrumbs.values.forEach((crumb) => (allCrumbs = [...allCrumbs, crumb]))
    );
    return allCrumbs;
  };

  const deselectAllCrumbs = () => {
    if (searchEngine.state.query?.q !== '') {
      if (searchScope == 'Jobs') {
        searchEngine.dispatch(paginationCreator.registerNumberOfResults(12));
      } else {
        searchEngine.dispatch(paginationCreator.registerNumberOfResults(16));
      }
    } else {
      if (searchScope == 'News room') {
        paginationCreator.registerNumberOfResults(16);
      } else if (searchScope == 'Jobs') {
        paginationCreator.registerNumberOfResults(12);
      } else if (
        searchScope == 'Insights' ||
        searchScope == 'ClientStories' ||
        searchScope == 'Events'
      ) {
        // when deselect filters
        searchEngine.dispatch(paginationCreator.registerNumberOfResults(17));
      } else {
        paginationCreator.registerNumberOfResults(17);
      }
    }
    headlessBreadcrumbs.deselectAll();
    if (urlName.asPath.includes('search-jobs')) {
      if (facetValue === 'recent') {
        urlName.push(data.searchJobs.value.href + window.location.hash);
      }
    }
  };

  useEffect(() => {
    if (urlName.asPath.includes('saved-jobs')) {
      deselectAllCrumbs();
    }
  }, [urlName.asPath]);

  const id = props.params.RenderingIdentifier;

  return (
    <>
      <div
        className={`custom-breadcrumbs ${sitecoreStyles(props.params.styles)}`}
        id={id ? id : undefined}
      >
        {editingMode && props.sitecoreContext.route?.templateName == 'Partial Design' ? (
          <div className="is-metadata-hint">
            <strong>Search:</strong> filters breadcrumbs
          </div>
        ) : (
          <>
            {breadcrumbsState.facetBreadcrumbs.length > 0 && (
              <div className="custom-breadcrumbs__container">
                {getCrumbs().map((crump, index) => (
                  <button
                    className="custom-breadcrumbs__pill"
                    onClick={() => {
                      crump.deselect();
                      if (urlName.asPath.includes('search-jobs')) {
                        if (facetValue === 'recent')
                          urlName.push(data.searchJobs.value.href + window.location.hash);
                      }
                    }}
                    key={index}
                  >
                    <span className="custom-breadcrumbs__pill-text">{crump.value.value}</span>
                    <Icon
                      icon={IconName.Dismiss}
                      iconSize={IconSize.Size16}
                      iconLabel={t('coveo-close')}
                    />
                  </button>
                ))}

                <button className="body2-link" onClick={() => deselectAllCrumbs()}>
                  {t('coveo-clear-all-filters')}
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default withSitecoreContext()(Default);
