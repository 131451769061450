import React from 'react';
import {
  ImageField,
  Field,
  LinkField,
  Text,
  RichText,
  Link,
} from '@sitecore-jss/sitecore-jss-nextjs';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import dynamic from 'next/dynamic';
import config from 'temp/config';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

interface Fields {
  PromoIcon: ImageField;
  PromoText: Field<string>;
  PromoLink: LinkField;
  PromoText2: Field<string>;
}

type PromoProps = {
  params: { [key: string]: string };
  fields?: Fields;
};

// storybook type statement
type StorybookProps = {
  PromoIcon?: ImageField;
  PromoText?: Field<string>;
  PromoLink?: LinkField;
  PromoText2?: Field<string>;
};

const editingmode = isEditorActive();
let DynamicNextImageWithNullFallback;

if (editingmode) {
  // Static import when in editing mode

  DynamicNextImageWithNullFallback =
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    require('components/shared/nextImg/NextImageWithNullFallback').NextImageWithNullFallback;
} else {
  // Dynamic import when not in editing mode
  DynamicNextImageWithNullFallback = dynamic(
    () =>
      import('components/shared/nextImg/NextImageWithNullFallback').then(
        (mod) => mod.NextImageWithNullFallback
      ),
    { ssr: !!config.dynamicImportSsr } // Adjust server-side rendering (ssr: true) based on your config
  );
}
export const Default = (props: PromoProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;

  if (props.fields) {
    return (
      <div
        className={`component promo ${sitecoreStyles(props.params.styles)}`}
        id={id ? id : undefined}
      >
        <div className="component-content">
          <DynamicNextImageWithNullFallback
            field={props.fields.PromoIcon}
            width={24}
            height={24}
            params={{
              styles: 'field-promoicon',
            }}
          ></DynamicNextImageWithNullFallback>

          <div className="promo-text">
            <>
              <div className="field-promotext">
                <RichText field={props.fields.PromoText} />
              </div>
            </>
            <div className="field-promolink">
              <Link
                field={props.fields.PromoLink}
                className="ga-track"
                data-analytics-link-name={`${props.fields.PromoLink.value?.title}`}
                data-analytics-link-type="Link"
                data-analytics-content-class="Content"
                data-analytics-template-zone="Body"
                data-analytics-component-name="Promo"
                data-analytics-component-variation="NAN"
                data-analytics-target={`${props.fields.PromoLink.value?.href}`}
                data-analytics-component-section="NAN"
                data-analytics-slide-number="NAN"
                data-analytics-ispersonalized="False"
                data-analytics-iscarousal="False"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <PromoDefaultComponent {...props} />;
};

export const WithText = (props: PromoProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  if (props.fields) {
    return (
      <div
        className={`component promo ${sitecoreStyles(props.params?.styles)}`}
        id={id ? id : undefined}
      >
        <div className="component-content">
          <DynamicNextImageWithNullFallback
            field={props.fields.PromoIcon}
            width={24}
            height={24}
            params={{
              styles: 'field-promoicon',
            }}
          ></DynamicNextImageWithNullFallback>

          <div className="promo-text">
            <>
              <div className="field-promotext">
                <Text className="promo-text" field={props.fields.PromoText} />
              </div>
            </>
            <div className="field-promotext">
              <Text className="promo-text" field={props.fields.PromoText2} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <PromoDefaultComponent {...props} />;
};

export const PromoDefaultComponent = (props: PromoProps): JSX.Element => (
  <div className={`component promo ${sitecoreStyles(props.params?.styles)}`}>
    <div className="component-content">
      <span className="is-empty-hint">Promo</span>
    </div>
  </div>
);

// storybook markup
export const PromoStorybookComponent = (props: StorybookProps) => (
  <div className={`component promo`}>
    <div className="component-content">
      <DynamicNextImageWithNullFallback
        field={props.PromoIcon}
        width={24}
        height={24}
        params={{
          styles: 'field-promoicon',
        }}
      ></DynamicNextImageWithNullFallback>

      <div className="promo-text">
        <>
          <div className="field-promotext">
            <Text className="promo-text" field={props.PromoText} />
          </div>
        </>
        <div className="field-promotext">
          <Text className="promo-text" field={props.PromoText2} />
        </div>
      </div>
    </div>
  </div>
);
