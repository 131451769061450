import React from 'react';
import { useI18n } from 'next-localization';
import { IconName } from 'src/types/Enum';
import dynamic from 'next/dynamic';
import config from 'temp/config';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

export interface IsFeaturedFields {
  isFeatured: {
    value: boolean;
  };
}
export type IsFeaturedProps = {
  params: { [key: string]: string };
  fields?: {
    data: {
      contextItem?: IsFeaturedFields;
    };
  };
};

export const Default = (props: IsFeaturedProps) => {
  console.log('---->[IsFeaturedProps] Component', props.fields);
  const { t } = useI18n();

  const editingmode = isEditorActive();
  let DynamicIcon;
  if (editingmode) {
    // Static import when in editing mode
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicIcon = require('components/shared/icon/Icon').Icon;
  } else {
    // Dynamic import when not in editing mode
    DynamicIcon = dynamic(() => import('components/shared/icon/Icon').then((mod) => mod.Icon), {
      ssr: !!config.dynamicImportSsr,
    });
  }
  return (
    <>
      <div className="is-featured">
        {props.fields && props.fields?.data?.contextItem?.isFeatured && (
          <>
            {props.fields?.data?.contextItem?.isFeatured?.value == true ? (
              <div className="is-featured__tag body1">
                <DynamicIcon icon={IconName.Sparkle} iconLabel={'Sparkle'} />{' '}
                {t('component-IsFeatured')}
              </div>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};
