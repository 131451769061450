import React from 'react';
import {
  ComponentParams,
  ComponentRendering,
  Placeholder,
} from '@sitecore-jss/sitecore-jss-nextjs';

interface SectionProps {
  rendering: ComponentRendering & { params: ComponentParams };
  params: ComponentParams;
}

export const Default = (props: SectionProps): JSX.Element => {
  const sectionStyles = props.params && props.params.Styles ? props.params.Styles : '';
  const styles = `${sectionStyles}`.trimEnd();
  const phKey = `section-${props.params.DynamicPlaceholderId}`;
  const id = props.params.RenderingIdentifier;

  return (
    <section {...(styles ? { className: styles } : {})} id={id ? id : undefined}>
      <Placeholder name={phKey} rendering={props.rendering} />
    </section>
  );
};
