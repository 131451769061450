import React from 'react';
import {
  TextImageRightSquareTimage,
  TimageProps,
} from 'components/timage/variants/text-image/TimageRightSquare';

export const TextImageRightSquareBlackH3Timage = (props: TimageProps): JSX.Element => {
  return (
    <div className="timage-right-square-black-h3">
      <TextImageRightSquareTimage {...props} />
    </div>
  );
};
