import { ImageField, RichTextField } from '@sitecore-jss/sitecore-jss-nextjs';
import {
  AnswerLevels,
  AnswerChoices,
  SurveyInput,
  SurveyQuestion,
  SurveyQuestionHeader,
  SurveyQuestionPage,
  SurveyAnswer,
} from '../survey-assessment/AssessmentType';
import {
  AssessmentFields,
  AssessmentLevelFields,
  AssessmentResultFields,
  AssessmentAnswerFields,
} from '../survey-assessment/Assessment';
import { useI18n } from 'next-localization';
import { Model } from 'survey-core';

//Function to convert the survey data from CMS into survey model
export const GetSurveyInput = (surveyinputfields: AssessmentFields): SurveyInput => {
  const { t } = useI18n();
  const startPage: SurveyQuestionHeader = {
    type: 'html',
    html: imageContainer(
      surveyinputfields.mainImage?.jsonValue,
      surveyinputfields.mainDescription?.jsonValue
    ),
  };
  const pagesArray: (SurveyQuestionHeader | SurveyQuestionPage)[] = [
    {
      elements: [startPage],
    },
  ];

  const noofquestions = surveyinputfields.children?.results?.length;

  // loop through each question and convert the data from CMS into survey model question format
  noofquestions &&
    surveyinputfields.children.results.forEach(function (q, index) {
      const pageHeader: string = t(`survey-page-x-of-y`)
        .replace('{X}', (index + 1).toString())
        .replace('{Y}', noofquestions.toString());
      const questionHeader: SurveyQuestionHeader = {
        type: 'html',
        html: `<div class="progress-buttons-wrapper"><div class="sd-progress-buttons__page-title">${pageHeader}</div></div>`,
      };
      const questionData: SurveyQuestion = {
        name: q.qName?.jsonValue?.value,
        title: q.qContent?.jsonValue?.value,
        type: 'radiogroup',
        choices: q.children?.results && GetAnswerChoices(q.children.results),
        isRequired: true,
        requiredErrorText: t(`survey-question-error`),
      };
      const questionPage: SurveyQuestionPage = {
        elements: [questionHeader, questionData],
      };
      pagesArray.push(questionPage); // append each formatted question to the main pages array
    });

  const surveyJson: SurveyInput = {
    pages: pagesArray,
    showQuestionNumbers: 'off',
    pageNextText: t(`survey-next`),
    pagePrevText: t(`survey-previous`),
    completeText: t(`survey-next`),
    showPrevButton: true,
    firstPageIsStarted: true,
    startSurveyText: surveyinputfields.startButtonText?.jsonValue?.value,
  };
  return surveyJson;
};

// Function to convert the answers for a given question from CMS to survey model answer format
const GetAnswerChoices = (answerfields: AssessmentAnswerFields[]): SurveyAnswer[] => {
  const answerChoices: SurveyAnswer[] = [];
  answerfields.forEach(function (ans) {
    const selectedLevels: AnswerLevels[] = [];
    const selectedLevelFields: AssessmentLevelFields[] = ans.ansLevel?.targetItems;
    selectedLevelFields.forEach(function (level) {
      const ansLevelValue: AnswerLevels = {};
      ansLevelValue[level.id] = parseInt(level.levelValue?.jsonValue?.value); //add answer level id and level value pair
      selectedLevels.push(ansLevelValue);
    });
    const answerChoice: AnswerChoices = {
      ansTitle: ans.ansTitle?.jsonValue?.value,
      answerLevels: selectedLevels,
    }; //add answer title to each answer choice to make it unique
    const answer: SurveyAnswer = {
      text: ans.ansTitle?.jsonValue?.value,
      value: JSON.stringify(answerChoice),
    };
    answerChoices.push(answer);
  });
  return answerChoices;
};

// Function to map survey question and selected response
export const GetSurveyResponse = (surveyResult: Model): string => {
  let responseMap = '';
  if (surveyResult) {
    const results = surveyResult.data;
    for (const key in results) {
      const question = surveyResult.getQuestionByName(key);
      const ansChoice: AnswerChoices = JSON.parse(question?.value);
      responseMap = responseMap + `${question.title} : ${ansChoice.ansTitle} ^ `;
    }
  }
  return responseMap;
};

// Function to get qualifier level mapping
const GetQualifierLevels = (
  qualifier: AssessmentResultFields | undefined
): AnswerLevels | undefined => {
  if (qualifier) {
    const qualLevelMapping: AnswerLevels = {}; // define an index signature for qualifier criteria - level id to level value mapping
    const combinedQualLevels: string = qualifier.levelResultMapping?.jsonValue?.value
      .replaceAll('%7B', '')
      .replaceAll('%7D', '')
      .replaceAll('-', '');
    const qualLevels: string[] = combinedQualLevels.split('&'); //split qualifier criteria into individual criterion
    qualLevels.forEach(function (ql) {
      const qual: string[] = ql.split('=');
      if (qual.length == 2) {
        qualLevelMapping[qual[1]] = Number(qual[0]) || 0;
      }
    }); // convert the qualifier criterion into key value pairs
    return qualLevelMapping;
  }
  return undefined;
};

// Function to calculate assessment results
export const GetSurveyResults = (
  survey: Model,
  qualifiers: AssessmentResultFields[]
): AssessmentResultFields | undefined => {
  if (survey) {
    const results = survey.data;
    const levelOccurrences: AnswerLevels = {}; // define an index signature for mapping level id with level occurrences
    for (const key in results) {
      const question = survey.getQuestionByName(key);
      const ansChoice: AnswerChoices = JSON.parse(question?.value); // get selected answer choice for individual question
      const ansLevels: AnswerLevels[] = ansChoice?.answerLevels; // get array of individual levels and their values for the answer choice
      ansLevels?.forEach(function (level) {
        for (const k in level) {
          if (k in levelOccurrences) {
            levelOccurrences[k] = levelOccurrences[k] + level[k];
          } else levelOccurrences[k] = level[k];
        }
      }); // check occurrence of each level, if already present then add the level value, else add a new key value pair with level id and level value
    }

    qualifiers.sort(
      (a, b) => (a.sortOrder.jsonValue.value || 0) - (b.sortOrder.jsonValue.value || 0)
    ); // sort the qualifier items on basis of sort order defined in CMS

    if (qualifiers) {
      for (const qf of qualifiers) {
        let flag = true;
        const qualLevelMapping = GetQualifierLevels(qf);
        if (qualLevelMapping) {
          for (const k in qualLevelMapping) {
            if (!(k in levelOccurrences && levelOccurrences[k] >= qualLevelMapping[k])) {
              flag = false;
              break;
            }
          } // check if the level occurrences key value pair satisfies individual criterion, if not then continue evaluation for next qualifier criterion
          if (flag) {
            return qf; // return qualifier set
          }
        }
      }
    }
  }

  return undefined;
};

//function returning image and discription in 1st slide of start assessment
export const imageContainer = (image: ImageField, mainDescription: RichTextField) => {
  let mediaUrl = image.value?.src;
  if (!mediaUrl?.startsWith('http')) {
    mediaUrl = '/' + mediaUrl;
  }
  return `<div class="assessment-img-container"><img class="assessment-img" src="${image.value?.src}" /></div>
  <div><p>${mainDescription?.value}</p></div>`;
};
