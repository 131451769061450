const jssConfig = require('./src/temp/config');
const plugins = require('./src/temp/next-config-plugins') || {};

const publicUrl = jssConfig.publicUrl;

/**
 * @type {import('next').NextConfig}
 */
const nextConfig = {
  // Set assetPrefix to our public URL
  assetPrefix: publicUrl,
  poweredByHeader: false,
  productionBrowserSourceMaps: true,
  compiler: {
    removeConsole:
      process.env.NEXTJS_REMOVE_CONSOLE_LOGS == 'true'
        ? {
            exclude: ['error'],
          }
        : false,
  },
  // Allow specifying a distinct distDir when concurrently running app in a container
  distDir: process.env.NEXTJS_DIST_DIR || '.next',

  webpack: (config, { isServer }) => {
    // Fixes npm packages that depend on `fs` module
    // https://stackoverflow.com/questions/64926174/module-not-found-cant-resolve-fs-in-next-js-application
    config.resolve.fallback = {
      ...config.resolve.fallback, // if you miss it, all the other options in fallback, specified
      // by next.js will be dropped. Doesn't make much sense, but how it is
      fs: false, // the solution
      net: false,
      tls: false,
      dns: false,
      assert: false,
    };
    //
    // config.module.rules.push({
    //   test: /\.svg$/,
    //   use: ['@svgr/webpack'],
    // });

    return config;
  },

  // Make the same PUBLIC_URL available as an environment variable on the client bundle
  env: {
    PUBLIC_URL: publicUrl,
  },

  i18n: {
    // These are all the locales you want to support in your application.
    // These should generally match (or at least be a subset of) those in Sitecore.
    locales: [
      'default',
      'en',
      'de-de',
      'de-at',
      'en-ae',
      'en-au',
      'en-be',
      'en-ca',
      'en-ch',
      'en-dk',
      'en-fi',
      'en-gb',
      'en-id',
      'en-ie',
      'en-my',
      'en-nl',
      'en-no',
      'en-pl',
      'en-se',
      'en-sg',
      'en-th',
      'en-us',
      'es-es',
      'fr-ca',
      'fr-fr',
      'it-it',
      'ja-jp',
      'pt-br',
      'pt-pt',
      'zh-cn',
    ],
    // This is the locale that will be used when visiting a non-locale
    // prefixed path e.g. `/styleguide`.
    defaultLocale: 'default', //jssConfig.defaultLanguage,
    localeDetection: false,
  },

  // Enable React Strict Mode
  reactStrictMode: true,

  // use this configuration to ensure that only images from the whitelisted domains
  // can be served from the Next.js Image Optimization API
  // see https://nextjs.org/docs/app/api-reference/components/image#remotepatterns
  images: {
    dangerouslyAllowSVG: true,
    formats: ['image/avif', 'image/webp'],
    remotePatterns: [
      {
        protocol: 'https',
        hostname: 'edge*.**',
        port: '',
      },
      {
        protocol: 'https',
        hostname: '**.brightcove.com',
      },
      {
        protocol: 'https',
        hostname: 'feaas*.blob.core.windows.net',
        port: '',
        },
      {
        protocol: 'https',
        hostname: '**.sitecorecontenthub.cloud',
        port: '',
      },
      {
        protocol: 'https',
        hostname: 'xmc-*.**',
        port: '',
      },
      {
        protocol: 'https',
        hostname: '**.avanade.com',
        port: '',
      },
      {
        protocol: 'https',
        hostname: 'cf-images.us-east-1.prod.boltdns.net',
      },
    ]
  },

  async rewrites() {
    // When in connected mode we want to proxy Sitecore paths off to Sitecore
    return [
      // API endpoints
      {
        source: '/sitecore/api/:path*',
        destination: `${jssConfig.sitecoreApiHost}/sitecore/api/:path*`,
      },
      // media items
      {
        source: '/-/:path*',
        destination: `${jssConfig.sitecoreApiHost}/-/:path*`,
      },
      // visitor identification
      {
        source: '/layouts/system/:path*',
        destination: `${jssConfig.sitecoreApiHost}/layouts/system/:path*`,
      },
      // healthz check
      {
        source: '/healthz',
        destination: '/api/healthz',
      },
      // rewrite for Sitecore service pages
      {
        source: '/sitecore/service/:path*',
        destination: `${jssConfig.sitecoreApiHost}/sitecore/service/:path*`,
      },
    ];
  },

  async headers() {
    return [
      {
        source: '/:path*{/}?',
        headers: [
          {
            key: 'Access-Control-Allow-Headers',
            value: 'x-requested-with',
          },
          {
            key: 'Access-Control-Allow-Methods',
            value: 'GET, OPTIONS',
          },
          {
            key: 'Access-Control-Allow-Origin',
            value: 'https://www.avanade.cn',
          },
          {
            key: 'Content-Security-Policy',
            value:
            "script-src 'unsafe-inline' 'unsafe-eval' * blob:; \
             frame-src 'unsafe-inline' *; \
             connect-src 'unsafe-inline' 'unsafe-eval' *; \
             style-src 'unsafe-inline' *; \
             frame-ancestors 'self' https://*.sitecorecloud.io; \
             img-src * data:; \
             upgrade-insecure-requests;",
          },
          {
            key: 'Strict-Transport-Security',
            value: 'max-age=31536000; includeSubdomains; preload',
          },
          {
            key: 'X-Content-Type-Options',
            value: 'nosniff',
          },
          {
            key: 'X-Xss-Protection',
            value: '1; mode=block',
          },
        ],
      },
    ];
  },
  async redirects() {
    return [
      {
        source: '/career/job-details',
        destination: '/career/search-jobs',
        permanent: true,
      },
    ];
  },
};

module.exports = () => {
  // Run the base config through any configured plugins
  return Object.values(plugins).reduce((acc, plugin) => plugin(acc), nextConfig);
};
