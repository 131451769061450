import { AtomicSearchInterface, SearchEngine } from '@coveo/atomic-react';
import { useI18n } from 'next-localization';
import React from 'react';
import { capitalizeLanguageRegion } from 'lib/utils';

export type AtomicPageWrapperProps = {
  engine: SearchEngine;
  children?: React.ReactNode;
};

export const AtomicPageWrapper = (props: AtomicPageWrapperProps) => {
  const { locale, table } = useI18n();
  const currentLocale = capitalizeLanguageRegion(locale());
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const coveoLocalization: any = {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const translations = table(currentLocale) as any;
  Object.keys(translations).forEach((propertyName: string) => {
    if (propertyName.startsWith('coveo-')) {
      const newPropertyName = propertyName.replace('coveo-', '');
      coveoLocalization[newPropertyName] = translations[propertyName];
    }
  });
  // different placeholder for jobs search box
  const currentPage = window.location?.href;
  if (currentPage?.includes('search-jobs') || currentPage?.includes('saved-jobs')) {
    coveoLocalization['search'] = translations['career-job-search-for-keyword'];
  }
  return (
    <>
      {props.children && (
        <AtomicSearchInterface
          iconAssetsPath=""
          engine={props.engine}
          fieldsToInclude={[
            'jobid',
            'job_id',
            'jobareaofexpertise',
            'jobcountry',
            'title',
            'jobdescription',
          ]}
          language={currentLocale}
          localization={(i18) => {
            i18.addResourceBundle(currentLocale, 'translation', coveoLocalization);
          }}
        >
          {props.children}
        </AtomicSearchInterface>
      )}
    </>
  );
};
