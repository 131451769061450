/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { LinkField, LinkFieldValue, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useState, useEffect, useContext } from 'react';
import { CardProps } from 'components/card/Card';
import { useI18n } from 'next-localization';
import { Tertiary, TertiaryWithDownArrow } from 'components/link/Link';
import { useBookmark } from '../../../../lib/contexts/BookmarkContext';
import { SocialBlockModal } from '../../../shared/social-share-block/socialShare';
import { CoveoSearchApiCareersResult } from '../../../../lib/coveo/CoveoSearchApi';
import { Default } from '../../../search/career-job-search/CareerJobSearch';
import totalContext from 'src/utils/totalValueContext';
import { usePathname } from 'next/navigation';
import parse from 'html-react-parser';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

export const DefaultJobCard = (props: CardProps): JSX.Element => (
  <div className={`component promo ${props.params.styles}`}>
    <div className="component-content">
      <span className="is-empty-hint">Card</span>
    </div>
  </div>
);

const GetLoadMoreLink = () => {
  const { t } = useI18n(); /*Dictionary*/
  const linkFieldValue: LinkFieldValue = {};
  linkFieldValue.href = 'javascript:void(0);';
  linkFieldValue.text = t(`career-job-load-more`);
  return linkFieldValue;
};

let jobLangCode = 1;

export const Job = (props: CardProps): JSX.Element => {
  console.log('jobs props', props);
  const [apiData, setApiData] = useState<CoveoSearchApiCareersResult[]>([]);
  const { toggleBookmark, bookmarkedCards } = useBookmark();
  const { totalCount } = useContext<any>(totalContext);
  const [selectedRadio, setSelectedRadio] = useState<string>('relevant');
  const [loadValue, setLoadValue] = useState<number>(12);
  const [searchData, setSearchData] = useState<any>([]);
  const [newCount, setNewCount] = useState<number>(totalCount);
  const [loadLength, setLoadLength] = useState(12);
  const [expertise, setExpertise] = useState<any>([]);

  const initialCardList = 12; // Number of cards to display initially
  const incrementInitialCardList = 12; // Number of cards to add each time the "load more" button is clicked
  const [displayCards, setDisplayCards] = useState(initialCardList);
  const [consent, setConsent] = useState<boolean>(false);

  const { t } = useI18n(); /*Dictionary*/

  const router = useRouter();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, [isLoaded]);

  const jobLanguage = router.locale && router.locale.toLowerCase();
  if (jobLanguage) {
    if (jobLanguage == 'fr-ca') {
      jobLangCode = 11;
    }
    if (jobLanguage == 'pt-br') {
      jobLangCode = 13;
    }
  }

  const jobdetailslink = props.fields?.data?.datasource?.link?.jsonValue?.value?.href;

  const loadMoreLink: LinkField = {
    value: GetLoadMoreLink(),
  };

  const showingJobRelatedTo = (arg: string, x: string, y: string): string => {
    const templateString = t(arg);
    const newString = templateString.replace('{X}', x);
    const finalString = newString.replace('{Y}', y);
    return finalString;
  };

  const currentPath = usePathname();
  const recSearchData = (dataArray: any) => {
    setSearchData(dataArray);
  };

  // / getting expertise value from child starts
  const reExp = (expArr: any) => {
    setExpertise(expArr);
  };

  const resetLoadMoreValue = (val: any) => {
    setLoadValue(val);
  };

  const expertiseValue = expertise.filter((expId: any) => {
    if (searchData[2] && searchData[2].value) {
      return expId['value'] == searchData[2].value;
    } else {
      return null;
    }
  });

  // getting expertise value from child end

  useEffect(() => {
    const consentFromLocalStorage = JSON.parse(localStorage.getItem('CookieState') || '')
      ? JSON.parse(localStorage?.getItem('CookieState') || '')
      : false;

    setConsent(consentFromLocalStorage);

    const storedRadioValue = (!consent && localStorage?.getItem('selectedRadio')) || '"relevant"';

    if (!consent) {
      setSelectedRadio(JSON.parse(storedRadioValue));
    }
    const storedCardData = localStorage?.getItem('cardApi');
    if (storedCardData !== null) {
      setTimeout(() => {
        setApiData(JSON.parse(storedCardData));
      }, 300);
    }
  }, []);
  // Radio Starts

  const countryList = searchData[1] && searchData[1]?.map((item: any) => item.label)?.join(', ');

  const handleRadioChange = async (event: { target: { value: any; name: string } }) => {
    const selectedValue = event.target.value;
    setLoadLength(12);
    setLoadValue(12);
    setSelectedRadio(selectedValue);
    if (!consent) {
      localStorage?.setItem('selectedRadio', JSON.stringify(selectedValue));
    }
    // Check if the current path matches the specific URL
    if (currentPath !== '/career/saved-jobs') {
      const reqListCount =
        searchData.length > 0 && searchData[1]?.map((item: any) => item.id)?.join(', ');
      const sortCriteria = selectedRadio === 'relevant' ? 0 : 1;
      const updateRadioData = await GetJobList({
        keyword: searchData[0],
        areaOfExpertise: searchData[2],
        LoadMoreValue: loadValue,
        country: reqListCount,
        sortCriteria: sortCriteria,
        jobLocale: jobLangCode,
        siteCountry: jobLanguage,
      });
      localStorage?.setItem('cardApi', JSON.stringify(updateRadioData.results));
      setApiData(updateRadioData.results);
    } else if (currentPath.includes('/career/saved-jobs')) {
      const sortCriteria = selectedRadio === 'relevant' ? 0 : 1;
      const updatedSavedRadioData = await GetSavedJobList({
        jobList: await JSON.parse(localStorage?.getItem('bookmarkedCards') || '[]')
          ?.map((item: any) => item)
          ?.join(','),
        sortCriteria: sortCriteria,
        jobLocale: jobLangCode,
      });
      setApiData(updatedSavedRadioData.results);
    }
  };

  // Radio button ends

  // LoadMore starts ⬇︝
  const loadMore = async () => {
    setLoadValue(loadValue + 12);
    const sortCriteriax = selectedRadio === 'relevant' ? 1 : 0;
    const initialApiData = apiData;
    const reqListCount =
      searchData.length > 0 && searchData[1]?.map((item: any) => item.id)?.join(', ');
    const updateData = await GetJobList({
      keyword: searchData[0],
      country: reqListCount,
      areaOfExpertise: searchData[2],
      LoadMoreValue: loadLength,
      sortCriteria: sortCriteriax,
      initialLoad: loadValue,
      jobLocale: jobLangCode,
      siteCountry: jobLanguage,
    });

    const updatedArrayData = [...initialApiData, ...updateData.results];
    setApiData(updatedArrayData);

    setNewCount(updateData.totalCount);
    setDisplayCards(displayCards + incrementInitialCardList);
  };

  // Load More functionality Ends ⬆︝

  const handleBookmarkClick = (cardId: number) => {
    toggleBookmark(cardId);
  };

  //bookmark functionality ends
  const [editingMode] = useState(isEditorActive());

  // Share functionality - End
  const sendData = async (data: any) => {
    const localCardData = await JSON.parse(localStorage?.getItem('cardApi') || '[]');
    if (localCardData) {
      setApiData(data);

      return;
    } else {
      setApiData(data);
    }
  };

  const noResultClearFilter = () => {
    const clearFilterElements: HTMLCollectionOf<Element> =
      document.getElementsByClassName('clearFilter');
    (clearFilterElements[0] as HTMLElement).click();
  };

  const titleContent = [searchData[0], countryList, expertiseValue[0] && expertiseValue[0].label]
    .filter(Boolean)
    .join(', ');
  const hasTooltip = titleContent !== '';

  const datasource = props.fields?.data?.datasource;

  if (!datasource) {
    return <></>;
  }

  return (
    <>
      <div
        className={
          'job-cards-container ' + (props.params?.isJobDetail === 'true' ? 'recommended-jobs' : '')
        }
      >
        {props.params?.isJobDetail === 'true' ? (
          <div className="job-alerts col-12">
            <h3>{t(`career-job-roles-that-interest-you`)}</h3>
          </div>
        ) : null}

        <div className="job-cards-container-internal">
          {props.params?.isJobDetail === 'true' ? (
            props.data?.map((carddata) => {
              return (
                <div
                  className={`card insight-card job-card insight-card__default-container`}
                  id={`${carddata.raw.jobid ? carddata.raw.jobid : undefined}`}
                  key={`${carddata.raw.jobid ? carddata.raw.jobid : undefined}`}
                  onClick={
                    !editingMode && jobdetailslink && jobdetailslink !== ''
                      ? () => {
                          window.open(jobdetailslink + '/' + carddata.raw.jobid, '_self');
                        }
                      : () => null
                  }
                >
                  <div className="insight-card__text-container">
                    <div className="body2 job-card__tag">
                      <p className="job-card__sub-title">{carddata.raw.jobareaofexpertise}</p>
                      <div
                        id={`${carddata.raw.jobid ? carddata.raw.jobid : undefined}`}
                        className={`bookmarkIcon ${
                          bookmarkedCards.includes(carddata.raw.jobid)
                            ? 'bookmarkIconSelected'
                            : 'bookmarkIconNotSelected'
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          !consent && handleBookmarkClick(carddata.raw.jobid);
                        }}
                        data-analytics-link-name="Bookmark Job Card"
                        data-analytics-link-type="Bookmark"
                        data-analytics-content-class="Job Card"
                        data-analytics-template-zone="Body"
                        data-analytics-component-name="Job Card Bookmark"
                        data-analytics-component-variation="Card"
                        data-analytics-target={`${
                          carddata.raw.jobid ? carddata.raw.jobid : undefined
                        }`}
                        data-analytics-component-section="Job Card"
                        data-analytics-slide-number="NAN"
                        data-analytics-ispersonalized="False"
                        data-analytics-iscarousal="False"
                        data-analytics-job-title={carddata.raw.title}
                        data-analytics-job-region={carddata.raw.jobcountry.replaceAll('^', ', ')}
                        data-analytics-job-sub-title={carddata.raw.jobareaofexpertise}
                        data-analytics-job-id={`${
                          carddata.raw.jobid ? carddata.raw.jobid : undefined
                        }`}
                      >
                        {' '}
                      </div>
                    </div>
                    <div className="job-card__title body1">{carddata.raw.title}</div>
                    <p className="job-card__Country">
                      {carddata.raw.jobcountry.replaceAll('^', ', ')}
                    </p>
                    <div className="body2 job-card__body">
                      {carddata.raw.jobdescription && parse(carddata.raw.jobdescription)}
                    </div>
                  </div>
                  <div>
                    {jobdetailslink && jobdetailslink !== '' && carddata?.raw?.jobid && (
                      <>
                        <Tertiary
                          //fields={{ Link: getCardFieldsData().link.jsonValue }}
                          fields={{
                            Link: {
                              value: {
                                text: t(`component-contentgallery-readmore`),
                                href: jobdetailslink + carddata.raw.jobid,
                              },
                            },
                          }}
                          params={{}}
                        ></Tertiary>
                        <SocialBlockModal
                          shareLinkUrls={jobdetailslink + carddata.raw.jobid}
                          shareLinkTitle={carddata.raw.title}
                        />
                      </>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <>
              <totalContext.Provider value={{ totalCount: newCount, setNewCount }}>
                <Default
                  sendData={sendData}
                  loadValue={loadValue}
                  recSearchData={recSearchData}
                  selectedRadio={selectedRadio}
                  reExp={reExp}
                  resetLoadMoreValue={resetLoadMoreValue}
                />
              </totalContext.Provider>
              {apiData.length > 0 ? (
                <>
                  <div className="showing-results-container">
                    <div className="showing-results-text">
                      <h5
                        className={`showing-result-heading ${hasTooltip ? 'has-tooltip' : ''}`}
                        title={titleContent}
                      >
                        {currentPath == '/career/saved-jobs'
                          ? `${showingJobRelatedTo(
                              'career-job-showing-saved-jobs',
                              newCount.toString(),
                              newCount.toString()
                            )}`
                          : searchData[0] || countryList || expertiseValue.length != 0
                          ? `${showingJobRelatedTo(
                              'career-job-showing-related-to',
                              displayCards > apiData.length
                                ? String(apiData.length)
                                : String(displayCards),
                              String(newCount)
                            )} "${
                              searchData[0] &&
                              `${searchData[0]}${
                                countryList.length !== 0
                                  ? ', '
                                  : expertiseValue[0] != undefined
                                  ? ', '
                                  : ''
                              }`
                            }${
                              countryList.length === 0 || countryList == false
                                ? ''
                                : `${countryList.replace(/,/g, ',')}${
                                    expertiseValue[0] != undefined ? ', ' : ''
                                  }`
                            }${
                              expertiseValue.length == 0
                                ? ''
                                : expertiseValue && expertiseValue[0].label
                            }"`
                          : selectedRadio === 'recent'
                          ? `${showingJobRelatedTo(
                              'career-job-showing-recent-jobs',
                              displayCards > apiData.length
                                ? String(apiData.length)
                                : String(displayCards),
                              String(newCount)
                            )}`
                          : `${showingJobRelatedTo(
                              'career-job-showing-relevant-jobs',
                              displayCards > apiData.length
                                ? String(apiData.length)
                                : String(displayCards),
                              String(newCount)
                            )}`}
                      </h5>
                    </div>
                    <div className="radio-button-container">
                      <input
                        type="radio"
                        name="radio"
                        value="recent"
                        checked={selectedRadio === 'recent'}
                        onChange={handleRadioChange}
                      />
                      <div className="radio-button-text">{t(`career-job-recent`)}</div>

                      <input
                        type="radio"
                        name="radio"
                        value="relevant"
                        checked={selectedRadio === 'relevant'}
                        onChange={handleRadioChange}
                      />
                      <div className="radio-button-text">{t(`career-job-relevant`)}</div>
                    </div>
                  </div>
                  <div className="job-cards-container-cards">
                    {apiData.map((carddata) => {
                      return (
                        <div
                          className={`card insight-card job-card insight-card__default-container`}
                          id={`${carddata.raw.job_id ? carddata.raw.job_id : undefined}`}
                          key={`${carddata.raw.job_id ? carddata.raw.job_id : undefined}`}
                          onClick={
                            !editingMode && jobdetailslink && jobdetailslink !== ''
                              ? () => {
                                  window.open(jobdetailslink + '/' + carddata.raw.jobid, '_self');
                                }
                              : () => null
                          }
                        >
                          <div className="insight-card__text-container">
                            <div className="body2 job-card__tag">
                              <p className="job-card__sub-title">
                                {carddata.raw.jobareaofexpertise}
                              </p>
                              <div
                                id={`${carddata.raw.jobid ? carddata.raw.jobid : undefined}`}
                                className={`bookmarkIcon ${
                                  bookmarkedCards.includes(carddata.raw.jobid)
                                    ? 'bookmarkIconSelected'
                                    : 'bookmarkIconNotSelected'
                                }`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  !consent && handleBookmarkClick(carddata.raw.jobid);
                                }}
                                data-analytics-link-name="Bookmark Job Card"
                                data-analytics-link-type="Bookmark"
                                data-analytics-content-class="Job Card"
                                data-analytics-template-zone="Body"
                                data-analytics-component-name="Job Card Bookmark"
                                data-analytics-component-variation="Card"
                                data-analytics-target={`${
                                  carddata.raw.jobid ? carddata.raw.jobid : undefined
                                }`}
                                data-analytics-component-section="Job Card"
                                data-analytics-slide-number="NAN"
                                data-analytics-ispersonalized="False"
                                data-analytics-iscarousal="False"
                                data-analytics-job-title={carddata.raw.title}
                                data-analytics-job-region={carddata.raw.jobcountry}
                                data-analytics-job-sub-title={carddata.raw.jobareaofexpertise}
                                data-analytics-job-id={`${
                                  carddata.raw.jobid ? carddata.raw.jobid : undefined
                                }`}
                              >
                                {' '}
                              </div>
                            </div>
                            <div className={`job-card__title body1`}>{carddata.raw.title}</div>
                            <p className="job-card__Country">
                              {carddata.raw.jobcountry.replaceAll('^', ', ')}
                            </p>
                            <div className="body2 job-card__body">
                              {carddata.raw.jobdescription && parse(carddata.raw.jobdescription)}
                            </div>
                          </div>
                          <div>
                            {jobdetailslink && jobdetailslink !== '' && carddata?.raw?.jobid && (
                              <>
                                <Tertiary
                                  //fields={{ Link: getCardFieldsData().link.jsonValue }}
                                  fields={{
                                    Link: {
                                      value: {
                                        text: t(`component-contentgallery-readmore`),
                                        href: jobdetailslink + '/' + carddata.raw.jobid,
                                      },
                                    },
                                  }}
                                  params={{}}
                                ></Tertiary>
                                <SocialBlockModal
                                  shareLinkUrls={jobdetailslink + '/' + carddata.raw.jobid}
                                  shareLinkTitle={carddata.raw.title}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                <div>
                  <div className="col-12 no-results title-banner">
                    <div className="title-banner__content">
                      <h3 className="no-results__title">
                        {props?.fields?.data?.datasource?.title?.jsonValue.value}
                      </h3>
                      {/*<div className="body2 title-banner__description">*/}
                      {/*<p>We appreciate your interest in Avanade.</p>*/}
                      {/*<p>*/}
                      {/*{props?.fields?.data?.datasource?.body?.jsonValue.value}*/}
                      {/*For requests that require a personal response, we will make every effort*/}
                      {/*to respond via email or phone in a timely manner.*/}
                      <RichText
                        tag="div"
                        className="no-results__description"
                        field={props?.fields?.data?.datasource?.body?.jsonValue}
                      />

                      {currentPath != '/career/saved-jobs' && isLoaded ? (
                        <Link
                          className={`link__button-primary noResult_clearFilter`}
                          href={`javascript:void(0)`}
                          onClick={noResultClearFilter}
                        >
                          {' '}
                          {t(`coveo-clear-all-filters`)}
                        </Link>
                      ) : (
                        <></>
                      )}

                      {/*</p>*/}
                      {/*</div>*/}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {props.params?.isJobDetail === 'true' && props?.datalinks?.seealljobs ? (
          <div className="all-jobs col-12">
            <Tertiary
              fields={{
                Link: {
                  value: {
                    text: t(`career-job-view-all-jobs`),
                    href: props.datalinks.seealljobs,
                  },
                },
              }}
              params={{}}
            ></Tertiary>
          </div>
        ) : currentPath.includes('saved-jobs') ? null : apiData.length < newCount ? (
          // Load More ⬇︝
          <div className="load-more-container col-12">
            <div onClick={loadMore}>
              <TertiaryWithDownArrow
                fields={{ Link: loadMoreLink }}
                params={{}}
              ></TertiaryWithDownArrow>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export const GetJobList = async ({
  keyword,
  country,
  areaOfExpertise,
  sortCriteria,
  initialLoad = 0,
  jobLocale,
  siteCountry = '',
}: any) => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const coveoModule = require('lib/coveo/CoveoSearchApi');
  const CoveoSearchRequest = coveoModule.CoveoSearchRequest;
  // Construct your query here, including city and areaOfExpertise if provided
  const constantQuery = `@job_item_type == ActiveJobs AND @joblanguageid == ${jobLocale} AND @isignoredjob == 0`;
  const isValidSiteCountry = siteCountry && siteCountry != '' && siteCountry != 'en';
  const countryFilter = country
    ? `(@jobcountryid = (${country}) OR @jobcityid=(${country}))`
    : isValidSiteCountry && !keyword && !areaOfExpertise
    ? `@sitecorecountry = ${siteCountry}`
    : null;
  const queryArray = [];
  keyword && queryArray.push(`${keyword}`);
  /* country && queryArray.push(`(@jobcountryid=(${country}) OR @jobcityid=(${country}))`);*/
  areaOfExpertise && queryArray.push(`@jobexpertiseid == (${areaOfExpertise.value})`);
  countryFilter && queryArray.push(countryFilter);
  const queryString = queryArray.length > 0 ? queryArray.join(' AND ') : '';
  //Radio Btn
  let sortField;
  if (sortCriteria === 1) {
    sortField = '@job_area_of_expertise ascending';
  } else {
    sortField = '@jobposteddate descending, @jobname ascending';
  }

  let results = await CoveoSearchRequest({
    cq: constantQuery,
    q: queryString,
    firstResult: initialLoad,
    numberOfResults: 12,
    sortCriteria: sortField,
    // sortCriteria: sortField,
    //  sortCriteria: relevant ? '@jobareaofexpertise ascending' : '@jobposteddate descending', //if jobposteddate is same then sort on basis of title
    //locale: lang
    // Add other parameters as needed
  });
  if (countryFilter?.includes(siteCountry) && results?.totalCount == 0) {
    const queryArrayWithoutLocation = queryArray.filter((q) => !q.includes(siteCountry));
    const queryWithoutLocation =
      queryArrayWithoutLocation.length > 0 ? queryArrayWithoutLocation.join(' AND ') : '';
    results = await CoveoSearchRequest({
      cq: constantQuery,
      q: queryWithoutLocation,
      firstResult: initialLoad,
      numberOfResults: 12,
      sortCriteria: sortField,
      // sortCriteria: sortField,
      //  sortCriteria: relevant ? '@jobareaofexpertise ascending' : '@jobposteddate descending', //if jobposteddate is same then sort on basis of title
      //locale: lang
      // Add other parameters as needed
    });
  }
  return results;
};
export const GetSavedJobList = async ({
  jobList,
  sortCriteria,
  initialLoad = 0,
  jobLocale,
}: any) => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const coveoModule = require('lib/coveo/CoveoSearchApi');
  const CoveoSearchRequest = coveoModule.CoveoSearchRequest;
  // Construct your query here, including city and areaOfExpertise if provided
  const constantQuery = `@job_item_type == ActiveJobs AND @joblanguageid == ${jobLocale} AND @isignoredjob == 0`;
  const queryString = `@jobid==(${jobList})`;

  //Radio Btn
  let sortField;
  if (sortCriteria === 1) {
    sortField = '@job_area_of_expertise ascending';
  } else {
    sortField = '@jobposteddate descending, @jobname ascending';
  }
  const bookmarkCardValue = localStorage?.getItem('bookmarkedCards');
  const bookmarkCardArray = eval(bookmarkCardValue!);
  const bookmarkCardCount = bookmarkCardArray && bookmarkCardArray.length;
  const results = await CoveoSearchRequest({
    cq: constantQuery,
    q: queryString,
    firstResult: initialLoad,
    numberOfResults: bookmarkCardCount,
    sortCriteria: sortField,
    // sortCriteria: sortField,
    //  sortCriteria: relevant ? '@jobareaofexpertise ascending' : '@jobposteddate descending', //if jobposteddate is same then sort on basis of title
    //locale: lang
    // Add other parameters as needed
  });
  const availableItems: (number | string)[] = [];
  let localStorageBookmarkedCardData: string[];

  if (localStorage?.getItem('bookmarkedCards')) {
    localStorageBookmarkedCardData = JSON.parse(localStorage?.getItem('bookmarkedCards') || '[]');
  } else {
    localStorageBookmarkedCardData = [];
  }
  results.results.map((item: { raw: { job_id: number | string } }) =>
    availableItems.push(item.raw.job_id)
  );

  const diff = localStorageBookmarkedCardData.filter((item) => !availableItems.includes(item));

  if (diff.length > 0) {
    window.dispatchEvent(new Event('localStorageEvent'));
    localStorage?.setItem('bookmarkedCards', '[' + availableItems.toString() + ']');
  }

  return results;
};
//coveo api call for getting job card list
