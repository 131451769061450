import React, { useEffect, useState } from 'react';
import {
  LinkField,
  DateField,
  RichText,
  RichTextField,
  Text,
  Field,
} from '@sitecore-jss/sitecore-jss-nextjs';

import { IconName } from 'src/types/Enum';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import config from 'temp/config';
// import { useI18n } from 'next-localization';
import { format, isBefore, isSameDay, parseISO, startOfToday, subDays } from 'date-fns';
import { TagFields } from 'src/types/Tag';
import { useI18n } from 'next-localization';
import { getLocale } from 'components/posted-date/PostedDate';
import dynamic from 'next/dynamic';

export interface EventFields {
  id: string;
  eventName: {
    jsonValue: Field<string>;
  };
  eventDescription: {
    jsonValue: RichTextField;
  };
  eventStart: {
    jsonValue: Field<string>;
  };
  eventEnd: {
    jsonValue: Field<string>;
  };
  eventHideTime?: {
    jsonValue: Field<string>;
  };
  eventLink: {
    jsonValue: LinkField;
  };
  eventLocation?: {
    jsonValue: Field<string>;
  };
  eventType?: {
    targetItem: TagFields;
  };
  eventFeatured: {
    jsonValue: Field<boolean>;
  };
  eventTimezone?: {
    jsonValue: Field<string>;
  };
  eventTwentyFourHour?: {
    jsonValue: Field<string>;
  };
}

export type EventProps = {
  params: { [key: string]: string };
  fields?: {
    data: {
      datasource?: EventFields;
    };
  };
};

export const Default = (props: EventProps): JSX.Element => {
  console.log('---->[Eventcards] Component', props.fields);
  const id = props.params?.RenderingIdentifier;
  const datasource = props.fields?.data?.datasource;
  const { locale } = useI18n();

  const { t } = useI18n();
  const [editingMode] = useState(isEditorActive());
  const [startTimeLocal, setStartTimeLocal] = useState<string | null>('');
  const [endTimeLocal, setEndTimeLocal] = useState<string | null>('');
  const [languageClass, setLanguageClass] = useState<string>('');
  const timezoneValue = datasource?.eventTimezone?.jsonValue?.value;
  const is24HourFormat = datasource?.eventTwentyFourHour?.jsonValue?.value;
  const defaultDate = '0001-01-01T00:00:00Z';
  const timeFormat = is24HourFormat ? 'HH:mm' : 'h:mma';
  const hideTime = datasource?.eventHideTime?.jsonValue?.value;

  useEffect(() => {
    if (datasource && datasource.eventStart && datasource.eventEnd) {
      const startDateISO =
        datasource?.eventStart?.jsonValue?.value.toString() || new Date().toISOString();
      const endDateISO =
        datasource?.eventEnd?.jsonValue?.value.toString() || new Date().toISOString();
      const startTimePart = startDateISO.split('T')[1].replace('Z', '').substring(0, 5);
      const endTimePart = endDateISO.split('T')[1].replace('Z', '').substring(0, 5);

      const formattedStartTime =
        timeFormat === 'HH:mm'
          ? startTimePart
          : format(new Date(`1970-01-01T${startTimePart}`), timeFormat);
      const formattedEndTime =
        timeFormat === 'HH:mm'
          ? endTimePart
          : format(new Date(`1970-01-01T${endTimePart}`), timeFormat);

      setStartTimeLocal(formattedStartTime);
      setEndTimeLocal(formattedEndTime);
    }
  }, [datasource, timeFormat]);

  useEffect(() => {
    if (window.location.pathname.includes('/ja-jp')) {
      setLanguageClass('ja-jp-event-date');
    } else {
      setLanguageClass('');
    }
  }, []);

  if (!datasource || !datasource.eventStart || !datasource.eventEnd) {
    return <></>;
  }

  const handleClick = () => {
    if (datasource?.eventLink?.jsonValue.value.href)
      window.open(datasource?.eventLink.jsonValue.value.href, '_self');
  };

  if (datasource) {
    const startDateISO =
      datasource?.eventStart?.jsonValue?.value.toString() ||
      new Date().toISOString() ||
      defaultDate;
    const endDateISO =
      datasource?.eventEnd?.jsonValue?.value.toString() || new Date().toISOString() || defaultDate;
    const isDefaultDate = startDateISO === defaultDate || endDateISO === defaultDate;

    const splitStartDate = startDateISO.split('T')[0];
    const splitEndDate = endDateISO.split('T')[0];
    const today = startOfToday();
    const twoDaysAgo = subDays(today, 2);
    const bothDatesPast =
      isBefore(splitStartDate, twoDaysAgo) && isBefore(splitEndDate, twoDaysAgo);
    const shouldRenderDateRange = !bothDatesPast;

    const isStartDateToday = isSameDay(parseISO(splitStartDate), today);
    const isEndDateToday = isSameDay(parseISO(splitEndDate), today);
    const shouldShowDates = isStartDateToday && isEndDateToday;

    let formattedStartDate = '';
    let formattedEndDate = '';
    const isMultiDayEvent = !isSameDay(parseISO(splitStartDate), parseISO(splitEndDate));
    let dateDisplay;
    let yearToDisplay;
    let futureEvent;
    let futureEvent2;

    const currentYear = today.getFullYear();
    const startYear = parseISO(splitStartDate).getFullYear();
    const endYear = parseISO(splitEndDate).getFullYear();
    const isSameYear = startYear === endYear;
    const isCurrentYear = startYear === currentYear;

    if (locale() === 'es-ES') {
      const startDay = format(parseISO(splitStartDate), 'd', { locale: getLocale(locale()) });
      const endDay = format(parseISO(splitEndDate), 'd', { locale: getLocale(locale()) });
      const startMonth = format(parseISO(splitStartDate), 'MMM', { locale: getLocale(locale()) });
      const endMonth = format(parseISO(splitEndDate), 'MMM', { locale: getLocale(locale()) });

      // Capitalize the first letter of the month
      const capitalizedStartMonth = startMonth.charAt(0).toUpperCase() + startMonth.slice(1);
      const capitalizedEndMonth = endMonth.charAt(0).toUpperCase() + endMonth.slice(1);
      if (isMultiDayEvent) {
        if (isSameYear) {
          // Case 1: Multi-day event within the same month
          if (startMonth === endMonth) {
            dateDisplay = `${startDay} - ${endDay} de ${capitalizedStartMonth} de ${startYear}`;
          } else {
            // Case 2: Multi-day event across different months
            dateDisplay = `${startDay} de ${capitalizedStartMonth} de - ${endDay} de ${capitalizedEndMonth} de ${endYear}`;
          }
        } else {
          // Case 3: Multi-day event across different years
          dateDisplay = `${startDay} de ${capitalizedStartMonth} de ${startYear} - ${endDay} de ${capitalizedEndMonth} de ${endYear}`;
        }
      } else {
        dateDisplay = `${startDay} de ${capitalizedStartMonth} de ${startYear}`;
      }
    } else {
      formattedStartDate = format(parseISO(splitStartDate), 'MMM d', {
        locale: getLocale(locale()),
      });
      formattedEndDate = format(parseISO(splitEndDate), 'MMM d', {
        locale: getLocale(locale()),
      });

      const month = formattedStartDate.split(' ')[0];
      const endMonth = formattedEndDate.split(' ')[0];
      const startDay = formattedStartDate.split(' ')[1];
      const endDay = formattedEndDate.split(' ')[1];
      if (isMultiDayEvent) {
        if (isSameYear) {
          if (isCurrentYear) {
            if (month !== endMonth) {
              dateDisplay = `${formattedStartDate} - \n ${formattedEndDate}`;
              yearToDisplay = '';
            } else {
              // Same month and year but in the current year
              dateDisplay = `${month} ${startDay} - ${endDay}`;
              yearToDisplay = '';
            }
          } else {
            // Same year but different months (not current year)
            if (month !== endMonth) {
              futureEvent = true; // Set futureEvent to true for different months in the same year
            } else {
              // Same month and year but not current year
              dateDisplay = `${month} \n ${startDay} - ${endDay}`;
              yearToDisplay = `${startYear}`; // Show year if it's not the current year
            }
          }
        } else {
          if (startYear === currentYear && !(endYear === currentYear)) {
            dateDisplay = `${formattedStartDate} - \n ${formattedEndDate}`;
            yearToDisplay = `${endYear}`;
          } else {
            if (!(startYear === currentYear) && !(endYear === currentYear)) {
              futureEvent = true; // Handle future events that are not in the current year
            } else {
              futureEvent2 = true; // Handle other future events
            }
          }
        }
      } else {
        dateDisplay = `${month} ${startDay}`;
        yearToDisplay = isCurrentYear ? '' : startYear;
      }
    }

    const isDisplayingTimeAndDate =
      (shouldRenderDateRange && !shouldShowDates) || (shouldRenderDateRange && !hideTime);

    const topSectionClass = isDisplayingTimeAndDate
      ? 'event__top-section'
      : 'event__top-section no-min-height';

    const editingmode = isEditorActive();

    let DynamicTertiary;
    if (editingmode) {
      // Static import when in editing mode
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      DynamicTertiary = require('components/link/Link').Tertiary;
    } else {
      // Dynamic import when not in editing mode
      DynamicTertiary = dynamic(() => import('components/link/Link').then((mod) => mod.Tertiary), {
        ssr: !!config.dynamicImportSsr,
      });
    }

    let DynamicIcon;
    if (editingmode) {
      // Static import when in editing mode
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      DynamicIcon = require('components/shared/icon/Icon').Icon;
    } else {
      // Dynamic import when not in editing mode
      DynamicIcon = dynamic(() => import('components/shared/icon/Icon').then((mod) => mod.Icon), {
        ssr: !!config.dynamicImportSsr,
      });
    }

    return (
      <div
        className={`event ${sitecoreStyles(props.params?.styles)}`}
        id={id ? id : undefined}
        onClick={!editingMode ? handleClick : () => null}
      >
        <div className={topSectionClass}>
          {!isDefaultDate && shouldRenderDateRange && !shouldShowDates && !futureEvent && (
            <div>
              <Text
                style={{ display: 'inline' }}
                tag="h3"
                tabIndex={0}
                className={`event__date ${languageClass}`}
                field={{ value: dateDisplay }}
              />
              {yearToDisplay && (
                <Text
                  style={{ display: 'inline' }}
                  tag="h5"
                  tabIndex={0}
                  className={`event__year ${languageClass}`}
                  field={{ value: yearToDisplay }}
                />
              )}
            </div>
          )}
          {!isDefaultDate && shouldRenderDateRange && !shouldShowDates && futureEvent && (
            <div>
              <Text
                style={{ display: 'inline' }}
                tag="h4"
                tabIndex={0}
                className={`event__date`}
                field={{ value: formattedStartDate }}
              />
              {startYear && (
                <Text
                  style={{ display: 'inline' }}
                  tag="h6"
                  tabIndex={0}
                  className={`event__year`}
                  field={{ value: startYear }}
                />
              )}
              <h3 style={{ display: 'inline' }}> - </h3>
              <br />
              <Text
                style={{ display: 'inline' }}
                tag="h4"
                tabIndex={0}
                className={`event__date`}
                field={{ value: formattedEndDate }}
              />
              {endYear && (
                <Text
                  style={{ display: 'inline' }}
                  tag="h6"
                  tabIndex={0}
                  className={`event__year`}
                  field={{ value: endYear }}
                />
              )}
            </div>
          )}

          {!isDefaultDate && shouldRenderDateRange && !shouldShowDates && futureEvent2 && (
            <div>
              <Text
                style={{ display: 'inline' }}
                tag="h3"
                tabIndex={0}
                className={`event__date`}
                field={{ value: formattedStartDate }}
              />
              {startYear && (
                <Text
                  style={{ display: 'inline' }}
                  tag="h5"
                  tabIndex={0}
                  className={`event__year`}
                  field={{ value: startYear }}
                />
              )}
              <h3 style={{ display: 'inline' }}> - </h3>
              <br />
              <Text
                style={{ display: 'inline' }}
                tag="h3"
                tabIndex={0}
                className={`event__date`}
                field={{ value: formattedEndDate }}
              />
            </div>
          )}

          {shouldRenderDateRange && !hideTime && (
            <div
              className={
                shouldShowDates
                  ? 'today body1 event__date-container'
                  : 'not-today body1 event__date-container'
              }
            >
              {!isDefaultDate && (
                <>
                  <DateField
                    tag={!shouldShowDates ? 'div' : 'h3'}
                    field={datasource?.eventStart.jsonValue}
                    render={(date) => (
                      <>
                        {' '}
                        {date ? (
                          <>
                            {!shouldShowDates && '@'}
                            {startTimeLocal?.toLowerCase()}
                            <>&nbsp;-&nbsp;</>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  />
                  <DateField
                    field={datasource?.eventEnd.jsonValue}
                    render={(date) => (
                      <>
                        {date ? (
                          <>
                            {shouldShowDates ? (
                              <div>
                                <h3 style={{ display: 'inline' }}>
                                  {' '}
                                  {endTimeLocal?.toLowerCase()}
                                </h3>
                                &nbsp;
                                <h5 style={{ display: 'inline' }}>{timezoneValue}</h5>
                              </div>
                            ) : (
                              <div>
                                {endTimeLocal?.toLowerCase()} {timezoneValue}
                              </div>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  />
                </>
              )}
            </div>
          )}

          {datasource?.eventLocation?.jsonValue.value ? (
            <Text
              tag="span"
              className="body2 event__location"
              field={datasource?.eventLocation.jsonValue}
            />
          ) : (
            datasource?.eventType?.targetItem?.title?.jsonValue?.value && (
              <span className="body2 event__type">
                {' '}
                {isEditorActive() ? (
                  datasource.eventType.targetItem.title.jsonValue.value
                ) : (
                  <Text
                    tag="span"
                    className="body2 event__type"
                    field={datasource.eventType.targetItem.title.jsonValue}
                  />
                )}{' '}
              </span>
            )
          )}
          {datasource?.eventFeatured.jsonValue?.value && (
            <div className="event__featured body1">
              <DynamicIcon icon={IconName.Sparkle} iconLabel={'Sparkle'} />{' '}
              {t('component-IsFeatured')}
            </div>
          )}
          {isMultiDayEvent && shouldRenderDateRange && (
            <div className="multi-day__tag body1">
              <DynamicIcon icon={IconName.Calendar} iconLabel={'Calendar'} />
              {t('component-multidayevent')}
            </div>
          )}
        </div>

        <div className="event__bottom-section">
          <Text
            tag="div"
            tabIndex={0}
            className="subheading1 event__title"
            field={datasource?.eventName.jsonValue}
          />
          <RichText
            tag="div"
            className="body2 event__description"
            field={datasource?.eventDescription.jsonValue}
          />
          {datasource?.eventLink.jsonValue && (
            <DynamicTertiary fields={{ Link: datasource?.eventLink.jsonValue }} params={{}} />
          )}
        </div>
      </div>
    );
  }
  return <EventDefaultComponent {...props} />;
};

export const EventDefaultComponent = (props: EventProps): JSX.Element => (
  <div className={`event component--with-paddings ${sitecoreStyles(props.params?.styles)}`}>
    <span className="is-empty-hint">Event</span>
  </div>
);
