import Link from 'next/link';
import { Fields as MenuItem } from '../Navigation';

type LowLvlMenuProps = {
  menuItem: MenuItem;
  setCurrentMenuItem: (index: number) => void;
};

export const LowLvlMenu = ({ menuItem, setCurrentMenuItem }: LowLvlMenuProps): JSX.Element => {
  const hasLvl3Item = menuItem?.Children?.some(
    (child) => child.Children && child.Children.length >= 1
  );

  return (
    <>
      {menuItem?.Children && (
        <div className={`low-lvl-menu ${hasLvl3Item ? 'having-lvl-3' : ''}`}>
          <div className="low-lvl-menu__container" onMouseLeave={() => setCurrentMenuItem(-1)}>
            {menuItem.Children?.map((child: MenuItem, index: number) => (
              <ul key={`${index}l2`} className="low-lvl-menu__list low-lvl-menu__list--lvl-2">
                {child?.NavigationTitle?.value !== '$name' && (
                  <li className="low-lvl-menu__list-item ">
                    <Link
                      href={child.Href}
                      className="gatrack internal-link low-lvl-menu__link body2"
                      data-analytics-link-name={child?.NavigationTitle?.value}
                      data-analytics-link-type="Navigation"
                      data-analytics-content-class="Content"
                      data-analytics-template-zone="Header"
                      data-analytics-component-name="Main Navigation"
                      data-analytics-component-variation="Main Navigation"
                      data-analytics-target={child?.Href}
                      data-analytics-component-section="Main Navigation Items L2"
                      data-analytics-slide-number="NAN"
                      data-analytics-ispersonalized="False"
                      data-analytics-iscarousal="False"
                      aria-label={child?.NavigationTitle?.value?.toString()}
                      onClick={() => {
                        setCurrentMenuItem(-1);
                        //careerFix(child.Href, e);
                      }}
                    >
                      <div className="h6">{child?.NavigationTitle?.value}</div>
                    </Link>
                  </li>
                )}

                {child?.Children && (
                  <ul className="low-lvl-menu__list">
                    {child?.Children?.map((child: MenuItem, index: number) => {
                      if (child.NavigationTitle.value !== '$name') {
                        return (
                          <>
                            <li
                              key={`${index}l3`}
                              className="low-lvl-menu__list-item low-lvl-menu__list-item--lvl-3"
                            >
                              <Link
                                href={child.Href}
                                className="gatrack internal-link low-lvl-menu__link body2"
                                data-analytics-link-name={child?.NavigationTitle?.value}
                                data-analytics-link-type="Navigation"
                                data-analytics-content-class="Content"
                                data-analytics-template-zone="Header"
                                data-analytics-component-name="Main Navigation"
                                data-analytics-component-variation="Main Navigation"
                                data-analytics-target={child?.Href}
                                data-analytics-component-section="Main Navigation Items L3"
                                data-analytics-slide-number="NAN"
                                data-analytics-ispersonalized="False"
                                data-analytics-iscarousal="False"
                                aria-label={child?.NavigationTitle?.value?.toString()}
                                onClick={() => setCurrentMenuItem(-1)}
                              >
                                {child?.NavigationTitle?.value}
                              </Link>
                            </li>
                          </>
                        );
                      } else {
                        return null; // Skip rendering HTML if the condition is not met
                      }
                    })}
                  </ul>
                )}
                <span
                  onMouseEnter={() => setCurrentMenuItem(-1)}
                  className="low-lvl-menu__background"
                ></span>
              </ul>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
