import React, { useContext, useEffect, useState } from 'react';
import { Text, Field, ImageField, Link, RichTextField } from '@sitecore-jss/sitecore-jss-nextjs';
import { DoubleAccordionContext } from 'components/double-accordion/partials/double-acc-context/DoubleAccordionContext';
import { IconName } from 'src/types/Enum';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import dynamic from 'next/dynamic';
import config from 'temp/config';
export interface EmployeeProfileFields {
  FullName: Field<string>;
  Role: Field<string>;
  Picture: ImageField;
  ShortBio: RichTextField;
  Bio: RichTextField;
  SocialNetworks: Field<string>;
}

type EmployeeProfileProps = {
  params: { [key: string]: string };
  fields: EmployeeProfileFields;
};

export const parseKeyValuePairs = (input?: string): { type: string; link: string }[] => {
  const result = [];
  if (input) {
    const pairs = input.split('&');
    for (const pair of pairs) {
      const [key, value] = pair.split('=');

      // Only add to the result if both key and value are present
      if (key && value) {
        try {
          let link = decodeURIComponent(value.trim());

          // Check if the URL has a valid protocol, prepend 'https://' if not
          if (!(link.startsWith('http://') || link.startsWith('https://'))) {
            link = `https://${link}`;
          }

          result.push({ type: key, link });
        } catch (error) {
          // console.error('Error decoding URL:', value, error);
          result.push({ type: key, link: '' }); // Handle as needed
        }
      }
    }
  }
  return result;
};

const editingmode = isEditorActive();

let DynamicIcon;
if (editingmode) {
  // Static import when in editing mode
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  DynamicIcon = require('components/shared/icon/Icon').Icon;
} else {
  // Dynamic import when not in editing mode
  DynamicIcon = dynamic(() => import('components/shared/icon/Icon').then((mod) => mod.Icon), {
    ssr: !!config.dynamicImportSsr,
  });
}

export const Default = (props: EmployeeProfileProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const fields = props.fields;
  console.log('---->[EmployeePropfile] Component', fields);
  const { doubleAccordionState, setDoubleAccordionState } = useContext(DoubleAccordionContext);
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setOpened(doubleAccordionState.currentlyOpenAccordion.includes(props.fields?.FullName.value));
  }, [doubleAccordionState, props.fields?.FullName.value]);

  const editingmode = isEditorActive();

  let DynamicNextImageWithNullFallback;
  if (editingmode) {
    // Static import when in editing mode

    DynamicNextImageWithNullFallback =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/shared/nextImg/NextImageWithNullFallback').NextImageWithNullFallback;
  } else {
    // Dynamic import when not in editing mode
    DynamicNextImageWithNullFallback = dynamic(
      () =>
        import('components/shared/nextImg/NextImageWithNullFallback').then(
          (mod) => mod.NextImageWithNullFallback
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }

  let DynamicEmployeeProfileCard;
  if (editingmode) {
    // Static import when in editing mode
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicEmployeeProfileCard = require('./partials/EmployeeProfileCard').EmployeeProfileCard;
  } else {
    // Dynamic import when not in editing mode
    DynamicEmployeeProfileCard = dynamic(
      () => import('./partials/EmployeeProfileCard').then((mod) => mod.EmployeeProfileCard),
      { ssr: !!config.dynamicImportSsr }
    );
  }

  if (props.fields) {
    return (
      <div
        id={id ? id : undefined}
        className={`inside-accordion__container ${sitecoreStyles(props.params?.styles)} ${
          opened ? 'inside-accordion__container--open' : ''
        }`}
      >
        <div className={'inside-accordion__item '}>
          <div className="inside-accordion__header">
            <div className={`inside-accordion__item-container`}>
              <div className="inside-accordion__item-info-box">
                <div
                  className={`inside-accordion__main-container ${
                    !props.fields.SocialNetworks?.value
                      ? 'inside-accordion__main-container--full'
                      : ''
                  }`}
                >
                  <DynamicNextImageWithNullFallback
                    field={props.fields.Picture}
                    width={104}
                    height={104}
                    params={{
                      styles: 'inside-accordion__image next-image--circle',
                    }}
                  ></DynamicNextImageWithNullFallback>

                  <div className="inside-accordion__item-title">
                    <Text
                      className="inside-accordion__full-name"
                      tabIndex={0}
                      tag="h4"
                      field={props.fields.FullName}
                    />
                    <Text
                      tabIndex={0}
                      tag="div"
                      className={`subheading1 inside-accordion__name`}
                      field={props.fields.Role}
                    />
                  </div>
                </div>
                {props.fields.SocialNetworks?.value && (
                  <>
                    <div className={'inside-accordion__item-divider'}></div>
                    <div className={'inside-accordion__item-social'}>
                      {parseKeyValuePairs(props.fields.SocialNetworks?.value).map((media) => (
                        <Link
                          key={media.type}
                          field={{ value: { href: media.link } }}
                          className="inside-accordion__social-icon"
                        >
                          {getSocialMediaIcon(media.type)}
                        </Link>
                      ))}
                    </div>
                  </>
                )}
              </div>

              <div className={'inside-accordion__trigger'}>
                <div className={'inside-accordion__icon--plus'}>
                  <button
                    type="button"
                    title="Open"
                    onClick={() => {
                      setOpened(!opened);
                      if (!opened) {
                        setDoubleAccordionState({
                          currentlyOpenAccordion: [
                            ...doubleAccordionState.currentlyOpenAccordion,
                            props.fields.FullName.value,
                          ],
                        });
                      }
                    }}
                  >
                    <DynamicIcon
                      icon={IconName.Add}
                      iconLabel={'Open profile'}
                      additionalClass={'inside-accordion__icon'}
                    />
                  </button>
                </div>
                <div className={'inside-accordion__icon--minus'}>
                  <button
                    type="button"
                    title="Close"
                    onClick={() => {
                      setOpened(!opened);
                      setDoubleAccordionState({
                        currentlyOpenAccordion: [
                          ...doubleAccordionState.currentlyOpenAccordion.filter(
                            (item) => item != props.fields.FullName.value
                          ),
                        ],
                      });
                    }}
                  >
                    <DynamicIcon
                      icon={IconName.Substract}
                      iconLabel={'Close profile'}
                      additionalClass={'inside-accordion__icon'}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="inside-accordion__accordion-content">
          <DynamicEmployeeProfileCard {...props.fields} />
        </div>
      </div>
    );
  } else {
    return <></>; //ToDo check why all the udefined data is flying
  }
};

export const EmployeeProfileDefault = (): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  return (
    <React.Fragment>
      {editingMode && (
        <div className="employee-profile component--with-paddings">
          <span className="is-empty-hint">Employee profile</span>
        </div>
      )}
    </React.Fragment>
  );
};

export const getSocialMediaIcon = (media: string) => {
  console.log(media);
  const lowerCaseMedia = media.toLowerCase().trim();
  if (lowerCaseMedia === 'facebook')
    return <DynamicIcon icon={IconName.Facebook} iconLabel={'Facebook'} />;
  if (lowerCaseMedia === 'youtube')
    return <DynamicIcon icon={IconName.Youtube} iconLabel={'Youtube'} />;
  if (lowerCaseMedia === 'linkedin')
    return <DynamicIcon icon={IconName.Linkedin} iconLabel={'Linkedin'} />;
  if (lowerCaseMedia === 'email') return <DynamicIcon icon={IconName.Email} iconLabel={'Email'} />;
  if (lowerCaseMedia === 'instagram')
    return <DynamicIcon icon={IconName.Instagram} iconLabel={'Instagram'} />;
  return <></>;
};
