/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { Field, Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import Script from 'next/script';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import Image from 'next/image';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import { useRouter } from 'next/router';
import config from 'temp/config';
// import LazyLoadComponent from 'components/shared/lazy-load/LazyLoadComponent';

declare global {
  interface Window {
    InitializeBrightcovePlaylistPlayerLoader: (arg0: string, arg1: string, arg2: string) => void;
  }
}
export interface BrightcovePlayer {
  id: string;
  playerKey: {
    jsonValue: Field<string>;
  };
}

export interface BrightcoveMediaElement {
  id: string;
  brightcoveID: {
    jsonValue: Field<string>;
  };
  brightcoveName: {
    jsonValue: Field<string>;
  };
}

export interface BrightcoveItemFields {
  id: string;
  brightcovePlayer: {
    targetItem: BrightcovePlayer;
  };
  brightcoveItem: {
    targetItem: BrightcoveMediaElement;
  };
}

type PlaylistVideoProps = {
  params: { [key: string]: string };
  fields: {
    data: {
      datasource: BrightcoveItemFields;
    };
  };
  autoplay?: boolean;
  isModalDisplayed?: boolean;
};

export const Default = (props: PlaylistVideoProps): JSX.Element => {
  console.log('---->[Playlist] Component', props.fields);
  const id = props.params?.RenderingIdentifier;
  const [currentUrl, setCurrentUrl] = useState('');
  const unformattedVideoPlayerId =
    props.fields.data.datasource?.brightcoveItem?.targetItem?.brightcoveID?.jsonValue?.value;
  const brightcoveIDValue =
    props.fields.data.datasource?.brightcoveItem?.targetItem?.brightcoveID?.jsonValue?.value || '';
  let videoPlayerId = `videoId_${unformattedVideoPlayerId}`;

  const { sitecoreContext } = useSitecoreContext();
  let brightcoveplaryerloaderUrl = '/scripts/brightcove-player-loader.min.js';
  if (
    sitecoreContext &&
    (sitecoreContext.pageState === 'edit' || sitecoreContext.pageState === 'preview')
  ) {
    brightcoveplaryerloaderUrl = config.publicUrl + '/scripts/brightcove-player-loader.min.js';
  }

  const router = useRouter();
  const [showVideoModal, setShowVideoModal] = useState(false);
  if (showVideoModal || props.isModalDisplayed) {
    videoPlayerId = videoPlayerId + 'Modal';
  }
  // const [isPlayListVideoContainer, setisPlayListVideoContainer] = useState(false);
  // const [singleVideoBrightcoveIDValue, setSingleVideoBrightcoveIDValue] = useState('');
  useEffect(() => {
    const url = window.location.href;
    setCurrentUrl(url);
  }, []);
  // const closeModal = () => {
  //   setShowVideoModal(false);
  // };
  useEffect(() => {
    const urlWithoutHash = router.asPath.split('#')[0];
    const videoIdFromHash = router.asPath.split('#')[1];
    const queryParams = urlWithoutHash.split('?')[1];
    if (queryParams || videoIdFromHash) {
      const urlParams = new URLSearchParams(queryParams);
      const videoId = urlParams.get('videoId');
      if (videoId) {
        setShowVideoModal(true);
        // for setting single video player id not the playlist ID
        // setSingleVideoBrightcoveIDValue(videoId);
        // setisPlayListVideoContainer(true);
      }
    }
  }, [router.asPath, unformattedVideoPlayerId]);

  function LoadPlayer() {
    if (props.fields) {
      const datasource = props.fields.data.datasource;
      if (typeof window !== undefined) {
        window.InitializeBrightcovePlaylistPlayerLoader(
          '#' + videoPlayerId,
          datasource?.brightcovePlayer?.targetItem?.playerKey.jsonValue.value,
          brightcoveIDValue
        );
      }
    }
  }
  const scriptRoot = useRef<HTMLFormElement>(null); // gets assigned to a root node
  const scriptRootModal = useRef<HTMLFormElement>(null); // gets assigned to a root node
  const script = `<script> 
function InitializeBrightcovePlaylistPlayerLoader(bVideoId, playerKey, brightcoveIDValue) {
console.log(bVideoId,"bVideoId");
                                if (document.querySelector(bVideoId) != null) 
                                {
                                    try {
                                        brightcovePlayerLoader({
                                        refNode: document.querySelector(bVideoId),
                                        refNodeInsert: 'replace',
                                        accountId: '2103045685001',
                                        playerId: playerKey,
                                        embedId: 'default',
                                        embedOptions: true,
                                        playlistId: brightcoveIDValue
                                        })
                                        .then(function(success) {
                                            // The player has been created!
                                            /*
                                              At this point:
                                              - at least one video player will be in the DOM.
                                              - at least one script created by this library will be in the DOM.
                                              - window.bc will exist.
                                              - window.videojs will exist.
                                             */
                                            const videoDomElement = document.getElementsByClassName('vjs-tech')[0];
                                            const accountId = encodeURIComponent('2103045685001');
                                            const socialUrl = encodeURI(
                                                'https://' + window.location.hostname + window.location.pathname + '?playlistId='+ brightcoveIDValue + '?videoId='
                                            );
                                            videoDomElement?.setAttribute('data-account', accountId);
                                            videoDomElement?.setAttribute('data-player', playerKey);
                                            videoDomElement?.setAttribute('data-video-id', brightcoveIDValue);
                                            if (typeof window.bc != 'undefined') {
                                                window.bc(videoDomElement);
                                            }

                                            if (typeof window.videojs != 'undefined') {
                                                /*//const player = window.videojs.getPlayer(success.ref);*/
                                                const player = success.ref;
                                                player.ready(function () {
                                                player.one('loadstart', function () {
                                                    const options = {
                                                    title: player.mediainfo.name,
                                                    description: player.mediainfo.description,
                                                    url: socialUrl + player.mediainfo.id,
                                                    deeplinking: false,
                                                    autoplay: false,
                                                    offset: '00:00:00',
                                                    services: {
                                                        facebook: true,
                                                        twitter: false,
                                                        tumblr: false,
                                                        pinterest: true,
                                                        linkedin: true,
                                                    },
                                                    };
                                                    player.autoplay(false);

                                                    player.social(options);
                                                });
                                                if (${props?.autoplay}) {
                                                    player.play();
                                                }
                                                });
                                            }
                                             /*brightcovePlayerLoader.reset();*/

                                            /*
                                              At this point:
                                              - no video players will be in the DOM.
                                              - no scripts created by this library will be in the DOM.
                                              - window.bc will not exist.
                                              - window.videojs will not exist.
                                             */
                                        })
                                        .catch(function(error) {
                                            // Player creation failed!
                                            console.error(' Player creation failed! ' + error);
                                        });
                                    } 
                                    catch (error) {
                                        console.error(error);
                                        // Expected output: ReferenceError: nonExistentFunction is not defined
                                        // (Note: the exact output may be browser-dependent)
                                    }
                                }
                            }
</script>`;

  useEffect(() => {
    if (showVideoModal || props.isModalDisplayed) {
      setTimeout(() => {
        const rangeModal = document.createRange(); // creates a mini-document (light weight version), in our range with our script in it
        const documentFragmentModal = rangeModal.createContextualFragment(script); // appends it to our script root - so it renders in the correct location
        if (scriptRootModal.current && scriptRootModal.current.childElementCount < 1) {
          scriptRootModal.current.appendChild(documentFragmentModal);
          if (typeof window !== undefined) {
            LoadPlayer();
          }
        }
      }, 1500);
    } else {
      const range = document.createRange(); // creates a mini-document (light weight version), in our range with our script in it
      const documentFragment = range.createContextualFragment(script); // appends it to our script root - so it renders in the correct location
      if (scriptRoot.current && scriptRoot.current.childElementCount < 1) {
        scriptRoot.current.appendChild(documentFragment);
        if (typeof window !== undefined) {
          setTimeout(() => {
            LoadPlayer();
          }, 2000);
        }
      }
    }
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      scriptRoot.current?.firstChild?.remove;
      scriptRootModal.current?.firstChild?.remove;
    };
  }, [script, showVideoModal]);
  if (props.fields) {
    const datasource = props.fields.data.datasource;
    if (
      datasource?.brightcoveItem?.targetItem?.brightcoveID.jsonValue.value &&
      datasource?.brightcovePlayer?.targetItem?.playerKey.jsonValue.value
    ) {
      const generateVideoComponent = () => {
        return (
          // <LazyLoadComponent componentId="Playlist">
          <div
            className={`component playlist-video ${sitecoreStyles(props.params?.styles)}`}
            id={id ? id : undefined}
          >
            <div className="component-content video-tab-container">
              <div className="video-tab">
                <div className="playlist-video-text">
                  <input
                    id="hddn_m_DataplaylistId"
                    name="hddn_m_DataplaylistId"
                    type="hidden"
                    value={`${datasource?.brightcoveItem?.targetItem?.brightcoveID?.jsonValue?.value}`}
                  />
                  <input
                    id="hddn_m_PlayerKey"
                    name="hddn_m_PlayerKey"
                    type="hidden"
                    value={`${datasource?.brightcovePlayer?.targetItem?.playerKey?.jsonValue?.value}`}
                  />
                  <input
                    id="hddn_m_AccountId"
                    name="hddn_m_AccountId"
                    type="hidden"
                    value="2103045685001"
                  />
                  <input id="hddn_m_language" name="hddn_m_language" type="hidden" value="en" />
                  <input
                    id="hddnSharingUrl"
                    name="hddnSharingUrl"
                    type="hidden"
                    value={currentUrl}
                  />
                  <input id="hddn_disable_map" name="hddn_disable_map" type="hidden" value="0" />

                  <div>
                    <div className="playlist-video-container">
                      <Script
                        id="BrightcovePlayerID"
                        src={brightcoveplaryerloaderUrl}
                        // onLoad={() => LoadPlayer()}
                        // onReady={() => LoadPlayer()}
                      />
                      <div id={videoPlayerId} className="video-js" />
                      <div id="brightcove-playlist" className="video-tab-menu">
                        <div className="vjs-playlist" />
                      </div>
                      {/* <Script id="BrightcovePlayerLoaderID">
                        {`
                            
                        `}
                      </Script> */}
                      {showVideoModal || props.isModalDisplayed ? (
                        <form ref={scriptRootModal} />
                      ) : (
                        <form ref={scriptRoot} />
                      )}
                      <Image
                        className="mapbrightcovecom"
                        src="https://map.brightcove.com/lead/clear"
                        width={0}
                        height={0}
                        alt="cookie consent"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          // </LazyLoadComponent>
        );
      };

      return !showVideoModal ? (
        <>{generateVideoComponent()}</>
      ) : (
        <>
          {generateVideoComponent()}
          {/* <Modal isOpen={showVideoModal} hasCloseBtn onClose={closeModal}>
            {showVideoModal && brightcoveIDValue ? (
              <SingleVideo
                isModalDisplayed
                autoplay={true}
                fields={{
                  data: { datasource: props.fields.data.datasource as BrightcoveItemFields },
                }}
                params={{}}
                singleVideoBrightcoveIDValue={singleVideoBrightcoveIDValue}
                isPlayListVideoContainer={isPlayListVideoContainer}
              />
            ) : (
              <div className={'h1'}>Video not defined</div>
            )}
          </Modal> */}
        </>
      );
    } else if (isEditorActive()) {
      <div
        className={`component playlist-video ${sitecoreStyles(props.params?.styles)}`}
        id={id ? id : undefined}
      >
        <div className="component-content">
          <div className="playlist-video-text">
            <div className="field-playerKey">
              <span>Player Key</span>:
              <Text field={datasource?.brightcovePlayer?.targetItem?.playerKey.jsonValue} />
              <span>click save to load the Playlist Module.</span>
            </div>
            <div className="field-brightcoveID">
              <span>Brightcove Playlist ID </span>:
              <Text field={datasource?.brightcoveItem?.targetItem?.brightcoveID.jsonValue} />
            </div>
            <div className="field-brightcoveName">
              <span>Brightcove Playlist Name </span>:
              <Text field={datasource?.brightcoveItem?.targetItem?.brightcoveName.jsonValue} />
            </div>
          </div>
        </div>
      </div>;
    }
  }
  return <PlaylistVideoDefaultComponent {...props} />;
};

export const WithText = (props: PlaylistVideoProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  if (props.fields) {
    return (
      <div className={`component playlist-video ${props.params.styles}`} id={id ? id : undefined}>
        <div className="component-content">
          <div className="playlist-video-text">
            {props.fields.data.datasource.brightcovePlayer.targetItem?.playerKey.jsonValue && (
              <div className="field-playerKey">
                <span>Player Key</span>:
                <Text
                  field={
                    props.fields.data.datasource.brightcovePlayer.targetItem.playerKey.jsonValue
                  }
                />
                <span>click save to load the Single Video Module.</span>
              </div>
            )}
            {props?.fields?.data?.datasource?.brightcoveItem?.targetItem?.brightcoveID
              ?.jsonValue && (
              <div className="field-brightcoveID">
                <span>Brightcove Playlist ID</span>:
                <Text
                  field={
                    props?.fields?.data?.datasource?.brightcoveItem?.targetItem?.brightcoveID
                      ?.jsonValue
                  }
                />
              </div>
            )}
            {props?.fields?.data?.datasource?.brightcoveItem?.targetItem?.brightcoveName
              ?.jsonValue && (
              <div className="field-brightcoveName">
                <span>Brightcove Playlist ID</span>:
                <Text
                  field={
                    props?.fields?.data?.datasource?.brightcoveItem?.targetItem?.brightcoveName
                      ?.jsonValue
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return <PlaylistVideoDefaultComponent {...props} />;
};

export const PlaylistVideoDefaultComponent = (props: PlaylistVideoProps): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  return (
    <>
      {editingMode && (
        <div className={`component playlist-video ${sitecoreStyles(props.params?.styles)}`}>
          <div className="component-content">
            <span className="is-empty-hint">Playlist Video</span>
          </div>
        </div>
      )}
    </>
  );
};
