/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Text, TextField } from '@sitecore-jss/sitecore-jss-nextjs';
import { SearchEngineContext } from 'components/search/search-context/SearchContext';
import {
  buildSort,
  SortBy,
  SortByField,
  SortByRelevancy,
  SortCriterion,
} from '@coveo/atomic-react';
import { SortInitialState, SortOrder } from '@coveo/headless';
// import sitecoreStyles from '../../../helpers/sitecoreStyles';
import { useSortFacet } from 'lib/contexts/SortFacetContext';
import { useShowResult } from 'lib/contexts/ShowingResultContext';
import { useRouter } from 'next/router';
import { Loader } from 'components/shared/loader/Loader';
import { useSearchJob } from 'lib/contexts/SearchJobContext';
export interface SearchSortFacetFields {
  id: string;
  selectedByDefault?: {
    targetId: string;
  };
  children: {
    results: SearchSortOptionFields[];
  };
}

export interface SearchSortOptionFields {
  id: string;
  label?: {
    jsonValue: TextField;
  };
  criterias?: {
    value?: string;
  };
}

type SortFacetItem = {
  id: string;
  label?: TextField;
  criteria: SortCriterion | SortCriterion[];
};

export type SearchSortProps = {
  params: { [key: string]: string };
  fields?: {
    data: {
      contextItem: SearchSortFacetFields;
    };
  };
};

const formatSortCriteria = (sortCriteria: string): SortByField[] => {
  if (!sortCriteria.trim()) {
    return [];
  }

  const params = new URLSearchParams(sortCriteria);
  const sortFields: SortByField[] = [];

  for (const [key, value] of params.entries()) {
    if (key && value) {
      const normalizedValue = value.toLowerCase();

      let sortOrder: SortOrder | undefined;
      if (normalizedValue === SortOrder.Ascending) {
        sortOrder = SortOrder.Ascending;
      } else if (normalizedValue === SortOrder.Descending) {
        sortOrder = SortOrder.Descending;
      } else {
        console.warn(`Invalid sort order "${value}" for field "${key}". Skipping this parameter.`);
        continue; // Skip invalid sort orders
      }
      sortFields.push({
        by: SortBy.Field,
        field: key,
        order: sortOrder,
      });
    }
  }
  return sortFields;
};

const relevancyCriteria = (): SortByRelevancy => {
  return {
    by: SortBy.Relevancy,
  };
};

export const Default = (props: SearchSortProps): JSX.Element => {
  console.log('---->[SearchSortFacet] Component', props.fields?.data?.contextItem);

  let sortFacetItems: SortFacetItem[] = [];

  if (
    props.fields?.data?.contextItem?.children?.results &&
    props.fields?.data?.contextItem?.children?.results.length > 0
  ) {
    const options = props.fields.data.contextItem.children.results;

    sortFacetItems = options
      .filter((item) => {
        return item.label;
      })
      .map((item) => {
        const option: SortFacetItem = {
          id: item.id,
          label: item.label?.jsonValue,
          criteria: item.criterias?.value
            ? formatSortCriteria(item.criterias.value)
            : relevancyCriteria(),
        };
        return option;
      });
  }

  let initialSort: SortInitialState = {};

  console.log('---->[SearchSortFacet] sortFacetItems', sortFacetItems);
  let selectedItem = sortFacetItems.find(
    (item) => item.id === props.fields?.data?.contextItem?.selectedByDefault?.targetId
  );
  if (!selectedItem && sortFacetItems.length > 0) {
    selectedItem = sortFacetItems[0];
  }

  initialSort = {
    criterion: selectedItem?.criteria,
  };
  const { toggleSortFacet } = useSortFacet();
  const { showResultData } = useShowResult();
  const { searchJobData } = useSearchJob();
  const urlName = useRouter();
  const [sortOptions] = useState<SortFacetItem[]>(sortFacetItems);
  const [selectedSortOption, setSelectedSortOption] = useState<SortFacetItem | undefined>(
    selectedItem
  );
  const [loader, setLoader] = useState(false);
  const searchScope = props.params.SearchScope;
  const { getSearchEngine } = React.useContext(SearchEngineContext);
  const searchEngine = getSearchEngine(searchScope);
  const [sortFacet] = useState(
    buildSort(searchEngine, {
      initialState: initialSort,
    })
  );

  //setSortOptions(sortFacetItems);

  const [, setSortFacetState] = useState(sortFacet.state);

  useEffect(() => {
    sortFacet.subscribe(() => {
      setSortFacetState(sortFacet.state);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchEngine]);

  ///Below useEffect is used for sorting recent relevent when changing country and want to keep exact sorting option
  // useEffect(() => {
  //   const localFacetValue = localStorage.getItem('facetValue');
  //   let count = 0;
  //   if (localFacetValue == 'recent') {
  //     count = 2;
  //   } else {
  //     count = 1;
  //   }
  //   {
  //     sortOptions.map((option) => {
  //       if (count == Object.keys(option.criteria).length) {
  //         handleSortChange(option);
  //       }
  //     });
  //   }
  // }, [urlName.asPath, urlName.locale]);

  const handleSortChange = (option: SortFacetItem) => {
    setLoader(true);
    setTimeout(() => {
      setSelectedSortOption(option);
      sortFacet.sortBy(option.criteria);
      let selectedValue = '';
      if (Number(Object.keys(option.criteria).length) == 2) {
        selectedValue = 'recent';
      } else {
        selectedValue = 'relevent';
      }
      toggleSortFacet(selectedValue);
      // localStorage.setItem('facetValue', selectedValue);
      setLoader(false);
    }, 500);
  };

  if (urlName.asPath.includes('saved-jobs')) {
    if (showResultData['total'] > 0) {
      return (
        <div className="sort-facet__container radio-button-container">
          {loader && <Loader></Loader>}
          {sortOptions.map((option) => {
            return (
              <label key={option.id} className={'sort-facet__option'}>
                <input
                  type="radio"
                  name={`sort-${props.fields?.data?.contextItem?.id}`}
                  value={option.id}
                  checked={option.id === selectedSortOption?.id}
                  onChange={() => handleSortChange(option)}
                />
                <Text field={option.label} tag={'span'} />
              </label>
            );
          })}
        </div>
      );
    } else {
      return <></>;
    }
  }
  return (
    <>
      {searchJobData['searchResultLength'] != 0 ? (
        <div className="sort-facet__container radio-button-container">
          {sortOptions.map((option) => {
            return (
              <label key={option.id} className={'sort-facet__option'}>
                <input
                  type="radio"
                  name={`sort-${props.fields?.data?.contextItem?.id}`}
                  value={option.id}
                  checked={option.id === selectedSortOption?.id}
                  onChange={() => handleSortChange(option)}
                />
                <Text field={option.label} tag={'span'} />
              </label>
            );
          })}
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};
