import { LinkField, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { useI18n } from 'next-localization';
import { AssessmentResultFields } from '../survey-assessment/Assessment';
import dynamic from 'next/dynamic';
import config from 'temp/config';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

export const SurveyResult = ({
  props,
  retake,
  ctalink,
}: {
  props: AssessmentResultFields;
  retake: () => void;
  ctalink: LinkField;
}): JSX.Element => {
  const handleRetakeAssessment = () => {
    retake(); // Invoke the provided callback to handle retaking assessment
  };

  const { t } = useI18n();
  let DynamicNextImageWithNullFallback;
  const editingmode = isEditorActive();
  if (editingmode) {
    // Static import when in editing mode

    DynamicNextImageWithNullFallback =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/shared/nextImg/NextImageWithNullFallback').NextImageWithNullFallback;
  } else {
    // Dynamic import when not in editing mode
    DynamicNextImageWithNullFallback = dynamic(
      () =>
        import('components/shared/nextImg/NextImageWithNullFallback').then(
          (mod) => mod.NextImageWithNullFallback
        ),
      { ssr: !!config.dynamicImportSsr } // Adjust server-side rendering (ssr: true) based on your config
    );
  }

  return (
    <>
      <div className="survey-result-frame">
        <div className="picture__container assessment-result-image">
          <DynamicNextImageWithNullFallback
            loading="eager"
            field={props.resultImage?.jsonValue}
            className="picture"
          ></DynamicNextImageWithNullFallback>
        </div>
        <div className="result-assessment-body">
          <h2>{props.resultTitle?.jsonValue?.value}</h2>

          <RichText
            className="result-assessment-discription"
            field={props.resultDescription.jsonValue}
          />

          <div className="result-assessment-footer">
            <a
              href={ctalink.value?.href}
              className=" result-assessment-register link__button-primary"
            >
              {ctalink.value?.text}
            </a>

            <div
              className="retake-assessement link__button-tertiary"
              onClick={handleRetakeAssessment}
            >
              <div className="link__button-tertiary-image-container">
                <>
                  <i
                    className="icon icon--arrow-right icon--24 undefined"
                    aria-label="Open link"
                  ></i>
                </>
              </div>

              <div className="link__button-tertiary-text"> {t(`survey-retake-assessment`)}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
