import React from 'react';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';

export interface SkipLinkFields {
  Title: Field<string>;
  ContentID: Field<string>;
}

type SkipLinkItemProps = {
  params: { [key: string]: string };
  fields?: SkipLinkFields;
};

export const Default = (props: SkipLinkItemProps): JSX.Element => {
  console.log('---->[SkipLink] Component', props.fields);
  const datasource = props.fields;
  return (
    <a
      className="ga-track skip-link body2"
      href={`#${datasource?.ContentID?.value}`}
      data-analytics-link-name={`${datasource?.Title?.value}`}
      data-analytics-link-type="Link"
      data-analytics-content-class="Content"
      data-analytics-template-zone="Body"
      data-analytics-component-name="Skip Links"
      data-analytics-component-variation="NAN"
      data-analytics-target={`#${datasource?.ContentID?.value}`}
      data-analytics-component-section="NAN"
      data-analytics-slide-number="NAN"
      data-analytics-ispersonalized="False"
      data-analytics-iscarousal="False"
    >
      {datasource?.Title?.value}
    </a>
  );
};
