import React, { useState } from 'react';
import {
  Text,
  ComponentParams,
  ComponentRendering,
  RichText,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { Tertiary } from 'components/link/Link';
import { ChildrenOrPlaceholder } from 'components/shared/children-or-placeholder/ChildrenOrPlaceholder';
import { BannerFields } from 'components/banner/Banner';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import sitecoreStyles from 'src/helpers/sitecoreStyles';

export type BannerProps = {
  rendering: ComponentRendering & { params: ComponentParams };
  params: { [key: string]: string; styles: string };
  fields?: {
    data: {
      datasource?: BannerFields;
    };
  };
  children?: React.ReactNode[];
};

const FullBleedBannerWithVideoTertiary = (props: BannerProps): JSX.Element => (
  <div className={`component  ${props.params.styles}`}>
    <div className="component-content">Empty</div>
  </div>
);

export const FullBleedBannerVideoTertiary = (props: BannerProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const titleTag = props.params['TitleTag'] || 'h3';
  const [editingMode] = useState(isEditorActive());
  const sitecoreContext = useSitecoreContext();

  if (props.fields) {
    return (
      <div
        className={`full-bleed-banner-with-video-tertiary component--with-paddings ${sitecoreStyles(
          props.params?.styles
        )}`}
        id={id ? id : undefined}
      >
        <div className="row full-bleed-banner-with-video-tertiary__main-row">
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-lg-5 full-bleed-banner-with-video-tertiary__text-container">
                {!props.fields?.data.datasource?.hideContentLabel?.jsonValue?.value &&
                  props.fields.data.datasource?.link?.jsonValue.value?.href &&
                  (isEditorActive() ? (
                    <div className="button-tags full-bleed-banner-with-video-tertiary__tag">
                      {
                        props.fields?.data.datasource?.contentLabel?.targetItem?.title?.jsonValue
                          .value
                      }
                    </div>
                  ) : (
                    <Text
                      tag="div"
                      className="button-tags full-bleed-banner-with-video-tertiary__tag"
                      field={
                        props.fields?.data.datasource?.contentLabel?.targetItem?.title
                          ?.jsonValue || { value: '' }
                      }
                    />
                  ))}
                <Text
                  tag={titleTag}
                  className="full-bleed-banner-with-video-tertiary__title"
                  field={props.fields.data.datasource?.title?.jsonValue}
                />
                <RichText
                  tag="div"
                  className="full-bleed-banner-with-video-tertiary__text"
                  field={props.fields.data.datasource?.description?.jsonValue}
                />
                {(props.fields.data.datasource?.link?.jsonValue.value?.href || editingMode) && (
                  <div className="full-bleed-banner-with-video-tertiary__link">
                    <Tertiary
                      fields={{ Link: props.fields.data.datasource?.link?.jsonValue }}
                      params={{}}
                    ></Tertiary>
                  </div>
                )}
              </div>
              <div className="col-12 col-md-12 col-lg-7 full-bleed-banner-with-video-tertiary__image-container">
                <ChildrenOrPlaceholder
                  name={`Fullbleed-video-1`}
                  rendering={props.rendering}
                  sitecoreContext={sitecoreContext}
                >
                  {props.children ? props.children[0] : null}
                </ChildrenOrPlaceholder>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <FullBleedBannerWithVideoTertiary {...props} />;
};
