import React, { useState } from 'react';
import {
  RichText,
  Text,
  ComponentParams,
  ComponentRendering,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { BannerFields } from 'components/banner/Banner';
import NextImageWithNullFallback from 'components/shared/nextImg/NextImageWithNullFallback';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import { Tertiary } from 'components/link/Link';

export type BannerProps = {
  fields?: {
    data: {
      datasource?: BannerFields;
    };
  };
  rendering?: ComponentRendering & { params: ComponentParams };
  params: { [key: string]: string; styles: string };
  children?: React.ReactNode[];
};

const FullBleedBannerDefaultComponent = (props: BannerProps): JSX.Element => (
  <div className={`component  ${props.params.styles}`}>
    <div className="component-content">Empty</div>
  </div>
);

export const FullBleedBannerMirrorsTertiary = (props: BannerProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const [editingMode] = useState(isEditorActive());
  const titleTag = props.params['TitleTag'] || 'h3';

  if (props.fields) {
    return (
      <div
        className={`full-bleed-banner-mirror-tertiary component--with-paddings ${sitecoreStyles(
          props.params?.styles
        )}`}
        id={id ? id : undefined}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-7 full-bleed-banner-mirror-tertiary__image-container">
              <NextImageWithNullFallback
                field={props.fields.data.datasource?.image?.jsonValue}
                params={{
                  styles: 'next-image--16-9',
                }}
              ></NextImageWithNullFallback>
            </div>
            <div className="col-12 col-lg-5 full-bleed-banner-mirror-tertiary__text-container">
              {!props.fields?.data.datasource?.hideContentLabel?.jsonValue?.value &&
                props.fields.data.datasource?.link?.jsonValue.value?.href &&
                (isEditorActive() ? (
                  <div className="button-tags full-bleed-banner-mirror-tertiary__tag">
                    {
                      props.fields?.data.datasource?.contentLabel?.targetItem?.title?.jsonValue
                        .value
                    }
                  </div>
                ) : (
                  <Text
                    tag="div"
                    className="button-tags full-bleed-banner-mirror-tertiary__tag"
                    field={
                      props.fields?.data.datasource?.contentLabel?.targetItem?.title?.jsonValue || {
                        value: '',
                      }
                    }
                  />
                ))}
              <Text
                tag={titleTag}
                className="full-bleed-banner-mirror-tertiary__title"
                field={props.fields.data.datasource?.title?.jsonValue}
              />
              <RichText
                tag="div"
                className="body2 full-bleed-banner-mirror-tertiary__text"
                field={props.fields.data.datasource?.description?.jsonValue}
              />
              {(props.fields.data.datasource?.link?.jsonValue.value?.href || editingMode) && (
                <div className="full-bleed-banner-mirror-tertiary__link">
                  <Tertiary
                    fields={{ Link: props.fields.data.datasource?.link?.jsonValue }}
                    params={{}}
                  ></Tertiary>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <FullBleedBannerDefaultComponent {...props} />;
};
