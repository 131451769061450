import Head from 'next/head';
import Script from 'next/script';

export interface JobDetailMetadata {
  jobid: number;
  metatitle: string;
  metadescription: string;
  jobposteddate?: number;
  jobdetailsurl?: string;
  sharingimage?: string;
  ogtype?: string;
  ogsitename?: string;
  fbappid?: string;
  fbadmins?: string;
}

export const DetailMetadata = (props: JobDetailMetadata): JSX.Element => {
  const browserTitle = props.metatitle?.concat(' | Avanade');
  const origin =
    typeof window !== 'undefined' && window.location.origin ? window.location.origin : '';
  return (
    <>
      <Head>
        {props.metatitle && (
          <>
            <title>{browserTitle}</title>
            <meta name="title" content={browserTitle} />
            <meta property="og:title" content={props.metatitle} />
            {/*commenting out twitter sharing*/}
            {/*<meta name="twitter:title" content={props.metatitle} />*/}
          </>
        )}

        {props.metatitle && props.jobposteddate && (
          <meta
            name="description"
            content={`${props.metatitle} created at ${new Date(
              props.jobposteddate
            ).toLocaleDateString('en-US')}`}
          />
        )}

        {/*{props.metadescription && (*/}
        {/*  <meta*/}
        {/*    name="description"*/}
        {/*    content="@string.Concat(jobDetailsTitle, ' created at ',jobPostedDate)"*/}
        {/*  />*/}
        {/*)}*/}

        {/*content load image from template*/}
        {/*<meta property="og:image" content="@ogimage" />*/}
        {/*<meta property="og:image:type" content="image/jpeg" />*/}

        {props.sharingimage && (
          <>
            <meta property="og:image" content={origin + props.sharingimage} />
            <meta property="og:image:type" content="image/jpeg" />
          </>
        )}

        {/*commenting out twitter sharing*/}
        {/*<meta name="twitter:image" content="@ogimage" />*/}

        {props.metadescription && (
          <>
            <meta property="og:description" content={props.metadescription} />
            {/*commenting out twitter sharing*/}
            {/*<meta name="twitter:description" content={props.metadescription} />*/}
          </>
        )}

        {/*<meta property="og:url" content="@currentUrl" />*/}
        {/*<meta property="og:url" content={`${origin}/en/career/job-details/${props.jobid}`} />*/}
        <meta property="og:url" content={`${origin}${props.jobdetailsurl}/${props.jobid}`} />

        {/*commenting out twitter sharing*/}
        {/*<meta name="twitter:url" content="@currentUrl" />*/}
        {/*<meta name="twitter:url" content={`${origin}/en/career/job-details/${props.jobid}`} />*/}

        {/*<link rel="canonical" href="@(_header + '://' + host + canonicalUrl + '/' + jobid)" />*/}
        {/*<link rel="canonical" href={`${origin}/en/career/job-details/${props.jobid}`} />*/}
        <link rel="canonical" href={`${origin}${props.jobdetailsurl}/${props.jobid}`} />

        {/* get from template or common config */}

        {/* commenting this as it is not present in new site */}
        {/*<meta property="og:ttl" content="2419200" />*/}

        {/* get from template or common config */}
        <meta property="og:type" content={props.ogtype ? props.ogtype : 'website'} />

        {/* get from template or common config */}
        {/*<meta*/}
        {/*  property="og:site_name"*/}
        {/*  content="@(context.GetCurrentItem<SocialMedia>().Ava_SiteName)"*/}
        {/*/>*/}
        {props.ogsitename && <meta property="og:site_name" content={props.ogsitename} />}

        {/* get from template or common config */}
        {/*<meta property="fb:app_id" content="@AppIds.GetAppIds().AppsId[' fbAppId']" />*/}
        {props.fbappid && <meta property="fb:app_id" content={props.fbappid} />}

        {/* get from template or common config */}
        {/*<meta property="fb:admins" content="fb admin value" />*/}
        {props.fbadmins && <meta property="fb:admins" content={props.fbadmins} />}

        {/*commenting out twitter sharing*/}
        {/*<meta*/}
        {/*  name="twitter:card"*/}
        {/*  content="@(context.GetCurrentItem<SocialMedia>().Ava_TwitterCard)"*/}
        {/*/>*/}
        {/*<meta*/}
        {/*  name="twitter:site"*/}
        {/*  content="@(context.GetCurrentItem<SocialMedia>().Ava_TwitterSite)"*/}
        {/*/>*/}
      </Head>
      {/*props.iseditingOrPartialDesign && (
        //<div className="is-metadata-hint">
        //  <strong>Metadata:</strong> Job Detail Seo Meta Tags
        //</div>
      )*/}
      <Script
        src="https://widget.altrulabs.com/main.js"
        data-altru-widget-id="9216"
        data-altru-global-region="us"
        //strategy="afterInteractive"
      ></Script>
    </>
  );
};
