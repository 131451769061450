import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useState } from 'react';
import { CardProps } from 'components/card/Card';
import { Tertiary } from 'components/link/Link';
import { TagWithIconLeft } from 'components/tag/Tag';
import { removeColFromStylesProps } from 'src/helpers/sitecoreStyles';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

export const InsightCard = (props: CardProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const { sitecoreContext } = useSitecoreContext();
  const [editingMode] = useState(sitecoreContext && sitecoreContext.pageState === 'edit');
  const datasource = props.fields?.data?.datasource;

  const handleClick = () => {
    if (datasource?.link.jsonValue) window.open(datasource?.link.jsonValue?.value?.href, '_blank');
  };
  const handleChildElementClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (editingMode) {
      e.preventDefault();
    } else {
      e.stopPropagation();
    }
  };

  if (!datasource) {
    return <></>;
  }

  return (
    <div
      className={`card insight-card insight-card__default-container ${removeColFromStylesProps(
        props.params.styles
      )} `}
      id={id ? id : undefined}
      onClick={!editingMode ? handleClick : () => null}
    >
      <div className="insight-card__text-container">
        {((datasource?.subTitle?.jsonValue?.value && datasource?.iconCard?.jsonValue?.value?.src) ||
          editingMode) && (
          <div className="body2 insight-card__tag">
            <TagWithIconLeft
              fields={{
                Title: datasource?.subTitle?.jsonValue || { value: '' },
                Icon: datasource?.iconCard?.jsonValue,
              }}
              params={{}}
            ></TagWithIconLeft>
          </div>
        )}

        <Text
          tag="div"
          className="body1 insight-card__title"
          field={datasource?.title?.jsonValue}
        />
        <RichText
          tag="div"
          className="body2 insight-card__body"
          field={datasource?.body?.jsonValue}
        />
      </div>
      <div onClick={(e) => handleChildElementClick(e)}>
        <Tertiary fields={{ Link: datasource?.link?.jsonValue }} params={{}} />
      </div>
    </div>
  );
};
