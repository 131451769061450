import { buildQuerySummary } from '@coveo/atomic-react';
import {
  Field,
  WithSitecoreContextProps,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { SearchEngineContext } from '../search-context/SearchContext';
import { useState, useEffect } from 'react';
import React from 'react';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import { useShowResult } from 'lib/contexts/ShowingResultContext';
import { useRouter } from 'next/router';

interface SearchQuerySummaryFields {
  TextPattern: Field<string>;
}

type SearchQuerySummaryProps = {
  params: { [key: string]: string };
  fields: SearchQuerySummaryFields;
} & WithSitecoreContextProps;

const Default = (props: SearchQuerySummaryProps): JSX.Element => {
  console.log('---->[SearchQuerySummary] Component', props.fields);

  const searchScope = props.params.SearchScope;
  const { getSearchEngine } = React.useContext(SearchEngineContext);
  const searchEngine = getSearchEngine(searchScope);
  const { showResultData } = useShowResult();
  const [editingMode] = useState<boolean>(isEditorActive());
  const [headlessQuerySummary] = useState(buildQuerySummary(searchEngine)); //we need SC props!
  const [querySummary, setQuerySummary] = useState(headlessQuerySummary.state);
  const [firstSearch, setFirstSearch] = useState(true);
  const urlName = useRouter();
  const total = showResultData['total'];
  const finalCount = showResultData['finalCount'];

  useEffect(() => {
    headlessQuerySummary.subscribe(() => {
      setQuerySummary(headlessQuerySummary.state);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchEngine]);

  useEffect(() => {
    if (window.location.hash) {
      setFirstSearch(false);
    } else {
      setFirstSearch(true);
    }
  }, [querySummary]);

  if ((firstSearch && !editingMode) || (!querySummary.total && !editingMode)) {
    return <></>;
  }

  if (searchEngine.state.search.isLoading) {
    return <></>;
  }
  const id = props.params.RenderingIdentifier;

  if (urlName.asPath.includes('saved-jobs')) {
    if (showResultData['total'] > 0) {
      return (
        <div
          className={`custom-query-summary ${sitecoreStyles(props.params.styles)}`}
          id={id ? id : undefined}
        >
          {editingMode && props.sitecoreContext.route?.templateName == 'Partial Design' ? (
            <div className="is-metadata-hint">
              <strong>Search:</strong> query summary
            </div>
          ) : (
            <div className="h6">
              {props.fields.TextPattern?.value ? (
                <>
                  {props.fields.TextPattern.value
                    .replace(`{CurrentPage}`, String(finalCount))
                    .replace(`{TotalResults}`, String(total))}
                </>
              ) : (
                <>{editingMode && `Text Pattern is not provided.`}</>
              )}
            </div>
          )}
        </div>
      );
    } else {
      return <></>;
    }
  }

  return (
    <div
      className={`custom-query-summary ${sitecoreStyles(props.params.styles)}`}
      id={id ? id : undefined}
    >
      {editingMode && props.sitecoreContext.route?.templateName == 'Partial Design' ? (
        <div className="is-metadata-hint">
          <strong>Search:</strong> query summary
        </div>
      ) : (
        <div className="h6">
          {props.fields.TextPattern?.value ? (
            <>
              {props.fields.TextPattern.value
                .replace(`{CurrentPage}`, querySummary.lastResult.toString())
                .replace(`{TotalResults}`, querySummary.total.toString())}
            </>
          ) : (
            <>{editingMode && `Text Pattern is not provided.`}</>
          )}
        </div>
      )}
    </div>
  );
};

export default withSitecoreContext()(Default);
