export const timestampDateToISO = (timestamp: number | undefined): string => {
  let date = new Date();
  if (timestamp && timestamp > 0) date = new Date(timestamp);

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth is zero-indexed
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  return `${year}${month}${day}T${hours}${minutes}${seconds}Z`;
};

export const formatGuid = (guid?: string): string | undefined => {
  return guid?.replace(/-/g, '')?.toUpperCase();
};
