import React, { useState } from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { HeroProps } from 'components/hero/Hero';
import { Default as PrimaryButton } from 'components/link/Link';
import { HomepageWaves } from 'components/logo-lines/LogoLines';
import NextImageWithNullFallback from 'components/shared/nextImg/NextImageWithNullFallback';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

export const DefaultHomepageHero = (): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  return (
    <React.Fragment>
      {editingMode && (
        <div className={`full-bleed-hero`}>
          <span className="is-empty-hint">Homepage Hero 16/9 Image</span>
        </div>
      )}
    </React.Fragment>
  );
};

export const HomepageHero169Image = (props: HeroProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  if (props.fields) {
    return (
      <div
        className={`homepage-hero-16-9-image component--with-paddings ${props.params.styles}`}
        id={id ? id : undefined}
      >
        <div>
          <div className="homepage-hero-16-9-image__blurBG"></div>

          <div className="container homepage-hero-16-9-image__content">
            <div className="row">
              <div className="col-12 col-md-8 col-lg-5 homepage-hero-16-9-image__text-container">
                <Text
                  tag="h1"
                  className="homepage-hero-16-9-image__title"
                  field={props.fields?.data.datasource?.title?.jsonValue}
                />
                <RichText
                  tag="p"
                  className="homepage-hero-16-9-image__paragraph subheading2"
                  field={props.fields?.data.datasource?.body?.jsonValue}
                />
                {props.fields?.data.datasource?.link?.jsonValue && (
                  <PrimaryButton
                    params={{ styles: 'homepage-hero-16-9-image__link link__button-primary' }}
                    fields={{ Link: props.fields?.data.datasource?.link?.jsonValue }}
                  ></PrimaryButton>
                )}
              </div>
              <div className="col-12 col-md-8 col-lg-7 homepage-hero-16-9-image__image-box">
                <NextImageWithNullFallback
                  field={props.fields?.data.datasource?.image?.jsonValue}
                  loading="eager"
                  priority
                  width={634}
                  height={357}
                  params={{
                    styles: 'next-image--16-9',
                  }}
                />
              </div>
            </div>
          </div>

          <HomepageWaves />
        </div>
      </div>
    );
  }
  return <DefaultHomepageHero />;
};
