import React, { useState } from 'react';
import {
  ComponentParams,
  ComponentRendering,
  Field,
  Text,
  LinkField,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import dynamic from 'next/dynamic';
import config from 'temp/config';
import LazyLoadComponent from 'components/shared/lazy-load/LazyLoadComponent';

export type CardsDatasourceFields = {
  Title?: Field<string>;
  link?: LinkField;
};

interface CardsProps {
  rendering: ComponentRendering & { params: ComponentParams };
  params: ComponentParams;
  fields: CardsDatasourceFields;
  children?: React.ReactNode[];
}

const editingmode = isEditorActive();

let DynamicChildrenOrPlaceholder;
if (editingmode) {
  // Static import when in editing mode

  DynamicChildrenOrPlaceholder =
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    require('components/shared/children-or-placeholder/ChildrenOrPlaceholder').ChildrenOrPlaceholder;
} else {
  // Dynamic import when not in editing mode
  DynamicChildrenOrPlaceholder = dynamic(
    () =>
      import('components/shared/children-or-placeholder/ChildrenOrPlaceholder').then(
        (mod) => mod.ChildrenOrPlaceholder
      ),
    { ssr: !!config.dynamicImportSsr }
  );
}

let DynamicTertiary;

// Static import when in editing mode
if (editingmode) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  DynamicTertiary = require('components/link/Link').Tertiary;
} else {
  // Dynamic import when not in editing mode
  DynamicTertiary = dynamic(() => import('components/link/Link').then((mod) => mod.Tertiary), {
    ssr: !!config.dynamicImportSsr,
  });
}

let counter = 0;

const CardsLayout = ({
  title,
  titleTag,
  containerClass,
  cardCount,
  props,
}: {
  title?: Field<string>;
  titleTag?: string;
  containerClass: string;
  cardCount: number;
  props: CardsProps;
}): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const [editingMode] = useState(isEditorActive());
  const sitecoreContext = useSitecoreContext();
  counter++;
  return (
    <LazyLoadComponent componentId={`CardsLayout${counter}`}>
      <div className={`${containerClass} component--with-paddings`} id={id ? id : undefined}>
        <div className={`${containerClass}__container`}>
          {title && (
            <div className={`${containerClass}__title ${sitecoreStyles(props.params?.styles)}`}>
              <Text tag={titleTag} tabIndex={0} field={title} />
            </div>
          )}
          <div className={`${containerClass}__cards-container`}>
            {Array.from({ length: cardCount }, (_, index) => (
              <div
                key={index}
                className={`${containerClass}__single-card-container single-card-container`}
              >
                <DynamicChildrenOrPlaceholder
                  name={`card-${index + 1}`}
                  rendering={props.rendering}
                  sitecoreContext={sitecoreContext}
                >
                  {props.children ? props.children[index] : null}
                </DynamicChildrenOrPlaceholder>
              </div>
            ))}
          </div>
          {(props?.fields?.link?.value.href || editingMode) && (
            <div className={`${containerClass}__cta-container`}>
              <DynamicTertiary fields={{ Link: props?.fields?.link as LinkField }} params={{}} />
            </div>
          )}
        </div>
      </div>
    </LazyLoadComponent>
  );
};

export const Default = (props: CardsProps): JSX.Element => {
  console.log('---->[Cards] Component', props.fields);

  return (
    <CardsLayout
      title={props.fields?.Title}
      titleTag={props.params['TitleTag'] || 'h3'}
      containerClass="cards--default"
      cardCount={4}
      props={props}
    />
  );
};

export const Stack = (props: CardsProps): JSX.Element => {
  console.log('---->[Cards] Component', props.fields);
  const id = props.params.RenderingIdentifier;
  const sitecoreContext = useSitecoreContext();
  const [editingMode] = useState(isEditorActive());

  return (
    <LazyLoadComponent componentId="Stack">
      <div
        className={`cards--stack component--with-paddings ${sitecoreStyles(props.params?.styles)}`}
        id={id ? id : undefined}
      >
        {(props.fields?.Title?.value || editingMode) && (
          <div className="cards--stack__title">
            <Text tag="h3" tabIndex={0} field={props.fields?.Title} />
          </div>
        )}
        <div className="cards--stack__container">
          <div
            className={`cards--stack__tiles-container ${
              props?.children?.length === 4 ? 'cards--stack__tiles-container--center' : ''
            }`}
          >
            <DynamicChildrenOrPlaceholder
              name={`card-1`}
              rendering={props.rendering}
              sitecoreContext={sitecoreContext}
            >
              {props.children ? props.children[0] : null}
            </DynamicChildrenOrPlaceholder>
            <DynamicChildrenOrPlaceholder
              name={`card-2`}
              rendering={props.rendering}
              sitecoreContext={sitecoreContext}
            >
              {props.children ? props.children[1] : null}
            </DynamicChildrenOrPlaceholder>
            <DynamicChildrenOrPlaceholder
              name={`card-3`}
              rendering={props.rendering}
              sitecoreContext={sitecoreContext}
            >
              {props.children ? props.children[2] : null}
            </DynamicChildrenOrPlaceholder>
            <DynamicChildrenOrPlaceholder
              name={`card-4`}
              rendering={props.rendering}
              sitecoreContext={sitecoreContext}
            >
              {props.children ? props.children[3] : null}
            </DynamicChildrenOrPlaceholder>
          </div>
        </div>
      </div>
    </LazyLoadComponent>
  );
};

export const WithoutTitle = (props: CardsProps): JSX.Element => {
  console.log('---->[Cards] Component', props.fields);

  return <CardsLayout containerClass="cards--without-title" cardCount={4} props={props} />;
};

export const TopTitle = (props: CardsProps): JSX.Element => {
  console.log('---->[Cards] Component', props.fields);

  return (
    <CardsLayout
      title={props.fields?.Title}
      titleTag="h3"
      containerClass="cards--top-title"
      cardCount={4}
      props={props}
    />
  );
};

export const StackWithScroll = (props: CardsProps): JSX.Element => {
  console.log('---->[Cards] Component', props.fields);

  return (
    <div className="cards--stack-with-scroll">
      <Stack {...props} />
    </div>
  );
};
