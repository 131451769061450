import React from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { BannerFields } from 'components/banner/Banner';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import dynamic from 'next/dynamic';
import LazyLoadComponent from 'components/shared/lazy-load/LazyLoadComponent';
import config from 'temp/config';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

type BannerProps = {
  params: { [key: string]: string; styles: string };
  fields?: {
    data: {
      datasource?: BannerFields;
    };
  };
};

export const TextImageLeft = (props: BannerProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const titleTag = props.params['TitleTag'] || 'h2';
  const editingmode = isEditorActive();

  let DynamicNextImageWithNullFallback;

  // Static import when in editing mode
  if (editingmode) {
    DynamicNextImageWithNullFallback =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/shared/nextImg/NextImageWithNullFallback').NextImageWithNullFallback;
  } else {
    // Dynamic import when not in editing mode
    DynamicNextImageWithNullFallback = dynamic(
      () =>
        import('components/shared/nextImg/NextImageWithNullFallback').then(
          (mod) => mod.NextImageWithNullFallback
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }

  let DynamicTertiary;

  // Static import when in editing mode
  if (editingmode) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicTertiary = require('components/link/Link').Tertiary;
  } else {
    // Dynamic import when not in editing mode
    DynamicTertiary = dynamic(() => import('components/link/Link').then((mod) => mod.Tertiary), {
      ssr: !!config.dynamicImportSsr,
    });
  }

  return (
    <LazyLoadComponent componentId="TextImageLeft">
      <div
        className={`text-image-left component--with-paddings ${sitecoreStyles(
          props.params?.styles
        )}`}
        id={id ? id : undefined}
      >
        <div className="row">
          <div className="col-12 col-lg-7 text-image-left__image-column">
            <DynamicNextImageWithNullFallback
              field={props.fields?.data.datasource?.image?.jsonValue}
              width={648}
              height={365}
              params={{
                styles: 'text-image-left__image-container next-image--16-9',
              }}
            ></DynamicNextImageWithNullFallback>
          </div>

          <div className="col-12 col-lg-5 text-image-left__text-column">
            <Text
              tag={titleTag}
              field={props.fields?.data.datasource?.title?.jsonValue}
              className="text-image-left__header"
            />
            <RichText
              tag="div"
              className="text-image-left__description body2"
              field={props.fields?.data.datasource?.description?.jsonValue}
            />
            {props.fields?.data.datasource?.link?.jsonValue && (
              <DynamicTertiary
                fields={{ Link: props.fields?.data.datasource?.link?.jsonValue }}
                params={{}}
              ></DynamicTertiary>
            )}
          </div>
        </div>
      </div>
    </LazyLoadComponent>
  );
};
