import React, { useState } from 'react';
import Script from 'next/script';
import Head from 'next/head';
import {
  WithSitecoreContextProps,
  withSitecoreContext,
  Field,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

interface Fields {
  pageinstanceid: Field<string>;
  pageid: Field<string>;
  version: Field<string>;
  siteid: Field<string>;
  sitebranch: Field<string>;
  country: Field<string>;
  pagetype: Field<string>;
  language: Field<string>;
  sitecoretemplateid: Field<string>;
  sitecoretemplatename: Field<string>;
  sitecorepublishdate: Field<string>;
  sitecorecontentdate: Field<string>;
  adobeid: Field<string>;
  targetid: Field<string>;
}

type AdobeProps = {
  params: { [key: string]: string };
  fields?: Fields;
} & WithSitecoreContextProps;

export const Default = (props: AdobeProps) => {
  console.log('---->[Adobe] Component', props.fields);
  const [editingMode] = useState(isEditorActive());
  if (editingMode && props.sitecoreContext?.route?.templateName == 'Partial Design') {
    return (
      <>
        <div className="is-metadata-hint">
          <strong>Metadata:</strong> Adobe
        </div>
      </>
    );
  } else {
    return (
      <>
        {props.fields &&
          Object.values(props.fields).map((item, index) => (
            <React.Fragment key={index}>
              <Head key={index + '-head'}>
                <link rel="preconnect" href="https://dpm.demdex.net" />
                <link rel="preconnect" href="https://fast.techmarketingdemos.demdex.net" />
                <link rel="preconnect" href="https://techmarketingdemos.demdex.net" />
                <link rel="preconnect" href="https://cm.everesttech.net" />
                <link rel="preconnect" href="https://techmarketingdemos.tt.omtrdc.net" />
                <link rel="preconnect" href="https://techmarketingdemos.sc.omtrdc.net" />
                <link rel="dns-prefetch" href="https://dpm.demdex.net" />
                <link rel="dns-prefetch" href="https://fast.techmarketingdemos.demdex.net" />
                <link rel="dns-prefetch" href="https://techmarketingdemos.demdex.net" />
                <link rel="dns-prefetch" href="https://cm.everesttech.net" />
                <link rel="dns-prefetch" href="https://techmarketingdemos.tt.omtrdc.net" />
                <link rel="dns-prefetch" href="https://techmarketingdemos.sc.omtrdc.net" />
              </Head>
              <Script id="initialize-target" key={index + '-script-A'}>
                {`
                  function targetPageParams() {
                    return {
                      "at_property": "${item.targetid}",
                    };
                  }
                `}
              </Script>

              <Script id="initialize-adobe" key={index + '-script-B'}>
                {`
              
                                 mqRl = "lg/md";
                                  function getDeviceSize() {
                                      var wWidth = window.screen.width;
                                      var sbWidth = window.innerWidth - document.documentElement.clientWidth;
                                      var vpWidth = wWidth + sbWidth;
                                      if (vpWidth >= 480 && vpWidth <= 767) {
                                          mqRl = "xs";
                                      }
                                      else if (vpWidth >= 768 && vpWidth <= 999) {
                                          mqRl = "sm";
                                      }
                                      else if (vpWidth >= 1000) {
                                          mqRl = "lg/md";
                                      }
                                  }              
                                  getDeviceSize();
                                  var dataModel = {
                                    "enableLaunch": true,
                                    "hostname": window.location.hostname,
                                    "page": {
                                      "pageInstanceId": "${item.pageinstanceid}", //index
                                      "id" : "${item.pageid}",
                                      "version": "${item.version}",
                                      "pageInfo": {
                                        "siteID": "${item.siteid}", //e.g. abs
                                        "siteBranch": "${item.sitebranch}",
                                        "country": "${item.country}", //e.g. us1
                                        "pageName": window.location.pathname + window.location.search, //e.g. abs:index
                                        "pageUrl" :  window.location.pathname + window.location.search,
                                        "pageType": "${item.pagetype}",
                                        "urlReferrerDomain": document.referrer,
                                        "responsiveLayout": mqRl, //dynamic based on the screen size
                                        "errorMessage": "",
                                        "language": "${item.language}", //e.g. en2
                                        "sitecoreTemplateId": "${item.sitecoretemplateid}",
                                        "sitecoreTemplateName": "${item.sitecoretemplatename}",
                                        "sitecorePublishDate": "${item.sitecorepublishdate}",
                                        "sitecoreContentDate": "${item.sitecorecontentdate}",
                                        "pageShortURL": ""
                                      }
                                    }
                                  }
                            `}
              </Script>
              <Script
                id="AdobeScript"
                key={index + '-script-C'}
                src={`https://assets.adobedtm.com/ea56a3787115/06fa619f79c1/${item.adobeid}.min.js`}
                data-document-language="true"
                type="text/javascript"
                strategy="afterInteractive"
              />
            </React.Fragment>
          ))}
      </>
    );
  }
};

export default withSitecoreContext()(Default);
