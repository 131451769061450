/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Field,
  WithSitecoreContextProps,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import Script from 'next/script';
import React, { useState, useRef } from 'react';

type Strategy = 'afterInteractive' | 'lazyOnload' | 'beforeInteractive' | 'worker'; ///afterInteractive -> script in body; beforeInteractive -> script in head

interface Fields {
  Value?: Field<string>;
  Attributes?: Field<string>;
}

type ScriptProps = {
  params: { [key: string]: string };
  fields?: Fields;
} & WithSitecoreContextProps;

export const Default = (props: ScriptProps) => {
  console.log('---->[Script] Component.Fields', props.fields);
  console.log('---->[Script] Component.Params', props.params);

  const [editingMode] = useState(isEditorActive());
  const containerRef = useRef<HTMLInputElement>(null);

  const parseKeyValuePairs = (input?: string): { [key: string]: string } => {
    const result: { [key: string]: string } = {};
    if (input) {
      const pairs = input.split('&');
      for (const pair of pairs) {
        const [key, value] = pair.split('=');

        // Only add to the result if both key and value are present
        if (key && value) {
          result[decodeURIComponent(key)] = decodeURIComponent(value);
        }
      }
    }
    return result;
  };

  const convertAttributesToString = (attributes: { [key: string]: string }) => {
    return Object.keys(attributes)
      .map((key) => `${key}="${attributes[key]}"`)
      .join(' ');
  };

  const strategy = (props.params.Strategy || 'afterInteractive') as Strategy;
  const renderInline = props.params.RenderAsInlineScript === '1';
  const renderInEditingMode = props.params.RenderInEditingMode === '1';
  const attributes = parseKeyValuePairs(props?.fields?.Attributes?.value);
  const id = attributes.id || props.params.RenderingIdentifier;
  let rawScript = props.fields?.Value?.value?.trim();
  if (!renderInline && rawScript) {
    attributes['src'] = rawScript;
    rawScript = undefined;
  }

  //Code to append generated iframe in current parent node
  function moveScript() {
    const iframeID = document.getElementById('bugcrowd-external-submission-form')!;
    if (containerRef.current) {
      containerRef.current.appendChild(iframeID);
    }
  }
  return (
    <>
      {rawScript ? (
        <>
          {editingMode ? (
            <>
              {renderInEditingMode ? (
                <>
                  <Script id={id ? id : undefined} strategy={strategy} {...attributes}>
                    {rawScript}
                  </Script>
                </>
              ) : (
                <>
                  <pre>
                    {`<script ${convertAttributesToString(attributes)}>\n${rawScript}\n</script>`}
                  </pre>
                </>
              )}
            </>
          ) : (
            <>
              {attributes['data-bugcrowd-program'] ? (
                <div ref={containerRef} id="script-container">
                  <Script
                    id={id ? id : undefined}
                    strategy={strategy}
                    {...attributes}
                    onLoad={moveScript}
                  >
                    {rawScript}
                  </Script>
                </div>
              ) : (
                <Script id={id ? id : undefined} strategy={strategy} {...attributes}>
                  {rawScript}
                </Script>
              )}
            </>
          )}
        </>
      ) : (
        <div className={`component script`}>
          <div className="component-content">
            <span className="is-empty-hint">
              Script: component is missing datasource or Value field is empty
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default withSitecoreContext()(Default);
