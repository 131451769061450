import { ImageField, LinkField, Link } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useState } from 'react';
import sitecoreStyles, { removeColFromStylesProps } from 'src/helpers/sitecoreStyles';
import { IconName } from 'src/types/Enum';
import { useI18n } from 'next-localization';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import dynamic from 'next/dynamic';
import config from 'temp/config';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

interface Fields {
  Link?: LinkField;
  Icon?: ImageField;
}

type LinkProps = {
  params: { [key: string]: string };
  fields?: Fields;
};

const LinkDefaultComponent = (props: LinkProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const [editingMode] = useState(sitecoreContext && sitecoreContext.pageState === 'edit');
  return (
    <React.Fragment>
      {editingMode && (
        <div className={`link ${sitecoreStyles(props.params.styles)}`}>
          <div className="link">Link</div>
        </div>
      )}
    </React.Fragment>
  );
};

export const Default = (props: LinkProps): JSX.Element => {
  console.log('---->[Link] Component', props.fields);
  const id = props.params.RenderingIdentifier;
  const { sitecoreContext } = useSitecoreContext();
  const [editingMode] = useState(sitecoreContext && sitecoreContext.pageState === 'edit');

  if (props.fields) {
    if (!!!props.fields.Link?.value?.href && !!!props.fields.Link?.value?.anchor && !editingMode) {
      return <></>;
    }
    let linktype = 'internal-link';
    if (props.fields?.Link?.value?.linktype == 'internal') {
      linktype = 'internal-link';
    } else if (props.fields?.Link?.value?.linktype == 'external') {
      linktype = '.external-links';
    } else if (props.fields?.Link?.value?.linktype == 'media') {
      linktype = 'media-link';
    } else if (props.fields?.Link?.value?.linktype == 'mailto') {
      linktype = 'mailto-link';
    } else if (props.fields?.Link?.value?.linktype == 'anchor') {
      linktype = 'anchor-link';
    } else if (props.fields?.Link?.value?.linktype == 'javascript') {
      linktype = 'javascript';
    }
    return (
      <Link
        id={id ? id : undefined}
        className={`link ga-track ${linktype} ${removeColFromStylesProps(props.params.styles)}`}
        field={props.fields.Link || {}}
        aria-label={`${props.fields?.Link?.value.text}`}
        data-analytics-link-name={`${props.fields?.Link?.value?.text}`}
        data-analytics-link-type="Link"
        data-analytics-content-class="Content"
        data-analytics-template-zone="Body"
        data-analytics-component-name="Link-Molecule"
        data-analytics-component-variation="NAN"
        data-analytics-target={`${props.fields?.Link?.value?.href}`}
        data-analytics-component-section="NAN"
        data-analytics-slide-number="NAN"
        data-analytics-ispersonalized="False"
        data-analytics-iscarousal="False"
      />
    );
  }
  return <LinkDefaultComponent {...props} />;
};

export const Disabled = (props: LinkProps): JSX.Element => {
  console.log('---->[Link] Component', props.fields);
  const id = props.params.RenderingIdentifier;
  if (props.fields) {
    let linktype = 'internal-link';
    const type = props.fields?.Link?.value?.linktype;

    if (type == 'internal') {
      linktype = 'internal-link';
    } else if (type == 'external') {
      linktype = '.external-links';
    } else if (type == 'media') {
      linktype = 'media-link';
    } else if (type == 'mailto') {
      linktype = 'mailto-link';
    } else if (type == 'anchor') {
      linktype = 'anchor-link';
    } else if (type == 'javascript') {
      linktype = 'javascript';
    }
    return (
      <Link
        id={id ? id : undefined}
        tabIndex={-1}
        className={`link ${linktype} ${removeColFromStylesProps(props.params.styles)}`}
        field={props.fields.Link || {}}
        aria-label={`${props.fields?.Link?.value?.text}`}
        data-analytics-link-name={`${props.fields?.Link?.value?.text}`}
        data-analytics-link-type="Link"
        data-analytics-content-class="Content"
        data-analytics-template-zone="Body"
        data-analytics-component-name="Link-Molecule-Tertiary"
        data-analytics-component-variation="NAN"
        data-analytics-target={`${props.fields?.Link?.value?.href}`}
        data-analytics-component-section="NAN"
        data-analytics-slide-number="NAN"
        data-analytics-ispersonalized="False"
        data-analytics-iscarousal="False"
      />
    );
  }
  return <LinkDefaultComponent {...props} />;
};

const editingmode = isEditorActive();
let DynamicIcon;
if (editingmode) {
  // Static import when in editing mode
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  DynamicIcon = require('components/shared/icon/Icon').Icon;
} else {
  // Dynamic import when not in editing mode
  DynamicIcon = dynamic(() => import('components/shared/icon/Icon').then((mod) => mod.Icon), {
    ssr: !!config.dynamicImportSsr,
  });
}
export const Tertiary = (props: LinkProps): JSX.Element => {
  console.log('---->[Link] Component', props.fields);
  const id = props.params.RenderingIdentifier;
  const { sitecoreContext } = useSitecoreContext();
  const [editingMode] = useState(sitecoreContext && sitecoreContext.pageState === 'edit');

  if (props.fields) {
    if (!!!props.fields.Link?.value?.href && !editingMode) {
      return <></>;
    }

    let linktype = 'internal-link';
    const type = props.fields?.Link?.value?.linktype;

    if (type == 'internal') {
      linktype = 'internal-link';
    } else if (type == 'external') {
      linktype = '.external-links';
    } else if (type == 'media') {
      linktype = 'media-link';
    } else if (type == 'mailto') {
      linktype = 'mailto-link';
    } else if (type == 'anchor') {
      linktype = 'anchor-link';
    } else if (type == 'javascript') {
      linktype = 'javascript';
    }

    const linkText = props.fields.Link?.value?.text || props.fields.Link?.value?.title;

    if (
      props.fields &&
      props.fields.Link &&
      props.fields.Link.value &&
      props.fields.Link.value.href?.startsWith('http://#videoid')
    ) {
      props.fields.Link.value.href = props.fields.Link.value.href?.replace(
        'http://#videoid',
        '#videoid'
      );
    }

    if (
      props.fields &&
      props.fields.Link &&
      props.fields.Link.value &&
      props.fields.Link.value.href?.startsWith('http://#playlistId')
    ) {
      props.fields.Link.value.href = props.fields.Link.value.href?.replace(
        'http://#playlistId',
        '#playlistId'
      );
    }

    return (
      <>
        <Link
          id={id ? id : undefined}
          className={`link ga-track ${linktype} link__button-tertiary subheading1 ${removeColFromStylesProps(
            props.params.styles
          )}`}
          field={props.fields?.Link || {}}
          aria-label={linkText}
          data-analytics-link-name={linkText}
          data-analytics-link-type="Link"
          data-analytics-content-class="Content"
          data-analytics-template-zone="Body"
          data-analytics-component-name="Link-Molecule-Tertiary"
          data-analytics-component-variation="NAN"
          data-analytics-target={`${
            props.fields.Link?.value?.href
              ? props.fields.Link?.value?.href.toString().toLowerCase()
              : '#'
          }`}
          data-analytics-component-section="NAN"
          data-analytics-slide-number="NAN"
          data-analytics-ispersonalized="False"
          data-analytics-iscarousal="False"
        >
          {!editingMode && (
            <div className="link__button-tertiary-text">
              <span>{linkText}</span>
              <div className="link__button-tertiary-image-container">
                <div className="link__button-tertiary-arrow">
                  <DynamicIcon icon={IconName.ArrowRight} iconLabel={'Open link'} />
                </div>

                <div className="link__button-tertiary-arrow-active">
                  <DynamicIcon icon={IconName.ArrowRightActive} iconLabel={'Open active link'} />
                </div>
              </div>
            </div>
          )}
        </Link>
        {editingMode && (
          <a className="link__button-tertiary">
            <div className="link__button-tertiary-image-container">
              <div className="link__button-tertiary-arrow">
                <DynamicIcon icon={IconName.ArrowRight} iconLabel={'Open link'} />
              </div>

              <div className="link__button-tertiary-arrow-active">
                <DynamicIcon icon={IconName.ArrowRightActive} iconLabel={'Open active link'} />
              </div>
            </div>
          </a>
        )}
      </>
    );
  }
  return <LinkDefaultComponent {...props} />;
};

export const TertiaryWithLeftArrow = (props: LinkProps): JSX.Element => {
  console.log('---->[Tertiary with Left Arrow Link] Component', props.fields);
  const id = props.params.RenderingIdentifier;
  const { sitecoreContext } = useSitecoreContext();
  const [editingMode] = useState(sitecoreContext && sitecoreContext.pageState === 'edit');

  if (props.fields) {
    console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^props.fields.Link', props.fields.Link);
    if (!!!props.fields.Link?.value?.href && !editingMode) {
      return <></>;
    }
    let linktype = 'internal-link';
    if (props.fields?.Link?.value?.linktype == 'internal') {
      linktype = 'internal-link';
    } else if (props.fields?.Link?.value?.linktype == 'external') {
      linktype = '.external-links';
    } else if (props.fields?.Link?.value?.linktype == 'media') {
      linktype = 'media-link';
    } else if (props.fields?.Link?.value?.linktype == 'mailto') {
      linktype = 'mailto-link';
    } else if (props.fields?.Link?.value?.linktype == 'anchor') {
      linktype = 'anchor-link';
    } else if (props.fields?.Link?.value?.linktype == 'javascript') {
      linktype = 'javascript';
    }
    return (
      <Link
        id={id ? id : undefined}
        className={`link ga-track ${linktype} link__button-tertiary subheading1 ${removeColFromStylesProps(
          props.params.styles
        )}`}
        field={props.fields.Link || {}}
        aria-label={`${props.fields?.Link?.value?.text}`}
        data-analytics-link-name={`${props.fields?.Link?.value?.text}`}
        data-analytics-link-type="Link"
        data-analytics-content-class="Content"
        data-analytics-template-zone="Body"
        data-analytics-component-name="Link-Molecule-Tertiary"
        data-analytics-component-variation="NAN"
        data-analytics-target={`${
          props.fields.Link?.value?.href
            ? props.fields.Link?.value?.href.toString().toLowerCase()
            : '#'
        }`}
        data-analytics-component-section="NAN"
        data-analytics-slide-number="NAN"
        data-analytics-ispersonalized="False"
        data-analytics-iscarousal="False"
      >
        <div className="link__button-tertiary-image-container">
          <div className="link__button-tertiary-arrow">
            <DynamicIcon icon={IconName.ArrowRight} iconLabel={'Open link'} />
          </div>

          <div className="link__button-tertiary-arrow-active">
            <DynamicIcon icon={IconName.ArrowRightActive} iconLabel={'Open active link'} />
          </div>
        </div>
        {!editingMode && (
          <div className="link__button-tertiary-text">{props.fields.Link?.value?.text}</div>
        )}
      </Link>
    );
  }
  return <LinkDefaultComponent {...props} />;
};
export const TertiaryWithDownArrow = (props: LinkProps): JSX.Element => {
  console.log('---->[Tertiary with Down Arrow Link] Component', props.fields);
  const id = props.params.RenderingIdentifier;
  const { sitecoreContext } = useSitecoreContext();
  const [editingMode] = useState(sitecoreContext && sitecoreContext.pageState === 'edit');
  const { t } = useI18n(); /*Dictionary*/

  if (props.fields) {
    console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^props.fields.Link', props.fields.Link);
    if (!!!props.fields.Link?.value?.href && !editingMode) {
      return <></>;
    }
    let linktype = 'internal-link';
    if (props.fields?.Link?.value?.linktype == 'internal') {
      linktype = 'internal-link';
    } else if (props.fields?.Link?.value?.linktype == 'external') {
      linktype = '.external-links';
    } else if (props.fields?.Link?.value?.linktype == 'media') {
      linktype = 'media-link';
    } else if (props.fields?.Link?.value?.linktype == 'mailto') {
      linktype = 'mailto-link';
    } else if (props.fields?.Link?.value?.linktype == 'anchor') {
      linktype = 'anchor-link';
    } else if (props.fields?.Link?.value?.linktype == 'javascript') {
      linktype = 'javascript';
    }
    return (
      <Link
        id={id ? id : undefined}
        className={`link ga-track ${linktype} link__button-tertiary subheading1 ${removeColFromStylesProps(
          props.params.styles
        )}`}
        field={props.fields.Link || {}}
        aria-label={`${props.fields?.Link?.value?.text}`}
        data-analytics-link-name={`${props.fields?.Link?.value?.text}`}
        data-analytics-link-type="Link"
        data-analytics-content-class="Content"
        data-analytics-template-zone="Body"
        data-analytics-component-name="Link-Molecule-Tertiary"
        data-analytics-component-variation="NAN"
        data-analytics-target={`${
          props.fields.Link?.value?.href &&
          props.fields.Link?.value?.text != t(`career-job-load-more`)
            ? props.fields.Link?.value?.href.toString().toLowerCase()
            : 'NAN'
        }`}
        data-analytics-component-section="NAN"
        data-analytics-slide-number="NAN"
        data-analytics-ispersonalized="False"
        data-analytics-iscarousal="False"
      >
        {!editingMode && (
          <div className="link__button-tertiary-text">{props.fields.Link?.value?.text}</div>
        )}

        <div className="link__button-tertiary-image-container">
          <div className="link__button-tertiary-arrow">
            <DynamicIcon icon={IconName.ArrowDown} iconLabel={'Open link'} />
          </div>

          <div className="link__button-tertiary-arrow-active">
            <DynamicIcon icon={IconName.ArrowDownActive} iconLabel={'Open active link'} />
          </div>
        </div>
      </Link>
    );
  }
  return <LinkDefaultComponent {...props} />;
};

export default Default;
