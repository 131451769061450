import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { BannerFields } from 'components/banner/Banner';
import { NextImageWithNullFallback } from 'components/shared/nextImg/NextImageWithNullFallback';
import { SectionHeaderWavePrimary } from 'components/logo-lines/LogoLines';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import LazyLoadComponent from 'components/shared/lazy-load/LazyLoadComponent';

type SectionHeaderProps = {
  params: { [key: string]: string; styles: string };
  fields?: {
    data: {
      datasource?: BannerFields;
    };
  };
};

export const SectionHeader1 = (props: SectionHeaderProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const titleTag = props.params['TitleTag'] || 'h2';
  return (
    <LazyLoadComponent componentId="sectionHeaderPrimary">
      <div
        className={`section-header-primary component--with-paddings ${sitecoreStyles(
          props.params?.styles
        )}`}
        id={id ? id : undefined}
      >
        <div className="row section-header-primary__content">
          <div className="container">
            <div className="row section-header-primary__header">
              <div className="col-12 col-lg-10">
                <Text tag={titleTag} field={props.fields?.data.datasource?.title?.jsonValue} />
              </div>
            </div>
          </div>

          <div className="row section-header-primary__main-container">
            <div className="col-12 col-md-8 col-lg-12">
              <div className="container no-padding-container">
                <div className="section-header-primary__image-container">
                  <NextImageWithNullFallback
                    field={props.fields?.data.datasource?.image?.jsonValue}
                    // width={1128}
                    // height={376}
                    fill
                    sizes="(max-width: 1200px) 100vw, (min-width: 1201px) 50vw, 33vw"
                    params={{
                      styles: 'section-header-primary__image next-image--3-1',
                    }}
                  />
                </div>
              </div>
            </div>
            <SectionHeaderWavePrimary />
          </div>
        </div>
      </div>
    </LazyLoadComponent>
  );
};
