import React from 'react';
import 'animate.css';

export type SliderProps = {
  incompleteCount: number;
  doneCount: number;
};

export const Slider = ({ incompleteCount, doneCount }: SliderProps): JSX.Element => {
  if (incompleteCount == 0 && doneCount == 0) {
    incompleteCount = 1;
  }

  return (
    <div tabIndex={-1} className={`component slider`}>
      <div className="slider__step">
        <div
          className="slider--done"
          style={{ height: `${(doneCount / (incompleteCount + doneCount)) * 100}%` }}
        ></div>
        <div className="slider--incomplete"></div>
      </div>
    </div>
  );
};
