import React, { useState, useEffect } from 'react';
import { Field, DateField } from '@sitecore-jss/sitecore-jss-nextjs';
import {
  format,
  isBefore,
  isSameDay,
  isSameMonth,
  parseISO,
  startOfToday,
  subDays,
} from 'date-fns';
import { useI18n } from 'next-localization';
import { getLocale } from 'components/posted-date/PostedDate';

export interface DateTimeRangeFields {
  id?: string;
  startDateTime?: {
    jsonValue: Field<string>;
  };
  endDateTime?: {
    jsonValue: Field<string>;
  };
  hideTime?: {
    jsonValue: Field<string>;
  };
  timezone?: {
    jsonValue: Field<string>;
  };
  twentyFourHour?: {
    jsonValue: Field<string>;
  };
}

export type DateTimeRangeProps = {
  params: { [key: string]: string };
  fields?: {
    data: {
      contextItem?: DateTimeRangeFields;
    };
  };
};

export const Default = (props: DateTimeRangeProps): JSX.Element => {
  console.log('---->[DateTime] Component', props.fields);
  const { locale } = useI18n();
  const [startDateLocal, setStartDateLocal] = useState<Date | null>(null);
  const [endDateLocal, setEndDateLocal] = useState<Date | null>(null);
  const [startTimeLocal, setStartTimeLocal] = useState<string | null>('');
  const [endTimeLocal, setEndTimeLocal] = useState<string | null>('');

  const timezoneValue = props.fields?.data?.contextItem?.timezone?.jsonValue?.value;
  const is24HourFormat = props.fields?.data?.contextItem?.twentyFourHour?.jsonValue?.value;
  const hideTime = props.fields?.data?.contextItem?.hideTime?.jsonValue?.value;

  // Define timeFormat based on 24-hour format
  const timeFormat = is24HourFormat ? 'HH:mm' : 'h:mma';
  useEffect(() => {
    const datasource = props.fields?.data.contextItem;
    if (datasource && datasource.startDateTime && datasource.endDateTime) {
      const startDateISO =
        datasource?.startDateTime?.jsonValue?.value.toString() || new Date().toISOString();
      const endDateISO =
        datasource?.endDateTime?.jsonValue?.value.toString() || new Date().toISOString();
      // Extract time parts directly from ISO strings
      const startTimePart = startDateISO.split('T')[1].replace('Z', '').substring(0, 5);
      const endTimePart = endDateISO.split('T')[1].replace('Z', '').substring(0, 5);
      // Use the extracted time parts and format according to timeFormat

      const splitStartDate = startDateISO.split('T')[0];
      const splitEndDate = endDateISO.split('T')[0];

      const formattedStartTime =
        timeFormat === 'HH:mm'
          ? startTimePart
          : format(new Date(`1970-01-01T${startTimePart}`), timeFormat);
      const formattedEndTime =
        timeFormat === 'HH:mm'
          ? endTimePart
          : format(new Date(`1970-01-01T${endTimePart}`), timeFormat);
      setStartDateLocal(parseISO(splitStartDate));
      setEndDateLocal(parseISO(splitEndDate));
      setStartTimeLocal(formattedStartTime);
      setEndTimeLocal(formattedEndTime);
    }
  }, [locale, props.fields, timeFormat]);

  const datasource = props.fields?.data.contextItem;

  if (
    !datasource ||
    !datasource.startDateTime ||
    !datasource.endDateTime ||
    !startDateLocal ||
    !endDateLocal
  ) {
    return <></>;
  }

  const defaultDate = '0001-01-01T00:00:00Z';
  if (
    datasource.startDateTime.jsonValue.value === defaultDate ||
    datasource.endDateTime.jsonValue.value === defaultDate
  ) {
    return <></>;
  }
  const today = startOfToday();
  const twoDaysAgo = subDays(today, 2);
  const bothDatesPast = isBefore(startDateLocal, twoDaysAgo) && isBefore(endDateLocal, twoDaysAgo);
  if (bothDatesPast) {
    return <></>;
  }
  // Determine the date formatting based on the conditions
  const isSameDayResult = isSameDay(startDateLocal, endDateLocal);
  const isSameMonthResult = isSameMonth(startDateLocal, endDateLocal);
  const isSameYearResult = startDateLocal.getFullYear() === endDateLocal.getFullYear();

  let startdatetimeFormat = 'MMMM d';
  let enddatetimeFormat = '';

  if (locale() === 'es-ES') {
    // Spanish locale formatting
    startdatetimeFormat = "d 'de' MMMM 'de'";
    if (isSameDayResult) {
      enddatetimeFormat = 'yyyy'; // Only year for the same day
    } else if (isSameMonthResult) {
      startdatetimeFormat = 'd';
      enddatetimeFormat = "d 'de' MMMM 'de' yyyy"; // Only year for the same month
    } else if (!isSameYearResult) {
      startdatetimeFormat += ' yyyy'; // Full date for different years
      enddatetimeFormat = "d 'de' MMMM 'de' yyyy"; // Full date for different years
    } else {
      enddatetimeFormat = "d 'de' MMMM 'de' yyyy"; // Full date for same year
    }
  } else {
    // Default formatting for other locales
    if (isSameDayResult) {
      enddatetimeFormat = 'yyyy';
    } else if (isSameMonthResult) {
      enddatetimeFormat = 'd, yyyy';
    } else if (!isSameYearResult) {
      startdatetimeFormat = 'MMMM d, yyyy';
      enddatetimeFormat = 'MMMM d, yyyy';
    } else {
      enddatetimeFormat = 'MMMM d, yyyy';
    }
  }

  /// Format the dates
  let formattedStartDate = format(startDateLocal, startdatetimeFormat, {
    locale: getLocale(locale()),
  });
  let formattedEndDate = format(endDateLocal, enddatetimeFormat, { locale: getLocale(locale()) });

  // Capitalize the first letter of the month for Spanish locale
  if (locale() === 'es-ES') {
    const startMonthIndex = formattedStartDate.indexOf('de') + 3; // Find the index after 'de '
    const startMonth = formattedStartDate.slice(startMonthIndex, startMonthIndex + 1).toUpperCase(); // Capitalize the first letter of the month
    formattedStartDate =
      formattedStartDate.slice(0, startMonthIndex) +
      startMonth +
      formattedStartDate.slice(startMonthIndex + 1); // Replace the month in the final string

    const endMonthIndex = formattedEndDate.indexOf('de') + 3; // Find the index after 'de '
    const endMonth = formattedEndDate.slice(endMonthIndex, endMonthIndex + 1).toUpperCase(); // Capitalize the first letter of the month
    formattedEndDate =
      formattedEndDate.slice(0, endMonthIndex) +
      endMonth +
      formattedEndDate.slice(endMonthIndex + 1); // Replace the month in the final string
  }

  return (
    <div className="dateTimeRangeLabel h4">
      {startDateLocal ? (
        <>
          <DateField
            tag="h4"
            field={datasource.startDateTime.jsonValue}
            render={(date) => <>{date ? <>{formattedStartDate}</> : <></>}</>}
          />
          <DateField
            tag="h4"
            field={datasource.endDateTime.jsonValue}
            render={(date) => (
              <>
                {date ? (
                  <>
                    {isSameDayResult ? (
                      locale() === 'es-ES' ? (
                        <>&nbsp;</>
                      ) : (
                        <>,&nbsp;</>
                      )
                    ) : !isSameMonthResult ? (
                      <>&nbsp;-&nbsp;</>
                    ) : (
                      <>&nbsp;-&nbsp;</>
                    )}
                    {formattedEndDate}
                    {!hideTime && (
                      <>
                        , {startTimeLocal?.toLowerCase()} - {endTimeLocal?.toLowerCase()}{' '}
                        {timezoneValue && <>{timezoneValue}</>}
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export const DateRangeWithoutTimezone = (props: DateTimeRangeProps): JSX.Element => {
  console.log('---->[DateRangeOnly] Component', props.fields);
  const { locale } = useI18n();
  const [startDateLocal, setStartDateLocal] = useState<Date | null>(null);
  const [endDateLocal, setEndDateLocal] = useState<Date | null>(null);

  useEffect(() => {
    const datasource = props.fields?.data?.contextItem;

    if (datasource && datasource.startDateTime && datasource.endDateTime) {
      const startDateISO =
        new Date(datasource?.startDateTime?.jsonValue?.value).toISOString() ||
        new Date().toISOString();
      const endDateISO =
        new Date(datasource?.endDateTime?.jsonValue?.value).toISOString() ||
        new Date().toISOString();

      const splitStartDate = startDateISO.split('T')[0];
      const splitEndDate = endDateISO.split('T')[0];

      setStartDateLocal(parseISO(splitStartDate));
      setEndDateLocal(parseISO(splitEndDate));
    }
  }, [locale, props.fields]);

  const datasource = props.fields?.data?.contextItem;

  if (
    !datasource ||
    !datasource.startDateTime ||
    !datasource.endDateTime ||
    !startDateLocal ||
    !endDateLocal
  ) {
    return <></>;
  }

  const defaultDate = '1401-01-01T00:00:00.000Z';
  if (
    datasource.startDateTime.jsonValue.value === defaultDate ||
    datasource.endDateTime.jsonValue.value === defaultDate
  ) {
    return <></>;
  }

  const today = startOfToday();
  const twoDaysAgo = subDays(today, 2);
  const bothDatesPast = isBefore(startDateLocal, twoDaysAgo) && isBefore(endDateLocal, twoDaysAgo);
  if (bothDatesPast) {
    return <></>;
  }

  const isSameMonthResult = isSameMonth(startDateLocal, endDateLocal);
  const isSameDayResult = isSameDay(startDateLocal, endDateLocal);
  const isSameYearResult = startDateLocal.getFullYear() === endDateLocal.getFullYear();
  // Determine the date formatting based on the conditions
  let startdatetimeFormat = 'MMMM d';
  let enddatetimeFormat = '';

  if (locale() === 'es-ES') {
    // Spanish locale formatting
    startdatetimeFormat = "d 'de' MMMM 'de'";
    if (isSameDayResult) {
      enddatetimeFormat = 'yyyy'; // Only year for the same day
    } else if (isSameMonthResult) {
      startdatetimeFormat = 'd';
      enddatetimeFormat = "d 'de' MMMM 'de' yyyy"; // Only year for the same month
    } else if (!isSameYearResult) {
      startdatetimeFormat += ' yyyy'; // Full date for different years
      enddatetimeFormat = "d 'de' MMMM 'de' yyyy"; // Full date for different years
    } else {
      enddatetimeFormat = "d 'de' MMMM 'de' yyyy"; // Full date for same year
    }
  } else {
    // Default formatting for other locales
    if (isSameDayResult) {
      enddatetimeFormat = 'yyyy';
    } else if (isSameMonthResult) {
      enddatetimeFormat = 'd, yyyy';
    } else if (!isSameYearResult) {
      startdatetimeFormat = 'MMMM d, yyyy';
      enddatetimeFormat = 'MMMM d, yyyy';
    } else {
      enddatetimeFormat = 'MMMM d, yyyy';
    }
  }

  // Format the dates
  let formattedStartDate = format(startDateLocal, startdatetimeFormat, {
    locale: getLocale(locale()),
  });
  let formattedEndDate = format(endDateLocal, enddatetimeFormat, { locale: getLocale(locale()) });

  // Capitalize the first letter of the month for Spanish locale
  if (locale() === 'es-ES') {
    const startMonthIndex = formattedStartDate.indexOf('de') + 3; // Find the index after 'de '
    const startMonth = formattedStartDate.slice(startMonthIndex, startMonthIndex + 1).toUpperCase(); // Capitalize the first letter of the month
    formattedStartDate =
      formattedStartDate.slice(0, startMonthIndex) +
      startMonth +
      formattedStartDate.slice(startMonthIndex + 1); // Replace the month in the final string

    const endMonthIndex = formattedEndDate.indexOf('de') + 3; // Find the index after 'de '
    const endMonth = formattedEndDate.slice(endMonthIndex, endMonthIndex + 1).toUpperCase(); // Capitalize the first letter of the month
    formattedEndDate =
      formattedEndDate.slice(0, endMonthIndex) +
      endMonth +
      formattedEndDate.slice(endMonthIndex + 1); // Replace the month in the final string
  }
  return (
    <div className="dateTimeRangeLabel body2">
      <DateField
        tag="span"
        field={datasource.startDateTime.jsonValue}
        render={(date) => <>{date ? <>{formattedStartDate}</> : <></>}</>}
      />
      <DateField
        tag="span"
        field={datasource.endDateTime.jsonValue}
        render={(date) => (
          <>
            {date ? (
              <>
                {isSameDayResult ? (
                  locale() === 'es-ES' ? (
                    <>&nbsp;</>
                  ) : (
                    <>,&nbsp;</>
                  )
                ) : !isSameMonthResult ? (
                  <>&nbsp;-&nbsp;</>
                ) : (
                  <>&nbsp;-&nbsp;</>
                )}
                {formattedEndDate}&nbsp;
              </>
            ) : (
              <></>
            )}
          </>
        )}
      />
    </div>
  );
};
