import React, { useState } from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { Tertiary } from 'components/link/Link';
import { TimageFields } from 'components/timage/Timage';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import sitecoreStyles from 'src/helpers/sitecoreStyles';

type TimageProps = {
  params: { [key: string]: string; styles: string };
  fields: TimageFields;
};

export const TextBlockOnly = (props: TimageProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const [editingMode] = useState(isEditorActive());

  return (
    <div className="content-box" id={id ? id : undefined}>
      {editingMode && (
        <div className="row">
          <div className="col-12">
            <div className="s-editing-mode-hint">
              <strong>HINT:</strong> Requirement for the Title in this component is to
              <strong> NOT USE H1 AND H2 TAGS</strong> due to translations issue.
            </div>
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-12 col-lg-12 rich-text">
          <Text
            tag={props.params['TitleTag'] || 'h3'}
            field={props.fields.Title}
            className={`${sitecoreStyles(props.params?.styles)}`}
          ></Text>

          <RichText
            tag="div"
            className={`content-box__description body2 rich-text ${sitecoreStyles(
              props.params?.styles
            )}`}
            field={props.fields.Text}
          />
          {(props.fields.Link?.value?.href || editingMode) && (
            <div className="content-box__link">
              <Tertiary fields={{ Link: props.fields.Link }} params={{}}></Tertiary>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
