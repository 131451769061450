import React, { useState } from 'react';
import {
  Field,
  RichText,
  Text,
  ImageField,
  LinkField,
  ComponentParams,
  ComponentRendering,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { Tertiary } from 'components/link/Link';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import { TagFields } from 'src/types/Tag';
export interface HeroFields {
  title: {
    jsonValue: Field<string>;
  };
  body?: {
    jsonValue: Field<string>;
  };
  link: {
    jsonValue: LinkField;
  };
  image?: {
    jsonValue: ImageField;
  };
  contentLabel?: {
    targetItem: TagFields;
  };
  hideContentLabel?: {
    jsonValue: Field<boolean>;
  };
}

export type HeroProps = {
  rendering: ComponentRendering & { params: ComponentParams };
  params: ComponentParams;
  fields?: {
    data: {
      datasource?: HeroFields;
    };
  };
  children?: React.ReactNode[];
};

export const DefaultHeroTitle = (): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  return (
    <React.Fragment>
      {editingMode && (
        <div className={`hero component--with-paddings`}>
          <span className="is-empty-hint">Hero Title</span>
        </div>
      )}
    </React.Fragment>
  );
};

export const HeroTitle = (props: HeroProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  if (props.fields) {
    return (
      <div className={`hero component--with-paddings `} id={id ? id : undefined}>
        <div className="hero__container">
          <Text
            tag="h1"
            className={`hero__title ${sitecoreStyles(props.params.styles)}`}
            field={props.fields?.data.datasource?.title?.jsonValue}
          />
          <RichText
            tag="div"
            className="body2 hero__text"
            field={props.fields?.data.datasource?.body?.jsonValue}
          />
          {props.fields?.data.datasource?.link?.jsonValue && (
            <Tertiary
              fields={{ Link: props.fields?.data.datasource?.link?.jsonValue }}
              params={{}}
            ></Tertiary>
          )}
        </div>
      </div>
    );
  }
  return <DefaultHeroTitle />;
};
