import React, { useState } from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { BannerFields } from 'components/banner/Banner';
import NextImageWithNullFallback from 'components/shared/nextImg/NextImageWithNullFallback';
import { StatementWave } from 'components/logo-lines/LogoLines';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import sitecoreStyles from 'src/helpers/sitecoreStyles';

type BannerProps = {
  params: { [key: string]: string; styles: string };
  fields?: {
    data: {
      datasource?: BannerFields;
    };
  };
};

const StatementDefaultComponent = (props: BannerProps): JSX.Element => (
  <div className={`component ${sitecoreStyles(props.params?.styles)}`}>
    <div className="component-content">Empty</div>
  </div>
);

export const StatementBanner = (props: BannerProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const [editingMode] = useState(isEditorActive());
  const titleTag = props.params['TitleTag'] || 'h2';

  if (props.fields) {
    return (
      <div className={`statement ${sitecoreStyles(props.params?.styles)}`} id={id ? id : undefined}>
        <div className="statement__container">
          <div className="statement__text-container">
            <Text
              tag={titleTag}
              className="statement__title"
              field={props.fields.data.datasource?.title?.jsonValue}
            />
            <RichText
              tag="div"
              className="body2 statement__text"
              field={props.fields.data.datasource?.description?.jsonValue}
            />
          </div>
        </div>
        <div className="statement__background-image">
          <NextImageWithNullFallback
            field={props.fields.data.datasource?.backgroundImage?.jsonValue}
            alt={props.fields.data.datasource?.backgroundImage?.jsonValue?.value?.src}
            width={1728}
            height={740}
            params={{
              styles: 'statement__img',
            }}
          ></NextImageWithNullFallback>
        </div>
        {!editingMode && <StatementWave />}
      </div>
    );
  }
  return <StatementDefaultComponent {...props} />;
};
