import {
  BlobServiceClient,
  ContainerClient,
  StorageSharedKeyCredential,
} from '@azure/storage-blob';

import { ICacheProvider } from 'lib/cache/cacheProvider';

class BlobCacheProvider implements ICacheProvider {
  private readonly containerClient?: ContainerClient;

  constructor() {
    const accountName = process.env.AZURE_STORAGE_ACCOUNT_NAME;
    const accountKey = process.env.AZURE_STORAGE_ACCOUNT_KEY;
    const containerName = process.env.AZURE_STORAGE_CONTAINER_NAME || 'cache';

    if (accountName && accountKey && containerName) {
      // Create shared key credential
      const credential = new StorageSharedKeyCredential(accountName, accountKey);

      // Create the blob service client using the account name and credential
      const blobServiceClient = new BlobServiceClient(
        `https://${accountName}.blob.core.windows.net`,
        credential
      );

      this.containerClient = blobServiceClient.getContainerClient(containerName);

      // Create the container if it doesn't exist
      this.createContainerIfNotExists().catch((err) => {
        console.error('Error creating Azure Blob container:', err);
      });
    } else {
      // console.error('Azure Storage account name, account key, or container name is not defined.');
    }
  }

  private async createContainerIfNotExists(): Promise<void> {
    if (this.containerClient) {
      const exists = await this.containerClient.exists();
      if (!exists) {
        await this.containerClient.create();
        console.log(`Container "${this.containerClient.containerName}" created.`);
      } else {
        console.log(`Container "${this.containerClient.containerName}" already exists.`);
      }
    }
  }

  private getContainerClient(): ContainerClient | undefined {
    if (!this.containerClient) {
      console.error('Azure Blob Container client is not initialized.');
    }
    return this.containerClient;
  }

  private encodeCacheKey(key: string): string {
    const cleanKey = key.replace('https://', '').replace('http://', '');
    return Buffer.from(cleanKey)
      .toString('base64')
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  }

  async get<T>(key: string): Promise<T | null> {
    const startTime = performance.now();
    const containerClient = this.getContainerClient();
    if (containerClient) {
      const encodedKey = this.encodeCacheKey(key);
      const blobClient = containerClient.getBlobClient(`${encodedKey}.json`);
      if (await blobClient.exists()) {
        const properties = await blobClient.getProperties();
        const expirationTimeStr = properties.metadata?.expirationtime;

        if (expirationTimeStr) {
          const expirationTime = new Date(expirationTimeStr);
          const nowUtc = new Date(new Date().toISOString()); // Ensuring now is in UTC
          if (nowUtc > expirationTime) {
            // Cache expired, delete the blob
            await blobClient.delete();
            return null;
          }
        }

        const downloadBlockBlobResponse = await blobClient.download();
        const downloaded = await this.streamToString(
          downloadBlockBlobResponse.readableStreamBody || null
        );
        const result = JSON.parse(downloaded) as T;
        const endTime = performance.now();
        const timeDiff = endTime - startTime; // Time in milliseconds

        console.log(`---------> BLOB CACHE Execution time: ${timeDiff} ms`);

        return result;
      }
    }
    return null;
  }

  async set<T>(key: string, value: T, ttl?: number): Promise<void> {
    const containerClient = this.getContainerClient();
    if (containerClient) {
      const encodedKey = this.encodeCacheKey(key);
      const blobClient = containerClient.getBlockBlobClient(`${encodedKey}.json`);
      const content = JSON.stringify(value);
      const metadata: { [key: string]: string } = {};

      if (ttl) {
        const nowUtc = new Date(new Date().toISOString()); // Current time in UTC
        const expirationTime = new Date(nowUtc.getTime() + ttl * 1000);
        metadata.expirationTime = expirationTime.toISOString();
      }

      await blobClient.upload(content, Buffer.byteLength(content), { metadata });
    }
  }

  async delete(key: string): Promise<number | null> {
    const containerClient = this.getContainerClient();
    if (containerClient) {
      const encodedKey = this.encodeCacheKey(key);
      const blobClient = containerClient.getBlobClient(`${encodedKey}.json`);
      if (await blobClient.exists()) {
        await blobClient.delete();
        return 1;
      }
    }
    return null;
  }

  async exists(key: string): Promise<boolean> {
    const containerClient = this.getContainerClient();
    if (containerClient) {
      const encodedKey = this.encodeCacheKey(key);
      const blobClient = containerClient.getBlobClient(`${encodedKey}.json`);
      return await blobClient.exists();
    }
    return false;
  }

  disconnect(): void {
    // Azure Blob Storage does not maintain persistent connections
  }

  private async streamToString(readableStream: NodeJS.ReadableStream | null): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!readableStream) {
        resolve('');
        return;
      }
      const chunks: string[] = [];
      readableStream.on('data', (data: Buffer | string) => {
        chunks.push(data.toString());
      });
      readableStream.on('end', () => {
        resolve(chunks.join(''));
      });
      readableStream.on('error', reject);
    });
  }
}

export const azureBlobCache = new BlobCacheProvider();
