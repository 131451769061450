import React from 'react';
import {
  FullBleedBannerWithVideo,
  BannerProps,
} from 'components/banner/variants/full-bleed/FullBleedBannerWithVideo';

export const FullBleedBannerWithVideoGrayTitle = (props: BannerProps): JSX.Element => {
  props.params['TitleTag'] = props.params['TitleTag'] || 'h3';
  return (
    <div className="full-bleed-banner-gray-title">
      <FullBleedBannerWithVideo {...props} />
    </div>
  );
};
