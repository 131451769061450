import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { PlaceholderComponentProps } from '@sitecore-jss/sitecore-jss-react';

interface ChildrenOrPlaceholderProps {
  children?: React.ReactNode;
}

export const ChildrenOrPlaceholder = (
  props: ChildrenOrPlaceholderProps & PlaceholderComponentProps
): JSX.Element => {
  if (props.children) {
    return <React.Fragment>{props.children}</React.Fragment>;
  }
  return <Placeholder {...props} />;
};
