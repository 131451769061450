import React, { useEffect, useState } from 'react';
import {
  ComponentParams,
  ComponentRendering,
  Field,
  Text,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import dynamic from 'next/dynamic';
import config from 'temp/config';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

export interface MetricsBlockFields {
  Title: Field<string>;
}

export interface MetricBlocksProps {
  rendering: ComponentRendering & { params: ComponentParams };
  params: ComponentParams;
  children?: React.ReactNode[];
  fields?: MetricsBlockFields;
}

const editingmode = isEditorActive();
let DynamicChildrenOrPlaceholder;

if (editingmode) {
  DynamicChildrenOrPlaceholder =
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    require('components/shared/children-or-placeholder/ChildrenOrPlaceholder').ChildrenOrPlaceholder;
} else {
  // Dynamic import when not in editing mode
  DynamicChildrenOrPlaceholder = dynamic(
    () =>
      import('components/shared/children-or-placeholder/ChildrenOrPlaceholder').then(
        (mod) => mod.ChildrenOrPlaceholder
      ),
    { ssr: !!config.dynamicImportSsr }
  );
}

export const Default = (props: MetricBlocksProps) => {
  console.log('---->[MetricsBlock] Component', props);
  const id = props.params.RenderingIdentifier;
  const tag = props.params['TitleTag'] || 'h2';
  const sitecoreContext = useSitecoreContext();

  return (
    <div
      className={`metrics-block component--with-paddings ${sitecoreStyles(props.params?.styles)}`}
      id={id ? id : undefined}
    >
      {props.fields?.Title && (
        <Text tag={tag} field={props.fields.Title} className="metrics-block__title" />
      )}
      <div className="metrics-block__container container">
        <div className="metrics-block__metric">
          <DynamicChildrenOrPlaceholder
            name={`metrics-block-1`}
            rendering={props.rendering}
            sitecoreContext={sitecoreContext}
          >
            {props.children ? props.children[0] : null}
          </DynamicChildrenOrPlaceholder>
        </div>
        <div className="metrics-block__metric">
          <DynamicChildrenOrPlaceholder
            name={`metrics-block-2`}
            rendering={props.rendering}
            sitecoreContext={sitecoreContext}
          >
            {props.children ? props.children[1] : null}
          </DynamicChildrenOrPlaceholder>
        </div>
        <div className="metrics-block__metric">
          <DynamicChildrenOrPlaceholder
            name={`metrics-block-3`}
            rendering={props.rendering}
            sitecoreContext={sitecoreContext}
          >
            {props.children ? props.children[2] : null}
          </DynamicChildrenOrPlaceholder>
        </div>
        <div className="metrics-block__metric">
          <DynamicChildrenOrPlaceholder
            name={`metrics-block-4`}
            rendering={props.rendering}
            sitecoreContext={sitecoreContext}
          >
            {props.children ? props.children[3] : null}
          </DynamicChildrenOrPlaceholder>
        </div>
      </div>
    </div>
  );
};

export const Featured = (props: MetricBlocksProps) => {
  console.log('---->[MetricsBlock] Component', props);
  const id = props.params.RenderingIdentifier;
  const tag = props.params['TitleTag'] || 'h2';
  const [childCount, setChildCount] = useState(0);
  const sitecoreContext = useSitecoreContext();

  useEffect(() => {
    const placeholders = props?.rendering?.placeholders || {};

    const placeholderKeys = Object.keys(placeholders);
    setChildCount(placeholderKeys.length);
  }, [props?.rendering?.placeholders]);

  const hasFourChildren = childCount >= 4;

  const metricBlockFeatured = hasFourChildren
    ? 'metrics-block--featured__metric'
    : 'metrics-block--featured__metric metrics-block--featured__metric--grid-span-two-column';

  return (
    <div
      className={`metrics-block--featured component--with-paddings ${sitecoreStyles(
        props.params?.styles
      )}`}
      id={id || undefined}
    >
      <Text tag={tag} field={props.fields?.Title} className="metrics-block--featured__title" />
      <div className="metrics-block--featured__container container">
        <div className="metrics-block--featured__metric">
          <DynamicChildrenOrPlaceholder
            name={`metrics-block-1`}
            rendering={props.rendering}
            sitecoreContext={sitecoreContext}
          >
            {props.children ? props.children[0] : null}
          </DynamicChildrenOrPlaceholder>
        </div>
        <div className="metrics-block--featured__metric">
          <DynamicChildrenOrPlaceholder
            name={`metrics-block-2`}
            rendering={props.rendering}
            sitecoreContext={sitecoreContext}
          >
            {props.children ? props.children[1] : null}
          </DynamicChildrenOrPlaceholder>
        </div>
        <div className={metricBlockFeatured}>
          <DynamicChildrenOrPlaceholder
            name={`metrics-block-3`}
            rendering={props.rendering}
            sitecoreContext={sitecoreContext}
          >
            {props.children ? props.children[2] : null}
          </DynamicChildrenOrPlaceholder>
        </div>
        {hasFourChildren && (
          <div className="metrics-block--featured__metric">
            <DynamicChildrenOrPlaceholder
              name={`metrics-block-4`}
              rendering={props.rendering}
              sitecoreContext={sitecoreContext}
            >
              {props.children && props?.children[3]}
            </DynamicChildrenOrPlaceholder>
          </div>
        )}
      </div>
    </div>
  );
};
