import { SearchEngine, buildQuerySummary } from '@coveo/atomic-react';
import { useState, useEffect } from 'react';

export const CustomQuerySummary = ({ engine }: { engine: SearchEngine }): JSX.Element => {
  const [headlessQuerySummary] = useState(buildQuerySummary(engine));
  const [querySummary, setQuerySummary] = useState(headlessQuerySummary.state);

  useEffect(() => {
    headlessQuerySummary.subscribe(() => {
      setQuerySummary(headlessQuerySummary.state);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log(querySummary);
  return (
    <div className="custom-query-summary">
      <div className="h6">
        Showing {querySummary.lastResult} results out of {querySummary.total}
      </div>
    </div>
  );
};
