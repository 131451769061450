import React, { useState } from 'react';
import { ReferencableItemFields } from 'src/types/Referencable';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import { DateTimeRangeFields } from 'components/date-time/DateTime';
import { PostedDateFields } from 'components/posted-date/PostedDate';
import { TaggableFields } from '../../types/Taggable';
// import dynamic from 'next/dynamic';
// import config from 'temp/config';
import { TopFiveCard } from './TopFiveCard';
import { TopFiveDateTimeCard } from './TopFiveCard';
import { TopFiveNewsRoomCard } from './TopFiveCard';
export interface TopFiveFields {
  content: {
    targetItems: (ReferencableItemFields | TaggableFields | DateTimeRangeFields)[];
  };
}

export interface TopFiveNewsRoomFields {
  content?: {
    targetItems?: (ReferencableItemFields | PostedDateFields)[];
  };
}

export type TopFiveProps = {
  params: { [key: string]: string; styles: string };
  fields?: {
    data: {
      datasource: TopFiveFields;
    };
  };
};

export type TopFiveNewsRoomProps = {
  params: { [key: string]: string; styles: string };
  fields?: {
    data: {
      datasource: TopFiveNewsRoomFields;
    };
  };
};

export const Default = (props: TopFiveProps): JSX.Element => {
  const datasource = props.fields?.data?.datasource;

  console.log('---->[TopFive] Component', datasource);

  const [editingMode] = useState(isEditorActive());

  const targetItems = datasource?.content?.targetItems || [];
  const hasTargetItems = targetItems.length > 0;

  const firstItem = hasTargetItems ? targetItems[0] : null;
  const otherItems = hasTargetItems ? targetItems.slice(1, 5) : [];

  // const DynamicTopFiveCard = dynamic(() => import('./TopFiveCard').then((mod) => mod.TopFiveCard), {
  //   ssr: !!config.dynamicImportSsr,
  // });

  return (
    <div className={`top-five component--with-paddings ${sitecoreStyles(props.params?.styles)}`}>
      <div className="row">
        {editingMode && targetItems.length === 0 && <h2>Component: Top Five</h2>}
        {firstItem && (
          <div key={firstItem.id} className="col-12 col-md-6 col-lg-8 left-container">
            {/* <DynamicTopFiveCard {...firstItem} isFirstItem={true} /> */}
            <TopFiveCard {...firstItem} isFirstItem={true} />
          </div>
        )}
        <div className="col-12 col-md-6 col-lg-4 right-container">
          {otherItems.map((item) => (
            // <DynamicTopFiveCard key={item.id} {...item} />
            <TopFiveCard key={item.id} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export const TopFiveDateTime = (props: TopFiveProps): JSX.Element => {
  const datasource = props.fields?.data?.datasource;

  console.log('---->[TopFiveDateTime] Component', datasource);

  const [editingMode] = useState(isEditorActive());

  const targetItems = datasource?.content?.targetItems || [];
  const hasTargetItems = targetItems.length > 0;

  const firstItem = hasTargetItems ? targetItems[0] : null;
  const otherItems = hasTargetItems ? targetItems.slice(1, 5) : [];

  // const DynamicTopFiveDateTimeCard = dynamic(
  //   () => import('./TopFiveCard').then((mod) => mod.TopFiveDateTimeCard),
  //   { ssr: !!config.dynamicImportSsr }
  // );
  return (
    <div className={`top-five component--with-paddings ${sitecoreStyles(props.params?.styles)}`}>
      <div className="row">
        {editingMode && targetItems.length === 0 && <h2>Component: Top Five</h2>}
        {firstItem && (
          <div key={firstItem.id} className="col-12 col-md-6 col-lg-8 left-container">
            <TopFiveDateTimeCard {...firstItem} isFirstItem={true} />
          </div>
        )}
        <div className="col-12 col-md-6 col-lg-4 right-container">
          {otherItems.map((item) => (
            <TopFiveDateTimeCard key={item.id} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export const TopFiveNewsRoom = (props: TopFiveNewsRoomProps): JSX.Element => {
  const datasource = props.fields?.data?.datasource;

  console.log('---->[TopFiveNewsRoom] Component', datasource);

  const [editingMode] = useState(isEditorActive());

  const targetItems = datasource?.content?.targetItems || [];
  const hasTargetItems = targetItems.length > 0;

  const firstItem = hasTargetItems ? targetItems[0] : null;
  const otherItems = hasTargetItems ? targetItems.slice(1) : [];
  // const DynamicTopFiveNewsRoomCard = dynamic(
  //   () => import('./TopFiveCard').then((mod) => mod.TopFiveNewsRoomCard),
  //   { ssr: !!config.dynamicImportSsr }
  // );
  return (
    <div className={`top-five component--with-paddings ${sitecoreStyles(props.params?.styles)}`}>
      <div className="row">
        {editingMode && targetItems.length === 0 && <h2>Component: Top Five</h2>}
        {firstItem && (
          <div key={firstItem.id} className="col-12 col-md-6 col-lg-8 left-container">
            <TopFiveNewsRoomCard {...firstItem} isFirstItem={true} />
          </div>
        )}
        <div className="col-12 col-md-6 col-lg-4 right-container">
          {otherItems.map((item) => (
            <TopFiveNewsRoomCard key={item.id} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export const TopFiveDefaultComponent = (props: TopFiveProps): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  return (
    <React.Fragment>
      {editingMode && (
        <div className={`top-five ${sitecoreStyles(props.params?.styles)}`}>
          <span className="is-empty-hint">Top Five</span>
        </div>
      )}
    </React.Fragment>
  );
};
