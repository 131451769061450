import React, { useState } from 'react';
import { SmallCard } from 'components/carousel/partials/carousel-card/CarouselCard';
import {
  CarouselCard,
  Default as CarouselContent,
} from 'components/carousel/partials/carousel-content/CarouselContent';
import {
  ImageField,
  ComponentParams,
  ComponentRendering,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ChildrenOrPlaceholder } from 'components/shared/children-or-placeholder/ChildrenOrPlaceholder';
import NextImageWithNullFallback from 'components/shared/nextImg/NextImageWithNullFallback';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

interface Fields {
  cardsData?: CarouselCard[];
  backgroundImg: ImageField;
}

//component properties
type FullBleedVideoCarouselProps = {
  rendering: ComponentRendering & { params: ComponentParams };
  params: { [key: string]: string; styles: string };
  fields: Fields;
  children?: React.ReactNode[];
};

//empty variant?
const FullBleedVideoCarouselDefaultComponent = (
  props: FullBleedVideoCarouselProps
): JSX.Element => (
  <div className={`component ${sitecoreStyles(props.params?.styles)}`}>
    <div className="component-content">Empty</div>
  </div>
);

//default variant
export const Default = (props: FullBleedVideoCarouselProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const cards = props.fields.cardsData;
  const [nextIndex, setNextIndex] = useState(1);
  const [editingMode] = useState(isEditorActive());
  const sitecoreContext = useSitecoreContext();

  if (!cards) {
    return <></>;
  }

  const updateNextIndex = (data: number) => {
    if (data + 1 === cards.length) {
      setNextIndex(0);
    } else {
      setNextIndex(data + 1);
    }
  };

  if (props.fields) {
    return (
      <div
        className={`full-bleed-video ${sitecoreStyles(props.params?.styles)}`}
        id={id ? id : undefined}
      >
        <div
          className={`full-bleed-video__background-container ${
            editingMode ? 'full-bleed-video__background-container--edit' : ''
          }`}
        >
          <NextImageWithNullFallback
            field={props.fields.backgroundImg}
            width={1728}
            height={740}
            params={{
              styles: 'full-bleed-video__background',
            }}
          ></NextImageWithNullFallback>
        </div>
        <div
          className={`full-bleed-video__carousel ${
            editingMode ? 'full-bleed-video__carousel--edit' : ''
          }`}
        >
          <CarouselContent
            slides={cards}
            options={{ loop: true, watchDrag: false }}
            updateNextSlide={updateNextIndex}
          />
        </div>

        <div className={'full-bleed-video__upnext'}>
          <SmallCard
            params={{ name: 'link__button-primary' }}
            fields={{
              area: { value: 'Up Next' },
              title: {
                value: cards[nextIndex]?.title?.value,
              },
            }}
          />
        </div>

        {props.children && (
          <div className={'full-bleed-video__video'}>
            <ChildrenOrPlaceholder
              name={`carousal-video-1`}
              rendering={props.rendering}
              sitecoreContext={sitecoreContext}
            >
              {props.children ? props.children[0] : null}
            </ChildrenOrPlaceholder>
          </div>
        )}
      </div>
    );
  }
  return <FullBleedVideoCarouselDefaultComponent {...props} />;
};
