import React from 'react';

type DividerProps = {
  params: { [key: string]: string };
};

export const Default = (props: DividerProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const rulerStyles = props.params && props.params.Styles ? props.params.Styles : '';
  const styles = `${rulerStyles}`.trimEnd();
  return (
    <div id={id ? id : undefined} className={`divider component--with-paddings ${styles}`.trim()}>
      <hr />
    </div>
  );
};
