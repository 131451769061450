import { TitleBanner } from 'components/banner/variants/title-banner/TitleBanner';
import { UnPlugWave } from 'components/logo-lines/LogoLines';
import Head from 'next/head';

/**
 * Rendered in case if we have 404 error
 */
const NotFound = (): JSX.Element => (
  <div className="not-found">
    <section>
      <div className="container">
        <Head>
          <title>404: NotFound</title>
        </Head>
        <TitleBanner
          fields={{
            data: {
              datasource: {
                title: {
                  jsonValue: {
                    value: 'Oops something went wrong',
                  },
                },
                subTitle: {
                  jsonValue: {
                    value:
                      'Quisque quis metus eu ligula vehicula gravida. Maecenas auctor elit ut ex eleifend eleifend. Integer scelerisque sapien in ultrices maximus. Nunc rutrum sapien nec nisi pretium ultrices.',
                  },
                },
                description: {
                  jsonValue: {
                    value: 'This page does not exist.',
                  },
                },
                link: {
                  jsonValue: {
                    value: {
                      href: '/',
                      text: 'Homepage',
                    },
                  },
                },
                backgroundImage: {
                  jsonValue: {
                    value: {},
                  },
                },
              },
            },
          }}
          params={{ styles: '' }}
        ></TitleBanner>
      </div>
    </section>
    <section>
      <div className="container-fluid">
        <UnPlugWave />
      </div>
    </section>
  </div>
);

export default NotFound;
