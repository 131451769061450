import React from 'react';
import { Link as JssLink, Text, LinkField, TextField } from '@sitecore-jss/sitecore-jss-nextjs';
import sitecoreStyles from 'src/helpers/sitecoreStyles';

type ResultsFieldLink = {
  field: {
    link: LinkField;
  };
};

interface Fields {
  data: {
    datasource: {
      children: {
        results: ResultsFieldLink[];
      };
      field: {
        title: TextField;
      };
    };
  };
}

type LinkListProps = {
  params: { [key: string]: string };
  fields?: Fields;
};

type LinkListItemProps = {
  key: string;
  index: number;
  total: number;
  field: LinkField;
};

export const Default = (props: LinkListProps): JSX.Element => {
  console.log('---->[LinkList] Component', props.fields);
  const datasource = props.fields?.data?.datasource;
  const styles = `link-list ${sitecoreStyles(props.params.styles)}`.trimEnd();
  const id = props.params.RenderingIdentifier;

  if (datasource) {
    const list = datasource.children.results
      .filter((element: ResultsFieldLink) => element?.field?.link)
      .map((element: ResultsFieldLink, key: number) => (
        <LinkListItem
          index={key}
          key={`${key}${element.field.link}`}
          total={datasource.children.results.length}
          field={element.field.link}
        />
      ));

    return (
      <div className={styles} id={id ? id : undefined}>
        <Text tag="h3" field={datasource?.field?.title} />
        <ul>{list}</ul>
      </div>
    );
  }

  return (
    <div className={styles} id={id ? id : undefined}>
      <div className="component-content">
        <h3>Link List</h3>
      </div>
    </div>
  );
};

export const LinkListItem = (props: LinkListItemProps) => {
  let className = `item${props.index}`;
  className += (props.index + 1) % 2 == 0 ? ' even' : ' odd';
  if (props.index == 0) {
    className += ' first';
  }
  if (props.index + 1 == props.total) {
    className += ' last';
  }
  return (
    <li className={className}>
      <div className="field-link">
        <JssLink
          field={props.field}
          aria-label={props.field.value.text}
          className="link footer_link"
          data-analytics-link-name={props.field.value.text}
          data-analytics-link-type="Navigation"
          data-analytics-content-class="content"
          data-analytics-template-zone="Footer"
          data-analytics-component-name="Footer links"
          data-analytics-component-variation="Footer links"
          data-analytics-target={props.field.value?.href}
          data-analytics-component-section="Footer"
          data-analytics-slide-number="NAN"
          data-analytics-ispersonalized="False"
          data-analytics-iscarousal="False"
        />
      </div>
    </li>
  );
};
