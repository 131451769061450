import { createContext } from 'react';

export type DoubleAccordionState = {
  currentlyOpenAccordion: string[];
};

type DoubleAccordionContext = {
  doubleAccordionState: DoubleAccordionState;
  setDoubleAccordionState: (doubleAccordionState: DoubleAccordionState) => void;
};

const DoubleAccordionContext = createContext<DoubleAccordionContext>({
  doubleAccordionState: {
    currentlyOpenAccordion: [''],
  },
  setDoubleAccordionState: function (): void {
    throw new Error('Function not implemented.');
  },
});

export { DoubleAccordionContext };
