import {
  Field,
  WithSitecoreContextProps,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useState } from 'react';
import { SearchEngineContext } from '../search-context/SearchContext';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { loadAdvancedSearchQueryActions, loadPaginationActions } from '@coveo/headless';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import { resolveCareerLangId } from '../../../lib/utils';

interface SearchFilterFields {
  cq?: Field<string>;
  aq?: Field<string>;
  dq?: Field<string>;
  lq?: Field<string>;
}

type SearchFilterProps = {
  params: { [key: string]: string };
  fields: SearchFilterFields;
} & WithSitecoreContextProps;

const Default = (props: SearchFilterProps): JSX.Element => {
  console.log('---->[SearchFilter] Component', props.fields);

  if (props.fields?.cq?.value?.includes('ActiveJobs')) {
    const langCode = resolveCareerLangId(props.sitecoreContext?.language?.toLowerCase());
    props.fields.cq.value = props.fields.cq.value.replace('{langid}', langCode);
  }

  const searchScope = props.params.SearchScope;
  const { getSearchEngine } = React.useContext(SearchEngineContext);
  const searchEngine = getSearchEngine(searchScope);

  const [editingMode] = useState(isEditorActive());
  //need a property here Alex plz help!

  const paginationCreator = loadPaginationActions(searchEngine);
  const queryCreators = loadAdvancedSearchQueryActions(searchEngine);
  if (
    props.fields?.aq?.value ||
    props.fields?.cq?.value ||
    props.fields?.dq?.value ||
    props.fields?.lq?.value
  ) {
    const advancedQuery = queryCreators.registerAdvancedSearchQueries({
      cq: props.fields?.cq?.value,
      aq: props.fields?.aq?.value,
      dq: props.fields?.dq?.value,
      lq: props.fields?.lq?.value,
    });
    searchEngine.dispatch(advancedQuery);
    const hasQueryOrFilter: boolean =
      window.location.href.includes('#q=') || window.location.href.includes('#f-');
    if (searchScope == 'News room') {
      searchEngine.dispatch(paginationCreator.registerNumberOfResults(16));
    } else if (searchScope == 'Jobs') {
      searchEngine.dispatch(paginationCreator.registerNumberOfResults(12));
    } else if (
      searchScope == 'Insights' ||
      searchScope == 'ClientStories' ||
      searchScope == 'Events'
    ) {
      searchEngine.dispatch(paginationCreator.registerNumberOfResults(hasQueryOrFilter ? 16 : 17));
    } else {
      searchEngine.dispatch(paginationCreator.registerNumberOfResults(17));
    }
  }
  return (
    <>
      {editingMode && (
        <div className={`is-metadata-hint ${sitecoreStyles(props.params?.styles)}`}>
          <strong>Search static filter</strong>
          <div>
            {props.fields?.aq?.value && (
              <pre>
                <strong>aq: </strong>
                {props.fields?.aq?.value}
              </pre>
            )}
            {props.fields?.cq?.value && (
              <pre>
                <strong>cq: </strong>
                {props.fields?.cq?.value}
              </pre>
            )}
            {props.fields?.dq?.value && (
              <pre>
                <strong>dq: </strong>
                {props.fields?.dq?.value}
              </pre>
            )}
            {props.fields?.lq?.value && (
              <pre>
                <strong>lq: </strong>
                {props.fields?.lq?.value}
              </pre>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default withSitecoreContext()(Default);
