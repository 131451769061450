import React from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { ReferencableItemFields } from 'src/types/Referencable';
import { useI18n } from 'next-localization';
import { IconName } from 'src/types/Enum';
import dynamic from 'next/dynamic';
import config from 'temp/config';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

export const ContentGalleryCard = (props: ReferencableItemFields): JSX.Element => {
  const { t } = useI18n();
  const editingmode = isEditorActive();

  // For DynamicNextImageWithNullFallback component
  let DynamicNextImageWithNullFallback;
  if (editingmode) {
    // Static import when in editing mode

    DynamicNextImageWithNullFallback =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/shared/nextImg/NextImageWithNullFallback').NextImageWithNullFallback;
  } else {
    // Dynamic import when not in editing mode
    DynamicNextImageWithNullFallback = dynamic(
      () =>
        import('components/shared/nextImg/NextImageWithNullFallback').then(
          (mod) => mod.NextImageWithNullFallback
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }

  // For DynamicIcon component
  let DynamicIcon;
  if (editingmode) {
    // Static import when in editing mode
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicIcon = require('components/shared/icon/Icon').Icon;
  } else {
    // Dynamic import when not in editing mode
    DynamicIcon = dynamic(() => import('components/shared/icon/Icon').then((mod) => mod.Icon), {
      ssr: !!config.dynamicImportSsr,
    });
  }
  return (
    <div className={`content-gallery-card `}>
      <div className="content-gallery-card__main-container">
        <DynamicNextImageWithNullFallback
          field={props.referenceImage?.jsonValue}
          width={264}
          height={264}
          params={{
            styles: 'content-gallery-card__image next-image--square',
          }}
        />

        <Text
          tag="div"
          className="body2 content-gallery-card__category"
          field={props.referenceCategory?.targetItems[0]?.title?.jsonValue}
        />
        <Text
          tag="div"
          className="body1 content-gallery-card__title"
          field={props.referenceTitle?.jsonValue}
        />
        <RichText
          tag="div"
          className="body2 content-gallery-card__description"
          field={props.referenceText?.jsonValue}
        />
      </div>

      <a
        className="ga-track link link__button-tertiary"
        href={props.url?.url}
        data-analytics-link-name={`${t('component-contentgallery-readmore')}`}
        data-analytics-link-type="Link"
        data-analytics-content-class="Content"
        data-analytics-template-zone="Body"
        data-analytics-component-name="Content Gallery Card"
        data-analytics-component-variation="NAN"
        data-analytics-target={`${props?.url?.url}`}
        data-analytics-component-section="NAN"
        data-analytics-slide-number="NAN"
        data-analytics-ispersonalized="False"
        data-analytics-iscarousal="False"
        aria-label={t('component-contentgallery-readmore')}
      >
        {t('component-contentgallery-readmore')}

        <div className="link__button-tertiary-image-container">
          <div className="link__button-tertiary-arrow">
            <DynamicIcon icon={IconName.ArrowRight} iconLabel={'Read more'} />
          </div>

          <div className="link__button-tertiary-arrow-active">
            <DynamicIcon icon={IconName.ArrowRightActive} iconLabel={'Read more'} />
          </div>
        </div>
      </a>
    </div>
  );
};
