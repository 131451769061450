import React, { useEffect, useRef, useState } from 'react';
import { Icon } from '../icon/Icon';
import { IconName } from 'src/types/Enum';

import { useI18n } from 'next-localization';

interface ModalProps {
  isOpen: boolean;
  hasCloseBtn?: boolean;
  onClose?: () => void;
  children: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  hasCloseBtn,
  onClose,
  children,
}): JSX.Element => {
  const modalRef = useRef<HTMLDialogElement | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const { t } = useI18n();

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    const modalElement = modalRef.current;
    if (modalElement) {
      if (isModalOpen) {
        modalElement.showModal();
        document.getElementsByTagName('html')[0].setAttribute('class', 'no-scroll');
        modalElement.focus();
      } else {
        document.querySelector('html')?.classList.remove('no-scroll');
        modalElement.close();
      }
    }

    return () => {
      document.querySelector('html')?.classList.remove('no-scroll');
    };
  }, [isModalOpen]);

  const handleCloseModal = () => {
    if (onClose) {
      onClose();
    }
    setIsModalOpen(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDialogElement>) => {
    if (event.key === 'Escape') {
      handleCloseModal();
    }
  };

  return (
    <dialog
      ref={modalRef}
      className={'modal'}
      onKeyDown={handleKeyDown}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="modal__content">
        {hasCloseBtn && (
          <div className={'modal__button-container'}>
            <button className="modal__close-btn" onClick={handleCloseModal}>
              <span className="body1">{t('component-modal-close')}</span>
              <Icon icon={IconName.Dismiss} iconLabel={'Close'} />
            </button>
          </div>
        )}
        {children}
      </div>
    </dialog>
  );
};

export default Modal;
