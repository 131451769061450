import React, { useState } from 'react';
import { SmallCard } from 'components/carousel/partials/carousel-card/CarouselCard';
import {
  CarouselCard,
  Default as CarouselContent,
} from 'components/carousel/partials/carousel-content/CarouselContent';
import { ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import NextImageWithNullFallback from 'components/shared/nextImg/NextImageWithNullFallback';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
// import LazyLoadComponent from 'components/shared/lazy-load/LazyLoadComponent';

interface Fields {
  cardsData?: CarouselCard[];
  backgroundImg: ImageField;
}

//component properties
type FullBleedContentCarouselProps = {
  params: { [key: string]: string; styles: string };
  fields: Fields;
};

//empty variant?
const FullBleedContentCarouselDefaultComponent = (
  props: FullBleedContentCarouselProps
): JSX.Element => (
  <div className={`component  ${sitecoreStyles(props.params?.styles)}`}>
    <div className="component-content">Empty</div>
  </div>
);

//default variant
export const Default = (props: FullBleedContentCarouselProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const cards = props.fields.cardsData;

  const [nextIndex, setNextIndex] = useState(1);
  const [editingMode] = useState(isEditorActive());

  const updateNextIndex = (data: number) => {
    if (data + 1 === cards?.length) {
      setNextIndex(0);
    } else {
      setNextIndex(data + 1);
    }
  };

  if (!cards) {
    return <></>;
  }

  if (props.fields && cards) {
    const shouldShowSmallCard = cards.length > 1;

    return (
      // <LazyLoadComponent componentId="FullBleedContent">
      <div
        className={`full-bleed-content ${sitecoreStyles(props.params?.styles)}`}
        id={id ? id : undefined}
      >
        <div
          className={`full-bleed-content__background ${
            editingMode ? 'full-bleed-content__background--edit' : ''
          }`}
        >
          <NextImageWithNullFallback
            field={props.fields?.backgroundImg}
            // width={1728}
            // height={740}
            params={{
              styles: ' full-bleed-content__background-image',
            }}
            fill
            sizes="(max-width: 1200px) 100vw, (min-width: 1201px) 60vw, 43vw"
            loading="eager"
            priority
          ></NextImageWithNullFallback>
        </div>
        <div className="full-bleed-content__carousel">
          <CarouselContent
            slides={cards}
            options={{ loop: true, watchDrag: false }}
            updateNextSlide={updateNextIndex}
          />
        </div>
        <div className={'full-bleed-content__upnext'}>
          {shouldShowSmallCard && (
            <SmallCard
              params={{ name: 'link__button-primary' }}
              fields={{
                area: { value: 'Up Next' },
                title: {
                  value: cards?.[nextIndex]?.title?.value,
                },
              }}
            />
          )}
        </div>
      </div>
      // </LazyLoadComponent>
    );
  }
  return <FullBleedContentCarouselDefaultComponent {...props} />;
};
