import React, { useState } from 'react';
import { Field, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import { ReferencableItemFields } from 'src/types/Referencable';
import dynamic from 'next/dynamic';
import LazyLoadComponent from 'components/shared/lazy-load/LazyLoadComponent';
import config from 'temp/config';

interface RelatedLinksDatasourceFields {
  title: {
    jsonValue: Field<string>;
  };
}

export interface RelatedLinksPageFields {
  relatedLinks: {
    targetItems: ReferencableItemFields[];
  };
}

type RelatedLinksProps = {
  params: { [key: string]: string; styles: string };
  fields?: {
    data: {
      datasource?: RelatedLinksDatasourceFields;
      contextItem: RelatedLinksPageFields;
    };
  };
};

export const Default = (props: RelatedLinksProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const datasource = props.fields?.data?.datasource;
  const page = props.fields?.data?.contextItem;
  const length = props.fields?.data?.contextItem?.relatedLinks?.targetItems?.length ?? 0;

  console.log('---->[RelatedLinks] Component', datasource);

  let DynamicRelatedLinksCard;
  const editingmode = isEditorActive();
  if (editingmode) {
    // Static import when in editing mode
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicRelatedLinksCard = require('./partials/RelatedLinksCard').RelatedLinksCard;
  } else {
    // Dynamic import when not in editing mode
    DynamicRelatedLinksCard = dynamic(
      () => import('./partials/RelatedLinksCard').then((mod) => mod.RelatedLinksCard),
      { ssr: !!config.dynamicImportSsr }
    );
  }

  return (
    <>
      {page && (
        <LazyLoadComponent componentId="RelatedLinks">
          <div
            className={`related-links component--with-paddings ${sitecoreStyles(
              props.params.styles
            )}`}
            id={id ? id : undefined}
          >
            {datasource?.title?.jsonValue && (
              <Text field={datasource.title.jsonValue} tag="h3" className="related-links__title" />
            )}
            <div className="related-links__content">
              <div className="related-links__cards-section">
                {length > 0 && length < 9 ? (
                  page?.relatedLinks?.targetItems.map((item) => (
                    <span key={item.id} className="related-links__card-box-content">
                      <DynamicRelatedLinksCard {...item} />
                    </span>
                  ))
                ) : (
                  <RelatedLinksDefaultComponent {...props} />
                )}
              </div>
            </div>
          </div>
        </LazyLoadComponent>
      )}
    </>
  );
};

export const RelatedLinksDefaultComponent = (props: RelatedLinksProps): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  return (
    <>
      {editingMode && (
        <div
          className={`related-links component--with-paddings ${sitecoreStyles(
            props.params?.styles
          )}`}
        >
          <span className="is-empty-hint">Related Links</span>
        </div>
      )}
    </>
  );
};
