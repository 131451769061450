import React, { useState } from 'react';
import { Text, RichText, DateField } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { useI18n } from 'next-localization';
import { format, isBefore, isSameDay, startOfToday, subDays } from 'date-fns';
import { EventsCardsByTagPageField } from './EventsCardsByTag';
import { getLocale } from 'components/posted-date/PostedDate';
import { Icon } from 'components/shared/icon/Icon';
import { IconName } from 'src/types/Enum';
import dynamic from 'next/dynamic';
import config from 'temp/config';

type RelatedEventsCardProps = {
  fields?: EventsCardsByTagPageField;
};

export const RelatedEventCard = (props: RelatedEventsCardProps): JSX.Element => {
  const { t } = useI18n();
  const { locale } = useI18n();

  const [editingMode] = useState(isEditorActive());
  console.log('[RelatedEventCard]', props);
  // const [languageClass, setLanguageClass] = useState<string>('');

  const timeZoneValue = props?.fields?.timezone;
  const is24HourFormat = props?.fields?.twentyFourHour;
  const timeFormat = is24HourFormat ? 'HH:mm' : 'h:mma';
  const hidetime = props?.fields?.hideTime;

  if (!props.fields) {
    return <></>;
  }

  const handleClick = () => {
    if (props.fields?.url) window.open(props.fields?.url, '_blank');
  };
  const handleChildElementClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };
  const datasource = props?.fields;
  if (props?.fields) {
    const startDateISO = datasource?.startDate?.toString() || new Date().toISOString();
    const endDateISO = datasource?.endDate?.toString() || new Date().toISOString();
    const splitStartDate = startDateISO.split('T')[0];
    const splitEndDate = endDateISO.split('T')[0];
    const today = startOfToday();
    const twoDaysAgo = subDays(today, 2);
    const bothDatesPast =
      isBefore(splitStartDate, twoDaysAgo) && isBefore(splitEndDate, twoDaysAgo);
    const shouldRenderDateRange = !bothDatesPast;
    const isStartDateToday = isSameDay(splitStartDate, today);
    const isEndDateToday = isSameDay(splitEndDate, today);
    const shouldShowDates = isStartDateToday && isEndDateToday;

    let formattedStartDate = '';
    let formattedEndDate = '';
    let dateDisplay;
    let yearToDisplay;
    let futureEvent;
    let futureEvent2;
    const isMultiDayEvent = !isSameDay(splitStartDate, splitEndDate);

    const currentYear = today.getFullYear();
    const startYear = new Date(splitStartDate).getFullYear();
    const endYear = new Date(splitEndDate).getFullYear();
    const isSameYear = startYear === endYear;
    const isCurrentYear = startYear === currentYear;

    if (locale() === 'es-ES') {
      const startDay = format(new Date(splitStartDate), 'd', { locale: getLocale(locale()) });
      const endDay = format(new Date(splitEndDate), 'd', { locale: getLocale(locale()) });
      const startMonth = format(new Date(splitStartDate), 'MMM', { locale: getLocale(locale()) });
      const endMonth = format(new Date(splitEndDate), 'MMM', { locale: getLocale(locale()) });

      // Capitalize the first letter of the month
      const capitalizedStartMonth = startMonth.charAt(0).toUpperCase() + startMonth.slice(1);
      const capitalizedEndMonth = endMonth.charAt(0).toUpperCase() + endMonth.slice(1);

      if (isMultiDayEvent) {
        if (isSameYear) {
          // Case 1: Multi-day event within the same month
          if (startMonth === endMonth) {
            dateDisplay = `${startDay} - ${endDay} de ${capitalizedStartMonth} de ${startYear}`;
          } else {
            // Case 2: Multi-day event across different months
            dateDisplay = `${startDay} de ${capitalizedStartMonth} de - ${endDay} de ${capitalizedEndMonth} de ${endYear}`;
          }
        } else {
          // Case 3: Multi-day event across different years
          dateDisplay = `${startDay} de ${capitalizedStartMonth} de ${startYear} - ${endDay} de ${capitalizedEndMonth} de ${endYear}`;
        }
      } else {
        dateDisplay = `${startDay} de ${capitalizedStartMonth} de ${startYear}`;
      }
    } else {
      formattedStartDate = format(splitStartDate, 'MMM d', { locale: getLocale(locale()) });
      formattedEndDate = format(splitEndDate, 'MMM d', { locale: getLocale(locale()) });
      const month = formattedStartDate.split(' ')[0];
      const endMonth = formattedEndDate.split(' ')[0];
      const startDay = formattedStartDate.split(' ')[1];
      const endDay = formattedEndDate.split(' ')[1];
      if (isMultiDayEvent) {
        if (isSameYear) {
          if (isCurrentYear) {
            if (month !== endMonth) {
              dateDisplay = `${formattedStartDate} - \n ${formattedEndDate}`;
              yearToDisplay = '';
            } else {
              // Same month and year but in the current year
              dateDisplay = `${month} ${startDay} - ${endDay}`;
              yearToDisplay = '';
            }
          } else {
            // Same year but different months (not current year)
            if (month !== endMonth) {
              futureEvent = true; // Set futureEvent to true for different months in the same year
            } else {
              // Same month and year but not current year
              dateDisplay = `${month} \n ${startDay} - ${endDay}`;
              yearToDisplay = `${startYear}`; // Show year if it's not the current year
            }
          }
        } else {
          if (startYear === currentYear && !(endYear === currentYear)) {
            dateDisplay = `${formattedStartDate} - \n ${formattedEndDate}`;
            yearToDisplay = `${endYear}`;
          } else {
            if (!(startYear === currentYear) && !(endYear === currentYear)) {
              futureEvent = true; // Handle future events that are not in the current year
            } else {
              futureEvent2 = true; // Handle other future events
            }
          }
        }
      } else {
        dateDisplay = `${month} ${startDay}`;
        yearToDisplay = isCurrentYear ? '' : startYear;
      }
    }

    const editingmode = isEditorActive();

    let DynamicTertiary;
    if (editingmode) {
      // Static import when in editing mode
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      DynamicTertiary = require('components/link/Link').Tertiary;
    } else {
      // Dynamic import when not in editing mode
      DynamicTertiary = dynamic(() => import('components/link/Link').then((mod) => mod.Tertiary), {
        ssr: !!config.dynamicImportSsr,
      });
    }

    let DynamicIsFeatured;
    if (editingmode) {
      // Static import when in editing mode
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      DynamicIsFeatured = require('components/Is-Featured/IsFeatured').Default;
    } else {
      // Dynamic import when not in editing mode
      DynamicIsFeatured = dynamic(
        () => import('components/Is-Featured/IsFeatured').then((mod) => mod.Default),
        { ssr: !!config.dynamicImportSsr }
      );
    }
    const defaultDate = props.fields?.startDate && props.fields?.endDate;
    const isDisplayingTimeAndDate =
      (shouldRenderDateRange && !shouldShowDates) || (shouldRenderDateRange && !hidetime);

    const topSectionClass = isDisplayingTimeAndDate
      ? 'event__top-section'
      : 'event__top-section no-min-height';

    return (
      <>
        <div
          className={`related-event-card event`}
          onClick={!editingMode ? handleClick : () => null}
        >
          <div className={topSectionClass}>
            {!shouldShowDates && shouldRenderDateRange && defaultDate && !futureEvent && (
              <div>
                <Text
                  style={{ display: 'inline' }}
                  tag="h3"
                  tabIndex={0}
                  className={`event__date`}
                  field={{ value: dateDisplay }}
                />
                {yearToDisplay && (
                  <Text
                    style={{ display: 'inline' }}
                    tag="h5"
                    tabIndex={0}
                    className={`event__year`}
                    field={{ value: yearToDisplay }}
                  />
                )}
              </div>
            )}
            {!shouldShowDates && shouldRenderDateRange && defaultDate && futureEvent && (
              <div>
                <Text
                  style={{ display: 'inline' }}
                  tag="h4"
                  tabIndex={0}
                  className={`event__date`}
                  field={{ value: formattedStartDate }}
                />
                {startYear && (
                  <Text
                    style={{ display: 'inline' }}
                    tag="h6"
                    tabIndex={0}
                    className={`event__year`}
                    field={{ value: startYear }}
                  />
                )}
                <h3 style={{ display: 'inline' }}> - </h3>
                <br />
                <Text
                  style={{ display: 'inline' }}
                  tag="h4"
                  tabIndex={0}
                  className={`event__date`}
                  field={{ value: formattedEndDate }}
                />
                {endYear && (
                  <Text
                    style={{ display: 'inline' }}
                    tag="h6"
                    tabIndex={0}
                    className={`event__year`}
                    field={{ value: endYear }}
                  />
                )}
              </div>
            )}
            {!shouldShowDates && shouldRenderDateRange && defaultDate && futureEvent2 && (
              <div>
                <Text
                  style={{ display: 'inline' }}
                  tag="h3"
                  tabIndex={0}
                  className={`event__date`}
                  field={{ value: formattedStartDate }}
                />
                {startYear && (
                  <Text
                    style={{ display: 'inline' }}
                    tag="h5"
                    tabIndex={0}
                    className={`event__year`}
                    field={{ value: startYear }}
                  />
                )}
                <h3 style={{ display: 'inline' }}> - </h3>
                <br />
                <Text
                  style={{ display: 'inline' }}
                  tag="h3"
                  tabIndex={0}
                  className={`event__date`}
                  field={{ value: formattedEndDate }}
                />
              </div>
            )}
            {!hidetime && shouldRenderDateRange && defaultDate && (
              <div
                className={
                  shouldShowDates
                    ? 'today body1 event__date-container'
                    : 'not-today body1 event__date-container'
                }
              >
                <DateField
                  tag={!shouldShowDates ? 'div' : 'h3'}
                  field={{ value: props.fields?.startDate }}
                  render={(date) => (
                    <>
                      {' '}
                      {date ? (
                        <>
                          {!shouldShowDates && '@'}
                          {format(date, timeFormat).toLowerCase()}
                          <>&nbsp;- &nbsp;</>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                />
                <DateField
                  field={{ value: props.fields?.endDate }}
                  render={(date) => (
                    <>
                      {date ? (
                        <>
                          {shouldShowDates ? (
                            <div>
                              <h3 style={{ display: 'inline' }}>
                                {format(date, timeFormat).toLowerCase()}
                              </h3>
                              &nbsp;
                              <h5 style={{ display: 'inline' }}>{timeZoneValue}</h5>
                            </div>
                          ) : (
                            <div>
                              {format(date, timeFormat).toLowerCase()} {timeZoneValue}
                            </div>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                />
              </div>
            )}
            {props?.fields?.location ? (
              <Text
                tag="span"
                className="body2 event__location"
                field={{ value: props?.fields?.location }}
              />
            ) : (
              props?.fields?.eventType && (
                <Text
                  tag="span"
                  className="body2 event__type"
                  field={{ value: props?.fields?.eventType }}
                />
              )
            )}
            {props.fields?.isFeatured && (
              <div className="body1">
                <DynamicIsFeatured
                  params={{}}
                  fields={{
                    data: { contextItem: { isFeatured: { value: props.fields?.isFeatured } } },
                  }}
                />
              </div>
            )}
            {isMultiDayEvent && shouldRenderDateRange && (
              <div className="multi-day__tag body1">
                <Icon icon={IconName.Calendar} iconLabel={'Calendar'} />
                {t('component-multidayevent')}
              </div>
            )}
          </div>

          <div className=" event__bottom-section">
            <div>
              <Text
                tag="div"
                tabIndex={0}
                className="subheading1 event__title"
                field={{ value: props.fields?.referenceTitle }}
              />
              <RichText
                tag="div"
                className="body2 event__description"
                field={{ value: props.fields?.referenceText }}
              />
            </div>
            <div onClick={(e) => handleChildElementClick(e)}>
              {props.fields?.url && (
                <DynamicTertiary
                  fields={{
                    Link: {
                      value: {
                        href: props.fields?.url,
                        text: t('component-eventscardsbytag-register'),
                      },
                    },
                  }}
                  params={{}}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
  return <></>;
};
