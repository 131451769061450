import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { parseISO, format } from 'date-fns';
import { useI18n } from 'next-localization';
import * as l from 'date-fns/locale';
import sitecoreStyles from 'src/helpers/sitecoreStyles';

export interface PostedDateFields {
  id?: string;
  postedDate?: {
    jsonValue: Field<string>;
  };
}

type PostedDateProps = {
  params: { [key: string]: string };
  fields?: {
    data: {
      contextItem?: PostedDateFields;
    };
  };
};

export const getLocale = (local: string) => {
  const locales: { [key: string]: l.Locale } = {
    default: l.enUS,
    en: l.enUS,
    'de-DE': l.de,
    'de-AT': l.deAT,
    'en-AE': l.enIN,
    'en-AU': l.enAU,
    'en-BE': l.enIN,
    'en-CA': l.enCA,
    'en-CH': l.enIN,
    'en-DK': l.enIN,
    'en-FI': l.enIN,
    'en-GB': l.enGB,
    'en-IE': l.enIE,
    'en-MY': l.enIN,
    'en-NL': l.enIN,
    'en-NO': l.enIN,
    'en-PL': l.enIN,
    'en-SE': l.enIN,
    'en-SG': l.enIN,
    'en-US': l.enUS,
    'es-ES': l.es,
    'fr-CA': l.frCA,
    'fr-FR': l.fr,
    'it-IT': l.it,
    'ja-JP': l.ja,
    'pt-BR': l.ptBR,
    'pt-PT': l.pt,
    'zh-CN': l.zhCN,
  };

  return locales[`${local}`] || locales['default'];
};

export const Default = (props: PostedDateProps) => {
  console.log('---->[PostedDateProps] Component', props);
  const { t, locale } = useI18n();

  const dateStrng =
    props.fields?.data?.contextItem?.postedDate?.jsonValue?.value.toString() ||
    new Date().toISOString();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion

  const splitStartDate = dateStrng.split('T')[0]; // Extract the date part

  const dateObj = parseISO(splitStartDate);

  let finalDate;
  if (locale() === 'es-ES') {
    finalDate = format(dateObj, " d 'de' MMMM 'de' yyyy", { locale: getLocale(locale()) });

    // Capitalize only the first letter of the month
    const monthIndex = finalDate.indexOf('de') + 3; // Find the index after 'de '
    const month = finalDate.slice(monthIndex, monthIndex + 1).toUpperCase(); // Capitalize the first letter of the month
    finalDate = finalDate.slice(0, monthIndex) + month + finalDate.slice(monthIndex + 1); // Replace the month in the final string
  } else {
    // Default formatting for other locales
    finalDate = format(dateObj, 'MMMM d, y', { locale: getLocale(locale()) });
  }

  if (
    !dateStrng ||
    dateStrng === '0001-01-01T00:00:00Z' ||
    dateStrng === '1401-01-01T00:00:00.000Z'
  ) {
    return null;
  }
  return (
    <>
      <div className={`posted-date ${sitecoreStyles(props.params?.styles)}`}>
        {props.fields && props.fields.data?.contextItem?.postedDate?.jsonValue && (
          <>
            {props.params?.postedLabel === 'false' ? (
              ''
            ) : (
              <span className="posted-date__title">{t('posted-on')}</span>
            )}
            <span
              className={`${props.params?.postedLabel === 'false' ? 'body2' : 'posted-date__date'}`}
            >
              {' '}
              {finalDate}
            </span>
          </>
        )}
      </div>
    </>
  );
};
