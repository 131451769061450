/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  ComponentRendering,
  Field,
  ImageField,
  LinkField,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { Job } from '../card/variants/career-job/CareerJob';
import { CardProps } from '../card/Card';
import { SocialBlock } from '../shared/social-share-block/socialShare';
import { BookmarkProvider } from '../../lib/contexts/BookmarkContext';
import { useBookmark } from '../../lib/contexts/BookmarkContext';
import { Tabs } from 'components/shared/tabs/Tabs';
import {
  //WelcomeLink,
  WelcomeSaveJobProps,
  Default as Welcomeandsavedjobs,
} from 'components/shared/welcome-savejob/WelcomeSaveJob';
import React, { useEffect, useState } from 'react';
import {
  CoveoSearchApiCareersFields,
  CoveoSearchApiCareersResult,
  //CoveoSearchApiQuery,
} from 'lib/coveo/CoveoSearchApi';
import { DetailMetadata, JobDetailMetadata } from './JobDetailsMetadata';
import { useI18n } from 'next-localization';
import parse from 'html-react-parser';
import { Default as Dropdown } from 'components/shared/dropdown/Dropdown';
import { useCareerJobDetailsContext } from 'lib/contexts/CareerJobDetailContext';

type JobRenderingData = {
  rendering: ComponentRendering;
  fields: {
    SearchJobs: LinkField;
    JobDetails: LinkField;
    SavedJob: LinkField;
    CreateProfile: Field<string>;
    EditProfile: Field<string>;
    ManageJobAlert: Field<string>;
    JobAlert: Field<string>;
    ApplyNow: Field<string>;

    SharingImage: ImageField;
    OpenGraphType?: Field<string>;
    OpenGraphSiteName?: Field<string>;
    FacebookAppID?: Field<string>;
    FacebookAdmins?: Field<string>;
  };
}; /*& WithSitecoreContextProps*/

export type JobDetailProps = {
  JobDetails: CoveoSearchApiCareersResult[];
  Recommendations: CardProps;
};

interface JobDetailTab {
  tabName: string | undefined;
  active: boolean;
  content: string | any;
}

interface SaveJobButtonProps {
  jobid: number;
  jobDetailData?: any;
}

const SaveJobBtn = (prop: SaveJobButtonProps): JSX.Element => {
  const { t } = useI18n();
  const { toggleBookmark, bookmarkedCards } = useBookmark();
  const [consent, setConsent] = useState(false);

  useEffect(() => {
    // Retrieve and parse the cookie state
    const consentFromLocalStorage = JSON.parse(localStorage?.getItem('CookieState') || 'false');
    // Coerce the parsed value to a boolean
    setConsent(!!consentFromLocalStorage);
  }, []);

  const handleBookmarkClick = (cardId: number) => {
    toggleBookmark(cardId);
    console.log('cardId', cardId);
  };
  console.log('consent', consent);
  return (
    <div
      className={`JobDetailSaveJob  ${
        bookmarkedCards.includes(prop.jobid) ? 'bookmarkIconSelected' : 'bookmarkIconNotSelected'
      }`}
      onClick={() => !consent && handleBookmarkClick(prop.jobid)}
      data-analytics-link-name="JobDetailSaveJob"
      data-analytics-link-type="CTA"
      data-analytics-content-class="Content"
      data-analytics-template-zone="Body"
      data-analytics-component-name="Career Job Detail"
      data-analytics-component-variation="Career Job Detail"
      data-analytics-target="#"
      data-analytics-component-section="Career Job Detail Save Job Button"
      data-analytics-slide-number="NAN"
      data-analytics-ispersonalized="False"
      data-analytics-iscarousal="False"
      data-analytics-job-id={prop.jobid}
      data-analytics-job-title={prop.jobDetailData?.raw?.title}
      data-analytics-job-region={
        prop.jobDetailData?.raw?.jobcountry +
        ':' +
        prop.jobDetailData?.raw?.jobcityname.replaceAll('^', ', ')
      }
      data-analytics-job-sub-title={prop.jobDetailData?.raw?.jobareaofexpertise}
    >
      {' '}
      {bookmarkedCards.includes(prop.jobid) ? t(`career-job-saved-job`) : t(`career-job-save-job`)}
    </div>
  );
};

export const Default = (props: JobRenderingData): JSX.Element => {
  console.log('---->[JobDetails] Component', props.fields);
  const { t } = useI18n();
  const { jobDetails, jobRecommendations } = useCareerJobDetailsContext();

  if (jobDetails) {
    const recommendedJobs: CardProps = {
      data: jobRecommendations,
      params: { isJobDetail: 'true' },
      fields: {
        data: {
          datasource: {
            id: '',
            link: {
              jsonValue: {
                value: {
                  href: '',
                },
              },
            },
          },
        },
      },
    };

    if (recommendedJobs.fields != undefined) {
      recommendedJobs.fields.data.datasource.link.jsonValue.value.href = props?.fields?.JobDetails
        ?.value?.href
        ? props.fields.JobDetails.value.href + '/'
        : '';
    }
    recommendedJobs.datalinks = {};
    recommendedJobs.datalinks.seealljobs = props?.fields?.SearchJobs?.value?.href
      ? props.fields.SearchJobs.value.href + '/'
      : '';
    recommendedJobs.datalinks.managejobalerts = props?.fields?.ManageJobAlert?.value
      ? props.fields.ManageJobAlert.value + '/'
      : '';
    const jobDetailRaw: CoveoSearchApiCareersFields = jobDetails.raw;
    const jobId = jobDetailRaw.jobid;
    const jobDetailMetadata: JobDetailMetadata = {
      jobid: jobDetailRaw.jobid,
      metatitle: jobDetailRaw.title,
      metadescription: jobDetailRaw.jobdescription,
      jobposteddate: jobDetailRaw.jobposteddate,
      jobdetailsurl: props?.fields?.JobDetails?.value?.href,
      sharingimage: props?.fields?.SharingImage?.value?.src,
      ogtype: props?.fields?.OpenGraphType?.value,
      ogsitename: props?.fields?.OpenGraphSiteName?.value,
      fbappid: props?.fields?.FacebookAppID?.value,
      fbadmins: props?.fields?.FacebookAdmins?.value,
    };

    const jobdetailswelcomelinks: WelcomeSaveJobProps = {
      welcomelinkprops: {
        backtosearch: props?.fields?.SearchJobs,
        savejob: props?.fields?.SavedJob,
        showlink: true,
      },
    };

    const jobTabs: JobDetailTab[] = [
      {
        tabName: t(`career-job-come-join-us`),
        content: jobDetailRaw.jobcomejoinus && parse(jobDetailRaw.jobcomejoinus),
        active: true,
      },
      {
        tabName: t(`career-job-what-you-will-do`),
        content: jobDetailRaw.jobwhatyouwilldo,
        active: false,
      },
      {
        tabName: t(`career-job-skills-and-experiences`),
        content: jobDetailRaw.jobskillsandexperiences,
        active: false,
      },
      {
        tabName: t(`career-job-about-you`),
        content: jobDetailRaw.jobaboutyou,
        active: false,
      },
      {
        tabName: t(`career-job-enjoy-your-career`),
        content: jobDetailRaw.jobenjoyyourcareer,
        active: false,
      },
      {
        tabName: t(`career-job-great-place-to-work`),
        content: jobDetailRaw.jobgreatplacetowork,
        active: false,
      },
      {
        tabName: t(`career-job-learn-more`),
        content: jobDetailRaw.joblearnmore,
        active: false,
      },
    ];

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [tabs, setTabs] = useState(jobTabs);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [activeCategory, setActiveCategory] = useState(jobTabs[0]);
    const handleClick = (obj: string) => {
      let activeTab: JobDetailTab;

      const tmpTabs = jobTabs.map((tmp) => {
        tmp.active = false;
        if (tmp.tabName == obj) {
          tmp.active = true;
          activeTab = tmp;
          setActiveCategory(activeTab);
        }
        return tmp;
      });

      setTabs([...tmpTabs]);
    };
    const activeCategoryContent = activeCategory.content;

    return (
      <BookmarkProvider>
        <>
          <DetailMetadata {...jobDetailMetadata} />
          <Welcomeandsavedjobs {...jobdetailswelcomelinks} />
          <div className="Job-detail">
            <div className="job-details-container">
              <div id="JobId" className="icms-hidden-container">
                {jobDetailRaw?.jobid}
              </div>
              <div className="areaofexpertise">{jobDetails?.raw?.jobareaofexpertise}</div>
              <div className="job-title">{jobDetails?.raw?.title}</div>
              <div className="country-city-areaofexp">
                <div className="country">
                  {t(`career-job-country`)} {(jobDetails?.raw?.jobcountry).replaceAll('^', ', ')}
                </div>
                <div className="city">
                  {t(`career-job-cities`)} {jobDetails?.raw?.jobcityname.replaceAll('^', ', ')}
                </div>
              </div>
            </div>
            <div className="job-description-video-container">
              <div className="job-description-container">
                <div className="job-description-heading">{t(`career-job-job-description`)}</div>
                <div className="jobDescription">
                  {jobDetails?.raw?.jobdescription && parse(jobDetails?.raw?.jobdescription)}
                </div>
                <div className="JobDetailButton">
                  <a
                    href={props?.fields?.ApplyNow?.value + jobId}
                    className="JobDetailApplyNow external-links"
                    data-analytics-link-name={t(`career-job-apply-now`)}
                    data-analytics-link-type="CTA"
                    data-analytics-content-class="Content"
                    data-analytics-template-zone="Body"
                    data-analytics-component-name="Career Job Detail"
                    data-analytics-component-variation="Career Job Detail primary button"
                    data-analytics-target={props?.fields?.ApplyNow?.value + jobId}
                    data-analytics-component-section="Career Job Detail Apply Button"
                    data-analytics-slide-number="NAN"
                    data-analytics-ispersonalized="False"
                    data-analytics-iscarousal="False"
                    data-analytics-job-title={jobDetails?.raw?.title}
                    data-analytics-job-region={
                      jobDetails?.raw?.jobcountry +
                      ':' +
                      jobDetails?.raw?.jobcityname.replaceAll('^', ', ')
                    }
                    data-analytics-job-sub-title={jobDetails?.raw?.jobareaofexpertise}
                    data-analytics-job-id={jobDetailRaw?.jobid}
                  >
                    {t(`career-job-apply-now`)}
                  </a>

                  <SaveJobBtn jobid={jobId} jobDetailData={jobDetails} />
                </div>
              </div>
              <div id="job-descvideo">
                <div></div>
              </div>
            </div>
            <div className="job-details-tab-content-Additionaldetails">
              {t(`career-job-additional-details`)}
            </div>
            <div
              className="job-details-tab-content-dropdown"
              data-analytics-link-name={activeCategory?.tabName}
              data-analytics-link-type="Engagement"
              data-analytics-content-class="Content"
              data-analytics-template-zone="Body"
              data-analytics-component-name="Career Job Detail"
              data-analytics-component-variation="Dropdown"
              data-analytics-target="NAN"
              data-analytics-component-section="Job Detail Tab Dropdown"
              data-analytics-slide-number="NAN"
              data-analytics-ispersonalized="False"
              data-analytics-iscarousal="False"
            >
              <Dropdown
                parentCallback={handleClick}
                params={{}}
                fields={{
                  options: tabs.map((el) => el.tabName ?? ''),
                  activeItem: activeCategory?.tabName ?? '',
                }}
              ></Dropdown>
            </div>

            <Tabs parentCallback={handleClick} fields={{ tabs: tabs }} params={{}}>
              <div className="job-details-tab-content">
                {typeof activeCategoryContent === 'object'
                  ? activeCategoryContent
                  : activeCategoryContent && parse(activeCategoryContent)}
              </div>
              <div className="JobDetailButton">
                <a
                  href={props?.fields?.ApplyNow?.value + jobId}
                  className="JobDetailApplyNow external-links"
                  data-analytics-link-name={t(`career-job-apply-now`)}
                  data-analytics-link-type="CTA"
                  data-analytics-content-class="Content"
                  data-analytics-template-zone="Body"
                  data-analytics-component-name="Career Job Detail"
                  data-analytics-component-variation="Career Job Detail secondary button"
                  data-analytics-target={props?.fields?.ApplyNow?.value + jobId}
                  data-analytics-component-section="Career Job Detail Apply Button"
                  data-analytics-slide-number="NAN"
                  data-analytics-ispersonalized="False"
                  data-analytics-iscarousal="False"
                  data-analytics-job-title={jobDetails?.raw?.title}
                  data-analytics-job-region={
                    jobDetails?.raw?.jobcountry +
                    ':' +
                    jobDetails?.raw?.jobcityname.replaceAll('^', ', ')
                  }
                  data-analytics-job-sub-title={jobDetails?.raw?.jobareaofexpertise}
                  data-analytics-job-id={jobDetailRaw?.jobid}
                >
                  {t(`career-job-apply-now`)}
                </a>
                <SaveJobBtn jobid={jobId} jobDetailData={jobDetails} />{' '}
              </div>
            </Tabs>
            <div className="social-share-container">
              <SocialBlock
                shareLinkUrls={props.fields.JobDetails.value.href + '/' + jobId}
                shareLinkTitle={jobDetails?.raw?.title}
              />
              <div className="profile-quick-links">
                <h5>{t(`career-job-profile-quick-links`)}</h5>
                <a
                  href={props?.fields?.EditProfile?.value}
                  className="link ga-track external-links "
                  title="Text Link"
                  aria-label="Text Link"
                  data-text="Text Link"
                  data-analytics-link-name={t(`career-job-edit-profile`)}
                  data-analytics-link-type="CTA"
                  data-analytics-content-class="Content"
                  data-analytics-template-zone="Body"
                  data-analytics-component-name="Profile quick link"
                  data-analytics-component-variation="Link-Molecule"
                  data-analytics-target={props?.fields?.EditProfile?.value}
                  data-analytics-component-section="Career Job Detail Edit profile Link"
                  data-analytics-slide-number="NAN"
                  data-analytics-ispersonalized="False"
                  data-analytics-iscarousal="False"
                  data-analytics-job-title={jobDetails?.raw?.title}
                  data-analytics-job-region={
                    jobDetails?.raw?.jobcountry +
                    ':' +
                    jobDetails?.raw?.jobcityname.replaceAll('^', ', ')
                  }
                  data-analytics-job-sub-title={jobDetails?.raw?.jobareaofexpertise}
                  data-analytics-job-id={jobDetailRaw?.jobid}
                >
                  {t(`career-job-edit-profile`)}
                </a>
                <a
                  href={props?.fields?.ManageJobAlert?.value}
                  className="link ga-track external-links "
                  title="Text Link"
                  aria-label="Text Link"
                  data-text="Text Link"
                  data-analytics-link-name={t(`career-job-manage-job-alerts`)}
                  data-analytics-link-type="CTA"
                  data-analytics-content-class="Content"
                  data-analytics-template-zone="Body"
                  data-analytics-component-name="Profile quick link"
                  data-analytics-component-variation="Link-Molecule"
                  data-analytics-target={props?.fields?.ManageJobAlert?.value}
                  data-analytics-component-section="Career Job Detail Manage job alerts"
                  data-analytics-slide-number="NAN"
                  data-analytics-ispersonalized="False"
                  data-analytics-iscarousal="False"
                  data-analytics-job-title={jobDetails?.raw?.title}
                  data-analytics-job-region={
                    jobDetails?.raw?.jobcountry +
                    ':' +
                    jobDetails?.raw?.jobcityname.replaceAll('^', ', ')
                  }
                  data-analytics-job-sub-title={jobDetails?.raw?.jobareaofexpertise}
                  data-analytics-job-id={jobDetailRaw?.jobid}
                >
                  {t(`career-job-manage-job-alerts`)}
                </a>
              </div>
            </div>
            <div className="insights-container">
              <div className="insight-heading">
                <h3>{t(`career-job-hear-from-our-people`)}</h3>
              </div>
              <div id="icms-cards"></div>
            </div>
            <Job {...recommendedJobs} />
          </div>
        </>
      </BookmarkProvider>
    );
  }
  return (
    <>
      <div className={`card is-empty-hint`}>
        <div className="card-header">
          <h4>Empty card</h4>
        </div>
      </div>
    </>
  );
};
