import React, { useState } from 'react';
import { LinkField, RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { HeroProps } from 'components/hero/Hero';
import { Tertiary } from 'components/link/Link';
import { NextImageWithNullFallback } from 'components/shared/nextImg/NextImageWithNullFallback';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import { SearchField } from 'components/search-field/SearchField';

const BaseFullBleedHero = ({
  props,
  hasSearchField = false,
  isRevert = false,
}: {
  props: HeroProps;
  hasSearchField?: boolean;
  isRevert?: boolean;
}): JSX.Element => {
  const id = props.params.RenderingIdentifier;

  if (props.fields) {
    return (
      <div
        className={`full-bleed-hero ${isRevert ? 'full-bleed-hero--revert' : ''} ${sitecoreStyles(
          props.params?.styles
        )}`}
        id={id ? id : undefined}
      >
        <div className="full-bleed-hero__container">
          <div className="full-bleed-hero__content">
            <div className="full-bleed-hero__box">
              <div className="full-bleed-hero__title-box">
                <div className="h3 full-bleed-hero__title">
                  {!props.fields?.data.datasource?.hideContentLabel?.jsonValue?.value &&
                    props.fields?.data.datasource?.link?.jsonValue?.value.href &&
                    !hasSearchField &&
                    (isEditorActive() ? (
                      <div className="button-tags full-bleed-hero__tag">
                        {
                          props.fields?.data.datasource?.contentLabel?.targetItem?.title?.jsonValue
                            .value
                        }
                      </div>
                    ) : (
                      <Text
                        tag="div"
                        className="button-tags full-bleed-hero__tag"
                        field={
                          props.fields?.data.datasource?.contentLabel?.targetItem?.title
                            ?.jsonValue || { value: '' }
                        }
                      />
                    ))}
                  <Text
                    tag="h1"
                    className="h1 full-bleed-hero__title"
                    field={props.fields?.data.datasource?.title?.jsonValue}
                  />
                </div>
              </div>
              <RichText
                tag="div"
                className="body2 full-bleed-hero__text"
                field={props.fields?.data.datasource?.body?.jsonValue}
              />

              {hasSearchField ? (
                <SearchField
                  fields={{ Link: props.fields?.data.datasource?.link?.jsonValue as LinkField }}
                />
              ) : (
                <Tertiary
                  fields={{ Link: props.fields?.data.datasource?.link?.jsonValue }}
                  params={{ styles: 'full-bleed-hero__link' }}
                />
              )}
            </div>
          </div>
        </div>
        <NextImageWithNullFallback
          loading="eager"
          field={props.fields?.data.datasource?.image?.jsonValue}
          // width={1728}
          // height={740}
          fill
          sizes="(max-width: 1200px) 100vw, (min-width: 1201px) 70vw"
          params={{ styles: 'full-bleed-hero__img-container' }}
          priority
        />
      </div>
    );
  }

  return <DefaultFullBleedHero {...props} />;
};

export const DefaultFullBleedHero = (props: HeroProps): JSX.Element => {
  const [editingMode] = useState(isEditorActive());

  return (
    <React.Fragment>
      {editingMode && (
        <div className={`full-bleed-hero ${sitecoreStyles(props.params?.styles)}`}>
          <span className="is-empty-hint">Hero</span>
        </div>
      )}
    </React.Fragment>
  );
};

export const FullBleedHeroLeft = (props: HeroProps): JSX.Element => (
  <BaseFullBleedHero props={props} />
);

export const FullBleedHeroLeftWithSearch = (props: HeroProps): JSX.Element => (
  <BaseFullBleedHero props={props} hasSearchField />
);

export const FullBleedHeroRight = (props: HeroProps): JSX.Element => (
  <BaseFullBleedHero props={props} isRevert />
);
