import { RichText, Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { ScrollingAccordionProps } from 'components/scrolling-accordion/ScrollingAccordion';
import { useState } from 'react';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import { ChildrenOrPlaceholder } from 'components/shared/children-or-placeholder/ChildrenOrPlaceholder';
import { Icon } from 'components/shared/icon/Icon';
import { IconName } from 'src/types/Enum';
export const ScrollingAccordionMobileView = (props: ScrollingAccordionProps): JSX.Element => {
  const id = props.params?.RenderingIdentifier;
  const datasource = props.fields?.data?.datasource;
  const sitecoreContext = useSitecoreContext();
  const [isAccordionOpen, setIsAccordionOpen] = useState(-1);

  const toggleAccordion = (index: number) => {
    if (index !== isAccordionOpen) {
      setIsAccordionOpen(index);
    } else {
      setIsAccordionOpen(-1);
    }
  };

  if (datasource) {
    return (
      <div
        className={`accordion scrolling-accordion-mobile ${sitecoreStyles(props.params?.styles)}`}
        id={id ? id : undefined}
      >
        <Text
          tag="h2"
          className="scrolling-accordion__header"
          field={datasource?.title?.jsonValue}
        />
        <div className="accordion__container">
          {datasource?.children?.results &&
            datasource?.children?.results.map((item, index) => (
              <div key={`item-${index}`} className="accordion__item">
                <div className="accordion__item-header">
                  <RichText
                    tabIndex={0}
                    tag="h5"
                    className={`accordion__item-title`}
                    field={item.title.jsonValue}
                  />
                  <div
                    className={`accordion__icon ${
                      isAccordionOpen === index ? 'accordion__icon--rotate' : ''
                    }`}
                  >
                    <button type="button" title="accordion" onClick={() => toggleAccordion(index)}>
                      <Icon icon={IconName.ChevronDown} iconLabel={'Open'} />
                    </button>
                  </div>
                </div>
                <div
                  className={`accordion__item-content ${
                    isAccordionOpen === index ? 'accordion__item-content--open' : ''
                  }`}
                >
                  <div className="accordion__inside-content">
                    <ChildrenOrPlaceholder
                      name={`accordion-card-${index + 1}`}
                      rendering={props.rendering}
                      sitecoreContext={sitecoreContext}
                    >
                      {props.children ? props.children : null}
                    </ChildrenOrPlaceholder>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }

  return <></>;
};
