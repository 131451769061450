import React from 'react';

import { TextImageRightComponent } from './TimageRight';
import { TimageFields } from 'components/timage/Timage';

export type TimageProps = {
  params: { [key: string]: string; styles: string };
  fields: TimageFields;
  variant: 'default' | 'square';
};

export const TextImageRightSquareTimage = (props: TimageProps): JSX.Element => {
  return <TextImageRightComponent props={props} variant="square" />;
};
