import React, { useState } from 'react';
import { RichText, Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { HeroProps } from 'components/hero/Hero';
import { ChildrenOrPlaceholder } from 'components/shared/children-or-placeholder/ChildrenOrPlaceholder';
import { Default as PrimaryButton } from 'components/link/Link';
import { HomepageWaves } from 'components/logo-lines/LogoLines';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

export const DefaultHomepageHero = (): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  return (
    <React.Fragment>
      {editingMode && (
        <div className={`homepage-hero-video component--with-paddings`}>
          <span className="is-empty-hint">Homepage Hero Video</span>
        </div>
      )}
    </React.Fragment>
  );
};

export const HomepageHeroVideo = (props: HeroProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const phKey = `homepage-hero-video-1-${props.params.DynamicPlaceholderId}`;
  const sitecoreContext = useSitecoreContext();
  if (props.fields) {
    return (
      <div className="homepage-hero-video component--with-paddings" id={id ? id : undefined}>
        <div>
          <div className="homepage-hero-video__blurBG"> </div>

          <div className="container homepage-hero-video__content">
            <div className="row">
              <div className="col-12 col-md-8 col-lg-5 homepage-hero-video__text-container">
                <Text
                  tag="h1"
                  className="homepage-hero-video__title"
                  field={props.fields?.data.datasource?.title?.jsonValue}
                />
                <RichText
                  tag="p"
                  className="homepage-hero-video__paragraph subheading2"
                  field={props.fields?.data.datasource?.body?.jsonValue}
                />
                {props.fields?.data.datasource?.link?.jsonValue && (
                  <PrimaryButton
                    params={{ styles: 'homepage-hero-video__link link__button-primary' }}
                    fields={{ Link: props.fields?.data.datasource?.link?.jsonValue }}
                  ></PrimaryButton>
                )}
              </div>
              <div className="col-12 col-md-8 col-lg-7 homepage-hero-video__video-container">
                <ChildrenOrPlaceholder
                  name={phKey}
                  rendering={props.rendering}
                  sitecoreContext={sitecoreContext}
                >
                  {props.children ? props.children[0] : null}
                </ChildrenOrPlaceholder>
              </div>
            </div>
          </div>
          <HomepageWaves />
        </div>
      </div>
    );
  }
  return <DefaultHomepageHero />;
};
