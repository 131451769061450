import {
  Field,
  ImageField,
  Text,
  RichTextField,
  Link,
  RichText,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import React, { useState } from 'react';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import config from 'temp/config';
import { DateTimeRangeFields } from 'components/date-time/DateTime';
import { PostedDateFields } from 'components/posted-date/PostedDate';
import { useI18n } from 'next-localization';
import { isBefore, startOfToday, subDays } from 'date-fns';
import dynamic from 'next/dynamic';
export interface ClientStoryCardFields {
  Image?: ImageField;
  Logo?: ImageField;
  Industry?: Field<string>;
  Title: Field<string>;
  Description?: RichTextField;
  Url?: string;
  Tag?: Field<string>;
  ContentType?: Field<string>;
}

export interface EventWebinarCardFields {
  EventType?: Field<string>;
}

type ClientStoryCardProps = {
  params: { [key: string]: string };
  fields?: ClientStoryCardFields;
};

type EventWebinarStoryCardProps = {
  params: { [key: string]: string };
  fields?: ClientStoryCardFields & EventWebinarCardFields & DateTimeRangeFields;
};

type NewsRoomCardProps = {
  params: { [key: string]: string };
  fields?: ClientStoryCardFields & PostedDateFields;
};

const DynamicNextImageWithNullFallback = dynamic(
  () =>
    import('components/shared/nextImg/NextImageWithNullFallback').then(
      (mod) => mod.NextImageWithNullFallback
    ),
  { ssr: !!config.dynamicImportSsr }
);

export const ClientStoryCard = (props: ClientStoryCardProps): JSX.Element => {
  const id = props.params?.RenderingIdentifier;
  const [editingMode] = useState(isEditorActive());
  console.log('---->[ClientStory] Component', props.fields);

  return (
    <div
      className={`client-story-card ${sitecoreStyles(props.params?.styles)}`}
      id={id ? id : undefined}
    >
      <div className="row">
        <div className="col-12">
          <div className="client-story-card__image-container">
            <DynamicNextImageWithNullFallback
              field={props.fields?.Image}
              width={552}
              height={264}
              params={{
                styles: 'client-story-card__main-image',
              }}
            ></DynamicNextImageWithNullFallback>
            {props.fields?.Logo?.value?.src && (
              <div className="client-story-card__logo-container">
                <DynamicNextImageWithNullFallback
                  field={props.fields?.Logo}
                  width={200}
                  height={100}
                  params={{
                    styles: 'next-image--contain',
                  }}
                ></DynamicNextImageWithNullFallback>
              </div>
            )}
          </div>
        </div>
      </div>
      {(props.fields?.Industry?.value || editingMode) && (
        <div className="row client-story-card__industry-container">
          <div className="col-12">
            <Text tag="div" field={props.fields?.Industry} className="body2" />
          </div>
          {/* <div className="row client-story-card__content-type-container">
          <div className="col-12">
            <Text tag="div" field={props.fields?.ContentType} className="body2" />
          </div>
 
        </div> */}
        </div>
      )}

      <div
        className="row client-story-card__title-container"
        data-analytics-link-name={props.fields?.Title?.value}
        data-analytics-link-type="Link"
        data-analytics-content-class="Card Content"
        data-analytics-template-zone="Body"
        data-analytics-component-name="Client Story Card"
        data-analytics-component-variation="Card"
        data-analytics-target={props.fields?.Url}
        data-analytics-component-section="Body"
        data-analytics-category={props.fields?.Industry?.value}
        data-analytics-slide-number="NAN"
        data-analytics-ispersonalized="False"
        data-analytics-iscarousal="False"
      >
        <div className="col-12">
          <Link
            className={`link ga-track  link__button-tertiary subheading1 `}
            field={{ value: props.fields?.Title?.value, href: props.fields?.Url }}
          >
            <div className="link__button-tertiary-text">
              <span>{props.fields?.Title?.value}</span>
            </div>
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <RichText tag="div" field={props.fields?.Description} className="body2" />
        </div>
      </div>
      {/* <div className="row client-story-card__tag-container">
        <div className="col-12">
          <Text tag="div" field={props.fields?.Tag} className="body2" />
        </div>
 
      </div> */}
    </div>
  );
};

export const InsightStoryCard = (props: ClientStoryCardProps): JSX.Element => {
  const id = props.params?.RenderingIdentifier;
  const [editingMode] = useState(isEditorActive());
  console.log('---->[InsightStory] Component', props);
  console.log('Insightstoryfields:', props.fields);

  const contentType = props.fields?.ContentType?.value;
  const tagValue = props.fields?.Tag?.value;
  const tag = tagValue ? tagValue.split(';')[0] : '';

  return (
    <div
      className={`insight-story-card ${sitecoreStyles(props.params?.styles)}`}
      id={id ? id : undefined}
    >
      <div className="row">
        <div className="col-12">
          <div className="insight-story-card__image-container">
            <DynamicNextImageWithNullFallback
              field={props.fields?.Image}
              // width={552}
              // height={264}
              fill
              sizes="(max-width: 1200px) 100vw, (min-width: 1201px) 25vw, 33vw"
              params={{
                styles: 'insight-story-card__main-image',
              }}
            ></DynamicNextImageWithNullFallback>
            {props.fields?.Logo?.value?.src && (
              <div className="insight-story-card__logo-container">
                <DynamicNextImageWithNullFallback
                  width={200}
                  height={100}
                  field={props.fields?.Logo}
                  params={{
                    styles: 'next-image--contain',
                  }}
                ></DynamicNextImageWithNullFallback>
              </div>
            )}
          </div>
        </div>
      </div>

      {(tag || editingMode) && (
        <div className="row insight-story-card__tag-container">
          <div className="col-12">
            <Text tag="div" field={{ value: tag }} className="body2" />
          </div>
        </div>
      )}

      <div
        className="row insight-story-card__title-container"
        data-analytics-link-name={props.fields?.Title?.value}
        data-analytics-link-type="Link"
        data-analytics-content-class="Card Content"
        data-analytics-template-zone="Body"
        data-analytics-component-name="Insight Story Card"
        data-analytics-component-variation="Card"
        data-analytics-target={props.fields?.Url}
        data-analytics-component-section="Body"
        data-analytics-category={props.fields?.Industry?.value}
        data-analytics-slide-number="NAN"
        data-analytics-ispersonalized="False"
        data-analytics-iscarousal="False"
      >
        <div className="col-12">
          <Link
            className={`link ga-track  link__button-tertiary subheading1 `}
            field={{ value: props.fields?.Title?.value, href: props.fields?.Url }}
          >
            <div className="link__button-tertiary-text">
              <span>{props.fields?.Title?.value}</span>
            </div>
          </Link>
        </div>
      </div>
      <div className="row insight-story-card__description-container">
        <div className="col-12">
          <RichText tag="div" field={props.fields?.Description} className="body2" />
        </div>
      </div>

      {(contentType || editingMode) && (
        <div className="row insight-story-card__content-type-container">
          <div className="col-12">
            <Text tag="div" field={{ value: contentType }} className="body2" />
          </div>
        </div>
      )}
    </div>
  );
};

export const EventWebinarCard = (props: EventWebinarStoryCardProps): JSX.Element => {
  const id = props.params?.RenderingIdentifier;
  const [editingMode] = useState(isEditorActive());
  console.log('---->[EventsWebinar] Component', props.fields);
  const dateProps: DateTimeRangeFields = {
    id: props.fields?.id,
    startDateTime: props.fields?.startDateTime,
    endDateTime: props.fields?.endDateTime,
  };

  const today = startOfToday();
  const twoDaysAgo = subDays(today, 2);
  const startDatetimeValue = props.fields?.startDateTime?.jsonValue?.value;
  const endDatetimeValue = props.fields?.endDateTime?.jsonValue?.value;
  const startDateTime = startDatetimeValue ? new Date(startDatetimeValue).toISOString() : 0;
  const endDateTime = endDatetimeValue ? new Date(endDatetimeValue).toISOString() : 0;
  const startDateLocal = new Date(startDateTime);
  const endDateLocal = new Date(endDateTime);

  const bothDatesPast = isBefore(startDateLocal, twoDaysAgo) && isBefore(endDateLocal, twoDaysAgo);
  const shouldRenderDateRange = !bothDatesPast;

  const editingmode = isEditorActive();

  let DynamicDateRangeWithoutTimezone;

  if (editingmode) {
    // Static import when in editing mode

    DynamicDateRangeWithoutTimezone =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/date-time/DateTime').DateRangeWithoutTimezone;
  } else {
    // Dynamic import when not in editing mode
    DynamicDateRangeWithoutTimezone = dynamic(
      () => import('components/date-time/DateTime').then((mod) => mod.DateRangeWithoutTimezone),
      { ssr: !!config.dynamicImportSsr }
    );
  }

  return (
    <div
      className={`events-webinar-card ${sitecoreStyles(props.params?.styles)}`}
      id={id ? id : undefined}
    >
      <div className="row">
        <div className="col-12">
          <div className="events-webinar-card__image-container">
            <DynamicNextImageWithNullFallback
              field={props.fields?.Image}
              params={{
                styles: 'events-webinar-card__main-image',
              }}
            ></DynamicNextImageWithNullFallback>
            {props.fields?.Logo?.value?.src && (
              <div className="events-webinar-card__logo-container">
                <DynamicNextImageWithNullFallback
                  field={props.fields.Logo}
                  params={{
                    styles: 'next-image--contain',
                  }}
                ></DynamicNextImageWithNullFallback>
              </div>
            )}
          </div>
        </div>
      </div>
      {(props.fields || editingMode) && shouldRenderDateRange && (
        <div className="row events-webinar-card__date-container">
          <div className="col-12">
            <DynamicDateRangeWithoutTimezone
              params={{}}
              fields={{ data: { contextItem: dateProps } }}
            />
          </div>
        </div>
      )}

      <div
        className="row events-webinar-card__title-container"
        data-analytics-link-name={props.fields?.Title.value}
        data-analytics-link-type="Link"
        data-analytics-content-class="Card Content"
        data-analytics-template-zone="Body"
        data-analytics-component-name="Client Story Card"
        data-analytics-component-variation="Card"
        data-analytics-target={props.fields?.Url}
        data-analytics-component-section="Body"
        data-analytics-category={props.fields?.Industry?.value}
        data-analytics-slide-number="NAN"
        data-analytics-ispersonalized="False"
        data-analytics-iscarousal="False"
      >
        <div className="col-12">
          <Link
            className={`link ga-track  link__button-tertiary subheading1 `}
            field={{ value: props.fields?.Title.value, href: props.fields?.Url }}
          >
            <div className="link__button-tertiary-text">
              <span>{props.fields?.Title.value}</span>
            </div>
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <RichText tag="div" field={props.fields?.Description} className="body2" />
        </div>
      </div>
      <div className="row events-webinar-card__event-type-container">
        <div className="col-12">
          <Text tag="div" field={props?.fields?.EventType} className="body2" />
        </div>
      </div>
    </div>
  );
};

export const NewsRoomCard = (props: NewsRoomCardProps): JSX.Element => {
  const id = props.params?.RenderingIdentifier;
  const { t } = useI18n();
  const [editingMode] = useState(isEditorActive());
  console.log('---->[NewsRoom] Component', props.fields);

  let postedDateObj = new Date();
  if (props.fields && props.fields?.postedDate) {
    postedDateObj = new Date(props.fields?.postedDate?.jsonValue?.value);
  }
  const formattedPostedDate = postedDateObj?.toISOString();
  const postedDate: PostedDateFields = {
    id: props.fields?.id,
    postedDate: { jsonValue: { value: formattedPostedDate } },
  };

  const handleClick = () => {
    if (props.fields?.Url) window.open(props?.fields?.Url, '_blank');
  };
  const handleChildElementClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const defaultDate = '1401-01-01T00:00:00.000Z';
  const isDateDafualt = formattedPostedDate === defaultDate;
  const editingmode = isEditorActive();

  let DynamicPostedDate;

  if (editingmode) {
    // Static import when in editing mode
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicPostedDate = require('components/posted-date/PostedDate').Default;
  } else {
    // Dynamic import when not in editing mode
    DynamicPostedDate = dynamic(
      () => import('components/posted-date/PostedDate').then((mod) => mod.Default),
      { ssr: !!config.dynamicImportSsr }
    );
  }
  let DynamicTertiary;

  if (editingmode) {
    // Static import when in editing mode
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    DynamicTertiary = require('components/link/Link').Tertiary;
  } else {
    // Dynamic import when not in editing mode
    DynamicTertiary = dynamic(() => import('components/link/Link').then((mod) => mod.Tertiary), {
      ssr: !!config.dynamicImportSsr,
    });
  }
  return (
    <div
      className={`news-room-card ${sitecoreStyles(props.params?.styles)}`}
      id={id ? id : undefined}
      onClick={!editingMode ? handleClick : () => null}
    >
      <div>
        {!isDateDafualt && (
          <div className="row news-room-card__posted-date">
            <div className="col-12">
              <DynamicPostedDate
                params={{ postedLabel: 'false' }}
                fields={{ data: { contextItem: postedDate } }}
              />
            </div>
          </div>
        )}

        <div
          className="row news-room-card__title-container"
          data-analytics-link-name={props.fields?.Title.value}
          data-analytics-link-type="Link"
          data-analytics-content-class="Card Content"
          data-analytics-template-zone="Body"
          data-analytics-component-name="Newsroom Card"
          data-analytics-component-variation="Card"
          data-analytics-target={props.fields?.Url}
          data-analytics-component-section="Body"
          data-analytics-category={props.fields?.Industry?.value}
          data-analytics-slide-number="NAN"
          data-analytics-ispersonalized="False"
          data-analytics-iscarousal="False"
        >
          <Text
            tag="div"
            className="subheading1 news-room-card__title"
            field={props.fields?.Title}
          />
        </div>
      </div>
      <div onClick={(e) => handleChildElementClick(e)} className="news-room-card__link">
        <DynamicTertiary
          fields={{ Link: { value: { href: props?.fields?.Url, text: t('read-more') } } }}
          params={{}}
        />
      </div>
    </div>
  );
};
