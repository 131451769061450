import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { CardProps } from 'components/card/Card';
import React, { useState } from 'react';
import { removeColFromStylesProps } from 'src/helpers/sitecoreStyles';
import dynamic from 'next/dynamic';
import { Tertiary } from 'components/link/Link';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import config from 'temp/config';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

export const ExpertCard = (props: CardProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const datasource = props.fields?.data?.datasource;
  const { sitecoreContext } = useSitecoreContext();
  const [editingMode] = useState(sitecoreContext && sitecoreContext.pageState === 'edit');

  const handleChildElementClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (editingMode) {
      e.preventDefault();
    } else {
      e.stopPropagation();
    }
  };

  const expertLink = props.fields?.data?.datasource?.link?.jsonValue?.value?.href;

  if (!datasource) {
    return <></>;
  }

  const editingmode = isEditorActive();

  let DynamicNextImageWithNullFallback;

  // Static import when in editing mode
  if (editingmode) {
    DynamicNextImageWithNullFallback =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/shared/nextImg/NextImageWithNullFallback').NextImageWithNullFallback;
  } else {
    // Dynamic import when not in editing mode
    DynamicNextImageWithNullFallback = dynamic(
      () =>
        import('components/shared/nextImg/NextImageWithNullFallback').then(
          (mod) => mod.NextImageWithNullFallback
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }
  return (
    <div
      className={`card expert-card ${
        expertLink ? 'expert-card__default-container' : ''
      } ${removeColFromStylesProps(props.params.styles)} `}
      id={id ? id : undefined}
    >
      <div className="expert-card__content">
        <DynamicNextImageWithNullFallback
          field={datasource?.image?.jsonValue}
          width={216}
          height={216}
          params={{
            styles: 'expert-card__image next-image--circle',
          }}
        />

        <div className="expert-card__text">
          <Text tag="div" className="body1" field={datasource?.title?.jsonValue} />
          <Text tag="h6" className="expert-card__name" field={datasource?.subTitle?.jsonValue} />
          <RichText
            tag="div"
            className="body2 expert-card__body"
            field={datasource?.body?.jsonValue}
          />
        </div>
      </div>
      {(expertLink || editingMode) && (
        <div onClick={(e) => handleChildElementClick(e)} className="expert-card__link">
          <Tertiary
            fields={{ Link: props.fields?.data?.datasource?.link?.jsonValue }}
            params={{}}
          ></Tertiary>
        </div>
      )}
    </div>
  );
};
