import { SearchEngine, buildResultList } from '@coveo/atomic-react';
import { ClientStoryCard } from 'components/client-story-card/ClientStoryCard';
import { useState, useEffect } from 'react';

export const CustomResultList = ({ engine }: { engine: SearchEngine }): JSX.Element => {
  const [headlessResultList] = useState(buildResultList(engine));
  const [headlessResultListState, setheadlessResultList] = useState(headlessResultList.state);

  useEffect(() => {
    headlessResultList.subscribe(() => {
      setheadlessResultList(headlessResultList.state);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="custom-poc-results-list row">
      {headlessResultListState.results.map((result, index) => {
        return (
          <div
            className={` ${index >= 3 ? 'col-12 col-md-6 col-lg-3' : 'col-12 col-lg-6'}`}
            key={index}
          >
            <ClientStoryCard
              key={index}
              params={{ name: 'client-story-card' }}
              fields={{
                Image: {
                  value: { src: result.raw.ec_images as string, width: 1, height: 1 },
                },
                Industry: { value: result.raw.ec_brand as string },
                Title: { value: result.title },
                Description: { value: result.excerpt },
              }}
            ></ClientStoryCard>
          </div>
        );
      })}
    </div>
  );
};
