import React, { useState } from 'react';
import {
  ComponentParams,
  ComponentRendering,
  Field,
  Text,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import dynamic from 'next/dynamic';
import config from 'temp/config';
import LazyLoadComponent from 'components/shared/lazy-load/LazyLoadComponent';

export interface FeaturedTextBlockFields {
  id: string;
  Title: Field<string>;
  Text?: Field<string>;
}

export interface TextBlocksProps {
  fields?: FeaturedTextBlockFields;
  rendering: ComponentRendering & { params: ComponentParams };
  params: ComponentParams;
  children?: React.ReactNode[];
}
let counter = 0;
const TextBlockLayout = ({
  titleColorClass,
  props,
}: {
  titleColorClass?: string;
  props: TextBlocksProps;
}): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const [editingMode] = useState(isEditorActive());
  const noFirstDivider = props.children?.length === 1 ? 'no-first-divider' : '';
  const noSecondDivider = props.children?.length === 2 ? 'no-second-divider' : '';
  const sitecoreContext = useSitecoreContext();

  let DynamicChildrenOrPlaceholder;
  const editingmode = isEditorActive();
  if (editingmode) {
    DynamicChildrenOrPlaceholder =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/shared/children-or-placeholder/ChildrenOrPlaceholder').ChildrenOrPlaceholder;
  } else {
    // Dynamic import when not in editing mode
    DynamicChildrenOrPlaceholder = dynamic(
      () =>
        import('components/shared/children-or-placeholder/ChildrenOrPlaceholder').then(
          (mod) => mod.ChildrenOrPlaceholder
        ),
      { ssr: !!config.dynamicImportSsr }
    );
  }

  counter++;
  return (
    <LazyLoadComponent componentId={`TextBlockLayout${counter}`}>
      <div
        className={`text-blocks text-blocks--${titleColorClass} component--with-paddings ${sitecoreStyles(
          props.params?.styles
        )}`}
        id={id ? id : undefined}
      >
        <div className="row">
          <div className="col-12 text-blocks__container">
            {(props.fields?.Title?.value || editingMode) && (
              <div className="row text-blocks__title-container">
                <div className="col-12">
                  <Text
                    className="text-blocks__title"
                    tag="h2"
                    tabIndex={0}
                    field={props.fields?.Title}
                  />
                </div>
              </div>
            )}

            {(props.fields?.Text?.value || editingMode) && (
              <div className="row text-blocks__text-container">
                <div className="col-12 col-lg-10">
                  <Text className="body2" tag="p" tabIndex={0} field={props.fields?.Text} />
                </div>
              </div>
            )}

            <div className="row text-blocks__blocks-container">
              <div className={`col-12 col-md-4 text-blocks__block ${noFirstDivider}`}>
                <DynamicChildrenOrPlaceholder
                  name={`text-block-1`}
                  rendering={props.rendering}
                  sitecoreContext={sitecoreContext}
                >
                  {props.children ? props.children[0] : null}
                </DynamicChildrenOrPlaceholder>
              </div>
              <div
                className={`col-12 col-md-4 text-blocks__block ${noFirstDivider} ${noSecondDivider}`}
              >
                <DynamicChildrenOrPlaceholder
                  name={`text-block-2`}
                  rendering={props.rendering}
                  sitecoreContext={sitecoreContext}
                >
                  {props.children ? props.children[1] : null}
                </DynamicChildrenOrPlaceholder>
              </div>
              <div className="col-12 col-md-4 text-blocks__block">
                <DynamicChildrenOrPlaceholder
                  name={`text-block-3`}
                  rendering={props.rendering}
                  sitecoreContext={sitecoreContext}
                >
                  {props.children ? props.children[2] : null}
                </DynamicChildrenOrPlaceholder>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LazyLoadComponent>
  );
};

export const Default = (props: TextBlocksProps): JSX.Element => {
  return <TextBlockLayout titleColorClass="default" props={props} />;
};

export const BlackTitle = (props: TextBlocksProps): JSX.Element => {
  return <TextBlockLayout titleColorClass="black-title" props={props} />;
};

export const CenterAligned = (props: TextBlocksProps): JSX.Element => {
  return (
    <div className="text-blocks--center-aligned">
      <TextBlockLayout titleColorClass="default" props={props} />
    </div>
  );
};
