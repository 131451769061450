/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ComponentRendering,
  Field,
  GetStaticComponentProps,
  LinkField,
  RichText,
  Text,
  useComponentProps,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ReferencableItemFields } from 'src/types/Referencable';
import { useState } from 'react';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import React from 'react';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import config from 'temp/config';
import { getOrganizationEndpoints } from '@coveo/atomic-react';
import { useI18n } from 'next-localization';
import { cacheProvider } from '../../lib/cache/cacheProvider';
import { CoveoSearchResult } from '../../types/Coveo';
import dynamic from 'next/dynamic';
import useMediaQuery from './../hooks/media-query-hook';
interface ContentGalleryDatasource {
  id: string;
  title?: {
    jsonValue: Field<string>;
  };
  text?: {
    jsonValue: Field<string>;
  };
  link: {
    jsonValue: LinkField;
  };
  content?: {
    targetItems: ReferencableItemFields[];
  };
  contentByTag?: {
    targetItems: ContentTag[];
  };
}

interface Fields {
  data: {
    datasource: ContentGalleryDatasource;
  };
}

interface ContentGalleryCoveoData {
  datasource: ContentGalleryDatasource;
}

interface ContentTag {
  id: string;
  url: { url: string };
  title: {
    jsonValue: Field<string>;
  };
}

export interface ContentTab {
  tabName: string | undefined;
  active: boolean;
  content: ReferencableItemFields[] | undefined;
}

export type ContentGalleryProps = {
  params: { [key: string]: string };
  fields?: Fields;
  rendering: ComponentRendering;
};

const editingmode = isEditorActive();

let DynamicContentGalleryCard;

if (editingmode) {
  // Static import when in editing mode

  DynamicContentGalleryCard =
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    require('components/content-gallery/partials/content-gallery-card/ContentGalleryCard').ContentGalleryCard;
} else {
  // Dynamic import when not in editing mode
  DynamicContentGalleryCard = dynamic(
    () =>
      import('components/content-gallery/partials/content-gallery-card/ContentGalleryCard').then(
        (mod) => mod.ContentGalleryCard
      ),
    { ssr: !!config.dynamicImportSsr }
  );
}

let DynamicTertiary;

if (editingmode) {
  // Static import when in editing mode
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  DynamicTertiary = require('components/link/Link').Tertiary;
} else {
  // Dynamic import when not in editing mode
  DynamicTertiary = dynamic(() => import('components/link/Link').then((mod) => mod.Tertiary), {
    ssr: !!config.dynamicImportSsr,
  });
}

let DynamicTabs;

if (editingmode) {
  // Static import when in editing mode
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  DynamicTabs = require('components/shared/tabs/Tabs').Tabs;
} else {
  // Dynamic import when not in editing mode
  DynamicTabs = dynamic(() => import('components/shared/tabs/Tabs').then((mod) => mod.Tabs), {
    ssr: !!config.dynamicImportSsr,
  });
}

let DynamicDropdown;

if (editingmode) {
  // Static import when in editing mode
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  DynamicDropdown = require('components/shared/dropdown/Dropdown').Default;
} else {
  // Dynamic import when not in editing mode
  DynamicDropdown = dynamic(
    () => import('components/shared/dropdown/Dropdown').then((mod) => mod.Default),
    { ssr: !!config.dynamicImportSsr }
  );
}

export const Default = (props: ContentGalleryProps): JSX.Element => {
  console.log('---->[ContentGalleryDefault] Component', props.fields?.data?.datasource);
  const datasource = props.fields?.data?.datasource;
  const id = props.params.RenderingIdentifier;
  const [editingMode] = useState(isEditorActive());
  const contentTabs: ContentTab[] = [];
  const isDesktop = useMediaQuery('(min-width: 768px)');

  const prepareData = (defaultCategoryName: string) => {
    contentTabs.push({
      tabName: defaultCategoryName,
      active: true,
      content: datasource?.content?.targetItems,
    });

    datasource?.content?.targetItems?.forEach((item) => {
      const refCategories = item.referenceCategory?.targetItems?.map(
        (ele) => ele?.title?.jsonValue.value
      );
      refCategories?.forEach((cat) => {
        if (cat) {
          const tabWithContent = contentTabs.find((element) => element.tabName === cat);
          if (tabWithContent) {
            tabWithContent.content?.push(item);
          } else {
            contentTabs.push({
              tabName: cat,
              active: false,
              content: [item],
            });
          }
        }
      });
    });

    return contentTabs;
  };

  const { t } = useI18n();

  const [tabs, setTabs] = useState(prepareData(t(`component-contentgallery-category-all`)));
  const [activeCategory, setActiveCategory] = useState(contentTabs[0]);
  console.log('activeCategory', activeCategory?.content);

  const handleClick = (obj: string) => {
    let activeTab: ContentTab;

    const tmpTabs = contentTabs.map((tmp) => {
      tmp.active = false;
      if (tmp.tabName == obj) {
        tmp.active = true;
        activeTab = tmp;
        setActiveCategory(activeTab);
      }

      return tmp;
    });

    setTabs([...tmpTabs]);
  };
  if (datasource) {
    return (
      <div
        className={`content-gallery content-gallery--default component--with-paddings ${sitecoreStyles(
          props.params?.styles
        )}`}
        id={id ? id : undefined}
      >
        <div className="row">
          <div className="col-12 col-lg-10 content-gallery__header-container">
            <Text tag="h2" className="content-gallery__title" field={datasource.title?.jsonValue} />
            {datasource.text?.jsonValue.value && (
              <RichText
                tag="div"
                className="body2 content-gallery__body"
                field={datasource.text?.jsonValue}
              />
            )}
          </div>
        </div>
        {isDesktop ? (
          <div className="row">
            <div className="col-12">
              <div className="content-gallery__all-cards-container">
                <DynamicTabs parentCallback={handleClick} fields={{ tabs: tabs }} params={{}}>
                  <div
                    className={`content-gallery__desktop-cards-container ${
                      activeCategory.content?.length === 5 || activeCategory.content?.length === 6
                        ? 'content-gallery__desktop-cards-container--equal'
                        : ''
                    } ${activeCategory?.content?.length === 1 ? 'single-card' : ''}`}
                  >
                    {activeCategory?.content?.map((card) => (
                      <div
                        className={`content-gallery__card-container`}
                        key={`${card.id}-dropdown`}
                      >
                        <DynamicContentGalleryCard {...card}></DynamicContentGalleryCard>
                      </div>
                    ))}
                  </div>
                </DynamicTabs>
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-12">
              <div className="content-gallery__main-container">
                <div className="content-gallery__category">Category</div>
                <div className="content-gallery__dropdown">
                  <DynamicDropdown
                    parentCallback={handleClick}
                    params={{}}
                    fields={{
                      options: tabs.slice(0, 10).map((el) => el.tabName ?? ''),
                      activeItem: activeCategory?.tabName ?? '',
                    }}
                  ></DynamicDropdown>
                </div>
                <div
                  className={`content-gallery__mobile-cards-container ${
                    activeCategory?.content?.length === 1 ? 'single-card' : ''
                  }`}
                >
                  {activeCategory?.content?.map((card) => {
                    return (
                      <div className="content-gallery__card-container" key={card.id}>
                        <DynamicContentGalleryCard {...card}></DynamicContentGalleryCard>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
        {(datasource.link?.jsonValue.value.href ||
          (!datasource.link?.jsonValue.value.href && editingMode)) && (
          <div className="row">
            <div className="col-12">
              <div className="content-gallery__link">
                <DynamicTertiary
                  fields={{ Link: datasource.link.jsonValue }}
                  params={{}}
                ></DynamicTertiary>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
  return <ContentGalleryDefaultComponent {...props} />;
};

export const WithoutFilters = (props: ContentGalleryProps): JSX.Element => {
  console.log('---->[ContentGalleryWithoutFilters] Component', props.fields?.data?.datasource);
  const datasource = props.fields?.data?.datasource;
  const id = props.params.RenderingIdentifier;
  const numberOfItems = datasource?.content?.targetItems.length ?? 0;

  const [editingMode] = useState(isEditorActive());
  if (datasource) {
    return (
      <div
        className={`content-gallery content-gallery--without-filters component--with-paddings ${sitecoreStyles(
          props.params?.styles
        )}`}
        id={id ? id : undefined}
      >
        {(datasource.title?.jsonValue.value || datasource.text?.jsonValue.value) && (
          <div className="row content-gallery__header-container">
            <div className="col-12 col-lg-10">
              <Text
                tag="h2"
                className="content-gallery__title"
                field={datasource.title?.jsonValue}
              />
              {datasource.text?.jsonValue.value && (
                <RichText
                  tag="div"
                  className="body2 content-gallery__body"
                  field={datasource.text?.jsonValue}
                />
              )}
            </div>
          </div>
        )}
        <div className="content-gallery__cards-container">
          <div
            className={`content-gallery__cards-container ${
              numberOfItems === 5 || numberOfItems === 6
                ? 'content-gallery__cards-container--equal'
                : ''
            }`}
          >
            {datasource.content?.targetItems?.map((card) => {
              return (
                <div className={`col-3 content-gallery__card-container`} key={card.id}>
                  <DynamicContentGalleryCard {...card}></DynamicContentGalleryCard>
                </div>
              );
            })}
          </div>
        </div>

        {(datasource.link?.jsonValue.value.href ||
          (!datasource.link?.jsonValue.value.href && editingMode)) && (
          <div className="row">
            <div className="col-12 content-gallery__link">
              <DynamicTertiary
                fields={{ Link: datasource.link.jsonValue }}
                params={{}}
              ></DynamicTertiary>
            </div>
          </div>
        )}
      </div>
    );
  }
  return <ContentGalleryDefaultComponent {...props} />;
};

const extractTagCategory = (path: string): string | null => {
  const regex = /\/data\/tags\/([^\/]+)\//;
  const match = path.match(regex);
  return match ? match[1] : null;
};

const groupItemsByX = (items: ContentTag[]): Record<string, string[]> => {
  return items.reduce((acc, item) => {
    const x = extractTagCategory(item.url.url);
    //console.log('Trigun - Extracted', item?.title?.jsonValue.value);
    if (x) {
      if (!acc[x]) {
        acc[x] = [];
      }
      acc[x].push(`"${item?.title?.jsonValue.value}"`);
    }
    return acc;
  }, {} as Record<string, string[]>);
};

const concatArrayForCoveoQuery = (data: string[]) => {
  return data
    ?.map(
      (element) =>
        `\"${element.replace('www.avanadedotcom.localhost', 'dotcomfed-vercel-qa.vercel.app')}\"`
    )
    ?.join(',');
};

async function fetchCoveoData(
  query: string,
  locale: string,
  numberOfResults?: number,
  sortCriteria?: string
) {
  const coveoUrl = `${getOrganizationEndpoints(config.coveoOrganizationId).search}?organizationId=${
    config.coveoOrganizationId
  }`;
  interface CoveoRequestBody {
    cq: string;
    locale: string;
    numberOfResults?: number;
    sortCriteria?: string;
  }
  const coveoBody: CoveoRequestBody = {
    cq: query,
    locale: locale,
  };
  if (numberOfResults) {
    coveoBody.numberOfResults = numberOfResults;
  }
  if (sortCriteria) {
    coveoBody.sortCriteria = sortCriteria;
  }
  //console.log('coveo request body ', coveoBody);
  try {
    const res = await fetch(coveoUrl, {
      headers: {
        Authorization: `Bearer ${config.coveoAccessToken}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(coveoBody),
    });

    const data = await res.json();
    if (!res.ok) {
      return { props: { data: { results: [] } } };
    }
    return { props: { data } };
  } catch (error) {
    //console.log('---->[DynamicGallery] Coveo Error !!!!!!!!!!!!!!!!!!!!!!!', error);
    return { props: { data: { results: [] } } };
  }
}

async function fetchDynamicCards(
  locale: string,
  taxonomyTagMapping: Record<string, string[]>,
  numberOfResults: number,
  isEditing: boolean,
  excludedCards?: string[]
) {
  let query = `@pagetype == 'InsightsBlogDetailPage'`;
  const taxonomyQueryParams: string[] = [];
  if (taxonomyTagMapping && Object.keys(taxonomyTagMapping).length > 0) {
    Object.entries(taxonomyTagMapping).forEach(([key, values]) => {
      values.length > 0 && taxonomyQueryParams.push(`@taxonomy_${key}==(${values.join(', ')})`);
    });

    if (taxonomyQueryParams.length > 0) {
      query += ` AND (${taxonomyQueryParams.join(' OR ')})`;
    }
    //console.log('query log taxonomy', query);
  }
  if (excludedCards && excludedCards.length > 0) {
    query += ` AND NOT (@uri==(${concatArrayForCoveoQuery(excludedCards)}))`;
    //console.log('query log exclusions', query);
  }

  const sortCriteria = '@pageposteddate descending';

  const cacheKey = `${process.env.PUBLIC_URL}_dynamic_content_gallery_tagged_${locale}`;
  let dynamicCards = await cacheProvider.get<CoveoSearchResult[]>(cacheKey);
  if (!dynamicCards || isEditing) {
    const dynamicCardsWrapper = await fetchCoveoData(query, locale, numberOfResults, sortCriteria);
    dynamicCards = dynamicCardsWrapper.props.data.results;
    if (dynamicCards && dynamicCards.length > 0 && !isEditing) {
      const ttl = parseInt(process.env.RELATED_CLIENT_STORIES_CACHE_DURATION || '21600'); // 6 hours
      //const ttl = parseInt('600'); // 10 mins
      await cacheProvider.set(cacheKey, dynamicCards, ttl); // 6 hours ttl
    }
  }
  return dynamicCards;
}

async function fetchContentAuthorCards(uri: string[], locale: string, isEditing: boolean) {
  if (uri.length) {
    const query = `@pagetype== 'InsightsBlogDetailPage' AND @uri==(${concatArrayForCoveoQuery(
      uri
    )})`;

    //console.log('content author query', query);
    const cacheKey = `${process.env.PUBLIC_URL}_dynamic_content_gallery_authored_${locale}`;
    let coveoAuthorCards = await cacheProvider.get<CoveoSearchResult[]>(cacheKey);
    if (!coveoAuthorCards || isEditing) {
      const coveoAuthorCardsWrapper = await fetchCoveoData(query, locale);
      //console.log('coveoAuthorCardsWrapper ', coveoAuthorCardsWrapper);
      coveoAuthorCards = coveoAuthorCardsWrapper.props.data.results;
      if (coveoAuthorCards && coveoAuthorCards.length > 0 && !isEditing) {
        const ttl = parseInt(process.env.RELATED_CLIENT_STORIES_CACHE_DURATION || '21600'); // 6 hours
        //const ttl = parseInt('600'); // 10 mins
        await cacheProvider.set(cacheKey, coveoAuthorCards, ttl); // 6 hours ttl
      }
    }

    if (coveoAuthorCards && coveoAuthorCards.length > 0) {
      //console.log('coveoAuthorCards ', Array.from(coveoAuthorCards.map((x: any) => x.uri)));
      const orderMap = new Map(
        uri.map((item, index) => [
          item.replace('www.avanadedotcom.localhost', 'dotcomfed-vercel-qa.vercel.app'),
          index,
        ])
      );
      //console.log('Reference Index Map:', Array.from(orderMap.entries()));
      const sortedCoveoAuthorCards = coveoAuthorCards.sort((a: any, b: any) => {
        const indexA = orderMap.get(a.uri);
        const indexB = orderMap.get(b.uri);

        //console.log(`Comparing a.id: ${a.uri} (indexA: ${indexA}) with b.id: ${b.uri} (indexB: ${indexB})`);

        if (indexA !== undefined && indexB !== undefined) {
          return indexA - indexB;
        }
        //if (indexA !== undefined) {
        //  return -1;
        //}
        //if (indexB !== undefined) {
        //  return 1;
        //}
        return 0;
      });
      //console.log('sortedCoveoAuthorCards', sortedCoveoAuthorCards);

      return sortedCoveoAuthorCards;
    }
  }
  return [];
}

export const getStaticProps: GetStaticComponentProps = async (props, _layoutData, context) => {
  const finalData: any[] = [];
  // const currentPage = context?.params?.path?.slice(-1)?.[0] || ' ';
  const pageMode = _layoutData?.sitecore?.context?.pageState;
  const isEditOrPreview = pageMode == 'edit' || pageMode == 'preview';
  //only run this for dynamic content gallery variant
  if (props?.params?.FieldNames == 'InsightsDynamicGallery') {
    const gallerySource = props?.fields?.data as unknown as ContentGalleryCoveoData;
    //console.log('---->[Trigun] getStaticProps', gallerySource.datasource.contentByTag?.targetItems);

    const galleryData = gallerySource?.datasource;

    if (galleryData) {
      const dynamicGallery = galleryData.content?.targetItems || [];
      //console.log('dynamicGallery', dynamicGallery);

      const maxNumberOfCards = 4;
      dynamicGallery.length > maxNumberOfCards && dynamicGallery.splice(maxNumberOfCards);
      //console.log('dynamicGallery after splice', dynamicGallery);

      const cardsByTag = galleryData?.contentByTag?.targetItems as unknown as ContentTag[];
      //console.log('cardsByTag', cardsByTag);

      const groupedItems = groupItemsByX(cardsByTag);
      //console.log('groupedItems ', groupedItems);

      let taggedCardCount = maxNumberOfCards;
      let contentAuthorCardsUri: string[] = [];

      if (dynamicGallery.length > 0) {
        const contentAuthorSelection = dynamicGallery
          .map((item) => item.url?.url?.split('?')?.[0])
          .filter((url): url is string => url !== undefined);
        //console.log('contentAuthorSelection', contentAuthorSelection);

        if (contentAuthorSelection.length > 0) {
          const contentAuthorCards = await fetchContentAuthorCards(
            contentAuthorSelection,
            context.locale || 'en',
            isEditOrPreview
          );
          //console.log('contentAuthorCards', contentAuthorCards);

          if (contentAuthorCards && contentAuthorCards.length > 0) {
            finalData.push(...contentAuthorCards);
            //console.log('finalData ', finalData);

            contentAuthorCardsUri = contentAuthorCards.map((result: any) => result.uri);
            //console.log('contentAuthorCardsUri', contentAuthorCardsUri);

            taggedCardCount -= contentAuthorCardsUri.length;
            //console.log('taggedCardCount ', taggedCardCount);
          }
        }
      }

      const dynamicTaggedCards =
        taggedCardCount > 0 &&
        (await fetchDynamicCards(
          context.locale || 'en',
          groupedItems,
          taggedCardCount,
          isEditOrPreview,
          contentAuthorCardsUri
        ));
      //console.log('dynamicTaggedCards ', dynamicTaggedCards);

      dynamicTaggedCards && finalData.push(...dynamicTaggedCards);
    }
  }
  return {
    finalData,
  };
};

export const InsightsDynamicGallery = (props: ContentGalleryProps): JSX.Element => {
  console.log('---->[ContentByTag] Component', props.fields?.data?.datasource);
  const datasource = props.fields?.data?.datasource;
  const id = props.params.RenderingIdentifier;
  const [editingMode] = useState(isEditorActive());
  const externalData = useComponentProps<any>(props.rendering.uid);
  const coveoData = externalData?.finalData;

  if (coveoData && coveoData?.length > 0) {
    const numberOfItems = coveoData.length;
    const contentTabs: ContentTab = {
      tabName: undefined,
      active: false,
      content: [],
    };
    const mapCoveoData = () => {
      for (let searchIndex = 0; searchIndex < numberOfItems; searchIndex++) {
        contentTabs.content?.push({
          url: { url: coveoData?.[searchIndex]?.Uri },
          //referenceCategory: t(category.toLowerCase()) || category,
          referenceTitle: { jsonValue: { value: coveoData?.[searchIndex]?.raw.pagereftitle } },
          referenceText: { jsonValue: { value: coveoData?.[searchIndex]?.raw.pagereftext } },
          referenceImage: {
            jsonValue: { value: { src: coveoData?.[searchIndex]?.raw.pagerefimage } },
          },
        });
      }
      //console.log('Mapped coveo data ', contentTabs);
    };

    if (datasource) {
      mapCoveoData();
      if (contentTabs?.content && contentTabs.content?.length > 0) {
        return (
          <div
            className={`content-gallery dynamic-content-gallery--without-filters component--with-paddings ${sitecoreStyles(
              props.params?.styles
            )}`}
            id={id ? id : undefined}
          >
            {(datasource.title?.jsonValue.value || datasource.text?.jsonValue.value) && (
              <div className="row content-gallery__header-container">
                <div className="col-12 col-lg-10">
                  <Text
                    tag="h2"
                    className="content-gallery__title"
                    field={datasource.title?.jsonValue}
                  />
                  {datasource.text?.jsonValue.value && (
                    <RichText
                      tag="div"
                      className="body2 content-gallery__body"
                      field={datasource.text?.jsonValue}
                    />
                  )}
                </div>
              </div>
            )}
            <div className="content-gallery__cards-container">
              <div
                className={`content-gallery__cards-container ${
                  numberOfItems === 5 || numberOfItems === 6
                    ? 'content-gallery__cards-container--equal'
                    : ''
                }`}
              >
                {contentTabs.content?.map((card) => {
                  return (
                    <div className={`col-3 content-gallery__card-container`} key={card.id}>
                      <DynamicContentGalleryCard {...card}></DynamicContentGalleryCard>
                    </div>
                  );
                })}
              </div>
            </div>

            {(datasource.link?.jsonValue.value.href ||
              (!datasource.link?.jsonValue.value.href && editingMode)) && (
              <div className="row">
                <div className="col-12 content-gallery__link">
                  <DynamicTertiary
                    fields={{ Link: datasource.link.jsonValue }}
                    params={{}}
                  ></DynamicTertiary>
                </div>
              </div>
            )}
          </div>
        );
      }
    }
  }

  return <ContentGalleryDefaultComponent {...props} />;
};

export const ContentGalleryDefaultComponent = (props: ContentGalleryProps): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  return (
    <React.Fragment>
      {editingMode && (
        <div className={`content-gallery ${sitecoreStyles(props.params?.styles)}`}>
          <span className="is-empty-hint">Content Gallery Default</span>
        </div>
      )}
    </React.Fragment>
  );
};
