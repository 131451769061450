import React, { useState } from 'react';
import { Field, RichText as JssRichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import LazyLoadComponent from 'components/shared/lazy-load/LazyLoadComponent';

interface Fields {
  Text: Field<string>;
}

export type RichTextProps = {
  params: { [key: string]: string };
  fields?: Fields;
};
let counter = 0;
export const Default = (props: RichTextProps): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  const text = props.fields ? (
    <JssRichText field={props.fields.Text} />
  ) : (
    <React.Fragment>
      {editingMode && (
        <div className={`rich-text ${sitecoreStyles(props.params?.styles)}`}>
          <span className="is-empty-hint">Rich text</span>
        </div>
      )}
    </React.Fragment>
  );
  const id = props.params.RenderingIdentifier;
  counter++;
  return (
    <LazyLoadComponent componentId={`richText${counter}`}>
      <div className={`rich-text ${sitecoreStyles(props.params?.styles)}`} id={id ? id : undefined}>
        {text}
      </div>
    </LazyLoadComponent>
  );
};
