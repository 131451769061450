import React, { useState } from 'react';
import { ReferencableItemFields } from 'src/types/Referencable';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { useI18n } from 'next-localization';
import { removeColFromStylesProps } from 'src/helpers/sitecoreStyles';
import { IconName } from 'src/types/Enum';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import dynamic from 'next/dynamic';
import config from 'temp/config';

type RefereceProps = {
  params: { [key: string]: string };
  fields?: {
    data: {
      datasource?: ReferencableItemFields;
    };
  };
};

export const Default = (props: RefereceProps) => {
  const id = props.params.RenderingIdentifier;
  const datasource = props.fields?.data?.datasource;
  const [editingMode] = useState(isEditorActive());
  console.log('---->[ReferenceCard] Component', datasource);

  const { t } = useI18n();

  const handleClick = () => {
    if (datasource?.url && props?.params?.scrolling === 'true')
      window.open(datasource.url.url, '_self');
  };

  const editingmode = isEditorActive();
  let DynamicNextImageWithNullFallback;

  if (editingmode) {
    // Static import when in editing mode

    DynamicNextImageWithNullFallback =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/shared/nextImg/NextImageWithNullFallback').NextImageWithNullFallback;
  } else {
    // Dynamic import when not in editing mode
    DynamicNextImageWithNullFallback = dynamic(
      () =>
        import('components/shared/nextImg/NextImageWithNullFallback').then(
          (mod) => mod.NextImageWithNullFallback
        ),
      { ssr: !!config.dynamicImportSsr } // Adjust server-side rendering (ssr: true) based on your config
    );
  }

  let DynamicIcon;

  if (editingMode) {
    // Static import when in editing mode
    DynamicIcon =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('components/shared/icon/Icon').Icon;
  } else {
    // Dynamic import when not in editing mode
    DynamicIcon = dynamic(() => import('components/shared/icon/Icon').then((mod) => mod.Icon), {
      ssr: !!config.dynamicImportSsr,
    });
  }
  if (datasource) {
    return (
      <div
        className={`reference ${removeColFromStylesProps(props.params.styles)}`.trim()}
        id={id ? id : undefined}
      >
        <DynamicNextImageWithNullFallback
          field={datasource.referenceImage?.jsonValue}
          width={456}
          height={456}
          params={{
            styles: 'reference__image-container',
          }}
        ></DynamicNextImageWithNullFallback>

        <div className="reference__content-container">
          {datasource.referenceCategory?.targetItems[0]?.title?.jsonValue && (
            <p className="reference__category">
              {datasource.referenceCategory?.targetItems[0]?.title?.jsonValue.value}
            </p>
          )}
          <Text
            field={datasource.referenceTitle?.jsonValue}
            tag="h5"
            className="reference__title"
            onClick={!editingMode ? handleClick : () => null}
          />
          {props?.params?.isThreeUp !== 'true' && (
            <RichText
              tag="div"
              className="reference__text"
              field={datasource.referenceText?.jsonValue}
            />
          )}
          <div className="reference__link-container">
            <a
              aria-label={t('component-reference-learnmore')}
              href={datasource?.url?.url}
              className="link link__button-tertiary subheading1"
              data-analytics-link-name={datasource?.referenceTitle?.jsonValue?.value}
              data-analytics-link-type="Link"
              data-analytics-content-class="Content"
              data-analytics-template-zone="Body"
              data-analytics-component-name="Three-up Pages"
              data-analytics-component-variation="Three-up page container"
              data-analytics-target={datasource?.url?.url}
              data-analytics-component-section={t('component-reference-learnmore')}
              data-analytics-slide-number="NAN"
              data-analytics-ispersonalized="False"
              data-analytics-iscarousal="False"
            >
              <div className="link__button-tertiary-text">
                <span>{t('component-reference-learnmore')}</span>
                <div className="link__button-tertiary-image-container">
                  <div className="link__button-tertiary-arrow">
                    <DynamicIcon icon={IconName.ArrowRight} iconLabel={'Open link'} />
                  </div>

                  <div className="link__button-tertiary-arrow-active">
                    <DynamicIcon icon={IconName.ArrowRightActive} iconLabel={'Open link'} />
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    );
  }
  return <RefereceDefaultComponent {...props} />;
};

export const RefereceDefaultComponent = (props: RefereceProps) => {
  const [editingMode] = useState(isEditorActive());
  return (
    <React.Fragment>
      {editingMode && (
        <div className={`reference ${removeColFromStylesProps(props.params.styles)}`.trim()}>
          <span className="is-empty-hint">Reference</span>
        </div>
      )}
    </React.Fragment>
  );
};
