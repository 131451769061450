import React from 'react';
import LazyLoadComponent from 'components/shared/lazy-load/LazyLoadComponent';

// basic logolines variants
export const Default = (): JSX.Element => {
  return (
    <div className="logoline--default">
      <div className="logoline logoline--default-front"></div>
      <div className="logoline logoline--default-back"></div>
    </div>
  );
};

export const EVPLogoline = (): JSX.Element => {
  return (
    <div className="logoline--evp-logoline">
      <div className="logoline logoline--evp-logoline-front"></div>
      <div className="logoline logoline--evp-logoline-back"></div>
    </div>
  );
};

export const Front = (): JSX.Element => {
  return <div className="logoline logoline--front"></div>;
};

export const Back = (): JSX.Element => {
  return <div className="logoline logoline--back"></div>;
};

export const FatWaveColor = (): JSX.Element => {
  return <div className="logoline logoline--fat-wave-color"></div>;
};

export const FatWaveWhite20Opacity = (): JSX.Element => {
  return <div className="logoline logoline--fat-wave-white"></div>;
};
let counter = 0;
export const Divider = (): JSX.Element => {
  counter++;
  return (
    <LazyLoadComponent componentId={`divider${counter}`}>
      <div className="logoline logoline--divider"></div>
    </LazyLoadComponent>
  );
};

export const HeartWave = (): JSX.Element => {
  return <div className="logoline logoline--heart"></div>;
};

export const UnPlugWave = (): JSX.Element => {
  return <div className="logoline logoline--unplug"></div>;
};

// logolines used in specific components - position absolute
export const HomepageWaves = (): JSX.Element => {
  return (
    <>
      <div className="logoline logoline--homepage-front"></div>
      <div className="logoline logoline--homepage-back"></div>
    </>
  );
};

export const SectionHeaderWavePrimary = (): JSX.Element => {
  return <div className="logoline logoline--section-header-primary"></div>;
};

export const SectionHeaderWaveSecondary = (): JSX.Element => {
  return <div className="logoline logoline--section-header-secondary"></div>;
};

export const StatementWave = (): JSX.Element => {
  return <div className="logoline logoline--statement"></div>;
};

export const SectionHeroLogoLines = (): JSX.Element => {
  return (
    <div className="logoline--section-hero">
      <div className="logoline logoline--section-hero-front"></div>
      <div className="logoline logoline--section-hero-back"></div>
    </div>
  );
};
