import {
  ImageField,
  LinkField,
  RichText,
  RichTextField,
  Text,
  TextField,
  Field,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { Tertiary } from 'components/link/Link';
import { NextImageWithNullFallback } from 'components/shared/nextImg/NextImageWithNullFallback';

import React, { useState } from 'react';
import sitecoreStyles from 'src/helpers/sitecoreStyles';
import { TagFields } from 'src/types/Tag';

interface Fields {
  area?: TextField;
  img?: ImageField;
  title?: TextField;
  body?: RichTextField;
  link?: LinkField;
  backgroundSlideImg?: ImageField;
  contentLabel?: {
    targetItem: TagFields;
  };
  hideContentLabel?: {
    jsonValue: Field<boolean>;
  };
}

type CarouselCardProps = {
  params: { [key: string]: string };
  fields: Fields;
};

const CarouselCardDefaultComponent = (props: CarouselCardProps): JSX.Element => (
  <div className={`carousel-card ${sitecoreStyles(props.params?.styles)}`}>
    <div>Empty</div>
  </div>
);

export const Default = (props: CarouselCardProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  const [editingMode] = useState(isEditorActive());

  if (props.fields) {
    return (
      <div
        className={`carousel-card ${sitecoreStyles(props.params?.styles)}`}
        id={id ? id : undefined}
      >
        <div className="carousel-card__main-box">
          {!props.fields?.hideContentLabel?.jsonValue?.value &&
            props.fields?.link?.value.href &&
            (isEditorActive() ? (
              <div className="carousel-card__element-margin carousel-card__tag button-tags">
                {props.fields?.contentLabel?.targetItem?.title?.jsonValue.value}
              </div>
            ) : (
              <Text
                tag="div"
                className="carousel-card__element-margin carousel-card__tag button-tags"
                field={props.fields?.contentLabel?.targetItem?.title?.jsonValue || { value: '' }}
              />
            ))}
          {(props?.fields?.img?.value?.src || editingMode) && (
            <div className="carousel-card__element-margin">
              <NextImageWithNullFallback
                field={props.fields?.img}
                width={200}
                height={100.65}
                params={{
                  styles: 'carousel-card__logo-container next-image--contain',
                }}
              />
            </div>
          )}
          {(props.fields.title?.value || editingMode) && (
            <Text
              tag="div"
              className="carousel-card__element-margin carousel-card__title h3"
              field={props.fields?.title}
            />
          )}

          <RichText
            tag="div"
            className="body2 carousel-card__element-margin carousel-card__body"
            field={props?.fields?.body}
          />
        </div>

        {props.fields.link && (
          <Tertiary fields={{ Link: props.fields?.link }} params={{}}></Tertiary>
        )}
      </div>
    );
  }
  return <CarouselCardDefaultComponent {...props} />;
};

export const SmallCard = (props: CarouselCardProps): JSX.Element => {
  const id = props.params.RenderingIdentifier;
  if (props.fields) {
    return (
      <div
        className={`carousel-small-card  ${sitecoreStyles(props.params?.styles)}`}
        id={id ? id : undefined}
      >
        <div>
          <Text tag="div" className="carousel-small-card__title" field={props?.fields?.area} />
          <Text tag="div" field={props.fields?.title} className="h5" />
        </div>
      </div>
    );
  }
  return <CarouselCardDefaultComponent {...props} />;
};
