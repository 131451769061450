/* eslint-disable @next/next/inline-script-id */
/* eslint-disable @next/next/no-before-interactive-script-outside-document */
import React, { useState } from 'react';
import Script from 'next/script';
import {
  Field,
  WithSitecoreContextProps,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

interface Fields {
  GTMID: Field<string>;
}

type GTMProps = {
  params: { [key: string]: string };
  fields?: Fields;
} & WithSitecoreContextProps;

const Default = (props: GTMProps): JSX.Element => {
  const [editingMode] = useState(isEditorActive());

  return (
    <>
      {editingMode && props.sitecoreContext.route?.templateName == 'Partial Design' ? (
        <div className="is-metadata-hint">
          <strong>Metadata:</strong> GTM
        </div>
      ) : (
        <>
          {!!props.fields?.GTMID?.value && (
            <>
              <Script>
                {`
                    // Define dataLayer and the gtag function.
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}

                    //gtag('set' , 'developer_id.dYWJhMj', true);

                    gtag('consent', 'default', 
                    {
                    'ad_storage':"denied",
                    //'region': ['DK','FI','AT','BE','DE','ES','FR','IE','IT','NL','NO','PL','PT','SE','GB','CH'],
                    'analytics_storage': "denied",
                    'functionality_storage': "denied",
                    'personalization_storage': "denied",
                    'security_storage': "denied",
                    'ad_user_data': "denied",
                    'ad_personalization': "denied",
                    'wait_for_update': 500 
                     });

                    //gtag('consent', 'default', 
                    //{
                    // 'ad_storage': "granted",
                    // 'analytics_storage': "granted",
                    // 'functionality_storage': "granted",
                    // 'personalization_storage':"granted",
                    // 'security_storage': "granted",
                    // 'ad_user_data': "granted",
                    // 'ad_personalization': "granted",
                    // 'wait_for_update': 500 
                    //  });

                `}
              </Script>
              <Script id="google-tag-manager" strategy="beforeInteractive">
                {`
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${props.fields?.GTMID?.value}');`}
              </Script>
              <noscript>
                <iframe
                  id="noscript-gtm"
                  src={`https://www.googletagmanager.com/ns.html?id=${props.fields?.GTMID?.value}`}
                  height="0"
                  width="0"
                ></iframe>
              </noscript>
            </>
          )}
        </>
      )}
    </>
  );
};

export default withSitecoreContext()(Default);
