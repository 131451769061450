import React, { useState } from 'react';
import { Field, ImageField, LinkField, RichTextField } from '@sitecore-jss/sitecore-jss-nextjs';
import { CoveoSearchApiCareersResult } from '../../lib/coveo/CoveoSearchApi';
import { BrightcoveItemFields } from 'components/single-video/SingleVideo';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { removeColFromStylesProps } from 'src/helpers/sitecoreStyles';
import dynamic from 'next/dynamic';
import config from 'temp/config';
export interface CardFields {
  id: string;
  title?: {
    jsonValue: Field<string>;
  };
  subTitle?: {
    jsonValue: Field<string>;
  };
  subTitle2?: {
    jsonValue: Field<string>;
  };
  body?: {
    jsonValue: RichTextField;
  };
  image?: {
    jsonValue: ImageField;
  };
  iconCard?: {
    jsonValue: ImageField;
  };
  link: {
    jsonValue: LinkField;
  };
  video?: {
    targetItem: BrightcoveItemFields;
  };
}

export type CardProps = {
  params: { [key: string]: string };
  data?: CoveoSearchApiCareersResult[];
  datalinks?: {
    seealljobs?: string;
    managejobalerts?: string;
  }; // links to be used in other roles module
  //totalcardcount?: number; // it will give the total number of job cards
  fields?: {
    data: {
      datasource: CardFields;
    };
  };
};

const editingmode = isEditorActive();

let DynamicInsightCard;

// Static import when in editing mode
if (editingmode) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  DynamicInsightCard = require('components/card/variants/insight/Insight').InsightCard;
} else {
  // Dynamic import when not in editing mode
  DynamicInsightCard = dynamic(
    () => import('components/card/variants/insight/Insight').then((mod) => mod.InsightCard),
    { ssr: !!config.dynamicImportSsr }
  );
}

export const Default = (props: CardProps): JSX.Element => {
  console.log('---->[Card] Component', props.fields);
  if (props.fields) return <DynamicInsightCard {...props} />;
  return <CardDefaultComponent {...props} />;
};

let DynamicHighlightedCard;
// Static import when in editing mode
if (editingmode) {
  DynamicHighlightedCard =
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    require('components/card/variants/highlighted/Highlighted').HighlightedCard;
} else {
  // Dynamic import when not in editing mode
  DynamicHighlightedCard = dynamic(
    () =>
      import('components/card/variants/highlighted/Highlighted').then((mod) => mod.HighlightedCard),
    { ssr: !!config.dynamicImportSsr }
  );
}

export const Highlighted = (props: CardProps): JSX.Element => {
  console.log('---->[Card] Component', props.fields);
  if (props.fields) return <DynamicHighlightedCard {...props} />;
  return <CardDefaultComponent {...props} />;
};

let DynamicExpertCard;

// Static import when in editing mode
if (editingmode) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  DynamicExpertCard = require('components/card/variants/expert/Expert').ExpertCard;
} else {
  // Dynamic import when not in editing mode
  DynamicExpertCard = dynamic(
    () => import('components/card/variants/expert/Expert').then((mod) => mod.ExpertCard),
    { ssr: !!config.dynamicImportSsr }
  );
}

export const ExpertProfile = (props: CardProps): JSX.Element => {
  console.log('---->[Card] Component', props.fields);
  if (props.fields) return <DynamicExpertCard {...props} />;
  return <CardDefaultComponent {...props} />;
};

let DynamicContentVerticalCard;

// Static import when in editing mode
if (editingmode) {
  DynamicContentVerticalCard =
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    require('components/card/variants/content-vertical/ContentVertical').ContentVerticalCard;
} else {
  // Dynamic import when not in editing mode
  DynamicContentVerticalCard = dynamic(
    () =>
      import('components/card/variants/content-vertical/ContentVertical').then(
        (mod) => mod.ContentVerticalCard
      ),
    { ssr: !!config.dynamicImportSsr }
  );
}

export const ContentVertical = (props: CardProps): JSX.Element => {
  console.log('---->[Card] Component', props.fields);
  if (props.fields) return <DynamicContentVerticalCard {...props} />;
  return <CardDefaultComponent {...props} />;
};

let DynamicContentHorizontalCard;

// Static import when in editing mode
if (editingmode) {
  DynamicContentHorizontalCard =
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    require('components/card/variants/content-horizontal/ContentHorizontal').ContentHorizontalCard;
} else {
  // Dynamic import when not in editing mode
  DynamicContentHorizontalCard = dynamic(
    () =>
      import('components/card/variants/content-horizontal/ContentHorizontal').then(
        (mod) => mod.ContentHorizontalCard
      ),
    { ssr: !!config.dynamicImportSsr }
  );
}

export const ContentHorizontal = (props: CardProps): JSX.Element => {
  console.log('---->[Card] Component', props.fields);
  if (props.fields) return <DynamicContentHorizontalCard {...props} />;
  return <CardDefaultComponent {...props} />;
};

let DynamicLinkTileCard;

// Static import when in editing mode
if (editingmode) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  DynamicLinkTileCard = require('./variants/link-tile/LinkTile').LinkTileCard;
} else {
  // Dynamic import when not in editing mode
  DynamicLinkTileCard = dynamic(
    () => import('./variants/link-tile/LinkTile').then((mod) => mod.LinkTileCard),
    { ssr: !!config.dynamicImportSsr }
  );
}

export const LinkTile = (props: CardProps): JSX.Element => {
  console.log('---->[Card] Component', props.fields);
  if (props.fields) return <DynamicLinkTileCard {...props} />;
  return <CardDefaultComponent {...props} />;
};

let DynamicCardVideo;

// Static import when in editing mode
if (editingmode) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  DynamicCardVideo = require('./variants/card-video/CardVideo').CardVideo;
} else {
  // Dynamic import when not in editing mode
  DynamicCardVideo = dynamic(
    () => import('./variants/card-video/CardVideo').then((mod) => mod.CardVideo),
    { ssr: !!config.dynamicImportSsr }
  );
}

export const ModalVideo = (props: CardProps): JSX.Element => {
  console.log('---->[Card] Component', props.fields);
  if (props.fields) return <DynamicCardVideo {...props} />;
  return <CardDefaultComponent {...props} />;
};

// https://gyazo.com/0774eff125ee935faecd51b1f95d3407
// Title and Image fields
export const Simple = (props: CardProps): JSX.Element => {
  console.log('---->[Card] Component', props.fields);
  if (props.fields) return <DynamicExpertCard {...props} />;
  return <CardDefaultComponent {...props} />;
};

export const CardDefaultComponent = (props: CardProps): JSX.Element => {
  const [editingMode] = useState(isEditorActive());
  return (
    <React.Fragment>
      {editingMode && (
        <div className={`card is-empty-hint ${removeColFromStylesProps(props.params.styles)}`}>
          <div className="card-header">
            <h4>Empty card</h4>
          </div>
          <div className="card-body">
            <h3>Card datasource isnt selected</h3>
            <p>
              This card wont be rendered because you dont have datasource selected. Please select a
              datasource to continue.
            </p>
          </div>
          <div className="card-footer">
            <a href="#" className="is-empty-hint">
              Card link example
            </a>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

let DynamicJob;

// Static import when in editing mode
if (editingmode) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  DynamicJob = require('components/card/variants/career-job/CareerJob').Job;
} else {
  // Dynamic import when not in editing mode
  DynamicJob = dynamic(
    () => import('components/card/variants/career-job/CareerJob').then((mod) => mod.Job),
    { ssr: !!config.dynamicImportSsr }
  );
}

let DynamicBookmarkProvider;

// Static import when in editing mode
if (editingmode) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  DynamicBookmarkProvider = require('lib/contexts/BookmarkContext').BookmarkProvider;
} else {
  // Dynamic import when not in editing mode
  DynamicBookmarkProvider = dynamic(
    () => import('lib/contexts/BookmarkContext').then((mod) => mod.BookmarkProvider),
    { ssr: !!config.dynamicImportSsr }
  );
}

export const CareerJobCard = (props: CardProps): JSX.Element => {
  return (
    <DynamicBookmarkProvider>
      <DynamicJob {...props} />
    </DynamicBookmarkProvider>
  );
};
